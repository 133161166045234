import React from "react";
import { Box } from "@mui/material";
import Mainhead from "../../../Components/Services/MainContent/Mainhead";
import Content from "../../../Components/Services/MainContent/Content";
import Step from "../../../Components/Services/MainContent/Step";
import CustomList from "../../../Components/Income-Tax/CustomList";
import ReusableTable from "../../../Components/Income-Tax/ReusableTable";
import { Image } from "../../../Components/Income-Tax/ImageStyle";
import Image8 from "../../../Assets/IncomeTax/salary/image7.png";

const LeaveEncashmentIndia = () => {
  return (
    <Box
      style={{
        display: "flex",
        flexDirection: "column",
        gap: 4,
        marginBottom: "2rem",
      }}
    >
      <Mainhead heading="Leave Encashment in India" />
      <Content paragraph="Leave encashment in the Income Tax Act, 1961 in India refers to the amount of money an employee receives from their employer for not utilizing paid leaves." />
      <Content paragraph="Employees receive certain paid leaves, and if they don't utilize these leaves (for example, 12 days in a year), they get reimbursed for the same. Leave encashment is typically repaid at the time of resignation or retirement and must be settled by the 7th or 10th of the following month." />
      <Step number="Different Types of Leaves Available as an Employee in India" />
      <CustomList
        points={[
          "Casual Leave: Taken for personal reasons without prior plans.",
          "Privilege Leave: Earned leave granted to employees based on their work.",
          "Medical Leave: Granted for health-related reasons.",
          "Sabbaticals: Provided for employees to enhance their knowledge and skills through courses.",
          "Maternity Leaves: Granted to pregnant employees with varying durations.",
        ]}
      />
      <Step number="Taxability of Leave Encashment in India" />
      <Content paragraph="When it comes to the taxability of leave encashment, any sum received during the term of employment is fully taxable. However, the assessee can claim relief under section 89 of the Income Tax Act." />
      <Content paragraph="In case of Leave encashment received at the time of retirement or resignation the assessee can claim completely or partially claim exemption provided the conditions below are followed:-" />
      <CustomList
        points={[
          "Central/State Government Employees: Fully Exempt",
          "Other than Central/State Government Employees: Exemption based on calculation u/s 10(10AA)",
          "Legal heir of deceased employees: Fully Exempt",
        ]}
      />
      <Box sx={{ textAlign: "center" }}>
        <Image
          component="img"
          sx={{
            content: {
              xs: `url(${Image8})`,
            },
          }}
        />
      </Box>
      <Step number={`Latest Updates On Leave Encashment in India`} />
      <Content paragraph="Rs. 25,00,000 has been revised to Rs. 3,00,000 as per Notice 31/2023, applicable from 01/04/2022. You can still claim the exemption under this revision by filing a rectification return under section 154." />
      <Step
        number={`
          Example: Leave Encashment Calculation
        `}
      />
      <Content paragraph="Let's understand this with an example:" />
      <Content paragraph="Mr. Rajesh decides to retire after 20 years of service. He has 350 days of unavailed leaves, and his monthly salary is Rs. 1,00,000 per month." />
      <ReusableTable
        data={[
          { Particulars: "Salary per day", Rs: "3,334" },
          { Particulars: "Unavailed leaves", Rs: "350 days" },
          { Particulars: "Leave Encashment", Rs: "Rs. 8,33,500" },
          {
            Particulars: "Amount specified by government",
            Rs: "Rs. 25,00,000",
          },
          { Particulars: "Actual encashment received", Rs: "Rs. 8,33,500" },
          {
            Particulars: "Average Salary (last 10 months)",
            Rs: "Rs. 10,00,000",
          },
          {
            Particulars:
              "One day’s salary * (30 * completed years of service - unutilised leaves)",
            Rs: "Rs. 8,33,500",
          },
        ]}
      />
      <Content
        paragraph={`Thus, the amount of Rs. 8,33,500 received as leave encashment would entirely be exempt.`}
      />
    </Box>
  );
};

export default LeaveEncashmentIndia;
