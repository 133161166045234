import React from "react";
import { Box } from "@mui/material";
import Mainhead from "../../../Components/Services/MainContent/Mainhead";
import Content from "../../../Components/Services/MainContent/Content";
import Step from "../../../Components/Services/MainContent/Step";
const SectionOtherIncomesTax = () => {
  const lotteryDetails = [
    "Winnings from Lotteries, Crossword Puzzles, Races (including horse races), Gambling and Betting, Card games, and game shows on electronic programs of any television are taxable under this head.",
    "Winning in a lottery can come from a random drawing, chance, or any other way under any plan or arrangement, regardless of the term used.",
  ];

  const lotteryExemptionDeduction = [
    "Such income is completely taxable (Section 58(4)) and cannot be deducted.",
    "Tax rate under Section 115BB: Taxes are paid at a fixed rate of 30%.",
  ];

  const lotteryNotes = [
    "Income from jockey: Income from horse racing is not treated as income from a horse race.",
    "Income from a motor car rally: Winning from a motor car rally is considered a return of skill and effort and is taxable as normal income.",
    "Lottery held as a stock in trade: Winning from a lottery to an agent or trader out of his unsold stock (tickets) shall be treated as incidental to business and taxed under the head of 'Profits and Gains from Business and Profession.'",
  ];

  const lotteryDeduction = [
    "No deduction can be claimed from such income even if expenditure is incurred exclusively and wholly for earning such income.",
    "Deduction under 80C to 80U cannot be claimed for such income.",
  ];

  const lotteryGrossingIncome = [
    "Lottery income received is given under lottery income.",
    "Relation between the lottery income earned and lottery income received: Lottery income received = Lottery income earned - Tax deducted at source on such income.",
  ];

  const lotteryGrossingUpProcedure = [
    "Tax deducted at source by virtue of Section 194B or 194BB, tax deductible at the source at 30% on payment in respect of payment such as lottery, etc.",
    "Procedure of grossing up for individuals or HUFs: Lottery income received = 70% of gross lottery income.",
    "Gross lottery income = Lottery income received / 70%.",
  ];

  const lotteryGrossingUpNote = [
    "Tax is not deducted in the following case, hence there is no need for grossing up:",
    "If the amount of winning from lottery, etc., or horse race is not more than Rs. 10,000.",
    "In the case of winning from racing other than horse race, e.g., camel race, etc.",
    "Winning wholly in kind or partly cash or partly kind: Even if someone wins the lottery in full or in part with cash, kind, or both, they are still responsible for paying taxes and must wait to claim their award until 30% of their earnings have been settled.",
    "In addition to the cash award, the tax will be calculated depending on the market value of the wins, such as a vehicle or other asset. Usually, the organizer deposits the winner's portion of the tax responsibility with the government after collecting it.",
  ];

  return (
    <Box
      style={{
        display: "flex",
        flexDirection: "column",
        gap: 4,
        marginBottom: "2rem",
      }}
    >
      <Mainhead heading="Other Important Incomes Taxed in Other Sources in India" />
      <Step number="Lotteries, Crossword Puzzles, Races, Gambling, Betting, and Card Games" />
      {lotteryDetails.map((detail, index) => (
        <Content key={index} paragraph={detail} />
      ))}
      <Step number="Exemption / Deduction" />
      {lotteryExemptionDeduction.map((detail, index) => (
        <Content key={index} paragraph={detail} />
      ))}
      <Step number="Notes" />
      {lotteryNotes.map((note, index) => (
        <Content key={index} paragraph={note} />
      ))}
      <Step number="Expenditure to be Deducted" />
      {lotteryDeduction.map((deduction, index) => (
        <Content key={index} paragraph={deduction} />
      ))}
      <Step number="Deduction" />
      {lotteryGrossingIncome.map((detail, index) => (
        <Content key={index} paragraph={detail} />
      ))}
      <Step number="Method of Grossing Income / Conversion of Income Received into Grossing Income" />
      {lotteryGrossingUpProcedure.map((procedure, index) => (
        <Content key={index} paragraph={procedure} />
      ))}
      <Step number="Note" />
      {lotteryGrossingUpNote.map((note, index) => (
        <Content key={index} paragraph={note} />
      ))}
    </Box>
  );
};

export default SectionOtherIncomesTax;
