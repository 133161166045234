import React from "react";
import { Box } from "@mui/material";
import Mainhead from "../../../Components/Services/MainContent/Mainhead";
import Content from "../../../Components/Services/MainContent/Content";
import Step from "../../../Components/Services/MainContent/Step";
import { Image } from "../../../Components/Income-Tax/ImageStyle";
import Image5 from "../../../Assets/IncomeTax/image5.png";

const SalesConsideration = () => {
  return (
    <Box
      style={{
        display: "flex",
        flexDirection: "column",
        gap: 4,
        marginBottom: "2rem",
      }}
    >
      {" "}
      <Mainhead heading="Sales Consideration as per Income Tax Act, 1961" />
      <Step
        number={`
          WHAT DO YOU MEAN BY SALES CONSIDERATION AS PER INCOME TAX ACT,1961?
        `}
      />
      <Content paragraph="In the context of capital gains under the Income Tax Act, 'sales consideration' refers to the amount received or accrued from the transfer of a capital asset." />
      <Content paragraph="When an individual or entity sells a capital asset like property, stocks, bonds, or any other asset, the consideration received for that transfer is the sales consideration." />
      <Content paragraph="For taxation purposes, the sales consideration plays a crucial role in determining the capital gains or losses incurred from the sale of the asset." />
      <Content paragraph="Capital gains are categorized as either short-term or long-term based on the holding period of the asset:" />
      <ul className="list">
        <li>
          Short-term capital gains: If the asset is held for less than a
          specified period (typically 36 months for most assets, except for
          certain assets like stocks held for less than 12 months), any gains
          from its sale are considered short-term capital gains. These gains are
          taxed at a higher rate than long-term capital gains and are added to
          the individual's total income for the assessment year.
        </li>
        <li>
          Long-term capital gains: If the asset is held for a period exceeding
          the specified time frame, the gains from its sale are considered
          long-term capital gains. These gains are subject to a lower tax rate
          compared to short-term capital gains.
        </li>
      </ul>
      <Step
        number={`
          The computation of capital gains involves subtracting the cost of acquisition (the price at which the asset was purchased), along with any improvement costs incurred, from the sales consideration received or accrued. The resulting amount is the capital gain (or loss), which is then subject to taxation as per the relevant tax rules and rates.
        `}
      />
      <Box sx={{ textAlign: "center" }}>
        <Image
          component="img"
          sx={{
            content: {
              xs: `url(${Image5})`,
            },
          }}
        />
      </Box>
    </Box>
  );
};

export default SalesConsideration;
