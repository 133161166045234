import React from 'react';
import Mainhead from '../../../../Components/Services/MainContent/Mainhead';
import Content from '../../../../Components/Services/MainContent/Content';
import List from '../../../../Components/Services/MainContent/List';
import Step from '../../../../Components/Services/MainContent/Step';

function Overview() {
  return (
    <div>
        <Mainhead heading="Trademark renewal"/>
        <Content paragraph="Trademark registrations are valid for 10 years and are perpetually renewable. It has to be renewed starting six months before the expiration date. Even if overlooked it—which you will receive a reminder at your registered address prior to the expiration date.
"/>
<Content paragraph="The registrar may publish a notice in the Trade Marks Journal announcing the withdrawal of the trademark if you still do not submit a renewal application.However, this is most likely to occur within a year after the expiration date.
"/>
<Content paragraph="Trademark Registration Renewal Online India process can be done between six and twelve months after they expire by paying a fee through a procedure known as 'restoration'. "/>

    </div>
  )
}

export default Overview