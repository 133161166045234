import React from "react";
import { Box } from "@mui/material";
import Mainhead from "../../../Components/Services/MainContent/Mainhead";
import Content from "../../../Components/Services/MainContent/Content";
import Step from "../../../Components/Services/MainContent/Step";
import CustomList from "../../../Components/Income-Tax/CustomList";

const Section80GGB = () => {
  return (
    <Box
      style={{
        display: "flex",
        flexDirection: "column",
        gap: 4,
        marginBottom: "2rem",
      }}
    >
      <Mainhead heading="Section 80GGB" />
      <Content paragraph="Section 80GGB provides tax deductions for contributions made by Indian companies to political parties." />
      <Step
        number={`
          What are the Eligible Donors?
        `}
      />
      <CustomList
        points={[
          "Only Indian companies are eligible to claim deductions under Section 80GGB.",
        ]}
      />
      <Step
        number={`
          Nature of Contribution:
        `}
      />
      <CustomList
        points={[
          "Deductions are allowed for contributions made by companies to political parties through any mode other than cash. Contributions can be made by cheque or through digital transactions.",
        ]}
      />
      <Step
        number={`
          Limit on Deduction:
        `}
      />
      <CustomList
        points={[
          "The deduction is limited to the amount contributed by the company. There is no specific percentage limit on the deduction.",
        ]}
      />
      <Step
        number={`
          Political Party Recognition:
        `}
      />
      <CustomList
        points={[
          "To qualify for deductions, the political party must be registered with the Election Commission of India.",
          "It should have secured at least 1% of the votes in the last general election, or it should have at least one member elected to the Lok Sabha, or it should be recognized as a state party in at least four states.",
        ]}
      />
      <Step
        number={`
          Disclosure Requirements:
        `}
      />
      <CustomList
        points={[
          "Companies are required to disclose details of contributions made to political parties in their profit and loss account.",
        ]}
      />
      <Step
        number={`
          No Cash Contributions:
        `}
      />
      <CustomList
        points={[
          "Contributions made in cash are not eligible for deductions under Section 80GGB. Companies need to make contributions through recognized banking channels.",
        ]}
      />
      <Step
        number={`
          Verification and Compliance:
        `}
      />
      <CustomList
        points={[
          "The Income Tax Department may verify the details of contributions, and companies should maintain proper documentation and records for verification purposes.",
        ]}
      />
      <Step
        number={`
          Example:
        `}
      />
      <Content paragraph="Let's consider an example: ABC Ltd., an Indian company, contributes Rs. 10,00,000 to a political party by cheque. The political party is registered with the Election Commission of India and fulfills the criteria for recognition." />
      <CustomList
        points={[
          "ABC Ltd. can claim a deduction of Rs. 10,00,000 under Section 80GGB.",
          "The deduction will be limited to the actual amount contributed.",
        ]}
      />
      <Step
        number={`
          Compliance Requirements:
        `}
      />
      <CustomList
        points={[
          "Mode of Contribution: Contributions should be made through recognized banking channels—cheque or digital transactions—to qualify for deductions.",
          "Disclosure in Financial Statements: Companies should disclose the details of political contributions in their profit and loss account.",
          "Verification by Tax Authorities: The Income Tax Department may verify the details of contributions, and companies should maintain proper documentation and records for verification purposes.",
        ]}
      />
    </Box>
  );
};

export default Section80GGB;
