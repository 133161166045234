import React from "react";
import { Box } from "@mui/material";
import Mainhead from "../../../Components/Services/MainContent/Mainhead";
import Content from "../../../Components/Services/MainContent/Content";
import CustomList from "../../../Components/Income-Tax/CustomList";
import Step from "../../../Components/Services/MainContent/Step";

const DearnessAllowanceBonus = () => {
  return (
    <Box
      style={{
        display: "flex",
        flexDirection: "column",
        gap: 4,
        marginBottom: "2rem",
      }}
    >
      <Mainhead heading="Dearness Allowance and Bonus" />

      <Step number={`Dearness Allowance (DA)`} />
      <Content paragraph="Dearness Allowance (DA) is an integral component of a person's salary or pension in India. It is a cost of living adjustment allowance paid to employees or pensioners to mitigate the impact of inflation on their income." />
      <CustomList
        points={[
          "Purpose: The primary objective of DA is to offset the impact of inflation and rising prices of essential commodities on employees' purchasing power.",
          "Applicability: DA is usually applicable to government employees, public sector employees, and sometimes to employees in the private sector as well, depending on the terms of their employment contracts or industry standards.",
          "Calculation: Dearness Allowance is calculated as a percentage of the basic salary and is usually revised biannually or quarterly based on the Consumer Price Index (CPI) or the Wholesale Price Index (WPI), reflecting the changes in the cost of living.",
          "Revisions: The government revises the DA periodically to ensure that the compensation of employees keeps up with the changing cost of living. The percentage increase or decrease in DA is determined by the prevailing inflation rates and economic conditions.",
          "Impact on Salary/Pension: When DA is increased, it directly impacts the salary or pension of the employees or pensioners. It leads to an increase in the overall compensation, ensuring that employees' income maintains its real value in the face of rising prices.",
          "Tax Treatment: Dearness Allowance may be taxable under the head 'Income from Salaries' in the hands of the recipients as per the Income Tax Act, depending on the rules and exemptions provided by tax authorities.",
          "Contribution to Provident Fund and Gratuity: In some cases, DA might be included for the calculation of contributions to the Provident Fund and Gratuity.",
        ]}
      />

      <Step number={`Bonus`} />
      <Content paragraph="Bonus in a salary context refers to an additional payment made to employees by their employers, usually as a reward for their performance, contribution to the company's success, or as an incentive to achieve certain targets or goals. It's a form of variable pay that supplements the fixed salary or wages of employees." />
      <CustomList
        points={[
          "Types of Bonuses: Bonuses can take various forms, such as:",
          "Performance Bonuses: Awarded based on an employee's individual performance or achievements.",
          "Annual Bonuses: Given at the end of a fiscal year, often based on company performance or meeting specific targets.",
          "Sign-on Bonuses: Offered to new hires as an incentive to join the company.",
          "Referral Bonuses: Awarded to employees who refer successful candidates for open positions within the company.",
          "Profit-Sharing Bonuses: Distributed based on a company's profitability, shared among employees.",
          "Calculation and Disbursement: The calculation and disbursement of bonuses depend on the company's policies. It can be a fixed percentage of the employee's salary, a predetermined amount, or a variable amount based on performance metrics or company profits.",
          "Taxation: Bonuses are generally taxable as part of an employee's income and are subject to applicable income tax rates. Employers often withhold taxes on bonuses based on statutory regulations.",
          "Incentive and Motivation: Bonuses serve as incentives to encourage employees to perform well, meet targets, and contribute effectively to the company's objectives. They can boost morale and motivation within the workforce.",
          "Employment Contracts and Bonus Policies: Bonus terms and conditions are typically outlined in employment contracts or company policies, specifying eligibility criteria, performance metrics, timing, and other relevant details.",
          "Impact on Total Compensation: Bonuses supplement an employee's total compensation package, providing an opportunity to earn additional income beyond the fixed salary component.",
          "Variable Nature: Unlike fixed salaries, bonuses are often discretionary and can vary from year to year based on individual or company performance, economic conditions, and company policies.",
          "Legal Regulations: Different jurisdictions may have specific laws or regulations governing the payment of bonuses, including minimum bonus requirements or guidelines for fair and equitable distribution.",
        ]}
      />
    </Box>
  );
};

export default DearnessAllowanceBonus;
