import React from "react";
import Typography from "@mui/material/Typography";
import CallIcon from "@mui/icons-material/Call";
import { useTheme,Grid,DialogContent,Dialog,Fab,useMediaQuery,Container } from "@mui/material";
import TaxForm from "../../Components/Tax-Planning/IncomeTaxForm/TaxForm";

import Image1 from "../../Assets/TaxPlanning/Images/image1.png";
import Image2 from "../../Assets/TaxPlanning/Images/image2.png";
import MobileHero from "../../Assets/TaxPlanning/Images/mobileHero.png";
import useStyles from "./Style";


function Heading(props) {
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  const theme = useTheme();

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const isTablet = useMediaQuery(theme.breakpoints.down("md"));
  const isRange = useMediaQuery(theme.breakpoints.between("md", "lg"));
  return (
    <div className="Container">
      <Container >
      <Fab
          onClick={handleClickOpen}
          variant="extended"
          className={classes.fabButton}
          sx={
            !(isMobile || isTablet)
              ? {
                  position: "fixed",
                  zIndex: "1",
                  bottom: "2%",
                  right: "1%",
                }
              : isRange
              ? {
                  display: "none",
                }
              : isMobile
              ? {
                  position: "fixed",
                  zIndex: "1",
                  bottom: "2%",
                  right: "15%",
                  width: "20%",
                }
              : isTablet && {
                  position: "fixed",
                  zIndex: "1",
                  bottom: "2%",
                  right: "5%",
                  width: "10%",
                }
          }
        >
          <CallIcon />{" "}
          <Typography
            sx={
              !isMobile && !isTablet
                ? {
                    fontWeight: "bolder",
                    fontFamily: "Sora",
                  }
                : {
                    display: "None",
                  }
            }
          >
            {" "}
            Get In Touch{" "}
          </Typography>
        </Fab>
        <Dialog
          sx={
            isMobile
              ? {
                  width: "100%",
                  display: "flex",
                  justifyContent: "center",
                }
              : isTablet && {
                  width: "100%",
                  display: "flex",
                  justifyContent: "center",
                }
          }
          open={open}
          onClose={handleClose}
          PaperProps={{
            style: { borderRadius: 20 },
          }}
        >
          <DialogContent
            sx={
              !isMobile
                ? {
                    display: "flex",
                    justifyContent: "center",
                    px: 0,
                    width: 400,
                  }
                : {
                    display: "flex",
                    justifyContent: "center",
                    px: 0,
                    width: 300,
                  }
            }
          >
           <TaxForm service="Advancetax" />
          </DialogContent>
        </Dialog>
        

      
      <div
        style={{
          marginTop: "20px",
        }}
      >
        <Grid
          container
          alignItems="stretch"
          sx={
            isMobile || isTablet
              ? {
                  background:
                    "linear-gradient(135deg, #FFC251 0.05%, #FFA600 0.15%)",
                    
                }
              : {
                  background:
                    "linear-gradient(106.08deg, #EAA31F 9.92%, rgba(255, 255, 255, 0) 82.59%)",
                    
                }
          }
        >
          <Grid item xs={12} md={4}>
            <Grid container spacing={0}>
              {!(isMobile || isTablet) && !isRange ? (
                <>
                  <Grid item>
                    <img style={{ width: "340px" }} src={Image1} alt="image1" />
                  </Grid>
                  <Grid item sx={{ marginTop: "-50px", marginBottom: "-40px" }}>
                    <img src={Image2} alt="image2" style={{ width: "364px" }} />
                  </Grid>
                </>
              ) : isRange ? (
                <>
                  <Grid item sx={{ marginTop: "0px", marginBottom: "0px" }}>
                    <img src={Image1} alt="image1" style={{ width: "280px" }} />
                  </Grid>
                  <Grid item sx={{ marginTop: "-40px", marginBottom: "-10px" }}>
                    <img src={Image2} alt="image2" style={{ width: "284px" }} />
                  </Grid>
                </>
              ) : (
                <Grid
                  item
                  sx={{
                    width: "100%",
                  }}
                >
                  {/* <Container sx={{
                      display:"flex",
                      justifyContent:"center",
                      width:'12%'
                    }} */}
                  {/* </Container> */}
                  <img
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      width: "100%",
                    }}
                    src={MobileHero}
                    alt="image1"
                  />
                </Grid>
              )}
            </Grid>
          </Grid>
          <Grid item md={4} xs={12}>
            <Grid
              container
              direction="column"
              justifyContent="center"
              sx={{ marginTop: "100px" }}
            >
              <Grid item>
                <Typography
                  variant="h4"
                  sx={
                    !(isMobile || isTablet) && !isRange
                      ? {
                          color: "#703C00",
                          fontSize: "40px",
                          fontWeight: "700",
                          fontFamily: "Sora",
                        }
                      : isRange
                      ? {
                          color: "#703C00",
                          fontSize: "20px",
                          fontWeight: "700",
                          fontFamily: "Sora",
                        }
                      : {
                          color: "white",
                          padding: "0 20px 0 20px",
                          fontFamily: "Sora",
                        }
                  }
                >
                  {props.heading}
                </Typography>
              </Grid>
              <Grid item sx={{ marginTop: "29px", marginBottom: "40px" }}>
                <Typography
                  sx={
                    isMobile || isTablet
                      ? {
                          color: "white",
                          padding: "0 20px 0 20px",
                          fontFamily: "Sora",
                        }
                      : {
                          color: "#703C00",
                          fontSize: "20px",
                          fontWeight: "700",
                          fontFamily: "Sora",
                        }
                  }
                >
                  <a
                    style={{marginRight:'8px'}}
                    href="https://calendly.com/legalfactory/free-consultation"
                  >
                    Contact us now
                  </a>
                  and get a FREE CONSULTATION from an expert
                </Typography>
              </Grid>
              <Grid item sx={{ marginTop: "19px", marginBottom: "40px" }}>
                <Typography
                  sx={
                    isMobile || isTablet
                      ? {
                          color: "white",
                          padding: "0 20px 0 20px",
                          fontFamily: "Sora",
                        }
                      : {
                          color: "#703C00",
                          fontSize: "20px",
                          fontWeight: "700",
                          fontFamily: "Sora",
                        }
                  }
                >
                  Start your journey at just{" "}
                  <span style={{ color: "red", fontFamily: "Sora" }}>
                    Rs 499*
                  </span>
                </Typography>
              </Grid>

              {/* {!(isMobile || isTablet) && (
                  <>
                    <Grid item>
                      <Typography
                        sx={{
                          color: "#703C00",
                          fontWeight: "700",
                          fontFamily: "Sora",
                        }}
                      >
                        Start your journey to success at just{" "}
                        <span style={{ color: "red", fontFamily: "Sora" }}>
                          Rs 499*
                        </span>
                      </Typography>
                    </Grid>
                  </>
                )} */}
            </Grid>
          </Grid>

          <Grid
            item
            md={4}
            xs={12}
            sx={
              !(isMobile || isTablet) && !isRange
                ? {
                    my: 2,
                  }
                : isRange
                ? {}
                : {
                   display: "none",
                  }
            }
          >
            {" "}
            <div
              className={!isRange ? classes.getInTouch : classes.getInTouch2}
            >
              <TaxForm service="TDS" />
            </div>
            <br/><br/>
          </Grid>
        </Grid>
        <br/>
        
      </div>
      
    </Container>
    </div>
  );
}
export default Heading;
