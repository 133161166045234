import React from "react";
import { Box } from "@mui/material";
import Mainhead from "../../../Components/Services/MainContent/Mainhead";
import Content from "../../../Components/Services/MainContent/Content";
import CustomList from "../../../Components/Income-Tax/CustomList";
import Step from "../../../Components/Services/MainContent/Step";

const Section44ADPlan = () => {
  return (
    <Box
      style={{
        display: "flex",
        flexDirection: "column",
        gap: 4,
        marginBottom: "2rem",
      }}
    >
      <Mainhead heading="Section 44AD Plan for Presumptive Taxation on Small Taxpayers" />

      <Content paragraph="Interpretation of the presumptive tax system: A person in business or the profession is mandated by the Income-tax Act to keep regular books of accounts and to have his accounts audited. Sections 44AD, 44ADA, and 44AE of the Income-tax Act set up the presumptive taxation plan to relieve small taxpayers of this laborious job." />

      <Step
        number={`
          By choosing to use the presumptive taxation plan, an individual can declare income at a predetermined rate and avoid the laborious task of maintaining books of accounts and having the accounts audited.
        `}
      />

      <Step
        number={`
          Who can Adopt for Section 44 AD?
        `}
      />
      <CustomList
        points={[
          "A resident person",
          "A Hindu family living together in unity",
          "Limited Liability Partnership Firm, not Resident Partnership Firm",
        ]}
      />

      <Step
        number={`
          What are the various situations in which an assessee cannot claim for section 44AD?
        `}
      />
      <CustomList
        points={[
          "Section 44AE refers to the business of transporting, hiring, or leasing goods carriages.",
          "A person who conducts any agency business.",
          "A person who earns money through commissions or brokerage.",
          "Section 44AD's presumptive taxation structure cannot be used by an insurance agency.",
          "A person engaged in a profession as defined in section 44AA(1) is not permitted to use the presumptive taxation scheme of section 44AD.",
          "Individuals who have annual gross receipts or turnover exceeding Rs. 2,00,00,000* are ineligible to use section 44AD's presumptive taxation scheme.",
        ]}
      />

      <Step
        number={`
          LATEST UPDATES IN BUDGET REGARDING SECTION 44AD
        `}
      />
      <Content paragraph="The following changes to the presumptive taxation limits for FY 2023–24 (AY 2024–25) were made by the Budget 2023 to Section 44AD : -" />
      <CustomList
        points={[
          "Category: Sec 44AD: In the case of small firms",
          "Previous constraints: Rs. 2 crore",
          "Limits have been revised: Rs. 3 crore",
        ]}
      />

      <Step
        number={`
    Method of computing taxable business income under the Income-tax Act's normal provisions, that is, if an individual chooses not to use section 44AD's presumptive taxation scheme:
  `}
      />
      <Content paragraph="According to the Income-tax Act, each person's taxable business income is generally calculated as follows:" />
      <CustomList
        points={[
          "Turnover or gross receipts from the business: XXX",
          "Less: Expenses incurred in relation to earning of the income: (XXX)",
          "Taxable Business Income: XXX",
        ]}
      />

      <Step
        number={`
    How to calculate Income Under Section 44AD?
  `}
      />
      <Content paragraph="In the case of a person who follows the provisions of Section 44AD, income is estimated on a presumptive basis at the rate of 8% of the qualified business's turnover or gross revenues for the year." />
      <Content paragraph="The 8% rate has been decreased to 6% in order to increase digital transactions and encourage businesses to accept digital payments, such as credit cards." />
      <CustomList
        points={[
          "Credit cards",
          "Bank draft or account payee cheque",
          "Internet banking",
          "UPI",
          "RTGS",
          "IMPS",
          "NEFT",
        ]}
      />

      <Step
        number={`
    Do I need to maintain any Books Of Accounts As Intimated by Section 44AA of the Income Tax Act, 1961?
  `}
      />
      <Content paragraph="If an individual conducting business chooses to utilize section 44AD's presumptive taxation scheme, the section 44AA requirements concerning the upkeep of books of account will not be applicable." />
      <Content paragraph="Stated differently, a person is exempt from maintaining the books of account required by section 44AA for businesses covered by the presumptive taxation scheme of section 44AD if they adopt the provisions of section 44AD and declare income at 6% or 8% (as the case may be) of the turnover." />

      <Step
        number={`
    The advance payment of tax on company profits covered by section 44AD
  `}
      />
      <Content paragraph="If someone chooses to use section 44AD's presumptive taxation plan, they must pay the whole advance tax amount by March 15th of the prior year, at the latest." />
      <Content paragraph="He will be required to pay interest in accordance with section 234C if he does not pay the advance tax by the prior year's March 15th." />

      <Step
        number={`
    Other crucial details:
  `}
      />
      <CustomList
        points={[
          "No more expenses will be permitted or prohibited; the presumptive income calculated at the prescribed rate is the final income.",
          "A person who has claimed any deductions under section 10A/10AA/10B/10BA or under sections 80HH to 80RRB in the relevant year is not eligible to adopt this scheme.",
          "If a person chooses to use section 44AD's presumptive taxation scheme, they are not subject to a tax audit under section 44AB.",
        ]}
      />

      <Step
        number={`
    Consequences of choosing not to participate in section 44AD's presumptive taxation scheme
  `}
      />
      <Content paragraph="A person must adhere to the presumptive taxation scheme for the next five years if they want to do so." />
      <Content paragraph="The presumptive taxation method will not be available to him for the next five years if he doesn't comply." />
      <Content
        paragraph={`Furthermore, in accordance with section 44AB of the AY, in which he chooses to withdraw from the presumptive taxation plan, he must keep and maintain books of account and is subject to a tax audit. [If his total income surpasses the highest amount exempt from taxation].`}
      />
    </Box>
  );
};

export default Section44ADPlan;
