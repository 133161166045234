import React from 'react';
import Sidecomponent from '../../../Components/Tax-Planning/Sidecomponent';
import Topdiv from "../../../Components/Tax-Planning/Topdiv"
import COA from "../../../Assets/TaxPlanning/CapitalGain/COA.png";
import COI from "../../../Assets/TaxPlanning/CapitalGain/COI.png";
import Chart from "../../../Assets/TaxPlanning/CapitalGain/Table.png"
import {
  Grid, Box, Container, styled, Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Link
} from '@mui/material';
import GetInTouch from '../../../Components/Services/GetInTouch';
import Mainhead from '../../../Components/Services/MainContent/Mainhead';
import Content from '../../../Components/Services/MainContent/Content';
import List from '../../../Components/Services/MainContent/List';
import Step from '../../../Components/Services/MainContent/Step';

import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import Faq from './Faq';


function createData(
  Particulars,
  Taxtreatment
) {
  return {
    Particulars,
    Taxtreatment
  };
}

function createData1(
 Capital,
 Cost,
 Period
) {
  return {
    Capital,
 Cost,
 Period
  };
}

function createData2(
  Gain,
  Acquisition
 ) {
   return {
    Gain,
    Acquisition
   };
 }
const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: '#773106',
    color: theme.palette.common.white,
    textAlign: 'left',
    fontFamily: 'Sora',
    fontWeight: 'bold',



  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 16,
    textAlign: 'left',
    textAlign: 'left',
    fontFamily: 'Sora',
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(even)': {
    backgroundColor: theme.palette.action.hover,
    fontFamily: 'Sora'
  },

  '&:last-child td, &:last-child th': {
    border: 0,
  },
}));

const rows = [
  createData('Sale consideration received or accruing as a result of transfer of capital asset. (FVOC)','XXX'),
  createData('Less: The Cost of Acquisition of the asset (COA)','XXX'),
  createData('Less: The Cost of improvement to the asset (COI)','XXX'),
  createData('Capital gain / Loss','XXX')
];

const rows1 = [
  createData1('Original share/ securities','Purchase Price','From the date of purchase or allotment'),
  createData1('Right shares/securities','Price actually paid under the right issue','from the date of allotment'),
  createData1('In the hands of the person who renounces the right','NIL','From the date of offer(always short-term)'),
  createData1('In the hands of the purchaser of right','Price paid to the person who renounced the right and amount paid to the company to acquire the right share','From the date of allotment'),
  createData1('Bonus share/securities','NIL','From the date of allotment')
]

const rows2 = [
  createData2('Equity share or shares alloted to a member of a recognized stock exchange in india','Cost of acquisition of his original membership of the exchage'),
  createData2('Treading or clearing right of the recognized stock exchange acquired by a shareholder','NIL')
];

const Bottomspace = styled(Box)(({ theme }) => ({
  marginBottom:'2.1rem'
}));

function Capitalgain() {
  const Image  = styled(Box)(({ theme }) => ({
    marginTop:'30px',
    width:'75%',
    marginBottom:'3rem',
    justifyContent:'center',
    [theme.breakpoints.down("md")]: {
      marginTop:'20px',
      width:'100%',
    }
  }));

  const Bottomspace = styled(Box)(({ theme }) => ({
    marginBottom:'2.1rem'
  }));

  return (
    <div>
       <Topdiv heading="Are you looking to file your Income Tax Returns ?"/>
       <div className='Container'>
      <Container>
        <Grid container>
          <Grid item  lg={4} xl={3}>
            <Box sx={{ display: { xs: 'none', lg: 'block' }, marginTop: '4rem', position: 'sticky', top: 90, marginBottom: '3rem' }}>
              <Sidecomponent />
            </Box>
          </Grid>
          <Grid item xs={11} lg={8} sx={{ marginTop: '4rem' }} xl={9}>
            <Mainhead heading="CAPITAL GAIN" />
            <Step number="Section 45(1) is the charging Section of capital gains and it provides as under:"/>
            <Content paragraph="any profits and gains arising from transfer of a capital asset effected in the PY shall be chargeable to income-tax under the head capital gains in the PY in which transfer took place."/>
            <Content paragraph="An income is chargeable to tax under this head of income only if following conditions are satisfied:"/>
            <ul className='list'>
              <li className='bullet'>
                <span>
            <List points="There must be a “CAPITAL ASSET”"/>
            </span>
            </li>
            <li className='bullet'>
                <span>
            <List points="The capital asset must be “TRANSFERRED”"/>
            </span>
            </li>
            <li className='bullet'>
                <span>
            <List points="Such capital asset must be transferred “DURING THE P.Y."/>
            </span>
            </li>
            </ul>
            <br/>

            <Mainhead paragraph="Definition Of Capital Assets ( SEC 2(14))"/>
            <Content paragraph="(a) property of any kind (movable/immovable/tangible/intangible) held by an assessee whether or not connected with his business or profession,"/>
            <Content paragraph="(b) any securities held by a Foreign Institutional Investor (FII) which has invested in such securities in accordance with the regulations made under the Securities and Exchange Board of India Act, 1992,"/>
            <Content paragraph="(c) any unit linked insurance policy to which exemption under section 10(10D)does not apply on account of the applicability of the fourth and fifth provisos thereof, but does not include (Exceptions) –"/>
            <Content paragraph="(1) any stock-in-trade, consumable stores or raw materials held for the purposes of his business or profession [other than securities referred to in sub-clause (b)];"/>
            <Content paragraph="(2) personal effects, that is to say, movable property (including wearing apparel and furniture) held for personal use by the assessee or any member of his family dependent on him, but excludes—"/>
            <Step number ="(a) jewellery;"/>
            <Step number ="(b) drawings;"/>
            <Step number ="(c) paintings;"/>
            <Step number ="(d) sculptures;"/>
            <Step number="(e) archaeological collection; or"/>
            <Step number="(f) any work of art."/>
         <Bottomspace/>
            <Content paragraph="(a) ornaments made of gold, silver, platinum or any other precious metal or any alloy containing one or more of such precious metals, whether or not containing any precious or semi-precious stone, and whether or not worked or sewn into any wearing apparel;"/>
            <Content paragraph="(b) precious or semi-precious stones, whether or not set in any furniture, utensil or other article or worked or sewn into any wearing apparel;"/>
            <Content paragraph="(3) agricultural land in India (Rural Agriculture land in India),– It means land outside the followings:"/>
            <Content paragraph="(a) in any area which is comprised within the jurisdiction of a municipality (whether known as municipality, municipal corporation, notified area committee, town area committee, town committee, or by any other name) or a cantonment board and which has a population 10,000 or more; or"/>
            <Content paragraph="(b) in following area within the distance, measured aerially,- i Shortest aerial distance from the local limits of a municipality or cantonment board referred to in item (a) Population according to the last preceding census of which the relevant figures have been published before the first day of the PY."/>
            <ul className='list'>
              <li className='bullet'>
                <span>
            <List points ="2 kilometers > 10,000 ` 1,00,000"/>
            </span>
            </li>
            <li className='bullet'>
                <span>
            <List points =" 6 kilometers > 1,00,000 ` 10,00,000"/>
            </span>
            </li>
            <li className='bullet'>
                <span>
            <List points =" 8 kilometers > 10,00,000"/>
             </span>
             </li>
             </ul>
             <br/>
            <Content paragraph="Explanation: “Population” means population according to last preceding census of which t he relevant figures have been published before the first day of the PY."/>

            <Content paragraph="(4) Gold Deposit Bonds issued under the Gold Deposit Scheme, 1999 or deposit certificates issued under the Gold Monetisation Scheme, 2015, notified by the Central Government."/>
            <br/>

            <Mainhead heading="Types of CAPITAL Assets"/>
            <Content paragraph="Short Term Capital Asset: Section 2(42A) defines a short term capital asset to mean a capital asset held by an assessee for not more than 12 months immediately preceding the date of its transfer Long Term Capital Asset: As per section 2(29A), any asset, which is not a STCA is called Long term capital Asset. In other words if a capital asset is held for a period of more than 36 months, then it called “Long Term Capital Asset”."/>
            <Bottomspace/>
            <Mainhead heading="Second proviso to Sec. 48: Indexation"/>
            <ul className='list'>
              <li className='bullet'>
                <span>
            <List points ="(1) Where the capital gains arises from the transfer of a long term capital asset, then for the purposes of computing capital gains:"/>
            </span>
            </li>
            <li className='bullet'>
                <span>
            <List points="(a) “Indexed Cost of Acquisition” shall be taken instead of “Cost of Acquisition” and"/>
            </span></li>
            <li className='bullet'>
                <span>
            <List points="(b) “Indexed cost of any improvement” shall be taken instead of “cost of any improvement”."/>
            </span>
            </li>
            </ul>
            <br/>
            
            <Mainhead heading="INDEXED COST OF ACQUISITION"/>
            <br/>
            <Box sx={{textAlign:'center'}}>
            <Image component="img"
                sx={{
                  content: {
                    xs: `url(${COA})`
                  }
                }}/>
                </Box>
                <br/><br/>
            <Mainhead heading="INDEXED COST OF IMPROVEMENT"/>
            <br/>
            <Box sx={{textAlign:'center'}}>
            <Image component="img"
                sx={{
                  content: {
                    xs: `url(${COI})`
                  }
                }}/>
                </Box>
                <br/><br/>
            <Mainhead heading="Cost Inflation Index (CII)"/>
            <br/>
            <Box sx={{textAlign:'center'}}>
            <Image component="img"
                sx={{
                  content: {
                    xs: `url(${Chart})`
                  }
                }}/>
                </Box>
                <br/><br/>

                <Mainhead heading="Fourth proviso to Sec. 48 : "/>
                <Step number="NO Index in Case of Debentures & Bonds"/>
                <Content paragraph="The indexation under the second proviso to section 48 shall not apply to the capital gains arising from the transfer of bonds and debentures. ± However, benefit of indexation is available for capital indexed bonds issued by the central government or Sovereign Gold Bond issued by the Reserve Bank of India under the Sovereign Gold Bond Scheme, 2015. i Benefit of indexation is available in respect of units of UTI and Units of Mutual Funds."/>
                <Bottomspace/>

                <Mainhead heading="Fourth proviso to Sec. 48 :"/>
                <Step number="FOREIGN EXCHANGE FLUCTUATION GAIN ON RDB NO Index in Case of Debentures & Bonds"/>
<Content paragraph="Provided also that in case of an assessee being a NON-RESIDENT,any gains arising on account of appreciation of rupee against a foreign currency at the time of redemption of rupee denominated bond (RDB) of an a indian company held by him, shall be ignored for the purpose of computation of full value of consideration under this section ( Amended by FA 2017)
"/>

<Content paragraph="The above proviso has been amended with the effect from assessment year 2018-19 by substituting the word “held” in place of the word “subscribed”. Thus the benefit of said proviso shall be available to any person who hold the bond,then is,even a secondary holder also."/>
<Bottomspace/>

<Mainhead heading="Seventh proviso to Sec. 48:"/>
<Step number="STT Not Allowed"/>
<Content paragraph="No deduction shall be allowed in computing the income chargeable under the head “Capital Gains” in respect of any sum paid on account of securities transaction tax.
Accordingly the securities transaction tax (STT) paid on sale of shares/units shall not be reduced from the sale price and the STT paid on purchase of shares/units shall not be added to the cost of acquisition"/>
<Bottomspace/>

<Mainhead heading="COST OF ACQUISITION SEC 55."/>
<Content paragraph="In relation to a capital asset being"/>
<ul className='list'>
  <li className='bullet'>
    <span>
<List points ="Goodwill of a business or Profession,"/>
</span>
</li>
<li className='bullet'>
                <span>
<List points ="Trade mark or brand name associated with a business or profession, or"/>
</span>
</li>
<li className='bullet'>
                <span>
<List points ="Tenancy rights, or"/>
</span>
</li>
<li className='bullet'>
                <span>
<List points ="Tenancy rights, or"/>
</span>
</li>
<li className='bullet'>
                <span>
<List points =" Loom hours, or"/>
</span>
</li>
<li className='bullet'>
                <span>
<List points =" A right to manufacture, produce or process any article or thing, or"/>
</span>
</li>
<li className='bullet'>
                <span>
<List points ="A right to carry on any business or Profession"/>
</span>
</li>
</ul>

<Step number="Cost of acquisition means"/>
<Step number="(a) In case such asset is acquired by the assessee: the amount of price paid and"/>
<Step number="(b) In any other case (Self-Generated): Nil"/>

<Content paragraph="1. In case of Intangible Assets, the option to take cost of acquisition as Fair market value as on 01-04-2001 is not available even if such assets were acquired before 01.04.2001. This will apply irrespective of the fact whether such assets are self-generated or have been purchased from someone."/>
<Content paragraph="2. Capital gain on transfer of self-generated goodwill of a profession or self-generated trademark/ brand name associated with a profession, is not chargeable to tax up to the assessment year 2020-21."/>
<Content paragraph="3. Proviso added in section 55: Provided that where the capital asset, being goodwill of a business or profession, in respect of which a deduction on account of depreciation under section 32(1) has been obtained by the assessee in any PY preceding the PY relevant to the AY year commencing on or after the 1st day of April, 2021, the above provisions shall apply with the modification that the total amount of depreciation obtained by the assessee before the assessment AY 21-22 shall be reduced from the amount of purchase price. (will discuss with PGBP"/>
<Bottomspace/>

<Mainhead heading="In case of share/security"/>
<br/>
<TableContainer component={Paper}>
                <Table aria-label="customized table" size='small'>
                  <TableHead>
                    <TableRow>
                      <StyledTableCell>Capital Assets</StyledTableCell>
                      <StyledTableCell>Cost Of Acquisition</StyledTableCell>
                      <StyledTableCell>Period Of Holding</StyledTableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {rows1.map((row) => (
                      <StyledTableRow
                        key={row.name}

                      >
                        <StyledTableCell component="th" scope="row"
                        >
                          {row.Capital}
                        </StyledTableCell>
                        <StyledTableCell>{row.Cost}</StyledTableCell>
                        <StyledTableCell>{row.Period}</StyledTableCell>
                      </StyledTableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
              <br/><br/>

              <Mainhead heading="Under a scheme for demutualization of corporatization approved by SEBI in a relation to a capital asset,being:"/>
              <br/>
              <TableContainer component={Paper}>
                <Table aria-label="customized table" size='small'>
                  <TableHead>
                    <TableRow>
                      <StyledTableCell> Capital Gain</StyledTableCell>
                      <StyledTableCell>Cost Of Acquisition</StyledTableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {rows2.map((row) => (
                      <StyledTableRow
                        key={row.name}

                      >
                        <StyledTableCell component="th" scope="row"
                        >
                          {row.Gain}
                        </StyledTableCell>
                        <StyledTableCell>{row.Acquisition}</StyledTableCell>
                      </StyledTableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
              <br/><br/>
              <Content paragraph="The option to take FMV as on 1-04-2001 is Not available.

(4) in relation to other capital assets

1)where the capital asset is acquired by the assessee or the previous owner before 1-04-2001:

(i) cost of Acquisition of the assessee or the previous

owner (ii) FMV as on 01/04/2001 Whichever is higher

(2)where the capital asset is acquired by the assessee or the previous owner before 1-04-2001:"/>

<Content paragraph="Provided that in case of capital asset,being land or building or both,the fair market value of such asset on the 1st day of April,2001 for the purpose of the said sub-clause shall not exceed the stamp duty value,wherever available,of such asset as on the 1st day of April,2001."/>
<Content paragraph="Explanation: for the purpose of this proviso “stamp duty value” means the value adopted or assessed or assessable by any authority of central government or state government for the purpose of payment stamp duty in respect of an immovable property."/>
<Bottomspace/>

<Mainhead heading="COST OF IMPROVEMENT SEC 55"/>
<Content paragraph="Cost of improvement of the following shall be taken to be NIL :"/>
<Step number="1.Goodwill of business"/>
<Step number="2.Right of manufacturing,produce or process any article or thing"/>
<Step number="3.Right to carry on any business or profession"/>
<Step number="4.(b) in other capital asset,capital expenditure by assessee in PY on or after 01.04.2001"/>
<Step number="5.Expenditure incurred by on or before 1.04.2001 shall be IGNORED"/>
<Bottomspace/>
<Faq/>
<Bottomspace/>
<Bottomspace/>
            </Grid>
            </Grid>
            
            </Container>
            </div>
    </div>
  )
}

export default Capitalgain