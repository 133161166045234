import React from "react";
import { Box } from "@mui/material";
import Mainhead from "../../../Components/Services/MainContent/Mainhead";
import Content from "../../../Components/Services/MainContent/Content";
import Step from "../../../Components/Services/MainContent/Step";

const CryptoDigitalMoneyTax = () => {
  return (
    <Box
      style={{
        display: "flex",
        flexDirection: "column",
        gap: 4,
        marginBottom: "2rem",
      }}
    >
      {" "}
      <Mainhead heading="Special Rate - Crypto and Digital Money" />
      <Step
        number={`
            Crypto
          `}
      />
      <Content paragraph="Crypto currencies are treated as capital assets in India." />
      <Step
        number={`
            Capital Gains Tax on Crypto
          `}
      />
      <Content paragraph="Profits or gains arising from the transfer of cryptocurrencies are considered capital gains." />
      <Content paragraph="Short-term capital gains (STCG) are taxed at the individual's applicable income tax slab rates, while long-term capital gains (LTCG) are taxed at a flat rate of 20% with indexation." />
      <Step
        number={`
            Reporting Requirements for Crypto
          `}
      />
      <Content paragraph="Individuals involved in cryptocurrency transactions are required to report their gains or losses in their income tax returns under the head 'Income from Capital Gains.'" />
      <Step
        number={`
            Other Digital Money
          `}
      />
      <Content paragraph="Any income generated from digital assets, such as gains from the sale or transfer of digital assets, would be categorized based on the nature of the income (e.g., capital gains, business income, etc.)." />
      <Step
        number={`
            Capital Gains Tax on Other Digital Money
          `}
      />
      <Content paragraph="Profits or gains from the sale of digital assets are generally treated as capital gains. STCG is taxed at the individual's applicable income tax slab rates, while LTCG is taxed at a flat rate of 20% with indexation benefits." />
      <Step
        number={`
            Updates in Budget 2023
          `}
      />
      <Content paragraph="Indian investors trading in cryptocurrencies and NFTs must report revenue from these transactions as capital gains. Crypto/NFT income is classified as business income if held for trading purposes. The deadline for filing income tax returns for FY 2022–2023 is July 31, 2023, with a late filing option until December 31, 2023." />
      <Step
        number={`
            Updates in Union Budget 2022
          `}
      />
      <Content paragraph="Digital assets, including cryptocurrency, have been classified as 'Virtual Digital Assets.' Taxes on income from the transfer of virtual digital assets, including cryptocurrency and NFTs, are set at 30%. No deductions are permitted other than the acquisition cost. Loss from digital assets cannot be set off against other income. Gifting digital assets results in taxes for the recipient. From July 1, 2022, a 1% TDS will be applied to all sales of virtual digital assets (VDAs), including cryptocurrencies and NFTs." />
    </Box>
  );
};

export default CryptoDigitalMoneyTax;
