import React from "react";
import { Box } from "@mui/material";

import Mainhead from "../../../Components/Services/MainContent/Mainhead";
import Content from "../../../Components/Services/MainContent/Content";
import CustomList from "../../../Components/Income-Tax/CustomList";
import Step from "../../../Components/Services/MainContent/Step";
import { Image } from "../../../Components/Income-Tax/ImageStyle";
import Image2 from "../../../Assets/IncomeTax/image2.jpg";

const CapitalGainComponent = () => {
  return (
    <Box
      style={{
        display: "flex",
        flexDirection: "column",
        gap: 4,
        marginBottom: "2rem",
      }}
    >
      {" "}
      <Mainhead heading="WHAT IS CAPITAL GAIN" />
      <Content paragraph="When you sell a capital asset such as real estate, stock, or any other investment at a price that is higher than what you originally paid for it, such gain is subjected to tax under the head ‘Capital Gain’." />
      <Mainhead heading="WHAT DOES CAPITAL GAIN MEANS AS PER INCOME TAX ACT, 1961" />
      <Content paragraph="Any profit/gain that arises out of the sale or transfer of a capital asset is known as 'Capital Gain'. Capital gain can be classified into two:  ‘Short term capital gain’ and ‘Long term capital gain’ depending upon the tenure for which the capital asset is held before its sale or transfer." />
      <Content paragraph="As per the charging section 45 of the Income Tax Act, any profits or gains arising from the transfer of a capital asset shall be chargeable under the head capital gain in the previous year in which the transfer took place." />
      <Mainhead heading="WHAT DOES CAPITAL ASSET MEAN IN INCOME TAX ACT, 1961?" />
      <Content paragraph="A 'capital asset' refers to any property held by an individual, including tangible and intangible assets, which leads to capital gains when sold or transferred. A capital asset is such an asset which is not purchased for the purpose of reselling it in the course of business." />
      <Step number="As per section 2(14) of the Income Tax Act, capital asset means-" />
      <CustomList
        points={[
          "Property of any kind held by the assessee, whether or not it is related to the business and profession.",
          "The primary types of assets that are classified as capital asset under the Income Tax Act are:",
          "Real estate property- Any land, building, commercial property, residential property and any rights attached to them come under real estate. Gain on sale of such property is subject to capital gains tax.",
          "Vehicles- Any vehicles that are held for personal use are considered as capital assets.",
          "Securities- Shares, stocks, bonds, debentures, mutual fund units, government securities etc. are treated as capital assets.",
          "Intellectual property rights- Copyrights, patents, trademarks and other intangible assets.",
          "Jewelry, Bullion, and Precious Items- Gold, silver, diamond or any other item made of precious metals or stones.",
          "Leasehold rights- Leasehold rights on any property are also considered as capital assets.",
        ]}
      />
      <Mainhead heading="WHAT DOES NOT CONSTITUTE A CAPITAL ASSET?" />
      <CustomList
        points={[
          "Any stock-in-trade (goods held for business purposes)",
          "Consumable goods or raw materials.",
          "Agricultural land in rural India (not within specified municipal limits)",
          "Personal articles like clothing, furniture, etc., held for personal use.",
          "6.5% Gold Bonds, 1977, issued by the Central Govt.",
          "7% Gold Bonds, 1980, issued by the Central Govt.",
          "Special Bearer Bonds, 1991, issued by the Central Govt",
          "National Defence Gold Bonds,1980, issued by the Central Govt.",
          "Gold Deposit Bonds issued under Gold Deposit Scheme, 1999",
          "Deposit Certificates issued under the Gold Monetisation Scheme, 2015",
        ]}
      />
      <Mainhead heading="IMPORTANT TERMS UNDER HEAD OF CAPITAL GAIN IN INCOME TAX ACT,1961" />
      <CustomList
        points={[
          "Full value of consideration- It is the amount that the transferor of the asset has received or will receive in lieu of the transferred asset. If the assets have been transferred against a consideration in kind, then the fair market value at the time of transfer will be taken as full value of consideration.",
          "Transfer expenses- The expenses incurred by the transferor exclusively for executing the sale or transfer of the asset are known as transfer expenses.",
          "Cost of acquisition- This is the price at which the transferor originally acquired the capital asset. If such an asset was received as a gift or inherited from the previous owner, then the cost to such previous owner will be considered as cost of acquisition.",
          "Cost of improvement- The capital expenditure incurred by the transferor for any addition or alteration made to the asset is taken as cost of improvement to be deducted from the full value of consideration.",
          "Cost inflation index- The cost inflation index is used to calculate the inflation adjusted purchase price for the purpose of computing capital gain. It is issued by the Central Board of Direct Taxes (CBDT)",
          "Holding period- Holding period refers to the duration for which the assets have been held by the transferor. It is used to determine whether the asset will be subjected to ‘long term capital gain’ or ‘short term capital gain’.",
        ]}
      />
      <Step number="Meaning of transferable or non transferable -" />
      <Box sx={{ textAlign: "center" }}>
        <Image
          component="img"
          sx={{
            content: {
              xs: `url(${Image2})`,
            },
          }}
        />
      </Box>
      <Mainhead heading="WHAT DO YOU MEAN BY TRANSFERABLE ASSET? " />
      <Step number="As per section 2(47) of income tax act, " />
      <CustomList
        points={[
          "A transferable capital asset is one that may be passed from one person to the next. This transfer can take several forms, including sale, gift, exchange, or relinquishment.",
          "When you transfer a capital asset, you may incur capital gains or losses, depending on the selling price compared to the cost of acquisition.",
        ]}
      />
      <Content
        paragraph="When delivery or possession are finished, the transfer of movable property is finished. 
A conveyance deed's registration is not necessary for the transfer of immovable property; transactions including agreements to purchase or sell any immovable property or any of its rights are also considered transfers of immovable property. 
"
      />
      <Mainhead heading="WHAT ARE THE VARIOUS TRANSFERS UNDER INCOME TAX ACT, 1961?" />
      <CustomList
        points={[
          "Exchange - The exchange of one capital asset for another capital asset or assets.",
          "Sales - A person sells his property for a consideration and transfers his right to a third party (sale deed, agreement).",
          "Relinquishment of assets - When someone transfers a capital asset without receiving payment, they give up their ownership of the asset.",
          "Reducing any right from assets - Loss of any capital assets owing to natural climate conditions without consideration.",
          "Compulsory acquisition - The legal purchase of a capital asset by the government or another authority. This covers acquisition made possible by land acquisition legislation or eminent domain.",
        ]}
      />
      <Mainhead heading="WHICH ARE NON-TRANSFERABLE ASSETS UNDER INCOME TAX ACT, 1961?" />
      <Content paragraph="Non-transferable capital assets are those assets that, by their nature or legal restrictions, cannot be easily transferred from one person to another. These assets typically do not involve a sale or exchange, which means there is no capital gains tax implication upon transfer." />
      <CustomList
        points={[
          "Transfer of capital asset by a company to its wholly-owned subsidiary company",
          "Transfer of capital assets in a scheme of amalgamation",
          "Transfer of capital asset under a gift",
          "Transfer by way of conversion of bonds or debentures into shares",
        ]}
      />
      <Mainhead heading="TAX ON TRANSFERABLE CAPITAL ASSETS:" />
      <CustomList
        points={[
          "When you sell or transfer a transferable capital asset, you may be liable to pay capital gains tax. The capital gains can be categorized into short-term or long-term, depending on the holding period of the asset.",
          "Short-term capital gains are typically taxed at a higher rate than long-term capital gains.",
        ]}
      />
      <Mainhead heading="TAX ON NON-TRANSFERABLE CAPITAL ASSETS:" />
      <Content paragraph="Non-transferable capital assets are normally exempt from capital gains tax because they cannot be sold or transferred." />
    </Box>
  );
};

export default CapitalGainComponent;
