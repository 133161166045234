import React from 'react'
import Mainhead from '../../../../Components/Services/MainContent/Mainhead';
import Content from '../../../../Components/Services/MainContent/Content';
import List from '../../../../Components/Services/MainContent/List';
import { Box, styled } from '@mui/material';
import Step from '../../../../Components/Services/MainContent/Step';

import ChecklistBanner from "../../../../Assets/Services/StartupReg/LegalFactory_StartUp1.png"

const Benefitimg = styled(Box)(({ theme }) => ({
  width: '34rem',
  height: '32rem',
  marginTop: '0.8rem',
  [theme.breakpoints.down("sm")]: {
    width: "90%",
    height: 'auto',
    objectFit: 'contain'
  },
  [theme.breakpoints.down("md")]: {
    width: '18rem',
    height: '21.5rem',
  },
  [theme.breakpoints.between("md", "lg")]: {
    width: '25rem'
  }
}));

function Checklist() {
  return (
    <div>
      <Mainhead heading="Checklist " />

      <Content paragraph="An entity shall be considered as a Startup :" />
      <ul className='list'>
        <li className='bullet'>
          <span>
            <List points="If it's Incorporated like a Private Ltd Company or as a Partnership Firm or a Liability Partnership in India

"/>
          </span>
        </li>
        <li className='bullet'>
          <span>
            <List points="Up to 10 years from the date of its incorporation/registration.
            The turnover has not exceeded INR 100 crores since the incorporation/registration"/>
          </span>
        </li>
        <li className='bullet'>
          <span>
            <List points="Any working innovation, development or improvement of products or processes or services, or if it a scalable business model with a high potential of employment generation or wealth creation" />
          </span>
        </li>
        <li className='bullet'>
          <span>
            <List points="If it possesses a DIPP number." />
          </span>
        </li>
        <li className='bullet'>
          <span>
            <List points="If it has obtained a patron guarantee from the Indian Patent and Trademark Office." />
          </span>
        </li>
        <li className='bullet'>
          <span>
            <List points="If it is funded by an incubation fund, angel fund, or private equity fund that is registered with Securities and Exchange Board of India(SEBI)" />
          </span>
        </li>
      </ul>

      <Box sx={{ textAlign: 'center' }}>
        <Benefitimg component="img"
          sx={{
            content: {
              xs: `url(${ChecklistBanner})`
            }
          }} />
      </Box>
    </div>


  )
}

export default Checklist