import Sidecomponent from "../../../Components/Income-Tax/Sidecomponent";
import Topdiv from "../../../Components/Tax-Planning/Topdiv";

import { Grid, Box, Container } from "@mui/material";

import Faq from "../../../Components/Income-Tax/Faq";
import faqData from "./faqData";

import { menuItems } from "../CapitalGain/menuItems";
import DirectAndIndirectTax from "./DirectAndIndirectTax";
import GrossSalary from "./GrossSalary";
import DearnessAllowanceBonus from "./DearnessAllowanceBonus";
import C2CvsTakeHomeSalary from "./C2CvsTakeHomeSalary";
import TaxCalculationFactors from "./TaxCalculationFactors";
import PerquisiteVsAllowance from "./PerquisiteVsAllowance";
import ArrearsOfSalary from "./ArrearsOfSalary";
import HraComponent from "./HraComponent";
import LTAComponent from "./LTAComponent";
import StandardDeductionIndia from "./StandardDeductionIndia";
import LeaveEncashmentIndia from "./LeaveEncashmentIndia";
import PensionInIndia from "./PensionInIndia";
import ProvidentFund from "./ProvidentFund";
import GratuityIndia from "./GratuityIndia";
import VRSIndiaTDSSalaryIndia from "./VRSIndiaTDSSalaryIndia";
import TaxRegimeComparison from "../CapitalGain/TaxRegimeComparison";

const Salaries = () => {
  return (
    <div>
      <Topdiv heading="Are you looking to file your Income Tax Returns ?" />
      <div className="Container">
        <Container>
          <Grid container>
            <Grid item lg={4} xl={3}>
              <Box
                style={{
                  display: { xs: "none", lg: "block" },
                  marginTop: "4rem",
                  position: "sticky",
                  top: 90,
                  marginBottom: "3rem",
                }}
              >
                <Sidecomponent menuItems={menuItems} />
              </Box>
            </Grid>

            <Grid item xs={11} lg={8} sx={{ marginTop: "4rem" }} xl={9}>
              <DirectAndIndirectTax />
              <GrossSalary />
              <DearnessAllowanceBonus />
              <C2CvsTakeHomeSalary />
              <TaxCalculationFactors />
              <PerquisiteVsAllowance />
              <HraComponent />
              <LTAComponent />
              <StandardDeductionIndia />
              <ArrearsOfSalary />
              <LeaveEncashmentIndia />
              <PensionInIndia />
              <ProvidentFund />
              <GratuityIndia />
              <VRSIndiaTDSSalaryIndia />
              <TaxRegimeComparison />
              <Faq data={faqData} />
            </Grid>
          </Grid>
        </Container>
      </div>
    </div>
  );
};

export default Salaries;
