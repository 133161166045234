import React from "react";
import { Box } from "@mui/material";
import Mainhead from "../../../Components/Services/MainContent/Mainhead";
import Content from "../../../Components/Services/MainContent/Content";
import Step from "../../../Components/Services/MainContent/Step";
import { Image } from "../../../Components/Income-Tax/ImageStyle";
import Image8 from "../../../Assets/IncomeTax/taxable3.jpg";
import ReusableTable from "../../../Components/Income-Tax/ReusableTable";

const Section80EESection80TTB = () => {
  const tableData = [
    {
      Person: "Interest on recurring deposit",
      "Individual other than senior citizen only TTA apply": "10000",
      "Senior citizen TTB apply":
        "*Limit extended from 10,000 to 50,000 and Scope of interest extends to fixed deposit and RD also",
    },
    {
      Person: "Interest on recurring deposit",
      "Individual other than senior citizen only TTA apply": "-",
      "Senior citizen TTB apply":
        "*Limit extended from 10,000 to 50,000 and Scope of interest extends to fixed deposit and RD also",
    },
    {
      Person: "Interest on recurring deposit",
      "Individual other than senior citizen only TTA apply": "-",
      "Senior citizen TTB apply":
        "*Limit extended from 10,000 to 50,000 and Scope of interest extends to fixed deposit and RD also",
    },
  ];
  return (
    <Box
      style={{
        display: "flex",
        flexDirection: "column",
        gap: 4,
        marginBottom: "2rem",
      }}
    >
      <Mainhead heading="Section 80EE" />
      <Content paragraph="Section 80EE provides income tax benefits on the interest component of a residential home loan obtained from any financial institution. This part allows you to claim a deduction of up to Rs 50,000 per financial year. You can continue to claim this deduction until you have fully repaid the loan." />
      <Box sx={{ textAlign: "center" }}>
        <Image
          component="img"
          sx={{
            content: {
              xs: `url(${Image8})`,
            },
          }}
        />
      </Box>
      <Step
        number={`
          Applicability to First-Time Homebuyers:
        `}
      />
      <Content paragraph="This section is applicable to individuals who are first-time homebuyers, i.e., those who are buying their first residential property." />
      <Step
        number={`
          Loan Criteria:
        `}
      />
      <Content paragraph="To be eligible for the deduction under Section 80EE, the individual should have taken a loan from a financial institution for the purpose of acquiring a residential property. The loan must be sanctioned by a financial institution or a housing finance company, and the loan amount should not exceed ₹35 lakhs. Additionally, the value of the residential property should not exceed ₹50 lakhs." />
      <Step
        number={`
          Maximum Deduction:
        `}
      />
      <Content paragraph="The maximum deduction allowed under Section 80EE is ₹50,000 per financial year. This deduction is in addition to the deduction available under Section 24(b) for the interest on housing loans." />
      <Step
        number={`
          Ownership of Residential Property:
        `}
      />
      <Content paragraph="The taxpayer should not own any residential house property on the date of sanction of the loan." />
      <Step
        number={`
          Loan Approval Date:
        `}
      />
      <Content paragraph="The loan for which the deduction is claimed must have been sanctioned between April 1, 2013, and March 31, 2017." />
      <Step
        number={`
          Things to Remember:
        `}
      />
      <Content paragraph="In the 2013–14 financial year, Section 80EE went into force. Only two years—FY 2013–14 and FY 2014–15—were it accessible. The previous deduction that was permitted was only available for two fiscal years and could not exceed a total of Rs 1 lakh. On the other hand, as of FY 2016–17 (AY 2017–18), this section has been back added. Therefore, until the loan is paid back, the deduction is permitted up to Rs 50,000 a year." />
      <Step
        number={`
          Residency Requirement:
        `}
      />
      <Content paragraph="Is residency required to receive this benefit? That is not made clear in this area. In light of this, it may be said that Indians whether residents or not are eligible for this deduction." />
      <Step
        number={`
          Self-Occupied Requirement:
        `}
      />
      <Content paragraph="Whether this house must be self-occupied in order to qualify for the deduction is likewise not specified in this provision. Thus, this deduction is also available to borrowers who live in rental properties." />
      <Step
        number={`
          Joint Ownership:
        `}
      />
      <Content paragraph="Additionally, people may claim the deduction individually or jointly for home purchases. Both the spouse and the individual jointly owning the home are eligible to claim this deduction if they are both making loan payments." />
      <Mainhead heading="Section 80 TTB - Deduction for Senior Citizens" />
      <Content paragraph="For senior citizens in their retirement years, the Indian government introduced Section 80 TTB under the Income Tax Act. This section offers a valuable deduction to senior citizens on the interest income earned from specified deposits, providing a financial respite for those aged 60 years and above." />
      <Content paragraph="Applicability to Senior Citizens: Section 80 TTB is tailor-made for individuals who fall under the category of resident senior citizens, specifically those aged 60 years or above." />
      <Content paragraph="Nature of Income Covered: The deduction under Section 80 TTB applies to interest income earned from specified deposits with banks, co-operative banks, or post offices." />
      <Content paragraph="Types of Deposits Included: Specified deposits encompass a range of financial instruments, including savings accounts, fixed deposits, recurring deposits, and other time deposits." />
      <Content paragraph="Maximum Deduction Limit: Senior citizens can enjoy a maximum deduction of up to ₹50,000 in a financial year through Section 80 TTB." />
      <Content paragraph="Scope of Deduction: The deduction encompasses the total interest income earned by the senior citizen during the financial year." />
      <Content paragraph="Eligibility Criteria: To avail of this deduction, senior citizens must ensure they report the interest income in their income tax return." />
      <Content paragraph="Exclusion of Other Sources: It's important to note that Section 80 TTB doesn’t cover interest income from sources other than specified deposits. Other financial instruments such as bonds, mutual funds, or similar investments are not eligible for this deduction." />
      <Content paragraph="Taxable Income Calculation: The deduction under Section 80 TTB is subtracted from the gross interest income, providing a reduced taxable interest income for senior citizens." />
      <Content paragraph="Filing of Income Tax Return: Senior citizens are required to disclose the eligible interest income and claim the deduction when filing their income tax returns." />
      <Content paragraph="Nominee or Legal Heir Benefits: In the event of the senior citizen's demise, the nominee or legal heir can also claim the deduction for any interest income earned by the deceased in the financial year." />
      <Content paragraph="Analysis of SECTION 80TTa & 80TTB -" />
      <ReusableTable data={tableData} />
      <Content paragraph="Person: Individual other than senior citizen only TTA apply, Senior citizen TTB apply" />
      <Content paragraph="Interest on saving account: 10,000, Limit extended from 10,000 to 50,000 & Scope of interest extend to fixed deposit and RD also" />
      <Content paragraph="Interest on fixed deposit: -" />
      <Content paragraph="Interest on recurring deposit: -" />
      <Content paragraph="NOTE: Other interests not covered under section TTA & TTB & further TTB apply only to resident senior citizens." />
    </Box>
  );
};

export default Section80EESection80TTB;
