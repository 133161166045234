import React from 'react';
import { Container,styled,useTheme,useMediaQuery,Box, Typography,Button,Divider,TextField,InputAdornment, Stack, Grid,TableContainer,TableRow ,Table,TableHead,TableBody,Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,} from '@mui/material';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import Background from "../../Assets/Services/Private/LegalFactory_Service.png";
import Girl from "../../Assets/Services/Private/Image.png";
import StarIcon from '@mui/icons-material/Star';
import CheckSharpIcon from '@mui/icons-material/CheckSharp';
import PDF from "../../Assets/Services/Private/Pdf.png";
import PersonOutlineIcon from '@mui/icons-material/PersonOutline';
import MailOutlineIcon from '@mui/icons-material/MailOutline';
import LocalPhoneIcon from '@mui/icons-material/LocalPhone';
import LocationCityIcon from "@mui/icons-material/LocationCity";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useDispatch } from "react-redux";
import { submitDetailsHome } from "../../Action/GetInTouch";
import CircleIcon from '@mui/icons-material/Circle';
import { useNavigate } from 'react-router-dom';




const Border = styled(Box)(({ theme }) => ({
  border:'0.5px solid #AEA598',
  width:'72%',
  marginTop:'0.6rem',
  paddingRight:'0.5rem',
 [theme.breakpoints.up("xl")]: {
   width:'70%',
   paddingLeft:'0.5rem',
   paddingTop:'0.5rem',
   paddingRight:'0.5rem'
 },
 [theme.breakpoints.down("md")]: {
   width:'97%',
   marginLeft:'0.7rem',
   paddingLeft:'0.5rem',
   paddingTop:'0.5rem',
   paddingRight:'0.5rem'
 },
 [theme.breakpoints.between("md", "lg")]: {
  width:'66%',
  marginLeft:'0.7rem',
   paddingLeft:'0.5rem',
   paddingTop:'0.5rem',
   paddingRight:'0.5rem'
},
[theme.breakpoints.down("sm")]: {
width:'88%',
paddingLeft:'0.5rem',
   paddingTop:'0.5rem',
   paddingRight:'0.5rem'
},
}));




const Formbox = styled(Box)(({ theme }) => ({
 border:'0.5px solid #AEA598',
 width:'152%',
 marginLeft:'0.8rem',
 marginTop:'0.6rem',
[theme.breakpoints.up("xl")]: {
  width:'98%',
  marginLeft:'1.5rem',
  paddingLeft:'0.5rem',
  paddingTop:'0.5rem'
},

[theme.breakpoints.down("md")]: {
 width:'96%',

},
[theme.breakpoints.between("md", "lg")]: {
width:'170%',
},
[theme.breakpoints.down("sm")]: {
width:'92%',

},
}));

const Iconhead = styled(Typography)(({ theme }) => ({
 color:'#000000',
 fontSize:'0.9rem',
 fontWeight:450,
 lineHeight:'1.6rem',
 fontStyle:'normal',
 display:'flex',
 flexDirection:'row',
 marginTop:'0.5rem',
 [theme.breakpoints.up("xl")]: {
   fontSize:'1rem',
 },
 [theme.breakpoints.down("sm")]: {
   marginLeft:'0.8rem'
 },
 [theme.breakpoints.between("md", "lg")]: {
   fontSize:'0.8rem',}
}));

const Row = styled(Box)(({ theme }) => ({
 display:'flex',
 flexDirection:'row',
 justifyContent:'flex-start'
}));

const Line = styled(Divider)(({ theme }) => ({
 border: '1px solid rgba(0, 0, 0, 0.1)',
 marginTop:'0.9rem',
 marginRight:'0.7rem'
}));

const Direction = styled(Box)(({ theme }) => ({
 display:'flex',
 flexDirection:'row',
 justifyContent:'flex-start',
 [theme.breakpoints.down("md")]: {
   flexDirection:'column',
 justifyContent:'flex-start',
 }

}));

const Icondirection = styled(Box)(({ theme }) => ({
 display:'flex',
 flexDirection:'column',
 //justifyContent:'space-between',
 marginLeft:'0.5rem',
 [theme.breakpoints.up("xl")]: {
   
 },
 [theme.breakpoints.down("sm")]: {
   flexDirection:'column'
 }
}));

const Bulletdirection = styled(Box)(({ theme }) => ({
  display:'flex',
  flexDirection:'row',
  justifyContent:'space-between',
  marginLeft:'0.5rem',
  [theme.breakpoints.up("xl")]: {
    
  },
  [theme.breakpoints.down("sm")]: {
    flexDirection:'column'
  }
 }));

const Column = styled(Box)(({ theme }) => ({
 display:'flex',
 flexDirection:'column',
 justifyContent:'flex-start'
}));

const AlignItems = styled(Box)(({ theme }) => ({
 display:'flex',
 flexDirection:'column',
 alignItems:'flex-start'
}));

const Image = styled(Box)(({ theme }) => ({
 width:'16rem',
 height:'15rem',
 marginRight:'2rem',
   [theme.breakpoints.up("xl")]: {
  width:'20.85rem',
  height:'21.05rem',
  
},
[theme.breakpoints.down("md")]: {
 width:'18rem',
  height:'17.05rem',
  marginLeft:'2rem'
},
[theme.breakpoints.down("sm")]: {
 width:'13rem',
 height:'13rem',
},
[theme.breakpoints.between("md", "lg")]: {
 width:'9.5rem',
 height:'10rem',}
}));

const Head = styled(Typography)(({ theme }) => ({
 color: theme.palette.primary.main,
 fontSize:'1.2rem',
 fontWeight:600,
 lineHeight:'2rem',
 marginTop:'0.5rem',
 marginLeft:'0.5rem',
 [theme.breakpoints.up("xl")]: {
   fontSize:'1.5rem',
   lineHeight:'2.2rem',
 },
 [theme.breakpoints.down("sm")]: {
   fontSize:'1.1rem'
 },
 [theme.breakpoints.between("md", "lg")]: {
   fontSize:'0.9rem'
 }
}));


const Formhead = styled(Typography)(({ theme }) => ({
 color: theme.palette.primary.main,
 fontSize:'1.3rem',
 fontWeight:600,
 textAlign:'center',
 lineHeight:'2rem',
 marginTop:'0.5rem',
 [theme.breakpoints.up("xl")]: {
   fontSize:'1.5rem',
   lineHeight:'2.2rem',
 },
 [theme.breakpoints.down("sm")]: {
  fontSize:'1.2rem'
 },
 [theme.breakpoints.between("md", "lg")]: {
   fontSize:'1rem'
 }
}));


const Link = styled(Typography)(({ theme }) => ({
 color: theme.palette.primary.main,
 fontSize:'0.9rem',
 fontWeight:700,
 marginTop:'0.6rem',
 marginLeft:'0.5rem',
 [theme.breakpoints.up("xl")]: {
   fontSize:'1.15rem',
   lineHeight:'2rem',
 },
 [theme.breakpoints.down("sm")]: {
   fontSize:'1rem'
 },
 [theme.breakpoints.between("md", "lg")]: {
   fontSize:'0.9rem'
 }
}));

const Secondhead = styled(Typography)(({ theme }) => ({
 color: theme.palette.primary.main,
 fontSize:'1.2rem',
 fontWeight:600,
 lineHeight:'2rem',
 marginTop:'0.5rem',
 [theme.breakpoints.up("xl")]: {
   fontSize:'1.4rem',
   lineHeight:'2rem',
 },
 [theme.breakpoints.down("sm")]: {
   fontSize:'1.1rem',
   marginLeft:'0.5rem'
 },
 [theme.breakpoints.between("md", "lg")]: {
   fontSize:'0.9rem'
 }
}));

const Linked = styled(Typography)(({ theme }) => ({
 color: theme.palette.primary.main,
 fontSize:'0.9rem',
 fontWeight:700,
 marginTop:'0.6rem',
 [theme.breakpoints.up("xl")]: {
   fontSize:'1.15rem',
   lineHeight:'2rem',
 },
 [theme.breakpoints.down("sm")]: {
   fontSize:'0.8rem',
   marginLeft:'0.5rem'
 },
 [theme.breakpoints.between("md", "lg")]: {
   fontSize:'0.8rem'
 }
}));

const Mainhead = styled(Typography)(({ theme }) => ({
 color:'#281900',
 fontSize:'1.3rem',
 fontWeight:600,
 lineHeight:'1.8rem',
 fontStyle:'normal',
 marginTop:'0.5rem',
 [theme.breakpoints.up("xl")]: {
   fontSize:'1.5rem',
   lineHeight:'2rem',
 },
 [theme.breakpoints.down("sm")]: {
   fontSize:'1.2rem',
   marginLeft:'0.5rem'
 },
 [theme.breakpoints.between("md", "lg")]: {
   fontSize:'0.985rem'
 }
}));

const Greenbutton = styled(Button)(({ theme }) => ({
 background:'#02C856',
 color:'#FDFDFD',
 width:'0.5rem',
 height:'1.6rem',
 marginTop:'0.5rem',
 "&:hover":{
 background:'#02C856',
 color:'#FDFDFD',
 },
 [theme.breakpoints.up("xl")]: {
   width:'0.8rem',
   height:'1.8rem'
 },
 [theme.breakpoints.down("sm")]: {
   marginLeft:'0.5rem'
 },
 [theme.breakpoints.between("md", "lg")]: {
   width:'0.6rem',
   height:'1.2rem'
 }
 }));

 const Orangebutton = styled(Button)(({ theme }) => ({
   background:'#E99101',
   color:'#FDFDFD',
   marginTop:'0.8rem',
   marginLeft:'0.5rem',
   marginBottom:'0.8rem',
   "&:hover":{
   background:'#E99101',
   color:'#FDFDFD',
   },
   [theme.breakpoints.up("xl")]: {
      paddingLeft:'1rem',
      paddingRight:'1rem'
   }
   }));

   
 
   const Bluebutton = styled(Button)(({ theme }) => ({
     background:'#0085FF',
     color:'#FDFDFD',
     marginLeft:'0.2rem',
     marginTop:'0.8rem',
     marginBottom:'0.8rem',
     "&:hover":{
     background:'#0085FF',
     color:'#FDFDFD',
     },
     [theme.breakpoints.up("xl")]: {
       paddingLeft:'1rem',
      paddingRight:'1rem',
      marginLeft:'1rem',
     }
     }))

   

 const Greenhead = styled(Typography)(({ theme }) => ({
   color:'#02C856',
   fontSize:'0.9rem',
   fontWeight:600,
   lineHeight:'1.8rem',
   fontStyle:'normal',
   marginLeft:'1rem',
   marginTop:'0.4rem',
   [theme.breakpoints.up("xl")]: {
     fontSize:'1rem',
     lineHeight:'2rem',
   },
   [theme.breakpoints.down("sm")]: {
     fontSize:'0.8rem'
   },
   [theme.breakpoints.between("md", "lg")]: {
     fontSize:'0.7rem'
   }
 }));

 const Body = styled(Typography)(({ theme }) => ({
   color:'#000000',
   fontSize:'0.9rem',
   fontWeight:450,
   lineHeight:'1.8rem',
   fontStyle:'normal',
   marginTop:'0.5rem',
   [theme.breakpoints.up("xl")]: {
     fontSize:'1rem',
     lineHeight:'2rem',
   },
   [theme.breakpoints.down("sm")]: {
     marginLeft:'0.8rem'
   },
   [theme.breakpoints.between("md", "lg")]: {
     fontSize:'0.8rem',
     lineHeight:'1.5rem'
   }
 }));
 
 
 
 

 const Formfield = styled(TextField)(({ theme }) => ({
   width:'90%',
   marginLeft:'0.6rem',
   marginTop:'0.8rem',
   [theme.breakpoints.between("md", "lg")]: {
     width:'85%'
   }
 }));

 const Back = styled(Box)(({ theme }) => ({
   backgroundImage: `url(${Background})`,
   backgroundRepeat: 'no-repeat',
   backgroundSize: '85% 100%',
   marginTop:'10px',
   marginLeft:'10px',
   [theme.breakpoints.down("sm")]: {
     backgroundSize: '96% 100%'
   },
   [theme.breakpoints.down("md")]: {
     backgroundSize: '97% 180%'
   }
 }));


  

const Heading = (props) => {
  const navigate = useNavigate();
  const [checked, setChecked] = React.useState(false);
  const [open, setOpen] = React.useState(false);
  const dispatch = useDispatch();
  const handleClose = () => {
    setOpen(false);
  };

  const handleChange = (event) => {
    setChecked(event.target.checked);
  };
  const clickTerms = () => {
    navigate('/termsandcondition');
  }
  const phoneRegExp =
    /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;

  const validate = Yup.object().shape({
    name: Yup.string()
      .min(2, "Too Short!")
      .max(20, "Too Long!")
      .required("Required"),
    email: Yup.string().email("Invalid email").required("Required"),
    city: Yup.string()
      .min(2, "Too Short!")
      .max(20, "Too Long!")
      .required("Required"),
    phoneNumber: Yup.string()
      .matches(phoneRegExp, "Phone Number is not valid")
      .min(5, "Too Short!")
      .max(10, "Too Long!")
      .required("Required"),
  });

  const formik = useFormik({
    initialValues: {
      name: "",
      email: "",
      city: "",
      phoneNumber: "",
    },
    validationSchema: validate,
    onSubmit: (values,onSubmitProps) => {
      dispatch(
        submitDetailsHome({
          Name: values.name,
          Email: values.email,
          City: values.city,
          Phone: values.phoneNumber,
          Query: "",
          AgentCode: 0,
          GST: 0,
          EnquiryDate: new Date(),
          InvoiceNumber: 0,
          Invoice: "",
          LastModified: "",
          OTP: 0,
          OTPGenerated: "",
          OTPVerified: false,
          Password: "",
          Payment:0,
          PaymentLink: "",
          PaymentMode: "",
          ProfilePic: "",
          Service: props.service,
          Source: "Web",
          FormJSON: {
            name: "nil",
            gmail: "nil",
          },
          Status: "In Progress",
          Type: "Lead",
          createdAt: new Date()
        })
      );
      setOpen(true);
      onSubmitProps.resetForm()
    },
  });

  const Background = styled(Box)(({ theme }) => ({

    width: '100%',
    height: '100%',
    backgroundColor: 'rgb(250, 250, 250)',

}));

  return (
    <Background>
    <div className='Container'>
      <Container>
      <Grid container 
      display="flex" flexDirection={{xs:'column',md:'row'}}>
        <Border>
          <Direction>
          <Column>
          <Back>
            <AlignItems>
            <Image component="img"
                sx={{
                  content: {
                    xs: `url(${Girl})`
                  }
                }}/>
                </AlignItems>
                </Back>
                {/*
          <Head>Documents Required</Head>
          <Subhead>PAN Card</Subhead>
            <Subhead>Passport (For Foreign Nationals only)</Subhead>
              <Subhead>Aadhaar Card</Subhead>*/}
              <Head>About This Item</Head>
           <Icondirection>
           <Column>
           <Iconhead><CheckSharpIcon sx={{color:'#34A853'}}/>Company registration</Iconhead>
           <Iconhead><CheckSharpIcon sx={{color:'#34A853'}}/>Share Certificates</Iconhead>
           </Column>
           <Column>
           <Iconhead><CheckSharpIcon sx={{color:'#34A853'}}/>Current Account Opening</Iconhead>
           <Iconhead><CheckSharpIcon sx={{color:'#34A853'}}/>2 Digital Signatures</Iconhead>
           </Column>
              </Icondirection>
            {/*<Link><u>{props.document}</u></Link>*/}
            </Column>
            <Column>
            <Mainhead> {props.heading}</Mainhead>
            <Row>
          <Greenbutton startIcon={<StarIcon/>}>{props.rating}</Greenbutton>
          <Greenhead>{props.clients}</Greenhead>
            </Row>
            <Body>{props.description}</Body>
           {/*<Linked>Complete By* : 31-JAN-2023</Linked>*/}
           
           {/*
           <Secondhead>About This Item</Secondhead>
           <Icondirection>
           <Column>
           <Iconhead><CheckSharpIcon sx={{color:'#34A853'}}/>Company registration</Iconhead>
           <Iconhead><CheckSharpIcon sx={{color:'#34A853'}}/>Share Certificates</Iconhead>
           </Column>
           <Column>
           <Iconhead><CheckSharpIcon sx={{color:'#34A853'}}/>Current Account Opening</Iconhead>
           <Iconhead><CheckSharpIcon sx={{color:'#34A853'}}/>2 Digital Signatures</Iconhead>
           </Column>
              </Icondirection>*/}
             
           <br/>
           <Linked ><u><a href='/termsandcondition' style={{color:'orange'}}>Terms & Conditions</a></u></Linked>
          
           <Line/>
           <Secondhead>Pricing</Secondhead>
           
           <Body>{props.priceinfo}<span style={{color:'red',marginLeft:'4px'}}>Rs 4999/-</span></Body>
           
           {/*

           <Secondhead>Offers & Discounts</Secondhead>
           <Squarebox>
            <Row>
           <Pdf component="img"
                sx={{
                  content: {
                    xs: `url(${PDF})`
                  }
                }}/>
                <Column>
                <Smallhead>Invoicing Platform Support</Smallhead>
                <Subhead>Get free 18 month plan </Subhead>
                </Column>
                </Row>
           </Squarebox>
           <Squarebox>
            <Row>
           <Pdf component="img"
                sx={{
                  content: {
                    xs: `url(${PDF})`
                  }
                }}/>
                <Column>
                <Smallhead>Invoicing Platform Support</Smallhead>
                <Subhead>Get free 18 month plan </Subhead>
                </Column>
                </Row>
           </Squarebox>
              <br/>*/}
            </Column>
              </Direction>
           </Border>
           <Grid md={2} xl={3} lg={2} >
           <Formbox>
            <Formhead>Get In Touch</Formhead>
            <form onSubmit={formik.handleSubmit}>
            <Formfield
        variant='outlined'
       placeholder='Name'
       size='small'
       margin='dense'
       id="name"
            name="name"
       value={formik.values.name}
            onChange={formik.handleChange}
            error={formik.touched.name && Boolean(formik.errors.name)}
            helperText={formik.touched.name && formik.errors.name}
        InputProps={{
          disableUnderline: true,
          startAdornment: (
            <InputAdornment position="start">
              <PersonOutlineIcon fontSize='small'
                sx={{
                  color: "#AEA598",
                }}
              />
            </InputAdornment>
          ),
        }}
        
        />
        <Formfield
        variant='outlined'
       placeholder='E-mail'
       size='small'
       margin='dense'
       id="email"
            name="email"
            value={formik.values.email}
            onChange={formik.handleChange}
            error={formik.touched.email && Boolean(formik.errors.email)}
            helperText={formik.touched.email && formik.errors.email}
        InputProps={{
          disableUnderline: true,
          startAdornment: (
            <InputAdornment position="start">
              <MailOutlineIcon  fontSize='small'
                sx={{
                  color: "#AEA598",
                }}
              />
            </InputAdornment>
          ),
        }}
        
        />

<Formfield
        variant='outlined'
       placeholder='City'
       type='text'
       size='small'
       id="city"
            name="city"
       margin='dense'
       value={formik.values.city}
            onChange={formik.handleChange}
            error={formik.touched.city && Boolean(formik.errors.city)}
            helperText={formik.touched.city && formik.errors.city}
        InputProps={{
          disableUnderline: true,
          startAdornment: (
            <InputAdornment position="start">
              <LocationCityIcon fontSize='small'
                sx={{
                  color: "#AEA598",
                }}
              />
            </InputAdornment>
          ),
        }}
        
        />

        <Formfield
        variant='outlined'
       placeholder='Phone'
      // type='number'
       size='small'
       id="phoneNumber"
            name="phoneNumber"
       margin='dense'
       value={formik.values.phoneNumber}
            
            onChange={formik.handleChange}
            error={
              formik.touched.phoneNumber && Boolean(formik.errors.phoneNumber)
            }
            helperText={formik.touched.phoneNumber && formik.errors.phoneNumber}
            inputProps={{ inputMode: 'numeric', pattern: '[0-9]*', maxLength:10 }}
        InputProps={{
          disableUnderline: true,
          startAdornment: (
            <InputAdornment position="start">
              <LocalPhoneIcon fontSize='small'
                sx={{
                  color: "#AEA598",
                }}
                
              />
            </InputAdornment>
          ),
          
        }}
        
        />
        
          <Orangebutton variant='contained' size='small' type="submit">Submit</Orangebutton>
          <a href=' https://calendly.com/legalfactory/free-consultation?month=2023-08'><Bluebutton variant='contained' size='small'>Talk to Advisor</Bluebutton></a>
          </form>
        </Formbox>
        <Dialog
              open={open}
              onClose={handleClose}
              aria-labelledby="alert-dialog-title"
              aria-describedby="alert-dialog-description"
            >
              <DialogTitle id="alert-dialog-title">
                {"THANK YOU FOR CONTACTING US"}
              </DialogTitle>
              <DialogContent>
                <DialogContentText id="alert-dialog-description">
                  We will get back to you soon
                </DialogContentText>
              </DialogContent>
              <DialogActions>
                <Button onClick={handleClose}>Close</Button>
              </DialogActions>
            </Dialog>
        <br/>
        <Formbox>
          <Box sx={{mx:1.5}}>
        <Secondhead>Documents Required</Secondhead>
              <Bulletdirection>
                <Column>
          <Iconhead><CheckSharpIcon sx={{color:'#34A853'}}/>PAN Card</Iconhead>
            <Iconhead><CheckSharpIcon sx={{color:'#34A853'}}/>Passport </Iconhead>
           
              <Iconhead><CheckSharpIcon sx={{color:'#34A853'}}/>Aadhaar Card</Iconhead>
              
              </Column>
              </Bulletdirection>
              <Link><u>{props.document}</u></Link>
             
        </Box>
      </Formbox>
        </Grid>
        </Grid>
       

        
        
        
       
      </Container>
    </div>
    </Background>
  )
}

export default Heading;
