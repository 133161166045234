import React from "react";
import { Box } from "@mui/material";
import Mainhead from "../../../Components/Services/MainContent/Mainhead";
import Content from "../../../Components/Services/MainContent/Content";
import CustomList from "../../../Components/Income-Tax/CustomList";
import Step from "../../../Components/Services/MainContent/Step";

const GrossSalary = () => {
  return (
    <Box
      style={{
        display: "flex",
        flexDirection: "column",
        gap: 4,
        marginBottom: "2rem",
      }}
    >
      <Mainhead heading="Gross Salary in India" />

      <Step number={`Introduction`} />
      <Content paragraph="Salary is a predetermined amount of money paid by an employer to an employee for their work, usually calculated on a monthly, bi-weekly, or annual basis. It's a form of compensation agreed upon in an employment contract or as per industry standards for the position. In India, the Indian government, with the Finance Ministry, has created two tax regimes, the old tax scheme and the new tax scheme, for the citizens to choose and pay taxes." />

      <Step number={`Definition`} />
      <Content paragraph="As per the Income Tax Act 1961 in India, the amount paid out to an employee before any voluntary and mandatory deductions are made from it is known as Gross Salary. The Gross salary also includes the salary paid by the employer to an individual or any other benefit, service. If an employee is earning any income over the income paid by the employer, the gross salary also includes such earnings before any tax and deduction." />

      <Step number={`Elements of Gross Salary`} />
      <CustomList
        points={[
          "Basic salary - It is the fixed amount paid by the employer to employees. The basic salary does not include any benefit, bonus derived from the service.",
          "Commission - Commissions are a form of pay remuneration for services rendered or products sold.",
          "House rent allowance - Enterprises pay a certain amount to employees as a house rent allowance to cover their house rent.",
          "Perquisites - Perquisites are monetary or non-monetary benefits employers offer over the basic salary and allowances.",
          "Professional tax - Professional tax imposed by the state government on the gross salary of employees in India. Professional tax charge 2500 Rs. per annum in certain states.",
          "Bonus - A sum of money (cash or non-cash) added to individual wages as a reward for good performance.",
        ]}
      />

      <Step number={`Elements not included in Gross Salary`} />
      <CustomList
        points={[
          "Gratuity",
          "Leave encashment",
          "Travel leave concession",
          "Free meal",
        ]}
      />
      <Step number={`Formula Of Gross Salary`} />
      <Content paragraph="In the calculation of Gross Salary, we will add the employee's basic salary and all allowances and perquisites. It is the income before the taxpayer pays any deductions." />

      <Step number={`GROSS SALARY STRUCTURE`} />
      <CustomList
        points={[
          "Basic salary",
          "Fees",
          "Commission",
          "Bonus",
          "Advance salary/arrear salary",
          "Retirement benefits -",
          "Gratuity",
          "Leave encashment",
          "Pension",
          "Retrenchment compensation",
          "Voluntary retirement scheme (VRS)",
          "Allowance",
          "Fully taxable - Dearness allowance, medical allowance, telephone allowances, etc.",
          "Exempt allowance for official purposes - daily allowance, conveyance, etc.",
          "Exempt up to a certain limit ➖ Children allowance, house rent allowance, entertainment allowance, running allowance, etc.",
          "Perquisites",
          "House rent accommodation, car facility, free food facility, etc.",
          "Gross salary",
        ]}
      />

      <Step number={`Example`} />
      <Content paragraph="Example: Basic salary Rs 20,000, House rent allowance Rs 5,286, Commission Rs 1,000, Bonus Rs 1,200. Gross salary will be Gross salary: Rs 20,000 + Rs 5,286 + Rs 1,000 + 1,200 = Rs 27,486" />

      <Step number={`Is Basic Salary Same As The Gross Salary?`} />
      <CustomList
        points={[
          "NO, THERE IS A SLIGHT DIFFERENCE AS MENTIONED BELOW",
          "Basic salary is the amount an employee receives from an employer without adding any allowances and benefits.",
          "Gross salary is a sum of total amounts, including allowances and benefits but before tax and deduction.",
          "Basic salary is the core of the salary received by an employee.",
          "Gross salary includes all types of pay and benefits such as bonus, commissions, and allowances.",
        ]}
      />

      <Step number={`Is Gross Salary Same As Net Salary?`} />
      <CustomList
        points={[
          "NO, THERE IS A SLIGHT DIFFERENCE AS MENTIONED BELOW",
          "Gross salary includes benefits and allowances - Basic salary + HRA + Perquisites, etc.",
          "Net salary = Gross salary - all deductions (standard deduction, professional tax, etc.)",
          "All taxes are included in the employee’s gross salary.",
          "No taxes are included in the employee’s net salary.",
        ]}
      />
    </Box>
  );
};

export default GrossSalary;
