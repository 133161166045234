import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import {
  Container,
  Typography,
  styled,
  Card,
  Box,
  CardMedia,
  CardContent,
  Grid,
} from "@mui/material";
import Avatar from "../../../Assets/Home/Common/LegalFactory_Home2.png";
import Comma from "../../../Assets/Home/Common/LegalFactory_Home3.png";
import Heading from "../../../Components/CommonCss/Home/Heading";
import Content from "../../../Components/CommonCss/Home/Content";

const Testimonials = () => {
  const Background = styled(Box)(({ theme }) => ({
    backgroundColor: "rgb(242, 242, 242)",
    width: "100%",
    height: "100%",
  }));

  const Review = styled(Card)(({ theme }) => ({
    background: "FAFCFF",
    //height: '100%',
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    marginTop: theme.spacing(20),
    margin: "auto",
    [theme.breakpoints.between("sm", "lg")]: {
      maxWidth: 390,
    },
    [theme.breakpoints.up("lg")]: {
      maxWidth: "88%",
    },

    [theme.breakpoints.down("sm")]: {
      maxWidth: 290,
    },
  }));

  const Head = styled(Typography)(({ theme }) => ({
    fontWeight: "bolder",
    fontSize: "1rem",
    textAlign: "left",
    marginLeft: "20px",
    color: "black",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    [theme.breakpoints.up("xl")]: {
      fontSize: "1.5rem",
    },
  }));

  const Image = styled(CardMedia)(({ theme }) => ({
    height: "32%",
    width: "97%",
    marginTop: "65px",
    objectFit: "contain",
    //marginRight: "20px",
    // marginLeft:"45%",
    [theme.breakpoints.down("lg")]: {
      height: "25%",
      width: "110%",
      marginTop: "65px",
      objectFit: "contain",
      //marginRight: "5px",
    },
    [theme.breakpoints.up("xl")]: {
      height: "40%",
      //marginLeft:"15%",
      width: "105%",
      marginTop: "65px",
      objectFit: "contain",
      // marginRight: "12px",
    },

    [theme.breakpoints.down("sm")]: {
      height: "20%",
      width: "110%",
    },
  }));
  const Secondimg = styled(CardMedia)(({ theme }) => ({
    height: "40px",
    width: "55px",
    marginTop: "8px",
    marginLeft: "5px",
    [theme.breakpoints.down("sm")]: {
      width: "40px",
      height: "30px",
    },
  }));
  const Display = styled(Box)(({ theme }) => ({
    display: "flex",
    flexFlow: "row ",
    flexDirection: "row-reverse",
  }));

  const Content = styled(CardContent)(({ theme }) => ({
    color: "#000000",
    fontSize: "0.8rem",
    fontWeight: 400,
    lineHeight: "1.8rem",
    marginTop: "0.5rem",
    [theme.breakpoints.down("sm")]: {
      marginLeft: "0.6rem",
      lineHeight: "1.2rem",
      fontSize: "0.7rem",
    },

    [theme.breakpoints.between("md", "lg")]: {
      fontSize: "0.75rem",
      lineHeight: "1.8rem",
    },

    [theme.breakpoints.up("xl")]: {
      fontSize: "0.93rem",
      lineHeight: "1.8rem",
    },
  }));

  const settings = {
    dots: true,
    infinite: true,
    className: "center",
    slidesToShow: 2,
    slidesToScroll: 2,
    initialSlide: 0,
    autoplay: true,
    speed: 5000,
    autoplaySpeed: 5000,
    arrows: false,

    responsive: [
      {
        breakpoint: 800,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };
  return (
    <>
      <Background>
        <div>
          <Container>
            <br />
            <Heading mainhead="Listen What Customers Say" />
            <br />
            <br />

            <Slider {...settings}>
              <div>
                <Review>
                  <Grid container display="flex" flexDirection="row">
                    <Grid item xs={9.5}>
                      <Secondimg image={Comma} />
                      <Content>
                        I highly recommend Legal Factory for their exceptional
                        financial services. They handled our Income Tax filing
                        with utmost professionalism, ensuring accuracy and
                        maximizing our tax savings. Thank you for being our
                        reliable financial partner!
                      </Content>
                      <Head>Kamal Singh</Head>
                    </Grid>
                    <Grid item xs={2}>
                      <Image image={Avatar} />
                    </Grid>
                  </Grid>
                </Review>
              </div>

              <div>
                <Review>
                  <Grid container display="flex" flexDirection="row">
                    <Grid item xs={9}>
                      <Secondimg image={Comma} />

                      <Content>
                        {" "}
                        Their team made the GST registration process seamless,
                        simplifying a complex procedure for me. The Company
                        registration services provided by Legal Factory were
                        prompt, and they answered all my questions with clarity.
                      </Content>
                      <Head>Kamlesh Singh</Head>
                    </Grid>
                    <Grid item xs={2}>
                      <Image image={Avatar} />
                    </Grid>
                  </Grid>
                </Review>
              </div>

              <div>
                <Review>
                  <Grid container display="flex" flexDirection="row">
                    <Grid item xs={9}>
                      <Secondimg image={Comma} />
                      <Content>
                        Main Legal Factory ko mere financial needs ke liye
                        choose karke bahut khush hua. Unhone mujhe tax planning
                        mein expert advice di jiski wajah se maine apni taxes
                        optimize ki. Unki team bohot efficient thi aur unhone
                        har step ko guide kiya.{" "}
                      </Content>
                      <Head>Rajesh Joshi</Head>
                    </Grid>
                    <Grid item xs={2}>
                      <Image image={Avatar} />
                    </Grid>
                  </Grid>
                </Review>
              </div>
              <div>
                <Grid item>
                  <Review>
                    <Grid container display="flex" flexDirection="row">
                      <Grid item xs={9}>
                        <Secondimg image={Comma} />
                        <Content>
                          Maine Legal Factory ke financial services ka istemal
                          kiya aur mujhe bahut khushi hai. Unki team ne mere
                          Income Tax filing ko bohot professionalism se handle
                          kiya, ensuring ki meri tax savings maximize ho, Legal
                          Factory trustworthy financial services hai.
                        </Content>

                        <Head>Pritam Sharma</Head>
                      </Grid>
                      <Grid item xs={2}>
                        <Image image={Avatar} />
                      </Grid>
                    </Grid>
                  </Review>
                </Grid>
              </div>
            </Slider>

            <br />
            <br />
          </Container>
        </div>
        <br />
        <br />
      </Background>
    </>
  );
};

export default Testimonials;
