import React from 'react'
import Mainhead from '../../../../Components/Services/MainContent/Mainhead';
import Content from '../../../../Components/Services/MainContent/Content';
import List from '../../../../Components/Services/MainContent/List';
import { Box, styled } from '@mui/material';
import Step from '../../../../Components/Services/MainContent/Step';

function Documents() {
    return (
        <div>
            <Mainhead heading="Basic Documents Required " />
            <ul className='list'>
                <li className='bullet'>
                    <span>
                        <List points="Self-attested copy of PAN Card." />
                    </span>
                </li>
                <li className='bullet'>
                    <span>
                        <List points="Self-attested copy of Driver’s License/Voter ID/Aadhaar Card/Passport." />
                    </span>
                </li>
                <li className='bullet'>
                    <span>
                        <List points="Self-attested copy Bank Statement/Telephone Bill/Mobile Bill/ Electricity Bill." />
                    </span>
                </li>
                <li className='bullet'>
                    <span>
                        <List points="Passport-size Photograph." />
                    </span>
                </li>
                <li className='bullet'>
                    <span>
                        <List points="Specimen Signature Certificate." />
                    </span>
                </li>
            </ul>

        </div>
    )
}
export default Documents