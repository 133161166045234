import React from "react";
import logo from "../../Assets/Logo/LegalFactory_Logo1.png";
import { Link } from "react-router-dom";

const Logo = () => (
  <Link to="/">
    <img src={logo} alt="logo" className="logo" />
  </Link>
);

export default Logo;
