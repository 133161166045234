import { Box, styled } from "@mui/material";
export const Image = styled(Box)(({ theme }) => ({
  marginTop: "30px",
  width: "75%",
  marginBottom: "3rem",
  justifyContent: "center",
  [theme.breakpoints.down("md")]: {
    marginTop: "20px",
    width: "100%",
  },
}));
