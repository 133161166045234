import React from 'react'
import Sidecomponent from '../../../Components/Tax-Planning/Sidecomponent';
import Topdiv from "../../../Components/Tax-Planning/Topdiv"
import GetInTouch from '../../../Components/Services/GetInTouch';

import Mainhead from '../../../Components/Services/MainContent/Mainhead';
import Content from '../../../Components/Services/MainContent/Content';
import List from '../../../Components/Services/MainContent/List';
import Step from '../../../Components/Services/MainContent/Step';

import SalaryTypes from '../../../Assets/TaxPlanning/Salaries/SalaryTypes.png';
import SalaryPension from '../../../Assets/TaxPlanning/Salaries/SalaryPension.png';

import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import Faq from '../Salaries/Faq';

import {
  Grid, Box, Container, styled, Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Link
} from '@mui/material';

function createData(
  Particulars, Amount
) {
  return {
  Particulars, Amount
  };
}

function createDataSPF(
  Particulars, TaxTreatment
) {
  return {
  Particulars, TaxTreatment
  };
}

function createDataRPF(
  Particulars, TaxTreatment
) {
  return {
  Particulars, TaxTreatment
  };
}

function createDataUPF(
  Particulars, TaxTreatment
) {
  return {
  Particulars, TaxTreatment
  };
}

function createDataDR(
  Particulars, TaxTreatment
) {
  return {
  Particulars, TaxTreatment
  };
}

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: '#773106',
    color: theme.palette.common.white,
    textAlign: 'left',
    fontFamily: 'Sora',
    fontWeight: 'bold',



  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 16,
    textAlign: 'left',
    textAlign: 'left',
    fontFamily: 'Sora',
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: theme.palette.action.hover,
    fontFamily: 'Sora'
  },
  // hide last border
  '&:last-child td, &:last-child th': {
    border: 0,
  },
}));

const rows = [
  createData('Income from salary','(XXX)'),
  createData('Income by way of allowances','(XXX)'),
  createData('Value of Perquisites','(XXX)'),
  createData('GROSS SALARY','(XXX)'),
  createData('Less: Deduction u/s 16',''),
  createData('(I)Standard Deduction','(XXX)'),
  createData('(II)Entertainment Allowances','(XXX)'),
  createData('(III)Professional tax','(XXX)'),
  createData('TAXABLE SALARY','(XXX)'),
];

const rowsSPF = [
  createDataSPF('Employer’s contribution','Fully exempt from tax in the hands of the employee.'),
  createDataSPF('Employee’s contribution','Allowed as deduction u/s 80C.'),
  createDataSPF('Interest from fund','	Fully exempt from tax in the hands of the employee.'),
  createDataSPF('Withdrawals from fund','Fully exempt from tax in the hands of the employee'),
];

const rowsRPF = [
  createDataRPF('Employer’s contribution','Exempt upto 12% of the employee’s retirement benefit salary (*)'),
  createDataRPF('Employee’s contribution','Allowed as deduction u/s 80C'),
  createDataRPF('Interest from fund','Exempt upto 9.5% p.a.'), 
];

const rowsUPF = [
  createDataUPF('Employer’s contribution','Fully exempt from tax in the hands of the employee.'),
  createDataUPF('Employee’s contribution','No deduction u/s 80C'),
  createDataUPF('Interest from fund','	Fully exempt from tax in the hands of the employee.'), 
];

const rowsDR = [
  createDataDR('Fully exempt from tax in the hands of the employee','Taxable under Salary'),
  createDataDR('Employee contribution and interest on employee contribution','Employee contribution is not taxable, however interest on employee contribution is taxable under income from other sources.'),
];





function Salaries() {

  const TypeImage  = styled(Box)(({ theme }) => ({
    width:'28,5rem',
    height:'32rem',
    marginTop:'0.8rem',
    [theme.breakpoints.down("md")]: {
      width:'18rem',
    height:'21.5rem',
    },
  }));

  const PensionImage  = styled(Box)(({ theme }) => ({
    width:'28,5rem',
    height:'32rem',
    marginTop:'0.8rem',
    [theme.breakpoints.down("md")]: {
      width:'18rem',
    height:'21.5rem',
    },
  }));

  const Bottomspace = styled(Box)(({ theme }) => ({
    marginBottom:'2.1rem'
  }));


  return (
    <div>
     <Topdiv heading="Are you looking to file your Income Tax Returns ?"/>
     <div className='Container'>
      <Container>
      <Grid container>
          <Grid item  lg={4} xl={3}>
            <Box sx={{ display: { xs: 'none', md: 'block' }, marginTop: '4rem', position: 'sticky', top: 90, marginBottom: '3rem' }}>
              <Sidecomponent />
            </Box>
          </Grid>

          <Grid item xs={11} lg={8} sx={{ marginTop: '4rem' }} xl={9}>

            <Mainhead heading="What is salary ?" />
            <Content paragraph="Anything received by an employee from an employer in cash, kind or as a facility [perquisite] is considered as salary."/>
            <Bottomspace/>
            <Mainhead heading="Allowances"/>
            <Content paragraph="Allowances are fixed periodic amounts, apart from salary, which are paid by an employer for the purpose of meeting some particular requirements of the employee. E.g., House Rent Allowances (HRA)"/>
            <Bottomspace/>
            <Mainhead heading="Computation Of Salary Income"/>
            <Content paragraph="Format for computation of salary income"/>
            <br/>

            <TableContainer component={Paper}>
                <Table  aria-label="customized table"  size='small'>
        <TableHead>
          <TableRow>
            <StyledTableCell>Particulars </StyledTableCell>
            <StyledTableCell>Amount </StyledTableCell>
           
           
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.map((row) => (
            <StyledTableRow
              key={row.name}
            
            >
              <StyledTableCell component="th" scope="row"
              >
                {row.Particulars}
              </StyledTableCell>
              <StyledTableCell>{row.Amount}</StyledTableCell>
              </StyledTableRow>
          ))}
        </TableBody>
      </Table>
      </TableContainer>
      <br/><br/>

      <Mainhead heading="House Rent allowance (HRA)"/>
      <Content paragraph="Least/minimum of the following is exempt (Not taxable/deducted from total HRA received)"/>
      <ul className="list">
      <li className='bullet'>
        <span>
      <List points="Actual amount of HRA received"/>
      </span>
      </li>
      <li className='bullet'>
        <span>
      <List points="Rent paid Less 10% of salary"/>
      </span>
      </li>
      <li className='bullet'>
        <span>
      <List points="50% of salary if metro cities (Kolkata/Chennai/ Mumbai / Delhi) , 40% for other cities."/>
      </span>
      </li>
      </ul>
      <Bottomspace/>
      <Mainhead heading="Conveyance allowance"/>
      <Content paragraph="Conveyance allowance is exempt to the extent of amount received or amount spent, whichever is less."/><br/><br/>

      <Mainhead heading="Fixed Medical allowance"/>
      <Content paragraph="Medical allowance is a fixed allowance paid to the employees of a company on a monthly basis, irrespective of whether they submit the bills to substantiate the expenditure or not. It is fully taxable in the hands of the employee"/>
       <Bottomspace/>
      <Mainhead heading="LEAVE TRAVEL ALLOWANCE"/>
      <Content paragraph="Leave travel is paid by the employer to cover employee travel expenses,while employees go on leave with or without family. employer cover the expenses while employee incurred expenses on travel"/>
       <Bottomspace/>
      <Mainhead heading="Types of Provident Fund"/><br/>

      <Box sx={{textAlign:'center'}}>
      <TypeImage component="img"
                sx={{
                  content: {
                    xs: `url(${SalaryTypes})`
                  }
                }}/>
                </Box>
                <br/><br/><br/>
    
    <Mainhead heading="Treatment of the amount deposited in the Statutory Provident Fund"/> <br/>

    <TableContainer component={Paper}>
                <Table  aria-label="customized table"  size='small'>
        <TableHead>
          <TableRow>
            <StyledTableCell>Particulars </StyledTableCell>
            <StyledTableCell>Tax Treatment</StyledTableCell>
           
           
          </TableRow>
        </TableHead>
        <TableBody>
          {rowsSPF.map((row) => (
            <StyledTableRow
              key={row.name}
            
            >
              <StyledTableCell component="th" scope="row"
              >
                {row.Particulars}
              </StyledTableCell>
              <StyledTableCell>{row.TaxTreatment}</StyledTableCell>
              </StyledTableRow>
          ))}
        </TableBody>
      </Table>
      </TableContainer>
      <br/><br/><br/>

      <Mainhead heading="Treatment of contribution made to a Recognized Provident Fund"/> <br/>

      <TableContainer component={Paper}>
                <Table  aria-label="customized table"  size='small'>
        <TableHead>
          <TableRow>
            <StyledTableCell>Particulars </StyledTableCell>
            <StyledTableCell>Tax Treatment</StyledTableCell>
           
           
          </TableRow>
        </TableHead>
        <TableBody>
          {rowsRPF.map((row) => (
            <StyledTableRow
              key={row.name}
            
            >
              <StyledTableCell component="th" scope="row"
              >
                {row.Particulars}
              </StyledTableCell>
              <StyledTableCell>{row.TaxTreatment}</StyledTableCell>
              </StyledTableRow>
          ))}
        </TableBody>
      </Table>
      </TableContainer>
      <br/><br/>

      <Mainhead heading="Retirement Benefit Salary shall include:"/>
      <ul className='list'>
        <li className='bullet'>
          <span>
      <List points="Basic pay"/>
      </span>
      </li>
      <li className='bullet'>
          <span>
      <List points="Dearness allowance if the terms of employment provided so"/>
      </span>
      </li>
      <li className='bullet'>
          <span>
      <List points="Commission if it is paid as a fixed percentage of turnover"/>
      </span>
      </li>
      </ul>
      <b>Note: </b> Fixed commission shall not form part of Retirement Benefit Salary.<br/> <br/>
      <br/>

      <Mainhead heading="Treatment of contribution made to an Unrecognized Provident Fund"/> <br/>

      <TableContainer component={Paper}>
                <Table  aria-label="customized table"  size='small'>
        <TableHead>
          <TableRow>
            <StyledTableCell>Particulars </StyledTableCell>
            <StyledTableCell>Tax Treatment</StyledTableCell>
           
           
          </TableRow>
        </TableHead>
        <TableBody>
          {rowsUPF.map((row) => (
            <StyledTableRow
              key={row.name}
            
            >
              <StyledTableCell component="th" scope="row"
              >
                {row.Particulars}
              </StyledTableCell>
              <StyledTableCell>{row.TaxTreatment}</StyledTableCell>
              </StyledTableRow>
          ))}
        </TableBody>
      </Table>
      </TableContainer>
      <br/><br/>

      <Mainhead heading="On Death/ Retirement amount withdrawn:"/> <br/>

      <TableContainer component={Paper}>
                <Table  aria-label="customized table"  size='small'>
        <TableHead>
          <TableRow>
            <StyledTableCell>Particulars </StyledTableCell>
            <StyledTableCell>Tax Treatment</StyledTableCell>
           
           
          </TableRow>
        </TableHead>
        <TableBody>
          {rowsDR.map((row) => (
            <StyledTableRow
              key={row.name}
            
            >
              <StyledTableCell component="th" scope="row"
              >
                {row.Particulars}
              </StyledTableCell>
              <StyledTableCell>{row.TaxTreatment}</StyledTableCell>
              </StyledTableRow>
          ))}
        </TableBody>
      </Table>
      </TableContainer>
      <br/><br/>

      <Mainhead heading="Is standard deduction applicable to family pensioners?"/>
      <Content paragraph="​​​​Section 16(ia) has been introduced by the Finance Act, 2018 for a class of person whose income is chargeable to tax under head salary. Family Pension is taxable under the head income from other sources. Hence standard deduction is not applicable in case of Family Pension."/>
         <Bottomspace/>
      <Mainhead heading="Treatment of a pension:"/> <br/>

      <Box sx={{textAlign:'center'}}>
      <PensionImage component="img"
                sx={{
                  content: {
                    xs: `url(${SalaryPension})`
                  }
                }}/>
                </Box>
        <ul className='list'>
          <li className='bullet'>
            <span>
       <List points="Uncommuted Pension: Uncommuted pension is a periodical payment received by an employee after his retirement."/>
       </span>
       </li>
       <li className='bullet'>
          <span>
       <List points="Commuted Pension: commuted pension means lump sum amount taken by commuting the whole or part of the pension."/>
       </span>
       </li>
       </ul>
       <Bottomspace/>
       <Mainhead heading="Relief under section 89"/>

       <Step number="​​​Relief under section 89 is available to an individual if he has received"/>
       <ul className='list'>
        <li className='bullet'>
          <span>
       <List points="Gratuity in excess of exemption limit prescribed."/>
       </span>
       </li>
       <li className='bullet'>
          <span>
       <List points="On account of arrears of salary or advance salary."/>
       </span>
       </li>
       <li className='bullet'>
          <span>
       <List points="Arrears of Family pension"/>
       </span>
       </li>
       <li className='bullet'>
          <span>
       <List points="Compensation on termination of employment"/>
       </span>
       </li>
       <li className='bullet'>
          <span>
       <List points="Commuted pension in excess of exemption limit prescribed"/>
      </span>
      </li>
      </ul>
      <Bottomspace/>
       <Mainhead heading="Amendment Under SEC 17"/><br/>

       <Step number="RETIREMENT BENEFIT (SEC 17 (VIII) )"/>
       <Content paragraph="As per new clause aggregate amount contribution made by the employer to the following benefit scheme- "/>
        <ul className='list'>
          <li className='bullet'>
            <span>
       <List points="National pension scheme"/>
       </span>
       </li>
       <li className='bullet'>
          <span>
       <List points="Recognised provident fund"/>
       </span>
       </li>
       <li className='bullet'>
          <span>
       <List points="Approved superannuation fund"/>
       </span>
       </li>
       <li className='bullet'>
          <span>
       <List points="In excess of 750000, is taxable perquisites"/> <br/>
       </span>
       </li>
       </ul>
       <Bottomspace/>
       <Step number="SEC 17(2) (VIIIA) NEW CLAUSE INSERT BY FA 2020 "/>
       <Content paragraph="This provision provides that annual accretions by way of interest of any amount of similar nature to the PY to the balance at the credit of the fund or the scheme. Ex: Mr. A is basic salary 4800000 and he also get a commission 1% on turnover of Rs. 800000 further following information is given below:"/>
       <Bottomspace/>
       <Mainhead heading="Form 10E"/>
       <Content paragraph="In case of receipt in arrears or advance of any sum in the nature of salary, relief u/s 89 can be claimed. In order to claim such relief, the assessee has to file form 10 E."/>
         <Bottomspace/>
       <Mainhead heading="Form 12BB"/>
       <Content paragraph="Form No. 12BB is required to be furnished by an employ​ee to his employer for estimating his income or computing the tax deduction at source."/>
       <Content paragraph="An assessee shall furnish evidence or particulars of the claims, such as House Rent Allowance, Leave Travel concession, Deduction of Interest under the head 'Income from house property'nd deductions under Chapter-VIA in Form No. 12BB​ for estimating his income or computing the tax deduction at source."/>
         <Bottomspace/>
       <Mainhead heading="Retirement benefits like Provident Fund and Gratuity taxable"/>
       <Content paragraph="In the hands of a Government employee, Gratuity and PF receipts on retirement are exempt from tax. In the hands of non-Government employee, gratuity is exempt subject to the limits prescribed in this regard and PF receipts are exempt from tax, if the same are received from a recognized PF after rendering continuous service of not less than 5 years.​"/>
       <Content paragraph="Note: W.e.f. Assessment Year 2022-23, no exemption shall be available for the interest income accrued during the previous year in the recognized and statutory provident fund to the extent it relates to the contribution made by the employees over Rs. 2,50,000 in the previous year."/>
       <Content paragraph="However, if an employee is contributing to the fund but there is no contribution to such fund by the employer, then the interest income accrued during the previous year shall be taxable to the extent it relates to the contribution made by the employee to that fund in excess of Rs. 5,00,000 in a financial year."/>
       <Bottomspace/>
       <Mainhead heading="Deduction"/>
       <Step number="(A).STANDARD DEDUCTION"/>
       <Content paragraph="Standard Deduction was reintroduced in the 2018 budget. This deduction has replaced the conveyance allowance and medical allowance. The employee can now claim a flat Rs.50,000 (Prior to Budget 2019, it was Rs.40,000) deduction from the total income, thereby reducing the tax outgo. In the recent Union Budget 2023-24, a standard deduction of Rs.50,000 was introduced under the new tax regime, which was previously available only under the old tax regime."/>
        <Bottomspace/>
       <Step number="PROFESSIONAL TAX"/>
       <Content paragraph="Professional tax or tax on employment is a tax levied by a state, just like income tax which is levied by the central government. The maximum amount of professional tax that can be levied by a state is Rs 2,500. It is usually deducted by the employer and deposited with the state government. In your income tax return, professional tax is allowed as a deduction from your salary income."/>
        <Bottomspace/>
       <Faq/>
      <Bottomspace/>
      <Bottomspace/>
            </Grid>
          </Grid>
          
      </Container>
    </div>
    </div>
  )
}

export default Salaries