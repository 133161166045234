import React from "react";
import {
  styled,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
} from "@mui/material";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "#773106",
    color: theme.palette.common.white,
    textAlign: "left",
    fontFamily: "Sora",
    fontWeight: "bold",
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 16,
    textAlign: "left",

    fontFamily: "Sora",
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(even)": {
    backgroundColor: theme.palette.action.hover,
    fontFamily: "Sora",
  },
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

const ReusableTable = ({ data }) => {
  // Function to get unique keys from data objects
  const getUniqueKeys = () => {
    const uniqueKeys = new Set();
    data.forEach((row) => {
      Object.keys(row).forEach((key) => uniqueKeys.add(key));
    });
    return Array.from(uniqueKeys);
  };

  const uniqueKeys = getUniqueKeys();

  return (
    <TableContainer component={Paper}>
      <Table aria-label="customized table" size="small">
        <TableHead>
          <TableRow>
            {uniqueKeys.map((key) => (
              <StyledTableCell key={key}>{key}</StyledTableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {data.map((row, index) => (
            <StyledTableRow key={index}>
              {uniqueKeys.map((key) => (
                <StyledTableCell key={key}>{row[key]}</StyledTableCell>
              ))}
            </StyledTableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default ReusableTable;
