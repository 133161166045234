import './App.css';
import Footer from './Components/Footer';
import Header from './Components/Header';

import Topbox from './Components/Services/Topbox';

import Privatelimited from './Pages/Services/PrivateLimitedCompany/Privatelimited';
import StartupRegistration from './Pages/Services/StartupRegistration/StartupRegistration';
import PartnershipFirm from './Pages/Services/PartnershipFirm/PartnershipFirm';
import SoleProprietorship from './Pages/Services/SoleProprietorship/SoleProprietorship';
import NidhiCompany from './Pages/Services/NidhiCompany/NidhiCompany';

import ContactForm from './Pages/ContactUs/ContactForm';
import Aboutwork from './Pages/Home/Components/Aboutwork';
import Homepage from './Pages/Home/Homepage';
import Navigation from './Pages/Navigation/Navigation.component';
import About from './Pages/AboutUs/About';

import ShopAct from './Pages/Services/ShopAct/ShopAct';

import ProducerCompany from './Pages/Services/ProducerCompany/ProducerCompany';



import { Routes, Route } from 'react-router-dom';
import LimitedLiabilityPartnership from './Pages/Services/LimitedLiabilityPartnership/LimitedLiabilityPartnership';

import Incometax from './Pages/TaxPlanning/IncomeTax/Incometax';
import Mainroute from './Pages/TaxPlanning/Mainroute';

import ScrollToTop from './ScrollToTop';
import SignInClient from './Pages/Authentication/ClientSignIn/SignInClient';
import SignInAdmin from './Pages/Authentication/AdminSingIn/SignInAdmin';

import { ClientPrivateRoute, AdminPrivateRoute } from './Components/PrivateRoute/PrivateRoute';
import ClientDashboard from './Pages/Dashboard/ClientDashboard';
import AdminDashboard from './Pages/Dashboard/AdminDashboard';
import TermsAndCondition from './Pages/Services/TermsAndConditions/TermsAndCondition';
import Trademark from './Pages/Trademark/Traderenewal/Trademark';
import IncomeTaxRoute from './Pages/IncomeTax/IncomeTaxRouts.jsx';


function App() {
  return (
    <>


      <ScrollToTop />
      <Routes>
        <Route path='/' element={<Navigation />}>
          <Route index element={<Homepage />} />
          <Route path='/contact' element={<ContactForm />} />
          <Route path='/private' element={<Privatelimited />} />
          <Route path='/startupregistration' element={<StartupRegistration />} />
          <Route path='/partnershipfirm' element={<PartnershipFirm />} />
          <Route path='/soleproprietorship' element={<SoleProprietorship />} />
          <Route path='/nidhicompany' element={<NidhiCompany />} />
          <Route path='/shopact' element={<ShopAct />} />
          <Route path='termsandcondition' element={<TermsAndCondition />} />
          <Route path='/producercompany' element={<ProducerCompany />} />

          <Route path='/limitedliability' element={<LimitedLiabilityPartnership />} />
          <Route path='/trademark' element={<Trademark />} />
          <Route path='/tax/*' element={<Mainroute />} />
          <Route path='/incometax/*' element={<IncomeTaxRoute />} />
          <Route path='/about' element={<About />} />


        </Route>

        <Route exact path="/" element={<AdminPrivateRoute />}>
          <Route path="/dashboard" element={<AdminDashboard />} />
        </Route>
        <Route exact path="/" element={<ClientPrivateRoute />}>
          <Route path="/client-dashboard" element={<ClientDashboard />} />
        </Route>

        <Route path="/sign-in-admin" element={<SignInAdmin />} />
        <Route path="/sign-in" element={<SignInClient />} />
      </Routes>


    </>




  );
}

export default App;
