import React from 'react';

import { Button, Container, styled, Box } from '@mui/material';
import Solutions from './Components/Solutions';
import Services from './Components/Services';
import Standus from './Components/Standus';
import Brand from './Components/Brand';
import Aboutwork from './Components/Aboutwork';
import FAQ from './Components/FAQ';
import Contactus from './Components/Contactus';
import Testimonials from './Components/Testimonials';


const Homepage = () => {

    return (
        <div>

            <Solutions />
           <Services/>
           <Standus/>
           <Aboutwork/>
           <Brand/>
           <FAQ/>
           <Contactus/>
           <Testimonials/>

            


        </div>

    )
}

export default Homepage;