import React from "react";
import { Box } from "@mui/material";
import Mainhead from "../../../Components/Services/MainContent/Mainhead";
import Content from "../../../Components/Services/MainContent/Content";
import CustomList from "../../../Components/Income-Tax/CustomList";
import Step from "../../../Components/Services/MainContent/Step";
import ReusableTable from "../../../Components/Income-Tax/ReusableTable";

const ScientificResearchSection = () => {
  const deductionTableData = [
    { "Scientific Research": "Self", "Deduction (Percentage)": "100%" },
    {
      "Scientific Research": "Outside Agency",
      "Deduction (Percentage)": "100%",
    },
  ];

  return (
    <Box
      style={{
        display: "flex",
        flexDirection: "column",
        gap: 4,
        marginBottom: "2rem",
      }}
    >
      <Mainhead heading="Section 35: Scientific Research" />
      <Step
        number={`
            What does Scientific Research Mean?
          `}
      />
      <Content paragraph="Any systematic examination or study that tries to find new knowledge or improve existing knowledge in a specific topic is referred to as scientific research. This can involve scientific, technological, engineering, medical, and social sciences research." />
      <Step
        number={`
            Who is entitled to receive the Section 35 deduction?
          `}
      />
      <Content paragraph="To be eligible for the Section 35 deduction, the research and development work must be carried out in India by a resident of India. For the promotion of research and development, the activity must also be approved by the Department of Scientific and Industrial Research (DSIR) or the National Committee on Science and Technology (NCST)." />
      <Step
        number={`
            Expenditure that is deductible under Section 35(1)(ii)
          `}
      />
      <CustomList
        points={[
          "Expenditure for scientific research",
          "Expenses incurred in the acquisition of patents or patent rights",
          "Expenses incurred in developing new products or processes",
          "Expenses incurred to acquire new technology or technical know-how",
          "Expenditure on expanding or modernizing existing scientific research facilities",
        ]}
      />
      <Step
        number={`
            Various Advantages of Section 35(1)(ii)
          `}
      />
      <CustomList
        points={[
          "Tax savings: Companies may be able to lower their tax obligations by deducting any costs related to scientific research.",
          "Promotes innovation: The clause incentivizes companies to spend money on scientific research, which can drive economic growth and innovation.",
          "Boosts competitiveness: Businesses can become more marketable by investing in scientific research to assist them create new products and procedures.",
        ]}
      />
      <Step
        number={`
            Expenses not Included in the Deduction
          `}
      />
      <CustomList
        points={[
          "Expenses related to purchasing land or structures",
          "Costs associated with purchasing equipment or machinery",
          "The cost incurred while purchasing patents or the right to use patents from a connected entity",
          "The amount of money spent on purchasing any asset that qualifies for depreciation.",
        ]}
      />
      <Step
        number={`
            Weighted deduction u/s 35
          `}
      />
      <ReusableTable data={deductionTableData} />
      <Content paragraph="Other crucial details:" />
      <CustomList
        points={[
          "Depreciation is not permitted: With regard to assets for which a section 35 deduction has been claimed, no section 32 depreciation may be claimed.",
          "The way in which unabsorbed capital expenditures for scientific research are handled.",
          "There are similarities between the regulations for deducting and carrying forward unabsorbed capital expenditures for scientific research and those for deducting and carrying forward unabsorbed depreciation.",
          "Any unutilized capital investment for scientific research in a given year may be deducted from income in that same year under any other head, with the exception of casual income.",
          "If capital expenditures for scientific research cannot be adjusted in the same year, they may be carried forward for an indefinite amount of time (or for an unlimited period of time). In the years that follow, these unabsorbed expenditures may be deducted from any income that is not from casual sources.",
        ]}
      />
      <Content
        paragraph="
Treatment of scientific research assets that are no longer employed for scientific research:"
      />
      <ReusableTable
        data={[
          {
            "Asset sold without being used for any other business venture":
              "Section 41(3) applies. The lesser of the two sums is taxable as PgBP income: the asset's sale price Orthe deduction allowed under Section 35",
            "Asset transferred to another business":
              "The asset will be added to the other business's current asset block. The actual cost of the transferred asset is assumed to be 'NIL'.",
          },

          {
            "Asset sold without being used for any other business venture":
              " Capital gains will occur if the sale price exceeds the asset's cost. If the asset is sold within three years, the capital gains are long-term; otherwise, the capital gains are short-term.",
            "Asset transferred to another business": "",
          },
        ]}
      />
    </Box>
  );
};

export default ScientificResearchSection;
