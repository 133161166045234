import React from "react";
import { Box, Typography, styled } from "@mui/material";
import Mainhead from "../../../Components/Services/MainContent/Mainhead";
import Content from "../../../Components/Services/MainContent/Content";
import Step from "../../../Components/Services/MainContent/Step";
import ReusableTable from "../../../Components/Income-Tax/ReusableTable";

const Section44AE = () => {
  const ServiceContent = styled(Typography)(({ theme }) => ({
    color: "#000000",
    fontSize: "1rem",
    fontWeight: 450,
    lineHeight: "1.8rem",
    marginTop: "0.5rem",

    [theme.breakpoints.down("sm")]: {
      marginLeft: "0.8rem",
      lineHeight: "1.6rem",
      fontSize: "0.85rem",
    },
    [theme.breakpoints.between("md", "lg")]: {
      fontSize: "0.75rem",
      lineHeight: "1.8rem",
    },
    [theme.breakpoints.up("xl")]: {
      fontSize: "1.1rem",
      lineHeight: "1.8rem",
    },
  }));
  const presumptiveIncomeTableData = [
    {
      Type: "Heavy Goods Vehicle (HGV)",
      "Presumptive Income":
        "Rs. 1,000 per ton of gross vehicle weight or unladen weight",
    },
    {
      Type: "Other Goods Carriage",
      "Presumptive Income": "Rs. 7,500 per month or part of a month",
    },
  ];

  return (
    <Box
      style={{
        display: "flex",
        flexDirection: "column",
        gap: 4,
        marginBottom: "2rem",
      }}
    >
      <Mainhead heading="Section 44AE - Presumptive Taxation for Goods Carriage Owners" />
      <Content paragraph="Section 44AE of the Income Tax Act provides relief for small assessees engaged in the goods carrier sector. It falls under the presumptive taxation system, relieving assessees from the obligation to maintain regular books of accounts and undergo auditing." />
      <Step number="Applicability Of Section 44AE of Income Tax Act,1961" />
      <Content paragraph="This section applies to owners of goods carriage vehicles and is not applicable to those who own less than ten goods carriages at any time during the previous year." />
      <Step number="Presumptive Income" />
      <Content paragraph="Under the presumptive basis, the income of the taxpayer is presumed at specified rates for different types of vehicles, regardless of the actual amount." />
      <ReusableTable data={presumptiveIncomeTableData} />
      <Step number="Records and Books of Accounts" />
      <Content paragraph="Taxpayers opting for presumptive taxation under Section 44AE are not required to maintain regular books of accounts." />
      <Step number="Other Considerations" />
      <Content paragraph="To avail of the presumptive scheme, the taxpayer must satisfy certain conditions and requirements as specified in the Income Tax Act." />
      <Step number="Other Relevant Points" />
      <Content paragraph="Once the option is exercised for a particular assessment year, it cannot be withdrawn during the subsistence of that assessment year." />
      <ReusableTable
        data={[
          {
            ApplicableTo: "Condition",
            "All assessees engaged in the business of plying, hiring, or leasing goods carriage.":
              [
                " Number of carriages: Assessees must not own more than 10 goods carriages at any time during the previous year. Owner of carriages includes a buyer under hire purchase or installment system even if the whole amount is unpaid. Any motor vehicle built or modified specifically for the purpose of transporting goods, as well as any motor vehicle not so built or modified when utilized for such purposes, are referred to as goods carriage.",
              ],
          },
          {
            ApplicableTo: "Estimated income",
            "All assessees engaged in the business of plying, hiring, or leasing goods carriage.":
              [
                "Income from each goods carriage shall be",
                "From the month when the assessee acquired the property, whether it has been put to use or not. An assessee can declare a higher income.",
                "Presumptive income (per month or part of a month) for Heavy category: Rs. 1,000 per ton of gross vehicle weight or unladen weight",
                "Presumptive income (per month or part of a month) for Other category: Rs. 7,500",
              ],
          },
        ]}
      />

      <Step
        number={`
    Special Provisions under Section 44AE
  `}
      />
      <ul className="list-inside list-disc">
        <li>
          <strong>
            <ServiceContent component="p">
              Heavy Goods Vehicle (HGV) Definition:
            </ServiceContent>
          </strong>
          <ServiceContent component="p">
            - Any goods carriage with a gross vehicle weight exceeding 12000
            kilograms.
          </ServiceContent>
        </li>

        <li>
          <strong>
            <ServiceContent component="p">
              Deduction under Sections 30 to 38:
            </ServiceContent>
          </strong>
          <ServiceContent component="p">
            - The estimated income is comprehensive, and no further deduction
            under Sections 30 to 38 shall be allowed.
          </ServiceContent>
        </li>

        <li>
          <strong>
            <ServiceContent component="p">
              Deduction under Section 40(B) for Firms:
            </ServiceContent>
          </strong>
          <ServiceContent component="p">
            - In the case of a firm, deduction in respect of remuneration and
            interest to partners under Section 40(B) shall be further deductible
            from the computed income.
          </ServiceContent>
        </li>

        <li>
          <strong>
            <ServiceContent component="p">Depreciation:</ServiceContent>
          </strong>
          <ServiceContent component="p">
            - Depreciation is deemed to have been already allowed. The written
            down value of the asset will be calculated as if depreciation has
            been allowed.
          </ServiceContent>
        </li>

        <li>
          <strong>
            <ServiceContent component="p">
              Deduction and Aggregation:
            </ServiceContent>
          </strong>
          <ServiceContent component="p">
            - The estimated income is aggregated with other income of the
            assessee from any other business or under any other heads of income.
            Further deduction under Chapter VI-A shall be available.
          </ServiceContent>
        </li>

        <li>
          <strong>
            <ServiceContent component="p">Brought Forward Loss:</ServiceContent>
          </strong>
          <ServiceContent component="p">
            - Brought forward loss, if any, shall be adjusted from the estimated
            income.
          </ServiceContent>
        </li>

        <li>
          <strong>
            <ServiceContent component="p">
              Maintenance of Books of Accounts and Audit:
            </ServiceContent>
          </strong>
          <ServiceContent component="p">
            - An assessee, whose estimated income from such business as per
            Section 44AE or higher is not required to maintain books of accounts
            under Section 44AA and get his accounts audited under Section 44AB.
          </ServiceContent>
          <ServiceContent component="p">
            - Regarding other firms, adherence to Section 44AB and Section 44AA
            regulations is required. Gross revenues and income from the
            mentioned business are not included in the computation of the
            monetary limits under Section 44AA and 44AB.
          </ServiceContent>
        </li>

        <li>
          <strong>
            <ServiceContent component="p">
              Effect if Assessee Declares Lower Income:
            </ServiceContent>
          </strong>
          <ServiceContent component="p">
            - An assessee can declare his income lower than the estimated income
            as per the provisions of Section. In such a case, he will have to
            maintain books of accounts and other documents as required under
            Section 44AA and get his accounts audited and furnish the audit
            report under Section 44AB, irrespective of the amount and gross
            receipts.
          </ServiceContent>
          <ServiceContent component="p">
            - Note: Assessee can change his option from year to year.
          </ServiceContent>
        </li>

        <li>
          <strong>
            <ServiceContent component="p">
              Special Provision under Section 194C:
            </ServiceContent>
          </strong>
          <ServiceContent component="p">
            - If such transporter furnishes PAN to the payer, then the payer
            will not deduct TDS, irrespective of any amount.
          </ServiceContent>
        </li>

        <li>
          <strong>
            <ServiceContent component="p">
              No Other Business Expenditure Allowed:
            </ServiceContent>
          </strong>
          <ServiceContent component="p">
            - No other business expenditure is allowed if the assessee opts for
            this section.
          </ServiceContent>
        </li>
      </ul>
    </Box>
  );
};

export default Section44AE;
