import React from "react";
import { Box } from "@mui/material";
import Mainhead from "../../../Components/Services/MainContent/Mainhead";
import Content from "../../../Components/Services/MainContent/Content";
import CustomList from "../../../Components/Income-Tax/CustomList";
import Step from "../../../Components/Services/MainContent/Step";
import ReusableTable from "../../../Components/Income-Tax/ReusableTable";

const StandardDeductionIndia = () => {
  const standardDeductionTableData = [
    {
      Category: "Business",
      "Monthly Income": "1,00,000",
      "Annual Income": "12,00,000",
      "Less: Standard Deduction": "-",
      "Total Income Taxable": "12,00,000",
    },
  ];

  return (
    <Box
      style={{
        display: "flex",
        flexDirection: "column",
        gap: 4,
        marginBottom: "2rem",
      }}
    >
      <Mainhead heading="Standard Deduction in India" />
      <Content paragraph="In the Income Tax Act, 1961 in India, a deduction is a claim made to lower your taxable income due to certain investments and expenses incurred by a taxpayer." />
      <Step number="What is Standard Deduction as per Income Tax Act 1961 in India?" />
      <Content paragraph="Salaried employees and pensioners are entitled to the following deductions from their annual salary:" />
      <CustomList
        points={[
          "Flat deduction of Rs. 50,000",
          "Amount of salary, whichever is lower",
        ]}
      />
      <Step number="Who is Qualified to Get Standard Deduction in India?" />
      <Content paragraph="The standard deduction is available to all salaried employees but not to individuals doing business or on contract terms of employment." />
      <Step number="Standard Deduction Calculator" />
      <ReusableTable
        data={[
          {
            Category: "Employees on Salary",
            "Monthly Salary": "1,00,000",
            "Annual Salary": "12,00,000",
            "Less: Standard Deduction": "(50,000)",
            "Net Salary Taxable": "11,50,000",
          },
        ]}
      />
      <ReusableTable data={standardDeductionTableData} />
      <Step number="In Which Circumstances I Cannot Claim Standard Deduction?" />
      <CustomList
        points={[
          "Married couples filing separately if one of the spouses itemized deductions.",
          "Taxpayers holding dual citizenship or non-resident alien status throughout a fiscal year.",
          "Taxpayers who file taxes for less than a year.",
        ]}
      />
      <Mainhead heading="Professional Tax in India" />
      <Content paragraph="Professional tax is a tax paid by anyone who makes more than a certain amount of income, regardless of the source of such income." />
      <Content paragraph="The term 'professional tax' is misleading because it implies that it is solely paid by professionals. Professional tax, on the other hand, is a tax levied on an individual's income regardless of whether the person is a professional or involved in business." />
      <Step number="Professional Tax Liability in India" />
      <Content paragraph="Professional tax can be levied on all kinds of employees, businessmen, professionals, freelancers, or traders if they exceed a given threshold of income." />
      <Step number="Who Collects Professional Tax?" />
      <Content paragraph="Professional tax is a tax levied by the state government and is collected by the commercial tax department of the respective state. The funds collected are utilized by municipal corporations for the overall development of the state. Note: All states do not levy a professional tax." />
      <Step number="Professional Tax Rate Slab in Different States in India" />

      <CustomList
        points={[
          "Karnataka:- If your monthly salary/ wage is more than Rs 15000, you will be subjected to a tax of Rs. 200 monthly.",
          "Maharashtra:- ⦁	Monthly gross salary upto Rs7500-  Nil ⦁	Monthly gross salary from Rs7501 to 10000- Rs. 175 per month⦁	Monthly gross salary above Rs.10000- Rs. 2500 per annum",
        ]}
      />
      <Step number="Who is Liable for Paying Professional Tax in India?" />
      <CustomList
        points={[
          "If you are an employee, your employer is required to deduct the tax amount and pay the same to the state government. The employer is required to get a Professional tax enrollment certificate to deduct the tax of an employee.",
          "If you are an employer or a freelancer, the income you receive from your business will be subject to professional tax. You will need to register yourself and get a professional tax registration certificate.",
        ]}
      />
      <Step number="Who is Exempt from Professional Tax in India?" />
      <CustomList
        points={[
          "Member of Army, Air Force or Navy",
          "An individual suffering from mental or physical disability.",
          "Parent of a child suffering from a disability",
          "Temporary workers employed in a factory",
          "Individual running an educational institute",
          "A foreign individual",
          "Any individual above 65 years",
        ]}
      />
    </Box>
  );
};

export default StandardDeductionIndia;
