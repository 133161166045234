import React from 'react';
import Mainhead from '../../../../Components/Services/MainContent/Mainhead';
import Step from '../../../../Components/Services/MainContent/Step';
import List from '../../../../Components/Services/MainContent/List';
import Content from '../../../../Components/Services/MainContent/Content';

function Document() {
  return (
    <div>
      <Mainhead heading="Documentation Needed For Registering A Producer Company"/>
      <Step number="TO BE SUBMITTED BY DIRECTORS & SHAREHOLDERS"/>
      <ul className='list'>
        <li className='bullet'>
          <span>
      <List points="Scanned copy of PAN card or passport (foreign nationals & NRIs)"/>
      </span>
      </li>
      <li className='bullet'>
          <span>
      <List points="Scanned copy of voter ID/passport/driving licence"/>
      </span>
      </li>
      <li className='bullet'>
          <span>
      <List points="Scanned copy of the latest bank statement/telephone or mobile bill/electricity or gas bill"/>
      </span>
      </li>
      <li className='bullet'>
          <span>
      <List points="Scanned passport-sized photograph specimen signature (blank document with signature [directors only])"/>
      </span>
      </li>
     </ul>
      <Content paragraph="NOTE: Any of the directors must self-attest the first three documents. In case of foreign nationals & NRI’s, all the documents must be notarized(if currently in India or a non-Commonwealth country)or apostille (if in a Commonwealth country)"/>
     <br/>
     <Step number="FOR THE REGISTERED OFFICE"/>
     <ul className='list'>
      <li className='bullet'>
        <span>
     <List points="Scanned copy of the latest bank statement/telephone or mobile bill/electricity or gas bill"/>
     </span>
     </li>
     <li className='bullet'>
        <span>
     <List points="Scanned copy of notarized rental agreement in English"/>
     </span>
     </li>
     <li className='bullet'>
        <span>
     <List points="Scanned copy of no-objection certificate from the property owner"/>
     </span>
     </li>
     <li className='bullet'>
        <span>
     <List points="Scanned copy of sale deed/property deed in English (in case of owned property)"/>
     </span>
     </li>
     </ul>
     <Content paragraph="NOTE: Your registered office need not be a commercial space; it can be your residence, too."/>

    </div>
  )
}

export default Document