import React from 'react'
import Mainhead from '../../../../Components/Services/MainContent/Mainhead';
import Content from '../../../../Components/Services/MainContent/Content';
import List from '../../../../Components/Services/MainContent/List';
import { Box , styled} from '@mui/material';
import Step from '../../../../Components/Services/MainContent/Step';
import advantage from '../../../../Assets/Services/NidhiCompany/LegalFactory_Nidhi1.png'

const Advantageimg = styled(Box)(({ theme }) => ({
    width:'34rem',
    height:'32rem',
    marginTop:'0.8rem',
    [theme.breakpoints.down("sm")]: {
      width:'18rem',
    height:'21.5rem',
    },
    [theme.breakpoints.down("md")]: {
      width:'18rem',
    height:'21.5rem',
    },
    [theme.breakpoints.between("md", "lg")]: {
      width:'25rem'
    }
  }));
function Advantage(){
    return(
        <div>
            <Mainhead heading="Advantages of Registering a Nidhi Company"/>
            <ul className='list'>
              <li className='bullet'>
                <span>
            <List points="FORMATION PROCESS IS SERENE AND UNCOMPLICATED: The registration process of Nidhi Company offers freedom from the complex documentation processes throughout the procedure, moreover the registration process gets terminated in 10-14 days."/>
            </span>
            </li>
            <li className='bullet'>
                <span>
            <List points="LIMITED LIABILITY: This proves to be the greatest benefit of running a Nidhi Company in the sense that the government, bank or creditors cannot seize the personal assets of shareholders or even the directors."/>
            </span>
            </li>
            <li className='bullet'>
                <span>
            <List points="HIGH CERTAINTY VALUE: The principle of saving is deeply rooted in the incorporation and functioning process of Nidhi Company thereby resulting in a very high certainty."/>
            </span>
            </li>
            <li className='bullet'>
                <span>
            <List points="FEW GUIDELINES/ LESS RBI INTERFERENCE: The governance of Nidhi Companies come under the Nidhi Rules, 2014. The Nidhi Company is in the hands of the Central Government; RBI cannot create obstacles or govern the operations of a Nidhi Company."/>
            </span>
            </li>
            <li className='bullet'>
                <span>
            <List points="SEPARATE LEGAL ENTITY: As it is a separate legal entity from its shareholders and directors, it can own property and incur debts on its name, sue anybody, sue anybody or be sued by others."/>
            </span>
            </li>
            <li className='bullet'>
                <span>
            <List points="PERPETUAL EXISTENCE: This is one of the most sought off benefits/ prime benefits of Nidhi Company. Death or departure of any member won't affect the Nidhi Company and it will incessantly survive till its legal dissolution."/>
            </span>
            </li>
            </ul>
            <Box sx={{textAlign:'center'}}>
              <Advantageimg component="img"
                  sx={{
                    content: {
                      xs: `url(${advantage})`
                    }
                  }}/>
              </Box>
        </div>
    )
}

export default Advantage