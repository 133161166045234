import React from 'react';
import Sidecomponent from '../../../Components/Tax-Planning/Sidecomponent';
import Topdiv from "../../../Components/Tax-Planning/Topdiv"
import GetInTouch from '../../../Components/Services/GetInTouch';
import "../Common.css"
import {
  Grid, Box, Container, styled, Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Link
} from '@mui/material';
import Mainhead from '../../../Components/Services/MainContent/Mainhead';
import Content from '../../../Components/Services/MainContent/Content';
import List from '../../../Components/Services/MainContent/List';
import Step from '../../../Components/Services/MainContent/Step';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';

function createData(
  Capital,
  Revenue
) {
  return {
      Capital,
      Revenue
  };
}

function createData1(
  Sno,
  Individual,
  Indian,
  Leaving,
  Purpose
) {
  return {
      Sno,
      Individual,
      Indian,
      Leaving,
      Purpose
  };
}
const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: '#773106',
    color: theme.palette.common.white,
    textAlign: 'left',
    fontFamily: 'Sora',
    fontWeight: 'bold',



  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 16,
    textAlign: 'left',
    textAlign: 'left',
    fontFamily: 'Sora',
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(even)': {
    backgroundColor: theme.palette.action.hover,
    fontFamily: 'Sora'
  },

  '&:last-child td, &:last-child th': {
    border: 0,
  },
}));

const Bottomspace = styled(Box)(({ theme }) => ({
  marginBottom:'2.1rem'
}));

const rows = [
  createData('Receipt in lieu of sources of income is a capital receipt','A receipt in lieu of income is revenue income'),
  createData('Capital receipt are exempt from tax unless they are specifically taxable','Revenue receipts are taxable unless specifically exempt from tax'),
  createData('Capital receipts is generally referable to fixed Assets','Revenue receipts is referable to circulating capital or stock in trade'),
  createData('Profit on sale of land held as investment by investor will treated capital receipt','Profit on sale of land held as stock in trade by trader will treated revenue receipts')
  ];

const rows1 = [
  createData1('1','Individual','Indian Citizen','Leaving India','For employment purpose'),
  createData1('2','Individual','Indian Citizen','Leaving India','As a crew member of indian ship'),
  createData1('3','Individual','Indian Citizen or Indian Origin','Coming India','For visitng Purpose'),
]

function Incometax() {
  return (
    <div>
      <Topdiv heading="Are you looking to file your Income Tax Returns ?"/>
      <div className='Container'>
        <Container>
        <Grid container>
          <Grid item  lg={4} xl={3}>
            <Box sx={{ display: { xs: 'none', lg: 'block' }, marginTop: '3rem', position: 'sticky', top: 90, marginBottom: '3rem' }}>
              <Sidecomponent />
            </Box>
          </Grid>
          <Grid item xs={11} sm={12} md={12} lg={8} sx={{ marginTop: '3rem' }} xl={9}>
            <Mainhead heading="What is a meaning of income ?" />
            <Step number="Income (sec 2(24) ) -"/>
            <Content paragraph="1.Profit & Gains from B/P"/>
            <Content paragraph="2.Dividend"/>
            <Content paragraph="3.Capital gain chargeable u/s 454"/>
            <Content paragraph="4.Any winning from lotteries , crossword puzzles, races including horse race, card game & other games of any sort or from gambling and betting of any from of nature whatsoever"/>
            <Content paragraph="5.Deemed income u/s 41 or 59"/>
            <Content paragraph="6.Sum received by an Assessee from his employees toward welfare fund contribution such as pf , superannuation fund etc."/>
            <Content paragraph="7.Amount received under keyman insurance policy."/>
            <Bottomspace/>
            <Mainhead heading="Meaning of the previous year ?"/>
            <Content paragraph="Previous year ( sec 3) As per sec 3 : P/Y means the F/Y immediately preceding the A/Y P/Y for newly established business : Being with the date of setting up of new Business and end with 31st march of said FY."/>
            <Bottomspace/>
            <Mainhead heading="Meaning of Assessee ?"/>
            <Content paragraph="Assessee (sec 2(7))
Any person who is liable to pay any tax or other sum under the income tax act, and includes -"/>
<ul >
  <li style={{listStyle:'none'}}>
<Step number="1.Every person who is DEEMED TO BE AN ASSESSEE under the Act."/>
</li>
<li style={{listStyle:'none'}}>
<Step number="2.Every person who is deemed to be an ASSESSEE IN DEFAULT the Act."/>
</li>
</ul>
<Bottomspace/>
<Mainhead heading="In income tax, only real income is taxable unless otherwise specifically provided by law."/>
<Content paragraph="1.As per this theory if income does not result at all, there can’t be tax even though entry made in books on hypothetical basis."/>
<Content paragraph="2.This is based on the view that when income not accrue & not receipt then income tax can’t be levy on that."/>
<Content paragraph="3.However where in fact received, and subsequently given up, it remains the income of recipient and chargeable to tax."/>
<Bottomspace/>
<Mainhead heading="CAPITAL RECEIPT V/S REVENUE RECEIPT"/>
<br/>
<TableContainer component={Paper} >
                <Table aria-label="customized table" size='small'>
                  <TableHead>
                    <TableRow>
                      <StyledTableCell>Capital Revenue</StyledTableCell>
                      <StyledTableCell>Revenue Receipts</StyledTableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {rows.map((row) => (
                      <StyledTableRow
                        key={row.name}

                      >
                        <StyledTableCell component="th" scope="row"
                        >
                          {row.Capital}
                        </StyledTableCell>
                        <StyledTableCell>{row.Revenue}</StyledTableCell>
                      </StyledTableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
              <br/><br/>
        <Mainhead heading="Residence & scope of total income"/>
<Content paragraph="Total income of a person can’t be computed unless we know his residential status, place of Accrual & place of receipt in India during PY"/>
<Bottomspace/>
<Mainhead heading="RESIDENTIAL STATUS OF ASSESSEE IN INDIA"/>
<Content paragraph="Basic Rule for Determining RS : The following rule must be kept in mind:"/>
<ul className='list'>
  <li className='bullet'>
    <span>
<List points="It is determining for each category of person separately because rule for determining RS are different for individual & for company"/>
</span>
</li>
<li className='bullet'>
    <span>
<List points="Total income of a person can’t be determined w/o knowing his Rs."/>
</span>
</li>
<li className='bullet'>
    <span>
<List points="Residential status of a person is to be determined for every PY because it may change year to year."/>
</span>
</li>
<li className='bullet'>
    <span>
<List points="Foreign citizens may be resident in india."/>
</span>
</li>
</ul>
<Content paragraph="A person can be resident in more than one country for the same PY."/>
<Content paragraph="Indian citizens may be non - resident in india."/>
<Bottomspace/>
<Mainhead heading="HOW TO DETERMINE THE RESIDENTIAL STATUS ( SEC 6)"/>
<Content paragraph="BASIC CONDITION : Shall be resident in india if any one of the following condition is satisfied:"/>
<Content paragraph="(A) He stayed in India for a period of 182 days or more in the relevant previous year."/>
<Content paragraph="(B) If he stayed in India for a period of 60 days or more during the RPY & 365 days or more during four PY immediately preceding the RPY."/>
<Content paragraph="Analysis"/>
<ul>
  <li style={{listStyle:'none'}}>
    <Step number="1.If both of the above conditions are not satisfied - he is Non resident in india."/>
  </li>
  <li style={{listStyle:'none'}}>
  <Step number="2.The day on which he enters in india as well as leaves india shall be taken into a/c"/>
  </li>
</ul>
<br/>
<Mainhead heading="EXCEPTIONAL SITUATION"/>
<Content paragraph="For the following persons, only sec 6(1) (a) (i.e. 182 days) shall apply in other words (60+365) days condition will not apply"/>
<br/>
<TableContainer component={Paper} >
                <Table aria-label="customized table" size='small'>
                  <TableHead>
                    <TableRow>
                      <StyledTableCell></StyledTableCell>
                      <StyledTableCell></StyledTableCell>
                      <StyledTableCell></StyledTableCell>
                      <StyledTableCell></StyledTableCell>
                      <StyledTableCell></StyledTableCell>
                      
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {rows1.map((row) => (
                      <StyledTableRow
                        key={row.name}

                      >
                        <StyledTableCell component="th" scope="row"
                        >
                          {row.Sno}
                        </StyledTableCell>
                        <StyledTableCell>{row.Individual}</StyledTableCell>
                        <StyledTableCell>{row.Indian}</StyledTableCell>
                        <StyledTableCell>{row.Leaving}</StyledTableCell>
                        <StyledTableCell>{row.Purpose}</StyledTableCell>
                      </StyledTableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
              <br/><br/>
              <Content paragraph="Indian origin : A person is deemed to be of Indian origin, if he or either his parents or any of his grandparents was born in undivided india."/>
              <Bottomspace/>
              <Mainhead heading="Individual shall be resident and ordinary resident in india if he is satisfied both the additional conditions:"/>
              <Content paragraph="(A)He has been resident in India for at least 2 years out of 10 P/Y immediately RPY."/>
              <Content paragraph="(B)He has been resident in india"/>
              <Bottomspace/>
              <Bottomspace/>
              <Bottomspace/>
            </Grid>
            </Grid>
            
            </Container>
            </div>
        
       
        </div>
  )
}

export default Incometax;