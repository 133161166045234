import React from 'react'
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import InstagramIcon from '@mui/icons-material/Instagram';
import FacebookIcon from '@mui/icons-material/Facebook';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import TwitterIcon from '@mui/icons-material/Twitter';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import { submitDetailsHome } from '../../Action/GetInTouch';
import "./ContactFormStyles.css";
import { useFormik } from "formik";
import * as Yup from "yup";
import Logo from "../../Assets/Logo/LegalFactory_Logo1.png";
import { styled } from '@mui/material/styles';
import { useDispatch } from "react-redux";
import Model from "../../Assets/contactUs/Image4.png";
import Background from "./LegalFactory_ContactUs.png";

import MarketingComponent from "./marketing/marketing.component";


import {
  Typography,
  AppBar,
  Toolbar,
  useMediaQuery,
  TextField,
  Button,
  Container,
  Box, Grid,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Avatar,
  Divider,
  useTheme
} from '@mui/material';


import { Link } from '@mui/material';

const theme = createTheme({
  palette: {
    primary: {
      light: '#E99101',
      main: '#E99101',
      dark: '#E99101',
      contrastText: '#ffffff',
    },
    secondary: {
      light: '#E99101',
      main: '#1976D2',
      dark: '#E99101',
      contrastText: '#ffffff',
    },

  },
  typography: {
    fontFamily: 'Sora'
  }
});



const ContactForm = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const isTablet = useMediaQuery(theme.breakpoints.down("md"));
  const isRange = useMediaQuery(theme.breakpoints.between("md", "lg"));
  const isRange2 = useMediaQuery(theme.breakpoints.between("lg", "xl"));
  const dispatch = useDispatch();


  const Text_contact = styled(Typography)(({ theme }) => ({
    color: theme.palette.primary.main, textAlign: 'center',
    justifyContent: 'center',
    fontWeight: 'bold !important',
    fontSize: '1.25rem !important',
    margin: "1%"

  }));

  const Image_contact = styled(Box)(({ theme }) => ({

    [theme.breakpoints.up("lg")]: {
      width: '32rem',
      height: '30rem',
      objectFit: 'cover'
    },
    [theme.breakpoints.up("xl")]: {
      width: '36.5rem',
      height: '35rem',
      objectFit: 'cover'
    },
    [theme.breakpoints.between("md", "lg")]: {
      width: '32rem',
      height: '30rem',
      objectFit: 'cover'
    },
    [theme.breakpoints.down("md")]: {
      width: '25rem',
      height: '22rem',
      objectFit: 'contain'
    },
    [theme.breakpoints.down("sm")]: {
      width: '15rem',
      height: '16rem',
      objectFit: 'fill',


    },

  }));

  //     color: '#281900',
  //     fontWeight: 700,
  //     textAlign: 'left',
  //     fontStyle: 'normal',
  //     display:'block',
  //     flexDirection:'column',
  //     alignItems:'center',
  //     textWrap:'wrap',

  //     [theme.breakpoints.up("lg")]: {
  //         fontSize: '1.1rem',
  //         lineheight:'1.75rem',

  //         textAlign:'left',
  //         marginBottom:'rem',
  //         margintop:'1rem',


  //     },
  //     [theme.breakpoints.up("xl")]: {
  //         fontSize: '1.5rem',
  //         lineheight:'1.75rem',
  //         textAlign:'left',
  //         marginBottom:'0rem',
  //         margintop:'0rem'

  //     },
  //     [theme.breakpoints.between("md", "lg")]: {
  //         fontSize: '1.2rem',
  //         lineheight:'2rem',
  //        textAlign:'left',
  //         marginBottom:'1.5rem',
  //         margintop:'1.8rem' ,
  //         width:'60%',
  //     },

  //     [theme.breakpoints.down("md")]: {
  //         fontSize: '0.78rem',
  //         lineheight:'1rem',
  //        textAlign:'left',
  //         marginBottom:'2.5rem',
  //         margintop:'1.8rem' 
  //     },

  //     [theme.breakpoints.down("sm")]: {
  //         fontSize: '0.49rem',
  //         lineheight:'0.75rem',
  //        textAlign:'left',
  //         marginBottom:'0rem',
  //         width:'45%',
  //         // marginTop:'1rem' 
  //     },

  // }));

  const handleClose = () => {
    setOpen(false);
  };
  const [open, setOpen] = React.useState(false);

  const phoneRegExp =
    /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;

  const validate = Yup.object().shape({
    name: Yup.string()
      .min(2, "Too Short!")
      .max(20, "Too Long!")
      .required("Required"),
    email: Yup.string().email("Invalid email").required("Required"),
    phoneNumber: Yup.string()
      .matches(phoneRegExp, "Phone Number is not valid")
      .min(5, "Too Short!")
      .max(10, "Too Long!")
      .required("Required"),
    city: Yup.string()
      .min(2, "Too Short!")
      .max(20, "Too Long!")
      .required("Required"),
    query: Yup.string().required("Required"),
  });

  const formik = useFormik({
    initialValues: {
      name: "",
      email: "",
      phoneNumber: "",
      city: "",
      query: "",
    },
    validationSchema: validate,
    onSubmit: (values, onSubmitProps) => {
      dispatch(
        submitDetailsHome({
          Name: values.name,
          Email: values.email,
          City: values.city,
          Phone: values.phoneNumber,
          Query: values.query,
          AgentCode: 0,
          GST: 0,
          EnquiryDate: new Date(),
          InvoiceNumber: 0,
          Invoice: "",
          LastModified: "",
          OTP: 0,
          OTPGenerated: "",
          OTPVerified: false,
          Password: "",
          Payment: 0,
          PaymentLink: "",
          PaymentMode: "",
          ProfilePic: "",
          Service: "Home",
          Source: "Web",
          FormJSON: {
            name: "nil",
            gmail: "nil",
          },
          Status: "In Progress",
          Type: "Lead",
          createdAt: new Date()
        })

      );

      setOpen(true);
      onSubmitProps.resetForm()

    },
  });


  return (
    <div style={{ marginTop: "20px" }}>
      <div className='Container'>

      <Container>
        <Grid container spacing={1}
          direction={{ xs: 'column', sm: 'column', md: 'row-reverse', lg: 'row-reverse', xl: 'row-reverse' }}
          justifyContent={{ xs: 'center' }}
          alignContent={{ xs: 'center' }}>
          <Grid item xs={12} sm={12} md={12} lg={3} xl={3}>
            <Box border={2} sx={{ borderColor: "LightGrey" }}>
              <Text_contact>Get In Touch</Text_contact>

              <form style={{ padding: '0 20px 10px 20px' }} onSubmit={formik.handleSubmit}>
                <TextField
                  inputProps={{
                    style: {
                      padding: 8
                    }
                  }}
                  style={{ width: "100%", margin: "4px", padding: '0px' }}
                  type="text"
                  name='name'
                  id="name"
                  variant="outlined"
                  placeholder='Name'
                  value={formik.values.name}
                  onChange={formik.handleChange}
                  error={formik.touched.name && Boolean(formik.errors.name)}
                  helperText={formik.touched.name && formik.errors.name}

                />
                <br />
                <TextField
                  inputProps={{
                    style: {
                      padding: 8
                    }
                  }}
                  style={{ width: "100%", margin: "4px", padding: '0px' }}
                  type="email"
                  id="email"
                  name='email'
                  placeholder='Email'
                  variant="outlined"
                  value={formik.values.email}
                  onChange={formik.handleChange}
                  error={formik.touched.email && Boolean(formik.errors.email)}
                  helperText={formik.touched.email && formik.errors.email}
                />
                <br />
                <TextField
                  inputProps={{
                    style: {
                      padding: 8
                    }
                  }}
                  style={{ width: "100%", margin: "4px", padding: '0px' }}
                  type="number"
                  id='phoneNumber'
                  name='phoneNumber'
                  variant="outlined"
                  placeholder='Phone'
                  value={formik.values.phoneNumber}
                  onChange={formik.handleChange}
                  error={
                    formik.touched.phoneNumber && Boolean(formik.errors.phoneNumber)
                  }
                  helperText={
                    formik.touched.phoneNumber && formik.errors.phoneNumber
                  }
                />
                <br />
                <TextField
                  inputProps={{
                    style: {
                      padding: 8
                    }
                  }}
                  style={{ width: "100%", margin: "4px", padding: '0px' }}
                  type="text"
                  id='city'
                  name='city'
                  variant="outlined"
                  placeholder='City'
                  value={formik.values.city}
                  onChange={formik.handleChange}
                  error={formik.touched.city && Boolean(formik.errors.city)}
                  helperText={formik.touched.city && formik.errors.city}
                />
                <br />
                <TextField
                  inputProps={{
                    style: {
                      padding: 8
                    }
                  }}
                  style={{ width: "100%", margin: "4px", padding: '0px' }}
                  type="text"
                  id='query'
                  name='query'
                  placeholder='Query'
                  variant="outlined"
                  multiline
                  maxRows={2}
                  value={formik.values.query}
                  onChange={formik.handleChange}
                  error={formik.touched.query && Boolean(formik.errors.query)}
                  helperText={formik.touched.query && formik.errors.query}
                />
                <br />

                <ThemeProvider theme={theme}>
                  <span> <Button variant="contained" color="primary" style={{ margin: '4px', padding: '8px', }} type='submit'> Submit </Button>
                    <Button variant="contained" color="secondary" style={{ margin: '4px', padding: '8px' }}> Talk to Advisor </Button></span>
                </ThemeProvider>
              </form>
            </Box>
            <Grid className='contact-details' style={{ display: 'flex', margin: '5% 0 0 0' }}>
              <Grid>
                <img src={Logo} alt="logo" style={{ height: '2em', width: '12em', objectFit: 'cover' }} />
                <Typography style={{ margin: '0', fontWeight: 'bold' }}> Connect With Us On:</Typography>
                <span >
                <span style={{ padding: '0.6em' }}><a href="https://www.facebook.com/legalfatory"><FacebookIcon style={{ color: theme.palette.primary.main }} /></a></span>

                <span style={{ padding: '0.6em' }}><a href='https://twitter.com/Legalfactoryin'><TwitterIcon style={{ color: theme.palette.primary.main }} /></a></span>

                  <span style={{ padding: '0.6em' }}><a href='https://www.instagram.com/legalfactory.in'><InstagramIcon style={{ color: theme.palette.primary.main }} /></a></span>

                  <span style={{ padding: ' 0.6em ' }} ><a href="https://api.whatsapp.com/send?phone=9587754754"><WhatsAppIcon style={{ color: theme.palette.primary.main }} /></a></span>


                  
                </span>
              </Grid>
              <Grid style={{ margin: '2.3em 0 0 0' }}>
                <Typography style={{ margin: '0', fontWeight: 'bold', color: theme.palette.primary.main }}>Contact Us:</Typography>
                <Link style={{ color: 'black', fontSize: "15px" }}>support@legalfactory.in</Link>

              </Grid>
            </Grid>
          </Grid>



          <Grid item xs={12} sm={12} lg={8} xl={8}>
            <Box sx={{ display: 'flex' }}>
              <Box sx={{ backgroundImage: `url(${Background})`, backgroundRepeat: "no-repeat", objectFit: 'contain', backgroundSize: '140% 110%', height: '1%' }} spacing={2} >

                <Image_contact component="img"
                  sx={{
                    content: {
                      xs: `url(${Model})`
                    }
                  }}>
                </Image_contact>
              </Box>

              <MarketingComponent />


            </Box>
          </Grid>
        </Grid>
        <Dialog
          open={open}
          onClose={handleClose}
          aria-labelledby='alert-dialog-title'
          aria-describedby='alert-dialog-description'
        >
          <DialogTitle id='alert-dialog-title'>
            <Typography variant='h6' color='#E99101' fontFamily='Sora' fontWeight={600}>Thank You For Getting in touch!</Typography>
            <Divider sx={{ borderBottomWidth: '2px', background: '#4A2902', mt: 1 }} />
          </DialogTitle>
          <DialogContent>
            <DialogContentText id='alert-dialog-description'>
              <Typography fontFamily="Sora" fontWeight={550} >We appreciate you contacting us. One of your colleagues will get back in touch with you soon!
                <br />
                Have a great day!
              </Typography>

            </DialogContentText>
          </DialogContent>
          <DialogActions>

            <Box component="img"
              sx={{
                content: {
                  xs: `url(${Logo})`
                },
                width: '180px',
                height: 'auto',
                [theme.breakpoints.down("sm")]:{
                  marginRight:'85px'
                },
                [theme.breakpoints.down("md")]:{
                  marginRight:'290px'
                },
                [theme.breakpoints.up("lg")]:{
                  marginRight:'290px'
                },
                }} />
           

            <Button onClick={handleClose} variant='contained' sx={{ background: '#4A2902', color: 'white' }}>Close</Button>
          </DialogActions>
        </Dialog>


      </Container>

    </div>
    </div>
  )
}




export default ContactForm;

