import React from "react";
import { Box } from "@mui/material";
import Mainhead from "../../../Components/Services/MainContent/Mainhead";
import Content from "../../../Components/Services/MainContent/Content";
import CustomList from "../../../Components/Income-Tax/CustomList";
import Step from "../../../Components/Services/MainContent/Step";
import { Image } from "../../../Components/Income-Tax/ImageStyle";
import Image8 from "../../../Assets/IncomeTax/salary/image3.jpg";
const GratuityIndia = () => {
  return (
    <Box
      style={{
        display: "flex",
        flexDirection: "column",
        gap: 4,
        marginBottom: "2rem",
      }}
    >
      <Mainhead heading="Gratuity in India" />

      <Step
        number={`
          Introduction
        `}
      />
      <Content paragraph="Employers provide gratuities to their staff. Following an amendment to Section 10(10) of the Income Tax Act, dated 28th March 2019, clarified that the exemption limit of Rs. 20 lakh would be applicable on employees in the event of death, retirement, disablement, or resignation on or after 29th March 2018." />

      <Step
        number={`
          What is the meaning of Gratuity as per Income Tax Act 1961 in India?
        `}
      />
      <Content paragraph="Gratuity is not included in the base pay for each month. It is a form of financial compensation provided by the employer to staff members. The Gratuity Payment Act of 1972 governs the provision of gratuities, which are granted upon the occurrence of any of the following events:" />
      <CustomList
        points={[
          "On superannuation",
          "On retirement or resignation",
          "On death or disablement due to accident or disease (time limit of 5 years shall not be applicable in the case of death or disablement)",
        ]}
      />
      <Content paragraph="Note: At least five years of service must have been completed by employees." />

      <Step
        number={`
          Is every employee entitled to Gratuity in India?
        `}
      />
      <CustomList
        points={[
          "Employees are eligible for gratuity if they met the following criteria: Firstly if they worked full-time for the organisation and received full-time wages during the terms of employment.",
          "The employee must have been with the company for at least 5 years.",
          "Employees are entitled to a gratuity if they resign, die, or become disabled. It will not be subject to the 5-year condition.",
        ]}
      />

      <Step
        number={`
          Does every organisation is covered under Gratuity in India?
        `}
      />
      <Content paragraph="A gratuity is due to any individual who worked in a factory, mine, railway, or retail establishment with ten or more workers on any given day during the preceding year." />
      <CustomList
        points={[
          "In the event that there are fewer than ten employees, the gratuity act is still in effect.",
        ]}
      />

      <Step
        number={`
          Taxability of Gratuity in India
        `}
      />
      <Content paragraph="Section 10(10) of the Income Tax Act 1961: Gratuity received by employees is exempt from tax up to a certain limit. For government employees, the entire gratuity amount is tax-exempt. For non-government employees, the exemption limit is as per the provisions of the Income Tax Act." />
      <Box sx={{ textAlign: "center" }}>
        <Image
          component="img"
          sx={{
            content: {
              xs: `url(${Image8})`,
            },
          }}
        />
      </Box>
    </Box>
  );
};

export default GratuityIndia;
