import React from 'react';
import { Typography,styled, useTheme } from '@mui/material';

const Gap = styled(Typography)(({ theme }) => ({
   marginBottom:"5rem",
[theme.breakpoints.between("md", "lg")]: {
    marginBottom:"3rem",
},
[theme.breakpoints.down("md")]: {
    marginBottom:"2.5rem",
},
[theme.breakpoints.down("sm")]: {
    marginBottom:"2rem",
},
}));



function spacing(props) {
    return (
      <div>
        
          <Gap>
            {props.spacing}
          </Gap>

         
      </div>
    )
  }
  
  export default spacing;