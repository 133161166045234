import React from 'react'
import Mainhead from '../../../../Components/Services/MainContent/Mainhead';
import Content from '../../../../Components/Services/MainContent/Content';
import List from '../../../../Components/Services/MainContent/List';
import { Box , styled} from '@mui/material';
import Step from '../../../../Components/Services/MainContent/Step';
import LimitedBanner from "../../../../Assets/Services/LimitedLiability/LegalFactory_Limited1.png"

const Limitedimg = styled(Box)(({ theme }) => ({
    width:'34rem',
    height:'36rem',
    marginTop:'0.8rem',
    [theme.breakpoints.down("sm")]: {
      width:'18rem',
    height:'21.5rem',
    },
    [theme.breakpoints.down("md")]: {
      width:'18rem',
    height:'21.5rem',
    },
    [theme.breakpoints.between("md", "lg")]: {
      width:'25rem',
      height:'28rem',
    }
  }));

function Advantage(){
    return(
        <div>
            <Mainhead heading="Checklist for LLP Registration"/>
            <ul className="list">
              <li className='bullet'>
                <span>
            <List points="Separate Legal Entity: An LLP like a company, is a separate legal entity. It helps gain the trust of various stakeholders and customers. Even though one of the partners left, the business still remains, as it is distinct from its partners. Ownership can be transferred simply as any person can easily be inducted as a designated partner and the ownership switches to them."/>
            </span>
            </li>
            <li className='bullet'>
                <span>
            <List points="Limited Liability: The partnership is structured in such a way that the partners are only liable only to the contributions made by them, meaning that they are liable to pay only the amount of contribution made by them and are personally not liable for any loss in business. The partners have no personal liabilities, and thus they are free to operate as credible businessmen."/>
            </span>
            </li>
            <li className='bullet'>
                <span>
            <List points="Minimum Capital Contribution: It can be registered without any minimum capital, i.e. there is no requirement of having a minimum paid-up capital before going for incorporation. Having a minimum capital amount of less than 25 lakhs and a turnover below 40 lakhs per year,so it does not require any formal audits."/>
            </span>
            </li>
            </ul>

            <Box sx={{textAlign:'center'}}>
              <Limitedimg component="img"
                  sx={{
                    content: {
                      xs: `url(${LimitedBanner})`
                    }
                  }}/>
              </Box>
            
        </div>
    )
}

export default Advantage