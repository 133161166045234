import React from "react";
import { Box } from "@mui/material";
import Mainhead from "../../../Components/Services/MainContent/Mainhead";
import Content from "../../../Components/Services/MainContent/Content";
import CustomList from "../../../Components/Income-Tax/CustomList";
import ReusableTable from "../../../Components/Income-Tax/ReusableTable";

const InsuranceAndCapitalGainComponent = () => {
  const setOffData = [
    { head: "INTRA HEAD", shortTerm: "Allowed", longTerm: "Allowed" },
    { head: "INTER HEAD", shortTerm: "Allowed", longTerm: "Not allowed" },
    { head: "CARRY FORWARD", shortTerm: "Allowed", longTerm: "Allowed" },
  ];
  return (
    <Box
      style={{
        display: "flex",
        flexDirection: "column",
        gap: 4,
        marginBottom: "2rem",
      }}
    >
      <Mainhead heading="Receipt from Insurance Parties" />

      <Content paragraph="What are the various Tax implications from Receipts from insurance? Receipts from insurance can have different tax implications when it comes to capital gains. The taxability of these receipts would depend on the nature of the receipt and the specific circumstances involved. Here are some scenarios regarding the taxability of insurance receipts in relation to capital gains:" />

      <CustomList
        points={[
          "IS THERE ANY TAX LIABILITY ON INSURANCE PROCEEDS FROM CAPITAL ASSETS?",
          "If an insurance payout is received due to the destruction, damage, or loss of a capital asset (such as a property, stocks, or bonds), the tax treatment will depend on how the insurance proceeds are utilized.",
          "If the insurance proceeds are used to repair or replace the capital asset that suffered damage or loss, typically, there might not be any immediate capital gains tax implications.",
          "However, if the insurance payout exceeds the original cost or the written-down value of the asset, the surplus amount might be considered a capital gain. The excess amount could potentially be subject to capital gains tax.",
          "IS THERE ANY TAX LIABILITY ON INSURANCE PROCEEDS FROM INVESTMENTS?",
          "Sometimes, insurance claims can involve investments, such as life insurance policies or investment-linked insurance plans. The tax treatment of the proceeds from these policies could differ based on the specific policy terms and local tax laws.",
          "If the proceeds from such policies or investments exceed the premiums paid or the cost basis, the excess amount might be considered as capital gains and could be subject to capital gains tax.",
          "Tax on Surrendering Insurance Policies:",
          "Surrendering or canceling certain insurance policies might result in receiving a lump sum amount. Depending on the policy type and its terms, the excess of this amount over the premiums paid could be treated as income, potentially subject to capital gains tax.",
          "The tax treatment of insurance receipts in relation to capital gains is complex and can vary based on several factors, including the nature of the insurance, the purpose of the payment, the type of asset involved, and local tax regulations.",
        ]}
      />

      {/* Set Off of Capital Gain Losses */}
      <Mainhead heading="SET OFF OF CAPITAL GAIN LOSSES" />

      <Content paragraph="If you sell a capital asset for an amount that is lower than the adjusted purchase price (i.e. after indexation), you make a capital loss." />

      <Content paragraph="SET OFF OF LOSSES" />

      <Content paragraph="The capital losses that you incur in a given year can be adjusted against the profit or income earned. This is known as the set off of losses." />

      <Content paragraph="CARRY FORWARD OF LOSSES" />

      <Content paragraph="If the complete amount of capital loss is not set off against the income for that particular year due to limitations, such remaining amount of capital loss can then be carried forward to subsequent years for setting off from income earned in those years." />

      <Content paragraph="TREATMENT OF CAPITAL LOSSES" />

      <Content paragraph="The treatment of capital loss in The Income Tax Act is given as under-" />

      <CustomList
        points={[
          "The set off and carry forward of losses will depend upon whether it is long term capital loss or short term capital loss.",
          "TREATMENT OF SHORT TERM CAPITAL LOSS",
          "Intra head set off: Short term capital losses can be set off against short term capital gains.",
          "Inter head set off- Short term capital loss can be set off against both short term and long term capital gain.",
          "Carry forward of losses- If the entire capital loss cannot be set off in the current financial year due to limitations, the unutilized loss can be carried forward to subsequent years. Short term capital losses can be carried forward for 8 subsequent years.",
          "TREATMENT OF LONG TERM CAPITAL LOSS",
          "Intra head set off: Long term capital losses can be set off against any long term capital gains.",
          "Inter head set off- Long term capital loss can be set off against only long term capital gain. These cannot be adjusted against gains made on short term capital assets.",
          "Carry forward of losses- If the entire capital loss cannot be set off in the current financial year due to limitations, the unutilized loss can be carried forward to subsequent years. Long term capital losses can be carried forward for 8 subsequent years.",
        ]}
      />

      <ReusableTable
        data={setOffData}
        columns={[
          { label: "Head", key: "head" },
          { label: "Short Term Capital Loss", key: "shortTerm" },
          { label: "Long Term Capital Loss", key: "longTerm" },
        ]}
      />
      <Mainhead heading="TREATMENT AGAINST EXEMPT INCOME" />

      <Content
        paragraph="
Capital losses cannot be set off against exempt income. For example, if there are capital losses and exempt income like agricultural income, the losses cannot be used to offset the tax on exempt income."
      />
    </Box>
  );
};

export default InsuranceAndCapitalGainComponent;
