import React from 'react'
import Mainhead from '../../../../Components/Services/MainContent/Mainhead';
import Content from '../../../../Components/Services/MainContent/Content';
import List from '../../../../Components/Services/MainContent/List';
import { Box, styled } from '@mui/material';
import Step from '../../../../Components/Services/MainContent/Step';




function Checklist() {
    return (
        <div>
            <Mainhead heading="Checklist for LLP Registration " />
            <ul className="list">
                <li className="bullet">
                    <span>
                        <List points="Minimum of two partners." />
                    </span>
                </li>
                <li className="bullet">
                    <span>
                        <List points="DSC for all designated partners." />
                    </span>
                </li>
                <li className="bullet">
                    <span>
                        <List points="DPIN for all designated partners." />
                    </span>
                </li>
                <li className="bullet">
                    <span>            <List points="Name of the LLP, which is not similar to any existing LLP or trademark" />
                    </span>
                </li>
                <li className="bullet">
                    <span>
                        <List points="Capital contribution by the partners of the LLP." />
                    </span>
                </li>
                <li className="bullet">
                    <span>
                        <List points="LLP Agreement between the partners." />
                    </span>
                </li>
                <li className="bullet">
                    <span>
                        <List points="Proof of registered office of the LLP." />
                    </span>
                </li>
            </ul>

        </div>


    )
}

export default Checklist