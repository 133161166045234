import React from 'react';
import Mainhead from '../../../../Components/Services/MainContent/Mainhead';
import Content from '../../../../Components/Services/MainContent/Content';
import List from '../../../../Components/Services/MainContent/List';
import Step from '../../../../Components/Services/MainContent/Step';

function Legalfactory() {
  return (
    <div>
      <Mainhead heading="Why LEGAL FACTORY Partnership Firm?"/>
      <Content paragraph="LEGAL FACTORY a unit of Xpert finance is one of the the fastest going Fintech company. Legal factory and Xpert finance team of young and enthusiastic qualified professionals who care focused to provide excellent professional services with quality, innovation and highest standard of client’s satisfaction in the field of Corporate Law, Securities Law, Capital Market Compliance, SEBI, RBI, MSMEs and Intellectual property rights services. We have expertise in financial markets in the area of Compliance, Membership, Internal Audit, Anti Money Laundering & Insider Trading, System Audits and Cyber Securities Audit ."/>
      <br/>
      <Content paragraph="LEGAL FACTORY is cognizant of the new process. Our team is experienced like old wine and, we have helped many start up, traders, manufacturers, entrepreneurs, financial advisories, ad agencies, professionals register their businesses in India. Entire India , our services are comprehensive and reach every corner of the country ."/>
      <br/>
      <Content paragraph="With Legal factory and Xpert finance setting up business is an easy , 100% reliable, secure, fast process . When you choose us to be your partner to register your business , you get to avail a few undeniable benefits and in case of amendments or changes from the government/ regulator our experienced Dedicated compliance team takes care of them for you."/>
      <br/>

      <Step number="We also provide assistance with:"/>
      <ul className='list'>
        <li className='bullet'>
          <span>
      <List points="A first free consultation, followed by subsequent meetings to clear every concern you may face."/>
        </span>
        </li>
        <li className='bullet'>
          <span>
      <List points="Complete support on opening a Current Bank Account with partner banks."/>
      </span>
        </li>
      <li className='bullet'>
          <span>
      <List points="Comprehensive and on-time updates on ROC compliances."/>
      </span>
        </li>
      <li className='bullet'>
          <span>
      <List points="Purchase of Online Accounting Software or exclusive account manager for handling accounts ."/>
      </span>
        </li>
      <li className='bullet'>
          <span>
      <List points="A Master file that contains all the documented needed to file the incorporation."/>
      </span>
        </li>
      <li className='bullet'>
          <span>
      <List points="A Dedicated Admin manager is present at all times to ensure quality of service and maintenance of timeliness and schedules."/>
      </span>
        </li>
        </ul>
      <Content paragraph="Being a seperate legal entity from the existing partnership firm, LLP will have its unique name pan card so it is required a new/separate GST Registration on the name of LLP."/>
      <br/>
      <Content paragraph="Confidentiality is one of the most important aspects of our work. We guarantee the privacy of our customers through three key components. Our customer database cannot be printed or exported. So, all the confidential data or personal data of our customers is strictly confined to our offices."/>
      
      
        
    </div>
  )
}

export default Legalfactory