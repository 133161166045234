import React from 'react';
import Mainhead from '../../../../Components/Services/MainContent/Mainhead';
import Content from '../../../../Components/Services/MainContent/Content';
import List from '../../../../Components/Services/MainContent/List';


function Legalfactory() {
  return (
    <div>
      <Mainhead heading="WHY LEGAL FACTORY?"/>
      <Content paragraph="Legal Factory which is a unit of/powered by expert finance is an umbrella organisation offering Fintech services to its clients with the aid of its dynamic team of Chartered accountants, Cost and Management Accountants, Company Secretaries, Corporate Lawyers, Civil Lawyers, Registered Valuers & Chartered Engineers. Registering different forms of entities is our Forte."/>
      <ul className="list">
        <li className="bullet">
          <span>
      <List points="Lowest price: We here at Legal Factory charge nominal fees."/>
      </span>
      </li>
      <li className="bullet">
          <span>
      <List points="CA/CS assistance: Support from our in-house expert professionals."/>
      </span>
      </li>
      <li className="bullet">
          <span>
      <List points="Confidentiality: All your data is secured within our system."/>
      </span>
      </li>
      <li className="bullet">
          <span>
      <List points="Price Transparency: Transparent pricing for all our services, our professional fee, government fee, and GST"/>
      </span>
      </li>
      <li className="bullet">
          <span>
      <List points="Business Relations: We emphasize on a healthy customer-business relationship."/>
      </span>
      </li>
      <li className="bullet">
          <span>
      <List points="Customer Aid: We are always available for our customers, both service providers & service receivers, at all stages of the registration process"/>
      </span>
      </li>
      </ul>
    </div>
  )
}

export default Legalfactory