import React from "react";
import { Box } from "@mui/material";
import Mainhead from "../../../Components/Services/MainContent/Mainhead";
import Content from "../../../Components/Services/MainContent/Content";
import Step from "../../../Components/Services/MainContent/Step";
import ReusableTable from "../../../Components/Income-Tax/ReusableTable";

const CapitalGainPeriod = () => {
  // Data for the short-term capital gain table
  const shortTermCapitalData = [
    {
      natureOfAssets: "Listed shares",
      periodOfHolding: "Up to 12 months",
      natureOfCapitalGain: "Short term",
    },
    {
      natureOfAssets: "Unlisted share",
      periodOfHolding: "Up to 24 months",
      natureOfCapitalGain: "Short term",
    },
    {
      natureOfAssets: "Listed debenture/ bonds",
      periodOfHolding: "Up to 12 months",
      natureOfCapitalGain: "Short term",
    },
    {
      natureOfAssets: "Unlisted debenture / bonds",
      periodOfHolding: "Up to 36 months",
      natureOfCapitalGain: "Short term",
    },
    {
      natureOfAssets: "Zero rated coupon bond",
      periodOfHolding: "Up to 12 months",
      natureOfCapitalGain: "Short term",
    },
    {
      natureOfAssets: "Units of UTI or equity-oriented fund",
      periodOfHolding: "Up to 12 months",
      natureOfCapitalGain: "Short term",
    },
    {
      natureOfAssets: "Units except UTI and equity-oriented fund",
      periodOfHolding: "Up to 36 months",
      natureOfCapitalGain: "Short term",
    },
    {
      natureOfAssets: "Land, building etc.",
      periodOfHolding: "Up to 24 months",
      natureOfCapitalGain: "Short term",
    },
    {
      natureOfAssets: "Any assets other than above - gold, land etc.",
      periodOfHolding: "Up to 36 months",
      natureOfCapitalGain: "Short term",
    },
  ];

  // Data for the long-term capital gain table
  const longTermCapitalData = [
    {
      natureOfAssets: "Listed shares",
      periodOfHolding: "More than 12 months",
      natureOfCapitalGain: "Long term",
    },
    {
      natureOfAssets: "Unlisted share",
      periodOfHolding: "More than 24 months",
      natureOfCapitalGain: "Long term",
    },
    {
      natureOfAssets: "Listed debenture/ bonds",
      periodOfHolding: "More than 12 months",
      natureOfCapitalGain: "Long term",
    },
    {
      natureOfAssets: "Unlisted debenture / bonds",
      periodOfHolding: "More than 36 months",
      natureOfCapitalGain: "Long term",
    },
    {
      natureOfAssets: "Zero rated coupon bond",
      periodOfHolding: "More than 12 months",
      natureOfCapitalGain: "Long term",
    },
    {
      natureOfAssets: "Units of UTI or equity-oriented fund",
      periodOfHolding: "More than 12 months",
      natureOfCapitalGain: "Long term",
    },
    {
      natureOfAssets: "Units except UTI and equity-oriented fund",
      periodOfHolding: "More than 36 months",
      natureOfCapitalGain: "Long term",
    },
    {
      natureOfAssets: "Land, building etc.",
      periodOfHolding: "More than 24 months",
      natureOfCapitalGain: "Long term",
    },
    {
      natureOfAssets: "Any assets other than above - gold, land etc.",
      periodOfHolding: "More than 36 months",
      natureOfCapitalGain: "Long term",
    },
  ];

  return (
    <Box
      style={{
        display: "flex",
        flexDirection: "column",
        gap: 4,
        marginBottom: "2rem",
      }}
    >
      {" "}
      <Mainhead heading="Period for Short Term Capital Gain & Long Term Capital Gain" />
      <Content paragraph="Every asset has a unique time period that determines whether it falls under the category of short-term or long-term capital gain." />
      <Step
        number={`
            FOR WHAT PERIOD CAPITAL GAIN WOULD BE TREATED AS SHORT TERM CAPITAL GAIN?
          `}
      />
      <Content paragraph="Short-term capital gains occur when an asset is sold after only a short time of ownership." />
      {/* Table for Short Term Capital Gain */}
      <ReusableTable data={shortTermCapitalData} />
      <Step
        number={`
            FOR WHAT PERIOD CAPITAL GAIN WOULD BE TREATED AS LONG TERM CAPITAL GAIN?
          `}
      />
      <Content paragraph="Long-term capital gains come into play when an asset is held for more than a specified duration, often exceeding one year." />
      {/* Table for Long Term Capital Gain */}
      <ReusableTable data={longTermCapitalData} />
      <Content
        paragraph={`Note: After 1st April 2023, Debt Mutual Fund will be considered as short term.`}
      />
    </Box>
  );
};

export default CapitalGainPeriod;
