import React from 'react'
import Mainhead from '../../../../Components/Services/MainContent/Mainhead';
import Content from '../../../../Components/Services/MainContent/Content'
import Step from '../../../../Components/Services/MainContent/Step';
import List from '../../../../Components/Services/MainContent/List';
function Records() {
  return (
    <div>
      <Mainhead heading="Some Records that are maintained under the enactment of Shop & Establishment Act :"/>
      <Content paragraph="Under the Shop & Establishment Act, every business has to seek approval from the Department of Labour and keep up-to-date registers regarding details of employment, fines, deductions and advances, salary and holidays. However, the requirements may vary from state to state. Files related to annual holidays and the number of employees need to be submitted to the office of the Municipal Corporation annually further no Regular return needs to be filed under this Act."/>

      <Step number="However some of the records that might need to be maintained under the Act are:"/>
      <ul className='list'>
        <li className='bullet'>
          <span>
      <List points="National holidays"/>
      </span>
      </li>
      <li className='bullet'>
          <span>
      <List points="Hours of work including over-time"/>
      </span>
      </li>
      <li className='bullet'>
          <span>
      <List points="Prohibition of employment of children"/>
      </span>
      </li>
      <li className='bullet'>
          <span>
      <List points="Terms & conditions"/>
      </span>
      </li>
      <li className='bullet'>
          <span>
      <List points="Employment of young person/women"/>
      </span>
      </li>
      <li className='bullet'>
          <span>
      <List points="Opening and closing hours"/>
      </span>
      </li>
      <li className='bullet'>
          <span>
      <List points="Conditions on providing the wages"/>
      </span>
      </li>
      <li className='bullet'>
          <span>
      <List points="Weekly holidays and wages on holidays"/>
      </span>
      </li>
      <li className='bullet'>
          <span>
      <List points="Time of payment"/>
      </span>
      </li>
      <li className='bullet'>
          <span>
      <List points="Leave policy"/>
      </span>
      </li>
      <li className='bullet'>
          <span>
      <List points="Work Environment"/>
      </span>
      </li>
      <li className='bullet'>
          <span>
      <List points="Accidents"/>
      </span>
      </li>
      </ul>
    </div>
  )
}

export default Records