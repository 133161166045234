import React from 'react';
import Sidecomponent from '../../../Components/Tax-Planning/Sidecomponent';
import Topdiv from "../../../Components/Tax-Planning/Topdiv"
import GetInTouch from '../../../Components/Services/GetInTouch';
import {
  Grid, Box, Container, styled, Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Link
} from '@mui/material';


import Mainhead from '../../../Components/Services/MainContent/Mainhead';
import Content from '../../../Components/Services/MainContent/Content';
import List from '../../../Components/Services/MainContent/List';
import Step from '../../../Components/Services/MainContent/Step';

import TableCell, { tableCellClasses } from '@mui/material/TableCell';

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: '#773106',
    color: theme.palette.common.white,
    textAlign: 'left',
    fontFamily: 'Sora',
    fontWeight: 'bold',



  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 16,
    textAlign: 'left',
    textAlign: 'left',
    fontFamily: 'Sora',
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(even)': {
    backgroundColor: theme.palette.action.hover,
    fontFamily: 'Sora'
  },

  '&:last-child td, &:last-child th': {
    border: 0,
  },
}));

function createData(
  Basic,
  Agriculture
) {
  return {
    Basic,
    Agriculture
  };
}

function createData1(
  Activities,
  Non
) {
  return {
    Activities,
  Non 
  };
}

function createData2(
  Following,
  Operations,
  Nonoperations
) {
  return {
    Following,
    Operations,
    Nonoperations
  };
}

const rows = [
  createData('Basic operation','Agriculture Activity'),
  createData('Subsequent Operation','Not agriculture activity'),
  createData('Both basic and subsequent operation', 'Agriculture activity')
];

const rows1 = [
createData1('Income from growing flowers and creepers','Income from fishing'),
createData1('Plant sold in pots if basic operations performed','Income from poultry farm'),
createData1('Income from trade and commercial product like jute','royalty income from mines'),
createData1('Remuneration and interest to partner from firm engaged in agriculture operations','Income from agriculture land situated from outside india'),

];

const rows2 = [
createData2('Growing and manufacturing of Tea ','60%','40%'),
createData2('Growing anf manufacturing of Rubber','60%','40%'),
createData2('Growing and manufacturing coffee if coffee grwon and cured by seller','75%','25%'),
createData2('If coffee grown,cured and roasted and grounded by seller','60%','40%')
];

const Bottomspace = styled(Box)(({ theme }) => ({
  marginBottom:'2.1rem'
}));

function Income() {
  return (

    <div>
       <Topdiv heading="Are you looking to file your Income Tax Returns ?"/>
       <div className='Container'>
      <Container >
        <Grid container>
          <Grid item  lg={4} xl={3}>
            <Box sx={{ display: { xs: 'none', lg: 'block' }, marginTop: '4rem', position: 'sticky', top: 90, marginBottom: '3rem' }}>
              <Sidecomponent />
            </Box>
          </Grid>
          <Grid item xs={11} lg={8} sx={{ marginTop: '4rem' }} xl={9}> 
            <Mainhead heading="AGRICULTURE INCOME" />
            <Step number="Any RENT OR REVENUE DERIVED from land -"/>
            <ul className='list'>
              <li className='bullet'>
                <span>
             <List points="Which is situated in india"/>
             </span>
             </li>
             <li className='bullet'>
                <span>
             <List points="Used for agriculture purpose"/>
             </span>
             </li>
             <li className='bullet'>
                <span>
             <List points="Rent received may be cash or kind."/>
             </span>
             </li>
             <li className='bullet'>
                <span>
             <List points="The assessee may be the owner or tenant of such land."/>
             </span>
             </li>
             </ul>
             <Step number="Any income derived from such land BY AGRICULTURE OPERATION
Any income derived from such land by-"/>
<ul className='list'>
  <li className='bullet'>
    <span>
             <List points="The cultivator or receiver of rent in kind"/>
             </span>
             </li>
             <li className='bullet'>
            <span>
             <List points="By processing the agriculture produce"/>
             </span>
             </li>
             <li className='bullet'>
             <span>
             <List points="So as to RENDER IT FIT for sale in market"/>
             </span>
             </li>
             </ul>
             <Bottomspace/>
             <ul>
              <li style={{listStyle:'number'}}><Content paragraph="The process must be completed only to convert “The produce or rent in kind” in marketable form."/></li>
              <li style={{listStyle:'number'}}><Content paragraph="If the marketing process is performed on “The produce or rent in kind”which can be sold in its raw form in the market. Then income derived from such products is partly agriculture and partly Non agriculture income."/></li>
              </ul>
              <Mainhead heading="Any income derived from such land on sale made by -"/>
              <ul>
              <li style={{listStyle:'none'}}><Content paragraph="The cultivator or rent receiver in kind"/></li>
              <li style={{listStyle:'none'}}><Content paragraph="Of Agriculture produced Received."/></li>
              <li style={{listStyle:'none'}}><Content paragraph="Without carrying on any process"/></li>
              <li style={{listStyle:'none'}}><Content paragraph="Without carrying on any process Any income derived from Building subject to fulfilment the following condition"/></li>
              
             </ul>
             <Content paragraph="Occupation - Building must be occupied by cultivator or rent receiver must be in cash or in kind."/>
             <Content paragraph="Situation - The Building should be in or in the immediate vicinity of such agricultural land."/>
             <Content paragraph="Use of building - it must be used for a dwelling house or store house or out house. Land either in situated"/>
             <Content paragraph="If any land used for Non - agriculture purpose then any income derived from such land shall not be treated as agriculture income."/>
             <Bottomspace/>
             <Mainhead heading="AGRICULTURE OPERATION"/>
             <Content paragraph="FOR DETERMINED IN AGRICULTURE ACTIVITY IS DIVIDED IN TWO PARTS -
BASIC OPERATION - It means application of human labour and skill upon the land. E.G. planting , sowing of seeds"/>
<Content paragraph="NOTE : without any human efforts growth from land can not be treated as an agricultural operation."/>
<Content paragraph="SUBSEQUENT OPERATION : It means operation"/>
<ul>
  <li style={{listStyle:'number'}}><Step number="Which foster the growth and preserves the produce"/></li>
  <li style={{listStyle:'number'}}><Step number="For rendering the produce fit for sale in market"/></li>
  <li style={{listStyle:'number'}}><Step number="For rendering the produce fit for sale in market"/></li>
  </ul>
  <TableContainer component={Paper}>
                <Table aria-label="customized table" size='small'>
                  <TableHead>
                    <TableRow>
                      <StyledTableCell>Activity</StyledTableCell>
                      <StyledTableCell>Where Agriculture Activity Or NON - Agriculture Activity</StyledTableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {rows.map((row) => (
                      <StyledTableRow
                        key={row.name}

                      >
                        <StyledTableCell component="th" scope="row"
                        >
                          {row.Basic}
                        </StyledTableCell>
                        <StyledTableCell>{row.Agriculture}</StyledTableCell>
                      </StyledTableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
              <br/><br/>
  <Mainhead heading="Treatment of partly agriculture and partly Non - agriculture income ( rule 7)"/>
  <br/>
  <TableContainer component={Paper}>
                <Table aria-label="customized table" size='small'>
                  <TableHead>
                    <TableRow>
                      <StyledTableCell>Agriculture Activities</StyledTableCell>
                      <StyledTableCell>Non - Agriculture Activities</StyledTableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {rows1.map((row) => (
                      <StyledTableRow
                        key={row.name}

                      >
                        <StyledTableCell component="th" scope="row"
                        >
                          {row.Activities}
                        </StyledTableCell>
                        <StyledTableCell>{row.Non}</StyledTableCell>
                      </StyledTableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
              <br/><br/>
              <TableContainer component={Paper}>
                <Table aria-label="customized table" size='small'>
                  <TableHead>
                    <TableRow>
                      <StyledTableCell>Following Income are partly agriculture or partly Non- Agriculture</StyledTableCell>
                      <StyledTableCell>Agriculture</StyledTableCell>
                      <StyledTableCell>Non- Agriculture</StyledTableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {rows2.map((row) => (
                      <StyledTableRow
                        key={row.name}

                      >
                        <StyledTableCell component="th" scope="row"
                        >
                          {row.Following}
                        </StyledTableCell>
                        <StyledTableCell>{row.Operations}</StyledTableCell>
                        <StyledTableCell>{row.Nonoperations}</StyledTableCell>
                      </StyledTableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
              <br/><br/>
      <Mainhead heading="CAPTIVATE CONSUMPTION"/>
      <Content paragraph="CAPTIVE CONSUMPTION : in case of in house consumption of agriculture products. Then transfer from the agriculture unit to another unit will be at Fair market value. Means fair market value will be sale consideration in the hands of the agriculture unit."/>
      <Bottomspace/>
      <Mainhead heading="Content paragraph="/>
      <Content paragraph="Agriculture income is not included in total income. However in following conditions agriculture income included in non agriculture income for the purpose of Tax treatment .
"/>
      <Content paragraph="Applicability : The method of aggregation is applicable to individuals, HUF etc."/>
      <Content paragraph="Minimum agriculture income : it is applicable when agriculture income is exceed RS.5000"/>
      <Content paragraph="Minimum total income : The TI exceeds the minimum amount chargeable to tax i.e. 250000,300000,500000."/>
      <Bottomspace/>
      <Mainhead heading=""/>
      <Content paragraph=""/>
      <ul>
  <li style={{listStyle:'number'}}><Content paragraph="AGRICULTURE INCOME (SEC 10(1) : Any agriculture income in india is exempt. However Tea,coffee and rubber manufacturing income is partly exempt"/></li>
  <li style={{listStyle:'number'}}><Content paragraph="PROFIT SHARE RECEIVED BY MEMBER FROM HUF (sec 10(2) : it is exempt in the hands of members"/></li>
  <li style={{listStyle:'number'}}><Content paragraph="PROFIT SHARE RECEIVED BY PARTNER FORM FIRM ( sec 10 (2A) : It is exempt in hands of partner because firm already has given tax."/></li>
  <li style={{listStyle:'number'}}><Content paragraph="INTEREST RECEIVED BY INDIVIDUAL ON MONEY STANDING TO CREDIT OF NON- RESIDENT ( EXTERNAL A/C ) ON ANY INDIAN BANK : such individual must resident outside india under FEMA & he has permitted by RBI to maintain the Non- residential (external) account."/></li>
  <li style={{listStyle:'number'}}><Content paragraph="ALLOWANCE & PERQUISITES RECEIVED OUTSIDE INDIA BY INDIAN GOVERNMENT (SEC 10 (7)"/></li>
  <li style={{listStyle:'number'}}><Content paragraph="AMOUNT RECEIVED AS GRATUITY, PENSION, LEAVE ENCASHMENT, RETIREMENT COMPENSATION, VOLUNTARY RETIREMENT (VKS) : Exempt up to certain limit"/></li>
  <li style={{listStyle:'number'}}><Content paragraph="TAX ON NON - MONETARY PERQUISITES (SEC 10 (10CC) : Any tax paid by employer on behalf of employee on his non - monetary perquisites is exempt in hands of employee."/></li>
  <li style={{listStyle:'number'}}><Content paragraph="PAYMENT FROM STATUTORY PROVIDENT FUND(SPF), RECOGNISED PROVIDENT FUND (RPF) & APPROVED SUPERANNUATION FUND (ASF) - Is exempt"/></li>
  <li style={{listStyle:'number'}}><Content paragraph="COMPENSATION RECEIVED ON ACCOUNT OF DISASTER ( SEC 10(10BC) :Any amount received as compensation by individual or his legal heir on account of Disaster. Such compensation should be granted by CG OR SG AND LOCAL AUTHORITY."/></li>
  <li style={{listStyle:'number'}}><Content paragraph="SUM RECEIVED UNDER LIFE INSURANCE POLICY INCLUDING BONUS (SEC 10 (10 D) : Any sum received under life insurance policy ( including Bonus on such policy ) - shall be EXEMPT from tax."/></li>
  <li style={{listStyle:'number'}}><Content paragraph="PENSION FROM NATIONAL PENSION TRUST ( i.e. NPS 80CCD) SEC 10(12A) : Any payment received from NPS by AN EMPLOYEE
On closure of his account or on his opting out of the NPS pension scheme referred to u/s 80CCD
To the extent 60% of the amount payable to him at the time of closure of opting out is exempt ( 60% amount exempt )."/></li>
 

  </ul>
<Bottomspace/>
<Bottomspace/>
            </Grid>
            </Grid>
            
            </Container>

    </div>
    </div>
  )
}

export default Income