import React from "react";
import { Box } from "@mui/material";
import Mainhead from "../../../Components/Services/MainContent/Mainhead";
import Content from "../../../Components/Services/MainContent/Content";
import CustomList from "../../../Components/Income-Tax/CustomList";
import Step from "../../../Components/Services/MainContent/Step";
import NormalList from "../../../Components/Income-Tax/NormalList";

const DividendTax = () => {
  return (
    <Box
      style={{
        display: "flex",
        flexDirection: "column",
        gap: 4,
        marginBottom: "2rem",
      }}
    >
      <Mainhead heading="Taxation on Dividend" />
      <Content paragraph="Dividend is the distribution of profit by a company to its shareholders or owners." />

      <Step number="Introduction" />
      <Content paragraph="As per section 2(22) of the Income-tax Act, the dividend shall also include the following:" />
      <CustomList
        points={[
          "Distribution of accumulated profits to shareholders entailing release of the company's assets;",
          "Distribution of debentures or deposit certificates to shareholders out of the accumulated profits of the company and issue of bonus shares to preference shareholders out of accumulated profits;",
          "Distribution made to shareholders of the company on its liquidation out of accumulated profits;",
          "Distribution to shareholders out of accumulated profits on the reduction of capital by the company;",
          "Loan or advance made by a closely held company to its shareholder out of accumulated profits.",
        ]}
      />

      <Step number="Taxation of Dividend Income" />
      <Content paragraph="Up to 31st March 2020, Domestic companies were required to pay dividend distribution tax (DDT) if they were distributing dividends to their shareholders. This exempted the shareholders from paying any tax on such dividends." />
      <Content paragraph="But after the introduction of Finance Act 2020, the government has abolished DDT and dividend income has been made taxable in the hands of the investor." />

      <Step number="Taxability in Hands of Shareholders" />
      <Content paragraph="Dividend is treated as ‘income from other sources’ under the Income Tax Act." />
      <Content paragraph="FOR RESIDENT INDIVIDUALS" />
      <CustomList
        points={[
          "RATE OF TAX: The dividend income shall be chargeable to tax at normal tax rates as applicable in the case of the assessee.",
          "Exception: A concessional rate of 10% will be applicable in case of dividends received in respect of GDRs issued by way of ESOPs by any Indian IT, pharmaceutical or entertainment industry, provided that the employee has purchased in foreign currency.",
          "DEDUCTIONS: Any interest expenditure incurred to earn the dividend income to the extent of 20% of total dividend income can be claimed as deduction.",
        ]}
      />

      <Content paragraph="FOR NON-RESIDENT INDIVIDUALS" />
      <CustomList
        points={[
          "RATE OF TAX: The dividend income shall be chargeable to tax at the rate of 20% without providing for deduction under any provisions of the Income-tax Act.",
          "Exception: A concessional rate of 10% will be applicable - In case of dividends received in respect of GDRs issued by way of ESOPs by any Indian IT, pharmaceutical or entertainment industry, provided that the employee has purchased in foreign currency. Dividend income from the investment division of an offshore banking unit.",
        ]}
      />

      <Content paragraph="TAXABILITY UNDER DTAA: Dividend income is generally chargeable to tax in the source country as well as the country of residence of the assessee and, consequently, the country of residence provides a credit of taxes paid by the assessee in the source country. Thus, the dividend income shall be taxable in India as per provisions of the Act or as per relevant DTAA, whichever is more beneficial." />

      <Step number="DEDUCTION APPLICABLE U/S 57" />
      <Content paragraph="Section 57 of the Income Tax Act in India deals with deductions for any expenses incurred for the purpose of earning income." />

      <NormalList
        points={[
          "Expenses related to Interest and Dividends:",
          "- Deductions are allowed for any amount paid in the form of commission, remuneration, bank charges, brokerage fees, etc., to a banker for the collection of such income on behalf of the taxpayer or to any other person.",
          "- Interest on a loan taken to purchase a security can be claimed as a deduction up to a maximum of 20% of dividend income earned.",
        ]}
      />

      <NormalList
        points={[
          "Deduction on Family Pension:",
          "- Section 57 provides for a standard deduction on family pension. One-third of such family pension or Rs. 15,000, whichever is less, can be claimed as a deduction.",
        ]}
      />

      <NormalList
        points={[
          "Deduction on Rental Income:",
          "- Deductions are allowed for any expenditure laid out or expended wholly and exclusively for the purpose of making or earning such rental income. This can include interest paid on housing loans, municipal taxes, repair and maintenance charges, etc.",
        ]}
      />

      <NormalList
        points={[
          "Deduction on Business Income:",
          "- Deductions are allowed for any expenditure incurred wholly and exclusively for the purpose of making or earning such business income. This can include raw material purchased, salaries and wages, rent expenses, marketing and advertising expenses, etc.",
          "- Interest paid on a loan taken to invest in one’s business can be claimed as a deduction against income earned from business.",
        ]}
      />

      <Content paragraph="It's important to note that the expenses must be incurred wholly and exclusively for the purpose of earning the income. Additionally, the taxpayer needs to maintain proper documentation to support the claim for deductions." />
    </Box>
  );
};

export default DividendTax;
