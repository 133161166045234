import Sidecomponent from "../../../Components/Income-Tax/Sidecomponent";
import Topdiv from "../../../Components/Tax-Planning/Topdiv";

import { Grid, Box, Container } from "@mui/material";

import Faq from "../../../Components/Income-Tax/Faq";
import faqData from "./faqData";

import { menuItems } from "../CapitalGain/menuItems";
import Section58IncomeTax from "./Section58IncomeTax";
import SectionOtherIncomesTax from "./SectionOtherIncomesTax";
import SectionEmployeeContribution from "./SectionEmployeeContribution";
import SectionDeductionsAndTDS from "./SectionDeductionsAndTDS";
import DividendTax from "./DividendTax";
import InterestOnSecurities from "./InterestOnSecurities";
import LifeInsuranceTaxability from "./LifeInsuranceTaxability";
import GiftTaxation from "./GiftTaxation";
import CommissionTaxation from "./CommissionTaxation";
import Section562Taxation from "./Section56_2Taxation";

const Othersource = () => {
  return (
    <div>
      <Topdiv heading="Are you looking to file your Income Tax Returns ?" />
      <div className="Container">
        <Container>
          <Grid container>
            <Grid item lg={4} xl={3}>
              <Box
                style={{
                  display: { xs: "none", lg: "block" },
                  marginTop: "4rem",
                  position: "sticky",
                  top: 90,
                  marginBottom: "3rem",
                }}
              >
                <Sidecomponent menuItems={menuItems} />
              </Box>
            </Grid>

            <Grid item xs={11} lg={8} sx={{ marginTop: "4rem" }} xl={9}>
              <Section58IncomeTax />
              <SectionOtherIncomesTax />
              <SectionEmployeeContribution />
              <SectionDeductionsAndTDS />
              <DividendTax />
              <InterestOnSecurities />
              <LifeInsuranceTaxability />
              <GiftTaxation />
              <CommissionTaxation />
              <Section562Taxation />
              <Faq data={faqData} />
            </Grid>
          </Grid>
        </Container>
      </div>
    </div>
  );
};

export default Othersource;
