import React from 'react';
import Mainhead from '../../../../Components/Services/MainContent/Mainhead';
import Content from '../../../../Components/Services/MainContent/Content';
import List from '../../../../Components/Services/MainContent/List';
import { Box , styled} from '@mui/material';
import Registration from "../../../../Assets/Services/Private/LegalFactory_Private1.png"

const Registrationimg = styled(Box)(({ theme }) => ({
  width:'28.5rem',
  height:'32rem',
  marginTop:'0.8rem',
  [theme.breakpoints.down("sm")]: {
   width:"90%",
   height:'auto',
   objectFit:'contain'
  },
  [theme.breakpoints.down("md")]: {
    width:'18rem',
  height:'21.5rem',
  },
  [theme.breakpoints.between("md", "lg")]: {
    width:'25rem'
  }
}));

function Checklist() {
  return (
    <div>
      <Mainhead heading="Checklist for company registration in India"/>
      <ul className='list'>
        <li className='bullet'>
          <span>
      <List points="Two Directors:"/>
      </span>
      </li>
      <li className='bullet'>
        <span>
      <List points="Unique name ending with private limited for the company:"/>
      </span>
      </li>
      <li className='bullet'>
          <span>
      <List points="Minimum Capital Contribution Rs One Lakh only."/>
      </span>
      </li>
      <li className='bullet'>
      <span>
      <List points="Registered Office Rent Agreement or No Objection Certificate (NOC):"/>
      </span>
      </li>
      </ul>

      <Box sx={{textAlign:'center'}}>
      <Registrationimg component="img"
                sx={{
                  content: {
                    xs: `url(${Registration})`
                  }
                }}/>
                </Box>



    </div>
  )
}

export default Checklist;