import React from "react";
import {
  Typography,
  styled,
  Grid,
  Box,
  Container,
  Card,
  CardContent,
  useTheme,
  useMediaQuery,
} from "@mui/material";

import AccessTimeIcon from "@mui/icons-material/AccessTime";
import LockIcon from "@mui/icons-material/Lock";
import GroupsIcon from "@mui/icons-material/Groups";


import Group from "../../Assets/AboutUs/LegalFactory_About1.png";
import Man from "../../Assets/AboutUs/LegalFactory_About2.png";
// import Line from "../../Assets/AboutUs/Line 41.png";

const About = () => {
  const theme = useTheme()

  const GROUP_IMAGE = styled(Box)(({ theme }) => ({

    [theme.breakpoints.up("lg")]: {
       height:'55rem' ,
  },
    [theme.breakpoints.up("xl")]: {
       height:'57rem' ,
  },
    [theme.breakpoints.between("md", "lg")]: {
      height:'45.5rem',
  },
    [theme.breakpoints.down("md")]: {
      height:'33.5rem' ,  
  },
    [theme.breakpoints.down("sm")]: {
      height:'17.9rem' , 
  },

  }));

  const ABOUT_US_HEADING = styled(Typography)(({ theme }) => ({
    color: theme.palette.primary.main,
      fontWeight: 600,
      lineHeight: "2rem",

    [theme.breakpoints.up("lg")]: {
      fontSize: "4rem",
      marginTop: "-12rem",
 },
    [theme.breakpoints.up("xl")]: {
      fontSize: "3.5rem",
      marginTop: "-15rem",
 },
    [theme.breakpoints.between("md", "lg")]: {
      fontSize: "3.2rem",
      marginTop: "-10rem",
 },
    [theme.breakpoints.down("md")]: {
      fontSize: "3rem",
      marginTop: "-5rem",   
 },
    [theme.breakpoints.down("sm")]: {
      fontSize: "2rem",
      marginTop: "-1rem",   
 },


  }));

  const ABOUT_US_BODY = styled(Typography)(({ theme }) => ({
    marginBlock: "3rem",
    display: "flex",
    fontSize: '1.3rem',
    lineHeight: '2.2rem',
    fontWeight: 450,
    fontStyle: 'normal',
  
    [theme.breakpoints.up("xl")]: {
     lineHeight:'2rem',
     fontSize:'1.2rem', 
   
  },
   [theme.breakpoints.between("md", "lg")]: {
      fontSize: '1.32rem',
      lineHeight: '2.2rem',
     
  },
   [theme.breakpoints.down("md")]: {
      fontSize: '1.1rem',
      lineHeight: '2rem',
  },
   [theme.breakpoints.down("sm")]: {
      fontSize: '0.8rem',
      lineHeight: '1.5rem',
        
  },
    
  }));
  const Topboxhead = styled(Typography)(({ theme }) => ({
    //marginLeft:'20%',
    marginTop:'4%',
    fontSize: '1.3rem',
    lineHeight: '2.2rem',
    fontWeight: 450,
    fontStyle: 'normal',
    width:'100%',
    alignItems:'center',
    [theme.breakpoints.up("xl")]: {
     lineHeight:'2rem',
     fontSize:'1.2rem', 
   
  },
   [theme.breakpoints.between("md", "lg")]: {
      fontSize: '1.32rem',
      lineHeight: '2.2rem',
     
  },
   [theme.breakpoints.down("md")]: {
      fontSize: '1.1rem',
      lineHeight: '2rem',
  },
   [theme.breakpoints.down("sm")]: {
      fontSize: '0.8rem',
      lineHeight: '1.5rem',
        
  },
    
  }));


  const Background = styled(Box)(({ theme }) => ({
    backgroundColor: "#FAF9F6",
    width: "100%",
    height: "100%",
  }));

  const ICONS_LABEL = styled(Typography)(({ theme }) => ({
    color: theme.palette.primary.main ,
    fontWeight: "800",
    textAlign: "center",
    alignItems: "center", 
    fontSize: "1.5rem",

    [theme.breakpoints.up("lg")]: {
       fontSize:"1.4rem",
  },

    [theme.breakpoints.between("md", "lg")]: {
      fontSize: '1.2rem',
  },
    [theme.breakpoints.down("md")]: {
      fontSize: '0.8rem',
  },
   [theme.breakpoints.down("sm")]: {
      fontSize: '0.5rem',     
  },

  }));

  const Card = styled(Box)(({ theme }) => ({
    
    [theme.breakpoints.up("lg")]: {
      width: "15rem",
      height: "13rem",
      border: "0.4rem solid rgba(252, 189, 92, 0.55)",
      borderRadius: "0.7rem",
      
  },
    [theme.breakpoints.up("xl")]: {
      width: "16rem",
      height: "14rem",
      border: "0.5rem solid rgba(252, 189, 92, 0.55)",
      borderRadius: "0.7rem",
  },
   [theme.breakpoints.between("md", "lg")]: {
      width: "13rem",
      height: "11rem",
      border: "0.3rem solid rgba(252, 189, 92, 0.55)",
      borderRadius: "0.75rem",
     
  },
   [theme.breakpoints.down("md")]: {
      width: "11rem",
      height: "9rem",
      borderRadius: "0.75rem",
      border: "0.25rem solid rgba(252, 189, 92, 0.55)",
      
  },
   [theme.breakpoints.down("sm")]: {
      width: "6.8rem",
      height: "6rem",    
      borderRadius: "0.75rem",
      border: "0.2rem solid rgba(252, 189, 92, 0.55)",
  },
    
  }));

  const ICONSTYLE = styled(Box)(({ theme }) => ({
    color: theme.palette.primary.main ,
    display: "block",
    textAlign: "center",
    paddingTop: "2rem",
    
    [theme.breakpoints.up("lg")]: {
      paddingTop: "3rem",
  },
    [theme.breakpoints.down("md")]: {
      paddingTop: "1rem",
  },
  [theme.breakpoints.down("sm")]: {
      paddingTop:"0rem",
      
},

  }));

  const TIMELINE_HEADING = styled(Typography)(({ theme }) => ({
    color: theme.palette.primary.main ,
    fontWeight: 600,

  [theme.breakpoints.up("lg")]: {
    fontSize: "4rem",
    marginTop: "4rem",
},
  [theme.breakpoints.up("xl")]: {
    fontSize: "3.5rem",
    marginTop: "4.5rem",
},
  [theme.breakpoints.between("md", "lg")]: {
    fontSize: "3.2rem",
    marginTop: "3.5rem",
    marginLeft:'2.8rem',
},
  [theme.breakpoints.down("md")]: {
    fontSize: "3rem",
    marginTop: "3rem",
},
  [theme.breakpoints.down("sm")]: {
    fontSize: "2rem",
    textAlign:'center',
    marginTop: "2rem",
    
},

  }));

  const MAN_IMAGE = styled(Box)(({ theme }) => ({
     marginTop: "5rem",

  [theme.breakpoints.up("xl")]: {
       height: "24rem",
 },
  [theme.breakpoints.up("lg")]: {
     height:'28rem',
 },
   [theme.breakpoints.between("md", "lg")]: {
    height:'24rem',
    marginLeft:'2rem',
 },
   [theme.breakpoints.down("md")]: {
    height:'20rem',
 },
   [theme.breakpoints.down("sm")]: {
     display:'none',

 },
  }));

  const TOP_BOX = styled(Box)(({ theme }) => ({
    borderRadius: " 0.6rem",
    border: "0.25rem solid #F90",
    background: "#FDFDFD",
    marginBlock: "2rem",
    display:"flex",
    
    [theme.breakpoints.up("lg")]: {
      width: "26rem",
      height:'4rem',
  },
    [theme.breakpoints.up("xl")]: {
      width: "29rem",
      height: "5rem",
  },
    [theme.breakpoints.between("md", "lg")]: {
     width: "24rem",
     height:'4rem',
  },
    [theme.breakpoints.down("md")]: {
     width: "22rem",
     height:'3rem',   
  },
    [theme.breakpoints.down("sm")]: {
      width: "22rem",
      height:'3rem',
   
  },

  }));

  const RIGHT_BOX = styled(Box)(({ theme }) => ({
    borderRadius: "0.6rem",
    border: "0.25rem solid #F90",
    background: "#FDFDFD",
    marginLeft: "13rem",

    [theme.breakpoints.up("lg")]: {
      width: "16rem",
      height:'3rem',
  },
    [theme.breakpoints.up("xl")]: {
      width: "16rem",
      height: "3rem",
  },
    [theme.breakpoints.between("md", "lg")]: {
     width: "14rem",
     height:'3rem',
     
  },
    [theme.breakpoints.down("md")]: {
     width: "12rem",
     height:'3rem',
     marginLeft: "10rem",
     
     
  },
    [theme.breakpoints.down("sm")]: {
      width: "12rem",
      height:'3rem',
      marginLeft: "10rem",
  },

  }));

  const LEFT_BOX = styled(Box)(({ theme }) => ({
    width: "16rem",
    height: "3rem",
    borderRadius: "0.6rem",
    border: "0.25rem solid #F90",
    background: "#FDFDFD",
    marginBlock: "2rem",

    [theme.breakpoints.up("lg")]: {
      width: "16rem",
      height:'3rem',
  },
    [theme.breakpoints.up("xl")]: {
      width: "16rem",
      height: "3rem",
  },
    [theme.breakpoints.between("md", "lg")]: {
     width: "14rem",
     height:'3rem',
     
  },
    [theme.breakpoints.down("md")]: {
     width: "12rem",
     height:'3rem',
     
  },
    [theme.breakpoints.down("sm")]: {
      width: "12rem",
      height:'3rem',
      marginRight:"2rem",
      
  },
  }));

  const BACKGROUND_HEADING = styled(Typography)(({ theme }) => ({
    color: "#F19E1E",
    fontWeight: "800",
    lineHeight: "1.5rem",
    marginTop: "10rem",
    textAlign: "center",
    opacity: "0.2",

    [theme.breakpoints.up("lg")]: {
      fontSize: "7rem",    
  },
    [theme.breakpoints.up("xl")]: {
      fontSize: "7.5rem",
      
  },
    [theme.breakpoints.between("md", "lg")]: {
      fontSize: "6rem",
  },
    [theme.breakpoints.down("md")]: {
      fontSize: "5rem",
      
  },
    [theme.breakpoints.down("sm")]: {
      fontSize: "2.6rem",
      marginTop:"6rem",
      
  },
  }));

  const WHY_CHOOSE_LF = styled(Typography)(({ theme }) => ({
    color: "#F19E1E",
    fontWeight: 600,
    textAlign: "center",
    marginTop:"-0.5rem",

    [theme.breakpoints.up("lg")]: {
      fontSize: "3rem",    
  },
    [theme.breakpoints.up("xl")]: {
      fontSize: "3.5rem",
      
  },
    [theme.breakpoints.between("md", "lg")]: {
      fontSize: "2.8rem",
      
  },
    [theme.breakpoints.down("md")]: {
      fontSize: "2.5rem",
      
  },
    [theme.breakpoints.down("sm")]: {
      fontSize: "1.5rem",    
  },
  }));

  const OUR_APPROACH = styled(Typography)(({ theme }) => ({
    color: theme.palette.primary.main ,
    fontWeight: 600,
    marginBlock: "2rem",
    textAlign: "center",

   [theme.breakpoints.up("lg")]: {
      fontSize: '3.5rem', 
  },
   [theme.breakpoints.up("xl")]: {
      fontSize:'3.5rem',  
  },
   [theme.breakpoints.between("md", "lg")]: {
      fontSize: '3rem',
  },
   [theme.breakpoints.down("md")]: {
      fontSize: '2.5rem',
  },
   [theme.breakpoints.down("sm")]: {
      fontSize: '1.5rem',
  },
  }));

  const LARGE_CIRCLE = styled(Box)(({ theme }) => ({
    border: "0.75rem solid #F19E1E",
    background: "#FDFDFD",
    marginBlock: '-1.2rem 4rem',

   [theme.breakpoints.up("lg")]: {
    width: "12rem",
    height: "12rem",
    borderRadius: "12rem",   
  },
   [theme.breakpoints.up("xl")]: {
    width: "13rem",
    height: "13rem",
    borderRadius: "13rem",
  },
   [theme.breakpoints.between("md", "lg")]: {
    width: "10rem",
    height: "10rem",
    borderRadius: "10rem", 
    border: "0.65rem solid #F19E1E",
   
  
  },
   [theme.breakpoints.down("md")]: {
    width: "9rem",
    height: "9rem",
    borderRadius: "9rem", 
    border: "0.55rem solid #F19E1E",
   
  },
   [theme.breakpoints.down("sm")]: {
    width: "6rem",
    height: "6rem",
    borderRadius: "6rem", 
    border: "0.35rem solid #F19E1E",
   
  },
  }));

  const SMALL_CIRCLE = styled(Box)(({ theme }) => ({
    border: "0.35rem solid rgba(129, 81, 2, 0.54)",
    background: "#FDFDFD",
    marginLeft:"2rem",
   

    [theme.breakpoints.up("lg")]: {
      width: "8rem",
      height: "8rem",
      borderRadius: "8rem",  
    
     
    },
     [theme.breakpoints.up("xl")]: {
      width: "9rem",
      height: "9rem",
      borderRadius: "9rem",
     
    },
     [theme.breakpoints.between("md", "lg")]: {
      width: "7rem",
      height: "7rem",
      borderRadius: "7rem",   
    
      
    },
     [theme.breakpoints.down("md")]: {
      width: "6rem",
      height: "6rem",
      borderRadius: "4rem", 
     
      
    },
     [theme.breakpoints.down("sm")]: {
      width: "4rem",
      height: "4rem",
      borderRadius: "4rem", 

    },
  }));



  const Boxgap = styled(Box)(({ theme }) => ({
    [theme.breakpoints.down("sm")]: {
      marginBottom:'1rem',
     }
    })); 


    const LINE_IMAGE = styled(Box)(({ theme }) => ({
      marginTop: "2rem",
 
   [theme.breakpoints.up("xl")]: {
    height:'0.1rem',
    width:'4rem',
       
  },
   [theme.breakpoints.up("lg")]: {
    height:'0.1rem',
    width:'5rem',
    marginTop:'5rem',
     
  },
    [theme.breakpoints.between("md", "lg")]: {
      height:'0.1rem',
      width:'3rem',
    
  },
    [theme.breakpoints.down("md")]: {
      height:'0.1rem',
      width:'2rem',
   
  },
    [theme.breakpoints.down("sm")]: {
       height:'0.1rem',
       width:'1rem',
 
  },
   }));

   const Position = styled(Box)(({ theme }) => ({
    marginTop:'1.5rem',

 [theme.breakpoints.up("xl")]: {
  marginTop:'8.5rem'
},
 [theme.breakpoints.up("lg")]: {
  marginTop:'6rem'
   
},
  [theme.breakpoints.between("md", "lg")]: {
    marginTop:'2.5rem'
  
},
  [theme.breakpoints.down("md")]: {
    marginTop:'2rem'
 
},
  [theme.breakpoints.down("sm")]: {
    marginTop:'1.5rem'

},
 }));

 const Space = styled(Box)(({ theme }) => ({
  
  [theme.breakpoints.up("xl")]: {
marginLeft:'60%',
},
[theme.breakpoints.up("lg")]: {
  marginLeft:'30%',
 
},
[theme.breakpoints.between("md", "lg")]: {
  

},
[theme.breakpoints.down("md")]: {


},
[theme.breakpoints.down("sm")]: {


},
}));

const Dash = styled(Box)(({ theme }) => ({
  border: "0.2rem dashed #FCBD5C8C",
  width: "0rem",
  height: "68rem",
  display: "flex",
  justifyContent: "center",
  marginLeft: "12rem",
  alignItems: "center",
  marginBlock: "2rem", 
  [theme.breakpoints.up("xl")]: {
   height:'80rem'
    },
    [theme.breakpoints.down("sm")]: {
      height:'60rem'
       },
       [theme.breakpoints.down("sm")]: {
        height:'65rem'
         },
}));

const SecondDash = styled(Box)(({ theme }) => ({
  border: "0.2rem dashed #FCBD5C8C",
  width: "0rem",
  height: "43rem",
  display: "flex",
  justifyContent: "center",
  marginLeft: "12rem",
  alignItems: "center",
  marginBlock: "2rem", 
  [theme.breakpoints.up("xl")]: {
   height:'50rem'
    },
    [theme.breakpoints.down("sm")]: {
      height:'35rem'
       },
       [theme.breakpoints.down("md")]: {
        height:'40rem'
         },
}));
  return (
    <div>
      <Background>
      <div className='Container'>
        <Container>
        <Grid container>
                <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
            <GROUP_IMAGE
              component="img"
              sx={{
                content: {
                  xs: `url(${Group})`,
                },
              }}
            ></GROUP_IMAGE>
          </Grid>
       </Grid>

          <Grid container>
            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
            <ABOUT_US_HEADING>About Us</ABOUT_US_HEADING>

            <ABOUT_US_BODY>
            LEGAL FACTORY - Is  backed by a team of young and enthusiastic professionals mostly Chartered Accountant's, Company Secretaries and Advocates. and promises to be a personal CFO and TAX ADVISOR to help taxpayers and entrepreneurs start, run and grow their businesses.
            </ABOUT_US_BODY>
            
            <ABOUT_US_BODY>
            We aim to build a financial ecosystem focused on providing end-to-end support for every business and individual.  We have expertise in the financial market in the areas of Business setup, Compliance, taxation laws, Internal Audit, Anti Money Laundering intellectual property rights (IPR), trademarks, and start-up registration and fundraising.
            </ABOUT_US_BODY>
            
            <ABOUT_US_BODY>LEGAL FACTORY also aims a providing knowledgeable and updated FINANCE LITERATURE CUM ARTICLES and VIDEOS , available to them as a partner in their decision making process via ASK ME ANYTHING sessions and its daily postcards and videos on Instagram, YouTube, and other social media platforms as well as its website.
            </ABOUT_US_BODY> 


            <ABOUT_US_BODY>It is cognizant of the new process, our team is experienced like old wine and, we have helped many start-ups, traders, manufacturers, entrepreneurs, financial advisories, ad agencies, professionals register their businesses in India besides assisting individuals in their financial management and making compliances. Pan India, our services are comprehensive and reach every corner of the country.
            </ABOUT_US_BODY>
   
            <ABOUT_US_BODY>
            The company strongly believes that wrestling the knowledge of financial and investment planning is the first step towards the REVOLUTION to a Financially developed Indian economy.
            </ABOUT_US_BODY>
          
          </Grid>
        </Grid>
        {/*
          <Grid container>
                <Grid item xs={4} sm={4} md={4} lg={4} xl={4}>
                 
            <Card>
              <CardContent>
                <ICONSTYLE>
                  <AccessTimeIcon sx={{ fontSize: 60 }} />
                </ICONSTYLE>

                <ICONS_LABEL>
                  Our Commitment
                </ICONS_LABEL>
              </CardContent>
            </Card>
            
          </Grid>
          <Boxgap />
          <Grid item xs={4} sm={4} md={4} lg={4} xl={4}>
            <Card>
              <CardContent>
                  <ICONSTYLE>
                      <LockIcon sx={{ fontSize: 60 }}/>
                  </ICONSTYLE>

                  <ICONS_LABEL>
                      Our Privacy
                  </ICONS_LABEL>
              </CardContent>
            </Card>
          </Grid>
          <Boxgap />
          <Grid item xs={4} sm={4} md={4} lg={4} xl={4}>
            <Card>
              <CardContent>
                <ICONSTYLE>
                      <GroupsIcon sx={{ fontSize: 60 }} />
                </ICONSTYLE>

                <ICONS_LABEL>
                     Our Experts
                </ICONS_LABEL>
              </CardContent>
            </Card>
          </Grid>
          <Boxgap />
        </Grid>
            </Box>*/}
            <TIMELINE_HEADING> Why Opt for Our Services</TIMELINE_HEADING>
      
          <Grid container
                 justifyContent={{ xs:'Flex-strat'}}
                 alignContent={{xs:'Flex-start'}}>
            
            <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
             <SecondDash>
           
             
              <Grid>
                
                <TOP_BOX ><Topboxhead align="center" >Expertise And Experience</Topboxhead></TOP_BOX> 
                <TOP_BOX ><Topboxhead align="center">Comprehensive Services</Topboxhead></TOP_BOX> 
                <TOP_BOX ><Topboxhead align="center">Personalized Approach</Topboxhead></TOP_BOX> 
                <TOP_BOX ><Topboxhead align="center">Reliable and Timely</Topboxhead></TOP_BOX> 
                <TOP_BOX ><Topboxhead align="center">Client-Centric Philosophy</Topboxhead></TOP_BOX>
                <TOP_BOX ><Topboxhead align="center">Up-to-Date Knowledge</Topboxhead></TOP_BOX> 
                <TOP_BOX ><Topboxhead align="center">Confidentiality & Integrity</Topboxhead></TOP_BOX>

          

              </Grid>
            </SecondDash>
          </Grid>

          <Grid item xs={12} sm={4} md={4} lg={4} xl={4}>
              <MAN_IMAGE
                component="img"
                sx={{
                  content: {
                    xs: `url(${Man})`,
                  },
                }}
              ></MAN_IMAGE>
          </Grid>
        </Grid>
           
        <BACKGROUND_HEADING>Why Choose Us?</BACKGROUND_HEADING>

        <WHY_CHOOSE_LF>Why Choose Legal Factory?</WHY_CHOOSE_LF>
        
        <Grid container
              justifyContent={"center"}>
              <Grid item  xs={12} sm={12} md={12} lg={12} xl={12}>
        
            <ABOUT_US_BODY sx={{ textAlign: "center" }} display={"flex"} alignContent={"center"} >

              With the highest growth rate, our nation has become the biggest
              investment destination in the world. A huge market filled with
              opportunities and a young generation eager to make their impact on
              it have set up many businesses. These start-up's have become
              enormous growth opportunities as many of the now blooming
              businesses like Amazon, once started as small local startups by
              young individuals.

           </ABOUT_US_BODY>
        </Grid>
      </Grid>

          <OUR_APPROACH>Our Approach</OUR_APPROACH>
          <Position/>
          
          <Grid container>
            
            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
              {/*   
            <LARGE_CIRCLE >{/* write text here  </LARGE_CIRCLE></Grid> 
             
            {/* <LINE_IMAGE
                component="img"
                sx={{
                  content: {
                    xs: `url(${Line})`,
                  },
                }}
              ></LINE_IMAGE> 
                 
            <Grid item  xs={3} sm={3} md={3} lg={3} xl={3}>
            <SMALL_CIRCLE >{/* write text here  </SMALL_CIRCLE> </Grid>

            <LINE_IMAGE
                component="img"
                sx={{
                  content: {
                    xs: `url(${Line})`,
                  },
                }}
              ></LINE_IMAGE> */}
              
           
           {/* <SMALL_CIRCLE>{/* write text here  *</SMALL_CIRCLE></Grid>

           <LINE_IMAGE
                component="img"
                sx={{
                  content: {
                    xs: `url(${Line})`,
                  },
                }}
              ></LINE_IMAGE> 
                 
            <Grid item  xs={3} sm={3} md={3} lg={3} xl={3}>
            <SMALL_CIRCLE >{/* write text here  </SMALL_CIRCLE> </Grid>
                */}
               <Space>
               <Dash>
              <Grid>
                
                <TOP_BOX ><Topboxhead align="center" >Client-Centric Philosophy</Topboxhead></TOP_BOX> 
                <TOP_BOX ><Topboxhead align="center">Personalized Guidance</Topboxhead></TOP_BOX> 
                <TOP_BOX ><Topboxhead align="center">Expertise and Experience</Topboxhead></TOP_BOX> 
                <TOP_BOX ><Topboxhead align="center">Comprehensive Services</Topboxhead></TOP_BOX> 
                <TOP_BOX ><Topboxhead align="center">Timely and Reliable</Topboxhead></TOP_BOX>
                <TOP_BOX ><Topboxhead align="center">Staying Informed</Topboxhead></TOP_BOX> 
                <TOP_BOX ><Topboxhead align="center">Transparency and Integrity</Topboxhead></TOP_BOX>
                <TOP_BOX ><Topboxhead align="center">Empowering Financial Education</Topboxhead></TOP_BOX>
                <TOP_BOX ><Topboxhead align="center">Long-Term Partnership</Topboxhead></TOP_BOX>
                <TOP_BOX ><Topboxhead align="center">Responsive Support</Topboxhead></TOP_BOX>
                <TOP_BOX ><Topboxhead align="center">Confidentiality and Security</Topboxhead></TOP_BOX>
                <TOP_BOX ><Topboxhead align="center">Results-driven Approach</Topboxhead></TOP_BOX>
</Grid>
</Dash>
</Space>
                 
      
      
     
      </Grid>
      </Grid>
      <Position/>
      <Grid container>
        <Grid item xs={12} lg={12}>
          <ABOUT_US_BODY sx={{textAlign:'center'}}>At Legal Factory, our approach is focused on delivering exceptional financial services that empower our clients,streamline their financial processes, and pave the way for a secure and prosperous future. With a commitment to excellence, integrity, and personalised attention, we strive to be the preferred partner for all our client's financial needs.</ABOUT_US_BODY>
        </Grid>
      </Grid>
      
      
      
    

        </Container>
        </div>

      </Background>
    </div>
  
    
  );
};
export default About;
