import React from 'react'
import Mainhead from '../../../../Components/Services/MainContent/Mainhead';
import Content from '../../../../Components/Services/MainContent/Content'
import Step from '../../../../Components/Services/MainContent/Step';
import List from '../../../../Components/Services/MainContent/List';
function Document() {
  return (
    <div>
      <Mainhead heading="Basic Documents Required"/>
      <Step number="DOCUMENTS TO BE UPLOADED"/>
      <Content paragraph="NOTE: e-filing of application, e-payment, e-submission of document is mandatory, no hard copies required. Approved certificates are issued online"/>
      <ul className="list">
        <li className='bullet'>
          <span>
      <List points="Passport size Photograph of Employer"/>
      </span>
      </li>
      <li className='bullet'>
          <span>
      <List points="Photo of shop along with owner"/>
      </span>
      </li>
      <li className='bullet'>
          <span>
      <List points="List of Management Employees(.xls format)"/>
      </span>
      </li>
      <li className='bullet'>
          <span>
      <List points="Rates of Wages(.xls format)"/>
      </span>
      </li>
      <li className='bullet'>
          <span>
      <List points="Details of Employees working in Establishment(.xls format)"/>
      </span>
      </li>
      <li className='bullet'>
          <span>
      <List points="Employee Weekly Holidays(.xls format)"/>
      </span>
      </li>
      <li className='bullet'>
          <span>
      <List points="Address proof of Establishment [Copy of shop’s rent agreement (if on rent) or Shop’s ownership document proof (if owner of shop)]"/>
      </span>
      </li>
      <li className='bullet'>
          <span>
      <List points="Affidavit (Declaration Form)"/>
      </span>
      </li>
      <li className='bullet'>
          <span>
      <List points="Photo ID (PAN Card/Driving License/Aadhar Card/Passport)"/>
    </span>
    </li>
    </ul>
    </div>
  )
}

export default Document