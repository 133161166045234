import React from "react";
import { Box } from "@mui/material";
import Mainhead from "../../../Components/Services/MainContent/Mainhead";
import Content from "../../../Components/Services/MainContent/Content";
import Step from "../../../Components/Services/MainContent/Step";
import CustomList from "../../../Components/Income-Tax/CustomList";
import { Image } from "../../../Components/Income-Tax/ImageStyle";
import Image8 from "../../../Assets/IncomeTax/taxable2.jpg";

const Section80E = () => {
  return (
    <Box
      style={{
        display: "flex",
        flexDirection: "column",
        gap: 4,
        marginBottom: "2rem",
      }}
    >
      <Mainhead heading="Section 80E" />
      <Content paragraph="We have to invest a significant amount of savings in order to keep up with the escalating expense of higher education. The Income Tax Act of 1961 has a provision 80E that explicitly addresses educational loans, so if you plan to take out a loan to pursue higher education in India or overseas, you can deduct for it." />
      <Content paragraph="The costs are more for international university students than for Indian students studying abroad. In addition to paying the substantial tuition for the course, students are also responsible for covering the costs of housing, transportation, and required study aids like laptops and smartphones." />
      <Content paragraph="Section 80E was therefore created to offer some relief to taxpayers who are paying for a high school education and must obtain a loan in order to cover these costs." />

      <Step
        number={`
          ELIGIBILITY TO CLAIM DEDUCTION
        `}
      />
      <CustomList
        points={[
          "Only individuals can claim this tax deduction.",
          "One can also claim a deduction for education loan for his/her spouse, for his child’s, for a student for whom the individual is a legal guardian.",
          "The deduction under Sec 80E of the Income Tax Act doesn’t apply to Hindu Undivided Family (HUF) taxpayers or companies.",
          "Individuals availing education loan and paying interest thereon can claim 80E deduction if his income comes under the taxable bracket.",
          "Taxpayers can claim this deduction if a loan is taken to finance higher education from any financial institution or any approved charitable institution for the purpose.",
          "Apart from meeting the eligibility parameters, individuals need to submit certain documents to substantiate their authentication.",
        ]}
      />

      <Step
        number={`
          Duration of Deduction
        `}
      />
      <Content paragraph="The deduction is available for a maximum of eight assessment years or until the interest on the loan is fully repaid, whichever is earlier. The deduction begins from the year in which the individual starts repaying the loan." />

      <Step
        number={`
          Amount of Deduction
        `}
      />
      <Content paragraph="The entire amount of interest paid on the loan during the relevant assessment year is eligible for deduction. There is no cap on the maximum amount that can be claimed." />

      <Step
        number={`
          No Deduction for Principal Repayment
        `}
      />
      <Content paragraph="It's important to note that Section 80E provides a deduction only for the interest component of the loan repayment. The principal amount of the loan is not eligible for deduction under this section." />

      <Step
        number={`
          Loan from Specified Institutions
        `}
      />
      <Content paragraph="The loan must be taken from approved financial institutions or charitable institutions. This includes banks, financial institutions, approved charitable institutions, and approved charitable trusts." />

      <Step
        number={`
          Higher Education Definition
        `}
      />
      <Content paragraph="Higher education, for the purposes of Section 80E, includes any course of study pursued after passing the Senior Secondary Examination or its equivalent. It can be for any graduate or postgraduate course in engineering, medicine, management, or postgraduate course in applied sciences, etc." />
      <Box sx={{ textAlign: "center" }}>
        <Image
          component="img"
          sx={{
            content: {
              xs: `url(${Image8})`,
            },
          }}
        />
      </Box>
    </Box>
  );
};

export default Section80E;
