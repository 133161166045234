import * as React from 'react';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Divider } from '@mui/material';
import Content from '../../../../Components/Services/MainContent/Content';
import Mainhead from '../../../../Components/Services/MainContent/Mainhead';

export default function Faq() {
  const [expanded, setExpanded] = React.useState(false);

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  return (
    <div>
      <Mainhead heading="FAQ'S"/>
      <br/>
      <Divider sx={{border: '1px solid #FF990080'}}/>
      <Accordion expanded={expanded === 'panel1'} onChange={handleChange('panel1')}  elevation={0} sx={{ boxShadow: 'unset !important' }} disableGutters>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1bh-content"
          id="panel1bh-header"
        >
          <Typography  sx={{color:'#281900',fontWeight:450,fontSize:'20px'}}>What are the documents required for a trademark renewal application?
</Typography>
        </AccordionSummary>
        <AccordionDetails>
        <ul className='list'>
            <li className='bullet'><span><Content paragraph="Certified copy of the concerned trademark registration certificate."/></span></li>
            <li className='bullet'><span><Content paragraph="Certified copy of the form TM-A used for the trademark registration application.
"/></span></li>
            <li className='bullet'><span><Content paragraph="Proof of Identification of the applicant.
"/></span></li>
            <li className='bullet'><span><Content paragraph="Proof of Residence of the applicant.
"/></span></li>
            <li className='bullet'><span><Content paragraph="Power of Attorney if the applicant is an authorized representative of the trademark owner.
"/></span></li>
        </ul>
            
         
        </AccordionDetails>
      </Accordion>
      <Divider sx={{border: '1px solid #FF990080'}}/>
      <Accordion expanded={expanded === 'panel2'} onChange={handleChange('panel2')}
       elevation={0} sx={{ boxShadow: 'unset !important' }} disableGutters>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel2bh-content"
          id="panel2bh-header"
        >
          <Typography sx={{color:'#281900',fontWeight:450,fontSize:'20px'}}>
          What is the validity of a registered trademark?

          </Typography>
        </AccordionSummary>
        <AccordionDetails>
         <Content paragraph="The validity of a registered trademark is ten years from the date of issuance."/>
        </AccordionDetails>
      </Accordion>
      <Divider sx={{border: '1px solid #FF990080'}}/>
      <Accordion expanded={expanded === 'panel3'} onChange={handleChange('panel3')}
       elevation={0} sx={{ boxShadow: 'unset !important' }} disableGutters>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel3bh-content"
          id="panel3bh-header"
        >
          <Typography sx={{color:'#281900',fontWeight:450,fontSize:'20px'}}>
          can i renew my trademark indefinitely?
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
         <Content paragraph="Yes, there is no limit to the number of times you can renew your trademark in India. you can renew your trademark repeatedly for a period of 10 years at a time.
"/>
        </AccordionDetails>
      </Accordion>
      <Divider sx={{border: '1px solid #FF990080'}}/>
      
      
    </div>
  );
}