// faqData.js

const faqData = [
    {
        id: 'panel1',
        question: 'What is a Transfer of Property?',
        answer: 'A live individual transferring property to one or more other living people, either now or in the future, is known as a transfer of property.',
    },
    {
        id: 'panel2',
        question: 'What may be transferred?',
        answer: 'Any type of property, whether movable or immovable, may be transferred.',
    },
    {
        id: 'panel3',
        question: 'What types of transactions are exempted from the definition of “transfer” under Section 2(47)?',
        answer: 'The distribution of capital assets by a firm, the transfer of capital assets in the case of a Hindu Undivided Family (HUF), the transfer of capital assets in a tax-neutral transaction, and the transfer of capital assets according to a gift or bequest are among the exceptions.',
    },
    {
        id: 'panel4',
        question: 'What is the meaning of “transfer” under Section 2(47) of the Income Tax Act?',
        answer: 'The sale, exchange, surrender, extinction of a capital asset, the termination of any rights attached to it, or the acquisition of the asset via legal necessity are all considered forms of "transfer."',
    },
    {
        id: 'panel5',
        question: 'Can exemptions on long-term capital gains be availed multiple times?',
        answer: 'Yes, exemptions on long-term capital gains, such as under Sections 54, 54F, or 54EC, can be availed multiple times provided the conditions specified under each section are met for each investment.',
    },
    {
        id: 'panel6',
        question: 'Are gifts received considered as long-term capital assets?',
        answer: 'It depends on the nature of the asset gifted. If the gifted asset was a long-term capital asset in the hands of the donor, it will be treated as a long-term capital asset in the hands of the recipient.',
    },
    {
        id: 'panel7',
        question: 'What factors determine the holding period for long-term capital assets?',
        answer: 'The holding period for long-term assets varies based on the type of asset. It\'s essential to know the specific holding period for each asset class to classify gains or losses correctly.',
    },
    {
        id: 'panel8',
        question: 'Can short-term capital losses be set off against other income?',
        answer: 'Yes, short-term capital losses can be set off against short-term capital gains as well as long-term capital gains. Additionally, if any losses remain unadjusted, they can be carried forward for up to 8 assessment years to be set off against future capital gains.',
    },
    {
        id: 'panel9',
        question: 'What are the allowable expenses that can be deducted when calculating capital gains on the sale of a house property?',
        answer: 'Allowable expenses include the cost of acquisition, cost of improvement, transfer expenses (stamp duty, registration fees, legal charges), brokerage fees, legal expenses related to property transfer, and other incidental expenses directly related to the sale.',
    },
    {
        id: 'panel10',
        question: 'Is there any benefit available if I incur legal expenses during the sale of the property?',
        answer: 'Yes, legal expenses directly related to the property transfer can be considered as deductible expenses when calculating capital gains. These expenses can help reduce the taxable capital gains arising from the sale.',
    },
    {
        id: 'panel11',
        question: 'How do I prove and document the expenses incurred for deductions when filing my income tax return?',
        answer: 'It\'s crucial to maintain proper records, including bills, receipts, agreements, and other relevant documents, to substantiate the claimed deductions. These documents serve as evidence to support deductions claimed during tax filing.',
    },
    {
        id: 'panel12',
        question: 'What is the capital gains tax rate for listed, and unlisted bonds, and debentures in India for FY 23-24(AY24-25)?',
        answer: 'If the capital gains are short-term, the rates for both listed and unlisted bonds and debentures are as per slab rates; if the gains are long-term, the rates for both listed and unlisted bonds are 10% and 20%, respectively.',
    },
    {
        id: 'panel13',
        question: 'Is it possible to avoid capital gains taxes if you relocate to another country?',
        answer: 'No, it is unavoidable. The capital gains tax must be paid regardless of the person\'s residency status.',
    },
    {
        id: 'panel14',
        question: 'Is there an advance tax on capital gains and casual income? If so, how would the sum be determined?',
        answer: 'Capital gain and casual income should be taxed in advance. The amount is computed by adding the capital gain to the overall income and subtracting the tax.',
    },



    {
        id: 'panel15',
        question: 'If I convert my capital asset to stock in trade, will it be subject to both capital gain tax and tax under PGBP?',
        answer: 'Yes, such an asset will be subject to capital gains tax for the period of time it was held as a capital asset. And tax under PGBP will also be levied on the converted asset for the period after conversion.',
    },
    {
        id: 'panel16',
        question: 'If I convert my stock in trade to a capital asset, what will be considered as the period of holding for calculating capital gain?',
        answer: 'The period of holding will be considered from the date of conversion or treatment into capital assets.',
    },
    {
        id: 'panel17',
        question: 'If I convert my capital asset to stock in trade, which value will be considered as FVOC for calculating capital gain - the fair market value as on the date of conversion or the value of the asset as occurring in my books of accounts?',
        answer: 'The amount that is recorded in the assessee’s books of account is irrelevant, and only the fair market value of the asset on the date of conversion shall be taken as the FVOC for calculating capital gain.',
    },
    {
        id: 'panel18',
        question: 'How to calculate indexed cost of acquisition?',
        answer: 'The formula for calculating the indexed cost of acquisition is COA x CII for the year of transfer / CII for the first year or year 2001-02, whichever is lower.',
    },
    {
        id: 'panel19',
        question: 'How to calculate indexed cost of improvement?',
        answer: 'The formula for calculating the indexed cost of improvement is COA x CII for the year of transfer / CII for the year in which improvement made.',
    },
    {
        id: 'panel20',
        question: 'Is the cost of improvement before 1st April 2001 allowed?',
        answer: 'No, any cost of improvement incurred before 1st April 2001 is ignored while calculating capital gain.',
    },
    {
        id: 'panel21',
        question: 'Can we adjust ICOA and ICOI against the insurance claim received?',
        answer: 'The indexed cost of acquisition and improvement can be deducted from the insurance claim received to calculate the STCG/LTCG.',
    },
    {
        id: 'panel22',
        question: 'Will my debt mutual fund be short-term or long-term if I have it for longer than 36 months?',
        answer: 'Mutual funds for debt will be regarded as temporary as of April 1, 2023.',
    },
    {
        id: 'panel23',
        question: 'How long will debt mutual funds be referred to as short term after 36 months?',
        answer: 'All debt mutual funds, regardless of holding length, will be deemed short term as of April 1, 2023.',
    },
    {
        id: 'panel24',
        question: 'Does the holding period start from the purchase date or some other event?',
        answer: 'The holding period typically begins from the date of acquisition, but certain events like inheritance or gifting may have different rules for determining the holding period.',
    },
    {
        id: 'panel25',
        question: 'Are there specific rules or exceptions for capital gains related to primary residences?',
        answer: 'Some tax laws provide special treatment or exemptions for capital gains on the sale of primary residences if certain conditions regarding the holding period and usage are met.',
    },
    {
        id: 'panel26',
        question: 'Is a Slump Sale Beneficial for Tax Purposes?',
        answer: 'Slump sales may offer certain tax advantages, particularly in the computation of capital gains and tax treatment, compared to other types of asset sales. However, the specific benefits depend on individual circumstances and tax regulations.',
    },
    {
        id: 'panel27',
        question: 'Are There Any Exemptions Available in Slump Sales?',
        answer: 'Certain exemptions or provisions under tax laws may be available for slump sales, such as provisions for calculating capital gains or specific deductions, subject to fulfilling conditions outlined in the law.',
    },
    {
        id: 'panel28',
        question: 'What Factors Should be Considered Before Opting for a Slump Sale?',
        answer: 'Considerations such as the nature of the business, valuation of assets and liabilities, tax implications, legal documentation, and the objectives of both the seller and buyer should be carefully evaluated before opting for a slump sale.',
    },
    {
        id: 'panel29',
        question: 'What Are the Documentation Requirements for a Slump Sale?',
        answer: 'Documentation typically involves agreements between the parties, details of assets and liabilities transferred, valuation reports, and compliance with legal and regulatory requirements.',
    },
    {
        id: 'panel30',
        question: 'Is Professional Assistance Necessary for a Slump Sale?',
        answer: 'Given the complexities of tax implications, valuation, and legal aspects, seeking guidance from financial advisors, tax professionals, or legal experts is advisable when undertaking a slump sale to ensure compliance and optimal outcomes.',
    },
    {
        id: 'panel31',
        question: 'If I have capital gain losses from my business, will I be able to set off my losses even after shutting down my business?',
        answer: 'Yes, losses arising from business can be carried forward even if the business is not being carried on.',
    },
    {
        id: 'panel32',
        question: 'Do I need to file a return in case of capital losses?',
        answer: 'As per The Income Tax department, you need to mandatorily file a return of your capital losses if you want to carry forward such losses to subsequent years. This helps the assessee to keep track of their losses.',
    },
    {
        id: 'panel33',
        question: 'Do both long-term and short-term capital gains fall under Section 50C?',
        answer: 'Yes, both long-term and short-term capital gains are covered by Section 50C.',
    },
    {
        id: 'panel34',
        question: 'Is Section 50C applicable to all property types?',
        answer: 'Yes, Section 50C applies to all types of properties, including land and buildings. It covers both residential and commercial properties.',
    },
    {
        id: 'panel35',
        question: 'When does Section 43CA come into play?',
        answer: 'Section 43CA is applicable when immovable property is transferred for less than the stamp duty value and the difference between the consideration and the stamp duty value exceeds a certain threshold.',
    },
    {
        id: 'panel36',
        question: 'Can taxpayers deduct expenses incurred during the transfer of immovable property?',
        answer: 'Taxpayers can deduct expenses related to the transfer of real estate, such as brokerage fees and legal fees.',
    },
    {
        id: 'panel41',
        question: 'When can I claim Section 54 exemption?',
        answer: 'Section 54 exemption is available only when a capital gain from the transfer of a residential house property is invested in the purchase/construction of a new residential house property in India.',
    },
    {
        id: 'panel42',
        question: 'When should I buy/build the house in order to claim the exemption under Section 54F?',
        answer: 'A residential house property in India should be purchased within one year before or two years after the date of transfer (3 years from the date of transfer if construction).',
    },
    {
        id: 'panel43',
        question: 'What exactly is a capital gain account scheme?',
        answer: 'Unutilized capital gain can be claimed as an exemption by depositing it in the capital gain deposit A/c scheme with any nationalized bank before the due date for filing the income tax return.',
    },
    {
        id: 'panel44',
        question: 'Can I use Section 54G to get an exemption for a short-term capital gain?',
        answer: 'Yes, both short-term and long-term capital gains are eligible for the exemption under section 54G.',
    },
    {
        id: 'panel45',
        question: 'If I sell a residential property and the cost of new residential property purchased is less than the sale proceeds, how much exemption can I claim?',
        answer: 'The assessee can claim exemption on a proportionate basis, and the remaining amount can be invested in 54EC bonds.',
    },
    {
        id: 'panel46',
        question: 'Can I claim exemption if the property is purchased in the name of a family member?',
        answer: 'In order to claim exemption, the new property is to be purchased in the seller\'s name only.',
    },
    {
        id: 'panel47',
        question: 'How can I purchase the 54EC Bonds?',
        answer: 'These bonds are not listed on the stock exchange. They have to be purchased directly by going to the respective websites of the issuers.',
    },
    {
        id: 'panel48',
        question: 'What occurs if an NRI sells property without deducting TDS?',
        answer: 'If the buyer fails to deduct or deposit TDS within the time period specified, they will be subject to a penalty under Section 271C. In this case, the buyer or defaulter would be required to pay a penalty equal to the TDS not deducted or TDS not paid.',
    },
    {
        id: 'panel49',
        question: 'Does a buyer need to have a TAN number?',
        answer: 'No, during the property sale, buyers are not required to provide their TAN number.',
    },
    {
        id: 'panel50',
        question: 'Who is responsible for paying TDS on the sale of a property?',
        answer: 'The buyer of the property is responsible for paying TDS on the property\'s sale. The TDS is deducted from the sale proceeds when the seller is paid.',
    },
    {
        id: 'panel51',
        question: 'How can NRIs sell real estate without having to pay TDS?',
        answer: 'In the event that an NRI sells real estate in India, the buyer must deduct 20% of the sale price as long-term capital gains. Nevertheless, TDS, or short-term capital gains tax, will be subtracted at the rate of 30% if the property is sold before the two-year period has passed.',
    },
    {
        id: 'panel52',
        question: 'What is Tax on Mutual Funds?',
        answer: '"Capital gains" refers to earnings from investing in mutual funds. There is tax on these capital gains. Therefore, you should be fully aware of how your returns will be taxed before making any mutual fund investments. In some circumstances, you can also take advantage of tax deductions.',
    },
    {
        id: 'panel53',
        question: 'What is the taxation of dividends offered by mutual funds?',
        answer: 'Since dividends were previously tax-free in the hands of investors because the companies paid dividend distribution tax (DDT) prior to paying dividends, dividends offered by any mutual fund scheme are now taxed in the traditional way, meaning that investors\' taxable income is increased and they are taxed at their individual income tax slab rates. These changes were made in the Union Budget 2020.',
    },
    {
        id: 'panel54',
        question: 'How to Get Returns on Mutual Fund Investments?',
        answer: 'Capital gains and dividends are the two types of returns offered by mutual funds. If there are any company profits, they go towards paying dividends. Companies may choose to distribute any extra income to investors in the form of dividends when they have it. Dividends are paid to investors in accordance with the quantity of mutual fund units they own.',
    },
    {
        id: 'panel55',
        question: 'How to capital gain profit realized by the investor?',
        answer: 'Investors realize a capital gain when the price at which they sell the security they own exceeds the price at which they bought it. To put it plainly, capital gains are realized as a result of the mutual fund units\' price appreciation. Investors in mutual funds are subject to taxes on both capital gains and dividends.',
    },
    {
        id: 'panel56',
        question: 'Taxation in the hands of investors?',
        answer: 'For both equity and debt mutual funds, dividends are added to the investor\'s total income, and they are taxed based on their individual income tax slabs. The mutual fund company does not deduct tax at source on dividends.',
    },
    {
        id: 'panel57',
        question: 'What does a business earn from digital assets?',
        answer: 'If an individual or entity is engaged in activities related to digital assets as a business, the income generated from such activities may be treated as business income. The applicable tax rules for business income would then apply.',
    },
    {
        id: 'panel58',
        question: 'What tax is applicable to digital assets under goods and services?',
        answer: 'The supply of goods and services involving digital assets may be subject to Goods and Services Tax (GST). The specific treatment under GST may vary based on the nature of the transaction.',
    },
    {
        id: 'panel59',
        question: 'India taxes cryptocurrencies?',
        answer: 'In India, gains from cryptocurrencies are indeed taxable. The 2022 Budget made clear the government\'s official position on cryptocurrencies and other VDAs.',
    },
    {
        id: 'panel60',
        question: 'What taxes apply to cryptocurrencies in India?',
        answer: 'Cryptocurrencies are taxable in India since they are regarded as virtual digital assets.',
    },
    {
        id: 'panel61',
        question: 'As to Section 115 BBH, gains derived from trading cryptocurrencies are subject to a 30% tax rate plus a 4% cess.',
        answer: 'Starting on July 1, 2022, Section 194S would impose 1% Tax Deducted at Source (TDS) on the transfer of cryptocurrency assets if the transactions total more than ₹50,000 (or even ₹10,000 in some circumstances) within the same financial year.',
    },
    {
        id: 'panel62',
        question: 'Any investor who transfers digital assets during the year, whether private or commercial, is subject to the crypto tax.',
        answer: 'For both short-term and long-term gains, the investor\'s entire income is subject to the same tax rate. That means that profits made by buying, selling, or exchanging cryptocurrencies will be subject to a flat 30% tax (plus an additional 4%). This tax will apply regardless of whether the profits are classified as business or capital gains. In addition to this tax, selling cryptocurrency assets for more than Rs 50,000 (or Rs 10,000 in certain circumstances) would also be subject to 1% TDS.',
    },
];

export default faqData;
