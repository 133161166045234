import React from 'react';
import { Typography,styled, useTheme } from '@mui/material';


const Head = styled(Typography)(({ theme }) => ({
    color: theme.palette.primary.main,
    fontWeight: 700,
    textAlign: 'center',
    fontStyle: 'normal',
    [theme.breakpoints.up("lg")]: {
        fontSize: '3rem',
        lineHeight: '5rem',
    },
    [theme.breakpoints.between("md", "lg")]: {
        fontSize: '2.2rem',
        lineHeight: '3rem',
    },
    [theme.breakpoints.down("md")]: {
        fontSize: '2rem',
        lineHeight: '2.5rem',
    },
    [theme.breakpoints.down("sm")]: {
        fontSize: '1.8rem',
        lineHeight: '2.2rem',
    },

}));


function mainhead(props) {
    return (
      <div>
          <Head>
            {props.mainhead}
          </Head>

         
      </div>
    )
  }
  
  export default mainhead;