import React from "react";
import { Box } from "@mui/material";
import Mainhead from "../../../Components/Services/MainContent/Mainhead";
import Content from "../../../Components/Services/MainContent/Content";
import CustomList from "../../../Components/Income-Tax/CustomList";
import Step from "../../../Components/Services/MainContent/Step";
import ReusableTable from "../../../Components/Income-Tax/ReusableTable";

const PensionInIndia = () => {
  const pensionExampleData = [
    { Particulars: "Salary per day", Rs: "3,334" },
    { Particulars: "Unavailed leaves", Rs: "350 days" },
    { Particulars: "Leave Encashment", Rs: "Rs. 8,33,500" },
    { Particulars: "Amount specified by government", Rs: "Rs. 25,00,000" },
    { Particulars: "Actual encashment received", Rs: "Rs. 8,33,500" },
    { Particulars: "Average Salary (last 10 months)", Rs: "Rs. 10,00,000" },
    {
      Particulars:
        "One day’s salary * (30 * completed years of service - unutilised leaves)",
      Rs: "Rs. 8,33,500",
    },
  ];

  // Information about pension tax relief and investments
  const pensionTaxReliefData = [
    {
      Particulars: "Pension Tax Relief and Investments",
      Description:
        "After decades of hard work, retirement is the time to relax. Here are tips to save taxes on pension:",
      Points: [
        "Standard Deduction: Claim a flat deduction of Rs. 50,000 p.a. or the actual pension received, whichever is less (Section 16).",
        "Deduction under 80C: Invest in schemes like NPS, PPF, SCSS, etc., for tax exemptions with a maximum limit of Rs 1.5 lakh.",
        "Deduction under 80DDB: Senior citizens can claim up to Rs 1 lakh for specific diseases like cancer, AIDS, neurological disability, renal failure, etc.",
        "Deduction under 80D: Invest in a senior citizen health insurance policy for tax exemption up to Rs 50,000 per year on the premium paid.",
      ],
    },
    {
      Particulars: "Tax-Free Investments and Allowances in India",
      Description:
        "Explore tax-free investment options and allowances to secure your retirement:",
      Points: [
        "National Pension Scheme (NPS): A government initiative to encourage retirement savings. Invest in a market-linked pension fund.",
        "Tax-Free Bonds: Long-term bonds with low default risk, backed by government securities. Ideal for retirement planning.",
        "Senior Citizen Saving Scheme (SCSS): A government-backed 5-year investment plan with an 8.2% interest rate. Offers tax benefits under 80C.",
      ],
    },
  ];
  // Information about pension and taxation
  const pensionInfoData = [
    {
      Particulars: "Necessity of Filing ITR for Senior Citizens",
      Description:
        "If you are above 60 and receiving a pension, filing ITR is beneficial for various reasons.",
      Points: [
        "Easier VISA application process for foreign trips.",
        "AIS TIS information for summarised income details.",
        "Requirement for applying for a reverse mortgage loan.",
        "Valid proof of address and earnings for various purposes.",
      ],
    },
    {
      Particulars: "Important Updates in Pension",
      Description:
        "From 1st April 2021, senior citizens above the age of 75 are conditionally exempted from filing their ITR under section 194 P introduced in budget 2021.",
      Points: [
        "Must have pension and interest income only from a bank account where they receive the pension.",
        "Provide a declaration to the bank, which will compute total income and deduct tax.",
      ],
    },
    {
      Particulars: "Taxation Of Pension Income In India",
      Description:
        "Pension income is taxable under the Income Tax Act. Taxability depends on whether you are a government or non-government employee.",
      Points: [
        "Government Employees: Tax-free if received from a government body.",
        "Private Sector Employees: Tax-saving investments are crucial for tax planning.",
      ],
    },
    {
      Particulars: "Types of Pension",
      Description:
        "Pension can be categorized based on commuted or uncommuted and the type of income (salary or other sources).",
      Points: [
        "Commuted Pension: Tax treatment depends on gratuity receipt.",
        "Uncommuted Pension: Fully taxable for both government and non-government employees.",
      ],
    },
    {
      Particulars: "Taxability Based on Type of Income",
      Description:
        "Private sector, government, and family pension are taxed differently.",
      Points: [
        "Private Sector Pension: Taxable under 'Income from salary.'",
        "Government Pension: Taxable under 'Salary.'",
        "Family Pension: Taxable under 'Income from other sources.'",
      ],
    },
    {
      Particulars: "Taxability of Family Pension",
      Description:
        "Taxability rules for commuted and uncommuted family pension.",
      Points: [
        "Commuted Family Pension: Not taxable.",
        "Uncommuted Family Pension: Exempt up to Rs. 15,000 or 1/3rd of the uncommuted pension, whichever is less.",
      ],
    },
    {
      Particulars: "Taxability of LIC Pension",
      Description:
        "Pension received from Pension or Annuity plans of Life Insurance companies is taxable under 'Income from Other Sources.' Deduction under 80 CCC up to Rs 150,000 for the premium paid towards the pension fund.",
      Points: [
        "Lump sum pension on maturity is exempt under section 10(10A) of the Income-tax Act, 1961.",
      ],
    },
  ];
  return (
    <Box
      style={{
        display: "flex",
        flexDirection: "column",
        gap: 4,
        marginBottom: "2rem",
      }}
    >
      <Mainhead heading="Pension in India" />
      <Content paragraph="Introduction:" />
      <Content paragraph="We invest in retirement plans to maintain our lifestyle after retirement. However, the income received in the form of a pension is taxable under the Income Tax Act, impacting the anticipated amount." />
      <Content paragraph="To fulfill retirement dreams and lead a comfortable life, it's crucial to explore ways to reduce tax burdens." />
      <Content paragraph="What is a Pension in India?" />
      <Content paragraph="A pension is the regular monthly income from a saved corpus accumulated during active employment. It serves as a fixed retirement fund paid regularly during post-retirement years." />
      {/* Information about pension */}
      {pensionInfoData.map((info, index) => (
        <Box key={index}>
          <Step number={info.Particulars} />
          <Content paragraph={info.Description} />
          <CustomList points={info.Points} />
        </Box>
      ))}

      <Step
        number={`
    Example: Pension Calculation
  `}
      />
      <Content paragraph="Let's understand this with an example:" />
      <ReusableTable data={pensionExampleData} />
      <Content
        paragraph={`Thus, the amount of Rs. 8,33,500 received as leave encashment would entirely be exempt.`}
      />
      <Mainhead heading="Pension Tax Relief and Investments in India" />

      {pensionTaxReliefData.map((info, index) => (
        <Box key={index}>
          <Step number={info.Particulars} />
          <Content paragraph={info.Description} />
          <CustomList points={info.Points} />
        </Box>
      ))}
    </Box>
  );
};

export default PensionInIndia;
