import React from "react";
import { Box, Typography, styled } from "@mui/material";
import Mainhead from "../../../Components/Services/MainContent/Mainhead";
import Content from "../../../Components/Services/MainContent/Content";
import Step from "../../../Components/Services/MainContent/Step";

const ChargingSection28 = () => {
  const ServiceContent = styled(Typography)(({ theme }) => ({
    color: "#000000",
    fontSize: "1rem",
    fontWeight: 450,
    lineHeight: "1.8rem",
    marginTop: "0.5rem",

    [theme.breakpoints.down("sm")]: {
      marginLeft: "0.8rem",
      lineHeight: "1.6rem",
      fontSize: "0.85rem",
    },
    [theme.breakpoints.between("md", "lg")]: {
      fontSize: "0.75rem",
      lineHeight: "1.8rem",
    },
    [theme.breakpoints.up("xl")]: {
      fontSize: "1.1rem",
      lineHeight: "1.8rem",
    },
  }));
  return (
    <Box
      style={{
        display: "flex",
        flexDirection: "column",
        gap: 4,
        marginBottom: "2rem",
      }}
    >
      <Mainhead heading="Understanding Section 28" />
      <Step
        number={`
            Introduction to Section 28
          `}
      />
      <Content paragraph="Section 28 of the Income Tax Act of 1961 is a significant part of Indian tax law, specifically dealing with the taxation of 'Profits and gains of business & profession.' This section outlines the range of taxable income derived from a business or occupation." />
      <Step
        number={`
            Incomes Subject to Section 28
          `}
      />
      <ul className="list-inside list-disc">
        <li>
          <strong>
            <ServiceContent component="p">
              (1) Profit & Gains from any Business & Profession:
            </ServiceContent>
          </strong>
        </li>

        <li>
          <strong>
            <ServiceContent component="p">
              (2) Export Incentives:
            </ServiceContent>
          </strong>
          <ol className="list-decimal">
            <ServiceContent>
              - Profits on sale of import licenses
            </ServiceContent>
            <ServiceContent>- Cash assistance received</ServiceContent>
            <ServiceContent>- Duty drawback</ServiceContent>
          </ol>
        </li>

        <li>
          <strong>
            <ServiceContent component="p">
              (3) Any Benefit (in Cash or Kind) Arising from B&P:
            </ServiceContent>
          </strong>
          <ServiceContent component="p">
            (e.g., gift given to consignee to achieve the target sale)
          </ServiceContent>
        </li>

        <li>
          <strong>
            <ServiceContent component="p">
              (4) Any Sum Received under Keyman Insurance Policy
            </ServiceContent>
          </strong>
        </li>

        <li>
          <strong>
            <ServiceContent component="p">
              (5) Compensation/Other Payment Due or Received by:
            </ServiceContent>
          </strong>
          <ol>
            <ServiceContent>
              - Any person managing the affairs of Indian company for
              termination or modifications of terms and conditions of his
              appointment
            </ServiceContent>
            <ServiceContent>
              - Any person managing the affairs of any company in India
            </ServiceContent>
            <ServiceContent>
              - Any person holding an agency in India
            </ServiceContent>
          </ol>
        </li>

        <li>
          <strong>
            <ServiceContent component="p">
              (6) Remuneration/Interest to Partner by Firm (Taxable under B&P
              Head)
            </ServiceContent>
          </strong>
        </li>

        <li>
          <strong>
            <ServiceContent component="p">
              (7) Sum Received under Agreement for:
            </ServiceContent>
          </strong>
          <ol>
            <ServiceContent>
              - Non-competition (non-compete fees) for
            </ServiceContent>
            <ServiceContent>
              - Non-sharing any know-how, patent, copyright
            </ServiceContent>
            <ServiceContent component="p">
              Note: Provided it is not taxable under capital gain
            </ServiceContent>
          </ol>
        </li>

        <li>
          <strong>
            <ServiceContent component="p">
              (8) Any Sum Received from Transfer of Assets whose Cost has
              Allowed u/s 35 AD.
            </ServiceContent>
          </strong>
          <ServiceContent component="p">
            Explanation: Where the transaction settled without taking delivery
            of goods called speculative transaction. If speculative transactions
            are of such a nature as to constitute business, it shall be deemed
            to be distinct & separate from any other business.
          </ServiceContent>
        </li>

        <li>
          <strong>
            <ServiceContent component="p">
              (9) If Assets whose Cost has been Allowed as Deduction u/s 35 AD
              is Sold, Demolished, or Destroyed:
            </ServiceContent>
          </strong>
          <ol>
            <ServiceContent>
              - The sale price of such assets shall be taxable as profits and
              gains from business and profession.
            </ServiceContent>
            <ServiceContent>
              - The insurance compensation on the destruction of such assets
              shall be taxable as profits and gains from business and
              profession.
            </ServiceContent>
          </ol>
        </li>
      </ul>

      <Step
        number={`
            What does Business or Profession mean under Section 28?
          `}
      />
      <Content paragraph="Incomes that fall under the heading of profits or gains from a business or profession are subject to Section 28 of the Income Tax Act of 1961." />
      <Content paragraph="Typically, the terms 'business' and 'profession' are interpreted according to their typical usage in trade terminology. However, the Income Tax Act defines 'business' and 'profession,' as provided below:" />
      <Content paragraph=" - Section 2(13) defines 'business' as any trade, commerce, manufacture, or venture/concern related to these activities." />
      <Content paragraph=" - Section 2(36) defines 'profession,' and it is stated that the profession incorporates a vocation." />
      <Content paragraph="Under the tax heading 'Profits and gains of business or profession,' income from a business or profession is subject to taxation. The taxing head applies to the list of incomes covered by Section 28's provisions." />
      <Step
        number={`
            General Income and Chargeability
          `}
      />
      <Content paragraph="Income chargeable under profits and gains of business profession or income from other sources shall be computed in a method of accounting regularly followed by the assessee (either in cash or accrual basis)." />
      <Content paragraph="As per Section 145(3), if the assessing officer is not satisfied with the correctness or completeness of accounts, best judgment assessments may be made." />
      <Content paragraph="Negative income, notional profit, and anticipated profit and loss are considerations in determining taxable income." />
      <Content paragraph="There is no distinction between legal and illegal business, and income from illegal business is taxable." />
      <Content paragraph="Income of all businesses or professions of an assessee shall be merged together for taxation purposes." />
      <Content paragraph="Income is chargeable under the head of profits and gains from business and profession if carried on by the assessee during the previous year." />
      <Step
        number={`
            Income Not Taxable under Profits from Business & Profession
          `}
      />
      <Content paragraph="Rent from house property is taxable under 'income from house property' even if the assessee is engaged in the business of letting out properties on rent." />
      <Content paragraph="Dividend on shares is taxable under the head 'income from other sources,' even if it deals with shares held as a stock in trade." />
      <Content paragraph="Winnings from lotteries, races, etc., are taxable under 'income from other sources' even if derived from regular business activity." />
      <Content paragraph="Income from lottery tickets held as a stock in trade is taxable under 'income from other sources.'" />
      <Content paragraph="Expenditure allowed as a deduction includes capital and revenue expenditure, subject to certain rules and exceptions. Anticipated loss or expenditure and notional expenditure are also considered." />
    </Box>
  );
};

export default ChargingSection28;
