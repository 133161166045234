import React from "react";
import { Box } from "@mui/material";
import Mainhead from "../../../Components/Services/MainContent/Mainhead";
import Content from "../../../Components/Services/MainContent/Content";
import CustomList from "../../../Components/Income-Tax/CustomList";
import Step from "../../../Components/Services/MainContent/Step";
import ReusableTable from "../../../Components/Income-Tax/ReusableTable";

const TaxRegimeComparison = () => {
  const exemptionsData = [
    {
      Exemption: "House rent allowance",
      "OLD REGIME": "Yes",
      "NEW REGIME": "No",
    },
    {
      Exemption: "Leave travel allowance",
      "OLD REGIME": "Yes",
      "NEW REGIME": "No",
    },
    {
      Exemption: "Entertainment allowance",
      "OLD REGIME": "Yes",
      "NEW REGIME": "No",
    },
    {
      Exemption: "Voluntary retirement scheme 10(10C)",
      "OLD REGIME": "Yes",
      "NEW REGIME": "Yes",
    },
    {
      Exemption: "Gratuity 10(10)",
      "OLD REGIME": "Yes",
      "NEW REGIME": "Yes",
    },
    {
      Exemption: "Leave encashment",
      "OLD REGIME": "Yes",
      "NEW REGIME": "Yes",
    },
    {
      Exemption: "Conveyance allowance",
      "OLD REGIME": "Yes",
      "NEW REGIME": "Yes",
    },
    {
      Exemption: "Transport Allowance for a specially-abled person",
      "OLD REGIME": "Yes",
      "NEW REGIME": "Yes",
    },
    {
      Exemption: "Food allowance",
      "OLD REGIME": "Yes",
      "NEW REGIME": "No",
    },
    {
      Exemption: "Daily allowance",
      "OLD REGIME": "Yes",
      "NEW REGIME": "Yes",
    },
  ];

  // Home Loan Interest data
  const homeLoanInterestData = [
    {
      "Home Loan Interest": "Self occupied property",
      "OLD REGIME": "Yes",
      "NEW REGIME": "No",
    },
    {
      "Home Loan Interest": "Let out property",
      "OLD REGIME": "Yes",
      "NEW REGIME": "Yes",
    },
    {
      "Home Loan Interest": "Home loan principal repayment",
      "OLD REGIME": "Yes",
      "NEW REGIME": "No",
    },
  ];
  // Deductions data
  const deductionsData = [
    {
      Deduction: "Standard deduction (Rs. 50,000)",
      "OLD REGIME": "Yes",
      "NEW REGIME": "Yes",
    },
    {
      Deduction: "Deduction on family pension (Rs. 15,000)",
      "OLD REGIME": "Yes",
      "NEW REGIME": "Yes",
    },
    {
      Deduction: "80C (Lic, ppf, tuition fees, home loan repayment etc.)",
      "OLD REGIME": "Yes",
      "NEW REGIME": "No",
    },
    {
      Deduction: "80CCH (Contribution made towards Agniveer corpus fund)",
      "OLD REGIME": "Yes",
      "NEW REGIME": "Yes",
    },
    {
      Deduction: "80 D (medical insurance premium)",
      "OLD REGIME": "Yes",
      "NEW REGIME": "No",
    },
    {
      Deduction: "80 E (interest on education loan)",
      "OLD REGIME": "Yes",
      "NEW REGIME": "No",
    },
    {
      Deduction: "80 G (Donation)",
      "OLD REGIME": "Yes",
      "NEW REGIME": "No",
    },
    {
      Deduction: "80 TTA/TTB (Savings bank interest)",
      "OLD REGIME": "Yes",
      "NEW REGIME": "No",
    },
    {
      Deduction: "Other deductions under chapter VI A",
      "OLD REGIME": "Yes",
      "NEW REGIME": "No",
    },
  ];
  const termsData = [
    {
      SerialNo: 1,
      Term: "Assessee",
      Definition:
        "Refers to a person liable to pay tax as per the provisions of the Income Tax Act.",
    },
    {
      SerialNo: 2,
      Term: "Assessment Year",
      Definition:
        "The year following the financial year in which income is earned, and tax liability is calculated.",
    },
    {
      SerialNo: 3,
      Term: "Previous Year",
      Definition:
        "The financial year immediately preceding the assessment year used to calculate tax liabilities.",
    },
    {
      SerialNo: 4,
      Term: "Income",
      Definition:
        "Earnings or gains from various sources, including salary, wages, profits, capital gains, rental income, etc.",
    },
    {
      SerialNo: 5,
      Term: "Gross Total Income",
      Definition: "Total income earned before any deductions or exemptions.",
    },
    {
      SerialNo: 6,
      Term: "Total Income",
      Definition:
        "Gross total income minus permissible deductions, exemptions, and allowances.",
    },
    {
      SerialNo: 7,
      Term: "Person",
      Definition:
        "Includes individuals, Hindu Undivided Families (HUFs), companies, firms, associations, etc.",
    },
    {
      SerialNo: 8,
      Term: "Assessment",
      Definition:
        "Determining the amount of tax payable by an assessee after considering income, deductions, and exemptions.",
    },
    {
      SerialNo: 9,
      Term: "Capital Asset",
      Definition:
        "Property held by an assessee, excluding certain items held for business purposes.",
    },
    {
      SerialNo: 10,
      Term: "Capital Gain",
      Definition:
        "Represents the profit or gain arising from the transfer of a capital asset. It is calculated as the difference between the sale price of the asset and its cost of acquisition.",
    },
    {
      SerialNo: 11,
      Term: "Deduction",
      Definition:
        "Refers to the amount that can be subtracted from the gross total income to arrive at the total income, thereby reducing the taxable income. Deductions can be for various purposes like investments, expenses, donations, etc., as specified in the Income Tax Act.",
    },
    {
      SerialNo: 12,
      Term: "Resident",
      Definition:
        "A person or entity is considered a resident in a country for income tax purposes based on certain criteria such as the duration of stay, citizenship, or other substantial connections. Residents are typically taxed on their global income.",
    },
    {
      SerialNo: 13,
      Term: "Non-Resident",
      Definition:
        "A person or entity that does not meet the criteria to be considered a resident in a country for income tax purposes. Non-residents are usually taxed on income earned within the country or from certain specific sources in that country.",
    },
    {
      SerialNo: 14,
      Term: "Filing of Return",
      Definition:
        "Refers to the process of submitting an income tax return to the tax authorities, disclosing details of income earned, deductions claimed, and taxes paid by the assessee for a particular assessment year.",
    },
    {
      SerialNo: 15,
      Term: "Advance Tax",
      Definition:
        "Tax paid in instalments during the financial year itself based on estimated income, ensuring that the tax liability is distributed across the year rather than paying it all at once at the end.",
    },
    {
      SerialNo: 16,
      Term: "Assessment Officer",
      Definition:
        "An officer designated by the tax department to carry out assessments, scrutinise tax returns, and determine the tax liability of the assessee.",
    },
    {
      SerialNo: 17,
      Term: "Exemption",
      Definition:
        "An amount or income that is not included in the calculation of taxable income, thus reducing the tax liability.",
    },
    {
      SerialNo: 18,
      Term: "Taxable Income",
      Definition:
        "The income on which tax is calculated after applying deductions and exemptions.",
    },
    {
      SerialNo: 19,
      Term: "Assessment Order",
      Definition:
        "A formal document issued by the tax authorities specifying the amount of tax payable by the assessee after the assessment is completed.",
    },
    {
      SerialNo: 20,
      Term: "Tax Credit",
      Definition:
        "A reduction in the actual tax liability of an individual or entity. It is usually offered for taxes already paid or for specific expenditures.",
    },
    {
      SerialNo: 21,
      Term: "Tax Deducted at Source (TDS)",
      Definition:
        "Refers to the system where tax is deducted by the payer at the time of making payments, such as salaries, interest, rent, etc., and deposited with the government on behalf of the payee.",
    },
    {
      SerialNo: 22,
      Term: "Fiscal Year",
      Definition:
        "The period used for calculating annual financial statements and tax returns. It may or may not coincide with the calendar year.",
    },
    {
      SerialNo: 23,
      Term: "Advance Ruling",
      Definition:
        "A legal opinion or decision sought by an assessee from the tax authorities regarding the tax implications of a transaction or income.",
    },
    {
      SerialNo: 24,
      Term: "Presumptive Taxation",
      Definition:
        "A method of calculating tax liability based on certain presumptions and not the actual income earned.",
    },
    {
      SerialNo: 25,
      Term: "Audit",
      Definition:
        "An examination of financial records and statements to ensure compliance with tax laws and reporting requirements.",
    },
    {
      SerialNo: 26,
      Term: "Allowance",
      Definition:
        "An amount deducted from the total income to arrive at the taxable income. It includes various allowances such as house rent allowance (HRA), travel allowance, etc.",
    },
    {
      SerialNo: 27,
      Term: "Perquisite",
      Definition:
        "Any additional benefit or advantage received by an employee, apart from salary, which is subject to taxation.",
    },
    {
      SerialNo: 28,
      Term: "Surcharge",
      Definition:
        "An additional charge or tax imposed on the income tax payable by high-income individuals or entities.",
    },
    {
      SerialNo: 29,
      Term: "Penalty",
      Definition:
        "A financial punishment imposed by tax authorities for non-compliance, late filing, or under-reporting of income.",
    },
    {
      SerialNo: 30,
      Term: "Appeal",
      Definition:
        "A formal request made by an assessee to a higher authority to review a decision made by the assessing officer.",
    },
    {
      SerialNo: 31,
      Term: "Assessment Year",
      Definition:
        "The year in which the income earned during the previous year is assessed for tax purposes.",
    },
    {
      SerialNo: 32,
      Term: "Annual Information Return (AIR)",
      Definition:
        "A statement containing specific financial transactions that are reported to the tax department by banks, financial institutions, etc.",
    },
    {
      SerialNo: 33,
      Term: "Depreciation",
      Definition:
        "A reduction in the value of assets over time, used to calculate expenses for tax purposes.",
    },
    {
      SerialNo: 34,
      Term: "Clubbing of Income",
      Definition:
        "The inclusion of certain incomes, usually of family members or related entities, in the taxable income of the assessee to prevent tax avoidance.",
    },
    {
      SerialNo: 35,
      Term: "Tax Evasion",
      Definition:
        "An illegal act of deliberately underreporting income or inflating deductions to avoid paying taxes.",
    },
    {
      SerialNo: 36,
      Term: "Withholding Tax",
      Definition:
        "Tax deducted by the payer and remitted directly to the government on behalf of the payee.",
    },
    {
      SerialNo: 37,
      Term: "Double Taxation",
      Definition:
        "Occurs when the same income is taxed in more than one jurisdiction.",
    },
    {
      SerialNo: 38,
      Term: "Securities Transaction Tax (STT)",
      Definition:
        "A tax levied on the purchase and sale of securities such as stocks, derivatives, etc.",
    },
    {
      SerialNo: 39,
      Term: "Gift Tax",
      Definition:
        "A tax imposed on the transfer of assets or money by one person to another without consideration.",
    },
    {
      SerialNo: 40,
      Term: "Tax Haven",
      Definition:
        "A jurisdiction with favourable tax laws where individuals or entities can minimise their tax liability legally.",
    },
    {
      SerialNo: 41,
      Term: "Assessment Year",
      Definition:
        "The period during which the taxpayer files their income tax return for the previous year and the tax is assessed.",
    },
    {
      SerialNo: 42,
      Term: "Agricultural Income",
      Definition:
        "Income earned from agricultural activities, which may be exempt from income tax in certain jurisdictions.",
    },
    {
      SerialNo: 43,
      Term: "Tax Residency Certificate (TRC)",
      Definition:
        "A certificate issued by the government certifying the taxpayer's residency status for the purpose of availing benefits under Double Taxation Avoidance Agreements (DTAA).",
    },
    {
      SerialNo: 44,
      Term: "Charitable Trust",
      Definition:
        "An organisation established for charitable purposes, eligible for tax benefits on donations received.",
    },
    {
      SerialNo: 45,
      Term: "Self-Assessment Tax",
      Definition:
        "Tax paid by the assessee voluntarily to cover the tax liability after adjusting TDS and advance tax.",
    },
    {
      SerialNo: 46,
      Term: "Tax Tribunal",
      Definition:
        "An independent judicial body that hears appeals against orders passed by tax authorities.",
    },
  ];

  return (
    <Box
      style={{
        display: "flex",
        flexDirection: "column",
        gap: 4,
        marginBottom: "2rem",
      }}
    >
      <Mainhead heading="NEW TAX REGIME V/S OLD TAX REGIME IN INDIA" />
      <Step
        number={`
            Introduction to New Tax Regime
          `}
      />
      <Content paragraph="With the Budget 2023, the government has introduced the new tax regime with revised slab rates. Although this new regime has been declared as the ‘default’ regime, the assessee still has the option to opt for the old tax regime. Apart from the change in tax slabs, under the new regime, you will also not be able to claim certain deductions and exemptions." />
      <Step
        number={`
            Major Differences Between the Regimes
          `}
      />
      <Content paragraph="Due to this, there is a lot of confusion regarding which regime to opt for. To make the correct decision in this regard, you should first understand what the major differences between the two are and how they will impact your income. For example, if you are a salaried employee, you will need to judge which regime will help you to get maximum after-tax income." />
      <Step
        number={`
            Tax Slabs Under New Regime (After Budget 2023)
          `}
      />
      <ReusableTable
        data={[
          { SLABS: "Rs. 0 - 3 Lakhs", "TAX RATE": "NIL" },
          { SLABS: "Rs. 3 - 6 Lakhs", "TAX RATE": "5%" },
          { SLABS: "Rs. 6 - 9 Lakhs", "TAX RATE": "10%" },
          { SLABS: "Rs. 9 - 12 Lakhs", "TAX RATE": "15%" },
          { SLABS: "Rs. 12 - 15 Lakhs", "TAX RATE": "20%" },
          { SLABS: "Rs. 15 Lakhs and Above", "TAX RATE": "30%" },
        ]}
      />
      <Step
        number={`
            Tax Slabs Under Old Regime (Before Budget 2023)
          `}
      />
      <Content paragraph="⦁ NEW RATES" />
      <ReusableTable
        data={[
          { SLABS: "Rs. 0 - 2.5 Lakhs", "TAX RATE": "NIL" },
          { SLABS: "Rs. 2.5 - 5 Lakhs", "TAX RATE": "5%" },
          { SLABS: "Rs. 5 - 7.5 Lakhs", "TAX RATE": "10%" },
          { SLABS: "Rs. 7.5 - 10 Lakhs", "TAX RATE": "15%" },
          { SLABS: "Rs. 10 - 12.5 Lakhs", "TAX RATE": "20%" },
          { SLABS: "Rs. 12.5 - 15 Lakhs", "TAX RATE": "25%" },
          { SLABS: "Above 15 Lakhs", "TAX RATE": "30%" },
        ]}
      />
      <Content paragraph="⦁ OLD RATES IN INDIA" />
      <ReusableTable
        data={[
          { SLABS: "Rs. 0 - 2.5 Lakhs", "TAX RATE": "NIL" },
          { SLABS: "Rs. 2.5 - 5 Lakhs", "TAX RATE": "5%" },
          { SLABS: "Rs. 5 - 10 Lakhs", "TAX RATE": "20%" },
          { SLABS: "Above 10 Lakhs", "TAX RATE": "30%" },
        ]}
      />
      <Step
        number={`
            Which Regime is Better for Salaried Individuals in India?
          `}
      />
      <Content paragraph="The tax on your salary will be impacted by what regime you opt for. The following points should be considered while making a decision regarding the same." />
      <CustomList
        points={[
          "BASIC EXEMPTION LIMIT IN INDIA - The basic exemption limit under the new regime has been increased from Rs. 250,000 to Rs. 300,000.",
          "REBATE IN INDIA - Rebate given under section 87A has been increased from Rs.500,000 to Rs. 700,000 under the new regime. This means that the assessee will get a benefit of up to Rs. 25,000 if his income is below Rs. 700,000.",
          "LEAVE ENCASHMENT IN INDIA - The leave encashment exemption given to non-government salaried employees has been increased from Rs. 300,000 to Rs. 2,500,000.",
          "SURCHARGE IN INDIA - The surcharge rate on annual income of more than Rs. 5 crores has been reduced from 37% to 25%.",
        ]}
      />
      <Step
        number={`
            Deductions in India
          `}
      />
      <ReusableTable data={deductionsData} />
      <Step
        number={`
            Exemptions in India
          `}
      />
      <ReusableTable data={exemptionsData} />
      <Step
        number={`
            Home Loan Interest U/Sec 24b
          `}
      />
      <ReusableTable data={homeLoanInterestData} />
      <Step
        number={`
            Form 16 in India
          `}
      />
      <Content paragraph="If you opt for a new tax regime, then your employer will not provide you with the breakup of deductions and exemptions that you are eligible for. These will have to be manually calculated by you, and the records for the same should also be duly maintained in case the department calls for them." />
      <Step
        number={`
            Investment Goals in India
          `}
      />
      <Content paragraph="Your investment structure should also be considered while choosing which regime to opt for. If you have invested in, or intend to invest in, retirement funds or other tax-saving instruments, then you should opt for the old regime as you can claim the relevant deductions." />
      <Mainhead heading="SOME IMPORTANT TERMS USED IN SALARY IN INDIA" />
      <ReusableTable data={termsData} />
      <Mainhead heading="Laws and Provisions to Prevent Tax Evasion in India" />
      <CustomList
        points={[
          "Income Tax Act, 1961: This comprehensive legislation governs the assessment, collection, and administration of income tax in India. It contains provisions to prevent tax evasion and imposes penalties for non-compliance.",
          "PAN (Permanent Account Number): Every taxpayer in India is required to have a PAN, a unique identification number issued by the Income Tax Department. PAN helps track financial transactions and prevents tax evasion.",
          "Tax Deducted at Source (TDS): Under TDS provisions, certain individuals or entities are required to deduct tax at the source before making specific payments. This ensures tax collection at the time of income generation.",
          "Penalties and Prosecution: The Income Tax Act specifies penalties and prosecution provisions for tax evasion, including fines, interest on tax dues, and imprisonment in severe cases of evasion.",
          "Benami Transactions (Prohibition) Act: The Act prohibits benami transactions, where property is held by one person but paid for or held for the benefit of another. It aims to curb black money and tax evasion through benami properties.",
          "Income Disclosure Schemes: The government has introduced various income disclosure schemes to encourage voluntary disclosure of undisclosed income/assets and pay taxes, often with reduced penalties.",
          "Tax Avoidance vs. Tax Evasion: While tax avoidance (using legal means to minimise tax liabilities) is permissible, tax evasion (illegal actions to evade taxes) is punishable by law.",
          "Tax Investigation and Enforcement: The Income Tax Department conducts investigations, surveys, and raids to uncover instances of tax evasion and undisclosed income/assets.",
        ]}
      />
      <Mainhead heading="Sections Specifying Tax Assessments And Penalties In India" />
      <Step
        number={`
            Section 140:
          `}
      />
      <Content paragraph="Section 140 of the Income Tax Act deals with the provision for filing a belated or revised income tax return. It allows taxpayers to file their return of income after the due date, providing them with an opportunity to rectify omissions or inaccuracies in the original return." />
      <Step
        number={`
            Section 271:
          `}
      />
      <Content paragraph="Section 271 pertains to penalties for various defaults and non-compliance under the Income Tax Act. It specifies penalties for cases such as concealing income, furnishing inaccurate particulars of income, failure to maintain books of accounts, and non-compliance with notices issued by tax authorities." />
      <Step
        number={`
            Section 245:
          `}
      />
      <Content paragraph="Section 245 involves provisions related to the adjustment of tax refunds against outstanding tax dues. It allows the Income Tax Department to adjust any refund due to an assessee against any outstanding tax demand." />

      <Step
        number={`
            Section 234A - Penalty for Delay in Filing Income Tax Return:
          `}
      />
      <Content paragraph="Section 234A deals with the penalty imposed for the delay in filing income tax returns by the due date specified under the Income Tax Act." />
      <Content paragraph="Meaning: The due date for filing income tax returns for individuals is usually July 31st of the assessment year." />
      <Content paragraph="Penalty: If a taxpayer fails to file their income tax return by the due date, they may be liable to pay a penalty of 1% interest per month or part of the month on the amount of tax due. The penalty is calculated from the day immediately following the due date until the actual date of filing the return." />

      <Step
        number={`
            Section 234B - Penalty for Default in Payment of Advance Tax:
          `}
      />
      <Content paragraph="Section 234B applies to taxpayers who are liable to pay advance tax but fail to pay it or pay an amount less than 90% of the assessed tax before the end of the financial year." />
      <Content paragraph="Meaning: A penalty is imposed if the taxpayer fails to pay the stipulated amount of advance tax. The penalty is levied at the rate of 1% simple interest on the amount of the shortfall in payment of advance tax." />

      <Step
        number={`
            Section 234C - Interest for Default in Paying Advance Tax:
          `}
      />
      <Content paragraph="Section 234C deals with the interest payable by taxpayers for not paying advance tax in instalments as specified under the Income Tax Act." />
      <Content paragraph="Meaning: It involves interest charges for non-payment or underpayment of advance tax in instalments. If the taxpayer fails to pay the instalment(s) of advance tax on time, they may be liable to pay interest as per the provisions of this section." />

      <Step
        number={`
            Section 234F - Late Fee for Delay in Filing Income Tax Return:
          `}
      />
      <Content paragraph="Section 234F was introduced to impose a late fee for the delayed filing of income tax returns by individual taxpayers." />
      <Content paragraph="Meaning: Under Section 234F, a late fee is applicable if an individual files their income tax return after the due date but before December 31st of the assessment year. The late fee can range from Rs. 1,000 to Rs. 10,000, depending on the timing of filing." />

      <Step
        number={`
            Section 139:
          `}
      />
      <Step
        number={`
            Section 139(1): Mandatory and Voluntary Returns
          `}
      />
      <Content paragraph="Section 139(1) of the Income Tax Act mandates the filing of an income tax return by every individual, Hindu Undivided Family (HUF), company, firm, association of persons (AOP), body of individuals (BOI), artificial juridical person, and any other entity whose total income exceeds the basic exemption limit prescribed for the relevant assessment year." />
      <Content paragraph="This section applies to taxpayers whose total income, before claiming deductions under various sections of the Income Tax Act, surpasses the threshold exempt from tax liability. However, certain categories of individuals or entities might be required to file tax returns, even if their income falls below the taxable threshold, under specific conditions outlined within the Act." />

      <Step
        number={`
            Section 139(5): Revise Income Tax Return
          `}
      />
      <Content paragraph="Section 139(5) allows for the filing of revised returns or belated returns. It provides taxpayers with the opportunity to rectify any mistakes, errors, or omissions in the original return submitted." />
      <Content paragraph="Additionally, this section permits the filing of a belated return after the expiry of the original due date specified under Section 139(1). However, this option comes with certain limitations and consequences. Taxpayers filing belated returns might be liable to pay interest on any tax liability and could face penalties as per the provisions of the Income Tax Act." />

      <Step
        number={`
            Section 139(9): Defective return Notice
          `}
      />
      <Content paragraph="Section 139(9) applies to individuals or entities required to furnish an audit report under any provision of the Income Tax Act, excluding those covered under section 44AB, which deals with tax audit requirements." />
      <Content paragraph="If a person falls under the purview of any other section mandating an audit report, they are obligated to file their income tax return for the relevant assessment year within the prescribed due date, irrespective of whether their total income exceeds the taxable limit." />
      <Content paragraph="This section ensures compliance by individuals or entities subjected to specific types of audits other than tax audit and necessitates them to fulfill their obligation to file income tax returns within the specified timelines." />

      <Step
        number={`
            Section 139(10)
          `}
      />
      <Content paragraph="Section 139(10) applies to individuals or entities required to undergo a tax audit under other sections of the Income Tax Act. For instance, businesses whose turnover surpasses the threshold specified in the Act, certain professionals, or any other persons as specified by the provisions, are required to get their accounts audited." />
      <Content paragraph="Taxpayers falling under this section need to undergo a tax audit and subsequently file their income tax returns accordingly. This provision ensures that entities meeting specific criteria undergo necessary scrutiny through a tax audit and fulfill their obligations to file accurate income tax returns as per the requirements of the Act." />

      <Step
        number={`
      Section 271A:
    `}
      />
      <Content paragraph="Section 271A deals with penalties for failure to maintain books of accounts as required under the Income Tax Act." />

      <Step
        number={`
      SECTION 143 AND 148
    `}
      />
      <Step
        number={`
      Section 143(1):
    `}
      />
      <Content paragraph="Purpose: Section 143(1) deals with the preliminary processing of income tax returns filed by taxpayers." />
      <Content paragraph="Process:" />
      <CustomList
        points={[
          "When an individual or entity files their income tax return, the Income Tax Department processes it through an automated system. This preliminary processing involves matching the details provided in the return with the information available to the department.",
          "The system checks for arithmetic errors, calculation mistakes, consistency in information, and any discrepancies between the return filed and the information available with the department (like Form 26AS, TDS/TCS returns, etc.).",
          "If everything appears to be in order and no discrepancies or underreporting of income is found, an intimation under Section 143(1) is sent to the taxpayer. This intimation serves as an acknowledgment of the return filed and its acceptance by the department.",
        ]}
      />
      <Content paragraph="Action Required: If a taxpayer receives an intimation under Section 143(1), and everything is in order as per their understanding, no action is required from their end. However, if there are discrepancies or errors in the intimation, they may need to respond or rectify the issues." />

      <Step
        number={`
      Section 143(3):
    `}
      />
      <Content paragraph="Purpose: Section 143(3) pertains to scrutiny assessments and involves a more detailed examination of the taxpayer's return." />
      <Content paragraph="Process:" />
      <CustomList
        points={[
          "If the assessing officer, after processing the return under Section 143(1), finds discrepancies, underreporting of income, or doubts about the accuracy of the information provided in the return, they can initiate a scrutiny assessment.",
          "A notice is sent to the taxpayer, informing them about the initiation of a detailed scrutiny of their return. The taxpayer may be required to provide additional information, explanations, or documents to support the claims made in the return.",
          "The taxpayer will have an opportunity to present their case and provide clarifications during the assessment proceedings.",
        ]}
      />
      <Content paragraph="Action Required: If a taxpayer receives a notice under Section 143(3), it's essential to respond within the stipulated time frame, provide the necessary information or documents, and cooperate with the assessing officer during the scrutiny assessment." />

      <Step
        number={`
      Section 148:
    `}
      />
      <Content paragraph="Purpose: Section 148 empowers the Income Tax Department to reopen or reassess a case if income has escaped assessment." />
      <Content paragraph="Process:" />
      <CustomList
        points={[
          "If the Income Tax Department has reasons to believe that certain income chargeable to tax has escaped assessment in the original assessment proceedings, they can issue a notice to the taxpayer.",
          "This notice is served within a specified time limit after obtaining necessary sanctions/approvals. It allows the department to re-assess or re-evaluate the income that has not been assessed previously.",
        ]}
      />
      <Content paragraph="Action Required: If a taxpayer receives a notice under Section 148, they should carefully review the notice, understand the reasons cited for reopening the case, and respond within the prescribed time frame. Consulting with a tax professional or legal expert is advisable to handle the re-assessment proceedings properly." />

      <Step
        number={`
      SECTION 131 & 132
    `}
      />
      <Step
        number={`
      Section 131:
    `}
      />
      <Content paragraph="Purpose: Section 131 of the Income Tax Act grants the Income Tax authorities the power to conduct inquiries during proceedings. It empowers the authorities to carry out investigations for the purpose of enforcing the Income Tax Act." />
      <Step
        number={`
  Key Points:
    `}
      />
      <CustomList
        points={[
          "Section 131 provides the Tax Officer with the authority to conduct inquiries and summon witnesses, examine them under oath, compel the production of books of accounts, documents, and other evidence.",
          "The Tax Officer, in the exercise of their jurisdiction, has the same powers as are vested in a civil court under the Code of Civil Procedure when it comes to enforcing attendance, compelling the production of documents, etc.",
          "This section allows the Tax Officer to make inquiries deemed necessary to fulfill the purposes of the Income Tax Act.",
        ]}
      />
      <Step
        number={`
Actions:`}
      />
      <CustomList
        points={[
          "Tax authorities may summon individuals or entities, including witnesses and assessees, to provide information or produce documents relevant to tax inquiries.",
          "Failure to comply with summons or provide necessary information without reasonable cause may lead to penalties or legal consequences as per the Income Tax Act.",
        ]}
      />
      <Step
        number={`
      Section 132:
    `}
      />
      <Content paragraph="Purpose: Section 132 deals with the search and seizure powers of income tax authorities. It provides provisions for search and seizure operations related to suspected cases of tax evasion or undisclosed income." />
      <CustomList
        points={[
          "Section 132 empowers income tax authorities to conduct searches and seizures in cases where they have reason to believe that a person is in possession of undisclosed income or assets.",
          "Searches and seizures can be conducted at a place of business, residence, or any other premises where the authorities believe that documents, money, assets, or valuable items relevant to tax evasion might be found.",
          "During the search, the authorities can seize and take possession of documents, books of accounts, assets, money, jewellery, or other valuables that are suspected to be undisclosed or related to tax evasion.",
        ]}
      />
      <CustomList
        points={[
          "The search and seizure operations under Section 132 are conducted following specific procedures and with due authorization from higher authorities.",
          "Seized assets/documents are examined, and if they are found to be in violation of tax laws, further actions such as assessments, penalties, or prosecutions may follow.",
        ]}
      />
    </Box>
  );
};

export default TaxRegimeComparison;
