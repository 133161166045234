import React from "react";
import { Box } from "@mui/material";
import Mainhead from "../../../Components/Services/MainContent/Mainhead";
import Content from "../../../Components/Services/MainContent/Content";
import CustomList from "../../../Components/Income-Tax/CustomList";
import Step from "../../../Components/Services/MainContent/Step";

const BusinessAndProfessionTax = () => {
  return (
    <Box
      style={{
        display: "flex",
        flexDirection: "column",
        gap: 4,
        marginBottom: "2rem",
      }}
    >
      <Mainhead heading="What is Business & Profession?" />
      <Content paragraph="In the Income Tax Act 1961, 'Business' and 'Profession' are two terms used to describe different economic activities that individuals or entities engage in to earn income." />

      <Step
        number={`
            What do you mean by Business?
          `}
      />
      <Content paragraph="A business involves regular and continuous activities carried out with the intention of earning a profit. It typically includes commercial, industrial, or trade-related activities where goods or services are produced, bought, or sold." />

      <CustomList
        points={[
          "Example of Business: Let's consider an example of a small bakery owned by Mr. and Mrs. Patel. They bake and sell cakes, pastries, and bread to customers.",
          "Their bakery operations involve regular and continuous activities aimed at generating income.",
        ]}
      />

      <Step
        number={`
            What do you mean by Profession?
          `}
      />
      <Content paragraph="A profession refers to activities based on specialized knowledge, skills, or expertise. These activities are generally carried out by individuals who are qualified and trained in a particular field." />

      <CustomList
        points={[
          "Example of Profession: Dr. Kapoor is a qualified medical doctor. He runs his own clinic where he diagnoses illnesses, prescribes medications, and provides medical advice to patients.",
          "His income is derived from providing medical services based on his specialized knowledge and expertise in the field of medicine.",
        ]}
      />

      <Step
        number={`
            Are Profession and Business the same?
          `}
      />
      <Content paragraph="In summary, a business involves commercial or trade-related activities aimed at making a profit, while a profession typically involves specialized services based on professional expertise. Both activities generate income, but they differ in terms of the nature of work, scope, and intention." />

      <Step
        number={`
            Are presumptive taxation schemes available for both businesses and professions?
          `}
      />
      <Content paragraph="Yes, presumptive taxation schemes are available for both businesses and professions under specific sections of the Income Tax Act. These schemes provide an option for calculating income based on presumptive profits, simplifying the tax calculation process." />

      <Mainhead heading="Expenses Allowable under the head Business and Profession" />
      <Content paragraph="Allowable expenses under the head of 'Business and Profession' in the Income Tax Act encompass the various costs that businesses incur in their regular operations." />

      <Content paragraph="These expenses are deductible from the gross receipts or turnover to determine the net taxable income. They include a wide range of expenditures directly related to conducting business activities." />

      <Content paragraph="Business operating expenses form a significant part of these deductions, covering essential costs such as rent or lease payments for business premises, employee wages and benefits, utility bills like electricity and internet services, expenses for purchasing raw materials or inventory, and costs associated with maintaining and repairing business equipment or machinery." />

      <Content paragraph="Additionally, advertising and marketing expenses aimed at promoting products or services, along with travel and transportation expenses linked to business activities, are also considered allowable deductions." />

      <Content paragraph="Interest payments on loans or borrowings directly tied to business purposes are eligible for deduction, along with depreciation—an accounting measure that factors in the reduction in the value of tangible assets used in the business, accounting for wear and tear or obsolescence over their useful life." />

      <Content paragraph="Professional fees and consultancy charges paid for services related to the business or profession can be claimed as deductible expenses. Costs incurred for repairs and maintenance of business assets or properties, as well as insurance premiums covering business-related risks, are also among the expenses allowable under this category." />

      <Mainhead heading="What do you mean by Section 36?" />
      <Content paragraph="Section 36 of the Income Tax Act allows businesses to claim deductions for various expenses incurred during their operations. These deductions include interest on loans, bad debts, rent, repairs, insurance, depreciation, scientific research, agricultural development, and family planning expenses for employees." />

      <CustomList
        points={[
          "Interest on Borrowed Capital (Section 36(1)(iii)): If a business takes a loan for business purposes and pays interest on that loan, the interest amount can be subtracted from the business's income to lower the taxable amount.",
          "Bad Debts (Section 36(1)(vii)): When a business cannot collect money it lent to others because the debt has become uncollectible (for reasons such as insolvency or inability to pay), the business can claim this amount as a loss to reduce its taxable income.",
          "Rent, Rates, Taxes, and Repairs (Section 36(1)(i)): This deduction covers several essential business expenses:",
          "  Rent: The money a business pays for using a workspace or property for its operations.",
          "  Rates: Refers to municipal or local taxes applicable to the business premises.",
          "  Taxes: Other taxes related to the business's property or operations.",
          "  Repairs: Costs incurred in maintaining or fixing assets used for business purposes, such as repairing machinery or premises.",
          "Insurance Premiums (Section 36(1)(ib)): This deduction specifically focuses on the money spent on insuring the assets of the business against risks like fire, theft, or other hazards.",
          "Depreciation (Section 36(1)(ii)): Businesses can claim a deduction for the reduction in value or wear and tear of tangible assets used for business purposes over their useful life. This typically includes machinery, equipment, vehicles, etc.",
          "Expenditure on Scientific Research (Section 36(1)(iia)): This deduction covers expenses related to research and development activities that contribute to the business's growth and innovation. It includes costs associated with conducting experiments, studies, or technological advancements relevant to the business.",
          "Capital Expenditure on Agriculture (Section 36(1)(iii)): This deduction allows businesses involved in agricultural development to claim expenses incurred for research and scientific work aimed at improving agricultural practices, enhancing crop yield, or agricultural development.",
          "Family Planning Expenditure (Section 36(1)(ix)): Businesses can deduct the expenses they incur on providing family planning facilities or services to their employees.",
        ]}
      />

      <Mainhead heading="What do you mean by Section 37?" />
      <Content paragraph="This is the general section of deduction which aims to include all those expenses not covered specifically under other sections but are incurred exclusively for the purpose of business or profession." />

      <Content paragraph="However, a few conditions need to be satisfied:" />
      <CustomList
        points={[
          "The expenditure should be incurred by 31st March of the financial year for which return is to be filed.",
          "The expenditure incurred is wholly and exclusively for business/profession of the assessee.",
        ]}
      />

      <Content paragraph="It is to be noted that expenditure as mentioned above incurred for any purpose which is an offense or is prohibited under the law shall NOT be deemed to be for business/profession." />

      <Content paragraph="Also, expenditure on Corporate Social Responsibility (CSR) under the Companies Act, 2013, also shall NOT be deemed to be for business/profession." />
    </Box>
  );
};

export default BusinessAndProfessionTax;
