const faqData = [
    {
        id: 'panel1',
        question: 'What is salary income?',
        answer: `The term 'salary' is defined in Section 17 of the Income-tax Act. However, without delving into the technical definition, salary is generally defined as anything received by an employee from an employer in cash, kind, or as a facility [perquisite].`,
    },
    {
        id: 'panel2',
        question: 'Are salary arrears subject to taxes?',
        answer: 'Salary arrears are taxable, yes. On the other hand, section 89 allows for relief claims.',
    },
    {
        id: 'panel3',
        question: 'What is the formula to calculate Monthly Salary?',
        answer: 'The monthly salary is calculated by dividing the annual income by the total number of months.',
    },
    {
        id: 'panel4',
        question: 'Where can I find the details of my Gross Salary?',
        answer: 'Your employment contract or salary slip usually provides a breakdown of your gross salary, detailing various components and their respective amounts.',
    },
    {
        id: 'panel5',
        question: 'How does Gross Salary affect taxes?',
        answer: 'Gross salary impacts the taxable income of an individual. Higher gross salary may result in a higher tax liability unless there are exemptions, deductions, or investments to reduce the taxable income. In the case of a gross salary of Rs. 659,000 during the year 2023-24, the standard deduction allowable is Rs. 50,000.',
    },
    {
        id: 'panel6',
        question: 'Which form should I file as a salaried employee?',
        answer: 'For income solely from Salary, House Property, and other sources (including saving bank interest), file ITR 1. For income included in ITR 1 and gains from the sale of capital assets such as house property, shares, etc., file ITR 2.',
    },
    {
        id: 'panel7',
        question: 'Are allowances taxable?',
        answer: 'The taxability of allowances depends on their nature. Some allowances are fully taxable, while others may be partially or fully exempt from taxes based on specified conditions outlined by tax regulations.',
    },
    {
        id: 'panel8',
        question: 'Can Employees Claim Deductions on Allowances?',
        answer: 'Employees might be eligible to claim deductions on certain allowances based on their utilization for specific purposes. It\'s advisable to refer to the Income Tax Act and relevant provisions for details on deductions.',
    },
    {
        id: 'panel9',
        question: 'Are perquisites taxable?',
        answer: 'Yes, perquisites are generally taxable as part of an employee\'s income unless specific exemptions are provided for certain perquisites under tax regulations.',
    },
    {
        id: 'panel10',
        question: 'How are perquisites valued for taxation purposes?',
        answer: 'The value of perquisites is determined based on prescribed rules and valuations specified by tax authorities. For instance, the value of a company-provided accommodation is calculated based on its rent or fair market value.',
    },
    {
        id: 'panel11',
        question: 'Can Employees Opt-out of Perquisites to Avoid Taxation?',
        answer: 'In some cases, employees may have the option to forego certain perquisites provided by the employer to avoid tax implications. However, the decision might vary based on company policies and the nature of the perk.',
    },

    // Additional FAQs on House Rent Allowance (HRA)
    {
        id: 'panel12',
        question: 'Can a working spouse claim the benefit of HRA Tax separately?',
        answer: 'Indeed. If they can each provide a separate receipt and they are both paying the landlord\'s rent. Nonetheless, there shouldn\'t be any overlap that could result in the income tax department deducting taxes from landlords\' real estate earnings twice.',
    },
    {
        id: 'panel13',
        question: 'Is the House Rent Allowance completely free from taxes?',
        answer: 'No, only a portion of the House Rent Allowance (HRA) is exempt from taxes, and there are restrictions on this exemption.',
    },
    {
        id: 'panel14',
        question: 'If I move from a small town to a large city, would my HRA change?',
        answer: 'HRA varies according to the city where you are staying. Your HRA would increase from 40% to 50% of your base pay if you moved from a non-metropolitan area to a metro area.',
    },
    {
        id: 'panel15',
        question: 'What occurs if you live in a different city than where you work?',
        answer: 'In this scenario, the HRA calculation will take the resident\'s address into account rather than the workplace.',
    },
    {
        id: 'panel16',
        question: 'What happens if the HRA Tax Benefit is denied by the employer?',
        answer: 'In the unlikely event that your company forbids tax benefits, you shouldn\'t worry. When filing your tax return, you can make this claim and get the exempted amount back as a refund of excess TDS.',
    },

    // FAQs on Leave Travel Allowance (LTA)
    {
        id: 'panel17',
        question: 'If I want to claim the exemption, how many trips can I make in a year?',
        answer: 'LTA exemption is only valid for a single travel per calendar year.',
    },
    {
        id: 'panel18',
        question: 'Can I claim my family\'s travel expenses under the LTA benefit?',
        answer: 'For the purpose of covering your own travel expenses, as well as those of your dependent parents, siblings, and spouse, you are eligible to receive the LTA benefit.',
    },
    {
        id: 'panel19',
        question: 'How do I claim LTA for a multi-destination trip?',
        answer: 'If an employee travels to multiple destinations, only allowable expenses incurred on the shortest route accessible from the point of origin to the farthest point of the journey can be claimed under LTA.',
    },
    {
        id: 'panel20',
        question: 'Is it required to send travel expense bills to my employer?',
        answer: 'Although the Income Tax Act does not require employers to gather proof of expenses claimed for LTA tax reductions, it is prudent to maintain such bills on file in order to justify the expenses if the tax authorities request it. As a result, such spending bills are not required to be sent to the employer, but the tax authorities may require them by issuing a notice.',
    },

    // FAQs on Standard Deduction
    {
        id: 'panel21',
        question: 'Can a standard deduction be claimed under section 115 BAC, the default tax regime?',
        answer: 'According to the proposed Budget 2023, salaried individuals will be able to deduct a standard amount of Rs. 50,000 starting in FY 2023–24 (AY 2024–25).',
    },
    {
        id: 'panel22',
        question: 'What documentation is needed to claim the standard deduction?',
        answer: 'There is no documentation required to claim the standard deduction if you have worked for a full year then you can directly deduct this amount at the time of filing the return.',
    },
    {
        id: 'panel23',
        question: 'Can I claim transport allowance and medical allowance in addition to the standard deduction?',
        answer: 'No, you can take only the standard deduction of 50,000 and not the transport and medical allowance.',
    },
    {
        id: 'panel24',
        question: 'Is the standard deduction calculated on a monthly basis?',
        answer: 'The standard deduction on salary, often known as salary deduction, is not calculated on a monthly basis. When filing the ITR for the assessment year, a flat deduction is allowed.',
    },
    {
        id: 'panel25',
        question: 'Even if my income exceeds Rs 2,50,000, am I still eligible to claim the regular salary deduction?',
        answer: 'No matter how much money you make, you are still eligible for the basic salary deduction. In the event that you receive a salary, you will receive the benefit. In this instance, the pay amount is unimportant.',
    },
    {
        id: 'panel26',
        question: 'Does India\'s self-employed population have access to standard deduction?',
        answer: 'Self-employed individuals do not receive a salary. Their business brings in money. Therefore, since the standard deduction is only accessible from salary income, self-employed people are unable to claim it.',
    },

    // FAQs on Professional Tax
    {
        id: 'panel27',
        question: 'How to pay professional tax?',
        answer: 'Since it is a state tax, the form of payment may differ state to state. You must go to the official website of the relevant state to know details regarding the same.',
    },
    {
        id: 'panel28',
        question: 'Is there any due date or penalty for professional tax?',
        answer: 'If you do not pay your professional tax within the due date, you will be subjected to a penalty. The rules regarding the due date and penalty will be prescribed by the respective states. For example, in Karnataka, all employers/businesses with enrollment certificates must pay the professional tax by 30th April. A penalty of 1.25% per month will be charged in case of late payment.',
    },
    {
        id: 'panel29',
        question: 'What is Professional tax enrolment certificate?',
        answer: 'The Professional tax enrolment certificate gives the employer the authority to deduct professional tax of his/her employee.',
    },
    {
        id: 'panel30',
        question: 'What is Professional tax registration certificate?',
        answer: 'The Professional tax registration certificate authorises the employer to pay professional tax on their trade or business.',
    },
    {
        id: 'panel31',
        question: 'When is the relief under section 89 of the income tax act available?',
        answer: 'Relief under section 89 is available to an individual if he has received: Salary or family pension in arrears or in advance, Gratuity in excess of exemption under section 10(10)(ii)/(iii), Compensation on termination of employment, Commuted pension in excess of exemption under section 10(10A)(i). In case of payment received other than above, CBDT can allow relief under section 89 after examining each individual case.',
    },
    {
        id: 'panel32',
        question: 'Are arrears of salary taxable?',
        answer: 'Yes. However, the benefit of spread over of income to the years to which it relates to can be availed for lower incidence of tax. This is called as relief under section 89 of the Income-tax Act.',
    },
    {
        id: 'panel33',
        question: 'What Documentation Should I Maintain for Arrears of Salary?',
        answer: 'Maintain records such as salary slips, pay statements, or any communication from the employer detailing the arrears received for future reference or tax assessment purposes.',
    },
    {
        id: 'panel34',
        question: 'Why Do Arrears of Salary Occur?',
        answer: 'Arrears of salary may occur due to various reasons, such as delayed payments by the employer, retroactive salary revisions, promotions, increments, or settlements for previous periods.',
    },
    {
        id: 'panel35',
        question: 'How Should I Report Arrears of Salary in Income Tax Returns?',
        answer: 'Arrears of salary received should be reported under the head "Income from Salary" while filing income tax returns for the respective year in which they are received.',
    },

    // FAQs on Leave Encashment
    {
        id: 'panel36',
        question: 'Is leave encashment salary taxable?',
        answer: 'It is taxable if received while in service. Leave encashment received at the time of retirement is exempt in the hands of the Government employee. In the hands of a non-Government employee, leave encashment will be exempt subject to the limit prescribed in this behalf under the Income-tax Law.',
    },
    {
        id: 'panel37',
        question: 'Is Leave Encashment a Mandatory Benefit?',
        answer: 'Leave encashment is not mandatory under law in all cases. It depends on the company\'s leave policy or employment terms and conditions whether leave encashment is offered as a benefit.',
    },
    {
        id: 'panel38',
        question: 'What Documentation is Required for Leave Encashment?',
        answer: 'Employees may need to provide a formal request or application for leave encashment to the employer, and upon approval, the employer typically issues a pay statement or leave encashment statement as proof of the payment made.',
    },
    {
        id: 'panel39',
        question: 'What is the rate of contribution to the provident fund?',
        answer: 'The government has set the rate of provident fund contribution at 10% in general and 12% in the case of establishments that have been officially notified by the government.',
    },
    {
        id: 'panel40',
        question: 'Can I Withdraw PF Before Retirement?',
        answer: 'Yes, partial withdrawals or advances from the PF account are allowed for specific purposes like buying a house, medical emergencies, marriage, education, etc., subject to certain conditions.',
    },
    {
        id: 'panel41',
        question: 'How Can I Check My PF Balance?',
        answer: 'Employees can check their PF balance online through the EPFO (Employees\' Provident Fund Organization) portal, via SMS, or by using the UMANG (Unified Mobile Application for New-age Governance) app.',
    },
    {
        id: 'panel42',
        question: 'Can I Nominate Someone for PF Benefits?',
        answer: 'Yes, employees can nominate family members for PF benefits in case of their unfortunate demise. The nomination can be updated through the employer or EPFO portal.',
    },
    {
        id: 'panel43',
        question: 'What Happens to PF in Case of Death?',
        answer: 'In the event of the member\'s death, the accumulated PF amount along with the EPS amount, if applicable, is paid to the nominee/legal heir.',
    },
    {
        id: 'panel44',
        question: 'What Documents are Required for PF Withdrawal?',
        answer: 'Documents such as PF withdrawal form, bank account details, identity proof, address proof, and other necessary documents may be required for PF withdrawal.',
    },

    // FAQs on Gratuity
    {
        id: 'panel45',
        question: 'Are All Employees Eligible for Gratuity?',
        answer: 'Not all employees are eligible for gratuity. It is typically applicable to employees who have completed a minimum period of service, usually five continuous years, with the same employer.',
    },
    {
        id: 'panel46',
        question: 'What Happens to Gratuity If an Employee Dies?',
        answer: 'In the event of the death of an employee, the gratuity amount is paid to the nominee or legal heir of the employee as per the nomination made by the employee.',
    },
    {
        id: 'panel47',
        question: 'Can an Employee Receive Gratuity Before Completing 5 Years of Service?',
        answer: 'In exceptional cases of death or disability of an employee, gratuity can be paid before completing five years of service, as per legal provisions.',
    },
    {
        id: 'panel48',
        question: 'Is There a Gratuity Nomination Form?',
        answer: 'Yes, employees are required to fill and submit a nomination form for gratuity, indicating the nominee(s) who will receive the gratuity amount in case of the employee\'s demise.',
    },
    {
        id: 'panel49',
        question: 'Can Employers Deny Payment of Gratuity?',
        answer: 'Employers are legally obligated to pay gratuity if an employee meets the eligibility criteria. Denying or delaying gratuity payment without valid reasons could lead to legal consequences.',
    },
    {
        id: 'panel50',
        question: 'Does Gratuity Apply to Contractual Employees?',
        answer: 'Gratuity is typically applicable to employees covered under the Payment of Gratuity Act, 1972. However, contract employees, if covered by the Act, may also be eligible based on the completion of five years of continuous service.',
    },
    {
        id: 'panel51',
        question: 'Can an Employee Opt for VRS More Than Once?',
        answer: 'Generally, an employee can opt for VRS only once. Once an employee avails VRS and retires voluntarily, they cannot rejoin the organization under the VRS scheme again.',
    },
    {
        id: 'panel52',
        question: 'Is VRS Mandatory?',
        answer: 'VRS is voluntary, and employees can choose whether or not to opt for it. It is not mandatory, and employees have the freedom to accept or decline the VRS offered by the employer.',
    },
    {
        id: 'panel53',
        question: 'Does VRS Affect Retirement Benefits?',
        answer: 'VRS may impact retirement benefits such as gratuity, provident fund (PF), pension, or other post-retirement benefits, depending on the company\'s policy and the terms of the VRS scheme.',
    },

    // FAQs on TDS on Salary
    {
        id: 'panel54',
        question: 'In case of remuneration to Directors, how much TDS and under which section will it be deducted?',
        answer: 'Sec 194J levies TDS on technical and professional services. As per the provisions of the Companies Act, the director of the company is also a manager and thus, a technical personnel. As per Section 194J(1)(ba), any payment made to the director in the nature of sitting fees, remuneration or any other sum other than those on which tax deductible under section 192 is to be considered for deduction of tax at source @ 10% under section 194J. Further, there is no threshold limit for deduction of tax at source.',
    },
    {
        id: 'panel55',
        question: 'I have negative income from House Property, can I ask my employer to consider this while computing TDS on Salary?',
        answer: 'Yes, however, any loss from House Property can only be claimed up to Rs. 2,00,000, and losses other than the loss from the head house property cannot be set off while determining TDS.',
    },
    {
        id: 'panel56',
        question: 'Can my employer consider relief u/s 89 for the purpose of calculating TDS?',
        answer: 'Yes, if you are a Government employee or an employee of a PSU or company or co-operative society or local authority or university or institution or association or body. In such a case you need to furnish Form No. 10E to your employer.',
    },

    // FAQs on Form 16
    {
        id: 'panel57',
        question: 'Is Form 16 mandatory for all employees?',
        answer: 'Form 16 is issued to employees whose employers have deducted TDS from their salary income.',
    },
    {
        id: 'panel58',
        question: 'Can Form 16 be issued for multiple employers in a financial year?',
        answer: 'Yes, if an individual has changed jobs during the year, each employer is required to issue a separate Form 16 for the period of employment with them.',
    },
    {
        id: 'panel59',
        question: 'Is Form 16 required for claiming tax refunds?',
        answer: 'Form 16 serves as proof of TDS deducted. However, for claiming tax refunds or adjustments, one needs to file the income tax returns.',
    },
    {
        id: 'panel60',
        question: 'What information does Form 16 contain?',
        answer: 'Form 16 consists of two parts - Part A and Part B. Part A includes details like employer and employee information, while Part B provides a breakup of salary, deductions, exemptions, and tax computation details.',
    },
    {
        id: 'panel61',
        question: 'What should I do if there is an error in Form 16?',
        answer: 'If there are errors in Form 16, you should inform your employer to rectify them before filing your income tax returns.',
    },
    {
        id: 'panel62',
        question: 'Even if no taxes are deducted from salary, is there still a need for my employer to issue form 16 to me?',
        answer: 'Form-16 is a certificate of TDS. In your case, it will not apply. However, your employer can issue a salary statement.',
    },

    // FAQs on Comparison of Old Tax Regime with New Tax Regime
    {
        id: 'panel63',
        question: 'Can we switch between the new tax regime and old tax regime?',
        answer: 'Yes, individuals are allowed to switch from one regime to another. Such an option will be available to them at the time of filing ITR every financial year. The number of times you can switch depends on the type of income you have.',
    },
    {
        id: 'panel64',
        question: 'If I am a salaried employee, how many times can I change the regime I’ve opted for?',
        answer: 'A salaried employee will be given an option to select a regime every financial year. However, if you have income from business or profession, you will be able to change your tax regime only once.',
    },
    {
        id: 'panel65',
        question: 'Is the new tax regime more simplified?',
        answer: 'Yes, the documentation process has become easier under the new tax regime. Since many deductions and exemptions have not been allowed, calculating tax and filing your ITR is less time-consuming.',
    },
    {
        id: 'panel66',
        question: 'Will advance tax payments need to be made in the new regime?',
        answer: 'Yes, advance tax needs to be paid within the due dates prescribed irrespective of the regime you opt for.',
    },
];




export default faqData;
