import React from 'react'
import Sidecomponent from '../../../Components/Tax-Planning/Sidecomponent';
import Topdiv from "../../../Components/Tax-Planning/Topdiv"
import GetInTouch from '../../../Components/Services/GetInTouch';

import Mainhead from '../../../Components/Services/MainContent/Mainhead';
import Content from '../../../Components/Services/MainContent/Content';
import List from '../../../Components/Services/MainContent/List';
import Step from '../../../Components/Services/MainContent/Step';

import TableCell, { tableCellClasses } from '@mui/material/TableCell';

import Grossvalue from '../../../Assets/TaxPlanning/HouseProperty/Grossvalue.png';
import HouseProperty from '../../../Assets/TaxPlanning/HouseProperty/HouseProperty.png';

import {
  Grid, Box, Container, styled, Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Link
} from '@mui/material';

function createData(
  Particulars, Amount
) {
  return {
  Particulars, Amount
  };
}

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: '#773106',
    color: theme.palette.common.white,
    textAlign: 'left',
    fontFamily: 'Sora',
    fontWeight: 'bold',
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 16,
    textAlign: 'left',
    textAlign: 'left',
    fontFamily: 'Sora',
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: theme.palette.action.hover,
    fontFamily: 'Sora'
  },
  // hide last border
  '&:last-child td, &:last-child th': {
    border: 0,
  },
}));

const rows = [
  createData('Gross annual value','XXX =A'),
  createData('Less: Municipal taxes paid during the year','(XXX)=B'),
  createData('Net Annual Value (NAV)','XXX = A-B= C'),
  createData('Less: Deduction u/s section 24',' '),
  createData('(i) Standard deduction u/s 24(a) @30% of NAV','(XXX)= C*30%= D'),
  createData('(ii) interest on borrowed capital u/s 24 (b)','(XXX)=E'),
  createData('Income from house property','XXX= C-D-E'),

];

const Bottomspace = styled(Box)(({ theme }) => ({
  marginBottom:'2.1rem'
}));

function Houseproperty() {

  const Gross  = styled(Box)(({ theme }) => ({
    width:'28.5rem',
    height:'32rem',
    marginTop:'0.8rem',
    [theme.breakpoints.down("md")]: {
      width:'18rem',
    height:'21.5rem',
    },
  }));

  const House  = styled(Box)(({ theme }) => ({
    width:'35rem',
    height:'61rem',
    marginTop:'0.8rem',
    [theme.breakpoints.down("md")]: {
      width:'25rem',
    height:'35rem',
    },
  }));
  return (
    <div>
 <Topdiv heading="Are you looking to file your Income Tax Returns ?"/>
 <div className='Container'>
<Container>
      
      <Grid container>
          <Grid item  lg={4} xl={3}>
            <Box sx={{ display: { xs: 'none', md: 'block' }, marginTop: '4rem', position: 'sticky', top: 90, marginBottom: '3rem' }}>
              <Sidecomponent />
            </Box>
          </Grid>

          <Grid item xs={11} lg={8} sx={{ marginTop: '4rem' }} xl={9}>
          <Mainhead heading="What is House Property?"/>
          <Content paragraph="House property refers to any type of building or real estate that is owned by an individual, including residential homes, office spaces, shops, flats, farmhouses, cinema halls, hotel buildings, and more. These properties are considered part of the taxpayer's assets and are subject to certain tax regulations."/>
           <Bottomspace/>
          <Mainhead heading="What is “Income from House Property”?"/>
          <Content paragraph="In India, the income generated from renting out a building or land adjacent to it, of which the person is the owner, is taxed under the category of 'Income from house property'. This refers to any rental income received from the ownership of a residential, commercial, or any other type of property."/>
          <Bottomspace/>
          <Mainhead heading="Computation of Income from a House Property:"/>
          <br/>

          <TableContainer component={Paper}>
                <Table  aria-label="customized table"  size='small'>
        <TableHead>
          <TableRow>
            <StyledTableCell>Particulars </StyledTableCell>
            <StyledTableCell>Amount </StyledTableCell>
           
           
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.map((row) => (
            <StyledTableRow
              key={row.name}
            
            >
              <StyledTableCell component="th" scope="row"
              >
                {row.Particulars}
              </StyledTableCell>
              <StyledTableCell>{row.Amount}</StyledTableCell>
              </StyledTableRow>
          ))}
        </TableBody>
      </Table>
      </TableContainer>
      <br/><br/>

      <Mainhead heading="Computation of Gross Annual value of a House Property:"/>
      <Content paragraph="The computation of the gross annual value of a house property involves determining the higher value between two amounts: expected rent (ER) and actual rent received or receivable. If the property is rented out for the entire year, the gross annual value is calculated as the greater of these two values. The expected rent is the estimated amount of rent that could be obtained for the property, while the actual rent received or receivable is the amount actually received by the owner during the year. The gross annual value is used as a basis for determining the taxable income from the property."/>
       <Bottomspace/>
      <Mainhead heading="Computation of expected rent while computing gross annual value of a House Property:"/>
      <Content paragraph="The calculation of expected rent while computing the gross annual value of a house property is based on the highest value of the following:"/>
       <Step number="1.Municipal value of the property - "/>
       <Content paragraph=" This is the value assigned to the property by the local municipality for tax purposes."/>

       <Step number="2.Fair rent of the property -"/>
       <Content paragraph=" This is the rent that would reasonably be expected to be obtained for the property in the open market."/>
      <Bottomspace/>
       <Content paragraph="If the property is subject to the Rent Control Act, the expected rent cannot exceed the standard rent, which is the maximum legal rent that can be charged for the property. The reasonable expected rent is used as a basis for determining the taxable income from the property. It is important to note that the expected rent must be determined in a fair and reasonable manner, taking into account all relevant circumstances."/>
       <Bottomspace/>
       <Box sx={{textAlign:'center'}}>
      <Gross component="img"
                sx={{
                  content: {
                    xs: `url(${Grossvalue})`
                  }
                }}/>
                </Box>
                <br/><br/><br/>

                <Mainhead heading="Computation of actual rent while computing gross annual value of a property which is let-out throughout the year:"/>
                <Content paragraph="The computation of actual rent while determining the gross annual value of a let-out property is calculated as the sum of the following:"/>     
                <Step number="1.Rent received - "/> 
                <Content paragraph=" This is the amount of rent that was actually collected by the owner during the year."/>

                <Step number="2.Rent receivable-"/> 
                <Content paragraph=" This is the amount of rent that was due but not yet collected by the owner at the end of the year."/>

                <Step number="3.Unrealized rent -"/> 
                <Content paragraph="This is the amount of rent that was expected to be received but was not, due to reasons such as the property being unoccupied for some period of time."/>
                <Bottomspace/>
                <Content paragraph="The actual rent is calculated by adding the rent received and rent receivable, and then subtracting any unrealized rent. The resulting amount is used in the calculation of the gross annual value, which is used as a basis for determining the taxable income from the property."/>
                <Bottomspace/>
                <Mainhead heading="Deductions available while computing income from house property:"/>
                
                <Content paragraph="When computing income from a house property, there are two deductions available:"/>
                 
                <Step number="1.Deduction under Section 24(a) -"/>
                <Content paragraph="This deduction allows for a reduction of 30% of the net annual value of the property."/>

                <Step number="2.Deduction under Section 24(b) -"/>
                <Content paragraph="This deduction covers the interest paid on any capital borrowed for the purpose of purchasing, constructing, repairing, renewing, or reconstructing the house property."/>
                <br/>

                <Content paragraph="These deductions can significantly reduce the taxable income from a house property and help the owner lower their tax liability."/>
                <Bottomspace/>
                <Box sx={{textAlign:'center'}}>
            <House component="img"
                sx={{
                  content: {
                    xs: `url(${HouseProperty})`
                  }
                }}/>
                </Box>
                <br/>
                <Content paragraph="*PGBP- Profits and  gains from business and profession ( i.e. BUSINESS INCOME)"/>
                <br/> <br/>

                <Step number="1.Whether rental income could be charged to tax in the hands of a person who is not a registered owner of the property?"/>
                <Content paragraph="Rental income from a property is only chargeable to tax under the head 'Income from house property' if the person receiving the rent is the registered owner of the property. If the person receiving the rent is not the owner, then the rental income is not considered taxable under this head. However, in the case of an owner, rental income from the property will be taxed in their hands under the 'Income from house property'category. It is important to note that the registered owner of the property is responsible for paying tax on the rental income received from the property."/>
                <Bottomspace/>
                <Step number="2.What is meant by owner and deemed owner?"/>
                <Content paragraph="The term 'owner' refers to a person whose name the property is registered in. An 'owner' has the legal right to possess, use, and benefit from the property. A 'deemed owner' is a person who is considered to be the owner of the property, even though their name may not be registered as the owner. This can occur in cases where the property has been transferred to a spouse without adequate consideration, such as in a gift or inheritance. However, there is an exception to this rule. If the transfer of property to a spouse is made in connection with an agreement to live apart, then the transferor will not be deemed to be the owner, and the transferee will be considered the owner of the house property."/>
                <Bottomspace/>
                <Step number="3.What is the tax treatment of arrears of rent?"/>
                <Content paragraph="The tax treatment of arrears of rent received by an individual is as follows: When a tenant pays arrears of rent to an individual, the amount received is considered taxable under the head 'Income from house property' in the year in which it is recovered. This applies regardless of whether the individual is the owner of the property or not. However, the individual can deduct a sum equal to 30% of the arrears before computing the taxable amount. This reduction of 30% is in accordance with Section 24(a) of the Income Tax Act."/>
                <Bottomspace/>
                <Step number="4. If I have paid house tax / municipal tax to any government urban body shall I get any benefit ?"/>
                <Content paragraph="If you have paid house tax or municipal tax to a government urban body for a rented or let-out property, you can claim it as a deduction while computing your rental income. This means that the amount paid as house tax or municipal tax can be subtracted from the total rental income received, which reduces the taxable amount of rental income."/>
                <Bottomspace/>
                <Step number="5. If I pay any monthly charges for society maintenance / club charges to the housing society I am residing in or my let out property is located at . shall I be allowed any deduction ?"/>
                <Content paragraph="No"/>
                <br/>

                <Step number="6. How to compute income from a property, when part of the property is self-occupied and part is let-out?"/>
                <Content paragraph="When part of a property is self-occupied and part is let out, the computation of income from the property must take into account both the self-occupied and let-out portions. The area of the property that is let out is considered as a let-out property and is taxed as such, while the self-occupied portion is considered as a self-occupied property and is taxed differently. To compute the income, all the relevant parameters, such as the expected rent, municipal value, fair rent, etc., should be divided between the self-occupied and let-out portions of the property on an area basis. This means that each parameter should be calculated based on the relative area of the self-occupied and let-out portions of the property."/>
                <Bottomspace/>
                <Step number="7. I own three houses which are occupied by me and my family. Is there any tax implication ?"/>
                <Content paragraph="If a person owns three houses that are occupied by themselves and their family, they may be subject to tax implications. As per the tax laws, an individual can consider two of these properties as self-occupied, meaning they will not be taxed for these properties. The third property, however, will be considered a deemed let-out property and will be taxed accordingly. The taxpayer can choose which two properties they would like to designate as self-occupied."/>
                <Bottomspace/>
                <Step number="8. How to compute income from a property which is self-occupied for part of the year and let out for part of the year?"/>
                <Content paragraph="When a property is self-occupied for part of the year and rented out for the rest of the year, it is considered as a let-out property for the entire year. The income from the property is calculated based on the actual rent received during the rented-out period. The computation of taxable income in this case takes into account the rent received only during the let-out period."/>
                <Bottomspace/>
                <Step number="9. How to compute the gross annual value in the case of a property which is vacant for some time during the year?"/>
                <Content paragraph="In the case of a property that is unoccupied for a certain period of time during the year, the computation of its gross annual value will be based on the actual rent received or receivable by the owner during the year, reduced by the 'vacant allowance'.If this amount is lower than the reasonable expected rent, it will be considered as the Gross Annual Value of the property."/>
                <Bottomspace/>

                <Step number="10. While computing income chargeable to tax under the head “Income from house property” in the case of a let-out property, what are the expenses to be deducted from gross annual value?"/>
                <Content paragraph="When calculating the taxable income from a let-out property under the 'Income from house property' category, only certain expenses can be deducted from the Gross Annual Value. These include:"/>
                <ul className='list'>
                  <li className='bullet'>
                    <span>
                <List points="Municipal taxes paid by the owner during the year"/>
                </span>
                </li>
                <li className='bullet'>
                    <span>
                <List points="A deduction of 30% of the Net Annual Value, as per Section 24(a)"/>
                </span>
                </li>
                <li className='bullet'>
                    <span>
                <List points="Interest on capital borrowed for the purpose of purchasing, repairing, constructing, renewing, or reconstructing the property, as per Section 24(b)."/>
               
               </span>
               </li>
               </ul>

                <Step number="11. Can interest paid on loans taken from friends and relatives be claimed as deduction while calculating house property income?"/>
                <Content paragraph="Interest paid on loans taken from friends and relatives can only be claimed as a deduction while calculating house property income if the loan was taken specifically for the purpose of purchasing, constructing, repairing, renewing, or reconstructing the house. If the loan was taken for personal or any other purposes, the interest paid on such a loan cannot be considered as a deductible expense."/>
                <Bottomspace/>

                <Step number="12. While computing income chargeable to tax under the head “Income from house property” in the case of a let-out property, how much interest on a housing loan can be claimed as deduction?"/>
                <Content paragraph="While computing the income chargeable to tax under the head 'Income from house property' in the case of a let-out property, the interest paid on a housing loan for the purpose of buying, constructing, repairing, renewing, or reconstructing the property can be claimed as a deduction with no limit on the amount."/>
                <Bottomspace/>

                <Step number="13. What is the tax treatment in case of a co-owned property?"/>
                <Content paragraph="In the case of a co-owned property, the tax treatment depends on the certainty of each member's share in the property. If the share of each member is not specified, the income from the house property will be calculated as an Association of Persons (AOP) or Body of Individuals (BOI) rather than as individuals. However, if the share of each member is certain, each co-owner's portion of the property is valued separately and each co-owner is eligible for an interest deduction of either INR 30,000 or INR 200,000. If the co-owned property is let out, it will be considered as a single property for calculation purposes, and the income will be later divided among the different members."/>
                <Bottomspace/>

                <Step number="14. How to compute income from self occupied property?"/>
                <Content paragraph="The computation of income from a self-occupied property involves taking into account the interest on any housing loan taken. If there is no interest on the housing loan, then the income from the self-occupied property will be Nil. If there is an interest on the housing loan, then the amount of income will be negative, to the extent of the interest on the loan. The maximum amount of deduction allowed for the interest on the housing loan in case of a self-occupied property is either Rs. 2,00,000 or Rs. 30,000, whichever is applicable. No deduction for municipal taxes paid during the year will be allowed for a self-occupied property."/>
                <Bottomspace/>

                <Step number="15. ​​​​​​​​​​How much interest paid on Home Loan allowed as deduction in case of let out property?"/>
                <Content paragraph="The entire amount of interest paid on a home loan for a let-out property is eligible for deductions while computing the taxable income."/>
                <Bottomspace/>

                <Step number="16. How property owned by partnership firms is treated?"/>
                <Content paragraph="In case of a property owned by a partnership firm, the income generated from the property is considered to be that of the partnership firm and not of the individual partners, even if the property is registered in the name of one of the partners. If the property is treated as a firm's asset, then the income generated will be taxed as that of the partnership firm."/>
                <Bottomspace/>

       </Grid>
        </Grid>
        </Container>
        </div>
        
    </div>
  )
}

export default Houseproperty