import React from 'react'
import Mainhead from '../../../../Components/Services/MainContent/Mainhead';
import Content from '../../../../Components/Services/MainContent/Content';

function Overview() {
  return (
    <div>
        <Mainhead heading="What is Producer Company?"/>
        <Content paragraph="India is an agricultural country whose 60% economy is governed by agriculture. But these primary producers & farmers struggle hard to get their share of profit. Thereby Producer company was introduced with the arrival of Companies Act,2013. A Producer company is basically for agro based businesses supportive to marginal and small scale farmers, registered with the Ministry of Corporate Affairs(MCA) wherein 10 committee members along with 2 institutions make up the entire producer company. It can only have equity capital, require a minimum of 5 directors and an authorized capital of 5 lakh. The procedure to form a Producer company resembles that of a private limited company"/>
        
    </div>
  )
}

export default Overview
