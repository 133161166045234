import React from 'react'
import Mainhead from "../../../../Components/Services/MainContent/Mainhead";
import List from "../../../../Components/Services/MainContent/List";
import Advantagesole from "../../../../Assets/Services/Soleproprietorship/LegalFactory_Sole1.png";
import Benefitsole from "../../../../Assets/Services/Soleproprietorship/LegalFactory_Sole2.png"
import { Box , styled} from '@mui/material';

const Advantagesimg = styled(Box)(({ theme }) => ({
    width:'28.5rem',
    height:'32rem',
    marginTop:'0.8rem',
    [theme.breakpoints.down("sm")]: {
      width:'18rem',
    height:'21.5rem',
    },
    [theme.breakpoints.down("md")]: {
      width:'18rem',
    height:'21.5rem',
    },
    [theme.breakpoints.between("md", "lg")]: {
      width:'25rem'
    }
  }));

  const Benefitimg = styled(Box)(({ theme }) => ({
    width:'28.5rem',
    height:'32rem',
    marginTop:'0.8rem',
    [theme.breakpoints.down("sm")]: {
      width:'18rem',
    height:'21.5rem',
    },
    [theme.breakpoints.down("md")]: {
      width:'18rem',
    height:'21.5rem',
    },
    [theme.breakpoints.between("md", "lg")]: {
      width:'25rem'
    }
  }));


function Advantages() {
  return (
    <div>
         <Mainhead heading="Advantages of having a Sole Proprietorship" />
         <ul className='list'>
          <li className='bullet'>
            <span>
         <List points="It can be incorporated by a single person and so it is Easy to Operate"/>
         </span>
         </li>
         <li className='bullet'>
            <span>
         <List points="It does not have any specific registration requirements and the legal identity of the proprietor can be used by the business. Hence, a proprietorship can be started without any registration."/>
         </span>
         </li>
         <li className='bullet'>
            <span>
         <List points="The compliance requirements are very low as compared to other types of entities."/>
         </span>
         </li>
         <li className='bullet'>
            <span>
         <List points="It's economical as only one person is Sole Beneficiary"/>
         </span>
         </li>
         <li className='bullet'>
            <span>
         <List points="Only one person is responsible for decision making and control of the business."/>
         </span>
         </li>
         </ul>
          <br/>
         <Box sx={{textAlign:'center'}}>
      <Advantagesimg component="img"
                sx={{
                  content: {
                    xs: `url(${Advantagesole})`
                  }
                }}/>
                </Box>
                <br/> <br/>
                <Box sx={{textAlign:'center'}}>
      <Benefitimg component="img"
                sx={{
                  content: {
                    xs: `url(${Benefitsole})`
                  }
                }}/>
                </Box>      

    </div>
  )
}

export default Advantages
