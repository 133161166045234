import React, { useState } from "react";
import {
  AppBar,
  Box,
  Button,
  Container,
  Divider,
  Grid,
  Menu,
  Toolbar,
} from "@mui/material";
import logo from "../Assets/Logo/LegalFactory_Logo1.png";
import MenuButton from "./Header/MenuButton";
import { useNavigate } from "react-router-dom";
import "./Header.css";
import Logo from "./Header/Logo";
// Income tax
import CustomAccordion from "./Header/CustomAccordion";
import accordionData from "./Header/accordionData";
import { MenuLinks } from "./Header/HeaderStyles";
import TaxPlanningAccordion from "./Header/MobCustomAccordion";
const Header = () => {
  const navigate = useNavigate();

  const [anchorElNav, setAnchorElNav] = useState(null);
  const handleOpenNavMenu = (event) => {
    setAnchorElNav(event.currentTarget);
  };
  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  const clickContact = () => {
    navigate("/contact");
  };

  const clickSignIn = () => {
    navigate("/sign-in");
  };

  return (
    <>
      <AppBar
        elevation={4}
        sx={{
          backgroundColor: "#FFFFFF",
          color: "black",
          width: "100%",
          borderBottom: "1px solid #DADADA",
        }}
        position="sticky"
      >
        <Container>
          <Toolbar disableGutters>
            <Box sx={{ flexGrow: 1, display: { xs: "none", lg: "flex" } }}>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  width: "100%",
                }}
                component="div"
              >
                <Box sx={{ display: "flex" }}>
                  <Logo />
                </Box>
                <Box sx={{ display: "flex" }}>
                  {accordionData.map((data, index) => (
                    <CustomAccordion
                      key={index}
                      title={data.title}
                      menuItems={data.menuItems}
                    />
                  ))}

                  <MenuLinks onClick={clickContact}>Contact Us</MenuLinks>
                </Box>
                <Box sx={{ display: "flex" }}>
                  <Button
                    variant="outlined"
                    size="small"
                    disableElevation
                    onClick={clickSignIn}
                    sx={{
                      my: 1.5,
                      borderRadius: "30px",
                      fontSize: "14px",
                      color: "#FFA511",
                      fontWeight: "bold",
                      width: "80px",
                      height: "40px",
                      border: "2px solid #FFA511",
                      "&:hover": {
                        border: "2px solid green",
                        color: "green",
                      },
                    }}
                  >
                    Log In
                  </Button>
                </Box>
              </Box>
            </Box>
            <>
              <Box sx={{ flexGrow: 1, display: { xs: "flex", lg: "none" } }}>
                <Grid container justifyContent="flex-start">
                  <Grid>
                    <img
                      onClick={() => navigate("/")}
                      src={logo}
                      alt="logo"
                      style={{
                        height: "35px",
                        width: "190px",
                        marginTop: "4px",
                      }}
                    />
                  </Grid>
                </Grid>

                <Grid container justifyContent="flex-end">
                  <MenuButton handleOpenNavMenu={handleOpenNavMenu} />
                </Grid>

                <Menu
                  id="menu-appbar"
                  anchorEl={anchorElNav}
                  anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "right",
                  }}
                  keepMounted
                  transformOrigin={{
                    vertical: "top",
                    horizontal: "right",
                  }}
                  open={Boolean(anchorElNav)}
                  onClose={handleCloseNavMenu}
                  sx={{
                    display: { xs: "block", lg: "none" },
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      width: "230px",
                      height: "auto",
                    }}
                  >
                    <div style={{ textAlign: "left" }}>
                      {accordionData.map((data, index) => (
                        <TaxPlanningAccordion
                          key={index}
                          title={data.title}
                          menuItems={data.menuItems}
                        />
                      ))}

                      <Button
                        sx={{
                          color: "#323C40",
                          fontSize: "13px",
                          fontWeight: "650",
                          my: 0.5,
                          px: 2,
                          ml: 1.5,
                        }}
                        onClick={clickContact}
                      >
                        Contact Us
                      </Button>
                      <Divider variant="small" sx={{ background: "#FFA511" }} />

                      <Button
                        variant="outlined"
                        size="small"
                        disableElevation
                        sx={{
                          ml: 3.5,
                          mr: 3,
                          mt: 2,

                          borderRadius: "20px",
                          fontSize: "14px",
                          color: "#FFA511",
                          fontWeight: "bold",
                          border: "2px solid #FFA511",
                          "&:hover": {
                            border: "2px solid green",
                            color: "green",
                          },
                        }}
                      >
                        Log In
                      </Button>
                    </div>
                  </Box>
                </Menu>
              </Box>
            </>
          </Toolbar>
        </Container>
      </AppBar>
    </>
  );
};
export default Header;
