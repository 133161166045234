import React from 'react';
import Sidecomponent from '../../../Components/Tax-Planning/Sidecomponent';
import Topdiv from "../../../Components/Tax-Planning/Topdiv"
import GetInTouch from '../../../Components/Services/GetInTouch';
import {
  Grid, Box, Container, styled, Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Link
} from '@mui/material';


import Mainhead from '../../../Components/Services/MainContent/Mainhead';
import Content from '../../../Components/Services/MainContent/Content';
import List from '../../../Components/Services/MainContent/List';
import Step from '../../../Components/Services/MainContent/Step';

import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import Faq from './Faq';

function createData(
  Type,
  Description,
  Income
 ) {
     return {  
      Type,
      Description,
      Income
     };
   }
   const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor:'#773106',
      color: theme.palette.common.white,
      textAlign:'left',
      fontFamily: 'Sora',
      fontWeight:'bold',
      
      
    
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 16,
      textAlign:'left',
      textAlign:'left',
      fontFamily: 'Sora',
     },
  }));
  
  const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(even)': {
      backgroundColor: theme.palette.action.hover,
      fontFamily:'Sora'
    },
    // hide last border
    '&:last-child td, &:last-child th': {
        border: 0 ,
    },
  }));

  const rows = [
    createData ('Summary Assessment','A preliminary check of the return of income to check for arithmetical errors and small inconsistencies. Results in an intimation letter specifying refund, demand or no change.','143(1)'),
    createData('Scrutiny Assessment','A detailed review of the return of income to confirm the correctness and genuineness of claims, deductions, etc. Results in an assessment order computing the assessed taxable income of the taxpayer.','143(3)'),
    createData ('Re-Assessment','A review of the return of income initiated by the Assessing Officer when there is a belief that income has been underreported or not reported. Results in an assessment order and may result in a demand against the taxpayer.','147'),
    createData ('Best-Judgement Assessment','An assessment made by the Assessing Officer when the taxpayer does not file a return or respond to notices. The assessment is based on the best judgment of the Assessing Officer','144'),
  ];


function Incomeassessed() {
  return (
    <div>
      <Topdiv heading="Are you looking to file your Income Tax Returns ?"/>
      <div className='Container'>
      <Container>
        <Grid container>
          <Grid item  lg={4} xl={3}>
            <Box sx={{ display: { xs: 'none', lg: 'block' }, marginTop: '4rem', position: 'sticky', top: 90, marginBottom: '3rem' }}>
              <Sidecomponent />
            </Box>
          </Grid>
          <Grid item xs={11} lg={8} sx={{ marginTop: '4rem' }} xl={9}>
            <Mainhead heading="Assessment" />
            <Content paragraph ="The process of verification of the correctness of the return filed by the assessee by the Income Tax Department is known as Assessment. The assessment process involves reviewing the information provided in the tax return to ensure that it is accurate and complete, and that the appropriate amount of tax has been paid. The assessing officer may also make inquiries and seek additional information or documentation if necessary."/>
            <br/>
            <Mainhead heading="Major Assessments under the Income-tax Law"/>
            <br/>
            <TableContainer component={Paper}>
                <Table  aria-label="customized table"  size='small'>
        <TableHead>
          <TableRow>
            <StyledTableCell>Type of Assessment </StyledTableCell>
            <StyledTableCell>Description</StyledTableCell>
            <StyledTableCell>Income Tax Act Section</StyledTableCell>
           
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.map((row) => (
            <StyledTableRow
              key={row.name}
            
            >
              <StyledTableCell component="th" scope="row"
              >
                {row.Type}
              </StyledTableCell>
              <StyledTableCell>{row.Description}</StyledTableCell>
              <StyledTableCell>{row.Income}</StyledTableCell>
              </StyledTableRow>
          ))}
        </TableBody>
      </Table>
      </TableContainer>
      <br/><br/>
<Mainhead heading="Summary Assessment"/>
<Content paragraph="The Income Tax Return (ITR) is verified and checked for defects and discrepancies. If defects are found, a defective return notice is issued and the taxpayer has 30 days to correct them online. If no defects are found or they are corrected, the ITR is processed for summary assessment. The processing of ITR is also known as summary assessment, which verifies the correctness of tax payment, TDS credit, income, deductions and losses claimed."/>
<br/>
<Mainhead heading="In processing, the ITR is checked for following things:"/>
<List points="orderVerify Tax Payment"/>
<List points="Check if TDS credit claimed in ITR matches with that deposited by deductor"/>
<List points="Check if income claimed in ITR matches with other details in ITR"/>
<List points="Check if deductions claimed are arithmetically correct"/>
<List points="Check if loss claimed in ITR is in order"/>
<br/>

<Mainhead heading="Scrutiny assessment"/>
<Content paragraph="Scrutiny assessment is a detailed assessment of the Income Tax Return (ITR) where the correctness and genuineness of various claims and deductions made by the taxpayer are confirmed. If a mismatch is found in the submitted statement, the assessee has the option to agree with the claim or appeal to the commissioner of income tax appeals (CITA) and subsequently to the income tax appellate tribunal (ITAT), high court or supreme court if they have dissatisfaction with the claim."/>
<br/>

<Mainhead heading="Best judgment assessment"/>
<Content paragraph="Best judgment assessment is a method used by the tax officer to compute the tax payable when the assessee does not provide or maintain the necessary source documents or book of accounts to support the claims made in their tax return. Under this method, the officer uses their best judgment to estimate the income and tax liability based on available information, and the assessee is held liable for any additional tax, interest or penalty resulting from the assessment."/>
<br/>

<Mainhead heading="In case of the following 4 defaults, A.O. shall make an assessment to the best of his judgment and knowledge:"/>
<Step points="1.When the assessee does not file an income tax return"/>
<Step points="2.When the assessee does not respond to the notice requesting submission of documents"/>
<Step points="3.The response of the assessee has crossed the limit permitted by the Central Board of Direct Taxes (CBDT)"/>
<Step points="4.In such cases, the A.O. will use their discretion and knowledge to estimate the income and tax liability of the assessee based on the available information, and the assessee will be held liable for any additional tax, interest or penalty resulting from the assessment."/>
<br/>

<Mainhead heading="Income escaping assessment"/>
<Content paragraph="Income escaping assessment is a process where the assessing officer (A.O.) believes that any income chargeable to tax has not been reported or has been under reported for a financial year. In such cases, the Income Tax Department has the authority to revisit and review the tax filing of the previous 6 years if the alleged amount of unreported income is Rs. 1,00,000 or more. The time limit for opening such cases is 3 years. However, in cases where the concealment of income exceeds Rs. 50 Lakhs, the cases can be opened for 10 years. This is done to ensure that all income is reported and the correct amount of tax is paid."/>
<br/>

<Mainhead heading="Circumstances under which income is deemed to have escaped assessment are:"/>
<Step number ="1.When the assessee is found to have taxable income but has not filed income tax returns for the financial year."/>
<Step number ="2.The submitted income tax return is under or overstated."/>
<Step number ="3.Failure to furnish information relating to international income"/>
<Step number ="4.Unaccounted overseas assets-When the income of the assessee exceeds the tax exemption limit but has not filed income tax returns."/>
<Step number ="5.Under these circumstances, the assessing officer (A.O.) has the authority to conduct an income escaping assessment to ensure that all income is reported and the correct amount of tax is paid."/>
<br/>
<Mainhead heading="Faceless assessment"/>
<Content paragraph="Faceless assessment is a process where the assessment proceedings are conducted electronically through the assessee's registered account on a designated portal. This means that the entire process, from receiving the income tax return, to providing the notice, to communicating with the assessee, to giving the order, is done through the electronic mode. This method eliminates the need for the assessee and the tax officer to be physically present at the same place and reduces human interface, thus minimizing the chances of corruption and increasing transparency."/>
<br/>
<Faq/><br/><br/><br/>
            </Grid>
            </Grid>
            
            
            </Container>
            </div>
    </div>
  )
}

export default Incomeassessed