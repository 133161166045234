import React,{ useRef, useEffect, useState } from 'react';
import {
    Box,
    Typography,
    Container,
    styled,
    Divider,
    Grid,
    Button,
    useTheme,
  useMediaQuery
} from '@mui/material';
import "../Common.css"

import Topbox from '../../../Components/Services/Topbox';
import GetInTouch from '../../../Components/Services/GetInTouch';
import Overview from './Overview/Overview';
import Advantage from './Advantage/Advantage';
import Checklist from './Checklist/Checklist';
import Register from './Register/Register';
import Documents from './Documents/Documents';
import WhyLegalFactory from './WhyLegalFactroy/WhyLegalFactroy';
import FAQ from './FAQ/FAQ';

const getDimensions = ele => {
    const { height } = ele.getBoundingClientRect();
    const offsetTop = ele.offsetTop;
    const offsetBottom = offsetTop + height;
  
    return {
      height,
      offsetTop,
      offsetBottom,
    };
  };
  
  const scrollTo = ele => {
    ele.scrollIntoView({
      behavior: "smooth",
      block: "start",
    });
  };

const LimitedLiabilityPartnership = () => {

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const isTablet = useMediaQuery(theme.breakpoints.down("md"));
  const [visibleSection, setVisibleSection] = useState();
  const headerRef = useRef(null);
    const overviewRef = useRef(null);
    const advantageRef = useRef(null);
    const checklistRef = useRef(null);
    const howtoregisterRef = useRef(null);
    const documentsrequiredRef = useRef(null);
    const whylegalfactoryRef = useRef(null);
    const faqRef = useRef(null);
  
    const sectionRefs = [
      { section: "Overview_limited", ref: overviewRef },
      { section: "Advantage_limited", ref: advantageRef },
      { section: "Checklist_limited", ref: checklistRef },
      { section: "HowToRegister_limited", ref: howtoregisterRef },
      { section: "DocumentsRequired_limited", ref: documentsrequiredRef },
      { section: "WhyLegalFactory_limited", ref: whylegalfactoryRef },
      { section: "Faq_limited", ref: faqRef },
    ];
  
    useEffect(() => {
      const handleScroll = () => {
        const { height: headerHeight } = getDimensions(headerRef.current);
        const scrollPosition = window.scrollY + headerHeight;
  
        const selected = sectionRefs.find(({ section, ref }) => {
          const ele = ref.current;
          if (ele) {
            const { offsetBottom, offsetTop } = getDimensions(ele);
            return scrollPosition > offsetTop && scrollPosition < offsetBottom;
          }
        });
  
        if (selected && selected.section !== visibleSection) {
          setVisibleSection(selected.section);
        }
         
         else if (!selected && visibleSection) {
          setVisibleSection(undefined);
        }
      };
  
      handleScroll();
      window.addEventListener("scroll", handleScroll);
      return () => {
        window.removeEventListener("scroll", handleScroll);
      };
    }, [visibleSection]);
    
    
    const Topspacer  = styled(Box)(({ theme }) => ({
      height:'6rem',
      [theme.breakpoints.down("sm")]: {
        height:'1.5rem',
      }
          
  }));

  const Content  = styled(Box)(({ theme }) => ({
    
        
}));
  
  
  const Sticky  = styled(Box)(({ theme }) => ({
      position:'sticky',
      top:90,
      left:0,
     }));
  
  const PLheader  = styled(Box)(({ theme }) => ({
      display:'flex',
      flexDirection:'column',
      justifyContent:'flex-start',
  
  }));
  
  const Bottom_spacer  = styled(Box)(({ theme }) => ({
      height:'8rem',
  }));
  

  const Section  = styled(Box)(({ theme }) => ({
      height:'auto'
    }));

    return (
      <>
      <Topbox heading="Limited Liability Partnership" service="Limited Liability Partnership" document={[<a href='#DocumentsRequired_limited' style={{color:'#F19E1E'}} onClick={() => {
        scrollTo(documentsrequiredRef.current);
      }}>LearnMore</a>]}

      description="Embrace the power of partnership with ease. [Company Name] provides efficient LLP Registration services, laying the foundation for a successful collaborative venture."

      rating="4.3"

      clients= "From 75 customers"

      priceinfo={["Start your Limited Liability Partnership Company Registration Journey At "]}/>
      
        <div className='Container'>  
              <Container>
              <div className="App">
          <Topspacer />
          
         <Content>
            <Grid container spacing={1}>
                <Grid item lg={3} xl={3} md={3}
                sx={
                  isMobile || isTablet ? { display: 'none' } : { }
                }>
                  
            <Sticky>
              <PLheader ref={headerRef}>
                <Box
                  className={`header_link ${visibleSection === "Overview_limited" ? "selected" : ""}`}
                  onClick={() => {
                    scrollTo(overviewRef.current);
                  }}
                >
                  Overview
                </Box>
                <Box
                  type="button"
                  className={`header_link ${visibleSection === "Advantage_limited" ? "selected" : ""}`}
                  onClick={() => {
                    scrollTo(advantageRef.current);
                  }}
                >
                  Advantage
                </Box>
                <Box
                  type="button"
                  className={`header_link ${visibleSection === "Checklist_limited" ? "selected" : ""}`}
                  onClick={() => {
                    scrollTo(checklistRef.current);
                  }}
                >
                  Checklist
                </Box>
    
                <Box
                  type="button"
                  className={`header_link ${visibleSection === "HowToRegister_limited" ? "selected" : ""}`}
                  onClick={() => {
                    scrollTo(howtoregisterRef.current);
                  }}
                >
                  How To Register
                </Box>
                <Box
                  type="button"
                  className={`header_link ${visibleSection === "DocumentsRequired_limited" ? "selected" : ""}`}
                  onClick={() => {
                    scrollTo(documentsrequiredRef.current);
                  }}
                >
                  Documents Required
                </Box>
                <Box
                  type="button"
                  className={`header_link ${visibleSection === "WhyLegalFactory_limited" ? "selected" : ""}`}
                  onClick={() => {
                    scrollTo(whylegalfactoryRef.current);
                  }}
                >
                  Why Legal Factory?
                </Box>
                <Box
                  type="button"
                  className={`header_link ${visibleSection === "Faq_limited" ? "selected" : ""}`}
                  onClick={() => {
                    scrollTo(faqRef.current);
                  }}
                >
                  FAQ'S
                </Box>
                </PLheader>
            </Sticky>
            
            </Grid>
            <Grid item md={9} lg={9} xl={9}
             sx={
              isMobile || isTablet ? { display: 'none' } : { }
            }>
            <Section id="Overview_limited" ref={overviewRef}>
              <Overview/>
            </Section>
            <Topspacer/>
            <Section id="Advantage_limited" ref={advantageRef}>
                <Advantage/>
            </Section>
    
            <Topspacer/>
            
            <Section id="Checklist_limited" ref={checklistRef}>
              <Checklist/>
            </Section>
            <Topspacer/>
            
            
            <Section id="HowToRegister_limited" ref={howtoregisterRef}>
             <Register/>
            </Section>
    
            <Topspacer/>
            <Section id="DocumentsRequired_limited" ref={documentsrequiredRef}>
            <Documents/>
            </Section>
    
            <Topspacer/>
            <Section id="WhyLegalFactory_limited" ref={whylegalfactoryRef}>
            <WhyLegalFactory/>
            </Section>
           <Topspacer/>
            <Section id="Faq_limited" ref={faqRef}>
            <FAQ/>
            </Section>
            </Grid>
    
           
              <GetInTouch Floatingicon="Limited Liability Partnership"/>
            
            </Grid>
           </Content>
    <Box sx={
              isMobile || isTablet ? {  } : {display:'none' }
            }>
              <Grid container>
              <Grid item xs={12}>
              <Overview/>
              <br/>
              <Advantage/>
              <br/>
              <Checklist/>
              <br/>
              <Register/>
              <br/>
              <Documents/>
              <br/>
              <WhyLegalFactory/>
              <br/>
              <FAQ/>
              </Grid>
              </Grid>
            </Box>
          
          
    
          <Bottom_spacer/>
          
          
        </div>
               
               </Container>
            </div>
            </>
       )
    }

export default LimitedLiabilityPartnership;