import React from 'react'
import Mainhead from '../../../../Components/Services/MainContent/Mainhead';
import Content from '../../../../Components/Services/MainContent/Content'
import List from '../../../../Components/Services/MainContent/List';
function Legalfactory() {
  return (
    <div>
      <Mainhead heading="Why LEGAL FACTORY ?"/>
      <Content paragraph="LEGAL FACTORY, saves you from going through the long tedious process of delivering and handling every aspect of the registration. We simply do it for you with minimum efforts from your side, so you can focus on your business and leave the legalities for us to handle."/>
      <ul className='list'>
        <li className='bullet'>
          <span>
      <List points="We take care of all the paperwork ensuring an interactive process with the government."/>
      </span>
      </li>
      <li className='bullet'>
          <span>
      <List points="100% transparency on the incorporation process to give you a realistic expectations."/>
      </span>
      </li>
      <li className='bullet'>
          <span>
      <List points="We connect you with reliable professionals, with whom you can work to fulfill all your legal requirements."/>
      </span>
      </li>
      <li className='bullet'>
          <span>
      <List points="With an every growing family of experienced business advisors, we offer 24/7 support."/>
      </span>
      </li>
      <li className='bullet'>
          <span>
      <List points="100% Privacy Guaranteed. All the confidential data or personal data of our customers is strictly confined and not accessible without permission."/>
      </span>
      </li>
      </ul>
     
    

    </div>
  )
}

export default Legalfactory