import React from "react";
import {
  Typography,
  styled,
  Grid,
  Box,
  Container,
  useTheme,
  useMediaQuery,
  Button,
} from "@mui/material";
import DivGap from "../../../Components/CommonCss/Home/DivGap";
import Group from "../../../Assets/Home/Aboutwork/LegalFactory_Home5.png";
import EastIcon from "@mui/icons-material/East";
import { useNavigate } from "react-router-dom";
import Heading from "../../../Components/CommonCss/Home/Heading";
import Content from "../../../Components/CommonCss/Home/Content";

const Aboutwork = () => {
  const navigate = useNavigate();
  const clickAbout = () => {
    navigate("/about");
  };

  const Image = styled(Box)(({ theme }) => ({
    [theme.breakpoints.up("lg")]: {
      maxWidth: "80%",
      marginTop: "20px",
    },

    [theme.breakpoints.between("md", "lg")]: {
      width: "25.5rem",
      height: "25rem",
    },
    [theme.breakpoints.down("md")]: {
      width: "23.5rem",
      height: "25rem",
    },
    [theme.breakpoints.down("sm")]: {
      width: "22rem",
      height: "20rem",
    },
  }));

  const Position = styled(Box)(({ theme }) => ({
    [theme.breakpoints.up("md")]: {
      textAlign: "left",
    },
    [theme.breakpoints.down("lg")]: {
      textAlign: "center",
    },
  }));

  const Background = styled(Box)(({ theme }) => ({
    backgroundColor: "rgb(242, 242, 242)",
    width: "100%",
    height: "100%",
  }));

  const ReadMore = styled(Button)(({ theme }) => ({
    background: "white",
    color: "#F19E1E",
    border: "1px solid #F19E1E",
    borderRadius: 5,
    fontWeight: "bold",
    fontSize: "1.2rem",
    marginTop: "10px",
    paddingLeft: "25px",
    paddingRight: "25px",
    "&:hover": {
      background: "white",
      color: "#F19E1E",
    },
    [theme.breakpoints.between("md", "lg")]: {
      fontSize: "1.4rem",
      marginTop: "10px",
    },

    [theme.breakpoints.up("xl")]: {
      fontSize: "1.5rem",
    },
    [theme.breakpoints.down("md")]: {
      fontSize: "1.2rem",
      paddingLeft: "20px",
      paddingRight: "20px",
    },
    [theme.breakpoints.down("sm")]: {
      fontSize: "0.8rem",
      paddingLeft: "10px",
      paddingRight: "10px",
    },
  }));

  return (
    <>
      <Background>
        <div className="Container">
          <br />
          <Container>
            <Grid
              container
              spacing={2}
              direction={{ xs: "column-reverse", lg: "row" }}
            >
              <Grid item xs={10} sm={12} md={12} lg={6}>
                <Heading mainhead="About Us & Our Work" />
                <Content
                  paragraph={[
                    <Box key={1} sx={{ mx: 2 }}>
                      LEGAL FACTORY - Is backed by a team of young and
                      enthusiastic professionals mostly Chartered Accountant's,
                      Company Secretaries and Advocates. and promises to be a
                      personal CFO and TAX ADVISOR to help taxpayers and
                      entrepreneurs start, run and grow their businesses. We aim
                      to build a financial ecosystem focused on providing
                      end-to-end support for every business and individual. We
                      have expertise in the financial market in the areas of
                      Business setup, Compliance, taxation laws, Internal Audit,
                      Anti Money Laundering intellectual property rights (IPR),
                      trademarks, and start-up registration and
                      fundraising.LEGAL FACTORY also aims a providing
                      knowledgeable and updated FINANCE LITERATURE CUM ARTICLES
                      and VIDEOS
                    </Box>,
                  ]}
                />{" "}
                <Position>
                  <ReadMore
                    endIcon={
                      <EastIcon
                        style={{ color: "#F19E1E", fontSize: "40px" }}
                      />
                    }
                    variant="outlined"
                    onClick={clickAbout}
                    sx={{ mx: 2 }}
                  >
                    Read More
                  </ReadMore>
                </Position>
              </Grid>

              <Grid item xs={10} sm={12} md={12} lg={6} xl={6}>
                <Position>
                  <Image
                    component="img"
                    sx={{
                      content: {
                        xs: `url(${Group})`,
                      },
                    }}
                  ></Image>
                </Position>
              </Grid>
            </Grid>
          </Container>
        </div>
        <DivGap />
      </Background>
    </>
  );
};

export default Aboutwork;
