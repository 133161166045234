import React from "react";
import { Box } from "@mui/material";
import Mainhead from "../../../Components/Services/MainContent/Mainhead";
import Content from "../../../Components/Services/MainContent/Content";
import ReusableTable from "../../../Components/Income-Tax/ReusableTable";

const ExemptionSections5454B54G = () => {
  const dataSection54 = [
    {
      slNo: 1,
      condition: "Allowability",
      section54:
        "Exemption is granted if the assessee has long-term capital gains on the sale of a residential property.",
    },
    { slNo: 2, condition: "Allowed To", section54: "Individual/HUF" },
    {
      slNo: 3,
      condition: "Conditions to be Satisfied",
      section54:
        "a.) The Assessee must have purchased one residential house in India either one year before or two years after the transfer date. OR The Assessee must build one residential house in India within three years of the transfer date. b.) The Assesee Should either Purchase or Construct only one House within the specified time period. c.) The house purchased or built should not be transferred for at least three years.",
    },
    {
      slNo: 4,
      condition: "Amount of Exemption",
      section54:
        "1. Long-Term Capital Gain Amount; or 2. The amount spent on the acquisition or construction of a residential house. Whichever is smaller.",
    },
    {
      slNo: 5,
      condition: "Consequences",
      section54:
        "If the assessee violates the conditions stated above, the exemption previously granted will be withdrawn in a special manner, i.e. the cost of acquisition will be reduced by the amount of exemption previously granted when computing capital gains.",
    },
    {
      slNo: 6,
      condition: "UPDATE IN THE BUDGET",
      section54:
        "According to the most recent amendment to the Budget 2023, the long-term capital gain exemption will be capped at 10 crores on the sale of residential property AND the sale amount invested in new residential property.",
    },
  ];
  const dataSection54B = [
    {
      slNo: 1,
      condition: "Allowability",
      section54B:
        "Exemption is granted if the assessee has Capital Gains from the sale of agricultural land.",
    },
    { slNo: 2, condition: "Allowed To", section54B: "Individual/HUF" },
    {
      slNo: 3,
      condition: "Conditions to be Satisfied",
      section54B:
        "a.) The Assessee must have purchased one or more agricultural land within two years of the transfer date. b.) At the time of sale, the Assesee or his parents or HUF must have been using the transferred agricultural land for at least two years. c.) The purchased land should not be transferred for at least three years.",
    },
    {
      slNo: 4,
      condition: "Amount of Exemption",
      section54B:
        "Capital gain will be exempt to the extent it is invested for acquiring the new agricultural land.",
    },
    {
      slNo: 5,
      condition: "Consequences",
      section54B:
        "If the assessee violates the conditions stated above, the exemption previously granted will be withdrawn in a special manner, i.e. the cost of acquisition will be reduced by the amount of exemption previously granted when computing capital gains.",
    },
  ];

  const dataSection54G = [
    {
      slNo: 1,
      condition: "Allowability",
      section54G:
        "Exemption from capital gains tax applied to the transfer of capital assets, such as land, buildings, machinery, plants, and any rights in land or buildings used for commercial purposes in urban industrial undertakings.",
    },
    { slNo: 2, condition: "Allowed To", section54G: "All assessees." },
    {
      slNo: 3,
      condition: "Conditions to be Satisfied",
      section54G:
        "⦁ The transferred capital asset must be either short-term or long-term. ⦁ The land and building, as well as the plant and machinery, should be purchased one year before or three years after the date of transfer. ⦁ Such transfer should be on account of shifting of industrial undertaking from urban area to any area (other than an urban area).",
    },
    {
      slNo: 4,
      condition: "Amount of Exemption",
      section54G:
        "⦁ The amount invested in plant, machinery, land, or buildings to relocate an industrial enterprise from an urban to a rural area. OR ⦁ The amount of capital gain Whichever is lower.",
    },
    {
      slNo: 5,
      condition: "Consequences",
      section54G:
        "If the assessee violates the conditions stated above, the earlier granted exemption shall be withdrawn in a special manner, namely, the cost of the new asset shall be reduced by the amount of capital gain exempted earlier while calculating capital gain in the year of transfer.",
    },
  ];

  return (
    <Box
      style={{
        display: "flex",
        flexDirection: "column",
        gap: 4,
        marginBottom: "2rem",
      }}
    >
      {" "}
      <Mainhead heading="EXEMPTION UNDER SECTIONS 54, 54B, AND 54G" />
      <Content paragraph="IS THERE ANY BENEFIT IF THE ASSET IS LONG TERM CAPITAL ASSET?" />
      <Content paragraph="The main advantage of classifying an item as a long-term capital asset is that the assessee is qualified for indexation benefits. Furthermore, many exemptions are only available for long-term capital assets." />
      <Mainhead heading="SECTION 54 EXEMPTION FOR CAPITAL GAINS RESULTING FROM TRANSFER OF RESIDENTIAL HOUSE PROPERTY" />
      {/* Table for Section 54 */}
      <ReusableTable
        columns={[
          { label: "Sl. No.", key: "slNo" },
          { label: "Condition", key: "condition" },
          { label: "Section 54", key: "section54" },
        ]}
        data={dataSection54}
      />
      {/* Section 54B Content */}
      <Mainhead heading="EXEMPTION FROM CAPITAL GAINS ON AGRICULTURAL LAND TRANSFER UNDER SECTION 54B" />
      {/* Table for Section 54B */}
      <ReusableTable
        columns={[
          { label: "Sl. No.", key: "slNo" },
          { label: "Condition", key: "condition" },
          { label: "Section 54B", key: "section54B" },
        ]}
        data={dataSection54B}
      />
      {/* Section 54G Content */}
      <Mainhead heading="CAPITAL GAIN ON TRANSFER OF INDUSTRIAL UNDERTAKING FROM URBAN TO NON-URBAN AREAS IS EXEMPT UNDER SECTION 54G" />
      {/* Table for Section 54G */}
      <ReusableTable
        columns={[
          { label: "Sl. No.", key: "slNo" },
          { label: "Condition", key: "condition" },
          { label: "Section 54G", key: "section54G" },
        ]}
        data={dataSection54G}
      />
    </Box>
  );
};

export default ExemptionSections5454B54G;
