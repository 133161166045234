import React from 'react'
import Mainhead from '../../../../Components/Services/MainContent/Mainhead';
import Content from '../../../../Components/Services/MainContent/Content';
import List from '../../../../Components/Services/MainContent/List';
import { Box , styled} from '@mui/material';
import nidhiBanner from '../../../../Assets/Services/NidhiCompany/LegalFactory_Nidhi2.png';

const Tabletimg = styled(Box)(({ theme }) => ({
  width:'42rem',
  height:'10rem',
  marginTop:'0.8rem',
  [theme.breakpoints.down("sm")]: {
   display:'none'
 },
 [theme.breakpoints.down("md")]: {
   width:'28rem',
 height:'8rem',
 },
 [theme.breakpoints.between("md", "lg")]: {
  width:'30rem'
 },
 [theme.breakpoints.up("xl")]: {
   width:'48rem'
  }
}));

function Overview(){
    return(
        <div>
            <Mainhead heading="What is Nidhi Company" />
            <Content paragraph= "A Nidhi Company or a Mutual Benefit Finance Company is an NBFC(a Non-Banking Financing Company) which was registered under the Section 406 of Companies Act, 2013 for the sake of cheering the shareholders to invest within the company i.e. its a blessing in disguise for those who aspire to launch into loan &/or finance business wherein companies acquire from the members and confer to their members. And hence Nidhi Companies are widely known as Nidhi Banks. Regulated by the Ministry of Corporate Affairs, while the RBI monitors all its financial dealings."
             />
            <Content paragraph= "Nidhi Company activities fall under the ambit of RBI. In this category of NBFC, Directors are free from the shackles of eligibility norms of least qualification. A minimum of 7 members are required to start a Nidhi Company, out of which 3 must be the Directors of the company. It is mandatory/ binding to all Nidhi Companies to put Nidhi Ltd. at the end of their names at any cost. It is registered as a public company and should have “Nidhi Limited” as the last words of its name."
            />

            <Tabletimg component="img"
                sx={{
                  content: {
                    xs: `url(${nidhiBanner})`
                  }
                }}/>

        </div>
    )
}

export default Overview