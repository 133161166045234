import React from 'react';
import { Typography,styled, useTheme } from '@mui/material';


const Content = styled(Typography)(({ theme }) => ({
    color: 'Black',
    fontSize: '0.8rem',
    fontWeight: 400,
    textAlign: 'left',
    [theme.breakpoints.up("md")]: {
        fontSize: '1rem',
        lineHeight: '1.8rem'
    }
}));


function A(props) {
    return (
      <div>
          <Content>
            {props.A}
          </Content>
      </div>
    )
  }
  
  export default A;