import React from "react";
import { Box } from "@mui/material";
import Mainhead from "../../../Components/Services/MainContent/Mainhead";
import Content from "../../../Components/Services/MainContent/Content";
import CustomList from "../../../Components/Income-Tax/CustomList";
import Step from "../../../Components/Services/MainContent/Step";

const Section56_2Taxation = () => {
  return (
    <Box
      style={{
        display: "flex",
        flexDirection: "column",
        gap: 4,
        marginBottom: "2rem",
      }}
    >
      <Mainhead heading="Section 56(2) of Income Tax Act, 1961" />

      <Step
        number={`What does Relative under Section 56(2) of Income Tax Act in India Means?`}
      />
      <Content paragraph="Gift received from a relative is not taxable in the hands of the recipient under section 56 of the Income Tax Act. The persons who are considered as relatives are:" />
      <CustomList
        points={[
          "Spouse of the individual",
          "Brother or sister of the individual",
          "Brother or sister of the spouse of the individual",
          "Brother or sister of either of the parents of the individual",
          "Any lineal ascendant or descendant of the individual",
          "Any lineal ascendant or descendant of the spouse of the individual",
          "Spouse of the person referred to in the above points",
        ]}
      />
      <Content paragraph="In the case of HUF – Any member of the HUF. Based on the above points, there may be 51 relations from which a gift is not taxable in the hands of the individual." />

      <Step number={`Taxability of various situations in gifts?`} />
      <CustomList
        points={[
          "Gifts: Any sum of money or any property (movable or immovable) received without consideration, the aggregate value of which exceeds Rs. 50,000 in a financial year, is considered as income from other sources. However, gifts from specified relatives, on the occasion of marriage, or under a will or inheritance are exempt from tax.",
          "Immovable Property Received for Inadequate Consideration: If an individual receives immovable property for a consideration less than its stamp duty value by an amount exceeding Rs. 50,000, the difference is treated as income from other sources.",
          "Movable Property Received for Inadequate Consideration: Similar to immovable property, if an individual receives any property (other than immovable property) for a consideration less than its fair market value by an amount exceeding Rs. 50,000, the difference is treated as income from other sources.",
          "Exception for Business Transactions: The provisions of Section 56(2) do not apply to any sum of money or property received by an individual from a relative or on the occasion of the individual's marriage. Additionally, any sum of money or property received in the course of business or profession (including stock-in-trade) is not covered under this section.",
          "Exception for Certain Entities: Section 56(2) is not applicable to specified entities like companies, partnership firms, etc., for certain transactions, including shares received by a company from its subsidiary, etc.",
          "Fair Market Value and Stamp Duty Value: The fair market value of movable property and the stamp duty value of immovable property are crucial for determining the deemed income under Section 56(2).",
          "Valuation Date: The valuation date for determining the fair market value or stamp duty value is the date on which the property is transferred.",
          "Tax Treatment: The deemed income under Section 56(2) is taxed at the individual's applicable income tax slab rates.",
        ]}
      />
    </Box>
  );
};

export default Section56_2Taxation;
