import React from "react";
import Box from "@mui/material/Box";
import Fab from "@mui/material/Fab";
import { Typography, Button, useTheme, useMediaQuery } from "@mui/material";
import CallIcon from "@mui/icons-material/Call";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Slide from "@mui/material/Slide";
import { InputAdornment, styled, TextField } from "@mui/material";
import PersonOutlineIcon from "@mui/icons-material/PersonOutline";
import MailOutlineIcon from "@mui/icons-material/MailOutline";
import LocalPhoneIcon from "@mui/icons-material/LocalPhone";
import LocationCityIcon from "@mui/icons-material/LocationCity";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useDispatch } from "react-redux";
import { submitDetailsHome } from "../../Action/GetInTouch";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const Formfield = styled(TextField)(({ theme }) => ({
  width: "90%",
  marginLeft: "0.6rem",
  marginTop: "0.8rem",
}));
const Orangebutton = styled(Button)(({ theme }) => ({
  background: "#E99101",
  color: "#FDFDFD",
  marginTop: "0.8rem",
  marginRight: "1rem",
  marginBottom: "0.8rem",
  "&:hover": {
    background: "#E99101",
    color: "#FDFDFD",
  },
  [theme.breakpoints.up("xl")]: {
    paddingLeft: "1rem",
    paddingRight: "1rem",
  },
}));

const Bluebutton = styled(Button)(({ theme }) => ({
  background: "#0085FF",
  color: "#FDFDFD",
  //marginLeft:'0.2rem',
  marginTop: "0.8rem",
  marginBottom: "0.8rem",
  marginRight: "1.8rem",
  "&:hover": {
    background: "#0085FF",
    color: "#FDFDFD",
  },
  [theme.breakpoints.up("xl")]: {
    paddingLeft: "1rem",
    paddingRight: "1rem",
    marginLeft: "1rem",
  },
}));

const Floatingicon = (props) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const isTablet = useMediaQuery(theme.breakpoints.down("md"));

  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const [checked, setChecked] = React.useState(false);

  const dispatch = useDispatch();
  const handleClose = () => {
    setOpen(false);
  };

  const handleChange = (event) => {
    setChecked(event.target.checked);
  };

  const phoneRegExp =
    /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;

  const validate = Yup.object().shape({
    name: Yup.string()
      .min(2, "Too Short!")
      .max(20, "Too Long!")
      .required("Required"),
    email: Yup.string().email("Invalid email").required("Required"),
    city: Yup.string()
      .min(2, "Too Short!")
      .max(20, "Too Long!")
      .required("Required"),
    phoneNumber: Yup.string()
      .matches(phoneRegExp, "Phone Number is not valid")
      .min(5, "Too Short!")
      .max(10, "Too Long!")
      .required("Required"),
  });

  const formik = useFormik({
    initialValues: {
      name: "",
      email: "",
      city: "",
      phoneNumber: "",
    },
    validationSchema: validate,
    onSubmit: (values, onSubmitProps) => {
      dispatch(
        submitDetailsHome({
          Name: values.name,
          Email: values.email,
          City: values.city,
          Phone: values.phoneNumber,
          Query: "",
          AgentCode: 0,
          GST: 0,
          EnquiryDate: new Date(),
          InvoiceNumber: 0,
          Invoice: "",
          LastModified: "",
          OTP: 0,
          OTPGenerated: "",
          OTPVerified: false,
          Password: "",
          Payment: 0,
          PaymentLink: "",
          PaymentMode: "",
          ProfilePic: "",
          Service: props.service,
          Source: "Web",
          FormJSON: {
            name: "nil",
            gmail: "nil",
          },
          Status: "In Progress",
          Type: "Lead",
          createdAt: new Date(),
        })
      );
      setOpen(true);
      onSubmitProps.resetForm();
    },
  });

  return (
    <div>
      <Fab
        variant="extended"
        sx={{ position: "fixed", bottom: "1rem", right: "1rem" }}
        onClick={handleClickOpen}
      >
        <CallIcon sx={{ mr: 1 }} />
        <Typography
          fontWeight={700}
          sx={isMobile || isTablet ? { display: "none" } : {}}
        >
          Get In Touch
        </Typography>
      </Fab>
      <Dialog
        open={open}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClose}
        aria-describedby="alert-dialog-slide-description"
        PaperProps={{
          style: {
            borderRadius: 20,
            width: "480px",
            border: "4px solid #4a2902",
          },
        }}
      >
        <form onSubmit={formik.handleSubmit}>
          <DialogTitle
            sx={{ fontWeight: "700", textAlign: "center", color: "#F19E1E" }}
          >
            Get In Touch
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-slide-description">
              <Formfield
                variant="outlined"
                placeholder="Name"
                size="Medium"
                margin="dense"
                id="name"
                name="name"
                value={formik.values.name}
                onChange={formik.handleChange}
                error={formik.touched.name && Boolean(formik.errors.name)}
                helperText={formik.touched.name && formik.errors.name}
                InputProps={{
                  disableUnderline: true,
                  startAdornment: (
                    <InputAdornment position="start">
                      <PersonOutlineIcon
                        fontSize="medium"
                        sx={{
                          color: "#AEA598",
                        }}
                      />
                    </InputAdornment>
                  ),
                }}
              />
              <Formfield
                variant="outlined"
                placeholder="E-mail"
                size="Medium"
                margin="dense"
                id="email"
                name="email"
                value={formik.values.email}
                onChange={formik.handleChange}
                error={formik.touched.email && Boolean(formik.errors.email)}
                helperText={formik.touched.email && formik.errors.email}
                InputProps={{
                  disableUnderline: true,
                  startAdornment: (
                    <InputAdornment position="start">
                      <MailOutlineIcon
                        fontSize="medium"
                        sx={{
                          color: "#AEA598",
                        }}
                      />
                    </InputAdornment>
                  ),
                }}
              />

              <Formfield
                variant="outlined"
                placeholder="Phone"
                size="Medium"
                margin="dense"
                id="phoneNumber"
                name="phoneNumber"
                value={formik.values.phoneNumber}
                onChange={formik.handleChange}
                error={
                  formik.touched.phoneNumber &&
                  Boolean(formik.errors.phoneNumber)
                }
                helperText={
                  formik.touched.phoneNumber && formik.errors.phoneNumber
                }
                InputProps={{
                  disableUnderline: true,
                  startAdornment: (
                    <InputAdornment position="start">
                      <LocalPhoneIcon
                        fontSize="medium"
                        sx={{
                          color: "#AEA598",
                        }}
                      />
                    </InputAdornment>
                  ),
                }}
                inputProps={{ inputMode: "numeric", pattern: "[0-9]*" }}
              />

              <Formfield
                variant="outlined"
                placeholder="City"
                type="text"
                size="medium"
                margin="dense"
                id="city"
                name="city"
                value={formik.values.city}
                onChange={formik.handleChange}
                error={formik.touched.city && Boolean(formik.errors.city)}
                helperText={formik.touched.city && formik.errors.city}
                InputProps={{
                  disableUnderline: true,
                  startAdornment: (
                    <InputAdornment position="start">
                      <LocationCityIcon
                        fontSize="small"
                        sx={{
                          color: "#AEA598",
                        }}
                      />
                    </InputAdornment>
                  ),
                }}
              />
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Box sx={{ alignItems: "center", display: "flex" }}>
              <Orangebutton variant="contained" size="medium" type="submit">
                Submit
              </Orangebutton>
              <Bluebutton variant="contained" size="medium">
                Talk to Advisor
              </Bluebutton>
            </Box>
          </DialogActions>
        </form>
      </Dialog>
    </div>
  );
};

export default Floatingicon;
