import React from 'react';
import Mainhead from '../../../../Components/Services/MainContent/Mainhead';
import Content from '../../../../Components/Services/MainContent/Content';
import List from '../../../../Components/Services/MainContent/List';
import Step from '../../../../Components/Services/MainContent/Step';

function Checklist() {
  return (
    <div>
        <Mainhead heading="Checklist For Renewal Of A Trademark
"/>
<ul className='list'>
    <li className='bullet'><span><Content paragraph="A duplicate of the registration document"/></span></li>
    <li className='bullet'><span><Content paragraph="the intent for commercial usage should be examined
"/></span></li>
    <li className='bullet'><span><Content paragraph="Make sure there are no trademarks that are similar by performing a complete research of the renowned and licenced trademark database
"/></span></li>
    <li className='bullet'><span><Content paragraph="A legal opinion should be sought if there is a trademark conflict in order to find a solution
"/></span></li>
    <li className='bullet'><span><Content paragraph="Obtain and prepare a renewal application, and then adhere to the necessary conditions.
"/></span></li>
</ul>
    </div>
  )
}

export default Checklist