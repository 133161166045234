import React from "react";
import { Box } from "@mui/material";
import Mainhead from "../../../Components/Services/MainContent/Mainhead";
import Content from "../../../Components/Services/MainContent/Content";
import CustomList from "../../../Components/Income-Tax/CustomList";
import Step from "../../../Components/Services/MainContent/Step";
import ReusableTable from "../../../Components/Income-Tax/ReusableTable";

const MutualFundTaxation = () => {
  const mutualFundTableData = [
    {
      fund: "Equity funds",
      shortTermCapitalGain: "<12 months",
      longTermCapitalGain: ">12 months",
    },
    {
      fund: "Debt funds (after 31st March 2023)",
      shortTermCapitalGain: "Always short-term",
      longTermCapitalGain: "Always short-term",
    },
    {
      fund: "Hybrid equity - oriented fund",
      shortTermCapitalGain: "<12 months",
      longTermCapitalGain: ">12 months",
    },
    {
      fund: "Hybrid debt - oriented fund (after 31st March 2023)",
      shortTermCapitalGain: "Always short-term",
      longTermCapitalGain: "Always short-term",
    },
  ];

  return (
    <Box
      style={{
        display: "flex",
        flexDirection: "column",
        gap: 4,
        marginBottom: "2rem",
      }}
    >
      {" "}
      <Mainhead heading="Taxes on Equity & Debt Mutual Funds" />
      <Step
        number={`
            Taxation of Equity Mutual Funds
          `}
      />
      <Content paragraph="Mutual funds classified as equity funds have more than 65% of their total fund value invested in equity shares of companies." />
      <Step
        number={`
            Short-Term Capital Gains (STCG) on Equity Mutual Funds
          `}
      />
      <CustomList
        points={[
          "Holding period less than one year results in short-term capital gains.",
          "Taxed at a flat rate of 15%.",
        ]}
      />
      <Step
        number={`
            Long-Term Capital Gains (LTCG) on Equity Mutual Funds
          `}
      />
      <CustomList
        points={[
          "Holding period one year or more results in long-term capital gains.",
          "Taxed at 10% on gains exceeding Rs 1 lakh.",
        ]}
      />
      <Step
        number={`
            Tax Deduction at Source (TDS) for Equity Mutual Funds
          `}
      />
      <Content paragraph="TDS is not applicable on equity mutual funds." />
      <Step
        number={`
            Taxation of Debt Mutual Funds
          `}
      />
      <Content paragraph="Mutual funds classified as debt funds have a portfolio with an excess of 65% debt and no more than 35% equity." />
      <Step
        number={`
            Short-Term Capital Gains (STCG) on Debt Mutual Funds
          `}
      />
      <CustomList
        points={[
          "Holding period less than three years results in short-term capital gains.",
          "Taxed as per the investor's applicable income tax slab.",
        ]}
      />
      <Step
        number={`
            Long-Term Capital Gains (LTCG) on Debt Mutual Funds
          `}
      />
      <CustomList
        points={[
          "Holding period three years or more results in long-term capital gains.",
          "Taxed at a rate of 20% after applying indexation benefits.",
        ]}
      />
      <Step
        number={`
            Tax Deduction at Source (TDS) for Debt Mutual Funds
          `}
      />
      <Content paragraph="TDS is applicable on gains from debt mutual funds, primarily on the interest income. The rate may vary based on the investor's PAN and other details." />
      <ReusableTable data={mutualFundTableData} />
      <Step
        number={`
            Dividends from Equity Mutual Funds
          `}
      />
      <Content paragraph="DDT on equity-oriented mutual funds was abolished from April 1, 2020. Dividends received by investors became taxable in their hands as per their applicable income tax slabs." />
      <Step
        number={`
            Dividends from Debt Mutual Funds
          `}
      />
      <Content paragraph="DDT on debt mutual funds was abolished from April 1, 2020. Dividends received from debt funds became taxable in the hands of investors as per their applicable income tax slabs." />
      <Step
        number={`
            Security Transaction Tax (STT)
          `}
      />
      <Content paragraph="STT is levied on the purchase or sale of securities such as stocks and bonds. A securities transaction tax of 0.001% is imposed by the government." />
      <CustomList
        points={[
          "Revenue Generation: Governments use STT as a source of revenue.",
          "Market Regulation: STT is used to regulate financial markets by discouraging excessive and speculative trading.",
        ]}
      />
      <Step
        number={`
            Latest Updates from Budget 2023
          `}
      />
      <Content paragraph="Long-term capital gains on a specified mutual fund with less than 35% allocation to equity shares will have no indexation benefit. Mutual funds with debt will be subject to taxes based on relevant slab rates." />
    </Box>
  );
};

export default MutualFundTaxation;
