import React from "react";
import { Box } from "@mui/material";
import Mainhead from "../../../Components/Services/MainContent/Mainhead";
import Content from "../../../Components/Services/MainContent/Content";
import CustomList from "../../../Components/Income-Tax/CustomList";
import Step from "../../../Components/Services/MainContent/Step";
import ReusableTable from "../../../Components/Income-Tax/ReusableTable";

const InterestOnSecurities = () => {
  const securitiesTableData = [
    {
      Type: "Equity Securities",
      Description: "Common Stocks",
      TaxRate: "Varies",
    },
    {
      Type: "Equity Securities",
      Description: "Preferred Stocks",
      TaxRate: "Varies",
    },
    { Type: "Debt Securities", Description: "Bonds", TaxRate: "Varies" },
    {
      Type: "Debt Securities",
      Description: "Treasury Bills (T-Bills)",
      TaxRate: "Varies",
    },
    {
      Type: "Derivative Securities",
      Description: "Options",
      TaxRate: "Varies",
    },
    {
      Type: "Derivative Securities",
      Description: "Futures",
      TaxRate: "Varies",
    },
    {
      Type: "Hybrid Securities",
      Description: "Convertible Bonds",
      TaxRate: "Varies",
    },
    {
      Type: "Hybrid Securities",
      Description: "Preference Shares with Convertible Features",
      TaxRate: "Varies",
    },
    {
      Type: "Asset-backed Securities",
      Description: "Mortgage-backed Securities (MBS)",
      TaxRate: "Varies",
    },
    {
      Type: "Commodity Securities",
      Description: "Commodity Futures",
      TaxRate: "Varies",
    },
    {
      Type: "Foreign Exchange Securities",
      Description: "Currency Swaps",
      TaxRate: "Varies",
    },
  ];

  const taxComputationTableData = [
    { Particulars: "Salary income", Rs: "6,00,000" },
    { Particulars: "Interest on Securities", Rs: "50,000" },
    { Particulars: "Gross Total Income", Rs: "6,50,000" },
    { Particulars: "Less: Deduction", Rs: "Nil" },
    { Particulars: "Taxable Income", Rs: "6,50,000" },
    { Particulars: "Computation of Tax", Rs: "" },
    { Particulars: "Up to 2,50,000 @ nil", Rs: "Nil" },
    { Particulars: "2,50,000-5,00,000 @ 5%", Rs: "12,500" },
    { Particulars: "5,00,000-10,00,000 @ 20%", Rs: "60,000" },
    { Particulars: "Above 10,00,000 @ 30%", Rs: "18,000" },
    { Particulars: "Tax on total income", Rs: "90,500" },
    { Particulars: "Add: Health and Education cess @ 4%", Rs: "3,620" },
    { Particulars: "Total tax liability for the year", Rs: "94,120" },
  ];

  return (
    <Box
      style={{
        display: "flex",
        flexDirection: "column",
        gap: 4,
        marginBottom: "2rem",
      }}
    >
      <Mainhead heading="Understanding Section 56(2)(id) - Interest on Securities" />
      <Step
        number={`
            What does Section 56(2)(id) cover?
          `}
      />
      <Content paragraph="Section 56(2)(id) of the Income Tax Act deals with the taxation of income earned through 'Interest on Securities.' This includes interest received from various forms of securities such as government bonds, debentures, fixed-income securities, etc." />
      <Step
        number={`
            Example Illustrating Section 56(2)(id):
          `}
      />
      <Content paragraph="Let's consider an individual, Mr. A, who invested in government bonds during the financial year 2022-23. Throughout the year, Mr. A received interest payments on these bonds, amounting to Rs. 50,000. According to Section 56(2)(id), this interest income will be taxable in Mr. A's hands." />
      <Step
        number={`
            Calculation of Tax Liability:
          `}
      />
      <Content paragraph="For the assessment year 2023-24, Mr. A needs to include this interest income of Rs. 50,000 while filing his income tax return. The income tax liability will be calculated based on Mr. A's total taxable income, which includes this interest earned from securities." />
      <ReusableTable data={taxComputationTableData} />
      <Step
        number={`
            Taxation Rates and Implications on Interest on Securities
          `}
      />
      <Content paragraph="The taxation rates for interest on securities might vary depending on the individual's tax slab and the nature of securities. Generally, this income is added to the total income and taxed at the applicable slab rates as per the Income Tax Act." />
      <Step
        number={`
            Tax Deduction at Source (TDS) on Interest Income:
          `}
      />
      <Content paragraph="It's essential to note that in certain cases, Tax Deduction at Source (TDS) might be applicable on interest income from securities. The payer of such interest, like a bank or financial institution, deducts TDS at a specified rate before crediting the interest amount to the recipient." />
      <Step
        number={`
            What are the Types of Securities?
          `}
      />
      <CustomList
        points={[
          "Equity Securities: Common Stocks, Preferred Stocks",
          "Debt Securities: Bonds, Treasury Bills (T-Bills)",
          "Derivative Securities: Options, Futures",
          "Hybrid Securities: Convertible Bonds, Preference Shares with Convertible Features",
          "Asset-backed Securities: Mortgage-backed Securities (MBS)",
          "Commodity Securities: Commodity Futures",
          "Foreign Exchange Securities: Currency Swaps",
        ]}
      />
      <ReusableTable data={securitiesTableData} />
      <Step
        number={`
            These types of securities have varying levels of risk, returns, and terms associated with them. Investors choose securities based on their investment objectives, risk tolerance, and market conditions. Understanding these different types of securities is crucial for making informed investment decisions.
          `}
      />
      <Step
        number={`
            Chargeability of Interest on Securities:
          `}
      />
      <Content paragraph="The chargeability of interest on securities refers to the taxation of income earned through interest from various types of securities. In the context of the Indian Income Tax Act, the chargeability of interest on securities is governed by Section 56(2)(id)." />
      <Step
        number={`
            Grossing up of Interest on Securities:
          `}
      />
      <Content paragraph="Grossing up of interest on securities refers to the process of adjusting the interest income received from certain securities to account for the taxes that have been deducted at source (TDS) by the payer before the interest is credited to the recipient." />
      <Step
        number={`
            Grossing up of Interest on Securities (Continued):
          `}
      />
      <Content paragraph="In some cases, when interest income is subject to TDS, the recipient receives the interest after a certain percentage of tax has been deducted at the source by the payer (like a bank or financial institution). To calculate the gross amount or the original amount of interest income before the deduction of taxes, the concept of grossing up is applied." />
      <Content paragraph="The formula for grossing up interest income is as follows:" />
      <Content paragraph="Gross Interest Income = Net Interest Income / (1 - Tax Rate)" />
      <Content paragraph="Where:" />
      <CustomList
        points={[
          "Gross Interest Income is the original or gross amount of interest income.",
          "Net Interest Income is the amount received after the deduction of taxes.",
          "Tax Rate is the rate at which the tax was deducted at source.",
        ]}
      />
      <Content
        paragraph={`For example, let's consider a scenario where an individual receives an interest income of Rs. 10,000 from securities, but the bank deducts TDS at a rate of 10% before crediting the interest. The formula for grossing up can be applied:`}
      />
      <Content paragraph={`Given:`} />
      <CustomList
        points={[
          "Net Interest Income = Rs. 10,000 (amount received after TDS)",
          "Tax Rate = 10% (TDS deducted at this rate)",
        ]}
      />
      <Content paragraph={`Using the formula:`} />
      <Content paragraph={`Gross Interest Income = 10,000 / (1 - 0.10)`} />
      <Content paragraph={`Gross Interest Income = 10,000 / 0.90`} />
      <Content paragraph={`Gross Interest Income = Rs. 11,111.11`} />
      <Content
        paragraph={`Therefore, the grossed-up interest income before the deduction of taxes would be approximately Rs. 11,111.11.`}
      />
      <Content
        paragraph={`Grossing up of interest on securities helps in determining the original amount of interest income before the deduction of taxes, especially for tax reporting and calculation purposes. It ensures that the taxpayer properly accounts for the gross income earned, including the taxes that were withheld at the source.`}
      />

      <Step
        number={`
            Deductions under Section 57(1)(iii):
          `}
      />
      <Content
        paragraph={`Under the Income Tax Act of India, Section 57(1) covers deductions that can be claimed against income earned under the head "Income from Other Sources," which includes income from interest on securities. Section 57(1)(iii) specifies the deductions allowable against such income.`}
      />
      <Content
        paragraph={`Here's an overview of deductions allowed under Section 57(1)(iii) for expenses incurred against income from interest on securities:`}
      />

      <CustomList
        points={[
          "Deductions for Expenses: (i) This section allows for deductions for expenses that are directly related to earning income under the head 'Income from Other Sources,' specifically income from interest on securities. (ii) This section allows for deductions for expenses that are directly related to earning income under the head 'Income from Other Sources,' specifically income from interest on securities. (iii) It permits the deduction of any expenses that are wholly and exclusively incurred for the purpose of earning such income.",
          "Nature of Allowable Expenses: Allowable expenses might include administrative expenses, service charges, commission, fees paid to agents or brokers for managing the securities, or any other costs directly related to managing or receiving the interest income.",
          "Conditions for Deductions: The expenses claimed must be directly linked to earning the interest income from securities. They should be supported by proper documentation and should genuinely be incurred for earning such income.",
          "Calculation of Taxable Income: The deductible expenses are subtracted from the gross income earned from interest on securities to arrive at the net income, which is then added to the total income for taxation purposes.",
          "Compliance and Documentation: Taxpayers claiming deductions under Section 57(1)(iii) should maintain proper records and documentation to substantiate the expenses incurred. This is crucial in case of any scrutiny by tax authorities.",
        ]}
      />
      <Content
        paragraph={`It's important to note that while Section 57(1)(iii) allows for deductions against income from interest on securities for expenses directly related to earning such income, the deductions claimed should comply with the provisions of the Income Tax Act and be supported by appropriate documentation and evidence.`}
      />
    </Box>
  );
};

export default InterestOnSecurities;
