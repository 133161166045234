import { TableBody, createTheme, } from "@mui/material";




export const theme = createTheme({
    palette: {
        primary: {
            main: "#F19E1E", 
        },
        secondary: {
            main: "#4a2902", 
        },
        otherColor: {
            main: "#ffffff",
        }
    },


typography:{
    allVariants:{
        fontFamily:'Sora'
    }
},


components:{
    MuiContainer:{
        styleOverrides:{
            maxWidthLg:{
                maxWidth:'1240px!important',
                
            },
        }
    }
}

});


