import React from "react";
import { Box } from "@mui/material";
import Mainhead from "../../../Components/Services/MainContent/Mainhead";
import Content from "../../../Components/Services/MainContent/Content";
import CustomList from "../../../Components/Income-Tax/CustomList";
import Step from "../../../Components/Services/MainContent/Step";
import ReusableTable from "../../../Components/Income-Tax/ReusableTable";

const freelancingExpenseDeductions = [
  {
    expense: "Home Office Expenses",
    description:
      "Deduction for a dedicated workspace at home used exclusively for freelance work, covering rent, mortgage interest, utilities, property taxes, and home insurance.",
  },
  {
    expense: "Office Supplies and Equipment",
    description:
      "Deduction for office supplies like stationery, printer ink, computers, software, and equipment necessary for freelance work.",
  },
  {
    expense: "Professional Services and Subcontractor Costs",
    description:
      "Deduction for payments made to other professionals, subcontractors, or consultants hired for specific freelance projects.",
  },
  {
    expense: "Advertising and Marketing",
    description:
      "Deduction for costs associated with advertising freelance services, including website maintenance, online ads, business cards, and promotional materials.",
  },
  {
    expense: "Professional Development",
    description:
      "Deduction for expenses related to continuing education, training courses, workshops, conferences, and certifications directly related to freelance work.",
  },
  {
    expense: "Travel Expenses",
    description:
      "Deduction for travel costs incurred for business-related purposes, such as client meetings, conferences, or work-related travel, including transportation, lodging, meals, and related expenses.",
  },
  {
    expense: "Insurance Premiums",
    description:
      "Deduction for premiums paid for professional liability insurance related to freelance work.",
  },
];

const disallowedFreelancingExpenses = [
  {
    expense: "Personal Expenses",
    description:
      "Expenses not directly related to the business, such as personal clothing, personal grooming, and personal entertainment, are generally not deductible.",
  },
  {
    expense: "Capital Expenses",
    description:
      "Costs related to purchasing long-term assets like real estate or equipment that are intended for long-term use and benefit may not be fully deductible in the year of purchase. Instead, they might be subject to depreciation or amortization over several years.",
  },
  {
    expense: "Non-Business or Non-Work-Related Expenses",
    description:
      "Any expenses that are unrelated to the freelancing work, such as personal vacations, hobbies, or personal fines or penalties, are not deductible.",
  },
  {
    expense: "Commuting Expenses",
    description:
      "Generally, expenses for commuting from your home to your regular place of work are not considered deductible. However, travel expenses for business-related trips or client meetings away from your regular work location may be deductible.",
  },
  {
    expense: "Fines and Penalties",
    description:
      "Any fines, penalties, or legal fees incurred due to legal violations or unlawful activities are generally not deductible.",
  },
  {
    expense: "Gifts and Entertainment",
    description:
      "Expenses for lavish or excessive gifts, entertainment, or events that are not directly related to the business might not be deductible or have limited deductibility.",
  },
  {
    expense: "Non-Deductible Taxes",
    description:
      "Taxes that are not related to the operation of the business, such as personal income tax or fines for non-compliance, may not be deductible.",
  },
  {
    expense: "Personal Losses",
    description:
      "Any personal losses, whether from investments, gambling, or personal debts, are generally not deductible against freelance income.",
  },
  {
    expense: "Excessive Personal Portions of Business Expenses",
    description:
      "If an expense has both a business and personal use, only the portion directly related to the business is deductible. For example, if a phone is used for both personal and business purposes, only the business-related portion is deductible.",
  },
];

const booksOfAccounts = [
  "Income Ledger: Record all sources of income, including payments received from clients or customers. Segregate income based on projects, clients, or types of services provided for easy tracking.",
  "Expense Ledger: Keep a detailed record of all business-related expenses. Categorize expenses such as office supplies, equipment, software subscriptions, travel, marketing, utilities, insurance, professional fees, and any other costs related to your freelance work.",
  "Invoices and Receipts: Maintain copies of invoices issued to clients and receipts for business-related expenses. These documents serve as evidence for transactions and are crucial during tax filing or audits.",
  "Bank Statements: Regularly reconcile your business bank account statements with your records to ensure accuracy and identify any discrepancies.",
  "Tax Documentation: Keep track of tax-related documents such as tax returns, statements of income, tax payments made, and any correspondence with tax authorities.",
  "Mileage Log (if applicable): If you use your vehicle for business-related travel, maintain a mileage log detailing business-related trips for potential tax deductions.",
  "Contracts and Agreements: Keep copies of contracts, agreements, or project proposals with clients or service providers. These documents outline project scopes, terms, and payment terms.",
  "Software and Tools: Utilize accounting software or tools specifically designed for freelancers to streamline bookkeeping processes. Software like QuickBooks, FreshBooks, or Xero can help manage finances efficiently.",
  "Separate Business and Personal Finances: Maintain separate bank accounts and credit cards for business and personal use to simplify accounting and avoid commingling funds.",
];

const FreelancerInfo = () => {
  return (
    <Box
      style={{
        display: "flex",
        flexDirection: "column",
        gap: 4,
        marginBottom: "2rem",
      }}
    >
      <Mainhead heading="What Do You Mean by Freelancer?" />
      <Content paragraph="Freelancing refers to working independently on a contractual basis rather than being employed by a single employer. A freelancer, also known as an independent contractor or a self-employed individual, offers services, skills, or expertise to clients or businesses on a project-by-project basis." />

      <Step
        number={`
            What Are the Different Ways Through Which Freelancers Earn Income?
          `}
      />
      <CustomList
        points={[
          "Project-based Payments: Income generated by completing projects or assignments for clients as agreed upon in the contract.",
          "Hourly Rates: Some freelancers charge clients based on the number of hours worked on a project, and they invoice clients accordingly.",
          "Retainers or Long-Term Contracts: Freelancers may have ongoing contracts or retainers with clients, ensuring a steady income over a defined period.",
        ]}
      />

      <Step
        number={`
            What Are the Tax Implications for Freelancers?
          `}
      />
      <Content paragraph="Freelancers are typically considered self-employed individuals, and their income is subject to taxation. They are responsible for paying income tax, and sometimes self-employment tax, depending on the tax laws in their country. Keeping track of income, expenses, and maintaining proper financial records is crucial for accurate tax filing." />
      <Content paragraph="Freelancers may also have to manage their own healthcare, retirement savings, and other benefits that traditional employees might receive from an employer." />

      <Step
        number={`
            Is There Any Expense Which Can Be Allowed as Deduction for Freelancers?
          `}
      />
      <Content paragraph="Freelancers can often deduct certain expenses related to their freelance work when calculating their taxable income. However, the specific deductions available can vary based on the country's tax laws and regulations. Here are common expenses that freelancers might be allowed to deduct:" />
      <ReusableTable data={freelancingExpenseDeductions} />

      <Content paragraph="While many expenses incurred in freelancing are eligible for tax deductions, certain expenses are typically disallowed for tax deduction purposes." />
      <Step
        number={`
            What are the Expenses which will not be allowed to freelancers?
          `}
      />
      <Content paragraph="The disallowed expenses vary depending on tax laws in different jurisdictions, but common examples of expenses that are often not deductible include:" />
      <ReusableTable data={disallowedFreelancingExpenses} />

      <Step
        number={`
            What are the different types of books of accounts to be maintained by Freelancers?
          `}
      />
      <Content paragraph="As a freelancer, maintaining proper books of accounts is essential for managing finances, tracking income and expenses, and facilitating tax compliance. Here's a guide on setting up books of accounts:" />
      <CustomList points={booksOfAccounts} />
      <Step
        number={`
            Do TDS need to be deducted for Freelancers?
          `}
      />
      <Content paragraph="The applicability of Tax Deducted at Source (TDS) for freelancers depends on various factors such as the nature of payments received, the amount of income, and the tax regulations in the country where the freelancer operates. Here are some key points related to TDS applicability for freelancers:" />
      <ReusableTable
        data={[
          {
            point: "Contract Value Threshold:",
            description:
              "In some countries, TDS provisions may apply when the contract or payment exceeds a specified threshold limit. Below this threshold, TDS might not be applicable.",
          },
          {
            point: "Nature of Income:",
            description:
              "TDS is typically applicable to specific types of payments made by businesses or clients to freelancers. Payments such as professional fees, royalties, rent, or contractual payments may be subject to TDS deductions, depending on local tax laws.",
          },
          {
            point: "Client Category:",
            description:
              "If the client is a business entity or company, they might be required to deduct TDS on payments made to freelancers, subject to certain conditions and thresholds specified by tax authorities.",
          },
          {
            point: "Tax Treaties:",
            description:
              "For freelancers operating in countries with tax treaties or agreements between nations, the terms of these treaties might impact the applicability of TDS on cross-border payments.",
          },
          {
            point: "Exemption Certificates:",
            description:
              "Freelancers can apply for Tax Residency Certificates or other exemption certificates to reduce or exempt TDS if they are eligible as per tax laws or bilateral agreements between countries.",
          },
          {
            point: "Tax Compliance:",
            description:
              "Compliance with TDS provisions includes timely filing of TDS returns, issuance of TDS certificates, and adherence to TDS rates specified by tax authorities.",
          },
          {
            point: "Threshold Limits and Rate of TDS:",
            description:
              "Tax laws outline specific thresholds for TDS applicability, and the rates can vary based on the nature of payments, type of services rendered, and local regulations.",
          },
        ]}
      />

      <Content paragraph="Freelancers should be aware of the TDS provisions applicable in their respective countries and understand the specific thresholds, rates, and categories of payments subject to TDS deductions. Maintaining proper documentation, including contracts, invoices, and receipts, is crucial for accurate TDS compliance and tax filings." />
    </Box>
  );
};

export default FreelancerInfo;
