import React from "react";
import MenuIcon from "@mui/icons-material/Menu";
import { Grid, IconButton } from "@mui/material";

const MenuButton = ({ handleOpenNavMenu }) => (
  <Grid>
    <IconButton
      size="large"
      aria-label="account of current user"
      aria-controls="menu-appbar"
      aria-haspopup="true"
      onClick={handleOpenNavMenu}
      color="black"
    >
      <MenuIcon />
    </IconButton>
  </Grid>
);

export default MenuButton;
