import React from "react";
import { Box } from "@mui/material";
import Mainhead from "../../../Components/Services/MainContent/Mainhead";
import Content from "../../../Components/Services/MainContent/Content";
import Step from "../../../Components/Services/MainContent/Step";
import CustomList from "../../../Components/Income-Tax/CustomList";

const SlumpSaleExplanation = () => {
  return (
    <Box
      style={{
        display: "flex",
        flexDirection: "column",
        gap: 4,
        marginBottom: "2rem",
      }}
    >
      {" "}
      <Mainhead title="Slump Sale" />
      <Step number="WHAT DO YOU MEAN BY SLUMP SALE? EXPLAIN WITH THE HELP OF AN EXAMPLE?" />
      <Content paragraph="A slump sale is a type of business deal where a company sells its entire business or a part of it to another company as a single package, without separately stating the values of individual assets and liabilities." />
      <Content paragraph="Imagine a company named XYZ Pvt. Ltd. that manufactures and sells furniture. Now, if XYZ Pvt. Ltd. decides to sell its entire furniture business, including its factory, machinery, inventory, patents, contracts, and even its customer base, to another company called ABC Furniture Corp., this could be done as a slump sale." />
      <CustomList
        points={[
          "Single Transaction: Instead of listing the value of each item (like machines, contracts, etc.), XYZ Pvt. Ltd. sells its entire furniture business as a whole to ABC Furniture Corp. for a lump sum amount.",
          "Assets and Liabilities: ABC Furniture Corp. gets all the assets (like machinery, inventory, patents, etc.) and liabilities (such as loans or debts) related to the furniture business of XYZ Pvt. Ltd.",
          "No Separation of Values: Unlike other sales where individual values of assets and liabilities are determined separately, in a slump sale, no specific values are assigned separately to each item. The entire business is transferred in one go.",
          "Tax Treatment: For the seller (XYZ Pvt. Ltd.), the gains made from this sale may be treated as long-term or short-term capital gains, depending on how long the assets were held by XYZ Pvt. Ltd.",
          "Simplified Transfer: A slump sale offers a simpler way to transfer a business or a part of it, avoiding the need to assign values to each asset and liability separately.",
        ]}
      />
      <Content paragraph="So, a slump sale is like selling your whole collection of things (your business) in a single deal, without pricing each item individually. This method simplifies the transfer process, but it's important for both the seller and buyer to follow legal and tax regulations related to such transactions." />
      <Step number="WHAT ARE THE TAX IMPLICATIONS OF SLUMP SALE ON SELLER?" />
      <Content paragraph="Capital Gains: The gains made by the seller from a slump sale are generally considered as either long-term or short-term capital gains, depending on the holding period of the assets being transferred. If the assets were held for more than a specified period (usually one year), they are categorized as long-term, otherwise, they're short-term." />
      <Content paragraph="Tax Rates: Long-term capital gains are often taxed at a lower rate compared to short-term gains. The tax rate for long-term capital gains can vary based on the nature of the asset being sold and prevailing tax laws." />
      <Content paragraph="Exemptions: There might be certain exemptions available under the tax laws for the seller on the gains arising from a slump sale, subject to meeting specific conditions. For instance, in India, under Section 54GA of the Income Tax Act, exemptions could be available if the gains are invested in certain assets within a specified time frame." />
      <Step number="WHAT ARE THE TAX IMPLICATIONS OF SLUMP SALE ON BUYER?" />
      <Content paragraph="Allocation of Consideration: In a slump sale, the buyer typically acquires the entire business as a going concern for a lump sum consideration. The consideration paid by the buyer is allocated to the various assets and liabilities acquired, forming the basis for the buyer's future financial statements and tax computations." />
      <Content paragraph="Depreciation and Tax Benefits: The buyer can claim depreciation and tax benefits on the assets acquired based on the allocated values. This can help in reducing the taxable income of the buyer in subsequent years." />
      <Step number="WHAT IS THE PERIOD FOR LONG TERM/SHORT TERM FOR SLUMP SALE?" />
      <Content paragraph="The period of holding for determining short-term or long-term capital gains is calculated based on the holding period of the individual assets that constitute the business being sold, rather than considering a separate holding period specifically for the slump sale itself." />
      <Content paragraph="Here's a breakdown:" />
      <CustomList
        points={[
          "WHAT IS STCG IN SLUMP SALE?",
          "For assets that have been held for less than the specified duration set by tax laws (typically less than one year in many jurisdictions), any gains arising from the sale of these assets in the slump sale would be treated as short-term capital gains.",
          "WHAT IS LTCG IN SLUMP SALE?:",
          "For assets that have been held for more than the specified duration (usually one year or more), gains from the sale of these assets as part of the slump sale would be treated as long-term capital gains.",
          "It's important to note that the period of holding for determining short-term or long-term capital gains in a slump sale is determined based on the individual holding periods of the assets being transferred. Therefore, the specific holding period of the assets comprising the business being sold will determine whether the gains are categorized as short-term or long-term capital gains, rather than the slump sale itself having a distinct holding period for this purpose.",
        ]}
      />
      <Step number="WHAT IS DIFFERENCE BETWEEN SLUMP SALE AND ITEMIZED SALE?" />
      <CustomList
        points={[
          "Aspect\tSlump Sale\tItemized Sale",
          "Nature of Sale\tThe entire business or part sold as a whole\tIndividual assets or parts sold separately",
          "Transaction\tOne transaction for the entire business\tMultiple transactions for specific assets21",
          "Consideration\tLump sum amount for the whole business\tSeparate consideration for each asset",
          "Asset Valuation\tNo individual valuation of assets/liabilities\tDetailed valuation for each asset",
          "Tax Treatment\tCapital gains on entire business as a whole\tCapital gains for each asset sold separately",
        ]}
      />
      <Step number="HOW TO CALCULATE NET CONSIDERATION FOR SLUMP SALE?" />
      <Content paragraph="Section 50B of the Income Tax Act, 1961 in India deals with the computation of capital gains arising from a slump sale. The net consideration under Section 50B is crucial in determining the capital gains. Here's how to calculate the net consideration under Section 50B:" />
      <Content paragraph="The net consideration is calculated by taking into account the full value of the consideration received or accruing as a result of the slump sale and then deducting the net worth of the business or undertaking transferred." />
      <Content paragraph="WHAT IS THE FORMULA TO CALCULATE NET CONSIDERATION UNDER 50 B OF THE ACT?" />
      <Content paragraph="Net Consideration = Full Value of Consideration Received or Accrued − Net Worth of the Undertaking/Division" />
      <Content paragraph="Here's a breakdown of the components involved:" />
      <CustomList
        points={[
          "Full Value of Consideration Received or Accrued: This includes the entire amount received or accruing to the seller as a result of the slump sale. It may comprise cash, kind, or any other consideration received from the buyer.",
          "Net Worth of the Undertaking/Division: The net worth is calculated based on the aggregate value of assets as reduced by the aggregate value of liabilities as appearing in the books of accounts of the seller.",
          "Once you have both these figures, you subtract the net worth from the full value of consideration to determine the net consideration.",
        ]}
      />
    </Box>
  );
};

export default SlumpSaleExplanation;
