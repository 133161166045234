import React from "react";
import { Box } from "@mui/material";
import Mainhead from "../../../Components/Services/MainContent/Mainhead";
import Content from "../../../Components/Services/MainContent/Content";
import CustomList from "../../../Components/Income-Tax/CustomList";
import Step from "../../../Components/Services/MainContent/Step";

const TaxAuditSection44AB = () => {
  return (
    <Box
      style={{
        display: "flex",
        flexDirection: "column",
        gap: 4,
        marginBottom: "2rem",
      }}
    >
      <Mainhead heading="Tax Audit under Section 44AB" />
      <Step
        number={`
            Introduction
          `}
      />
      <Content paragraph="This type of audit comes under the compulsory category of audits, mandated by the Income Tax Act, 1961. It is a compulsory audit carried out by a Chartered Accountant for an assessee to ensure accuracy, compliance verification, promoting transparency, strengthening tax collection, and minimizing tax disputes." />
      <Step
        number={`
            What is the Applicability of Tax Audit in case of Business?
          `}
      />
      <CustomList
        points={[
          "Business not covered under presumptive taxation: If turnover > Rs. 3 Cr and total cash transactions are 5% of total sales (Revised to Rs. 10 Cr).",
          "Business covered under presumptive taxation: Actual profit is lower than the limit prescribed.",
          "Business covered under section 44AD: If total turnover exceeds Rs. 2 Cr.",
        ]}
      />
      <Step
        number={`
            What is the Applicability of Tax Audit in case of Profession?
          `}
      />
      <CustomList
        points={[
          "Profession not covered under presumptive taxation: If gross receipts exceed Rs. 75 Lacs (Revised from 50 Lakhs).",
          "Profession under Presumptive Taxation (Section 44ADA): If actual profit is lower than the prescribed limit and income exceeds the maximum amount not chargeable to tax.",
        ]}
      />
      <Step
        number={`
            What does the Tax audit report constitute of?
          `}
      />
      <Content paragraph="The tax audit report consists of Form 3CA/3CB and Form 3CD. Form 3CA is for entities audited by any other law, while Form 3CB is for other entities. Form 3CD contains details about the taxpayer, observations, compliance with tax laws, accounting policies, income, expenses, deductions, and relevant information." />
      <Step
        number={`
            How is the tax audit report filed and what is the due date?
          `}
      />
      <Content paragraph="Prepared by a practicing Chartered Accountant after conducting the tax audit, the report is filed electronically. The due date for filing the tax audit return is 30th November, and the tax audit must be conducted by 30th September. Penalties apply for delay." />

      <CustomList
        points={[
          "0.5% of total sales, turnover, or gross receipts:- Rs 1,50,000",
          "Penalty Exceptions:- Natural Calamities, Resignation of Tax Auditor, etc.",
        ]}
      />
    </Box>
  );
};

export default TaxAuditSection44AB;
