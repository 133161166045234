import * as React from 'react';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Divider } from '@mui/material';
import Content from '../../../../Components/Services/MainContent/Content';
import Mainhead from '../../../../Components/Services/MainContent/Mainhead';

export default function FAQ() {
  const [expanded, setExpanded] = React.useState(false);

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  return (
    <div>
      <Mainhead heading="FAQ'S"/>
      <br/>
      <Divider sx={{border: '1px solid #FF990080'}}/>
      <Accordion expanded={expanded === 'panel1'} onChange={handleChange('panel1')}  elevation={0} sx={{ boxShadow: 'unset !important' }} disableGutters>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1bh-content"
          id="panel1bh-header"
        >
          <Typography  sx={{color:'#281900',fontWeight:450,fontSize:'20px'}}>How can I expand the operations of my Nidhi Company to different areas</Typography>
        </AccordionSummary>
        <AccordionDetails>
         <Content paragraph ="A minimum of three branches can be opened in a particular district. In case you want to expand outside the district or want to open more than three branches, the Registrar of Companies need to be intimated 30 days prior to their opening"/>
         
        </AccordionDetails>
      </Accordion>
      <Divider sx={{border: '1px solid #FF990080'}}/>
      <Accordion expanded={expanded === 'panel2'} onChange={handleChange('panel2')}
       elevation={0} sx={{ boxShadow: 'unset !important' }} disableGutters>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel2bh-content"
          id="panel2bh-header"
        >
          <Typography sx={{color:'#281900',fontWeight:450,fontSize:'20px'}}>
          Who has got the authority to regulate Nidhi Company?
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
         <Content paragraph="Ministry of Corporate Affairs is the responsible authority for regulating Nidhi Company and is also accountable for Nidhi Company Registration. Provisions of Nidhi Rules, 2014, are being followed for doing all the activities"/>
        </AccordionDetails>
      </Accordion>
      <Divider sx={{border: '1px solid #FF990080'}}/>
      <Accordion expanded={expanded === 'panel3'} onChange={handleChange('panel3')}
       elevation={0} sx={{ boxShadow: 'unset !important' }} disableGutters>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel3bh-content"
          id="panel3bh-header"
        >
          <Typography sx={{color:'#281900',fontWeight:450,fontSize:'20px'}}>
          Should I dissolve my Nidhi Company because it has not gained the necessary numbers of representatives in spite of completing one full year of tenure?
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
         <Content paragraph="The conditions laid down by the Act says that a Nidhi Company should acquire a minimum of 200 members within its first year of incorporation. However, in case the company is not able to meet the required numbers, it can apply for an extension with the necessary government authorities"/>
        </AccordionDetails>
      </Accordion>
      <Divider sx={{border: '1px solid #FF990080'}}/>
      <Accordion expanded={expanded === 'panel4'} onChange={handleChange('panel4')}
       elevation={0} sx={{ boxShadow: 'unset !important' }} disableGutters>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel4bh-content"
          id="panel4bh-header"
        >
          <Typography sx={{color:'#281900',fontWeight:450,fontSize:'20px'}}> What are the criteria to become representative of a Nidhi Company?</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Content paragraph="Any person can deposit, lend or borrow money through the provisions provided by the Companies Act, 2013. Hence they all can become members of a Nidhi Company provided they are not a Corporate or a company"/>
        </AccordionDetails>
      </Accordion>
      <Divider sx={{border: '1px solid #FF990080'}}/>
      <Accordion expanded={expanded === 'panel5'} onChange={handleChange('panel5')}
       elevation={0} sx={{ boxShadow: 'unset !important' }} disableGutters>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel5bh-content"
          id="panel5bh-header"
        >
          <Typography sx={{color:'#281900',fontWeight:450,fontSize:'20px'}}> Can a Nidhi bank issue unsecured?</Typography>
        </AccordionSummary>
        <AccordionDetails>
         <Content paragraph="No, Nidhi company cannot issue"/>
          </AccordionDetails>
          </Accordion>


          <Divider sx={{border: '1px solid #FF990080'}}/>
      <Accordion expanded={expanded === 'panel6'} onChange={handleChange('panel6')}
       elevation={0} sx={{ boxShadow: 'unset !important' }} disableGutters>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel6bh-content"
          id="panel6bh-header"
        >
          <Typography sx={{color:'#281900',fontWeight:450,fontSize:'20px'}}>Are Nidhi Companies authorized to advertise their activities?</Typography>
        </AccordionSummary>
        <AccordionDetails>
         <Content paragraph="No, Nidhi Companies are not authorized to advertise their activities"/>
          </AccordionDetails>
          </Accordion>

          <Divider sx={{border: '1px solid #FF990080'}}/>
      <Accordion expanded={expanded === 'panel7'} onChange={handleChange('panel7')}
       elevation={0} sx={{ boxShadow: 'unset !important' }} disableGutters>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel7bh-content"
          id="panel7bh-header"
        >
          <Typography sx={{color:'#281900',fontWeight:450,fontSize:'20px'}}> How long does it take to register Nidhi Company?</Typography>
        </AccordionSummary>
        <AccordionDetails>
         <Content paragraph="It takes 30-40 days for completion of the registration process"/>
          </AccordionDetails>
          </Accordion>

          <Divider sx={{border: '1px solid #FF990080'}}/>
      <Accordion expanded={expanded === 'panel8'} onChange={handleChange('panel8')}
       elevation={0} sx={{ boxShadow: 'unset !important' }} disableGutters>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel8bh-content"
          id="panel8bh-header"
        >
          <Typography sx={{color:'#281900',fontWeight:450,fontSize:'20px'}}> Can Nidhi Company issue debt securities or preference shares?</Typography>
        </AccordionSummary>
        <AccordionDetails>
         <Content paragraph="No"/>
          </AccordionDetails>
          </Accordion>

          <Divider sx={{border: '1px solid #FF990080'}}/>
      
      

         




      


      


      



    </div>
  );
}