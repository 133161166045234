import React from 'react';
import Mainhead from '../../../../Components/Services/MainContent/Mainhead';
import Content from '../../../../Components/Services/MainContent/Content';
import List from '../../../../Components/Services/MainContent/List';
import Step from '../../../../Components/Services/MainContent/Step';
import { Box , styled, Typography} from '@mui/material';

function WhyLegalFactory(){
return(
    <div>
        <Mainhead heading="Why Legal Factory ?"/>
        <Content paragraph="Legal Factory, which is a unit of Xpert Finance, is an umbrella organisation offering Fintech services to its clients with the aid of its dynamic team of Chartered Accountants, Company Secretaries, Corporate Lawyers, Registered Valuers & Chartered Engineers. Registering different forms of entities is our Forte. We provide:"/>
        <ul className='list'>
            <li className='bullet'>
                <span>
        <List points="Lowest Price"/>
        </span>
        </li>
        <li className="bullet">
                    <span>
        <List points="CA/CS assistance"/>
        </span>
        </li>
        <li className="bullet">
                    <span>
        <List points="Confidentiality of Data"/>
        </span>
        </li>
        <li className="bullet">
                    <span>
        <List points="Experienced Team"/>
        </span>
        </li>
        <li className="bullet">
                    <span>
        <List points="Price Transparency"/>
        </span>
        </li>
        <li className="bullet">
                    <span>
        <List points="Swift Services"/>
        </span>
        </li>
        <li className="bullet">
                    <span>
        <List points="Maintain business relations"/>
        </span>
        </li>
        <li className="bullet">
                    <span>
        <List points="Customer Aid"/>
        </span>
        </li>
        </ul>
    </div>
)
}

export default WhyLegalFactory