import React from 'react'
import Mainhead from '../../../../Components/Services/MainContent/Mainhead';
import Content from '../../../../Components/Services/MainContent/Content';

import { Box , styled} from '@mui/material';


const Tabletimg = styled(Box)(({ theme }) => ({
    width:'15rem',
    height:'10rem',
    marginTop:'0.8rem',
    [theme.breakpoints.down("sm")]: {
     width:'4rem',
   height:'8rem',
   },
   [theme.breakpoints.down("md")]: {
     width:'9rem',
   height:'8rem',
   },
   [theme.breakpoints.between("md", "lg")]: {
    width:'10rem'
   },
   [theme.breakpoints.up("xl")]: {
     width:'16rem'
    }
 }));

function Overview(){
    return(
        <div>
            <Mainhead heading="What is Limited Liability Partnership?" />
            <Content paragraph= "LLP is an upcoming most famous preferred type of organisation with the benefit of Partnership Firm and Limited Liability Company, two birds with one stone. This Act was initiated in 2008 in India. A minimum number of two partners are required to incorporate an LLP with no maximum limit, and at least one of those partners should be an Indian resident. The key feature behind such organisation is easy maintenance and limited liability to the owners." />

        </div>
    )
}

export default Overview