import React from "react";
import { Box } from "@mui/material";
import Mainhead from "../../../Components/Services/MainContent/Mainhead";
import Content from "../../../Components/Services/MainContent/Content";
import CustomList from "../../../Components/Income-Tax/CustomList";
import Step from "../../../Components/Services/MainContent/Step";
import ReusableTable from "../../../Components/Income-Tax/ReusableTable";
import NormalList from "../../../Components/Income-Tax/NormalList";

const Section40A3and44ADA = () => {
  const section40A3TableData = [
    {
      Case: "Payment to plying, hiring or leasing goods carriages",
      "Monetary Limit": "35,000",
    },
    { Case: "Payment made for any other expense", "Monetary Limit": "10,000" },
  ];

  return (
    <Box
      style={{
        display: "flex",
        flexDirection: "column",
        gap: 4,
        marginBottom: "2rem",
      }}
    >
      <Mainhead heading="Section 40 A (3)" />
      <Content paragraph="Section 40A(3) of the Income Tax Act, in India, deals with specific payments and expenses that businesses and professions make." />
      <Content paragraph="Objective: The primary objective of Section 40A(3) is to prevent the evasion of tax by disallowing certain expenses or payments made in cash." />
      <Step
        number={`
            Introduction
          `}
      />
      <Content paragraph="This clause prohibits the deduction of up to Rs. 10,000 from any one person's expenses incurred on the same day. As a result, in order to claim a deduction for any expenses, an individual must only use the digital payment method that is specified." />
      <Content paragraph="A person's purchase of land or machinery is covered by the capital gain income tax statute and is not considered an expense." />
      <Step
        number={`
            Applicability
          `}
      />
      <Content paragraph="Any expense for which payment has been made in excess of Rs. 10,000 in a single day other than through the use of an electronic clearing system through a bank account, an account payee bank draft, or another electronic method (art. 40A(3))." />
      <Step
        number={`
            Treatment
          `}
      />
      <Content paragraph="100% of such payment shall be disallowed." />
      <Step
        number={`
            Exception
          `}
      />
      <Content paragraph="In the case of payment made for plying, hiring or leasing goods carriage (hereinafter referred to as road transport), the limit of Rs. 10,000 has been extended up to Rs. 35,000." />
      <Step
        number={`
            Taxpoint
          `}
      />
      <ReusableTable data={section40A3TableData} />

      <Step
        number={`
    Other Points
  `}
      />
      <Content paragraph="In cases where an allowance for any liability incurred by the assessee for any expenditure has been granted in the assessment year for any year; and the assessee then makes any payment in breach of any payment during the preceding year, then the payment made shall be deemed to be the profit and gains of business and profession from subsequent years." />
      <Content paragraph="In the event that an assessee pays two separate bills to the same individual in cash at the same time (not to exceed $10,000 or $35,000), Section 40A(3) is not triggered." />
      <Content paragraph="The provisions of Section 40A(3) do not apply if an assessee pays a single bill (exceeding Rs. 10,000/35,000) to the same person in cash on several days, so long as no portion of the payment exceeds Rs. 10,000/35,000." />
      <Content paragraph="Where payment is made over Rs. 10,000 (or Rs. 35,000) at a time, partly by account payee cheque & partly in cash but the cash alone at one time does not exceed Rs. 10,000 (or Rs. 35,000), the assessee is not attracted by Section 40A(3)." />
      <Content paragraph="The provision of Section 40A(3) is attracted only when such expenditure is claimed as a deduction under sections 30 to 37." />
      <Step
        number={`
    Various Modes of Payment Allowed under Section 40A(3)
  `}
      />
      <Content paragraph="No deduction will be taken even if the expense surpasses Rs. 10,000 (or Rs. 35,000) if the payment was made using:" />
      <CustomList
        points={[
          "An account payee cheque",
          "Demand draft",
          "Use of ECS or digital payment modes",
          "Other prescribed electronic modes",
        ]}
      />

      <Step
        number={`
    Key Points
  `}
      />
      <Content paragraph="Section 40A(3) of the Income Tax Act, in India, deals with specific payments and expenses that businesses and professions make." />
      <Content paragraph="Objective: The primary objective of Section 40A(3) is to prevent the evasion of tax by disallowing certain expenses or payments made in cash." />
      <Content paragraph="Key Points:" />
      <NormalList
        points={[
          "1. Disallowance of Payments in Cash:",
          "According to Section 40A(3), any expenditure incurred in respect of which a payment is made in cash exceeding a specified limit is not allowed as a deduction for the purpose of computing income under the head 'Profits and Gains of Business or Profession.'",
          "2. The specified limit can vary and may be amended by the government. As of my last update, the limit was Rs. 10,000 in aggregate per day for payments to a single person in a single day.",
          "3. Exceptions:",
          "Certain payments are exempted from the disallowance under Section 40A(3). For example, payments made to government, banking institutions, or those for which an exemption is granted by the prescribed authority are generally not covered by this provision.",
          "4. Penalties:",
          "If a taxpayer is found in violation of the provisions of Section 40A(3), penalties may be imposed.",
        ]}
      />
      <Step
        number={`
    Exception (Rule 6DD)
  `}
      />
      <Content paragraph="The following conditions, outlined in Rule 6DD, prevent the use of Sec. 40A(3) even when payments exceeding ₹10,000 (or ₹35,000) are paid by methods other than account payee bank drafts, account payee checks, or other approved methods:" />
      <NormalList
        points={[
          "1. Where the payment is made to—",
          "   - The Reserve Bank of India or any banking company",
          "   - The State Bank of India or any subsidiary bank",
          "   - Any co-operative bank or land mortgage bank;",
          "   - Any primary agricultural credit society or any primary credit society",
          "   - The Life Insurance Corporation of India",
          "2. Where the payment is made to the Government and, under the rules framed by it, such payment is required to be made in legal tender;",
          "3. Where the payment is made by—",
          "   - Any letter of credit arrangements through a bank;",
          "   - A mail or telegraphic transfer through a bank;",
          "   - A book adjustment from any account in a bank to any other account in that or any other bank;",
          "   - A bill of exchange made payable only to a bank;",
          "   - The use of electronic clearing system through a bank account;",
          "   - A credit card;",
          "   - A debit card",
          "4. When payment is made in the form of a book adjustment, which balances the amount of any obligation incurred by the payee for any goods supplied or services provided by the assessee to such payee,",
          "5. Where the payment is made for the purchase of",
          "   - Agricultural or forest produce; or",
          "   - The produce of animal husbandry (including livestock, meat, hides and skins) or dairy or poultry farming; or",
          "   - Fish or fish products; or",
          "   - The products of horticulture or apiculture, - to the cultivator, grower or producer of such articles, produce or products;",
          "6. When a producer of goods gets paid for the acquisition of goods produced or processed in a cottage industry without the assistance of power;",
          "7. Where the payment is made in a village or town, which on the date of such payment is not served by any bank, to any person who ordinarily resides, or is carrying on any business, profession or vocation, in any such village or town;",
          "8. When a gratuity, retrenchment compensation, or other similar terminal benefit is paid to an assessee employee or their heir in connection with their retirement, retrenchment, resignation, discharge, or death, and the total amount of these payments to the employee or his heir does not exceed ₹50,000;",
          "9. Where the payment is made by an assessee by way of salary to his employee after deducting the income-tax from salary in accordance with the provisions of section 192 of the Act, and when such employee -",
          "   - is temporarily posted for a continuous period of 15 days or more in a place other than his normal place of duty or on a ship; and",
          "   - does not maintain any account in any bank at such place or ship;",
          "10. Where the payment was required to be made on a day on which the banks were closed either on account of holiday or strike;",
          "11. In the event when a person pays money to their agent, who is obligated to pay cash for products or services on their behalf;",
          "12. When the payment is made in the regular course of business by an authorized dealer or money changer against the purchase of foreign currency or travelers' checks.",
        ]}
      />

      <Step
        number={`
    Amendment - Purchase of Capital Assets
  `}
      />
      <Content paragraph="Purchase of capital assets not earlier covered, now if there is a payment exceeding Rs. 10,000 otherwise than an account payee cheque, then the cost of such assets will not be considered in actual cost, and therefore no depreciation will be allowed on such assets." />

      <Step
        number={`
    Payment of Remuneration and Interest to Partners u/s 40(b)
  `}
      />
      <Content paragraph="INTRODUCTION: Section 40(b) of the Income Tax Act, 1961 in India deals with the computation of allowable deductions in the case of partnership firms regarding the payment of interest, salary, bonus, commission, or remuneration to partners. It lays down certain conditions and limits on the deductions that can be claimed by the firm for such payments." />
      <Step
        number={`
    Key Points Regarding Section 40(b) of the Income Tax Act, 1961
  `}
      />
      <NormalList
        points={[
          "Limits on Deductions:",
          "- Section 40(b) specifies that in the case of a partnership firm, any payment of interest, salary, bonus, commission, or remuneration to any partner is allowed as a deduction only if it is authorized by the partnership deed and is subject to certain limits and conditions.",
          "Conditions for Allowability:",
          "- The payment should be in accordance with the terms of the partnership deed and should not exceed the limits specified under Section 40(b).",
          "- It should be specifically allocated or mentioned in the partnership deed and be related to the assessment year for which the deduction is claimed.",
        ]}
      />
      <Step
        number={`
    Limits Regarding Remuneration in the Income Tax Act, 1961
  `}
      />
      <Content paragraph="The following conditions need to be satisfied in order to claim a deduction for the amount claimed under section 40(b):" />
      <CustomList
        points={[
          "Only remuneration paid to the Working Partners is eligible for deduction.",
          "It should be authorized by the partnership deed. The deed must state how much the salary is or how it will be calculated. If these details are missing, deductions won't be allowed. Usually, people mention in the deed that partners can receive salaries within the maximum limit set by this section, meeting the requirement for deductions.",
          "The remuneration should persist for the period from which the partnership came into existence; any prior period payments will not be allowed.",
          "The remuneration paid should be within the limits prescribed under this section.",
        ]}
      />
      <Content paragraph="The limits:" />
      <CustomList
        points={[
          "Book Profit up to Rs. 3,00,000 - Rs. 1,50,000 or 90% of the book profit, whichever is higher.",
          "Balance Book Profit - 60% of the book profit",
        ]}
      />

      <Step
        number={`
    What is Book Profit?
  `}
      />
      <Content paragraph="Book Profit can be calculated as follows:" />
      <ReusableTable
        data={[
          {
            "Book Profit": "Profit as per Profit and Loss (P&L)",
            Limit: "xxx",
          },
          {
            "Book Profit": "Add: Remuneration to partners (if debited to P&L)",
            Limit: "xx",
          },
          {
            "Book Profit": "Add: Interest paid to partners (if debited to P&L)",
            Limit: "xx",
          },
          {
            "Book Profit": "Less: Interest Allowed as per Section 40(b)",
            Limit: "(xx)",
          },
          { "Book Profit": "Book Profit", Limit: "xxx" },
        ]}
      />
      <Step
        number={`
    Let's understand this with an illustration
  `}
      />
      <Content paragraph="Assuming a Partnership firm, LF, has a Net Profit of Rs. 10,90,000. In the partnership deed, the remuneration paid to partners is Rs. 3,40,000 each. There are two partners, and interest paid to them is Rs. 50,000 for the year (assuming within the limits of Section 40(b)). Hence, the Book Profit will be calculated as follows:" />
      <ReusableTable
        data={[
          { Particulars: "Profit as per P&L", "Amount in Rs.": "10,90,000" },
          {
            Particulars: "Add: Remuneration Paid (Rs. 3,40,000*2)",
            "Amount in Rs.": "6,80,000",
          },
          {
            Particulars: "Add: Interest Paid (Rs. 50,000*2)",
            "Amount in Rs.": "1,00,000",
          },
          {
            Particulars: "Less: Interest Allowed",
            "Amount in Rs.": "1,00,000",
          },
          { Particulars: "Book Profit", "Amount in Rs.": "17,70,000" },
        ]}
      />

      <Step
        number={`
          Deduction Allowable for Remuneration Paid
        `}
      />
      <Content paragraph="For Rs. 3,00,000:" />
      <Content paragraph="- Rs. 1,50,000 or 90% of Rs. 3,00,000 w.i.h." />
      <Content paragraph="- Rs. 1,50,000 or Rs. 2,70,000 w.i.h." />
      <Content paragraph="- Rs. 2,70,000" />
      <Content paragraph="For Balance Book Profit:" />
      <Content paragraph="- 60% of Balance Book Profit" />
      <Content paragraph="Rs. 10,90,000 - Rs. 3,00,000 = Rs. 7,90,000" />
      <Content paragraph="60% of Rs. 7,90,000 = Rs. 4,74,000" />
      <Content paragraph="Total amount of Deduction allowable: Rs. 2,70,000 + Rs. 4,74,000 = Rs. 7,44,000" />
      <Content paragraph="Total amount of Remuneration Paid: Rs. 6,80,000" />
      <Content paragraph="Hence the entire amount of Remuneration Paid will be allowed for deduction." />

      <Step
        number={`
          Calculation of Interest Allowable under Income Tax Act, 1961
        `}
      />
      <Content paragraph="The total amount of Interest that is paid to the partner should not exceed 12% of the Partner’s Capital. Any excess amount will be disallowed for deduction." />
      <Content paragraph="For example, let's say the Capital Balances of A and B are Rs. 3,69,500 and Rs. 7,89,400, and the interest paid to them is Rs. 60,000 each." />
      <Content paragraph="The maximum allowable deduction for interest would be:" />
      <Content paragraph="For A: Rs. 3,69,500 * 12% = Rs. 44,340" />
      <Content paragraph="For B: Rs. 7,89,400 * 12% = Rs. 94,728" />
      <Content paragraph="Hence for A only Rs. 44,340 will be allowed for deduction, and for B, the entire amount paid in Interest, that is Rs. 60,000, is allowed." />

      <Step
        number={`
    Additional Depreciation
  `}
      />
      <Content paragraph="What does Additional Depreciation mean?" />
      <Content paragraph="Additional depreciation is a tax benefit provided under Section 32(1)(iia) of the Income Tax Act. This section allows an assessee engaged in the business of manufacturing or production of any article or thing to claim additional depreciation over and above the normal depreciation allowed." />
      <Content paragraph="Additional depreciation was introduced in India as a tax incentive to encourage capital investment in certain sectors, particularly in manufacturing and production activities. The objective behind introducing additional depreciation is to stimulate economic growth, promote industrial development, and enhance the competitiveness of specific industries." />

      <Step
        number={`
    Who is eligible for Additional Depreciation?
  `}
      />
      <Content paragraph="An assessee engaged in the production or manufacture of any article can claim additional depreciation if the following conditions are satisfied:" />
      <CustomList
        points={[
          "The asset must be new and not previously been used.",
          "The asset must be in use for business purposes.",
          "The asset must be purchased after 31st March 2005.",
        ]}
      />
      <Content paragraph="Note: Additional depreciation can also be claimed by an assessee engaged in the business of generation or transmission of power." />

      <Step
        number={`
    Calculation of Additional Depreciation
  `}
      />
      <Content paragraph="The rate of additional depreciation is 20% on the actual cost of the asset." />
      <Content paragraph="If the asset has been used for less than 180 days, then 50% of the additional depreciation (i.e., ½ of 20% = 10%) can be claimed in the current year, and the remaining 50% can be claimed in the subsequent year." />
      <Content paragraph="For example, a plant & machinery of Rs 1000000 was purchased on 24.06.2023, and another was purchased for Rs 500000 on 11.11.2023. Both have a depreciation rate of 15%. Then, additional depreciation rate will be calculated as follows:" />
      <CustomList
        points={[
          "Purchased on 24.06.2023 - 15% + 20% = 35%",
          "Purchased on 11.11.2023 - 15% + 10% = 25%",
        ]}
      />
      <Content paragraph="Note: As per the Finance Act 2015, the Central government has notified that the assessee will get an enhanced rate of depreciation @ 35% if the assessee sets up a manufacturing/production unit in a backward area. The backward areas of the following states have been notified:" />
      <CustomList
        points={["Andhra Pradesh", "West Bengal", "Bihar", "Telangana"]}
      />

      <Step
        number={`
    Exceptions in Additional Depreciation
  `}
      />
      <Content paragraph="Additional depreciation will not be applicable in the following cases:" />
      <CustomList
        points={[
          "If the asset comes under any block other than ‘Plant & machinery’ such as ‘Furniture & fixtures’, it is not applicable.",
          "Any asset that has previously been used before its installation.",
          "The asset should not be installed in an office or residential building.",
          "An asset whose entire cost has been claimed as a deduction under the Income Tax Act.",
          "If the asset is a ship, aircraft, or a road transport vehicle.",
        ]}
      />
      <Step
        number={`
    Section 44 ADA
  `}
      />
      <Content paragraph="Introduction" />
      <Content paragraph="Section 44 ADA is the scheme for presumptive taxation of income from professions pertaining to small professionals." />
      <Content paragraph="As per this section, if any professional chooses to pay tax as per the presumptive scheme, they will be required to pay tax at the prescribed rate on their gross receipts without the need for maintaining detailed books of accounts." />

      <Step
        number={`
    Who is eligible for presumptive taxation under 44ADA?
  `}
      />
      <Content paragraph="Only those professionals whose gross receipts are less than Rs. 50 lakhs can choose to pay tax under the presumptive scheme." />
      <Content paragraph="Note: As per the budget 2023, the limit for presumptive taxation has been increased from Rs. 50 Lakhs to Rs. 75 Lakhs given that 95% of the transactions have been done through online mode. (Applicable from FY 2023-24)" />
      <Content paragraph="Presumptive taxation can be opted by an assessee who is an individual or partnership firm." />

      <Step
        number={`
    Profession under Section 44 AA
  `}
      />
      <Content paragraph="The presumptive taxation scheme can be opted by assessees engaged in the professions mentioned under Section 44AA:" />
      <CustomList
        points={[
          "Legal professional",
          "Medical professional",
          "Engineering",
          "Architecture",
          "Interior designing",
          "Accounting",
          "Technical consultancy",
          "Any other profession as notified in the official gazette",
        ]}
      />

      <Step
        number={`
    Profit under Section 44 ADA
  `}
      />
      <Content paragraph="Under presumptive taxation, small professionals are required to presume their profit as at least 50% of the gross receipts." />
      <Content paragraph="For example, Mr. A is a chartered accountant. His gross receipts during a year are Rs. 4000000. Since gross receipts are less than 50 lakh limit, he can opt for presumptive taxation under section 44 ADA. Then, his taxable income will be calculated as under:" />
      <CustomList
        points={[
          "GROSS RECEIPTS: Rs 4000000",
          "LESS: EXPENSES @ 50%: (RS 2000000)",
          "PROFIT @ 50%: RS 2000000",
        ]}
      />

      <Step
        number={`
    What are the benefits of presumptive taxation
  `}
      />
      <CustomList
        points={[
          "With multitudes of transactions on a day to day basis, it is difficult to maintain all transactions on record. Through presumptive taxation, small professionals are exempted from maintaining proper books of accounts.",
          "The ITR filing process is also simplified for those opting for presumptive taxation under section 44 ADA as compared to others.",
          "The assessee can claim up to 50% of the gross receipts as expenses.",
        ]}
      />

      <Step
        number={`
    Section 43 of the Income Tax Act,1961
  `}
      />
      <Content paragraph="Introduction" />
      <Content paragraph="Section 43 of the Income Tax Act gives the definition of certain terms relevant to income from profits and gains of business or profession. It primarily deals with the determination of the actual cost and written down value of certain assets for the purpose of computing depreciation." />

      <Step
        number={`
    Actual Cost (Section 43(6))
  `}
      />
      <Content paragraph="Section 43 defines actual cost as the amount that is incurred in acquiring the asset." />
      <Content paragraph="Actual cost includes the following:" />
      <CustomList
        points={[
          "Purchase price",
          "Expenses directly attributable to bringing the asset in working condition.",
          "Any other expenses necessary to bring the asset in working condition",
        ]}
      />

      <Step
        number={`
    Written Down Value (WDV) (Section 43(6))
  `}
      />
      <Content paragraph="The term 'written down value' refers to the value of an asset after accounting for depreciation. It is the actual cost of the asset minus depreciation claimed in previous years. Section 43 provides guidelines on how to compute the written down value, which is used to calculate depreciation for the current year." />

      <Step
        number={`
    Speculative Transactions (Section 43(5))
  `}
      />
      <Content paragraph="Derivative Contracts: Section 43(5) specifically includes transactions in commodities, currencies, and stock or securities futures as speculative transactions." />
      <Content paragraph="Intention for Settlement: Any contract for the purchase or sale of commodities, currencies, or shares settled otherwise than by the actual delivery or transfer of the commodity, currency is considered a speculative transaction." />
      <Content paragraph="Non-Delivery-Based Settlement: If the settlement of a contract doesn’t involve actual delivery or transfer but is settled by a difference (cash settlement) between buying and selling prices, it falls under the speculative category." />

      <Step
        number={`
    Section 40(a) and 40(ia) of Income Tax Act,1961
  `}
      />

      <Step
        number={`
    Section 40(a)(i) of Income Tax Act,1961
  `}
      />
      <Content paragraph="Section 40(a)(i) of the Income Tax Act in India deals with the disallowance of certain expenses or payments made by businesses if they haven't been deducted while computing taxable income." />
      <Content paragraph="This section pertains to the disallowance of certain payments such as interest, commission, brokerage, rent, royalty, professional fees, and payments to contractors or subcontractors if these payments are made to a resident and are subject to tax deduction at source (TDS) as per the provisions of the Income Tax Act, but the TDS hasn't been deducted." />

      <Step
        number={`
    Exceptions to Section 40(a)(i) of Income Tax Act,1961
  `}
      />
      <Content paragraph="This section will not be applicable in the following cases:" />
      <CustomList
        points={[
          "If the assessee can prove that such payments made by her/him are not for any business purpose but are personal in nature.",
          "If the assessee can prove that such payments are not subject to tax or are under the threshold limit for deducting TDS.",
        ]}
      />

      <Step
        number={`
    Section 40(a)(ia) of Income Tax Act,1961
  `}
      />
      <Content paragraph="Section 40(a)(ia) of the Income Tax Act in India deals with the disallowance of certain expenses or payments made by businesses if they haven't been deducted while computing taxable income." />
      <Content paragraph="If the taxpayer has failed to deduct TDS or deposit the deducted TDS to the government on a timely basis, then such expenses will be disallowed to the taxpayer." />

      <Step
        number={`
    Exceptions to Section 40(a)(ia) of Income Tax Act,1961
  `}
      />
      <Content paragraph="If the taxpayer has included the income in the return and paid tax on it, then disallowance will not be applicable." />
    </Box>
  );
};

export default Section40A3and44ADA;
