import React from "react";
import { Box } from "@mui/material";
import Mainhead from "../../../Components/Services/MainContent/Mainhead";
import Content from "../../../Components/Services/MainContent/Content";
import CustomList from "../../../Components/Income-Tax/CustomList";
import Step from "../../../Components/Services/MainContent/Step";
import ReusableTable from "../../../Components/Income-Tax/ReusableTable";

const TaxCalculationFactors = () => {
  return (
    <Box
      style={{
        display: "flex",
        flexDirection: "column",
        gap: 4,
        marginBottom: "2rem",
      }}
    >
      <Mainhead heading="Different Factors Affecting Tax Calculation in India" />

      <Step number={`Introduction`} />
      <Content paragraph="The total amount of remuneration that the employer pays the employee during the course of their employment is known as their gross wage. They are offered aggregate compensation as their salary." />
      <Content paragraph="What does Gross Salary mean as per Income Tax Act,1961 in India?" />
      <Content paragraph="The gross salary is the total income received by an employee before any deductions are made. This includes regular wages, overtime, bonuses, commissions, and any other payments made to the employee. Gross salary isn't limited to only the money received in cash; it also encompasses revenue from all sources." />

      <Step number={`Breakdown of Elements of the Salary`} />
      <CustomList
        points={[
          "BASIC SALARY - The salary received to an employee before any additions or reductions to the overall compensation, such as investments, dues, overtime pay, or a bonus, is referred to as the basic salary.",
          "ALLOWANCES - An allowance is a financial advantage provided by the employer to the employee in addition to the regular income.",
          "PERQUISITES (SEC 17 (2)) - Perquisites are extra benefits and other advantages provided by your employer in addition to your basic compensation or wage.",
          "GROSS SALARY - The gross pay is the monthly or annual compensation received to the employee before any taxes or deductions.",
          "STANDARD DEDUCTION - The standard deduction is a government-set amount that can be deducted from your gross salary. Limit of standard deduction is 50,000 Rs.",
          "ENTERTAINMENT ALLOWANCE & PROFESSIONAL TAX - Entertainment allowance is payment made by an employer to an employee for the purpose of entertaining the client. Professional tax is imposed by the state government on individuals engaged in various professions. The maximum limit of which you can be charged is Rs 2500.",
          "TAXABLE INCOME - The amount left over after standard deduction and professional tax, entertainment allowance from gross salary is known as taxable income.",
          "DEDUCTION CHAPTER VIA (80C - 80U) - Taxpayers to decrease their tax liability by allowing deductions from gross total income.",
          "TOTAL INCOME - The total of income determined in the preceding phases is known as 'Gross Total Income (GTI),' from which various deductions are granted to a taxpayer on account of his investments and savings. 'Total Income' refers to the remaining income after deductions.",
          "TAX SLAB - During the Union Budget, the Government of India determines the amount of tax for the financial year. The tax is calculated according to an income tax slab. It means individuals will pay tax based on their level of income.",
          "REBATE 87A - You can claim the rebate if your total income after deduction (CHAPTER VIA) is less than 5 lakh in a financial year. Any individual is eligible for an income tax rebate of Rs 12,500.",
          "HEALTH & EDUCATION CESS - An additional tax charged by the Indian government to subsidise the education and healthcare requirements of rural and low-income families in India. After calculation of balance tax after rebate adding 4% health & education cess.",
          "TOTAL AMOUNT REFUNDABLE - If the self-assessment tax, tds or advance tax is paid more than the actual tax due, then the excess paid can be claimed as refund.",
        ]}
      />

      <Step number={`How to Calculate Gross Salary`} />
      <ReusableTable
        data={[
          { Particular: "Basic salary", Amount: "xxx" },
          { Particular: "Allowances", Amount: "xxx" },
          { Particular: "Perquisites", Amount: "xxx" },
          { Particular: "Gross salary", Amount: "xxx" },
          { Particular: "Less : Standard Deduction", Amount: "xxxx" },
          {
            Particular: "Less : Entertainment Allowance / Professional Tax",
            Amount: "xxxx",
          },
          { Particular: "Income charged under salary head", Amount: "xxx" },
          { Particular: "Less : Deduction (chapter VIA)", Amount: "xxxx" },
          { Particular: "Total Income", Amount: "xxx" },
          { Particular: "Tax on Total Income", Amount: "xxx" },
          { Particular: "Less : Rebate u/s 87A", Amount: "xxx" },
          { Particular: "Balance of tax after rebate", Amount: "xxx" },
          { Particular: "Add : Health & Education Cess (4%)", Amount: "xxx" },
          { Particular: "Total Tax Liability", Amount: "xxx" },
        ]}
      />
    </Box>
  );
};

export default TaxCalculationFactors;
