import Sidecomponent from "../../../Components/Income-Tax/Sidecomponent";
import Topdiv from "../../../Components/Tax-Planning/Topdiv";

import { Grid, Box, Container } from "@mui/material";

import { menuItems } from "../CapitalGain/menuItems";
import Faq from "../../../Components/Income-Tax/Faq";
import faqData from "./faqData";
import Section80C from "./Section80C";
import Section80CCC from "./Section80CCC";
import Section80CCD from "./Section80CCD";
import Section80TTA from "./Section80TTA";
import Deduction80U from "./Deduction80U";
import Section80RRB from "./Section80RRB";
import Section80G from "./Section80G";
import Section80GGB from "./Section80GGB";
import Section80E from "./Section80E";
import Section80EESection80TTB from "./Section80EESection80TTB";
import Section80DSection80DD from "../CapitalGain/Section80DSection80DD";
import Section80DDB from "./Section80DDB";

const Taxableincome = () => {
  return (
    <div>
      <Topdiv heading="Are you looking to file your Income Tax Returns ?" />
      <div className="Container">
        <Container>
          <Grid container>
            <Grid item lg={4} xl={3}>
              <Box
                style={{
                  display: { xs: "none", lg: "block" },
                  marginTop: "4rem",
                  position: "sticky",
                  top: 90,
                  marginBottom: "3rem",
                }}
              >
                <Sidecomponent menuItems={menuItems} />
              </Box>
            </Grid>

            <Grid item xs={11} lg={8} sx={{ marginTop: "4rem" }} xl={9}>
              <Section80C />
              <Section80CCC />
              <Section80CCD />
              <Section80TTA />
              <Deduction80U />
              <Section80RRB />
              <Section80G />
              <Section80GGB />
              <Section80E />
              <Section80EESection80TTB />
              <Section80DSection80DD />
              <Section80DDB />
              <Faq data={faqData} />
            </Grid>
          </Grid>
        </Container>
      </div>
    </div>
  );
};

export default Taxableincome;
