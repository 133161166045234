import React from "react";
import { Box } from "@mui/material";
import Mainhead from "../../../Components/Services/MainContent/Mainhead";
import Content from "../../../Components/Services/MainContent/Content";
import CustomList from "../../../Components/Income-Tax/CustomList";
import Step from "../../../Components/Services/MainContent/Step";
import ReusableTable from "../../../Components/Income-Tax/ReusableTable";

const HraComponent = () => {
  const hraExemptionCalculationData = [
    { Particulars: "50% of Salary", Amount: "6,30,000" },
    { Particulars: "HRA actually received", Amount: "3,00,000" },
    { Particulars: "Rent paid (-) 10% of Salary", Amount: "1,14,000" },
    { Particulars: "HRA exemption - Least of above", Amount: "1,14,000" },
  ];

  // Calculation details for Taxable HRA
  const taxableHRAData = [
    { Particulars: "HRA actually received", Amount: "3,00,000" },
    { Particulars: "Less: HRA exemption", Amount: "(1,14,000)" },
    { Particulars: "Taxable HRA", Amount: "1,86,000" },
  ];
  const hraComputationTableData = [
    { Particulars: "Salary (80000*12)", Rs: "9,60,000" },
    {
      Particulars: "Less: HRA exemption under section 10(13A) (15000*12)",
      Rs: "(1,80,000)",
    },
    { Particulars: "Gross Salary", Rs: "7,80,000" },
  ];

  return (
    <Box
      style={{
        display: "flex",
        flexDirection: "column",
        gap: 4,
        marginBottom: "2rem",
      }}
    >
      <Mainhead heading="House Rent Allowance (HRA) in India" />
      <Step
        number={`
            What is House Rent Allowance in Income Tax Act, 1961 In India?
          `}
      />
      <Content paragraph="It is a particular allowance issued by an employer to an employee for the payment of rent for the employee's residence." />
      <Step
        number={`
            What are the eligibility requirements for receiving House Rent Allowance (HRA)?
          `}
      />
      <CustomList
        points={[
          "Only salaried individuals are eligible to claim HRA.",
          "Stay in Rented Accommodation.",
          "HRA is a component of salary.",
          "HRA is available only under the optional tax regime (old tax regime). It is not available under the default tax regime (new tax regime i.e section 115 BAC).",
        ]}
      />
      <Content paragraph="Let's use an example to better grasp this condition." />
      <Content paragraph="Mr. Rahul works for ABC Limited. and is paid an amount of 80,000 per month. and was given HRA of Rs. 15,000 (the maximum allowed under the Income Tax Act of 1961)." />
      <Step
        number={`Now we can determine the gross salary that is taxable under the salary head under both regimes:`}
      />
      <ReusableTable data={hraComputationTableData} />
      <Step
        number={`HRA Exemption for Assessee living with parents/Self Occupied:`}
      />
      <Content paragraph="Employee can pay rent to his/her parents and claim the allowance provided." />
      <Content paragraph="Employee has to enter into a rental agreement with his/her parents and transfer money to them every month." />
      <Content paragraph="Also, his/her parents need to report the rent received from their child in their Income Tax Return." />
      <Content paragraph="If their other income is below the basic exemption limit or taxable at a lower tax slab, then you can ultimately save Taxes." />
      <Step
        number={`Documentation Required to Claim HRA under Income Tax Act, 1961:`}
      />
      <CustomList
        points={[
          "Proof of rent payment - Rental agreement, or bank statements if no rental agreement.",
          "If the annual rent paid exceeds Rs. 100,000, your landlord must provide a PAN card.",
          "Employees should give a self-declaration form if the landlord did not have a PAN card.",
        ]}
      />
      <Step
        number={`
          HRA EXEMPTION CALCULATION
        `}
      />
      <Content paragraph="An employee's HRA is exempt up to the least amount of the following:" />
      <ReusableTable data={hraExemptionCalculationData} />
      <Content
        paragraph={`
          * Salary = Basic Salary + Conditional Dearness Allowance + 
          Commission received as a percentage of Turnover
          ** Relevant period means the period during which the said 
          accommodation was occupied by the assessee during the previous year.
        `}
      />
      <Step
        number={`
          For example- Mr. Ram, a salaried individual from Mumbai. 
          He pays Rs.20,000 per month in rent for his leased apartment. 
          This works out to Rs. 2.4 lakh per year. 
          His monthly earnings are listed below.
        `}
      />
      <ReusableTable data={taxableHRAData} />
    </Box>
  );
};

export default HraComponent;
