import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import { ThemeProvider } from '@mui/material';
import { theme } from './theme'
import { BrowserRouter } from 'react-router-dom';
import { createStore, applyMiddleware } from "redux";
import thunk from "redux-thunk";
import { Provider } from "react-redux";
import { reducers } from "./Reducers";
import { composeWithDevTools } from "redux-devtools-extension"


const userData = JSON.parse(localStorage.getItem("access_token"));

const initialState = {
  user: {
    authData: userData,
    isLoading: false,
  }
}

const store = createStore(
  reducers,
  initialState,
  composeWithDevTools(applyMiddleware(thunk))
);



const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(

  <Provider store={store}>
    <ThemeProvider theme={theme}>
      <BrowserRouter>
        <App />
      </BrowserRouter>
    </ThemeProvider>
  </Provider>,

);


