import React from "react";
import { Box } from "@mui/material";
import Mainhead from "../../../Components/Services/MainContent/Mainhead";
import Content from "../../../Components/Services/MainContent/Content";
import CustomList from "../../../Components/Income-Tax/CustomList";
import Step from "../../../Components/Services/MainContent/Step";

const LifeInsuranceTaxability = () => {
  return (
    <Box
      style={{
        display: "flex",
        flexDirection: "column",
        gap: 4,
        marginBottom: "2rem",
      }}
    >
      <Mainhead heading="Taxability of Life Insurance Policies" />
      <Content paragraph="The taxability of life insurance policies varies depending on several factors such as the premium amount, the policy type, and the benefits received. In general, the tax implications of life insurance policies in India can be understood as follows:" />

      <CustomList
        points={[
          "Premiums Paid: Premiums paid towards life insurance policies may be eligible for tax benefits under Section 80C of the Income Tax Act, up to a specified limit. This deduction is available for policies taken on the life of the individual, spouse, or children. However, the aggregate limit for various tax-saving investments under Section 80C applies.",
          "Maturity Proceeds: The maturity amount received from a life insurance policy is generally tax-exempt under Section 10(10D) of the Income Tax Act if the premium paid doesn't exceed certain limits specified in the law.",
          "Death Benefit: The death benefit received by the nominee or legal heir upon the demise of the policyholder is tax-free under Section 10(10D) of the Income Tax Act.",
          "Surrender or Partial Withdrawal: Any amount received upon surrender or partial withdrawal of the life insurance policy may be taxable based on the conditions and provisions specified in the Income Tax Act. However, the tax treatment varies depending on the time the policy was held and the premium paid.",
          "Rider Benefits: Additional benefits or riders attached to the life insurance policy may also have different tax implications based on the nature of the benefit provided.",
          "Taxation for Keyman Insurance: Keyman insurance policies, taken by a company on the life of its key employee, might have specific tax implications concerning the premium payments and benefits received.",
        ]}
      />

      <Content paragraph="It's important to note that the tax treatment of life insurance policies can be complex and can differ based on individual circumstances and changes in tax laws. Taxpayers are advised to consult with tax professionals or financial advisors for personalized guidance and to understand the specific tax implications of their life insurance policies. Additionally, reading and understanding the policy documents and tax laws related to life insurance can help in making informed decisions." />

      <Mainhead heading="Life Insurance Premiums Taxability" />
      <Content paragraph="Life Insurance premiums can be taxable under the head 'Income from Other Sources' under specific circumstances. Here are some factors when LIC premiums might be considered taxable:" />

      <CustomList
        points={[
          "Premiums exceeding a certain percentage of Sum Assured: If the premium paid for an insurance policy issued after April 1, 2012, exceeds 10% of the sum assured in any year during the policy term, the maturity proceeds might become taxable under Section 10(10D).",
          "Policy Surrender or Partial Withdrawal: If a policyholder surrenders the policy or makes partial withdrawals, and the amount received exceeds the total premiums paid, the excess amount might be taxable. The tax treatment depends on factors such as the time the policy was held and the premiums paid.",
          "Policies not meeting the conditions of Section 10(10D): Policies that fail to meet the conditions specified under Section 10(10D) might become taxable upon maturity or surrender, especially if the premiums paid exceed the specified percentage of the sum assured.",
          "Keyman Insurance Policies: Keyman insurance policies taken by a company on the life of a key employee may have different tax implications concerning premium payments and benefits received. The taxation can be based on the purpose and nature of the policy.",
          "Taxation on Accrued Bonuses: If a policyholder receives accrued bonuses, these might be considered as income and become taxable under the head 'Income from Other Sources'.",
          "Inadequate Disclosure of Premiums Paid: In cases where there is inadequate disclosure of premium payments or discrepancies in reporting while filing tax returns, tax authorities might scrutinize the details, and if found inconsistent, could lead to potential taxation.",
        ]}
      />

      <Content paragraph="It's crucial to understand that while life insurance policies often offer tax benefits on premiums paid and on the maturity amount under specified conditions, there are instances where taxation can apply, particularly if certain criteria or conditions aren't met. The tax treatment can vary based on the policy type, premium amounts, duration, and compliance with tax regulations outlined in the Income Tax Act." />
      <Step
        number={`
            Section 10(10D) of Income Tax Act, 1961:
          `}
      />
      <Content paragraph="The concept of premiums exceeding a certain percentage of the sum assured pertains to the tax treatment of life insurance policies' maturity proceeds under Section 10(10D) of the Income Tax Act in India. According to this provision, if the premiums paid for a life insurance policy exceed a certain percentage of the sum assured, the maturity proceeds might become taxable." />

      <Step
        number={`
            Example Illustration:
          `}
      />
      <Content paragraph="Let's consider an individual, Mr. X, who has a life insurance policy issued after April 1, 2012. The sum assured under this policy is Rs. 10,00,000 (10 lakhs)." />
      <CustomList points={["Premium paid in a year: Rs. 1,20,000"]} />
      <Content paragraph="In this scenario, the premium paid in a particular year exceeds 10% of the sum assured:" />
      <CustomList
        points={[
          "10% of Sum Assured = 10% of Rs. 10,00,000 = Rs. 1,00,000",
          "Premium paid in the year = Rs. 1,20,000 (which is higher than Rs. 1,00,000, i.e., 10% of the sum assured)",
        ]}
      />
      <Content paragraph="As per the provisions of Section 10(10D):" />
      <CustomList
        points={[
          "If the premiums paid in any year during the policy term exceed 10% of the sum assured for policies issued after April 1, 2012, the maturity proceeds might become taxable.",
        ]}
      />
      <Content paragraph="In this case, if Mr. X continues to pay premiums exceeding 10% of the sum assured in any subsequent year, the maturity proceeds of the policy might be subject to taxation under the Income Tax Act." />
      <Content paragraph="It's essential to monitor the premium payments concerning the sum assured to ensure that the policy remains compliant with the specified limits. If the premiums exceed the threshold, the tax benefits on the maturity proceeds might be impacted, and the maturity amount might be taxable under Section 10(10D) of the Income Tax Act." />
      <Content paragraph="This provision aims to ensure that insurance policies primarily serve the purpose of risk coverage and protection, rather than being used solely for tax-saving purposes by keeping premiums disproportionately lower than the sum assured." />
    </Box>
  );
};

export default LifeInsuranceTaxability;
