import React from 'react';
import Mainhead from '../../../../Components/Services/MainContent/Mainhead';
import Content from '../../../../Components/Services/MainContent/Content';
import List from '../../../../Components/Services/MainContent/List';
import Step from '../../../../Components/Services/MainContent/Step';
import { Box } from '@mui/material';


function Steps() {
  return (
    <div>
        <Mainhead heading="Steps Involved in Renewal of Trademark in India"/>
        <ul className='list'>
            <li className='bullet'><span><Content paragraph={[<b style={{color:'#815102'}}>Trademark Search: </b>,"Before renewing a trademark, it is important to ensure that there is no similar or identical mark that has been registered or filed for registration. On the webpage of the Indian Trademark Office, a brand inquiry can be done."]}/></span></li>
            <li className='bullet'><span><Content paragraph={[<b style={{color:'#815102'}}>Application for Renewal:</b>, "the renewal application may be submitted either six months before the trademark expires or six months after it has expired. The registration can be submitted online through the website of the Indian Trademark Office."]}/></span></li>
            <li className='bullet'><span><Content paragraph={[<b style={{color:'#815102'}}>Review your application:</b>,"The Trademark Office will review the renewal application after it has been submitted to make sure that all required information has been given."]}/></span></li>
            <li className='bullet'><span><Content paragraph={[<b style={{color:'#815102'}}>Trademark Journal:</b>,"The brand will be promoted in the Trademark Journal following the review of the renewal application."]}/></span></li>
            <li className='bullet'><span><Content paragraph={[<b style={{color:'#815102'}}>Renewal certificate:</b>,"A renewal certificate will be given by the Trademark Office if there is no  opposition"]}/></span></li>
            <li className='bullet'><span><Content paragraph={[<b style={{color:'#815102'}}>Fee payment:</b>,"Fees must be paid in order to complete the renewal procedure, which is the last stage."]}/></span></li>
           
        </ul>
    </div>
  )
}

export default Steps