import * as React from 'react';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Divider } from '@mui/material';
import Content from '../../../../Components/Services/MainContent/Content';
import Mainhead from '../../../../Components/Services/MainContent/Mainhead';

export default function FAQ() {
  const [expanded, setExpanded] = React.useState(false);

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  return (
    <div>
      <Mainhead heading="FAQ'S"/>
      <br/>
      <Divider sx={{border: '1px solid #FF990080'}}/>
      <Accordion expanded={expanded === 'panel1'} onChange={handleChange('panel1')}  elevation={0} sx={{ boxShadow: 'unset !important' }} disableGutters>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1bh-content"
          id="panel1bh-header"
        >
          <Typography  sx={{color:'#281900',fontWeight:450,fontSize:'20px'}}>Is there a need to be physically present during this process ?</Typography>
        </AccordionSummary>
        <AccordionDetails>
         <Content paragraph ="No, the entire process is online and thus no physical presence is required. The Government of India in its efforts to streamline and promote ease of doing business has made the entire process through its portal"/>
            
         
        </AccordionDetails>
      </Accordion>
      <Divider sx={{border: '1px solid #FF990080'}}/>
      <Accordion expanded={expanded === 'panel2'} onChange={handleChange('panel2')}
       elevation={0} sx={{ boxShadow: 'unset !important' }} disableGutters>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel2bh-content"
          id="panel2bh-header"
        >
          <Typography sx={{color:'#281900',fontWeight:450,fontSize:'20px'}}>
          What is DPIN?
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
         <Content paragraph="Designated Partner Identification Number is a unique identification number that is assigned to all existing and proposed Designated partners of an LLP. All the present or proposed Directors must have a DPIN."/>
        </AccordionDetails>
      </Accordion>
      <Divider sx={{border: '1px solid #FF990080'}}/>
      <Accordion expanded={expanded === 'panel3'} onChange={handleChange('panel3')}
       elevation={0} sx={{ boxShadow: 'unset !important' }} disableGutters>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel3bh-content"
          id="panel3bh-header"
        >
          <Typography sx={{color:'#281900',fontWeight:450,fontSize:'20px'}}>
          How long does it take to incorporate LLP?
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
         <Content paragraph="The time taken for incorporation depends on the submission of relevant documents by the client as well as the Approvals from the Government authorities. Legal Factory can help you Incorporate an LLP in 14-20 days."/>
        </AccordionDetails>
      </Accordion>
      <Divider sx={{border: '1px solid #FF990080'}}/>
      <Accordion expanded={expanded === 'panel4'} onChange={handleChange('panel4')}
       elevation={0} sx={{ boxShadow: 'unset !important' }} disableGutters>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel4bh-content"
          id="panel4bh-header"
        >
          <Typography sx={{color:'#281900',fontWeight:450,fontSize:'20px'}}> Does this LLP require MoA and AoA?</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Content paragraph="No, the Memorandum of Association (MOA) and the Articles of Association (AOA) are important documents of a company registered under the Companies Act, 2013. The LLP agreement governs the LLP and not the MOA and AOA. Thus, an LLP does not have to draft the MOA and AOA. It has to draft the LLP agreement."/>
        </AccordionDetails>
      </Accordion>
      <Divider sx={{border: '1px solid #FF990080'}}/>
      <Accordion expanded={expanded === 'panel5'} onChange={handleChange('panel5')}
       elevation={0} sx={{ boxShadow: 'unset !important' }} disableGutters>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel5bh-content"
          id="panel5bh-header"
        >
          <Typography sx={{color:'#281900',fontWeight:450,fontSize:'20px'}}> Who can be partners in LLP</Typography>
        </AccordionSummary>
        <AccordionDetails>
         <Content paragraph="Any individual or body corporate can be a partner in an LLP. However, minors, persons of unsound mind and an undischarged insolvent cannot be partners in an LLP."/>
          </AccordionDetails>
          </Accordion>


          <Divider sx={{border: '1px solid #FF990080'}}/>
      <Accordion expanded={expanded === 'panel6'} onChange={handleChange('panel6')}
       elevation={0} sx={{ boxShadow: 'unset !important' }} disableGutters>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel6bh-content"
          id="panel6bh-header"
        >
          <Typography sx={{color:'#281900',fontWeight:450,fontSize:'20px'}}>How many designated partners are required in an LLP?</Typography>
        </AccordionSummary>
        <AccordionDetails>
         <Content paragraph="Every LLP must have at least two designated partners, and at least one of them should be a resident in India. If all partners in an LLP are body corporates, then at least two individual nominees of such body corporates should act as designated partners. Any partner can be a designated partner in accordance with the LLP agreement."/>
          </AccordionDetails>
          </Accordion>

          <Divider sx={{border: '1px solid #FF990080'}}/>

    </div>
  );
}