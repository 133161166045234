import { makeStyles } from "@mui/styles";

export default makeStyles((theme) => ({
outerDiv:{
width:"100%",
borderRadius:"0px",
boxShadow: 'rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;'

},

heading:{
    backgroundColor:"#3D2C02",
    display:"flex",
    justifyContent:"center",
    //borderRadius:"20px"

},
contentDiv:{
    display:"flex",
    flexDirection:"column",
    backgroundColor:"white",
    padding:"3%",
    justifyContent:"space-between",
    //borderRadius:"20px"
},
CheckboxDiv:{
display:"flex"
},
buttonDiv:{
    display:"flex"
}
}));
