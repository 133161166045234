import React from 'react';
import { Typography,styled, useTheme } from '@mui/material';

const Head = styled(Typography)(({ theme }) => ({
    fontStyle: 'normal',
    fontWeight: 450,
    color: '#281900',
    [theme.breakpoints.up("lg")]: {
        fontSize: '1.1rem',
    },
    [theme.breakpoints.up("xl")]: {
        fontSize: '1.5rem',
    },
    [theme.breakpoints.between("md", "lg")]: {

    },
    [theme.breakpoints.down("md")]: {
        fontSize: '1rem',
    },
    [theme.breakpoints.down("sm")]: {
        fontSize: '0.875rem',
    },

}));


function Q(props) {
    return (
      <div>
          <Head>
            {props. Q}
          </Head>
      </div>
    )
  }
  
  export default Q;