import axios from "axios";
import qs from "qs";

const sid = process.env.REACT_APP_SID;
const token = process.env.REACT_APP_TOKEN;

const baseURL = "https://api.twilio.com/2010-04-01/Accounts/";

const API = axios.create({ baseURL });

export const sendWhatsAppSMSAPI = (number) => {
  API.post(
    sid + "/Messages.json",
    qs.stringify({
      Body: "TEST TEST TEST",
      From: "whatsapp:+14155238886",
      To: `whatsapp:+91${number}`,
    }),
    {
      auth: {
        username: sid,
        password: token,
      },
    }
  );
};
