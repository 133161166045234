import React from "react";
import { Box } from "@mui/material";
import Mainhead from "../../../Components/Services/MainContent/Mainhead";
import Step from "../../../Components/Services/MainContent/Step";
import Content from "../../../Components/Services/MainContent/Content";

const Section80CCD = () => {
  return (
    <Box
      style={{
        display: "flex",
        flexDirection: "column",
        gap: 4,
        marginBottom: "2rem",
      }}
    >
      <Mainhead heading="Section 80CCD - Deduction for contribution to pension scheme" />

      <Step
        number={`
          WHAT IS SECTION 80CCD OF INCOME TAX ACT,1961 IN INDIA?
        `}
      />
      <Content
        paragraph={`
          Section 80CCD provides deduction in respect of contribution made to the pension scheme notified by the Central Government. Accordingly, in exercise of the powers conferred by section 80CCD(1), the Central Government has notified the Atal Pension Yojana (APY) as a pension scheme, contribution to which would qualify for deduction under section 80CCD in the hands of individuals.
        `}
      />

      <Step
        number={`
          WHAT IS THE QUANTUM OF DEDUCTION AVAILABLE IN SECTION 80CCD AS PER INCOME TAX ACT,1961?
        `}
      />
      <Content
        paragraph={`
          (a) Section 80CCD(1) provides a deduction for the amount paid or deposited by an employee in his pension account subject to a maximum of 10% of his salary. The deduction in the case of a self-employed individual would be restricted to 20% of his gross total income in the previous year.
        `}
      />
      <Step number={`Eligible assessee`} />
      <Content
        paragraph={`
        An individual employed 
        by CG on or after 
        01.01.2004 - 10% of salary     `}
      />
      <Content
        paragraph={`
        An Individual employed 
by any other employer - 10% of salary     `}
      />
      <Content
        paragraph={`
        Any other individual - 20% of GTI     `}
      />

      <Content
        paragraph={`
          (b) Section 80CCD(1B) provides for an additional deduction of up to Rs. 50,000 in respect of the whole of the amount paid or deposited by an individual assessee under NPS in the previous year, whether or not any deduction is allowed under section 80CCD(1).
        `}
      />

      <Content
        paragraph={`
          (c) Whereas the deduction under section 80CCD(1) is subject to the overall limit of Rs. 1.50 lakh under section 80CCE (i.e., the maximum permissible deduction under sections 80C, 80CCC and 80CCD(1) put together is Rs. 1,50,000), the deduction of up to Rs. 50,000 under section 80CCD(1B) is in addition to the overall limit of Rs. 1.50 lakh provided under section 80CCE.
        `}
      />

      <Content
        paragraph={`
          (d) Under section 80CCD(2), contribution made by the Central Government or State Government or any other employer in the previous year to the said account of an employee, is allowed as a deduction in computation of the total income of the assessee.
        `}
      />

      <Content
        paragraph={`
          (e) The entire employer‘s contribution would be included in the salary of the employee. However, deduction under section 80CCD(2) would be restricted to 14% of salary, in case of contribution made by the Central Government or State Government, and to 10% of salary, in case of contribution made by any other employer.
        `}
      />

      <Content
        paragraph={`
          Note: If he pays tax under the optional tax regime – the normal provisions of the Act).
        `}
      />

      <Step
        number={`
          Deemed income:
        `}
      />
      <Content
        paragraph={`
          The amount standing to the credit of the assessee in the pension account (for which deduction has already been claimed by him under this section) and accretions to such account, shall be taxed as income in the year in which such amounts are received by the assessee or his nominee on -
        `}
      />

      <Content
        paragraph={`
          - Closure of the account or
          - His opting out of the said scheme or
          - Receipt of pension from the annuity plan purchased or taken on such closure or opting out.
        `}
      />

      <Content
        paragraph={`
          However, the amount received by the nominee on the death of the assessee under the circumstances referred to in (a) and (b) above, shall not be deemed to be the income of the nominee.
        `}
      />

      <Content
        paragraph={`
          Further, the assessee shall be deemed not to have received any amount in the previous year if such amount is used for purchasing an annuity plan in the same previous year.
        `}
      />
    </Box>
  );
};

export default Section80CCD;
