import React from 'react';
import Sidecomponent from '../../../Components/Tax-Planning/Sidecomponent';
import Topdiv from "../../../Components/Tax-Planning/Topdiv"
import Dividend from '../../../Assets/TaxPlanning/OtherSource/Dividend.png';
import GetInTouch from '../../../Components/Services/GetInTouch'
import {

  Grid, Box, Container, styled, Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Link
} from '@mui/material';


import Mainhead from '../../../Components/Services/MainContent/Mainhead';
import Content from '../../../Components/Services/MainContent/Content';
import List from '../../../Components/Services/MainContent/List';
import Step from '../../../Components/Services/MainContent/Step';

import TableCell, { tableCellClasses } from '@mui/material/TableCell';

function Othersource() {
  const Image  = styled(Box)(({ theme }) => ({
    marginTop:'30px',
    width:'75%',
    marginBottom:'3rem',
    justifyContent:'center',
    [theme.breakpoints.down("md")]: {
      marginTop:'20px',
      width:'100%',
    }
  }));

  const Bottomspace = styled(Box)(({ theme }) => ({
    marginBottom:'2.1rem'
  }));


  return (
    <div>
      <Topdiv heading="Are you looking to file your Income Tax Returns ?"/>
      <div className='Container'>
      <Container >
        <Grid container>
          <Grid item  lg={4} xl={3}>
            <Box sx={{ display: { xs: 'none', lg: 'block' }, marginTop: '4rem', position: 'sticky', top: 90, marginBottom: '3rem' }}>
              <Sidecomponent />
            </Box>
          </Grid>
          <Grid item xs={11} lg={8} sx={{ marginTop: '4rem' }} xl={9}>
            <Mainhead heading="Income From Other Sources" />
            <Content paragraph="Any income which is not taxable under salary,Income from house property,capital gain, that income shall be taxable under income from other sources. Items taxable under the head “IFOS”"/>
            <Content paragraph="1.Interest on securities (if securities held as stock-in-trade,then interest taxable under PGBP)"/>
            <Content paragraph="2.Rent for letting house out of plant,machinery,furniture (if not taxable under PGBP)"/>
            <Content paragraph="3.Dividend"/>
            <Content paragraph="4.Winning from lotteries ,puzzles,card games,etc."/>
            <Content paragraph="5.Interest on bank deposit & loan given"/>
            <Content paragraph="6.Royalty income"/>
            <Content paragraph="7.Directors sitting fees"/>
            <Content paragraph="8.Agriculture income"/>
            <Content paragraph="9.Income from sub-letting of house property"/>
            <Content paragraph="10.Salary of MP/MLA"/>
            <Content paragraph="11.Interest on income tax refund."/>
             <Content paragraph="12.Deemed dividend"/> 
             <Content paragraph="13.Income on any investment"/>
             <Content paragraph="14.Amount received under family pension ( deduction u/s 57)
Allowed : (i) ⅓ of family pension (ii) rs.15000"/>
             <Content paragraph="15Interest on compensation of compulsory acquisition of capital asset ( 50% deduction allowed u/s 57)."/>
             <Content paragraph="16.Gift etc."/>\
             <Bottomspace/>

             <Mainhead heading="Dividend"/>
             <Content paragraph="Under the current tax laws in India, dividends are no longer subject to dividend distribution tax (DDT) or corporate dividend tax (CDT). Instead, dividends are taxable in the hands of the shareholder as normal income. ALSO TDS U/S 194 IS DEDUCTED ON THE SAME"/>
             <Content paragraph="If you are a resident individual, dividends are taxed at the applicable tax slab rate. If you are a non-resident individual or a foreign company, dividends are taxed at a flat rate of 20%."/>
             <Content paragraph="It's worth noting that the taxability of dividends may vary depending on the country in which the dividends are paid and the tax treaty between India and that country. It's always a good idea to consult with a tax professional or seek advice from the relevant tax authorities to understand the tax implications of dividends in your specific situation."/>
<Bottomspace/>
<Box sx={{textAlign:'center'}}>
<Image component="img"
                sx={{
                  content: {
                    xs: `url(${Dividend})`
                  }
                }}/>
</Box>
<br/><br/>
<Mainhead heading="Taxation of Gifts"/>
<Step number="Sec. 56(2) (x) : purchase / gifts received by any person"/>
             <Content paragraph="The following shall be taxable under the head “ income from other sources”: ‘Where any person receives, in any PY, from any person or person on or after the 1st day of April 2017-"/>
             <Content paragraph="(a)Any sum of Money, without consideration , the Aggregate value of which exceed rs.50,000 , the whole of the aggregate value of such sum ;
(b)Any immovable property ,"/>
             <Content paragraph="(i) without any consideration , the slump duty value of which exceed rs.50,000, the slump duty value of such property ;
(ii)for a consideration,the stamp duty value of such property as exceed such consideration , if the amount of such excess is more than the higher of following amounts , namely : -"/>
             <Content paragraph="(i)the amount of fifty thousand rupees;and
(ii) the amount equal to ten percent of the considerationProvided that where the date of the agreement fixing the amount of consideration for the transfer of immovable property and the date of registration are not the same, the stamp duty value on the date of agreement may be taken for the purpose of this sub-clause."/>
<br/>
<Step number="Provided further"/>
             <Content paragraph="Provided further that the provision of the first proviso shall apply only in a case where the amount of consideration referred to therein, has been paid by way of an account payee cheque or an account payee bank draft or by use of electronic clearing system through a bank account or through such other electronic mode as may be prescribed, on or before the date of agreement for transfer of such immovable property ."/>
             <Bottomspace/>

             <Step number="Provided also"/>
             <Content paragraph="Provided also that where the stamp duty value of immovable property is disputed by the assessee on grounds mentioned in sub-section (2) of section 50C, the Assessing officer may refer the valuation of such property to valuation officer, and the provision of section 50C and u/s 155(15) shall,as far as may be, apply in a relation to the stamp duty value of such property for the purpose of this sub-clause as they apply for valuation of capital asset under those sections"/>
             <Step number="Provided also that in case of property being referred to in the second proviso to sub-section (1) of section 43CA,the provision of sub-items(ii) of items (ii) shall have effect as if for the words “ten per cent”,the words “twenty per cent”had been substitute ( Added by FA 21 w.e.f. AY 21-22)"/>
             <Content paragraph="(2) any property (S.S.J.D.P.S.A.O.B) other then immovable property,
(i) without consideration,the aggregate fair market value of which exceed rs.50,000 the whole of the aggregate value fair market value of such property;
(ii) for consideration which is less than the aggregate fair market value of the property by an amount exceeding rs.50,000, the aggregate fair market value of such property as exceed such consideration;"/>
<Bottomspace/>

<Step number="Provided further that this clause shall not apply to any sum of money or any property received ( referred as a specified person):"/>
             <Content paragraph="1.From any relative"/>
             <Content paragraph="2.On the occasion of marriage of individual;or"/>
             <Content paragraph="3.Under a will or by way of inheritance ; or"/>
             <Content paragraph="4.In contemplation of death of payer or donor, as the case may be; or"/>
             <Content paragraph="5.From any of local authority as defined u/s section 10(20); or"/>
             <Content paragraph="6.From any fund or foundation or university or other educational institution or hospital or other medical institution or any trust or institution referred to in section 10(23); or"/>
             <Content paragraph="7.From or by any trust or institution registered under section 12A or 12AA or 12AB"/>
             <Content paragraph="8.By any fund or trust or institution or any university or other educational institution or any hospital or other medical institution referred to in sub-clause (iv) or sub-clause (v) or sub-clause (vi) or sub-clause (via) of clause (23C) of section 10;or"/>
             <Content paragraph="9.By way of transaction not regarded as transfer under clause (i) or clause (iv) or clause (v) or clause (vi) or clause (via) or clause (viaa) or clause (vib) or clause (vic) or clause (vica) or clause (vicb) or clause (vid) or clause (vii) or clause (viiac) or clause (viiad) or clause (viiae) or clause (viiaf) of section 47; or"/>
             <Content paragraph="10.From an individual by a trust created or established solely for the benefit of relative of the individual."/>
             <Bottomspace/>

             <Step number="Explanation : for the purpose of this clause"/>
             <Content paragraph="(a)“Assessable” shall have the meaning assigned to it in the explanation 2 to sub-section (2) of section 50C;"/>
             <Content paragraph="(b)“Fair market value” of a property,other than an immovable property,means the value determined in accordance with the method as may be prescribed;"/>
             <Content paragraph="(c)For the purpose of this sub-clause, “jewellery” includes"/>  
             <ul className='list'>
              <li className='bullet'>
                <span>
             <List points="ornaments made of gold,silver,platinum or any other precious metals, whether or not containing any precious or semi-precious stone,and whether or not worked or sewn into any wearing apparel;"/>
             </span>
             </li>
             <li className='bullet'>
                <span>
             <List points="precious or semi-precious stones, whether or not set in any furniture,utensil or other article or worked or sewn into any wearing apparel;"/>
             </span>
             </li>
             </ul>
<Bottomspace/>
<Mainhead heading="“Property”"/>
<Content paragraph="“Property” means the following capital asset of the assessee ,namely: (i) immovable property being a land or building or both;"/>
<Content paragraph="(ii) shares and securities ;"/>
<Content paragraph="(iii) jewellery;"/>
<Content paragraph="(iv) archaeological collections;"/>
<Content paragraph="(v) drawings;"/>
<Content paragraph="(vi) painting;"/>
<Content paragraph="(vii) sculptures;"/>
<Content paragraph="(viii) any work of art; or"/>
<Bottomspace/>
<Mainhead heading="Clarification from Finance Ministry"/>
<Content paragraph="sec.56(2)(x) shall apply if property is in nature of “capital Asset” in hands of recipient. If property is stock-in-trade,raw material and consumable store of business in hands of recipient,then section sec.56(2)(x) has no application

For the purpose of this clause , “relative” means:"/>
<Step number="In case of individual"/>
<ul className='list'>
  <li className='bullet'>
    <span>
<List points="Spouse of the individual;"/>
</span>
</li>
<li className='bullet'>
                <span>
<List points="Brother or sister of the individual;"/>
</span>
</li>
<li className='bullet'>
          <span>
<List points="Brother or sister of the spouse of individual;"/>
</span>
</li>
<li className='bullet'>
                <span>
<List points="Brother or sister of either of the parents of the individual"/>
</span>
</li>
<li className='bullet'>
                <span>
<List points="Brother or sister of either of the parents of the individual"/>
</span>
</li>
<li className='bullet'>
                <span>
<List points="Any lineal ascendant or descendant of the spouse of the individual"/>
</span>
</li>
<li className='bullet'>
                <span>
<List points="Spouse of the person referred to in clauses (2) to (6)"/>
</span>
</li>
</ul>
<Bottomspace/>
<Step number="In case of HUF"/>
<Content paragraph="Stamp duty value” means the value adopted or assessed or accessible by any authority of the central government or a state government for the purpose of payment of stamp duty in respect of an immovable property."/>
<Content paragraph="Accordingly, CBDT has, vide Notification No.42/2020, dated 29th june, 2020, notification that the provision of section 56(2)(x) would not be applicable to the following transaction : -"/>
<Bottomspace/>
<Mainhead heading="Sec. 49(4) : Cost of Acquisition"/>
<Content paragraph="Where the capital gain arises from the transfer of a property, the value of which has been subject to income-tax under section 56(2) (vii) or 56(2)(viia) or 56(2)(x), the cost of acquisition of such property shall be deemed to be the value which has been taken into account for the purpose of the said clause (vii) or clause (viia) or clause (x)."/>
<Bottomspace/>
<Mainhead heading="Special provision for full value or consideration for transfer of Stock in Trade Sec. 43CA"/>
<Content paragraph="(1) Where the consideration received or accruing as a result of the transfer by an assessee of an asset (other than a capital asset) , being a land or building or both, is less than the value adopted or assessed or assessable by an authority of a state Government for the purpose of payment of stamp duty in respect of such transfer, the value so adopted or assessed or assessable shall, for the purpose of computing profit and gains from transfer of such asset, be deemed to be the full value of the consideration received or accruing as a result of such transfer."/>
<Content paragraph="Provided that where the value adopted or assessed or assessable by the authority for the purpose of payment of stamp duty does not exceed one hundred and ten per cent of the consideration received or accruing as a result of the transfer , the consideration so received or accruing as a result of the transfer shall, for the purpose of computing profits and gains from transfer of such asset, be deemed to be the full value of the consideration."/>
<Content paragraph="Provided further that in case of transfer of an asset, being a residential unit, the provision of this proviso shall have the effect as if for the words “one hundred and ten per cent”, the words “one hundred and twenty per cent” has been substituted, if the following condition are satisfied, namely:-"/>
<List points=" the transfer of such residential unit takes place during the period beginning from the 12th day of November, 2020 and ending on the 30th day of June, 2021;"/>
<ul className='list'>
  <li className='bullet'>
    <span>
<List points="such transfer is by way of first time allotment of the residential unit to any person; and"/>
</span>
</li>
<li className='bullet'>
    <span>
<List points=" the consideration received or accruing as a result of such transfer does not exceed two crore rupees."/>
</span>
</li>
</ul>
<br/>
<Step number="Explanation - "/>
<Content paragraph=" For the purpose of this section, “residential unit” means an independent housing unit with separate facilities for living, cooking and sanitary requirement, distinctly separated from other residential units within the building, which is directly accessible from an outer door or through an interior door in a shared hallway and not by walking through the living space of another household"/>
<Content paragraph="(2)The provision of sub-section (2) and sub-section (3) of section 50C shall, so far as may be, apply in a relation to determination of the value adopted or assessed or assessable under sub-section (1)."/>
<Content paragraph="(3)Where the date of agreement fixing the value of consideration for transfer of the asset and the date of registration of such transfer of asset are not the same, the value referred to in sub-section (1) may be taken as the value assessable by any authority of a state government for the purpose of payment of stamp duty in respect of such transfer on the date of the agreement."/>
<Content paragraph="(4)The provision of sub-section (3) shall apply only in case where the amount of consideration or a part thereof has been received by way of an account payee cheque or an account payee bank draft or by use of electronic clearing system through a bank account or through such other electronic mode as may be prescribe on or before the date of agreement for transfer of the asset."/>
<Bottomspace/>
<Mainhead heading="Shares issued on premium by closely held company sec.56(2)(viib)"/>

<Content paragraph="1.Where a company, not being a company in which the public are substantially interested, receives."/>
<Content paragraph="2.In any previous year, from any person being a resident,"/>
<Content paragraph="3.Any consideration for issue of shares that exceed the face value of such shares,"/>
<Content paragraph="4.The aggregate consideration received for such shares that exceeds the fair market value of the shares: Shall be taxable in hands of company under IFOS."/>
<Bottomspace/>
<Content paragraph="Provided that this clause shall not apply where the consideration for issue of shares is received- (i) by a venture capital undertaking from a venture capital company or venture capital fund or a specified fund; or
(ii) by a company from a class or classes of person as may be notified by the central Government in this behalf"/>
<Content paragraph="Provided further that where the provision of this clause have not been applied to a company on account of fulfilment of condition specified in the notification issued under clause (ii) of the first proviso and such company fails to comply with any those conditions,then, any consideration received for issue of share that exceeds the fair market value of such share shall be deemed to be the income of that company chargeable to income-tax for the previous year in which such failure has taken place and, it shall also be deemed that the company has under reported the said income in consequence of misreporting referred to in sub-section (8) and sub-section (9) of section 270A for the said previous year."/>
<Content paragraph="Explanation : For the purpose of this clause, (i) As may be determined in accordance with such method as may be prescribed; or
(ii) as may be substantiated by the company to the satisfaction of the Assessing Officer, based on the value, on the date of issue of shares, of its assets, including intangible assets being goodwill, know-how, patents, copyrights, trademark, licences, franchises, or any other business or commercial rights of similar nature,"/>
<Step number="Whichever is higher."/>
<Content paragraph="(aa) “specified fund” means a fund established or incorporated in india in the form of a trust or a company or a limited liability partnership or a body corporate which has been granted a certificate of registration as a category I or category II Alternative
Investment Fund and is regulated under the SEBI ( Alternative Investment Fund ) Regulation, 2012 made under the SEBI Act,1992;
(ab) “trust” means a trust established under the Indian Trusts Act,1882 or under any law for the time being in force
(b) “venture capital company” , “venture capital fund” and “venture capital undertaking” shall have the meaning respectively assigned to them in section 10(23FB)."/>
<Bottomspace/>
<Mainhead heading="Sec.56(2)(xi): Compensation on termination of employment"/>
<Content paragraph="Any compensation or other payment, due or received by any person ( by whatever name called) in connection with termination of his employment ( or modification of terms of employment ) is treated as income under section 56(2)(xi). This section is applicable only if compensation is received from a person other than employer. However, if it is received from employer, then it is taxable as profit in lieu of salary under section 17(3) under the head “salaries” ."/>
<Bottomspace/>
<Mainhead heading="Expenses not allowed as deduction under IFOS SEC.58"/>
<ul className='list'>
  <li className='bullet'>
    <span>
<List points="Any personal expenses of the assessee."/>
</span>
</li>
<li className='bullet'>
    <span>
<List points="Any interest chargeable under this Act which is payable outside india on which TDS has not been paid or deduct."/>
</span>
</li>
<li className='bullet'>
    <span>
<List points="Any payment which is chargeable under the head “salaries” , if it is payable outside india, unless TDS has been paid thereon or deducted."/>
</span>
</li>
<li className='bullet'>
    <span>
<List points="Cash expenditure exceeding 10,000. Provision of section 40A(3) shall apply."/>
</span>
</li>
<li className='bullet'>
    <span>
<List points="30% of any sum payable to a resident on which TDS has not been paid or deducted at sources. Provision of section 40(a)(ia) shall apply."/>
</span>
</li>
</ul>
<Bottomspace/>
<Mainhead heading="Deductions"/>
<Content paragraph="The income chargeable under the head “ income from other sources” shall be computed after making the following deduction, namely : -"/>
<Step points=" (i)in the case of dividends or interest on securities, any reasonable sum paid by way of commission or remuneration to a banker or any other person for the purpose of realising such dividend or interest on behalf of the assessee;"/>
<Step points=" (ii)in the case of income in the nature of family pension, a deduction of a sum equal to thirty-three and one-third per cent of such income or fifteen thousand rupees, whichever is less."/>
<Step points="Explanation - for the purpose of this clause, “family pension” means a regular monthly amount payable by the employer to a person belonging to the family of an employee in the event of his death;"/>
<Step points="
(iii) any other expenditure (not being in the nature of capital expenditure) laid out or expended wholly and exclusively for the purpose of making or earning such income ;"/>
<Step points="(iv) in the case of income of the nature referred to in section 56(2)(viii),a deduction of a sum equal to fifty per cent of such income and no deduction shall be allowed under any other clause of this section."/>
<Bottomspace/>
<Mainhead heading="Following proviso shall be inserted in section 57 by the Finance Act,2020, w.e.f 1-4-2021 :"/>
<Content paragraph="Provided that no deduction shall be allowed from the dividend income, or income in respect of units of a Mutual Fund or UTI , other than deduction on account of interest expenses, and in any previous year such deduction shall not exceed twenty percent of the dividend income, or income in respect of such units, include in the total income for that year, without deduction under this section."/>
<br/><br/><br/>
            </Grid>
            </Grid>
            
            </Container>
    </div>
    </div>
  )
}

export default Othersource