import React from "react";
import { Box } from "@mui/material";
import Mainhead from "../../../Components/Services/MainContent/Mainhead";
import Content from "../../../Components/Services/MainContent/Content";
import CustomList from "../../../Components/Income-Tax/CustomList";
import Step from "../../../Components/Services/MainContent/Step";
import ReusableTable from "../../../Components/Income-Tax/ReusableTable";

const Section80DDB = () => {
  return (
    <Box
      style={{
        display: "flex",
        flexDirection: "column",
        gap: 4,
        "margin-bottom": "2rem",
      }}
    >
      <Mainhead heading="Deduction under Section 80DDB" />
      <Content paragraph="Taxpayers dealing with specific ailments for their dependents inquire about the process of obtaining a certificate to claim a deduction under Section 80DDB on their income tax return. Below is a discussion on this:" />
      <Step
        number={`
            Specifics of the Deduction permitted by Section 80DDB
          `}
      />
      <CustomList
        points={[
          "Allows deduction for the medical treatment of a dependent with designated diseases.",
          "Eligible for both individual and HUF claims.",
          "Permitted for Indian Residents.",
          "Applicable when the taxpayer has paid for the dependent's care.",
          "Dependents include parents, siblings, spouses, and children.",
          "Deduction reduced if the dependent is covered by insurance or receives reimbursement.",
        ]}
      />
      <Step
        number={`
            Applicability for Resident Individual or Resident HUF
          `}
      />
      <CustomList
        points={[
          "Expenditure incurred for medical treatment of specified diseases on the assessee and his family.",
          "Deduction amount: Lower of Rs. 40,000 or Rs. 1,00,000, or the amount actually paid.",
          "Deduction to be reduced by the amount received from the insurer or reimbursement by the employer against any insurance for medical treatment.",
          "Taxability: Obtain a prescription from a specialist doctor for the purpose of getting a deduction under 80DDB.",
        ]}
      />
      <Step
        number={`
            Specified Diseases
          `}
      />
      <CustomList
        points={[
          "Neurological diseases (mind)",
          "Cancer",
          "Aids",
          "Chronic renal failure (kidney-related)",
          "Hemophilia (excessive bleeding)",
          "Thalassemia (blood change)",
        ]}
      />
      <Step
        number={`
            Family Members Eligible
          `}
      />
      <ReusableTable
        data={[
          {
            member: "Spouse (dependent)",
            sec80C: "Yes",
            sec80D: "Yes",
            sec80DD: "Yes",
            sec80DDB: "Yes",
            sec80E: "Yes",
          },
          {
            member: "Spouse (independent)",
            sec80C: "Yes",
            sec80D: "Yes",
            sec80DD: "No",
            sec80DDB: "No",
            sec80E: "Yes",
          },
          {
            member: "Child (dependent)",
            sec80C: "Yes",
            sec80D: "Yes",
            sec80DD: "Yes",
            sec80DDB: "Yes",
            sec80E: "Yes",
          },
          {
            member: "Child (independent)",
            sec80C: "Yes",
            sec80D: "No",
            sec80DD: "No",
            sec80DDB: "No",
            sec80E: "Yes",
          },
          {
            member: "Brother / sister (dependent)",
            sec80C: "-",
            sec80D: "-",
            sec80DD: "Yes",
            sec80DDB: "Yes",
            sec80E: "-",
          },
          {
            member: "Brother / sister (independent)",
            sec80C: "-",
            sec80D: "-",
            sec80DD: "No",
            sec80DDB: "No",
            sec80E: "-",
          },
          {
            member: "Parents (dependent)",
            sec80C: "-",
            sec80D: "-",
            sec80DD: "Yes",
            sec80DDB: "Yes",
            sec80E: "-",
          },
          {
            member: "Parents (independent)",
            sec80C: "-",
            sec80D: "-",
            sec80DD: "No",
            sec80DDB: "No",
            sec80E: "-",
          },
          {
            member: "Other e.g. grandparents",
            sec80C: "-",
            sec80D: "-",
            sec80DD: "No",
            sec80DDB: "No",
            sec80E: "-",
          },
        ]}
      />
      <Step
        number={`
            Deduction Allowed
          `}
      />
      <ul className="list">
        <li className="bullet">
          From FY 2018-19 onwards (Assessment Year 2019-20 Onwards):
          <span>
            <li>
              Rs. 40,000/- or the amount actually paid, whichever is less.
            </li>
            <li>
              For senior citizens and super-senior citizens, Rs. 1,00,000 or
              amount actually paid, whichever is less.
            </li>
          </span>
        </li>
        <li className="bullet">
          From FY 2015-16 to FY 2017-18 (Assessment Year 2016-17 to Assessment
          Year 2018-19):
          <span>
            <li>
              Rs. 40,000/- or the amount actually paid, whichever is less.
            </li>
            <li>
              For senior citizens, Rs. 60,000 or amount actually paid, whichever
              is less.
            </li>
            <li>
              For very senior citizens, Rs. 80,000 is the maximum deduction that
              can be claimed.
            </li>
          </span>
        </li>
        <li className="bullet">
          For FY 2014-15 (Assessment Year 2015-16):
          <span>
            <li>
              Rs. 40,000/- or the amount actually paid, whichever is less.
            </li>
            <li>
              For senior citizens, Rs. 60,000 or amount actually paid, whichever
              is less.
            </li>
          </span>
        </li>
      </ul>
    </Box>
  );
};

export default Section80DDB;
