import React from "react";
import { Box } from "@mui/material";
import Mainhead from "../../../Components/Services/MainContent/Mainhead";
import Content from "../../../Components/Services/MainContent/Content";
import Step from "../../../Components/Services/MainContent/Step";
import CustomList from "../../../Components/Income-Tax/CustomList";
import { Image } from "../../../Components/Income-Tax/ImageStyle";
import Image8 from "../../../Assets/IncomeTax/ifb1.jpg";

const BooksOfAccounts = () => {
  return (
    <Box
      style={{
        display: "flex",
        flexDirection: "column",
        gap: 4,
        marginBottom: "2rem",
      }}
    >
      <Mainhead heading="Maintaining Books of Accounts" />

      <Step
        number={`
          The Income Tax Act's Section 44AA
        `}
      />
      <Content paragraph="Section 44AA of the Income Tax Act deals with the obligation to maintain books of accounts for certain designated individuals. It mandates the preparation and maintenance of books of accounts if the revenue, gross turnover, or receipts exceed the specified threshold limit." />
      <Box sx={{ textAlign: "center" }}>
        <Image
          component="img"
          sx={{
            content: {
              xs: `url(${Image8})`,
            },
          }}
        />
      </Box>
      <Step
        number={`
          Amendment by Finance Act 2017
        `}
      />
      <Content
        paragraph={`
          In the case of non-specified businesses, if the assessee is an individual or HUF, the limit has been revised to Rs. 2,50,000 and Rs. 25,00,000, respectively, instead of Rs. 1,20,000 and Rs. 10,00,000. However, the limit for other assesses remains Rs. 1,50,000.
        `}
      />

      <Step
        number={`
          Applicability of Maintenance of Books of Accounts
        `}
      />
      <Content
        paragraph={`
          Section 44AA primarily applies to individuals, Hindu Undivided Families (HUFs), and professionals engaged in specified professions or businesses. It requires the maintenance of books of accounts, and the specifics are outlined in the Income Tax Act.
        `}
      />

      <Step
        number={`
          Maintenance of Books of Accounts
        `}
      />
      <Content
        paragraph={`
          All individuals and entities falling under the purview of Section 44AA must keep books of accounts. The Income Tax Act specifies the information and details to be included in the books of accounts. Maintenance is mandatory if income or gross turnover/receipts surpass the prescribed threshold.
        `}
      />

      <Step
        number={`
          Section 44AA Threshold Limits
        `}
      />
      <CustomList
        points={[
          "Specified Professions and Businesses",
          "Non-Specified Professions",
          "Other Business",
        ]}
      />

      <Content
        paragraph={`
          (A) Specified Professions and Businesses: The requirement to maintain books of accounts under Section 44AA applies to specific professions and businesses, including legal, medical, engineering, architectural, accountancy, technical consultancy, interior decoration, authorized representatives, film artists, company secretaries, and information technology.
        `}
      />

      <Content
        paragraph={`
          (B) Non-Specified Professions: Non-specified professionals must keep books of account if their gross revenues or income from their profession exceed the specified threshold. For individuals or HUFs, the limit is Rs. 25 lakhs or Rs. 2,50,000, and for others, it is Rs. 10 lakhs or Rs. 1,20,000.
        `}
      />

      <Content
        paragraph={`
          (C) Other Business: Business entities must maintain books of account if the income or gross turnover exceeds the threshold. For individuals or HUFs, the limit is Rs. 25 lakhs, and for others, it is Rs. 10 lakhs. Note: Consider the threshold limit of the current year in cases where the business or profession was established during the preceding year.
        `}
      />
      <Step
        number={`In which case assessee not required to maintain books of accounts`}
      />
      <Content paragraph="A professional does not need to keep any books of accounts as specified in sub-rule 2 of rule 6F if their gross receipts in their line of work did not exceed Rs. 120,000 in any of the three years immediately preceding the previous year, or if their line of work was just established the year before, and their total gross receipts are not expected to exceed that amount." />
      <Content paragraph="The necessity to keep books of accounts, as mentioned in sub-rule 2 of rule 6F, will not apply if the gross receipts exceed the allowed limit in the two years prior but not in the third." />
      <Step number={`Which type of Books are required to be maintained?`} />
      <Content paragraph="For specified professions other than company secretary and information technology (where gross receipts exceed Rs. 1,50,000 in any of the 3 years immediately preceding the previous year):" />
      <CustomList
        points={[
          "Cash",
          "Ledger",
          "Journal",
          "Copy of bill exceeding Rs. 25 issued by assessee",
          "Original bill of expenditure exceeding Rs. 50",
          "If engaged in the medical profession, two additional books are required:",
          "Daily case register",
          "Inventory of medicines and consumable accessories used for the purpose of the profession, as on the first and last day of the previous year.",
        ]}
      />
      <Step number={`Point to be noted`} />
      <Content paragraph="Specified person means: Legal, medical, engineering, architectural, accountancy, technical, consultancy, interior decorations, information technology, company secretary, film artists." />
      <Content paragraph="Place where books of accounts are to be kept: It shall be kept at the principal place of business, where the assessee carries his profession. However, where a person keeps & maintains separate books in respect of each place where the profession is carried on, then such books of account may be kept and maintained at respective places at which the profession is carried on." />
      <Content paragraph="Penalty for non-compliance: A penalty under Section 271A of Rs. 25,000 may be levied on an assessee who fails to preserve or retain books of account and other documents for the period stipulated in line with this provision." />
    </Box>
  );
};

export default BooksOfAccounts;
