const faqData = [
    {
        id: 'panel1',
        question: 'Under Section 44ADA, is it possible to deduct Section 80C?',
        answer: 'Yes, you are still able to claim an 80C deduction up to Rs. 150000 even if your income has been disclosed under section 44ADA',
    },
    {
        id: 'panel2',
        question: 'Does the Provident Fund qualify for a tax exemption under section 80C?',
        answer: 'Yes, the section 80C deduction applies to Provident Fund.',
    },
    {
        id: 'panel3',
        question: 'I\'m building a house in India; may I deduct the principal amount of my home loan under section 80C?',
        answer: 'Section 80C governs the deduction for the principal payments of the home. It can only be claimed, though, if the construction is already finished.',
    },
    {
        id: 'panel4',
        question: 'What is the Senior Citizen Savings Plan (SSC) and is it possible to claim an 80C deduction for SSC contributions?',
        answer: 'Seniors can use this program to start saving for retirement. Up to Rs. 1,50,000 in deductions are available for SSC investments under Section 80C.',
    },
    {
        id: 'panel5',
        question: 'Can NRIs use Section 80CCC to seek a deduction?',
        answer: 'Indeed, if the contributions are made to the designated pension funds in accordance with Section 10, NRIs are eligible to claim this deduction (23AAB).',
    },
    {
        id: 'panel6',
        question: 'Are annuity plan proceeds free from taxes?',
        answer: 'These receipts are not exempt from taxes. In the year of receipt, the proceeds—including any bonuses or interest—become taxable.',
    },
    {
        id: 'panel7',
        question: 'Are deductions permitted under Sections 80C and 80CCC?',
        answer: 'The entire deduction allowed by section 80C includes the deduction under 80CCC. A total deduction of Rs 1.5 lakh is possible when the deduction under this provision is combined with the deduction under 80C and 80CCD.',
    },
    {
        id: 'panel8',
        question: 'Is it necessary to avail of annuity payments under the National Pension Scheme?',
        answer: 'The whole concept of the National Pension Scheme is to provide you with regular incomes after retirement, and so availing of annuity payments is compulsory.',
    },
    {
        id: 'panel9',
        question: 'Does Section 80CCD (1B) apply also to withdrawals?',
        answer: 'No, Section 80 CCD (1B) applies only to investments made towards NPS.',
    },
    {
        id: 'panel10',
        question: 'Who manages the National Pension Scheme?',
        answer: 'The Pension Fund Regulatory and Development Authority (PFRDA) manages the National Pension Scheme.',
    },
    {
        id: 'panel11',
        question: 'Who cannot claim the benefit of Section 80CCD (1B) deductions?',
        answer: 'Persons of Indian Origin (PIO), Hindu Undivided Families (HUFs), and Overseas Citizens of India (OCIs) cannot claim the tax deductions available under Section 80CCD (1B).',
    },
    {
        id: 'panel12',
        question: 'Do I have to tell people about the interest I get on my savings account balance?',
        answer: 'Yes, in accordance with the Income Tax Act of 1961, each person required to file a return is required to declare all income received during the relevant period and pay any applicable taxes.',
    },
    {
        id: 'panel13',
        question: 'What are the repercussions if I fail to disclose the interest I receive on my savings account balance?',
        answer: 'If, during the course of a year, you do not, either knowingly or unknowingly, report the income that you earned on your savings account balance, you will be penalized for noncompliance and will have to pay tax and interest when your return is selected for examination.',
    },
    {
        id: 'panel14',
        question: 'Does the current fiscal year qualify for the section 80TTA deduction?',
        answer: 'Indeed, it is applicable to the fiscal year 2023-24.',
    },
    {
        id: 'panel15',
        question: 'My yearly income is less than the tax slab\'s minimum. Does the interest I earn on my savings account have to be taxed?',
        answer: 'No, there is no taxable income on the interest you earn in your savings account, even if it exceeds Rs. 10,000, and you are not obligated to pay tax on it until your annual income falls below the lowest tax slab.',
    },
    {
        id: 'panel16',
        question: 'If I have received income from real estate, capital gains, etc., am I still eligible to claim the Section 80TTA deduction?',
        answer: 'No, you cannot claim the 80TTA deduction unless you have received interest payments from your savings account.',
    },
    {
        id: 'panel17',
        question: 'What if my disability certificate expires during the current fiscal year?',
        answer: 'If your disability assessment certificate expires, you can still claim a tax deduction under section 80U for the year in which it expires. However, you must have it reissued in order to claim tax benefits in subsequent years.',
    },
    {
        id: 'panel18',
        question: 'Can a person with a 44% prescribed disability claim tax breaks under Section 80U?',
        answer: 'Yes, Section 80U tax deductions are available. It is important to note that the prescribed disability percentage for claiming deductions under the section ranges from 40% to 80%.',
    },
    {
        id: 'panel19',
        question: 'What are the Section 80U eligibility requirements?',
        answer: 'To claim this deduction, you must be a resident individual certified by a medical authority to be a person with a disability at any time during the fiscal year. Non-Resident Indians (NRIs) are ineligible to claim a deduction under Section 80U.',
    },
    {
        id: 'panel20',
        question: 'How do I claim the Section 80U deduction?',
        answer: 'When filing an income tax return, a person or an individual with physical disabilities can claim the deduction under section 80U of the Income Tax Act.',
    },
    {
        id: 'panel21',
        question: 'Can I claim a deduction under both Section 80U and Section 80DD?',
        answer: 'No, you cannot deduct both Section 80U and Section 80DD at the same time.',
    },
    {
        id: 'panel22',
        question: 'What is Section 80E?',
        answer: 'Section 80E of the Income Tax Act provides a deduction for the interest paid on loans taken for higher education.',
    },
    {
        id: 'panel23',
        question: 'Who is eligible to claim the deduction under Section 80E?',
        answer: 'Individual taxpayers who have taken an education loan for themselves, their spouse, or children for higher studies are eligible to claim the deduction.',
    },
    {
        id: 'panel24',
        question: 'What types of education loans are eligible for deduction under Section 80E?',
        answer: 'Loans taken from approved financial institutions or charitable institutions for higher education courses in India or abroad are eligible.',
    },
    {
        id: 'panel25',
        question: 'Is there a limit on the deduction amount under Section 80E?',
        answer: 'There is no upper limit on the deduction amount. Taxpayers can claim the actual interest paid on the education loan.',
    },
    {
        id: 'panel26',
        question: 'Can the deduction be claimed for the principal repayment of the education loan?',
        answer: 'No, Section 80E provides a deduction only for the interest component of the education loan repayment. Principal repayment is not eligible.',
    },
    {
        id: 'panel27',
        question: 'When does the deduction period start under Section 80E?',
        answer: 'The deduction period starts from the year in which the individual begins repaying the education loan and continues for a maximum of eight assessment years or until the interest is fully repaid, whichever is earlier.',
    },
    {
        id: 'panel28',
        question: 'Can the deduction be claimed for multiple education loans?',
        answer: 'Yes, the deduction can be claimed for multiple education loans taken for higher studies of the taxpayer, their spouse, or children.',
    },
    {
        id: 'panel29',
        question: 'Are there specific institutions from which the education loan must be taken?',
        answer: 'Yes, the loan must be taken from approved financial institutions or charitable institutions, including banks, financial institutions, approved charitable institutions, and approved charitable trusts.',
    },
    {
        id: 'panel30',
        question: 'Does Section 80E apply only to specific courses?',
        answer: 'Section 80E applies to higher education courses pursued after passing the Senior Secondary Examination or its equivalent. It includes courses in engineering, medicine, management, and postgraduate courses in applied sciences, among others.',
    },
    {
        id: 'panel31',
        question: 'How should the taxpayer provide proof of the interest paid for claiming the deduction?',
        answer: 'Taxpayers should retain the certificate of interest payable on the education loan from the financial institution, as it needs to be furnished along with the income tax return to claim the deduction.',
    },
    {
        id: 'panel32',
        question: 'What is Section 80EE?',
        answer: 'Section 80EE of the Income Tax Act provides for an additional deduction on the interest paid on loans taken for the purpose of acquiring a residential property, specifically for first-time homebuyers.',
    },
    {
        id: 'panel33',
        question: 'Who is eligible to claim the deduction under Section 80EE?',
        answer: 'Individuals who are first-time homebuyers and have taken a loan from a financial institution for the purpose of purchasing a residential property are eligible to claim the deduction.',
    },
    {
        id: 'panel34',
        question: 'What are the criteria for the loan to be eligible for deduction under Section 80EE?',
        answer: 'The loan should be sanctioned by a financial institution or a housing finance company. The loan amount should not exceed ₹35 lakhs, and the value of the residential property should not exceed ₹50 lakhs.',
    },
    {
        id: 'panel35',
        question: 'Is the deduction under Section 80EE available for the principal repayment of the home loan?',
        answer: 'No, Section 80EE provides a deduction only for the interest component of the home loan repayment. Principal repayment is not eligible for this deduction.',
    },
    {
        id: 'panel36',
        question: 'What is the maximum deduction allowed under Section 80EE?',
        answer: 'The maximum deduction allowed under Section 80EE is ₹50,000 per financial year. This is in addition to the deduction available under Section 24(b) for the interest on housing loans.',
    },
    {
        id: 'panel37',
        question: 'Is there any restriction on the ownership of residential property for claiming the deduction under Section 80EE?',
        answer: 'Yes, the taxpayer should not own any residential house property on the date of sanction of the loan to be eligible for the deduction.',
    },
    {
        id: 'panel38',
        question: 'How does one provide evidence for claiming the deduction under Section 80EE?',
        answer: 'Taxpayers should maintain documents related to the home loan agreement, sanction letter, and other relevant documents as proof for claiming the deduction. These may need to be submitted during the assessment or as required by tax authorities.',
    },
    {
        id: 'panel39',
        question: 'What is the deduction amount under 80 TTB, and who is qualified to claim one?',
        answer: 'ELIGIBLE ASSESSEE: Resident senior citizen whose GTI includes interest on deposit with bank or co-bank or post-office. Deduction amount: Interest amount or ₹50,000.',
    },
    {
        id: 'panel40',
        question: 'What is Section 80 TTB under the Income Tax Act?',
        answer: 'Section 80 TTB is a provision that allows senior citizens to claim a deduction on the interest income earned from specified deposits.',
    },
    {
        id: 'panel41',
        question: 'What types of deposits are covered under Section 80 TTB?',
        answer: 'Specified deposits include savings accounts, fixed deposits, recurring deposits, and other time deposits with banks, co-operative banks, or post offices.',
    },
    {
        id: 'panel42',
        question: 'Can senior citizens claim deductions on interest income from sources other than specified deposits?',
        answer: 'No, Section 80 TTB is specifically for interest income from specified deposits, and interest from other sources is not covered.',
    },
    {
        id: 'panel43',
        question: 'Does Section 80 TTB cover interest income from investments such as mutual funds or bonds?',
        answer: 'No, the deduction is applicable only to interest income from specified deposits and does not cover income from other financial instruments.',
    },
    {
        id: 'panel44',
        question: 'What is Section 80D of the Income Tax Act?',
        answer: 'Section 80D provides deductions for payments made towards medical insurance premiums and preventive health check-up expenses.',
    },
    {
        id: 'panel45',
        question: 'Who is eligible to claim deductions under Section 80D?',
        answer: 'Individuals and Hindu Undivided Families (HUFs) who have paid medical insurance premiums for themselves, their spouse, children, or parents can claim deductions.',
    },
    {
        id: 'panel46',
        question: 'What is the maximum deduction limit under Section 80D?',
        answer: 'The maximum deduction limit depends on the individuals covered: For self, spouse, and dependent children: Up to ₹25,000 per year. For parents (non-senior citizens): An additional ₹25,000. For senior citizen parents: An additional ₹50,000. If both taxpayer and parents are senior citizens: Up to ₹1,00,000 (₹50,000 for self and ₹50,000 for parents).',
    },
    {
        id: 'panel47',
        question: 'Can preventive health check-up expenses be claimed as a deduction under Section 80D?',
        answer: 'Yes, taxpayers can claim an additional deduction of up to ₹5,000 for preventive health check-up expenses within the overall limit.',
    },
    {
        id: 'panel48',
        question: 'What types of health insurance policies are eligible for deductions under Section 80D?',
        answer: 'Payments made towards health insurance policies, including those from insurance companies, the Central Government Health Scheme (CGHS), or provided by employers, are eligible for deductions.',
    },
    {
        id: 'panel49',
        question: 'Do I need to submit proof of health insurance premiums while filing taxes?',
        answer: 'Taxpayers are advised to retain receipts or documentary evidence of health insurance premium payments as they might be required to support the claim during the tax filing process.',
    },
    {
        id: 'panel50',
        question: 'How does Section 80D contribute to promoting preventive healthcare?',
        answer: 'By providing an additional deduction for preventive health check-up expenses, Section 80D encourages individuals to prioritize and invest in preventive healthcare measures.',
    },

    {
        id: 'panel51',
        question: 'Who is eligible to claim deduction under Section 80DD?',
        answer: 'Individuals and Hindu Undivided Families (HUFs) who have incurred expenses for the medical treatment, training, and rehabilitation of a dependant with a disability are eligible to claim the deduction.',
    },
    {
        id: 'panel52',
        question: 'Who is considered a dependent for the purpose of Section 80DD?',
        answer: 'A dependent can be a spouse, children, parents, brothers, or sisters of the individual or HUF taxpayer.',
    },
    {
        id: 'panel53',
        question: 'Is there any specific format for the disability certificate?',
        answer: 'The certificate should be obtained from a prescribed medical authority. There is no specific format mentioned, but it should include details about the nature and extent of the disability.',
    },
    {
        id: 'panel54',
        question: 'Can an HUF claim a deduction under Section 80DD?',
        answer: 'Yes, Hindu Undivided Families (HUFs) are eligible to claim this deduction for the medical treatment and maintenance of a dependent with a disability.',
    },
    {
        id: 'panel55',
        question: 'Are the deduction limits subject to change?',
        answer: 'Yes, tax laws are subject to amendments, and it\'s essential to check for any updates or changes in the deduction limits or other provisions related to Section 80DD.',
    },


    {
        id: 'panel56',
        question: 'What is Section 80DDB and what does it cover?',
        answer: 'Section 80DDB provides a deduction for expenses incurred on the medical treatment of specified diseases for the taxpayer or their dependent family members.',
    },
    {
        id: 'panel57',
        question: 'Who is eligible to claim a deduction under Section 80DDB?',
        answer: 'Individual residents and Hindu Undivided Families (HUFs) are eligible to claim the deduction for the medical treatment of specified diseases for themselves or their dependent family members.',
    },
    {
        id: 'panel58',
        question: 'What are the specified diseases covered under Section 80DDB?',
        answer: 'The specified diseases include diseases like Neurological Diseases, Malignant Cancers, Chronic Renal failure, Hematological disorders, etc. The specific list of diseases is provided in Rule 11DD of the Income Tax Rules.',
    },
    {
        id: 'panel59',
        question: 'What is the maximum deduction allowed under Section 80DDB?',
        answer: 'The deduction is limited to the actual amount spent on the treatment or Rs. 40,000, whichever is less. For senior citizens (60 years or above), the maximum limit is Rs. 1,00,000.',
    },
    {
        id: 'panel60',
        question: 'Is the deduction limit subject to change?',
        answer: 'Yes, tax laws are subject to amendments, and it\'s important to check for any updates or changes in the deduction limits or other provisions related to Section 80DDB.',
    },


];

export default faqData;

