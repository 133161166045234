import React from 'react';
import Mainhead from '../../../../Components/Services/MainContent/Mainhead';
import Content from '../../../../Components/Services/MainContent/Content';
import List from '../../../../Components/Services/MainContent/List';
import Step from '../../../../Components/Services/MainContent/Step';
import { Box , styled, Typography} from '@mui/material';

function WhyLegalFactory(){
return(
    <div>
        <Mainhead heading="Why Legal Factory ?"/>
        <Content paragraph="We are an umbrella platform/organisation/alliance with a team of chartered accountants, company secretaries, Cost & Management Accountants, Corporate Lawyers, Civil Lawyers, Registered Valuers & Chartered Engineers administering legal work & other similar registration processes to inception to offer you handsome/ample/efficient/robust services alongwith simplified legal incorporation processes and financial accounting. We are dutifully/sincerely committed to your data security."/>
    </div>
)
}

export default WhyLegalFactory