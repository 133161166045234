import * as React from 'react';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Divider } from '@mui/material';
import Content from '../../../../Components/Services/MainContent/Content';
import Mainhead from '../../../../Components/Services/MainContent/Mainhead';

export default function Faq() {
  const [expanded, setExpanded] = React.useState(false);

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  return (
    <div>
      <Mainhead heading="FAQ'S"/>
      <br/>
      <Divider sx={{border: '1px solid #FF990080'}}/>
      <Accordion expanded={expanded === 'panel1'} onChange={handleChange('panel1')}  elevation={0} sx={{ boxShadow: 'unset !important' }} disableGutters>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1bh-content"
          id="panel1bh-header"
        >
          <Typography  sx={{color:'#281900',fontWeight:450,fontSize:'20px'}}>Do I need to be physically present during this process?</Typography>
        </AccordionSummary>
        <AccordionDetails>
         <Content paragraph ="No, the entire process is now automated and handled online. You will receive the DIN numbers and Certificate of Incorporation by e-mail from Ministry of Corporate Affairs(MCA).)"/>
            
         
        </AccordionDetails>
      </Accordion>
      <Divider sx={{border: '1px solid #FF990080'}}/>
      <Accordion expanded={expanded === 'panel2'} onChange={handleChange('panel2')}
       elevation={0} sx={{ boxShadow: 'unset !important' }} disableGutters>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel2bh-content"
          id="panel2bh-header"
        >
          <Typography sx={{color:'#281900',fontWeight:450,fontSize:'20px'}}>
            Timeline for settling up a Private Limited Company in India?
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
         <Content paragraph="It generally takes 30 business days to incorporate a Producer Company"/>
        </AccordionDetails>
      </Accordion>
      <Divider sx={{border: '1px solid #FF990080'}}/>
      <Accordion expanded={expanded === 'panel3'} onChange={handleChange('panel3')}
       elevation={0} sx={{ boxShadow: 'unset !important' }} disableGutters>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel3bh-content"
          id="panel3bh-header"
        >
          <Typography sx={{color:'#281900',fontWeight:450,fontSize:'20px'}}>
            What are the rules that bind the naming of a Producer Company?
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
         <Content paragraph="Every company has two parts to its name. The first part is unique to each one. For example, ‘HONDA’ or ‘NIVIA’. The second part describes the business activity of the company. For example, ‘bikes’ or ‘cream’. This second part must be as descriptive as possible. Hence, the name of the company must end with ‘Producer Company Limited’s."/>
        </AccordionDetails>
      </Accordion>
      <Divider sx={{border: '1px solid #FF990080'}}/>
      <Accordion expanded={expanded === 'panel4'} onChange={handleChange('panel4')}
       elevation={0} sx={{ boxShadow: 'unset !important' }} disableGutters>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel4bh-content"
          id="panel4bh-header"
        >
          <Typography sx={{color:'#281900',fontWeight:450,fontSize:'20px'}}>Are the shares of a Producer Company transversable?</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Content paragraph="Yes, they are transferable-members of the producer company transfer shares along with the special rights"/>
        </AccordionDetails>
      </Accordion>
      <Divider sx={{border: '1px solid #FF990080'}}/>
      <Accordion expanded={expanded === 'panel5'} onChange={handleChange('panel5')}
       elevation={0} sx={{ boxShadow: 'unset !important' }} disableGutters>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel5bh-content"
          id="panel5bh-header"
        >
          <Typography sx={{color:'#281900',fontWeight:450,fontSize:'20px'}}>How is a nominee appointed by the members in a Producer Company?</Typography>
        </AccordionSummary>
        <AccordionDetails>
         <Content paragraph="The members of the company need to nominate a person in a specified manner within 3 months of becoming a member of the Producer Company."/>
          </AccordionDetails>
          </Accordion>


          <Divider sx={{border: '1px solid #FF990080'}}/>
      <Accordion expanded={expanded === 'panel6'} onChange={handleChange('panel6')}
       elevation={0} sx={{ boxShadow: 'unset !important' }} disableGutters>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel6bh-content"
          id="panel6bh-header"
        >
          <Typography sx={{color:'#281900',fontWeight:450,fontSize:'20px'}}>What is a Digital Signature Certificate?</Typography>
        </AccordionSummary>
        <AccordionDetails>
         <Content paragraph="A Digital Signature Certificate, or DSC, is issued by six certifying authorities in India(such as e-Mudhra and n-Code). It is nothing but an electronic version of a physical signature. It can be used to verify documents in the company registration process."/>
          </AccordionDetails>
          </Accordion>

          <Divider sx={{border: '1px solid #FF990080'}}/>
      <Accordion expanded={expanded === 'panel6'} onChange={handleChange('panel6')}
       elevation={0} sx={{ boxShadow: 'unset !important' }} disableGutters>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel6bh-content"
          id="panel6bh-header"
        >
          <Typography sx={{color:'#281900',fontWeight:450,fontSize:'20px'}}>Can my Producer Company survive perpetually?</Typography>
        </AccordionSummary>
        <AccordionDetails>
         <Content paragraph="All companies are legal entities by themselves. Therefore, they have a perpetual existence. The only way a producer company can cease to exist(other than shutting it down voluntarily) is if the annual compliances are not met or if the creditors/courts force its closure"/>
          </AccordionDetails>
          </Accordion>

          <Divider sx={{border: '1px solid #FF990080'}}/>
      <Accordion expanded={expanded === 'panel6'} onChange={handleChange('panel6')}
       elevation={0} sx={{ boxShadow: 'unset !important' }} disableGutters>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel6bh-content"
          id="panel6bh-header"
        >
          <Typography sx={{color:'#281900',fontWeight:450,fontSize:'20px'}}>What is the tenure of the directorship period in Producer Company??</Typography>
        </AccordionSummary>
        <AccordionDetails>
         <Content paragraph="The minimum period is 1 year while the maximum period for Directorship is 5 years"/>
          </AccordionDetails>
          </Accordion>

          <Divider sx={{border: '1px solid #FF990080'}}/>
      <Accordion expanded={expanded === 'panel6'} onChange={handleChange('panel6')}
       elevation={0} sx={{ boxShadow: 'unset !important' }} disableGutters>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel6bh-content"
          id="panel6bh-header"
        >
          <Typography sx={{color:'#281900',fontWeight:450,fontSize:'20px'}}>What are the benefits of incorporating a Producer Company?</Typography>
        </AccordionSummary>
        <AccordionDetails>
         <Content paragraph="The topmost benefits are limited"/>
          </AccordionDetails>
          </Accordion>

          <Divider sx={{border: '1px solid #FF990080'}}/>
      <Accordion expanded={expanded === 'panel6'} onChange={handleChange('panel6')}
       elevation={0} sx={{ boxShadow: 'unset !important' }} disableGutters>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel6bh-content"
          id="panel6bh-header"
        >
          <Typography sx={{color:'#281900',fontWeight:450,fontSize:'20px'}}>Will the books of a Producer Company needs auditing</Typography>
        </AccordionSummary>
        <AccordionDetails>
         <Content paragraph="Yes, a producer company must have its books audited from its very first year. And in case turnover crosses Rs. 5 Crores, it must employ a full time company secretary to manage its affairs"/>
          </AccordionDetails>
          </Accordion>

          <Divider sx={{border: '1px solid #FF990080'}}/>
      <Accordion expanded={expanded === 'panel6'} onChange={handleChange('panel6')}
       elevation={0} sx={{ boxShadow: 'unset !important' }} disableGutters>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel6bh-content"
          id="panel6bh-header"
        >
          <Typography sx={{color:'#281900',fontWeight:450,fontSize:'20px'}}>What is the requirement of board meeting?</Typography>
        </AccordionSummary>
        <AccordionDetails>
         <Content paragraph="The minimum number of board meetings required is 4 and there should be a gap of 3 months between two board meetings."/>
          </AccordionDetails>
          </Accordion>

        

          <Divider sx={{border: '1px solid #FF990080'}}/>
      

         




      


      


      



    </div>
  );
}