import React from "react";
import { Box } from "@mui/material";
import Content from "../../../Components/Services/MainContent/Content";
import Step from "../../../Components/Services/MainContent/Step";
import ReusableTable from "../../../Components/Income-Tax/ReusableTable";
import Mainhead from "../../../Components/Services/MainContent/Mainhead";

const SectionEmployeeContribution = () => {
  const keymanInsuranceDetails = [
    "An employer can pay the premiums for a coverage known as a 'keyman insurance policy.'",
    "In the event of a claim, the employer will get benefits, and the life to be covered is that of the 'keyman.'",
    "Anyone with the necessary specialized skills whose absence could put a strain on the business's finances is qualified for keyman insurance.",
  ];

  const taxImplicationsDetails = [
    "Under the Income Tax Act in many jurisdictions, including India, keyman insurance policies may have tax implications.",
    "Premiums Paid: Generally, premiums paid by the company for a keyman insurance policy are not deductible as a business expense for income tax purposes.",
    "Tax Treatment of Proceeds: The proceeds from a keyman insurance policy are usually considered as a revenue receipt and are taxable in the hands of the business as income. However, the tax treatment can vary based on the purpose for which the proceeds are received.",
    "Capital vs. Revenue Purpose: If the policy is taken for the purpose of securing a capital asset or for capital expenditure, the proceeds may be treated differently than if it is for revenue purposes.",
  ];

  const advantagesDetails = [
    "If Keyman passes away, the business receives funding to help with the loss.",
    "Section 37(1) of the Income Tax Act allows any company that purchases keyman insurance for its employees to deduct the amount paid for the policy as a business cost.",
    "The company can also raise loans on the policy from LIC at 12 percent per annum.",
    "Insulate the risk of financial loss against the loss of a Keyman.",
    "Keyman insurance policy is a positive measure to improve the retention of the key man in the company.",
  ];

  const taxabilityDetails = [
    "Income tax is levied for each assessment year at a rate determined specifically for that year, in compliance with section 4 of the Income Tax Act of 1961.",
    "Any income accruing or arising to an Indian resident during any prior year includes all revenue received or presumed to have been received in India during that year, as well as any income that the resident obtained outside of India.",
    "Interest on such contribution or any sum received under a keyman insurance policy, including the sum allocated by way of bonus on such policy.",
    "Income chargeable under this head if not charged under the head 'profit and gains of business and profession' or under the head 'salaries.'",
    "Any sum (including bonus) received under a keyman insurance policy [section 56 (2)(iv)].",
    "Keyman insurance policy means a life insurance policy taken by a person on the life of another person, who is either the employee or is connected in any manner with the business of the former person.",
    "Any compensation or other payment, due to or received by any person, in connection with the termination of his employment or the modification of the terms and conditions relating thereto.",
  ];
  const familyPensionDetails = [
    "Family Pension means a regular amount payable by the employer to a person belonging to the family of a deceased employee (e.g., widow or legal heirs of a deceased employee).",
    "Tax treatment: It is taxable under the head 'income from other sources' after allowing standard deduction.",
  ];

  const standardDeductionDetails = [
    "Standard deduction [section 57(iia)]:",
    "  - Minimum of 1/3rd of such pension; or Rs. 15,000",
  ];

  const reliefSection89Details = [
    "Relief u/s 89 on arrear of family pension:",
    "Relief is available on arrears of family pension received by the family member of a deceased employee, as in the case of arrear/advance salary.",
  ];

  const notesDetails = [
    "Lump-sum payment made gratuitously or by way of compensation or otherwise to the widow or other legal heirs of an employee, who dies while still in service, is non-taxable income.",
    "Ex-gratia payment made to the widow or legal heir of an employee, who dies while still in active service would not be taxable as an income provided it is paid by the central government or state government or local authority or government or public sector undertaking.",
  ];
  const rentalIncomeDetails = [
    "Revenue from leasing equipment, real estate, or furnishings is subject to taxation under this heading, unless it falls under the purview of 'profit and gains from business and profession.'",
    "Any income received by renting out an item as a commercial asset or as part of a business activity is subject to taxation under the heading 'profit and gains from business and profession.'",
    "Lease rent will be subject to taxation under the heading 'profit and gains of business and profession' if the enterprise is leased out and is temporarily discontinued for any reason without the assessee's desire to part with it or close it down.",
  ];

  const compositeRentDetails = [
    "Rental income from buildings usually falls under the category of income from house property.",
    "If the rental income from buildings is inextricably linked to the rental income from machinery, plant, and equipment, it will be subject to tax under the category of 'income from other sources' if it isn't already under the category of 'profit and gain from business and profession.'",
    "If letting of such building alone is acceptable, then income from letting of business is taxable under the head 'income from house property.'",
    "Rent charged against each asset does not make it separable, unless and until the property is separately letable.",
    "Deduction allowed against income u/s 56(2)(ii) & 56(2)(iii) [section 57 (ii) & (iii)]:",
    "  - Current repairs shall be allowed as a deduction [as per section 30(a)(ii) or 31 (i)]",
    "  - Insurance premium paid for machinery, plant, furniture, or building [as per section 30(c) or 31(ii)]",
    "  - Depreciation and unabsorbed depreciation (as per sec. 32)",
    "  - Any other revenue expenditure expanded, during the previous year, wholly and exclusively for earning such income.",
  ];

  const employeeContributionDetails = [
    "Any amount received or deducted by an employer from an employee for:",
    "  - Provident fund",
    "  - Superannuation fund",
    "  - Fund set up under the provision of Employee’s State Insurance Act, 1948, etc.",
    "  - Other fund set up for the welfare of the employee",
    "If the amount is not taxable under the heading 'profit and gains from business and profession,' it will be treated as employee income under this heading.",
    "If the employer credits the amount to the employee's account in the relevant fund by the deadline specified by the applicable Act, an equal deduction will be available.",
  ];

  const employeeContributionTaxpointDetails = [
    {
      condition: "If employee’s contribution taken and deposited within time",
      treatment: "No treatment",
    },
    {
      condition:
        "If employee’s contribution taken and not deposited within time",
      treatment:
        "Taxable as income from other sources if not then taxable as business income",
    },
  ];

  return (
    <Box
      style={{
        display: "flex",
        flexDirection: "column",
        gap: 4,
        marginBottom: "2rem",
      }}
    >
      <Step number="Employee’s Contribution to Staff Welfare Schemes - Section 56(2)(ic)" />
      <Step number="Overview of Employee’s Contribution" />
      {employeeContributionDetails.map((detail, index) => (
        <Content key={index} paragraph={detail} />
      ))}
      <Step number="Taxpoint" />
      <ReusableTable data={employeeContributionTaxpointDetails} />
      <Step number="Rental Income of Plant, Machinery, or Furniture - Section 56(2)(ii)" />
      <Step number="Overview of Rental Income" />
      {rentalIncomeDetails.map((detail, index) => (
        <Content key={index} paragraph={detail} />
      ))}
      <Step number="Income from Plant, Machinery, or Furniture Let on Hire Along with Building" />
      {compositeRentDetails.map((detail, index) => (
        <Content key={index} paragraph={detail} />
      ))}
      <Mainhead heading="Family Pension - Section 56(2)(iia)" />
      <Step number="Meaning of Family Pension" />
      {familyPensionDetails.map((detail, index) => (
        <Content key={index} paragraph={detail} />
      ))}
      <Step number="Tax Treatment" />
      <Content paragraph="It is taxable under the head 'income from other sources' after allowing standard deduction." />
      <Step number="Standard Deduction" />
      {standardDeductionDetails.map((detail, index) => (
        <Content key={index} paragraph={detail} />
      ))}
      <Step number="Relief u/s 89 on Arrear of Family Pension" />
      {reliefSection89Details.map((detail, index) => (
        <Content key={index} paragraph={detail} />
      ))}
      <Step number="Notes" />
      {notesDetails.map((detail, index) => (
        <Content key={index} paragraph={detail} />
      ))}

      <Mainhead heading="Keyman Insurance Policy - Section 56(2)(iv)" />
      <Step number="Keyman Insurance Overview" />
      {keymanInsuranceDetails.map((detail, index) => (
        <Content key={index} paragraph={detail} />
      ))}
      <Step number="Tax Implications" />
      {taxImplicationsDetails.map((detail, index) => (
        <Content key={index} paragraph={detail} />
      ))}
      <Step number="Advantages of Keyman Insurance Policy" />
      {advantagesDetails.map((detail, index) => (
        <Content key={index} paragraph={detail} />
      ))}
      <Step number="Taxability of Keyman Insurance Policy" />
      {taxabilityDetails.map((detail, index) => (
        <Content key={index} paragraph={detail} />
      ))}
    </Box>
  );
};

export default SectionEmployeeContribution;
