import React from "react";
import { Box } from "@mui/material";
import Mainhead from "../../../Components/Services/MainContent/Mainhead";
import Content from "../../../Components/Services/MainContent/Content";
import CustomList from "../../../Components/Income-Tax/CustomList";
import Step from "../../../Components/Services/MainContent/Step";

const GiftTaxation = () => {
  return (
    <Box
      style={{
        display: "flex",
        flexDirection: "column",
        gap: 4,
        marginBottom: "2rem",
      }}
    >
      <Mainhead heading="Taxation of Gifts" />

      <Step number={`Introduction:`} />
      <Content paragraph="In the intricate web of India's Income Tax Act, gifts hold a unique place, often sparking questions about their taxation. This comprehensive guide aims to shed light on the taxation of gifts under the head 'Income from Other Sources' as per Section 56(2)(x) of the Income Tax Act." />

      <Step
        number={`What does Gifts mean as per Income Tax Act, 1961 in India and their related laws?`}
      />
      <Content paragraph="Gifts encompass a broad spectrum, ranging from monetary sums to movable and immovable property. Crucially, these are received without any consideration, distinguishing them from transactions with a quid pro quo." />

      <Step number={`Exemptions for Specified Relatives:`} />
      <Content paragraph="The Act provides a welcome relief by exempting gifts from specified relatives, including parents, spouses, siblings, and lineal descendants. This exemption applies to various kinds of gifts, promoting familial transactions without undue tax implications." />

      <Step number={`Exemption for Special Occasions:`} />
      <Content paragraph="The joyous occasions of marriage are further sweetened by the tax exemption granted for gifts received during weddings. This exemption is not limited to specific relatives, making it more inclusive for the celebrants." />

      <Step number={`Taxable Amount and Threshold:`} />
      <Content paragraph="While gifts are generally a cause for celebration, caution is advised when the gift amount exceeds Rs. 50,000 in a financial year. The amount exceeding this threshold becomes taxable under the head 'Income from Other Sources.'" />

      <Step number={`Tax Treatment of Gifts from Employers:`} />
      <Content paragraph="Gifts from employers fall under the category of perquisites and are taxed under the head 'Income from Salaries.' However, the Act extends exemptions for certain small gifts, ensuring a fair balance." />

      <Step number={`Valuation of Gifts:`} />
      <Content paragraph="Determining the tax liability requires a fair valuation of the gift. The Act considers the fair market value, with specific guidelines for immovable property, where the stamp duty value is considered." />

      <Step number={`Reporting Requirements:`} />
      <Content paragraph="Transparency is key when it comes to gifts and taxes. Gifts exceeding Rs. 50,000 need to be diligently reported in the income tax return under the head 'Income from Other Sources.'" />

      <Step number={`Cash Gifts and Banking Channels:`} />
      <Content paragraph="In an era where digital transactions dominate, receiving gifts in cash exceeding Rs. 50,000 may attract taxation. Opting for transparent banking channels not only aligns with modern practices but also aids in maintaining financial clarity." />

      <Step
        number={`Tax Implications for Hindu Undivided Families (HUF) for gifts:`}
      />
      <Content paragraph="HUFs are not exempt from gift taxation. Gifts received by an HUF are subject to taxation under the head 'Income from Other Sources,' unless falling under specific exemptions." />

      <Step number={`Eligibility to pay :`} />
      <CustomList
        points={[
          "Any person who receives monetary gifts worth more than Rs 50,000 in a single financial year is required to include that amount in their gross total income and pay the appropriate amount of tax.",
          "When a gift exceeds Rs 50,000 in fair market value and is accepted by a person without any expectation of return, the entire amount becomes taxable in that person's name.",
          "When a gift is received for less than its fair market value (FMV) and the difference surpasses Rs 50,000, both the consideration and the FMV difference are included in income and subject to applicable taxes.",
        ]}
      />

      <Step number={`Conclusion:`} />
      <Content paragraph="Gift taxation under the Income Tax Act is a nuanced area, requiring individuals to strike a balance between generosity and compliance. Keeping abreast of the latest regulations and seeking professional advice ensures a smooth navigation of the intricate landscape of gift taxation." />
    </Box>
  );
};

export default GiftTaxation;
