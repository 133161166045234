
const accordionData = [

    {
        title: 'Business Setup',
        menuItems: [
            {
                category: 'Popular Entities',
                items: [
                    { label: 'Private Limited Company', link: '/private' },
                    { label: 'Startup India', link: '/startupregistration' },
                    { label: 'Producer Company', link: '/producercompany' },
                    { label: 'Partnership Firm', link: '/partnershipfirm' },
                    { label: 'Sole Proprietorship', link: '/soleproprietorship' },
                    { label: 'Shop Act', link: '/shopact' },
                    { label: 'Nidhi Company', link: '/nidhicompany' },
                    { label: 'Limited Liability Partnership', link: '/limitedliability' },
                ],
            },
            // {
            //     category: 'Other Special Entities',
            //     items: [
            //         { label: 'US Incorporation', link: '/usincorporation' },

            //     ],
            // },
        ],
    },
    {
        title: 'Trademark',
        menuItems: [
            {
                category: '',
                items: [
                    { label: 'Trademark Renewal', link: '/trademark' },

                ],
            },

        ],
    },
    {
        title: 'Tax Planning',
        menuItems: [
            {
                category: 'Income tax',
                items: [
                    { label: 'Know the basics of Income Tax', link: '/tax/incometax' },
                    { label: 'Be prepared for ITR filing', link: '/tax/Itrfiling' },
                    { label: 'Get equipped with the procedure of ITR filing', link: '/tax/procedure' },
                    { label: 'Know your Residential Status', link: '/tax/residential' },
                    { label: 'Know your Income from Salaries', link: '/tax/salaries' },
                    { label: 'Know your Income from House Property', link: '/tax/houseproperty' },
                    { label: 'Calculate Your Profits of gains from Business And Profession', link: '/tax/business' },
                    { label: 'Calculate Your Capital Gain', link: '/tax/capitalgain' },
                    { label: 'Calculate Your Income from Other Sources', link: '/tax/othersources' },
                    { label: 'Exemption From Income U/S 10', link: '/tax/income' },
                    { label: 'Deduction from Taxable Income', link: '/tax/taxableincome' },
                    { label: 'Deduction of tax From Income(TDS)', link: '/tax/tds' },
                    { label: 'Calculate Your Advance Tax Liability', link: '/tax/advancetax' },
                    { label: 'How are Incomes Assessed in IT Act', link: '/tax/incomeassessed' },
                    { label: 'How to find an Appeal', link: '/tax/appeal' },
                ],
            },
        ],
    },
    {
        title: 'Income Tax',
        menuItems: [
            {
                category: '',
                items: [
                    { label: 'Capital Gains Income', link: '/incometax/capitalgain' },
                    { label: 'Deduction from Taxable Income', link: '/incometax/taxableincome' },
                    { label: "Profits Of Gain From Business And Profession", link: "/incometax/business", },
                    { label: 'Know your Income from House Property', link: '/incometax/houseproperty' },
                    { label: 'Calculate Your Income from Other Sources', link: '/incometax/othersources' },
                    { label: 'Know your Income from Salaries', link: '/incometax/salaries' },


                ],
            },

        ],
    },

];

export default accordionData;
