import React from "react";
import { Box } from "@mui/material";
import Mainhead from "../../../Components/Services/MainContent/Mainhead";
import Content from "../../../Components/Services/MainContent/Content";
import Step from "../../../Components/Services/MainContent/Step";
import ReusableTable from "../../../Components/Income-Tax/ReusableTable";

const LTAComponent = () => {
  // Calculation details for LTA exemption
  const ltaExemptionCalculationData = [
    {
      Particulars: "LTA received by Employer",
      HighCourtJudge: "40,000",
      NotHighCourtJudge: "40,000",
    },
    {
      Particulars: "Actual Travel Expense",
      HighCourtJudge: "35,000",
      NotHighCourtJudge: "35,000",
    },
    {
      Particulars:
        "For High Court Judge - Fully Exempt Irrespective of Actual Expenditure Incurred",
      HighCourtJudge: "40,000",
      NotHighCourtJudge: "",
    },
    {
      Particulars: "For Not High Court Judge - Least of Above is Exempt",
      HighCourtJudge: "35,000",
      NotHighCourtJudge: "35,000",
    },
    {
      Particulars:
        "For Not High Court Judge - Remaining Amount Taxable in Hands of Employee",
      HighCourtJudge: "",
      NotHighCourtJudge: "5,000",
    },
  ];

  return (
    <Box
      style={{
        display: "flex",
        flexDirection: "column",
        gap: 4,
        marginBottom: "2rem",
      }}
    >
      <Mainhead heading="Leave Travel Allowance (LTA) and Other Allowances in India" />
      <Step
        number={`
          What is Leave Travel Allowance Meaning In Income Tax Act 1961 In India?
        `}
      />
      <Content paragraph="A leave travel allowance is a sum of money that an employer gives to its staff members and their families so they can travel inside India:" />
      <Content paragraph="(a) while on leave; (b) upon retirement from employment; (c) upon termination from employment;" />
      <Content paragraph="Leave travel allowance (LTA) is often referred to as Leave Travel Concession." />
      <Step
        number={`
          Discuss the taxability/exemption of LTA now.
        `}
      />
      <Content paragraph="1. In the hands of employees, LTA is partially taxable and partially exempt (according to conditions)." />
      <Content paragraph="2. LTA awarded to a High Court or Supreme Court Judge and their family members is completely exempt from any conditions." />
      <Step
        number={`
          For example, if the employer grants LTA of Rs 40,000 and the employee's real travel expense is Rs 35,000, 
          then LTA if you are a High Court Judge OR if you are not a High Court Judge?
        `}
      />
      <ReusableTable data={ltaExemptionCalculationData} />

      <Step
        number={`
          How to Avail LTA?
        `}
      />
      <Content paragraph="(a) LTA is unavailable in the New Tax Regime." />
      <Content paragraph="Let's look at how salary is calculated in both regimes if LTA is present." />
      <ReusableTable
        data={[
          {
            Particulars: "Basic salary (40000*12)",
            OldTaxRegime: "4,80,000",
            NewTaxRegime: "4,80,000",
          },
          {
            Particulars: "Dearness Allowance @ 50%",
            OldTaxRegime: "2,40,000",
            NewTaxRegime: "2,40,000",
          },
          {
            Particulars: "Leave Travel Allowance",
            OldTaxRegime: "30,000",
            NewTaxRegime: "30,000",
          },
          {
            Particulars: "Less: Exemption of LTA Section- 10(5)",
            OldTaxRegime: "(30,000)",
            NewTaxRegime: "-",
          },
          {
            Particulars: "GROSS SALARY",
            OldTaxRegime: "7,20,000",
            NewTaxRegime: "7,50,000",
          },
        ]}
      />
      <Content paragraph="(b) LTA is offered to private individuals with regard to citizens and non-citizens." />
      <Content paragraph="(c) In order to receive the LTA tax benefit, employees must give their employer the bills and travel documentation." />
      <Content paragraph="(d) The employee's family members' travel expenses are also covered." />
      <Content paragraph="(e) It can be used for two trips over the course of four years." />
      <Step
        number={`
          Do you have any doubts that it can be carried over for the next four years in case you are unable to use it?
        `}
      />
      <Content paragraph="The answer is yes, but only one can be carried forward and used in the first calendar year of the following years." />

      <Step
        number={`
          What is the exemption Under Leave Travel Allowance?
        `}
      />
      <Content paragraph="For instance, Mr. Dinesh works for XYZ LTD. Employees of the company were given leave travel allowance; nevertheless, Mr. Dinesh failed to take leave for his trip from April 1, 2016, to March 31, 2020." />
      <Content paragraph="He was granted leave to go by air to Delhi on June 1, 2020. The company's LTA (along with the travel expenditures) for this trip was Rs. 40,000, whereas Indian Airlines' economy class ticket was Rs. 30,000." />
      <Content paragraph="He was granted leave to travel by rail to Jaipur on March 15, 2022. The company paid Rs. 15,000 in leave travel concession (the same amount as travel expenditures) for this trip, but the air-conditioned first-class rail price was Rs. 20,000." />
      <Content paragraph="He was recently travelling to his village on December 20, 2023, where there was no recognized public transportation system and no rail service. The company gave him an LTA of Rs. 28,000 for this journey (the same amount he paid for travel expenses), but he had to travel to Amritsar first, which made the trip longer and cost Rs. 24,000 for first class rail, limited to Rs. 18,000 for the length of the trip taken by the shortest route." />
      <Content paragraph="Let us now determine the annual Exemption of Leave Travel Allowance." />
      <ReusableTable
        data={[
          { Particulars: "PY-2020-21", Amount: "40,000" },
          { Particulars: "PY-2021-22", Amount: "15,000" },
          { Particulars: "PY-2023-24", Amount: "28,000" },
        ]}
      />
      <Step
        number={`
          Medical Reimbursement Income Tax Rules
        `}
      />
      <Content paragraph="Expenses Incurred or reimbursed to the employee for the medical treatment for him or his family will be exempted from the Income Tax if:" />
      <Content paragraph="⦁ Hospital is Maintained by the Employer" />
      <Content paragraph="⦁ Alternatively, for the hospital that is run by the Central, State, or Local government" />
      <Content paragraph="⦁ Or for the hospital that has the Chief Commissioner of Income Tax's approval for the specified illnesses." />
      <Content paragraph="Here Family Includes Spouse, Children, Dependent Parents and Dependent Brother and Sister. Similarly, the insurance premium that is paid or reimbursed by the employer will also be exempt from the Income Tax." />
      <Step
        number={`
          If the Medical Treatment is Held outside India
        `}
      />
      <Content paragraph="Expenditure Incurred For the Medical Treatment will be exempt:" />
      <Content paragraph="⦁ To the extent of Medical Treatment permitted by Reserve Bank Of India" />
      <Content paragraph="⦁ All The expenditure incurred during travelling including the expenditure of one attendant will be exempted to the extent approved by Reserve Bank Of India" />
      <Content paragraph="⦁ Travel and medical expenses are only covered if the gross total income is less than Rs. 2,00,000." />
      <Step
        number={`
          Tax Implications of Helper Allowance
        `}
      />
      <Content paragraph="According to the provisions of the Income Tax Act of 1961, in India, you will not be required to pay income tax on any helper or assistance allowance you receive as part of your salary, up to the amount of actual expenses incurred." />
      <Content paragraph="For Example, if you Get Rs.36000 p.a. as helper allowance in your salary and you pay Rs 30000 p.a. to the helper then Rs 30000 will be exempted from your salary." />
      <Step
        number={`
          Tax Implications of Food Allowance
        `}
      />
      <Content paragraph="As per the Provisions of Income Tax Act 1961, in India if you get coupons from your employer for your food then it will be exempted from the Income Tax To the extent of Rs 50 per meal." />
      <Content paragraph="For example, if you get Rs 2100 per month (that is Rs 70 daily) for a meal then your Rs 1500 (that is Rs 50 Daily) will be exempted from income tax and you will only be required to pay tax on Rs 600 only and Rs 1500 will be saved." />
      <Step
        number={`
          Tax Implications of Children Education Allowance
        `}
      />
      <Content paragraph="As per the provisions of the Income Tax Act 1961, in India if you get Children Education Allowance from your employer then it will be exempted from the Income Tax up to Rs 100 per month per children." />
      <Content paragraph="This allowance can only be used for a maximum of two kids." />
      <Content paragraph="For example, you received Rs 48000 as children education allowance for your children then it will be exempted from Income Tax up to Rs 2400. And the remaining school fees you can claim under section" />
    </Box>
  );
};

export default LTAComponent;
