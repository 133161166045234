import * as React from 'react';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Divider } from '@mui/material';
import Content from '../../../../Components/Services/MainContent/Content';
import Mainhead from '../../../../Components/Services/MainContent/Mainhead';

export default function Faq() {
  const [expanded, setExpanded] = React.useState(false);

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  return (
    <div>
      <Mainhead heading="FAQ'S"/>
      <br/>
      <Divider sx={{border: '1px solid #FF990080'}}/>
      <Accordion expanded={expanded === 'panel1'} onChange={handleChange('panel1')}  elevation={0} sx={{ boxShadow: 'unset !important' }} disableGutters>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1bh-content"
          id="panel1bh-header"
        >
          <Typography  sx={{color:'#281900',fontWeight:450,fontSize:'20px'}}>What minimum number of partners are required in a Partnership Firm?</Typography>
        </AccordionSummary>
        <AccordionDetails>
         <Content paragraph ="Minimum 2 members are required to start a partnership firm"/>
            
         
        </AccordionDetails>
      </Accordion>
      <Divider sx={{border: '1px solid #FF990080'}}/>
      <Accordion expanded={expanded === 'panel2'} onChange={handleChange('panel2')}
       elevation={0} sx={{ boxShadow: 'unset !important' }} disableGutters>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel2bh-content"
          id="panel2bh-header"
        >
          <Typography sx={{color:'#281900',fontWeight:450,fontSize:'20px'}}>
          What maximum number of partners are permitted in a Partnership Firm?
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
         <Content paragraph="At most 20 members are permitted in a partnership firm"/>
        </AccordionDetails>
      </Accordion>
      <Divider sx={{border: '1px solid #FF990080'}}/>
      <Accordion expanded={expanded === 'panel3'} onChange={handleChange('panel3')}
       elevation={0} sx={{ boxShadow: 'unset !important' }} disableGutters>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel3bh-content"
          id="panel3bh-header"
        >
          <Typography sx={{color:'#281900',fontWeight:450,fontSize:'20px'}}>
          Can we convert a partnership firm to a private limited company?
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
         <Content paragraph="Yes you can do so by filing a prescribed form with the authority"/>
        </AccordionDetails>
      </Accordion>
      <Divider sx={{border: '1px solid #FF990080'}}/>
      <Accordion expanded={expanded === 'panel4'} onChange={handleChange('panel4')}
       elevation={0} sx={{ boxShadow: 'unset !important' }} disableGutters>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel4bh-content"
          id="panel4bh-header"
        >
          <Typography sx={{color:'#281900',fontWeight:450,fontSize:'20px'}}>Who are the partners in a Partnership Firm?</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Content paragraph="The partners residing in India can only become the partners in a Partnership firm. Foreign individual who wish to start their company can opt for Private Limited Company registration"/>
        </AccordionDetails>
      </Accordion>
      <Divider sx={{border: '1px solid #FF990080'}}/>
      <Accordion expanded={expanded === 'panel5'} onChange={handleChange('panel5')}
       elevation={0} sx={{ boxShadow: 'unset !important' }} disableGutters>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel5bh-content"
          id="panel5bh-header"
        >
          <Typography sx={{color:'#281900',fontWeight:450,fontSize:'20px'}}>What are Capital Requirements for the Partnership Firm Registration in India?</Typography>
        </AccordionSummary>
        <AccordionDetails>
         <Content paragraph="There is no minimum capital requirement for this registration in our country. All you need is current bank account balance, and rest of the documents as stated above"/>
          </AccordionDetails>
          </Accordion>


          <Divider sx={{border: '1px solid #FF990080'}}/>
      <Accordion expanded={expanded === 'panel6'} onChange={handleChange('panel6')}
       elevation={0} sx={{ boxShadow: 'unset !important' }} disableGutters>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel6bh-content"
          id="panel6bh-header"
        >
          <Typography sx={{color:'#281900',fontWeight:450,fontSize:'20px'}}>Does it require for a partnership form to file an annual return to the Registrar of Firms?</Typography>
        </AccordionSummary>
        <AccordionDetails>
         <Content paragraph="According to the Partnership Act,1932, there is no such provision of audit. However, if the turnover is more than INR 2 Crore, then it is mandatory to get the books of account audited"/>
          </AccordionDetails>
          </Accordion>

          <Divider sx={{border: '1px solid #FF990080'}}/>
      <Accordion expanded={expanded === 'panel7'} onChange={handleChange('panel7')}
       elevation={0} sx={{ boxShadow: 'unset !important' }} disableGutters>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel7bh-content"
          id="panel7bh-header"
        >
          <Typography sx={{color:'#281900',fontWeight:450,fontSize:'20px'}}>How to apply for the PAN of the Partnership firm?</Typography>
        </AccordionSummary>
        <AccordionDetails>
         <Content paragraph="You can apply for the partnership firm once the partnership deed is notarized. You can take our assistance in case you need to apply for PAN"/>
          </AccordionDetails>
          </Accordion>

          <Divider sx={{border: '1px solid #FF990080'}}/>
      <Accordion expanded={expanded === 'panel8'} onChange={handleChange('panel8')}
       elevation={0} sx={{ boxShadow: 'unset !important' }} disableGutters>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel8bh-content"
          id="panel8bh-header"
        >
          <Typography sx={{color:'#281900',fontWeight:450,fontSize:'20px'}}>How much time does it take to register a Partnership firm in India</Typography>
        </AccordionSummary>
        <AccordionDetails>
         <Content paragraph="The registration of a partnership firm in India may take approx 10-12 working days. However, the time taken to issue a certificate of incorporation may differ as per the regulations of the concerned state. The registration of a partnership firm is subject to government processing time which depends on the particular state"/>
          </AccordionDetails>
          </Accordion>

          <Divider sx={{border: '1px solid #FF990080'}}/>
      <Accordion expanded={expanded === 'panel9'} onChange={handleChange('panel9')}
       elevation={0} sx={{ boxShadow: 'unset !important' }} disableGutters>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel9bh-content"
          id="panel9bh-header"
        >
          <Typography sx={{color:'#281900',fontWeight:450,fontSize:'20px'}}>When my Partnership turns invalid?/ In what circumstances my partnership turns invalid?</Typography>
        </AccordionSummary>
        <AccordionDetails>
         <Content paragraph="A court may deem the partnership agreement invalid when the partnership agreement is not registered in the eye law and dissolve the partnership if the object of the business is not legal"/>
          </AccordionDetails>
          </Accordion>
          <Divider sx={{border: '1px solid #FF990080'}}/>
      <Accordion expanded={expanded === 'panel10'} onChange={handleChange('panel10')}
       elevation={0} sx={{ boxShadow: 'unset !important' }} disableGutters>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel10bh-content"
          id="panel10bh-header"
        >
          <Typography sx={{color:'#281900',fontWeight:450,fontSize:'20px'}}>At what instance my certificate of registration can be cancelled?</Typography>
        </AccordionSummary>
        <AccordionDetails>
         <Content paragraph="Sometimes a partnership certification of incorporation can be revoked which can be termed as dissolution. When all partners or except one partner is declared insolvent or if the firm is carrying unlawful activities like corporate malpractice, trading in drugs or other illegal products, or making business operations with countries that may harm the interest of the country; in such cases the dissolution can be brought upon automatically"/>
          </AccordionDetails>
          </Accordion>
      

          <Divider sx={{border: '1px solid #FF990080'}}/>
      <Accordion expanded={expanded === 'panel11'} onChange={handleChange('panel11')}
       elevation={0} sx={{ boxShadow: 'unset !important' }} disableGutters>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel11bh-content"
          id="panel11bh-header"
        >
          <Typography sx={{color:'#281900',fontWeight:450,fontSize:'20px'}}>How to quit the partnership among partners?</Typography>
        </AccordionSummary>
        <AccordionDetails>
         <Content paragraph="If it is a partnership of will, then they can do so by dissolving the partnership by notice if the partners of the firm wished to end the partnership of the firm. This can also be done in accordance with the terms laid out in the Partnership Deed of the firm, or they can do so by producing a separate agreement"/>
          </AccordionDetails>
          </Accordion>
      

          <Divider sx={{border: '1px solid #FF990080'}}/>
      <Accordion expanded={expanded === 'panel12'} onChange={handleChange('panel12')}
       elevation={0} sx={{ boxShadow: 'unset !important' }} disableGutters>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel12bh-content"
          id="panel12bh-header"
        >
          <Typography sx={{color:'#281900',fontWeight:450,fontSize:'20px'}}>Is there any scope of liability under the parameters of partnerships?</Typography>
        </AccordionSummary>
        <AccordionDetails>
         <Content paragraph="Every partner is jointly and severally accountable for any acts/activities of the firm committed throughout the course of business while he or she is a partner. This implies that if a third party is injured or a penalty is imposed during the course of business, all parties will be held accountable, even if one of the partners caused the injury or loss"/>
          </AccordionDetails>
          </Accordion>

          <Divider sx={{border: '1px solid #FF990080'}}/>
      <Accordion expanded={expanded === 'panel1'} onChange={handleChange('panel1')}  elevation={0} sx={{ boxShadow: 'unset !important' }} disableGutters>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1bh-content"
          id="panel1bh-header"
        >
          <Typography  sx={{color:'#281900',fontWeight:450,fontSize:'20px'}}>Glossary of Terms:</Typography>
        </AccordionSummary>
        <AccordionDetails>

          <b>ACKNOWLEDGEMENT:</b>
         <Content paragraph ="A statement, written or oral, made before a person authorized by law to administer oaths(such as a notary public)"/>

         <b>EMPLOYMENT AGREEMENT:</b>
         <Content paragraph="An agreement between an employer and an employee. This differs from other agreements, in that it is governed by employment law, which may take precedence over standard contract law."/>

         <b>GENERAL PARTNER:</b>
         <Content paragraph="A partner in a limited partnership who has authority to engage in operating the business."/>

        <b>REGISTERED OFFICE:</b>
         <Content paragraph ="The official address of the company, as designated with the secretary of the state. Any documents delivered to this address are considered to be legally served by the company."/>

        <b>PARTNERSHIP AT WILL:</b>
         <Content paragraph="A partnership in which the partners have not agreed to remain partners until the expiration of a definite term or the completion of a particular undertaking."/>

         <b>INSTRUMENT:</b>         
         <Content paragraph="A legal term for a document."/>
        
         <b>DISSOLUTION:</b>
        <Content paragraph="The termination of a partnership."/>
        
        </AccordionDetails>
      </Accordion>

      
          <Divider sx={{border: '1px solid #FF990080'}}/>
      

         




      


      


      



    </div>
  );
}