import React from "react";
import { Box } from "@mui/material";
import Mainhead from "../../../Components/Services/MainContent/Mainhead";
import Content from "../../../Components/Services/MainContent/Content";
import ReusableTable from "../../../Components/Income-Tax/ReusableTable";
import { Image } from "../../../Components/Income-Tax/ImageStyle";
import Image8 from "../../../Assets/IncomeTax/taxable1.png";
const Section80DSection80DD = () => {
  const medicalDeductionsData = [
    {
      APPLICABLE: "DEDUCTION",
      "RESIDENT INDIVIDUAL OR RESIDENT HUF": [
        "FIXED DEDUCTION: 75,000",
        "FIXED DEDUCTIONS 1,25,000 IN CASE OF SEVERE DISABILITY (OVER 80%)",
      ],
    },
    {
      APPLICABLE: "NATURE OF PAYMENT",
      "RESIDENT INDIVIDUAL OR RESIDENT HUF": [
        "Deductions available  if assessee incurred expenses:",
        "⦁	For medical treatment, nursing ,training and rehabilitation or ",
        "⦁	Amount deposited in approved scheme for this purpose of CBDT  or handicapped dependent relative ",
      ],
    },
    {
      APPLICABLE: "CONDITIONS",
      "RESIDENT INDIVIDUAL OR RESIDENT HUF": [
        "Disability should be certified by any physician or specialist",
        "The dependent shall not claim deduction u/s 80U",
        "Relative should not be dependent on any person other than assessee",
      ],
    },
  ];
  return (
    <Box
      style={{
        display: "flex",
        flexDirection: "column",
        gap: 4,
        marginBottom: "2rem",
      }}
    >
      <Mainhead heading="Section 80D - Deduction for Health Insurance Premium" />
      <Content paragraph="Eligible assessee: Individual or HUF" />
      <Content paragraph="For whom: (A) self, spouse, parents, and dependent children." />
      <Content paragraph="Mode of payment: HEALTH INSURANCE PREMIUM - Premium should not be paid in cash. However, payment for preventive health checkup can be in cash." />
      <Box sx={{ textAlign: "center" }}>
        <Image
          component="img"
          sx={{
            content: {
              xs: `url(${Image8})`,
            },
          }}
        />
      </Box>
      <Content paragraph="Note: Medical expenditure of senior citizens (aged 60 or more) will be treated as a medical insurance premium if a medical insurance policy is not taken on the life of such a person." />
      <Content paragraph="Note: Aggregate payment of preventive check health up of self, spouse, dependent children, and parents cannot exceed ₹5,000." />
      <Content paragraph="Note: Where the premium for health insurance is paid in a lump sum - then the deduction shall be allowed under this section for each relevant previous year would be equal to the appropriate fractions of such a lump-sum payment." />
      <Content paragraph="Contribution to CGHS/notified scheme: Contributions made by individuals to the Central Government Health Scheme (CGHS) or any other notified scheme are eligible for a tax deduction of up to ₹25,000. However, this deduction does not apply to any contributions made on behalf of parents." />
      <Content paragraph="Things to keep in mind while purchasing health insurance in order to claim the 80D deduction:" />
      <Content paragraph="- It is not permitted to deduct medical insurance premiums for siblings, grandparents, aunts, uncles, or any other relative in order to receive tax benefits." />
      <Content paragraph="- The premium paid for a working child cannot be claimed as a tax deduction." />
      <Content paragraph="- If you and your parents each make a partial contribution, you can both claim a deduction for the amount that was paid." />
      <Content paragraph="- The service tax and cess part from the premium amount must be subtracted without being visible." />
      <Content paragraph="- Group health insurance premium provided by the company is not eligible for deduction." />
      <Content paragraph="- Any method other than cash used to pay the premium may be deducted. Therefore, the premium paid online or with a credit card is also deductible." />

      <Mainhead heading="Section 80DD - Deduction for Maintenance of a Dependent with Disability" />

      <ReusableTable data={medicalDeductionsData} />

      <Content paragraph="Note: If the dependent handicapped predeceases the individual, then the amount received by the assessee will be taxable." />
      <Content paragraph="What Separates Section 80U from Section 80DD:" />
      <Content paragraph="A person who has been certified as a disabled person may claim the deduction for themself under Section 80U. On the other hand, Section 80DD permits tax deductions for medical costs incurred in support of a disabled dependant." />
      <Content paragraph="It is crucial to note that if the dependant has previously claimed a deduction for themself under Section 80U, they are not eligible to claim one under Section 80DD." />
      <Content paragraph="Conditions covered by Section 80DD:" />
      <Content paragraph="- Mental illness" />
      <Content paragraph="- Hearing impairment" />
      <Content paragraph="- Mental retardation" />
      <Content paragraph="- Cerebral palsy" />
      <Content paragraph="- Leprosy-cured" />
      <Content paragraph="- Autism" />
      <Content paragraph="- Loco motor disability" />
      <Content paragraph="- Blindness" />
      <Content paragraph="- Low vision" />
      <Content paragraph="Who is in charge of providing a dependent with a disability with a medical certificate?" />
      <Content paragraph="A person can be certified as disabled by one of the medical authorities listed below:" />
      <Content paragraph="- A government hospital’s Civil Surgeon or Chief Medical Officer (CMO)." />
      <Content paragraph="- A Neurologist with a Doctor of Medicine (MD) degree in Neurology. In case of a child, a Paediatric Neurologist holding an equivalent degree." />
    </Box>
  );
};

export default Section80DSection80DD;
