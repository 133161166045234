import React from "react";
import { Box } from "@mui/material";
import Mainhead from "../../../Components/Services/MainContent/Mainhead";
import Step from "../../../Components/Services/MainContent/Step";
import Content from "../../../Components/Services/MainContent/Content";

const CapitalGainsSections = () => {
  return (
    <Box
      style={{
        display: "flex",
        flexDirection: "column",
        gap: 4,
        marginBottom: "2rem",
      }}
    >
      {" "}
      <Mainhead heading="Important Sections under Capital Gain - Income Tax Act, 1961" />
      <Step number="Section 48:" />
      <Content
        paragraph="The income chargeable under the head
        'capital asset' shall be computed by deducting from the full value of
        the consideration received or accruing as a result of the transfer of
        the capital asset the following amounts:"
      />
      <ol className="list">
        <li>
          (a) Expenditure incurred wholly and exclusively in connection with
          such transfer.
        </li>
        <li>
          (b) The cost of acquisition of the asset and the cost of any
          improvement thereto.
        </li>
      </ol>
      <Step number="First proviso:" />
      <Content
        paragraph="This proviso is introduced for calculating capital gain in the case
        where the assessee is a non-resident (NRI), and the asset being
        transferred is shares/debentures in an Indian company, acquired in
        foreign currency by way of purchase or reinvestment. It states that
        capital gain shall be first computed in the foreign currency and then be
        reconverted into Indian currency."
      />
      <Step number="Section 45(5):" />
      <Content
        paragraph="This section covers capital gain in the case of the transfer of assets
        resulting from compulsory acquisition by any law. In such a case,
        capital gain will be taxable in the year in which compensation is
        received, even if it is different from the year of transfer. The initial
        compensation will be taxable in the year in which the first installment
        is received. Any enhancement in consideration will be taxable as and
        when received."
      />
      <Step number="Section 45(1A):" />
      <Content
        paragraph="If any capital asset is destroyed due to fire, flood, earthquake, civil
        disturbance, etc., and an insurance claim is received, then such amount
        will be subjected to capital gain. It will be taxed in the year in which
        the insurance claim is received."
      />
    </Box>
  );
};

export default CapitalGainsSections;
