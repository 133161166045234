import React from "react";
import { Box } from "@mui/material";
import Mainhead from "../../../Components/Services/MainContent/Mainhead";
import Content from "../../../Components/Services/MainContent/Content";
import Step from "../../../Components/Services/MainContent/Step";
import CustomList from "../../../Components/Income-Tax/CustomList";
import ReusableTable from "../../../Components/Income-Tax/ReusableTable";

const ComputationSection29 = () => {
  return (
    <Box
      style={{
        display: "flex",
        flexDirection: "column",
        gap: 4,
        marginBottom: "2rem",
      }}
    >
      <Mainhead heading="Computation of Taxable Income from Business & Profession - Section 29" />
      <Content paragraph="According to section 29 of the Income Tax Act of 1961, every income included in section 28 of that act will be subject to charges in line with the guidelines outlined in sections 30 through 43D." />
      <Content paragraph="The profit and loss account should be used to compute business profit. Certain expenses in the profit and loss account are either partially or fully allowed by the income tax act. Certain income is shown on the credit side of the profit and loss account that is either tax-free or not taxable under the business or profession heading." />
      <Step number="What are the various steps for computation of Income from Business & Profession?" />
      <CustomList
        points={[
          "1. Gross Receipts or Turnover: Calculate the total income from your business or profession, known as 'gross receipts' or 'turnover.' This includes all revenue generated from the sale of goods or services.",
          "2. Deductible Expenses: Deduct allowable business expenses from the gross receipts. These may include Cost of Goods Sold (COGS), Operating Expenses, Depreciation, and Interest.",
          "3. Depreciation: Calculate depreciation on business assets based on the rates provided by the Income Tax Act.",
          "4. Allowable Deductions: Consider additional allowable deductions like Bad Debts, Preliminary Expenses, and certain Capital Expenditures.",
          "5. Other Incomes: Include any other incomes related to the business or profession, such as interest income, rental income, or income from the sale of assets.",
          "6. Net Income: Calculate the net income by subtracting total expenses, depreciation, and allowable deductions from the gross receipts.",
          "7. Taxable Income: Deduct exemptions and deductions available under the Income Tax Act. This may include deductions for investments, allowances, and other eligible items.",
          "8. Applicable Tax Rate: Apply the applicable income tax rate on the taxable income. The rates may vary depending on the legal structure of your business and the applicable tax laws.",
          "9. Paying Taxes: After calculating the income tax liability, pay the due amount within the specified timelines. Failure to do so may result in penalties and interest.",
        ]}
      />
      <Step number="DIRECT METHOD" />
      <ReusableTable
        data={[
          { PARTICULAR: "Consultancy fees", AMOUNT: "xxx", AMOUNT2: "-" },
          { PARTICULAR: "Audit fees", AMOUNT: "xxx", AMOUNT2: "-" },
          { PARTICULAR: "Other income", AMOUNT: "xxx", AMOUNT2: "xxx" },
          {
            PARTICULAR: "Less: Expenditure allowed as per section 30 to 43D",
            AMOUNT: "-",
            AMOUNT2: "-",
          },
          { PARTICULAR: "Office rent (u/s 30)", AMOUNT: "xxx", AMOUNT2: "-" },
          {
            PARTICULAR: "Repair of plant & machinery (u/s 31)",
            AMOUNT: "xxx",
            AMOUNT2: "-",
          },
          { PARTICULAR: "Depreciation ( u/s 32)", AMOUNT: "xxx", AMOUNT2: "-" },
          {
            PARTICULAR: "Sundry expenses (u/s 36 to 37)",
            AMOUNT: "xxx",
            AMOUNT2: "(xxx)",
          },
          {
            PARTICULAR: "Profit and gains from business & profession",
            AMOUNT: "",
            AMOUNT2: "xxx",
          },
        ]}
      />
      <Step number="INDIRECT METHOD" />
      <ReusableTable
        data={[
          {
            PARTICULAR: "Net profit as per accounting P&L",
            AMOUNT: "",
            AMOUNT2: "Profit (+)",
          },
          {
            PARTICULAR: "Add: Expenditure disallowed but debited to P&L",
            AMOUNT: "",
            AMOUNT2: "xxx",
          },
          { PARTICULAR: "Personal expenditure", AMOUNT: "xxx", AMOUNT2: "" },
          { PARTICULAR: "Capital expenditure", AMOUNT: "xxx", AMOUNT2: "" },
          {
            PARTICULAR: "Any provision or reserve",
            AMOUNT: "xxx",
            AMOUNT2: "",
          },
          {
            PARTICULAR: "Advertisement of political party",
            AMOUNT: "xxx",
            AMOUNT2: "",
          },
          {
            PARTICULAR:
              "Add: income taxable under business & profession head but not credited in P&L account",
            AMOUNT: "xxx",
            AMOUNT2: "",
          },
          {
            PARTICULAR: "Less: income not taxable but credited to P&L:",
            AMOUNT: "(xxx)",
            AMOUNT2: "",
          },
          { PARTICULAR: "Dividend u/s 10 (34)", AMOUNT: "xxx", AMOUNT2: "" },
          {
            PARTICULAR: "Sale on agriculture product",
            AMOUNT: "xxx",
            AMOUNT2: "",
          },
          {
            PARTICULAR: "Share of profit from partnership firm or huf",
            AMOUNT: "xxx",
            AMOUNT2: "",
          },
          {
            PARTICULAR:
              "Less: income credited to P&L but related to other head:",
            AMOUNT: "(xxx)",
            AMOUNT2: "",
          },
          {
            PARTICULAR: "Rent from house property",
            AMOUNT: "xxx",
            AMOUNT2: "",
          },
          {
            PARTICULAR: "Interest on income tax refund",
            AMOUNT: "xxx",
            AMOUNT2: "",
          },
          {
            PARTICULAR:
              "Less: closing stock overvalued or opening stock undervalued",
            AMOUNT: "(xxx)",
            AMOUNT2: "",
          },
          {
            PARTICULAR:
              "Add: closing stock overvalued or opening stock overvalued",
            AMOUNT: "xxx",
            AMOUNT2: "",
          },
          {
            PARTICULAR:
              "Add: expenses debited to P&L but not specifically not allowed:",
            AMOUNT: "xxx",
            AMOUNT2: "",
          },
          {
            PARTICULAR: "Cash purchase exceeds RS. 10,000",
            AMOUNT: "xxx",
            AMOUNT2: "",
          },
          {
            PARTICULAR: "Payment to relatives exceeds FMV",
            AMOUNT: "xxx",
            AMOUNT2: "",
          },
          {
            PARTICULAR: "Income tax or wealth tax",
            AMOUNT: "xxx",
            AMOUNT2: "",
          },
          {
            PARTICULAR: "Profit and gains from business & profession",
            AMOUNT: "xxx",
            AMOUNT2: "",
          },
        ]}
      />

      <Step number="Rule for adjustments of profit and loss accounts" />
      <Content paragraph="From an income tax perspective, the assessee's profit and loss account may have inaccuracies, such as:" />
      <CustomList
        points={[
          "Many expenses that are fully or partially permissible are not accounted for.",
          "Certain admissible expenses are omitted from the accounts.",
          "Certain taxable income is not credited.",
        ]}
      />
    </Box>
  );
};

export default ComputationSection29;
