import React from "react";
import { Box } from "@mui/material";
import Mainhead from "../../../Components/Services/MainContent/Mainhead";
import Content from "../../../Components/Services/MainContent/Content";
import CustomList from "../../../Components/Income-Tax/CustomList";
import Step from "../../../Components/Services/MainContent/Step";
import ReusableTable from "../../../Components/Income-Tax/ReusableTable";

const DeemedBusinessIncomeAndDepreciation = () => {
  const depreciationTableData = [
    {
      "As per Accounting System": "Charges for profit.",
      "As per Taxation System": "In nature, there are allowances.",
    },
    {
      "As per Accounting System": "Individual Asset Depreciation is charged",
      "As per Taxation System": "Depreciation is applied to the asset block.",
    },
    {
      "As per Accounting System": "Life of the asset.",
      "As per Taxation System": "Rate prescribed.",
    },
    {
      "As per Accounting System":
        "Based on the number of days the asset was used.",
      "As per Taxation System":
        "50% of normal Depreciation (if asset is used for less than 180 days) Or Normal Depreciation.",
    },
    {
      "As per Accounting System": "Only applicable to tangible assets.",
      "As per Taxation System": "Assets can be both tangible and intangible.",
    },
    {
      "As per Accounting System": "The WDV / SLM technique is permitted.",
      "As per Taxation System":
        "Only the WDV technique is permitted. (The SLM Method can be used by the Electricity Generation Unit.)",
    },
  ];

  const assetCategoriesTableData = [
    { "S.r. no.": 1, "Category of Asset": "Building" },
    { "S.r. no.": 2, "Category of Asset": "Furniture and Fittings." },
    { "S.r. no.": 3, "Category of Asset": "Machinery and Plant" },
    { "S.r. no.": 4, "Category of Asset": "Ships" },
    {
      "S.r. no.": 5,
      "Category of Asset":
        "Intangible Asset (Know-how, Patents, Copyrights, Trademarks, Licenses, Franchises or Commercial rights).",
    },
  ];

  const qualificationsSection32TableData = [
    {
      sr: "1",
      Qualification: "Goodwill and land are not eligible for depreciation.",
    },
    {
      sr: "2",
      Qualification:
        "Since a lessee does not own a piece of property, a lessor will be subject to depreciation. Lessees will be entitled to depreciation, nevertheless, if they build furniture or any component of it.",
    },
    {
      sr: "4",
      Qualification:
        "Taxpayers are entitled to depreciation if they buy property through a hire purchase agreement.",
    },
    {
      Qualification:
        "Co-owners may deduct depreciation in the amount that represents their ownership stake in an asset.",
      sr: "5",
    },
    {
      Qualification:
        "If a taxpayer does not claim the depreciation amount as a deduction, the amount of depreciation will be deducted from the sum of Written Down Value carried forward to the subsequent year.",
      sr: "6",
    },
    {
      Qualification:
        "The Companies Act of 1956 differs from the IT Act in terms of depreciation. As a result, independent of the depreciation payable under the books of accounts, the IT Department defines the depreciation rates.",
      sr: "7",
    },
    {
      Qualification:
        "If a taxpayer has spare machines or parts that have not been used, depreciation will apply to them.",
      sr: "8",
    },
    {
      Qualification:
        "Depreciation can be claimed by a taxpayer at a reduced rate. The tax authority will, however, take into account the WDV as reduced by the designated depreciation % for the next year.",
      sr: "9",
    },
    {
      Qualification:
        "If a taxpayer wants to claim an Input Tax Credit (ITC) for the amount of GST payable, depreciation is not relevant to the GST (Goods and Services Tax) component.",
      sr: "10",
    },
  ];

  const depreciationRateTableData = [
    {
      "S.r. no.": 1,
      "Type of Asset": "Building",
      "Rate of Depreciation":
        "Residential Purpose: 5%, Business Purpose: 10%, Installing Plant and Machinery /Temporary Building: 40%",
    },
    {
      "S.r. no.": 2,
      "Type of Asset": "Furniture and Fittings",
      "Rate of Depreciation": "10%",
    },
    {
      "S.r. no.": 3,
      "Type of Asset": "Plant and Machinery",
      "Rate of Depreciation":
        "General: 15%, Motor Car used on hire: 15%, Motor Car used for own business: 30%, Remaining Assets [Books, Computers /etc]: 40%",
    },
    { "S.r. no.": 4, "Type of Asset": "Ships", "Rate of Depreciation": "20%" },
    {
      "S.r. no.": 5,
      "Type of Asset": "Intangible Assets",
      "Rate of Depreciation": "25%",
    },
  ];
  return (
    <Box
      style={{
        display: "flex",
        flexDirection: "column",
        gap: 4,
        marginBottom: "2rem",
      }}
    >
      {/* Deemed Business Income */}
      <Mainhead heading="Deemed Business Income" />
      <Step
        number={`
            Introduction
          `}
      />
      <Content paragraph="Section 41 of the Income Tax Act, 1961 in India deals with the tax treatment of certain benefits obtained by a taxpayer when a trading liability or a deduction claimed in an earlier year ceases to exist or becomes irrecoverable. This section applies to both businesses and individuals subject to tax on business income." />
      <Step
        number={`
            Which Income will be treated as Deemed Income?
          `}
      />
      <CustomList
        points={[
          "Remission or Cessation of Trading Liability (Subsection 1): When a deduction has been claimed, and the liability ceases to exist, the amount of remission or cessation is treated as deemed income.",
          "Recovery of Bad Debts or Allowance for Bad Debts (Subsection 2): If any allowance or deduction for bad debts has been allowed and there is recovery, the recovered amount is treated as deemed income.",
        ]}
      />
      <Step
        number={`
            Key points to consider under Section 41:
          `}
      />
      <CustomList
        points={[
          "Section 41 operates on the principle of taxing the benefit received by the taxpayer due to the remission of a trading liability or recovery of bad debts.",
          "It ensures that when a benefit is derived by the taxpayer due to the remission or recovery of debts for which a deduction was earlier claimed, the amount of benefit received is added to the taxpayer's income in the year of such remission or recovery.",
          "The provision aims to prevent the misuse of deductions or allowances claimed in earlier years and ensures that the benefit derived from them, when it no longer remains a genuine liability or is recovered, is taxed appropriately.",
        ]}
      />

      {/* Depreciation */}
      <Mainhead heading="Depreciation under Section 32 of Income Tax Act 1961, in India" />
      <Step
        number={`
            What does depreciation mean?
          `}
      />
      <Content paragraph="Depreciation is the process of accounting for an asset's cost over its useful life. It is a non-cash expense that reduces the business's taxable income as well as the asset's balance-sheet value. Depreciation on assets used for business or profession is deductible under the Income Tax Act." />
      <Step
        number={`
            What are the various conditions to claim depreciation?
          `}
      />
      <CustomList
        points={[
          "The asset should have been used in the previous year.",
          "The asset should be used for professional or commercial purposes.",
          "A taxpayer must own the asset (even if they are not registered owners).",
        ]}
      />
      <Mainhead heading="Depreciation: Accounting vs. Taxation" />
      <Step
        number={`
            Is Depreciation as per Accounting System and Taxation System Same?
          `}
      />
      <ReusableTable data={depreciationTableData} />

      <Step
        number={`
            What are the various categories of Asset for Depreciation?
          `}
      />
      <ReusableTable data={assetCategoriesTableData} />

      <Step
        number={`
            Qualifications in accordance with Section 32
          `}
      />
      <Content paragraph="The fundamentals of depreciation under Section 32 of the Income Tax Act are as follows:" />
      <ReusableTable data={qualificationsSection32TableData} />

      <Step
        number={`
            When will 50% Depreciation will be applicable?
          `}
      />
      <Content paragraph="The assessee is only eligible to get 50% of normal Depreciation if an asset is purchased and utilized for no more than 180 days in the same prior year." />

      <Step
        number={`
            What is the Depreciation rate as per Income Tax Act,1961?
          `}
      />
      <ReusableTable data={depreciationRateTableData} />
    </Box>
  );
};

export default DeemedBusinessIncomeAndDepreciation;
