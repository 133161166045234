import React from "react";
import { Box } from "@mui/material";
import Mainhead from "../../../Components/Services/MainContent/Mainhead";
import Content from "../../../Components/Services/MainContent/Content";
import ReusableTable from "../../../Components/Income-Tax/ReusableTable";
import Step from "../../../Components/Services/MainContent/Step";

const PerquisiteVsAllowance = () => {
  const taxablePerquisitesData = [
    { "S.R. NO.": "1.", PARTICULARS: "Rent Free Accommodation" },
    { "S.R. NO.": "2.", PARTICULARS: "Concession in rent" },
    {
      "S.R. NO.": "3.",
      PARTICULARS:
        "Payment by the employer in respect of an obligation of the employee",
    },
    {
      "S.R. NO.": "4.",
      PARTICULARS:
        "Amount payable by an employer directly or indirectly to effect an assurance on the life of the assessee",
    },
    {
      "S.R. NO.": "5.",
      PARTICULARS:
        "Specified security or sweat equity shares allotted or transferred by the employer",
    },
    {
      "S.R. NO.": "6.",
      PARTICULARS:
        "Amount or the aggregate of amounts of any contribution made to the account of the assessee by the employer in a recognized provident fund, in NPS, in an approved superannuation fund",
    },
    {
      "S.R. NO.": "7.",
      PARTICULARS:
        "Annual accretion to the balance at the credit of the recognized provident fund/NPS/approved superannuation fund which relates to the employer’s contribution and included in total income (on account of the same having exceeded 7,50,000)",
    },
    {
      "S.R. NO.": "8.",
      PARTICULARS:
        "Any other fringe benefit or amenity like interest-free or concessional loan, traveling, touring and accommodation, free or concessional food and non-alcoholic beverages, gift, voucher or token in lieu of such gift, credit card expense, club expenditure, use of movable assets, transfer of movable assets, other benefit or amenity",
    },
  ];

  // Tax Free Perquisites Table Data
  const taxFreePerquisitesData = [
    { "S.R. NO.": "1.", PARTICULARS: "Telephone facility" },
    { "S.R. NO.": "2.", PARTICULARS: "Transport Facility" },
    { "S.R. NO.": "3.", PARTICULARS: "Privilege passes and privilege ticket" },
    {
      "S.R. NO.": "4.",
      PARTICULARS: "Perquisites allowed outside India by the Government",
    },
    {
      "S.R. NO.": "5.",
      PARTICULARS: "Employer’s contribution to staff group insurance scheme",
    },
    {
      "S.R. NO.": "6.",
      PARTICULARS: "Annual premium by employer on personal accident policy",
    },
    { "S.R. NO.": "7.", PARTICULARS: "Refreshment" },
    { "S.R. NO.": "8.", PARTICULARS: "Subsidized lunch" },
    { "S.R. NO.": "9.", PARTICULARS: "Recreational facilities" },
    { "S.R. NO.": "10.", PARTICULARS: "Amount spent on training of employees" },
    {
      "S.R. NO.": "11.",
      PARTICULARS:
        "Sum payable by employer to a Registered Provident Fund or an approved superannuation fund",
    },
    {
      "S.R. NO.": "12.",
      PARTICULARS:
        "Leave travel concession (Note: Value of Leave travel concession provided to the High Court judge or the Supreme Court Judge and members of his family are completely exempt without any conditions)",
    },
    { "S.R. NO.": "13.", PARTICULARS: "Medical facilities" },
    { "S.R. NO.": "14.", PARTICULARS: "Rent-free official residence" },
    { "S.R. NO.": "15.", PARTICULARS: "Conveyance facility" },
  ];

  // Taxable Perquisites for Specified Employees Table Data
  const specifiedEmployeesTaxablePerquisitesData = [
    { "S.R. NO.": "1.", PARTICULARS: "Director employee" },
    {
      "S.R. NO.": "2.",
      PARTICULARS:
        "An employee who has substantial interest in the company (substantial interest means beneficial ownership of equity shares carrying 20% or more of the voting power in the company.)",
    },
    { "S.R. NO.": "3.", PARTICULARS: "Employee drawing in excess of 50,000" },
  ];
  // Taxable Allowances Table Data
  const taxableAllowancesData = [
    { "S.R. NO.": "1.", PARTICULARS: "Entertainment Allowance" },
    { "S.R. NO.": "2.", PARTICULARS: "Dearness Allowance" },
    { "S.R. NO.": "3.", PARTICULARS: "Overtime Allowance" },
    { "S.R. NO.": "4.", PARTICULARS: "Fixed Medical Allowance" },
    {
      "S.R. NO.": "5.",
      PARTICULARS:
        "City Compensatory Allowance (to meet increased cost of living in cities)",
    },
    { "S.R. NO.": "6.", PARTICULARS: "Interim Allowance" },
    { "S.R. NO.": "7.", PARTICULARS: "Servant Allowance" },
    { "S.R. NO.": "8.", PARTICULARS: "Project Allowance" },
    { "S.R. NO.": "9.", PARTICULARS: "Tiffin/Lunch/Dinner Allowance" },
    { "S.R. NO.": "10.", PARTICULARS: "Non-practicing Allowance" },
    { "S.R. NO.": "11.", PARTICULARS: "Warden Allowance" },
    {
      "S.R. NO.": "12.",
      PARTICULARS:
        "Transport allowance to employee other than blind/deaf and dumb/orthopedically handicapped employee",
    },
    { "S.R. NO.": "13.", PARTICULARS: "Any other cash allowance" },
  ];

  // Partially Taxable Allowances Table Data
  const partiallyTaxableAllowancesData = [
    { "S.R. NO.": "1.", PARTICULARS: "House Rent Allowance [u/s 10(13A)]" },
    {
      "S.R. NO.": "2.",
      PARTICULARS: "Special Allowances [u/s 10(14)] (Except *",
    },
    { "S.R. NO.": "2(a).", PARTICULARS: "Travelling allowance" },
    { "S.R. NO.": "2(b).", PARTICULARS: "Daily allowance" },
    { "S.R. NO.": "2(c).", PARTICULARS: "Conveyance allowance" },
    {
      "S.R. NO.": "2(d).",
      PARTICULARS:
        "Transport allowance to blind/deaf and dumb/orthopedically handicapped employee*",
    },
    {
      "S.R. NO.": "3.",
      PARTICULARS: "*These allowances are partially exempt under both regimes",
    },
  ];

  // Fully Exempt Allowances Table Data
  const fullyExemptAllowancesData = [
    { "S.R. NO.": "1.", PARTICULARS: "Allowances to High Court Judges" },
    {
      "S.R. NO.": "2.",
      PARTICULARS:
        "Salary and Allowances paid by the United Nations Organization",
    },
    {
      "S.R. NO.": "3.",
      PARTICULARS:
        "Sumptuary allowance granted to High Court or Supreme Court Judges",
    },
    {
      "S.R. NO.": "4.",
      PARTICULARS:
        "Allowance granted to Government employees outside India (section 10(7))*",
    },
    { "S.R. NO.": "5.", PARTICULARS: "*Fully Exempt under both tax regimes" },
    {
      "S.R. NO.": "6.",
      PARTICULARS:
        "Except * all these allowances are fully exempt under the old tax (default) regime.",
    },
  ];

  const tableData = [
    {
      "Sr. No": "1.",
      Allowances:
        "Allowances are extra cash or benefits given to workers to help with certain costs associated with their jobs or personal situations.",
      Perquisites:
        "The term 'perks' refers to non-cash incentives or privileges that employers grant to their staff members in addition to their pay or compensation.",
    },
    {
      "Sr. No": "2.",
      Allowances:
        "To cover certain demands or expenses, allowances are frequently given as a set sum of money or as a predetermined proportion of an employee's pay.",
      Perquisites:
        "Generally speaking, perks are non-cash bonuses or privileges that are added to an employee's total remuneration package.",
    },
    {
      "Sr. No": "3.",
      Allowances:
        "Travel, housing, medical, and educational expenses are a few examples of the several types of allowances.",
      Perquisites:
        "Club memberships, company-provided cars, housing or lodging alternatives, and stock options are a few examples of types of perks.",
    },
    {
      "Sr. No": "4.",
      Allowances:
        "Employees typically receive allowances to support their personal circumstances or to make up for specific costs they incur while carrying out their job obligations.",
      Perquisites:
        "The purpose of perks is to give workers extra advantages or privileges, frequently connected to their role or status within the company.",
    },
    {
      "Sr. No": "5.",
      Allowances:
        "Typically, allowances are given on a regular basis—monthly or annually, for example—and are routinely evaluated in light of evolving conditions or regulations.",
      Perquisites:
        "Depending on the particulars of the offer and the organisation's policies, perks may be given continuously or as one-time incentives.",
    },
    {
      "Sr. No": "6.",
      Allowances: "Allowances raise one's tax obligations",
      Perquisites: "A perquisite lowers tax obligations",
    },
  ];

  return (
    <Box
      style={{
        display: "flex",
        flexDirection: "column",
        gap: 4,
        marginBottom: "2rem",
      }}
    >
      <Mainhead heading="Difference Between Perquisite and Allowance in India" />
      <Content paragraph="What does a perquisite mean in the Income Tax Act, 1961 in India?" />
      <Content paragraph="In addition to salary or compensation, a perquisite is any casual emolument or perk related to an office or post. Perquisites are supplementary perks provided by the employer in the form of goods or services, such as housing, a corporate car, or club memberships." />
      <Content paragraph="What does Allowance mean in the Income Tax Act, 1961 In India?" />
      <Content paragraph="An allowance is a financial advantage provided by the employer to the employee in addition to the regular income. These benefits are granted to offset expenses incurred to facilitate service discharge." />

      <Content paragraph="ARE PERQUISITES SAME AS THE ALLOWANCES?" />
      <ReusableTable data={tableData} />
      <Mainhead heading="List of Allowances" />
      <Step
        number={`
            Taxable Allowances
          `}
      />
      <ReusableTable data={taxableAllowancesData} />
      <Content paragraph="All these allowances are taxable under both the new tax regime (default) as well as the old tax regime (optional) regime." />

      <Step
        number={`
            Partially Taxable ALLOWANCES
          `}
      />
      <ReusableTable data={partiallyTaxableAllowancesData} />
      <Content paragraph="These allowances are partially taxable under the old tax regime only." />

      <Step
        number={`
            Fully Exempt ALLOWANCES
          `}
      />
      <ReusableTable data={fullyExemptAllowancesData} />
      <Content paragraph="Except * all these allowances are fully exempt under the old tax (default) regime." />
      <Mainhead heading="List of Perquisites" />

      <Content paragraph="List of Taxable Perquisites" />
      <ReusableTable data={taxablePerquisitesData} />
      <Content paragraph="With one exception: If a judge of the Supreme Court or a High Court chooses to opt out of the default tax regime outlined in section 115 BAC(1A), their rent-free official residence is not subject to taxes." />

      <Content paragraph="Tax Free Perquisites subject to conditions" />
      <ReusableTable data={taxFreePerquisitesData} />

      <Content paragraph="Perquisites taxable only in the hands of specified employees [Section 17(2)(iii)]" />
      <ReusableTable data={specifiedEmployeesTaxablePerquisitesData} />
      <Content paragraph="Specified employees include Directors, employees with substantial interest in the company, and employees drawing in excess of 50,000." />
    </Box>
  );
};

export default PerquisiteVsAllowance;
