import React from 'react';
import Mainhead from '../../../../Components/Services/MainContent/Mainhead';
import Step from '../../../../Components/Services/MainContent/Step';
import List from '../../../../Components/Services/MainContent/List';
import { Box , styled} from '@mui/material';
import Registrationpartner from "../../../../Assets/Services/Partnership/LegalFactory_Partnership3.png"

const Registrationimg = styled(Box)(({ theme }) => ({
  width:'28.5rem',
  height:'32rem',
  marginTop:'0.8rem',
  [theme.breakpoints.down("sm")]: {
    width:'18rem',
  height:'21.5rem',
  },
  [theme.breakpoints.down("md")]: {
    width:'18rem',
  height:'21.5rem',
  },
  [theme.breakpoints.between("md", "lg")]: {
    width:'25rem'
  }
}));

function Checklist() {
  return (
    <div>

      <Mainhead heading="Checklist for company registration in India"/>
      <ul className='list'>
        <li className='bullet'>
          <span>
      <List points="The name shouldn’t be too similar or identical to an existing firm operating in the industry."/>
      </span>
      </li>
      <li className='bullet'>
          <span>
      <List points="The name shouldn’t contain words like crown, empress, emperor, empire or any other words which show sanction or authorisation of the government."/>
      </span>
      </li>
      </ul>
      
      <br/>
      <Box sx={{textAlign:'center'}}>
      <Registrationimg component="img"
                sx={{
                  content: {
                    xs: `url(${Registrationpartner})`
                  }
                }}/>
                </Box>
          <br/>
          <b> Things Ought To Be Done: </b>
      <Step number="Drafting of Partnership deed."/>
      <List points="Minimum two members as partners."/>
      <List points="Maximum number of partners equal to or less than 20."/>
      <List points="Selection of appropriate names."/>
      <List points="Principal place of business"/>
      <List points="PAN card and bank account of the firm"/>
      <List points="Initial investment to start the firm"/>
    
    </div>
  )
}

export default Checklist;