import React from 'react';
import Mainhead from '../../../../Components/Services/MainContent/Mainhead';
import Step from '../../../../Components/Services/MainContent/Step';
import List from '../../../../Components/Services/MainContent/List';


function Checklist() {
  return (
    <div>

      <Mainhead heading="Checklist For Producer Company Registration"/>
      <Step number="Pre-Incorporation criteria which needs to be satisfied by the producer company :"/>
      <ul className='list'>
        <li className='bullet'>
          <span>
      <List points="There should be a minimum number of 5 directors and 10 members to incorporate the company."/>
      </span>
      </li>
      <li className='bullet'>
          <span>
      <List points="A minimum paid-up capital of Rs.5 lakhs or more to register a company"/>
      </span>
      </li>
      <li className='bullet'>
          <span>
      <List points="No limit for the maximum number of members in a producer company"/>
      </span>
      </li>
      <li className='bullet'>
          <span>
      <List points="Also, the company cannot be registered or considered as a Public Limited Company"/>
      </span>
      </li>
      <li className='bullet'>
          <span>
      <List points="The Company can only possess equity share capital."/>
      </span>
      </li>
      <li className='bullet'>
          <span>
      <List points="The company must carry on 4 board meetings per year, and it should hold after every three months."/>
      </span>
      </li>
      </ul>
    </div>
  )
}

export default Checklist;