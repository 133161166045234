import * as React from 'react';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Divider } from '@mui/material';
import Content from '../../../../Components/Services/MainContent/Content';
import Mainhead from '../../../../Components/Services/MainContent/Mainhead';

export default function Faq() {
  const [expanded, setExpanded] = React.useState(false);

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  return (
    <div>
      <Mainhead heading="FAQ'S"/>
      <br/>
      <Divider sx={{border: '1px solid #FF990080'}}/>
      <Accordion expanded={expanded === 'panel1'} onChange={handleChange('panel1')}  elevation={0} sx={{ boxShadow: 'unset !important' }} disableGutters>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1bh-content"
          id="panel1bh-header"
        >
          <Typography  sx={{color:'#281900',fontWeight:450,fontSize:'20px'}}>Do I need to be physically present for the process?</Typography>
        </AccordionSummary>
        <AccordionDetails>
         <Content paragraph ="No, the entire process is online and thus no physical presence is required."/>
            
         
        </AccordionDetails>
      </Accordion>
      <Divider sx={{border: '1px solid #FF990080'}}/>
      <Accordion expanded={expanded === 'panel2'} onChange={handleChange('panel2')}
       elevation={0} sx={{ boxShadow: 'unset !important' }} disableGutters>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel2bh-content"
          id="panel2bh-header"
        >
          <Typography sx={{color:'#281900',fontWeight:450,fontSize:'20px'}}>
         What is the Timeline for the entire process?
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
         <Content paragraph="The legal Factory team shall assist in filing of all the documents, once the verification of your documents is complete it shall take a minimum of 15 days to receive the Certificate offline but in case of online it takes upto 3 days in some stated for you to receive your certificate."/>
        </AccordionDetails>
      </Accordion>

      <Divider sx={{border: '1px solid #FF990080'}}/>
      <Accordion expanded={expanded === 'panel3'} onChange={handleChange('panel3')}
       elevation={0} sx={{ boxShadow: 'unset !important' }} disableGutters>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel3bh-content"
          id="panel3bh-header"
        >
          <Typography sx={{color:'#281900',fontWeight:450,fontSize:'20px'}}>
          When is a license under the Shops and Establishments Act required?
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
         <Content paragraph="Every commercial establishment is required to register under this Act within 30 days of commencement of work. This is often required as a proof of business like while opening a current account in a bank."/>
        </AccordionDetails>
      </Accordion>

      <Divider sx={{border: '1px solid #FF990080'}}/>
      <Accordion expanded={expanded === 'panel4'} onChange={handleChange('panel4')}
       elevation={0} sx={{ boxShadow: 'unset !important' }} disableGutters>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel4bh-content"
          id="panel4bh-header"
        >
          <Typography sx={{color:'#281900',fontWeight:450,fontSize:'20px'}}>
          What is the eligibility for getting a shops and establishments license?
            </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Content paragraph="There are two major pre-requisites for one to be able to become the applicant of trade shops and establishment license, they are:"/>

          <Content paragraph="1. The person must have attained the age of 18 years and"/>
          <Content paragraph="2. They must not have any criminal records in their name."/>
        </AccordionDetails>
      </Accordion>

      <Divider sx={{border: '1px solid #FF990080'}}/>
      <Accordion expanded={expanded === 'panel5'} onChange={handleChange('panel5')}
       elevation={0} sx={{ boxShadow: 'unset !important' }} disableGutters>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel5bh-content"
          id="panel5bh-header"
        >
          <Typography sx={{color:'#281900',fontWeight:450,fontSize:'20px'}}>
           Whom Shop Act Application is Applicable?
            </Typography>
        </AccordionSummary>
        <AccordionDetails>
         <Content paragraph="Shops Act Application is mandatory in some cases for businesses, as long as they qualify as an “Establishment” under the Shops and Establishment Act. So if you run a business in Maharashtra, you need to obtain Shop Act License under Bombay Shops and Establishments Act, 2018."/>
          </AccordionDetails>
          </Accordion>


          <Divider sx={{border: '1px solid #FF990080'}}/>
      
    </div>
  );
}