import React from 'react'
import Mainhead from '../../../../Components/Services/MainContent/Mainhead';
import Content from '../../../../Components/Services/MainContent/Content';
import List from '../../../../Components/Services/MainContent/List';
import { Box , styled} from '@mui/material';
import Step from '../../../../Components/Services/MainContent/Step';


function Register(){
    return(
        <div>
            <Mainhead heading="How to Register a Startup India Registration"/>

            <Step number="Step 1: Incorporate your business"/>
            <Content paragraph="As you know the business should be either Private Ltd Company, Partnership Firm or Limited Liability Partnership. Follow all the normal procedures for registration of any business like obtaining the Certificate of Incorporation/Partnership registration, PAN, and other required compliances."/>
            
            <Step number="Step 2: Register on Startup India"/>
            <Content paragraph="Log on to the Startup India website and fill the form with all the details of your business. After this you will receive an OTP on your email and other details like name, user, etc. After entering these details, the Startup India profile is created."/>
            
            <Step number="Step 3: Get DPIIT Recognition"/>
            <Content paragraph="After creating profile on the Startup India Website is to avail Department for promotion of Industry and Internal Trade (DPIIT) Recognition. This helps avail many benefits like access to high-quality intellectual property services and resources, relaxation in public procurement norms, self-certification under labor and environment laws, etc. Simply click on the “Get Recognized” button if you are a new user then, the ‘Recognition Application Detail’ page opens. On this page click on ‘View Details’ under the Registration Details section. Fill up the ‘Startup Recognition Form’ and click on ‘Submit’. But if you are an old user and already recognized click on the ‘Dashboard button’ and then ‘DPIIT Recognition’.

"/>
            
            <Step number="Step 4: Recognition Number"/>
            <Content paragraph="You get a recognition number right after applying and a certificate of recognition will be issued after the examination of all your documents which is almost always 2 days.
            Note: If found out that the uploaded documents are wrong or forged then there will be a fine of 50% of your paid-up capital of the startup with a minimum fine of Rs.25,000"/>
            
        </div>
    )

}
export default Register