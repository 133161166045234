import React from "react";
import { Box } from "@mui/material";
import Mainhead from "../../../Components/Services/MainContent/Mainhead";
import Content from "../../../Components/Services/MainContent/Content";
import CustomList from "../../../Components/Income-Tax/CustomList";
import Step from "../../../Components/Services/MainContent/Step";
import NormalList from "../../../Components/Income-Tax/NormalList";

const ProvidentFund = () => {
  return (
    <Box
      style={{
        display: "flex",
        flexDirection: "column",
        gap: 4,
        marginBottom: "2rem",
      }}
    >
      <Mainhead heading="Provident Fund and Taxability" />
      <Step
        number={`
          What does Provident Fund mean in Income Tax Act 1961?
        `}
      />
      <Content paragraph="An investment fund called a provident fund is willingly created by the employer and employees to act as long-term savings for an employee's retirement." />
      <Step
        number={`
          Types of Pension Funds in India
        `}
      />
      <CustomList
        points={[
          "Statutory provident fund",
          "Recognised provident fund",
          "Unrecognised provident fund",
          "Public provident fund",
        ]}
      />
      <Step
        number={`
          Statutory Provident Fund
        `}
      />
      <Content paragraph="The Provident Funds Act of 1925 established this plan, mainly meant for government workers, universities, other recognized educational institutions, railroads, and other designated organizations. Private sector employees are not eligible for statutory provident fund." />
      <Step
        number={`
          Taxability in Income Tax Act 1961 of Different Provident Funds in India
        `}
      />
      <Step
        number={`
          Statutory Provident Fund
        `}
      />
      <CustomList
        points={[
          "Employer’s contribution: Fully exempt",
          "Employee’s contribution: Covered under section 80C",
          "Interest credited: Fully exempt",
          "Amount received on retirement: Fully exempt",
        ]}
      />
      <Step
        number={`
          Recognised Provident Fund
        `}
      />
      <Content paragraph="A recognized provident fund is any establishment that has been approved by the Commissioner of Income Tax. To be recognized, an organization with 20 or more members must invest funds in accordance with the PF Act of 1952 and obtain clearance from the PF Commissioner of Income-tax." />
      <CustomList
        points={[
          "Employer’s contribution: Exempt up to 12% of salary",
          "Employee’s contribution: Deduction allowed under section 80C",
          "Interest credited: Exempt up to 9.5%",
          "Amount received on retirement: Fully exempt (subject to Note 3)",
        ]}
      />
      <Step
        number={`
          Unrecognised Provident Fund
        `}
      />
      <Content paragraph="An unrecognised provident fund is one that is not recognised by the Commissioner of Income Tax." />
      <CustomList
        points={[
          "Employer’s contribution: Not taxable",
          "Employee’s contribution: Not taxable",
          "Interest credited: Not taxable",
          "Amount received on retirement: See note 2",
        ]}
      />
      <Step
        number={`
          Public Provident Fund
        `}
      />
      <Content paragraph="Anyone can participate in this system by opening a public provident fund with an accredited bank. The government established a provident fund for the general people." />
      <CustomList
        points={[
          "Contribution: Deduction allowed under section 80C",
          "Interest Income: Exempt from tax",
        ]}
      />
      <Step
        number={`
          Employee Provident Fund (EPF)
        `}
      />
      <Content paragraph="Effective date: 2nd September 1925." />
      <Content paragraph="To establish an employee provident fund, the central government published a notification about the employee provident fund system." />
      <Content paragraph="Which establishments or which class of establishments will be covered by the aforementioned system will be determined by the central government." />
      <Content paragraph="The fund shall be administered by the central government." />
      <Content paragraph="Contribution:" />
      <CustomList
        points={[
          "By the employer to the fund = 10% of the basic wages, dearness allowances, and retaining allowances",
          "By the employees = equal to the employer",
        ]}
      />
      <Content paragraph="The government has fixed the rate of provident fund contribution at 10% in general and, in the case of establishments specially notified by the government, up to 12%." />
      <Content paragraph="Dearness allowances shall include the cash value of food concession allowed to an employee." />
      <Content paragraph="Retained allowances are allowances payable to an employee for retaining his services when the establishment is not working." />
      <Step
        number={`
          Is it necessary for every employee to deduct PF in India?
        `}
      />
      <Content paragraph="An employee is considered non-eligible if their monthly salaries exceed Rs 15,000 and they are not entitled to contribute under the scheme." />
      <Content paragraph="Workers who receive less than Rs 15,000 in pay per month are required to participate in the system." />
      <Content paragraph="However, if both the employee and his employer agree, the employee who is receiving 'wages' over the present stipulated maximum of Rs 15,000 may contribute under the programme with the assistant PF commissioner's authorization." />
      <Content paragraph="The international worker wage ceiling of Rs 15,000 is not applicable." />
      <Step
        number={`
          Important Terms in Provident Fund in India
        `}
      />
      <NormalList
        points={[
          "1).SALARY MEANS - Basic pay + DA (RB PART) + % of commission on Turnover",
          "2). RPF - Amount withdrawal from RPF is NOT TAXABLE PROVIDED:",
          " a) Employee retires or terminates job after 5 years of continuous service or",
          " b) Employee retires or terminates job before 5 years of continuous service by reason of ill health or discontinuation of employer’s business or other causes beyond the control of the employee.",
          "3). URPF: Lump sum amount withdrawal from URPF at the time of retirement shall be taxable as per the following:",
          "- Employee contribution: (-)",
          "- Interest on employee’s contribution: Taxable under the head 'Income from other sources'",
          "- Employer’s contribution: Taxable under the head 'Salary'",
          "- Interest on employer’s contribution: Taxable under the head 'Salary'",
        ]}
      />{" "}
    </Box>
  );
};

export default ProvidentFund;
