import React from 'react'
import Mainhead from '../../../../Components/Services/MainContent/Mainhead';
import List from '../../../../Components/Services/MainContent/List';
import { Box , styled,} from '@mui/material';
import ChecklistShop from '../../../../Assets/Services/ShopAct/LegalFactory_Shop2.png';

const Checklist = styled(Box)(({ theme }) => ({
  width:'28,5rem',
  height:'32rem',
  marginTop:'0.8rem',
  [theme.breakpoints.down("sm")]: {
    width:'18rem',
  height:'21.5rem',
  },
  [theme.breakpoints.down("md")]: {
    width:'18rem',
  height:'21.5rem',
  },
  [theme.breakpoints.between("md", "lg")]: {
    width:'25rem'
  }
}));

function Checklists() {
  return (
    <div>
      <Mainhead heading="Checklist for Shop Act Establishment"/>
      <ul className='list'>
        <li className='bullet'>
          <span>
      <List points="Shop or business address proof"/>
      </span>
      </li>
      <li className='list'>
        <span>
      <List points="ID proof"/>
      </span>
      </li>
      <li className='bullet'>
          <span>
      <List points="PAN card"/>
      </span>
      </li>
      <li className='bullet'>
          <span>
      <List points="Payment challan"/>
      </span>
      </li>
      <li className='bullet'>
          <span>
      <List points="Additional business licenses necessary to start a business."/>
      </span>
      </li>
      </ul>

      <Box sx={{textAlign:'center'}}>
      <Checklist component="img"
                sx={{
                  content: {
                    xs: `url(${ChecklistShop})`
                  }
                }}/>
                </Box>
    </div>
  )
}

export default Checklists