import React,{ useRef, useEffect, useState } from 'react';
import {
    Box,
    Typography,
    Container,
    styled,
    Divider,
    Grid,
    Button,
    useTheme,
  useMediaQuery
} from '@mui/material';
import "../Common.css"
import Topbox from '../../../Components/Services/Topbox';
import GetInTouch from '../../../Components/Services/GetInTouch';
import Overview from './Overview/Overview';
import Legalfactory from './WhyLegalFactory/Legalfactory';
import Checklist from './Checklist/Checklist';
import Register from './Register/Register';
import Document from './DocumentRequired/Document';
import Faq from './FAQ/Faq';

const getDimensions = ele => {
  const  {height}  = ele.getBoundingClientRect();
  const offsetTop = ele.offsetTop;
  const offsetBottom = offsetTop + height;

  return {
    height,
    offsetTop,
    offsetBottom,
  };
};

const scrollTo = ele => {
  ele.scrollIntoView({
    behavior: "smooth",
    block: "start",
  });
};

const Privatelimited = () => {

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const isTablet = useMediaQuery(theme.breakpoints.down("md"));
  const [visibleSection, setVisibleSection] = useState();
  const headerRef = useRef(null);
    const overviewRef = useRef(null);
    const checklistRef = useRef(null);
    const registerRef = useRef(null);
    const documentRef = useRef(null);
    const legalRef = useRef(null);
    const faqRef = useRef(null);
  
    const sectionRefs = [
      { section: "Overview_private", ref: overviewRef },
      { section: "Checklist_private", ref: checklistRef },
      { section: "Register_private", ref: registerRef },
      { section: "Document_private", ref: documentRef },
      { section: "Legal_private", ref: legalRef },
      { section: "Faq_private", ref: faqRef },
    ];
  
    useEffect(() => {
      const handleScroll = () => {
        const { height: headerHeight } = getDimensions(headerRef.current);
        const scrollPosition = window.scrollY + headerHeight;
  
        const selected = sectionRefs.find(({ section, ref }) => {
          const ele = ref.current;
          if (ele) {
            const { offsetBottom, offsetTop } = getDimensions(ele);
            return scrollPosition > offsetTop && scrollPosition < offsetBottom;
          }
        });
  
        if (selected && selected.section !== visibleSection) {
          setVisibleSection(selected.section);
        }
         
         else if (!selected && visibleSection) {
          setVisibleSection(undefined);
        }
      };
  
      handleScroll();
      window.addEventListener("scroll", handleScroll);
      return () => {
        window.removeEventListener("scroll", handleScroll);
      };
    });
    
    
    const Topspacer  = styled(Box)(({ theme }) => ({
      height:'6rem',
      [theme.breakpoints.down("sm")]: {
        height:'1.5rem',
      }
          
  }));

  const Content  = styled(Box)(({ theme }) => ({
    
        
}));
  
  
  const Sticky  = styled(Box)(({ theme }) => ({
      position:'sticky',
      top:90,
      left:0,
     }));
  
  const PLheader  = styled(Box)(({ theme }) => ({
      display:'flex',
      flexDirection:'column',
      justifyContent:'flex-start',
  
  }));
  
  const Bottom_spacer  = styled(Box)(({ theme }) => ({
      height:'8rem',
  }));
  
 
  const Section  = styled(Box)(({ theme }) => ({
      height:'auto'
    }));

return (
        <div>
            
       
          <Topbox heading="Private Limited Company" service="Private Limited Company"
          document={[<a href='#Document_private' style={{color:'#F19E1E'}}  onClick={() => {
            scrollTo(documentRef.current);
          }}>LearnMore</a>]}
          description="Company registration including Government Fee & Stamp Duty.Incorporation kit with share certificates."

          rating="4.1"

          clients="From 80 customers"
          
          priceinfo="Start Your Private Limited Company Registration Journey At "/>
          <div className='Container'>
             <Container>
          <div className="App">
      <Topspacer />
      
     <Content>
        <Grid container spacing={3}>
            <Grid item lg={3} xl={3} md={4}
            sx={
              isMobile || isTablet ? { display: 'none' } : { }
            }>
              
        <Sticky>
          <PLheader ref={headerRef}>
            <Box
              className={`header_link ${visibleSection === "Overview_private" ? "selected" : ""}`}
              onClick={() => {
                scrollTo(overviewRef.current);
              }}
            >
              Overview
            </Box>
            <Box
              type="button"
              className={`header_link ${visibleSection === "Checklist_private" ? "selected" : ""}`}
              onClick={() => {
                scrollTo(checklistRef.current);
              }}
            >
              Checklist
            </Box>
            <Box
              type="button"
              className={`header_link ${visibleSection === "Register_private" ? "selected" : ""}`}
              onClick={() => {
                scrollTo(registerRef.current);
              }}
            >
              How To Register
            </Box>

            <Box
              type="button"
              className={`header_link ${visibleSection === "Document_private" ? "selected" : ""}`}
              onClick={() => {
                scrollTo(documentRef.current);
              }}
            >
              Documents Required
            </Box>
            <Box
              type="button"
              className={`header_link ${visibleSection === "Legal_private" ? "selected" : ""}`}
              onClick={() => {
                scrollTo(legalRef.current);
              }}
            >
              Why Legal Factory
            </Box>
            <Box
              type="button"
              className={`header_link ${visibleSection === "Faq_private" ? "selected" : ""}`}
              onClick={() => {
                scrollTo(faqRef.current);
              }}
            >
              FAQ'S
            </Box>
            </PLheader>
        </Sticky>
        
        </Grid>
        <Grid item md={9} lg={9} xl={9}
         sx={
          isMobile || isTablet ? { display: 'none' } : { }
        }>
        <Section id="Overview_private" ref={overviewRef}>
          <Overview/>
        </Section>
        <Topspacer/>
        <Section id="Checklist_private" ref={checklistRef}>
          <Checklist/>
        </Section>
        <Topspacer/>
        <Section id="Register_private" ref={registerRef}>
        <Register/>
        </Section>
        <Topspacer/>
        <Section id="Document_private" ref={documentRef}>
         <Document/>
        </Section>

        
        <Topspacer/>
        <Section id="Legal_private" ref={legalRef}>
        <Legalfactory/>
        </Section>
       <Topspacer/>
        <Section id="Faq_private" ref={faqRef}>
        <Faq/>
        </Section>
        </Grid>

       
          <GetInTouch Floatingicon="Private Limited Company"/>
        
        </Grid>
       </Content>
<Box sx={
          isMobile || isTablet ? {  } : {display:'none' }
        }>
          <Grid container>
          <Grid item xs={11}>
         <br/><br/>
          <Overview/>
          <br/>
          <Checklist/>
          <br/>
          <Register/>
          <br/>
          <Document/>
          <br/>
          <Legalfactory/>
          <br/>
          
          </Grid>
          </Grid>
        </Box>
      
      

      <Bottom_spacer/>
      
      
    </div>
           
           </Container>
        </div>
        </div>
    )
}

export default Privatelimited