import React from 'react';
import Sidecomponent from '../../../Components/Tax-Planning/Sidecomponent';
import Topdiv from "../../../Components/Tax-Planning/Topdiv"
import IncomeHead from '../../../Assets/TaxPlanning/Salaryheads/Incomehead.png';
import GetInTouch from '../../../Components/Services/GetInTouch';
import {
  Grid, Box, Container, styled, Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Link
} from '@mui/material';


import Mainhead from '../../../Components/Services/MainContent/Mainhead';
import Content from '../../../Components/Services/MainContent/Content';
import List from '../../../Components/Services/MainContent/List';
import Step from '../../../Components/Services/MainContent/Step';

import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import Faq from './Faq';

function createData(
  Category,
  Description
   ) {
     return {  
      Category,
      Description
     };
   }
   function createData1(
    ITR,
    Descriptions
     ) {
       return {  
        ITR,
        Descriptions
       };
     }

   const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor:'#773106',
      color: theme.palette.common.white,
      textAlign:'left',
      fontFamily: 'Sora',
      fontWeight:'bold',
      },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 16,
      textAlign:'left',
      textAlign:'left',
      fontFamily: 'Sora',
     },
  }));
  
  const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(even)': {
      backgroundColor: theme.palette.action.hover,
      fontFamily:'Sora'
    },
    // hide last border
    '&:last-child td, &:last-child th': {
        border: 0 ,
    },
  }));

  const Bottomspace = styled(Box)(({ theme }) => ({
    marginBottom:'2.1rem'
  }));

  const rows = [
       createData('Individuals','The term "individual" typically refers to a single human being, as opposed to a group or organization. It is often used to emphasize the unique qualities or characteristics of a particular person, as opposed to their membership in a larger group.'),
       createData('Hindu Undivided Family (HUF)','Under the Hindu law in India, an HUF, or Hindu Undivided Family, is a type of joint family structure that consists of all male descendants of a common ancestor and their wives and children. The HUF is considered a single legal entity for the purpose of owning and managing property, and the karta, or head of the family, is responsible for managing the affairs of the HUF and representing it in legal matters.'),
       createData('Association of Persons(AOP)','People form an association for common purpose or action or for the object of generating income.'),
       createData('Body of Individuals (BOI)','Association formed by individuals for common purpose or action or for the object of generating income.'),
       createData('Firms/ partnership firms/ limited liability partnership(LLP)','A firm /Partnership/ LLP is the relation between persons who have agreed to share the profits of the business carried on by all or any of them acting for all. However a firm and partnership has unlimited liability whereas a LLP has limited liability.'),
       createData('Companies','Under the Companies Act of India, a "company" is defined as an association of persons formed for the purpose of doing business. The Act includes provisions for the incorporation and regulation of three types of companies: private companies,public companies, and one person companies (OPCs). A private company is a company that has a minimum of two and a maximum of fifty members, while a public company must have at least seven members. An OPC is a company with only one member, who is also the director of the company.'),
       createData('Artificial Judicial Persons','Artificial judicial persons, also known as juridical persons, are legal entities that are recognized as having legal personality separate from that of their members or founders. Examples of artificial judicial persons include universities, bar councils, and other professional organizations, as well as corporations and other types of business entities.'),
       createData('Local authority','It means a municipal committee, district board or other authority legally entitled to manage a municipal or local fund.'),
       
  ];

  const rows2 = [
    createData1('ITR-1','For individual income from salaries, one house property, other sources (interest etc.)'),
    createData1('ITR-2','For individuals and HUFs not having income from business or profession'),
    createData1('ITR-2A','For individuals and HUFs not having income form business or profession and capital gains and who do not hold foreign assets'),
    createData1('ITR-3','For individuals/HUFs beings partners in firms and not carrying out business or profession under any proprietorship'),
    createData1('ITR-4','For individuals and HUFs having income from a proprietary business or profession'),
    createData1('ITR-4S','Presumptive business income tax return'),
    createData1('ITR-5','For presons other than, - (i)individual, (ii)HUF, (iii)company and (iv)person filing Form ITR-7'),
    createData1('ITR-6','For companies oter than companies claiming exemption under section 11'),
    createData1('ITR-7','For persons including companies required to furnish return under sections 139(4A) OR 139(4B) OR 139(4C) or 139(4D) or 139(4E) or 139(4F)'),
    createData1('ITR-V','The acknowledgment form of filing a return of income')
  ];

function Itrfiling() {

  const Image  = styled(Box)(({ theme }) => ({
    marginTop:'30px',
    width:'75%',
    marginBottom:'3rem',
    justifyContent:'center',
    [theme.breakpoints.down("md")]: {
      marginTop:'20px',
      width:'100%',
    }
  }));
  return (
    <div>
      <Topdiv heading="Are you looking to file your Income Tax Returns ?"/>
      <div className='Container'>
      <Container>
        <Grid container>
          <Grid item  lg={4} xl={3}>
            <Box sx={{ display: { xs: 'none', lg: 'block' }, marginTop: '4rem', position: 'sticky', top: 90, marginBottom: '3rem' }}>
              <Sidecomponent />
            </Box>
          </Grid>
          <Grid item xs={11} lg={8} sx={{ marginTop: '4rem' }} xl={9}>
            <Mainhead heading="Table Of Contents About Indian Income Tax Basics" />
            <br/>
            <Box sx={{textAlign:'center'}}>
            <Image component="img"
                sx={{
                  content: {
                    xs: `url(${IncomeHead})`
                  }
                }}/>
                </Box>
                <br/><br/>
                <TableContainer component={Paper} sx={{mt:3}}>
                <Table  aria-label="customized table"  size='small'>
        <TableHead>
          <TableRow>
            <StyledTableCell>Category </StyledTableCell>
            <StyledTableCell>Description </StyledTableCell> 
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.map((row) => (
            <StyledTableRow
              key={row.name}
            
            >
              <StyledTableCell component="th" scope="row"
              >
                {row.Category}
              </StyledTableCell>
              <StyledTableCell>{row.Description}</StyledTableCell>
              </StyledTableRow>
          ))}
        </TableBody>
      </Table>
      </TableContainer>
      <br/><br/>

      <Mainhead content="Why LEGAL FACTORY ?"/>
      <Content paragraph="LEGAL FACTORY provides legal services to businesses, including assistance with the process of incorporating a business and fulfilling other legal requirements. They offer a range of services, including handling paperwork, connecting businesses with professionals, and providing 24/7 support. They also prioritize transparency and privacy in their services.. All the confidential data or personal data of our customers is strictly confined and not accessible without permission."/>
      <Bottomspace/>
<Mainhead heading="INCOME TAX CALCULATION"/>
<Content paragraph="The income tax calculator provided by the Income Tax Department of India is a tool that can be used to calculate an individual's income tax liability based on their income, deductions, and tax credits. The calculator takes into account the tax rates and thresholds that are in effect for the current tax year."/>
<Content paragraph="Income from the transfer of digital assets such as cryptocurrency is taxable at a rate of 30% in India. No deductions are allowed for this income, except for the cost of acquisition of the digital assets. Losses on the sale of digital assets cannot be set off against other income."/>
<Content paragraph="In India, eligible business deductions are allowed for expenses that are incurred in the course of carrying on a business. Surcharge and cess levied on income are not allowed as business expenditure."/>
<Content paragraph="Under the loss set off rules in India, a brought forward loss cannot be set off against undisclosed income detected during a survey or search."/>
<Bottomspace/>
        
    <Mainhead heading="INCOME TAX PAYMENT"/>
    <Step number="Income tax"/>
    <Content paragraph="Income tax is a tax that is imposed by the government on the income earned by individuals and businesses. In India, the income tax is governed by the Income Tax Act of 1961."/>
    <Step number="Tax Deducted at Source (TDS) "/>
    <Content paragraph="Tax Deducted at Source (TDS) is a tax that is deducted from the income earned by individuals and businesses at the source of the income. TDS is deducted by the payer at the time of making payment to the recipient of the income. The recipient of the income can claim the credit of the TDS amount by adjusting it with the final tax liability"/>
    <Step number="Advance tax"/>
    <Content paragraph="Advance tax is tax that is paid in advance by the taxpayer when the estimated income tax liability for the year exceeds INR 10,000. The government has specified due dates for payment of advance tax installments."/>
    <Step number="Self-assessment"/>
    <Content paragraph="Self-assessment tax is a tax that is paid by the taxpayer on the assessed income.The self-assessment tax calculator is a tool that can be used to calculate the self-assessment tax liability"/>
    <Bottomspace/>
    <Mainhead heading="Income Tax Forms List"/>
    <Content paragraph="For an individual wanting to claim an income tax refund, they will need to first file the income tax return. Depending on the income assessment group, the individual will need to submit one of the ITR forms listed below"/>
    <br/>
    <TableContainer component={Paper}>
                <Table  aria-label="customized table"  size='small'>
        <TableHead>
          <TableRow>
            <StyledTableCell>ITR Form Name </StyledTableCell>
            <StyledTableCell>Description </StyledTableCell> 
          </TableRow>
        </TableHead>
        <TableBody>
          {rows2.map((row) => (
            <StyledTableRow
              key={row.name}
            
            >
              <StyledTableCell component="th" scope="row"
              >
                {row.ITR}
              </StyledTableCell>
              <StyledTableCell>{row.Descriptions}</StyledTableCell>
              </StyledTableRow>
          ))}
        </TableBody>
      </Table>
      </TableContainer>
      <Bottomspace/>
<Faq/>
<br/><br/><br/>



            </Grid>
            </Grid>
            </Container>


    </div>
    </div>
  )
}

export default Itrfiling