import React from 'react'
import Mainhead from '../../../../Components/Services/MainContent/Mainhead';
import Content from '../../../../Components/Services/MainContent/Content';
import List from '../../../../Components/Services/MainContent/List';
import { Box, styled } from '@mui/material';
import Step from '../../../../Components/Services/MainContent/Step';

function Documents() {
    return (
        <div>
            <Mainhead heading="Basic Documents Required " />
            <ul className='list'>
                <li className='bullet'>
                    <span>
                        <List points="Incorporation/Registration Certificate of your startup" />
                    </span>
                </li>
                <li className='bullet'>
                    <span>
                        <List points="Details of the Directors" />
                    </span>
                </li>
                <li className='bullet'>
                    <span>

                        <List points="Proof of concept like pitch deck/website link/video (in case of a validation/ early traction/scaling stage startup)" />
                    </span>
                </li>
                <li className='bullet'>
                    <span>
                        <List points="Patent and trademark details (Optional)" />
                    </span>
                </li>
                <li className='bullet'>
                    <span>
                        <List points="PAN Number" />
                    </span>
                </li>
            </ul>


        </div>
    )
}
export default Documents