import React from "react";
import { Box } from "@mui/material";
import Mainhead from "../../../Components/Services/MainContent/Mainhead";
import Content from "../../../Components/Services/MainContent/Content";
import CustomList from "../../../Components/Income-Tax/CustomList";
import Step from "../../../Components/Services/MainContent/Step";

const Deduction80U = () => {
  return (
    <Box
      style={{
        display: "flex",
        flexDirection: "column",
        gap: 4,
        marginBottom: "2rem",
      }}
    >
      <Mainhead heading="Deduction 80U" />
      <Content paragraph="Section 80U of the Income Tax Act, 1961, provides tax deductions for individuals with disabilities." />
      <Step
        number={`
          What does Section 80U of Income Tax Act, 1961, in India mean?
        `}
      />
      <Content paragraph="Individual taxpayers with disabilities are eligible for a tax deduction under Section 80U of the Income Tax Act of 1961. To claim a tax deduction under section 80U, the individual must be certified as a disabled person by the appropriate medical authority." />
      <Step
        number={`
          Who is eligible for the 80U deduction?
        `}
      />
      <Content paragraph="Residents who are certified by a medical authority to be a person with disabilities at any time during the fiscal year can claim this deduction only for themselves. This deduction is only available to people who live in India. This deduction is not available to non-resident Indians." />
      <Step
        number={`
          List of diseases under which deduction can be claimed under 80U
        `}
      />
      <CustomList
        points={[
          "Blindness",
          "Low Vision",
          "Leprosy (cured)",
          "Hearing Impairment",
          "Locomotor Disability",
          "Mental Retardation",
          "Mental Illness",
          "Autism",
          "Cerebral Palsy",
        ]}
      />
      <Content paragraph="A definition of severe disability, as defined by the Disability Act, is a condition in which the disability is 80% or greater. A person will also be deemed severely disabled if they have multiple disabilities." />
      <Step
        number={`
          Limit of Deduction Under Section 80U of Income Tax Act, 1961, in India
        `}
      />
      <CustomList
        points={[
          "Individual with Disability: Up to Rs. 75,000 deduction for 40% or greater disability.",
          "Individuals with Severe Disability: Up to Rs. 1.25 lakh deduction for 80% or greater disability.",
        ]}
      />
      <CustomList
        points={[
          "Type of Disability: General Disability - Rs. 75,000",
          "Type of Disability: Severe Disability - Rs. 1.25 lakh",
        ]}
      />
      <Step
        number={`
          Documents needed to claim benefit under Section 80U
        `}
      />
      <Content paragraph="Section 80U allows for the claim of tax deductions without the need for any additional documentation other than the disability certificate issued by a medical authority. However, Form 10-IA must also be completed in the event of conditions like autism and cerebral palsy." />
      <Step
        number={`
          What Qualifies as a Medical Authority under section 80U of Income Tax Act,1961?
        `}
      />
      <CustomList
        points={[
          "A civil surgeon or a government hospital's Chief Medical Officer (CMO).",
          "A neurologist with a Neurology MD.",
          "In the case of children, a pediatric neurosurgeon.",
        ]}
      />
    </Box>
  );
};

export default Deduction80U;
