import React from "react";
import { Box } from "@mui/material";
import Mainhead from "../../../Components/Services/MainContent/Mainhead";
import Content from "../../../Components/Services/MainContent/Content";
import CustomList from "../../../Components/Income-Tax/CustomList";
import Step from "../../../Components/Services/MainContent/Step";
import ReusableTable from "../../../Components/Income-Tax/ReusableTable";

const BusinessLosses = () => {
  return (
    <Box
      style={{
        display: "flex",
        flexDirection: "column",
        gap: 4,
        marginBottom: "2rem",
      }}
    >
      <Mainhead heading="Set Off and Carry Forward of Losses for Business and Profession" />
      <Step
        number={`
            What is Set Off of Losses?
          `}
      />
      <Content paragraph="Adjusting the losses against the profit earned during the period is known as set off of losses. There are two types of set offs:" />
      <CustomList
        points={[
          "Inter head set off - Setting of losses from one head against the profit earned under another head.",
          "Intra head set off - Setting of losses from one head against the profit earned under that same head.",
        ]}
      />
      <Step
        number={`
            Rules for Set Off of Business Losses
          `}
      />
      <CustomList
        points={[
          "Inter head adjustments - Losses from regular business and profession can be adjusted against any head of income except 'income from salary'.",
          "Speculative business - Losses from speculative business can be set off only against profit from a speculative business. They cannot be set off against income from any other business or any other head.",
          "Specified business - Losses from specified business can be set off only against profit from a specified business. They cannot be set off against income from any other business or any other head.",
        ]}
      />
      <Step
        number={`
            What is Carry Forward of Losses?
          `}
      />
      <Content paragraph="When the entire amount of losses is not possible to be adjusted against the income earned during that year, such unadjusted losses can be carried forward to subsequent years to be for adjustment against income earned in those years. This is known as carry forward of losses." />
      <Step
        number={`
            Rules for Carry Forward of Business Losses
          `}
      />
      <CustomList
        points={[
          "Inter head adjustment - Business loss carried forward can only be set off against income from business and profession. Thus only in the year in which the loss is incurred, you can adjust it against any head of income. Once the loss has been carried forward it can be adjusted only against business income.",
          "Period -(i)Losses from regular business can be carried forward for up to the next 8 assessment years. (ii) Losses from speculative business can be carried forward for up to the next 4 assessment years. (iii) There is no limit to carry forward losses from specified business.",
          "Continuation of business - The losses incurred in a business can be carried forward and set off even if such business is not continued.",
          "Section 78(2) - States that 'Where any person carrying on any business or profession has been succeeded in such capacity by another person otherwise than by inheritance, nothing in this Chapter shall entitle any person other than the person incurring the loss to have it carried forward and set off against his income.' Thus only the assessee who has originally suffered the business loss shall be able to carry forward set it off against his business income.",
        ]}
      />
      <Step
        number={`
            Payment of Taxes under Business and Profession
          `}
      />
      <Content paragraph="TDS IS REQUIRED TO BE DEDUCTED ON WHICH PAYMENTS?" />
      <Content paragraph="TDS applies to various payments made by a business or professional entity to others. Some common instances where TDS is applicable in the case of income from business and profession include:" />
      <CustomList
        points={[
          "Salary Payments: Any salary payments made by an employer to an employee are subject to TDS under the head of income from business and profession. The employer deducts TDS based on the applicable slab rates.",
          "Professional Fees: Payments made to professionals such as doctors, lawyers, consultants, etc., are subject to TDS under Section 194J of the Income Tax Act. TDS is deducted at specified rates (typically 10%) on such payments exceeding a certain threshold.",
          "Contractual Payments: Payments made to contractors or sub-contractors for work done fall under TDS provisions. Section 194C of the Income Tax Act applies here, where TDS is deducted at specified rates based on the nature of the payment.",
          "Commission Payments: Any commission earned or paid out is subject to TDS. The TDS rates vary based on the nature of the commission and are covered under different sections of the Income Tax Act.",
          "Rent Payments: TDS is also applicable to rent payments made for the use of plant, machinery, land, building, etc., under Section 194I of the Income Tax Act.",
        ]}
      />
      <Content paragraph="The rate of TDS and the provisions under which TDS is applicable may differ based on the nature of the transaction, the relationship between the payer and payee, and the threshold limits set by the Income Tax Department." />
      <Content paragraph="Any (TDS) can be availed as a credit while filing the Income Tax Return (ITR) for income earned from business and profession. This credit can result in a refund if there's a loss in the business and profession. Alternatively, if profits are earned from business and profession, claiming TDS can effectively reduce the tax liability." />

      <Content paragraph="Advance Tax" />
      <Content paragraph="If you expect that your tax liability is bound to exceed Rs. 10,000, then you must calculate and pay Advance Tax in order to avoid the interest applicable under section 234B and 234C." />
      <Content paragraph="The payment of advance tax is done quarterly in the following manner:" />
      <ReusableTable
        data={[
          {
            dueDate: "On or before 15th June",
            advanceTaxPayable: "15% of the Tax Liability",
          },
          {
            dueDate: "On or before 15th September",
            advanceTaxPayable: "45% of the Tax Liability",
          },
          {
            dueDate: "On or before 15th December",
            advanceTaxPayable: "75% of the Tax Liability",
          },
          {
            dueDate: "On or before 15th March",
            advanceTaxPayable: "100% of the Tax Liability",
          },
        ]}
      />
    </Box>
  );
};

export default BusinessLosses;
