import React from "react";
import { Box } from "@mui/material";
import Mainhead from "../../../Components/Services/MainContent/Mainhead";
import Content from "../../../Components/Services/MainContent/Content";
import Step from "../../../Components/Services/MainContent/Step";
import CustomList from "../../../Components/Income-Tax/CustomList";

const Section80G = () => {
  return (
    <Box
      style={{
        display: "flex",
        flexDirection: "column",
        gap: 4,
        marginBottom: "2rem",
      }}
    >
      <Mainhead heading="Section 80G" />
      <Content paragraph="Section 80G is a provision under the Income Tax Act, 1961, encouraging individuals and organizations to contribute to charitable causes by providing tax benefits." />
      <Step
        number={`
          What is the Eligibility Criteria for Deduction under 80G?
        `}
      />
      <CustomList
        points={[
          "Approved Institutions: Donations must be made to institutions and funds approved by the Income Tax Department.",
          "Nature of Charitable Activities: Approved entities must be actively engaged in charitable activities.",
        ]}
      />
      <Step
        number={`
          What is the Quantum of Deduction under 80G?
        `}
      />
      <CustomList
        points={[
          "100% Deduction: Certain donations are eligible for a 100% deduction.",
          "50% Deduction: For other approved entities, the donor is eligible for a 50% deduction.",
        ]}
      />
      <Step
        number={`
          Mode of Payment and other Important Terms
        `}
      />
      <CustomList
        points={[
          "Authorized Modes: Donations must be made through authorized modes such as cheques, bank drafts, or digital payment methods.",
          "Tax Exemption Certificate: Upon receiving a donation, the eligible charitable institution issues a tax exemption certificate to the donor.",
          "Validity Period of Approval: The approval granted to charitable institutions and funds under Section 80G is not perpetual.",
        ]}
      />
      <Step
        number={`
          Section 80GGA
        `}
      />
      <Content paragraph="Section 80GGA is a related provision that allows deductions for donations made for scientific research or rural development." />
      <CustomList
        points={[
          "Scientific Research and Rural Development: Donors should ensure that the institutions and projects for which they are contributing fall under the criteria specified in Section 80GGA.",
        ]}
      />
      <Step
        number={`
          Filing Income Tax Return
        `}
      />
      <CustomList
        points={[
          "Documentation: To claim the deduction, donors must attach the tax exemption certificate received from the charitable institution along with their income tax return.",
          "Verification: The donor's eligibility and the validity of the deduction claimed may be subject to verification by the Income Tax Department during assessments or audits.",
        ]}
      />
      <Step
        number={`
          Impact on Corporate Social Responsibility (CSR)
        `}
      />
      <CustomList
        points={[
          "CSR Activities: Companies fulfilling their Corporate Social Responsibility (CSR) obligations can often claim deductions under Section 80G for the contributions made to eligible charitable organizations.",
          "Publicizing the Cause: Companies and individuals engaged in charitable activities can leverage the tax benefits under Section 80G as part of their communication and public relations strategies.",
        ]}
      />
      <Step
        number={`
          Regional Variances
        `}
      />
      <CustomList
        points={[
          "State-Specific Approvals: In addition to national approvals, some states may have their own set of approvals for charitable institutions.",
          "Social Impact: Measuring Impact: Donors may consider evaluating the social impact of their contributions by assessing the outcomes achieved through the charitable activities of the supported institutions.",
        ]}
      />
      <Step
        number={`
        Social Impact:
        `}
      />
      <CustomList
        points={[
          "Measuring Impact: Donors may consider evaluating the social impact of their contributions by assessing the outcomes achieved through the charitable activities of the supported institutions.",
        ]}
      />
    </Box>
  );
};

export default Section80G;
