import React from 'react';
import { Link } from 'react-router-dom';
import "./Sidecomponent.css";
import { Grid,Container,useTheme,Box ,styled,useMediaQuery} from "@mui/material";

function Sidecomponent() {
  const Bottomspace = styled(Box)(({ theme }) => ({
    marginBottom:'0.5rem'
  }));

return (
    <div>
      <Box width="90%" height='auto' sx={{display:'flex',
     flexDirection:'column',
     background:'#FFFFFF',
     boxShadow: 'rgba(0, 0, 0, 0.35) 0px 5px 15px',
     }}>
    
      <ul className='sidenav'>
        <li className='sidelist'>
      <a href='/tax/incometax' className='nav-link' title='Basic Of Income Tax'>Basics Of Income Tax</a>
      </li>
      <li className='sidelist'>
      <a href ='/tax/itrfiling' className='nav-link'>ITR Filing</a>
      </li>
      <li className='sidelist'>
      <a href='/tax/procedure' className='nav-link'> Procedure Of ITR Filing</a>
      </li>
      <li className='sidelist'>
      <a href='/tax/residential' className='nav-link'>Residential Status</a>
     </li>
     <li className='sidelist'>
      <a href='/tax/salaries' className='nav-link'>Income From Salaries</a>
      </li>
      <li className='sidelist'>
      <a href='/tax/houseproperty' className='nav-link'>Income From HouseProperty</a>
      </li>
      <li className='sidelist'>
      <a href='/tax/business' className='nav-link'>Profits Of Gain From Business And Profession</a>
      </li>
      <li className='sidelist'>
      <a href='/tax/capitalgain' className='nav-link'>Capital Gain</a>
      </li>
      <li className='sidelist'>
      <a href='/tax/othersources' className='nav-link'>Income From Other Sources</a>
     </li>
     <li className='sidelist'>
      <a href='/tax/income' className='nav-link'>Exemptions From Income U/S 10</a>
      </li>
      <li className='sidelist'>
      <a href='/tax/taxableincome' className='nav-link'>Deduction From Taxable Inocme</a>
      </li>
      <li className='sidelist'>
      <a href ='/tax/tds' className='nav-link'>Deduction Of Tax From Income (TDS)</a>
      </li>
      <li className='sidelist'>
      <a href='/tax/advancetax' className='nav-link'>Advance Tax Liability</a>
      </li>
      <li className='sidelist'>
      <a href='/tax/incomeassessed' className='nav-link'>Income Assessed In IT Act</a>
      </li>
      <li className='sidelist'>
      <a href='/tax/appeal' className='nav-link'>How To File An Appeal</a>
      </li>
      </ul>
     </Box>
    </div>
  )
}

export default Sidecomponent