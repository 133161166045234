import React from "react";
import { Box, Typography } from "@mui/material";
import Mainhead from "../../../Components/Services/MainContent/Mainhead";
import Content from "../../../Components/Services/MainContent/Content";
import Step from "../../../Components/Services/MainContent/Step";
import CustomList from "../../../Components/Income-Tax/CustomList";

const DeductibleExpenses = () => {
  return (
    <Box
      style={{
        display: "flex",
        flexDirection: "column",
        gap: 4,
        marginBottom: "2rem",
      }}
    >
      {" "}
      <Mainhead heading="Deductible Expenses" />
      <Step
        number={`
            What are the various expenses that can help to reduce the taxable amount
            of capital gains?
          `}
      />
      <CustomList
        points={[
          "Transfer Expenses: Costs directly associated with the transfer of the property, including stamp duty, registration fees, legal charges, brokerage fees, and any other incidental expenses incurred during the sale process, are deductible from the sale proceeds.",
          "Brokerage and Commission: The fees paid to real estate agents or brokers for facilitating the sale of the property can be deducted from the sale consideration to arrive at the net gains.",
          "Legal Expenses: Costs incurred for legal services related to the sale or transfer of the property, such as lawyer fees, documentation charges, and other legal expenses, can also be considered deductible expenses.",
          "Incidental Expenses: Other incidental expenses directly related to the sale of the property, such as advertising costs for property listing, property inspection charges, and any other legitimate expenses incurred during the selling process, may also be eligible for deduction.",
          "Cost of Maintenance and Repairs: Expenses incurred for maintenance and minor repairs to maintain the property's condition may be considered for deduction. However, it's essential to differentiate between regular maintenance and substantial improvements, as only the latter can be added to the cost of improvement.",
          "Capital Gains Account Scheme (CGAS): If the taxpayer is unable to reinvest the capital gains in specified assets before the due date of filing the income tax return, the unutilized amount can be deposited in a Capital Gains Account Scheme. The amount deposited in this scheme can be claimed as a deduction.",
        ]}
      />
      <Content>
        <Typography>
          <strong>Example:</strong> Mr. Himanshu Sharma sold his house property
          in 2023 for Rs. 80 lakhs. He had purchased the property in 2010 for
          Rs. 40 lakhs and spent Rs. 10 lakhs on renovations in 2015.
        </Typography>
      </Content>
      <Box>
        <Typography>
          <strong>Details of the sale:</strong>
          <br />
          Selling price of the property = Rs. 80 lakhs
        </Typography>

        <Typography>
          <strong>Cost details:</strong>
          <br />
          Cost of acquisition in 2010 = Rs. 40 lakhs
          <br />
          Cost of improvement in 2015 = Rs. 10 lakhs
          <br />
          Indexed Cost of Acquisition + Cost of Improvement (as per indexed
          values) = Rs. 60 lakhs
        </Typography>

        <Typography>
          <strong>Calculation of Capital Gains:</strong>
          <br />
          Sale Consideration - (Indexed Cost of Acquisition + Cost of
          Improvement)
          <br />
          Rs. 80 lakhs - Rs. 60 lakhs = Rs. 20 lakhs (Capital Gains)
        </Typography>

        <Typography>
          <strong>
            Now, considering deductible expenses related to the sale:
          </strong>
          <br />
          Mr. Himanshu Sharma incurred legal fees of Rs. 30,000 for the property
          transfer.
        </Typography>

        <Typography>
          <strong>Adjusted Calculation of Taxable Capital Gains:</strong>
          <br />
          Capital Gains (before deductions) = Rs. 20 lakhs
          <br />
          Deductible Legal Expenses = Rs. 30,000
          <br />
          Revised Capital Gains = Capital Gains (before deductions) - Deductible
          Legal Expenses
          <br />
          Revised Capital Gains = Rs. 20 lakhs - Rs. 30,000
          <br />
          Revised Capital Gains = Rs. 19,70,000
        </Typography>
      </Box>
    </Box>
  );
};

export default DeductibleExpenses;
