import React from 'react';
import Sidecomponent from '../../../Components/Tax-Planning/Sidecomponent';
import GetInTouch from '../../../Components/Services/GetInTouch';
import {
  Grid, Box, Container, styled, Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Link
} from '@mui/material';
import Topdiv from "../../../Components/Tax-Planning/Topdiv"

import Mainhead from '../../../Components/Services/MainContent/Mainhead';
import Content from '../../../Components/Services/MainContent/Content';
import List from '../../../Components/Services/MainContent/List';
import Step from '../../../Components/Services/MainContent/Step';

import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import Faq from './Faq';
import Advancetax2 from "../../../Assets/TaxPlanning/AdvanceTax/2.png";
import Advancetax3 from "../../../Assets/TaxPlanning/AdvanceTax/3.jpg";
import Advancetax4 from "../../../Assets/TaxPlanning/AdvanceTax/4.jpg";
import Advancetax5 from "../../../Assets/TaxPlanning/AdvanceTax/5.png";
import UPI1 from "../../../Assets/TaxPlanning/AdvanceTax/1.1.png";
import UPI2 from "../../../Assets/TaxPlanning/AdvanceTax/1.2.png";
import UPI3 from "../../../Assets/TaxPlanning/AdvanceTax/1.3.png";
import UPI4 from "../../../Assets/TaxPlanning/AdvanceTax/1.4.png";



function createData(
  InstallmentDate,
  DueDate,
  AmountPayable,
) {
  return {
    InstallmentDate,
    DueDate,
    AmountPayable,
  };
}
const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: '#773106',
    color: theme.palette.common.white,
    textAlign: 'left',
    fontFamily: 'Sora',
    fontWeight: 'bold',



  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 16,
    textAlign: 'left',
    textAlign: 'left',
    fontFamily: 'Sora',
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: theme.palette.action.hover,
    fontFamily: 'Sora'
  },
  // hide last border
  '&:last-child td, &:last-child th': {
    border: 0,
  },
}));


const rows = [
  createData('First Installment', 'On Or Before 15th June', '15% of tax liability'),
  createData('Second Installment', 'On Or Before 15th September', '45% of tax liability'),
  createData('Third Installment', 'On Or Before 15th December', '75% of tax liability'),
  createData('Fourth Installment', 'On Or Before 15th March', '100% of tax liability'),
];
function Advancetax() {


  const Image  = styled(Box)(({ theme }) => ({
    marginTop:'30px',
    width:'95%',
    marginBottom:'3rem',
    justifyContent:'center',
    [theme.breakpoints.down("md")]: {
      marginTop:'20px',
      width:'100%',
    }
  }));
  const Hyperlink  = styled(Link)(({ theme }) => ({ 
    [theme.breakpoints.down("sm")]: {
      fontSize:'0.775rem',
      color:'blue',

    }
  }));

  const Bottomspace = styled(Box)(({ theme }) => ({
    marginBottom:'2.1rem'
  }));

  return (
    <div>
     <Topdiv heading="Are you looking to file your Income Tax Returns ?"/>
     <div className='Container'>
      <Container>
        <Grid container>
          <Grid item lg={4} xl={4}>
            <Box sx={{ display: { xs: 'none', lg: 'block' }, marginTop: '4rem', position: 'sticky', top: 90, marginBottom: '3rem' }}>
              <Sidecomponent />
            </Box>
          </Grid>
          <Grid item xs={11}  lg={8} xl={8} sx={{ marginTop: '4rem' }}>
            <Mainhead heading="Advance Tax" />
            <Content paragraph="As per section 208, every person whose estimated tax liability for the year is Rs. 10,000 or more, shall pay his tax in advance, in the form of “advance tax”." />
            <Content paragraph="Advance tax is a method of paying a portion of your expected tax liability before the end of the tax year. This helps to reduce the burden of a large tax bill at the end of the year and is also known as TAX EMI to be paid at regular intervals
          "/>
          <Bottomspace/>
            <Mainhead heading="WHY TO PAY ADVANCE TAX" />
            <Content paragraph="Under this system, taxpayers are required to pay their taxes in installments throughout the year, rather than paying the full amount at the end of the tax year. This helps to reduce the burden of a large tax bill at the end of the year and can also help taxpayers avoid interest for underpayment of taxes. Advance tax is typically required for individuals and businesses with significant income or profits." />
            <Content paragraph="It provide recurring and butter smooth flow of revenue for statutory departments and governments and is paid when taxable profits and income of an assessee exceeds Rs.10000/- in a financial year" />
            <Content paragraph="Advance tax is an exemption to “taxable in next year” rule of The Income Tax Act and is paid in the same year as income is generated." />
            <Bottomspace/>
            <Mainhead heading="Advance Tax Calculator By Legal Factory" />
            <Content paragraph="Advance tax liability can be calculated by following the given below simple steps-" />
            <Step number="STEP 1: Assesee need to estimate their total taxable income under the 5 heads of The Income Tax Act.(salaries, house property, capital gains, business and profession and other sources) as advance tax liability is calculated on estimated total income." />
            <Step number="STEP 2: Calculate the net taxable income considering the deductions and exemptions from taxable income,considering that advance tax will not be paid on salaried income.Also non taxable income will affect the applicability of tax slabs." />
            <Step number="STEP 3: Calculate the tax liability with applicable tax rates for the respective previous year." />
            <Step number="STEP 4: Deduct the TDS/TCS credit available , if any as per form 26AS" />
            <Step number="STEP 5: If the net tax liability after deduction of TDS/TCS is more than Rs. 10000/- advance tax provisions shall apply accordingly." />
           <Bottomspace/>

            <Mainhead heading="EXAMPLES" />
            <List points="Mr. Subhash has estimated total income for PY 2022-23 as Rs.1150000/- with deductions of Rs.150000/- under section 80C of the Act." />
            <ul className='list'>
              <li className='bullet'>
                <span>
            <List points="Tax liability on Rs. 1000000/-(1150000-150000) is @ Rs.112500/-
"/>
</span>
</li>

</ul>
            <Step number="As such advance tax payments will be calculated as-" />
            <ul className='list'>
            <li className='bullet'>
                <span>
            <List points="Rs.16875/-(112500*15%) on or before 15 June" />
            </span>
            </li>
            <li className='bullet'>
                <span>
            <List points="Rs.16875/-(112500*15%) on or before 15 June" />
            </span>
            </li>
            <li className='bullet'>
                <span>
            <List points="Rs.33750/-(112500*45%-16875) on or before 15 September" />
            </span>
            </li>
            <li className='bullet'>
                <span>
            <List points="Rs.33750/-(112500*75%-16875-33750) on or before 15 December" />
            </span>
            </li>
            <li className='bullet'>
                <span>
            <List points="Rs.28125/-(112500*100%-16875-33750-33750)on or before 15 March" />
            </span>
            </li>
            <li className='bullet'>
                <span>
            <Step number="Note- in case of the new tax regime new tax rates shall be applicable." />
            </span>
            </li>
          </ul>
            <Bottomspace/>

            <Mainhead heading="DUE DATES FOR PAYMENT OF ADVANCE TAX" />
            <Content paragraph="Advance tax has to be paid in installments by individual taxpayers having income other than salary. Furthermore, the taxpayers who have opted for presumptive taxation under section 44AD or 44ADA must pay total advance tax before 15th March 2022." />

            <Content paragraph="You are required to pay advance tax only if" />
            <Step number="1.You are a taxpayer having a total tax liability of Rs 10,000 and above for the financial year 2021-22." />
            <Step number="2.The source of your income is other than Salary for the financial year" />
            <Bottomspace/>

            <Mainhead heading="DUE DATES FOR PAYMENT OF ADVANCE TAX" />
            <br/>
            <TableContainer component={Paper}>
                <Table  aria-label="customized table"  size='small'>
        <TableHead>
          <TableRow>
            <StyledTableCell>Installment Date </StyledTableCell>
            <StyledTableCell>Due Date </StyledTableCell>
            <StyledTableCell>Amount Payable</StyledTableCell>
           
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.map((row) => (
            <StyledTableRow
              key={row.name}
            
            >
              <StyledTableCell component="th" scope="row"
              >
                {row.InstallmentDate}
              </StyledTableCell>
              <StyledTableCell>{row.DueDate}</StyledTableCell>
              <StyledTableCell>{row.AmountPayable}</StyledTableCell>
              </StyledTableRow>
          ))}
        </TableBody>
      </Table>
      </TableContainer>
       <br/>
      <Mainhead heading="Who Can Pay Advance Tax"/>
      <Step number="PRESUMPTIVE INCOME FOR BUSINESSES/PROFESSIONALS"/>
      <Content paragraph="Taxpayers/assesses opting for taxation under presumptive scheme under section 44AD/44ADA can pay their advance tax in one installment on or before 15 March of the respective previous year. They can deposit the whole of tax on or before 31 March of the respective previous year."/>
      <Step number="COMPANIES"/>
      <Content paragraph="Advance tax liability is also applicable to all companies as per the above mentioned provisions."/>
      <Step number="INDIVIDUALS/HUF/ BUSINESSES(SALARIED OR FREELANCERS)"/>
      <Content paragraph="Advance tax applies to all taxpayers. As such any assessee having total tax liability of Rs.10000/- or more in a previous year is required to pay advance tax."/>

      <Bottomspace/>

      <Mainhead heading="HOW TO PAY ADVANCE TAX ONLINE"/>
      <Content paragraph="Below are the step by step procedure to pay advance tax online:"/>
      <Step number="Step 1: Go to"/>
      <a href="https://onlineservices.tin.egov-nsdl.com/etaxnew/tdsnontds.jsp" style={{color:"blue"}}>https://onlineservices.tin.egov-nsdl.com/etaxnew/tdsnontds.jsp</a>
      <Image component="img"
                sx={{
                  content: {
                    xs: `url(${Advancetax2})`
                  }
                }}/>
      <Step number="Step 2:You will see the e-payment of taxes page. Click on ‘Proceed’ under challan no/ ITNS 280 "/>
      <Step number="Step 3:In the below screen,
Tax Applicable: Select Income tax (other than companies)
Type of Payment : (100) Advance tax.
Mode of payment: Select any mode as per your preference."/>
      <Step number=" Step 3:In continuation to the form, enter your PAN, Select assessment year as 2022-23, enter your address and captcha as shown in the below screen. Click on Proceed."/>
      <Image component="img"
                sx={{
                  content: {
                    xs: `url(${Advancetax3})`
                  }
                }}/>
      <Step number="Step 4:In continuation to the form, enter your PAN, Select assessment year as 2022-23, enter your address and captcha as shown in the below screen. Click on Proceed."/>
      <Image component="img"
                sx={{
                  content: {
                    xs: `url(${Advancetax4})`
                  }
                }}/>
      <Step number="Step 5:Once the payment is successful, the portal will generate a challan as below. You must note down the BSR Code and the tender date so that you can mention the same while you are filing your income tax return."/>
    <Bottomspace/>

    <Mainhead number="HOW TO PAY ADVANCE TAX THROUGH E-PAY (UPI)"/>
    <Content number="A new method for payment of advance tax or any other tax under the Act has been made available. To pay advance tax using e-pay(UPI) the given steps shall be followed-"/>
    <Step points="STEP1: Sign in e-filing portal and select NEW PAYMENT from the epay menu under e-file tab."/>
    <Image component="img"
                sx={{
                  content: {
                    xs: `url(${UPI1})`
                  }
                }}/>
                <Image component="img"
                sx={{
                  content: {
                    xs: `url(${UPI2})`
                  }
                }}/>
    <Step points="STEP 2:Select ADVANCE TAX under the type of tax field and enter other details like assessment year, amount, surcharge cess etc."/>
    <Image component="img"
                sx={{
                  content: {
                    xs: `url(${UPI3})`
                  }
                }}/>
                <Image component="img"
                sx={{
                  content: {
                    xs: `url(${UPI4})`
                  }
                }}/>
    <Step points="STEP 3: After completing the above mentioned formalities select UPI as payment method and click on pay and continue."/>

    <Content paragraph="Currentlypayment gateway provides for payment of taxes through popular UPI apps such as- GPay, PhonePe, Amazon Pay, BHIM, PayTM etc."/>
    
    <Mainhead paragraph="OFFLINE METHODS (CHEQUES/DD) FOR ADVANCE TAX PAYMENT"/>
    <Content paragraph="Advance tax can be paid offline by downloading challan through following link-"/>
    <Hyperlink href="https://www.incometaxindia.gov.in/Forms/107010000000344456" style={{color:'blue'}}>https://www.incometaxindia.gov.in<br/>/Forms/107010000000344456
</Hyperlink>
<Content paragraph="The details required shall be filled taking note of the following-"/>
<ul className='list'>
  <li className='bullet'>
    <span>
<List points="Correct PAN should be quoted, else penalty@ Rs.10000/- u/s 272B will be leviable."/>
</span>
</li>
<li className='bullet'>
                <span>
<List points="Cheque/DD shall be drawn in the name of bank income tax a/c to which cheque/DD is submitted"/>
</span>
</li>
<li className='bullet'>
                <span>
<List points="The acknowledgement should contain BSR code of the branch, date of deposit and challan identification number"/>
</span>
</li>
<li className='bullet'>
                <span>
<List points="Separate challan should be made for each payment under the Act."/>
</span>
</li>
</ul>

<Mainhead heading="RECEIPT DOWNLOAD FOR PAYMENT OF ADVANCE TAX"/>
<Image component="img"
                sx={{
                  content: {
                    xs: `url(${Advancetax5})`
                  }
                }}/>
<Content paragraph="Advance tax payment challan shall be downloaded using the following"/>
<Hyperlink href="https://tin.tin.nsdl.com/oltas/index.html" >https://tin.tin.nsdl.com/oltas/index.html</Hyperlink>
<Step number="the details as required should be filled in, and the same shall be downloaded or a print copy shall be made for future use."/>

<Mainhead heading="CHECK ADVANCE TAX PAYMENT STATUS ONLINE"/>
<Content paragraph="The following 2 methods can be used to check advance tax payment status-"/>
<Step number="Step 1: One can  visit and enter the required details to get status"/>
<Hyperlink href=" https://tin.tin.nsdl.com/oltas/index.html">https://tin.tin.nsdl.com/oltas/index.html</Hyperlink>
<Step number="Step 2: The list of total advance tax paid shall be checked under Form 26AS (tax credit)."/>

<Mainhead heading=""/>
<Step number="1.Senior citizen above the age of 60 years and not having any income from business and profession are exempt from advance tax provisions."/>
<Step number="2.Assesses having TDS/TCS credit exceeding the total tax liability are excused from payment of advance tax."/>
<Step number="3.Salaried people who fall under the TDS are exempt from paying the advance tax. Any earnings from other sources however, will be subject to advance tax."/>
<Content paragraph="If in any case tax paid is more than the total tax liability at the end of the year the same will be refunded by the department."/>
<Content paragraph="The application for the same shall be made in FORM 30 within one year from the end of assessment year."/>
<Bottomspace/>
<Faq/>
<Bottomspace/>
<Bottomspace/>

          </Grid>


        </Grid>
      </Container>
      </div>

    </div>
  )
}

export default Advancetax