
import React from 'react';
import {
    Container,
    Grid,
    styled,
    Typography,
    Button,
    Box
} from '@mui/material';
import EastIcon from '@mui/icons-material/East';
import { useNavigate } from 'react-router-dom';
import DivGap from "../../../Components/CommonCss/Home/DivGap";


const Contactus = () => {
    const Body = styled(Typography)(({ theme }) => ({
        color:'#000000',
    fontSize:'1rem',
    fontWeight:450,
    lineHeight:'1.8rem',
    marginTop:'0.5rem',
    [theme.breakpoints.down("sm")]: {
      marginLeft:'0.8rem',
      lineHeight:'1.6rem',
      fontSize:'0.85rem'
         },
        
   [theme.breakpoints.between("md", "lg")]: {
          fontSize:'0.75rem',
          lineHeight:'1.8rem'
        },

        [theme.breakpoints.up("xl")]: {
          fontSize:'1.1rem',
          lineHeight:'1.8rem'
        }
    }));
    const Redbutton = styled(Button)(({ theme }) => ({
        background: '#E32227',
        color: 'white',
        fontWeight: 'bold',
        fontSize: '1.2rem',
        marginTop: '50px',
        paddingLeft: '25px',
        paddingRight: '25px',
        "&:hover": {
            background: '#E32227',
            color: 'white',
        },
        [theme.breakpoints.between("md", "lg")]: {
            fontSize: '1.4rem',
            marginTop: '50px',

        },

        [theme.breakpoints.up("xl")]: {
            fontSize: '1.5rem',
        },
        [theme.breakpoints.down("md")]: {
            fontSize: '1.2rem',
            paddingLeft: '20px',
            paddingRight: '20px',

        },
        [theme.breakpoints.down("sm")]: {
            fontSize: '0.8rem',
            paddingLeft: '10px',
            paddingRight: '10px',

        },
    }));

    const Background = styled(Box)(({ theme }) => ({
        backgroundColor: 'rgb(250, 250, 250)',
        width: '100%',
        height: '100%',
    
    }));
    
    const navigate = useNavigate();
    const clickContact = () => {
        navigate('/contact');
    }
    return (
        <>
        <Background>
            <br/><br/>
        <div className='Container'>
            <Container>
                <Grid container>
                    <Grid item xs={12} sm={12} lg={12} xl={12}>
                        <Body>Your financial success is our mission. With a wealth of experience in financial services, we are committed to helping you navigate the complexities of tax, registration, and planning. Get in touch today, and let us be your partner in financial growth.
                        Our team is here to assist you on your financial journey!</Body>
                        <Box sx={{ textAlign: 'center' }}>
                            <Redbutton endIcon={<EastIcon style={{ color: 'white', fontSize: '40px' }} />}
                                onClick={clickContact}>Contact Us</Redbutton>
                        </Box>
                    </Grid>
                </Grid>
            </Container>


        </div>
        <DivGap/>
        </Background>
        </>
    )
}

export default Contactus