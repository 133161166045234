import React from 'react';
import {
    Container,
    Grid,
    Typography,
    styled,
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Box
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Heading from '../../../Components/CommonCss/Home/Heading';
import DivGap from "../../../Components/CommonCss/Home/DivGap";
import Ques from "../../../Components/CommonCss/FAQ/Ques";
import Ans from "../../../Components/CommonCss/FAQ/Ans";


const FAQ = () => {
    const [expanded, setExpanded] = React.useState(false);

    const handleChange = (panel) => (event, isExpanded) => {
        setExpanded(isExpanded ? panel : false);
    };

    const Border = styled(Box)(({ theme }) => ({
        border: `1px solid ${theme.palette.primary.main}`,
        borderRadius:'3px'
    }));

    const Background = styled(Box)(({ theme }) => ({
        backgroundColor: 'rgb(242, 242, 242)',
        width: '100%',
        height: '100%',

    }));
    return (
        <>
        <Background>

        <div className='Container'>
            <Container >
                <Heading mainhead = "Frequently Asked Questions"/>
                <br /><br />
                <Grid container spacing={2}
                    direction={{ xs: 'column', md: 'row' }}
                    justifyContent={{ xs: 'center', md: 'space-evenly' }}
                    alignContent={{ xs: 'center', md: 'space-evenly' }}>
                    <Grid item xs={8} sm={12} md={6} lg={6} xl={6}>
                        <Border>
                            <Accordion expanded={expanded === 'panel1'} onChange={handleChange('panel1')}
                            sx={{border: 'none !important',
                                boxShadow: 'none !important',}}
                             >
                                <AccordionSummary
                                    expandIcon={<ExpandMoreIcon />}
                                    aria-controls="panel1bh-content"
                                    id="panel1bh-header"
                                >
                                    <Ques Q="What services does Legal Factory offer?"/>
                                </AccordionSummary>
                                <AccordionDetails>
                                    
                                    <Ans A="Legal Factory offers a wide range of financial services, including Income Tax filing, GST registration and compliance, and Company Registration services. We are committed to providing comprehensive financial solutions to individuals and businesses in India."/>
                                    
                                </AccordionDetails>
                            </Accordion>
                        </Border>
                        <br />
                        <Border>
                            <Accordion expanded={expanded === 'panel3'} onChange={handleChange('panel3')}
                            sx={{border: 'none !important',
                            boxShadow: 'none !important',}}>
                                <AccordionSummary
                                    expandIcon={<ExpandMoreIcon />}
                                    aria-controls="panel1bh-content"
                                    id="panel1bh-header"
                                >
                                    <Ques Q="Does Legal Factory handle GST registration for businesses?"/>
                                </AccordionSummary>
                                <AccordionDetails>
                                    
                                <Ans A="Yes, we do! We offer end-to-end assistance for GST registration and compliance. Our team will help you understand the GST registration process, complete the necessary documentation, and ensure timely GST filing for your business."/>
                                
                                </AccordionDetails>
                            </Accordion>
                        </Border>
                        <br />
                        <Border>
                            <Accordion expanded={expanded === 'panel5'} onChange={handleChange('panel5')}
                            sx={{border: 'none !important',
                            boxShadow: 'none !important',}}>
                                <AccordionSummary
                                    expandIcon={<ExpandMoreIcon />}
                                    aria-controls="panel1bh-content"
                                    id="panel1bh-header"
                                >
                                    <Ques Q="Can Legal Factory assist with both individual and business Income Tax filing?"/>
                                </AccordionSummary>
                                <AccordionDetails>
                                    
                                <Ans A="Yes, absolutely! Our team of experts is well-versed in individual and business Income Tax filing. Whether you are a salaried individual, a self-employed professional, or a company, we have you covered."/>
                                
                                </AccordionDetails>
                            </Accordion>
                        </Border>
                    </Grid>
                    <Grid item xs={10} sm={10} md={6} lg={6}>
                        <Border>
                            <Accordion expanded={expanded === 'panel2'} onChange={handleChange('panel2')}
                            sx={{border: 'none !important',
                            boxShadow: 'none !important',}}>
                                <AccordionSummary
                                    expandIcon={<ExpandMoreIcon />}
                                    aria-controls="panel1bh-content"
                                    id="panel1bh-header"
                                >
                                    <Ques Q="How can I avail of Legal Factory's services?"
                                    />
                                </AccordionSummary>
                                <AccordionDetails>
                                <Ans A="
                                    Availing our services is easy! You can reach out to us through our website through the contact us form or contact our customer support team. We will guide you through the process and ensure a smooth experience from start to finish."/>
                                   
                                </AccordionDetails>
                            </Accordion>
                        </Border>
                        <br />
                        <Border>
                            <Accordion expanded={expanded === 'panel4'} onChange={handleChange('panel4')}
                            sx={{border: 'none !important',
                            boxShadow: 'none !important',}}>

                                <AccordionSummary
                                    expandIcon={<ExpandMoreIcon />}
                                    aria-controls="panel1bh-content"
                                    id="panel1bh-header"
                                >
                                  <Ques Q =  "Can Legal Factory provide guidance on tax-saving strategies?"/>
                                </AccordionSummary>
                                <AccordionDetails>
                                <Ans A="
                                    Yes, definitely! Our experts are well-versed in tax-saving strategies and will guide you in optimizing your tax liabilities through legitimate deductions, exemptions, and other tax-saving avenues."/>
                                   
                                </AccordionDetails>
                            </Accordion>
                        </Border>
                        <br />
                        <Border>
                            <Accordion expanded={expanded === 'panel6'} onChange={handleChange('panel6')}
                            sx={{border: 'none !important',
                            boxShadow: 'none !important',}}>
                                <AccordionSummary
                                    expandIcon={<ExpandMoreIcon />}
                                    aria-controls="panel1bh-content"
                                    id="panel1bh-header"
                                >
                                    <Ques Q="Does Legal Factory offer post-registration support and compliance services?"/>
                                </AccordionSummary>
                                <AccordionDetails>
                                <Ans A="
                                    Yes, Legal Factory offers ongoing support and compliance services even after the Company Registration process. Our team will assist you in fulfilling regulatory requirements and maintaining statutory records.
                                    "/>
                                </AccordionDetails>
                            </Accordion>
                        </Border>
                    </Grid>

                </Grid>
            </Container>


        </div>
        <DivGap spacing=""/>
        <br/>
        </Background>
        </>
    )
}

export default FAQ