import React from "react";
import { Route, Routes } from "react-router";

// import Incometax from './IncomeTax/Incometax';
// import Itrfiling from './ITR-Filing/Itrfiling';
// import Procedure from './Procedure/Procedure';
// import Residential from './ResidentialStatus/Residential';
import Salaries from "./Salaries/Salaries";
import Houseproperty from "./HouseProperty/Houseproperty";
import Business from "./BusinessAndProfession/Business";
import Capitalgain from "./CapitalGain/Capitalgain";
import Othersource from "./OtherSource/Othersource";
// import Income from './IncomeUs10/Income';
import Taxableincome from "./TaxableIncome/Taxableincome";
// import Tds from './TDS/Tds';
// import Advancetax from './AdvanceTax/Advancetax';
// import Incomeassessed from './IncomeAssessed/Incomeassessed';
// import Apeal from './Appeal/Apeal';

function IncomeTax(props) {
  return (
    <div>
      <Routes>
        {/* <Route path='incometax' element={<Incometax/>}/>
            <Route path='Itrfiling' element={<Itrfiling/>}/>
            <Route path='procedure' element={<Procedure/>}/>
            <Route path='residential' element={<Residential/>}/>
             */}
        <Route path="salaries" element={<Salaries />} />
        <Route path="houseproperty" element={<Houseproperty />} />
        <Route path="business" element={<Business />} />
        <Route path="capitalgain" element={<Capitalgain />} />
        <Route path="taxableincome" element={<Taxableincome />} />
        <Route path="othersources" element={<Othersource />} />
        {/* 
            <Route path='income' element={<Income/>}/>
            
            <Route path='tds' element={<Tds/>}/>
            <Route path='advancetax' element={<Advancetax/>}/>
            <Route path='incomeassessed' element={<Incomeassessed/>}/>
            <Route path='appeal' element={<Apeal/>}/> */}
      </Routes>
    </div>
  );
}

export default IncomeTax;
