import React from 'react';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Divider } from '@mui/material';
import Content from '../../../Components/Services/MainContent/Content';
import Mainhead from '../../../Components/Services/MainContent/Mainhead';


function Faq() {
const [expanded, setExpanded] = React.useState(false);

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };


  return (
    <div>
        <Mainhead heading="FAQ'S"/>
      <br/>
      <Divider sx={{border: '1px solid #FF990080'}}/>
      <Accordion expanded={expanded === 'panel1'} onChange={handleChange('panel1')}  elevation={0} sx={{ boxShadow: 'unset !important' }} disableGutters>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1bh-content"
          id="panel1bh-header"
        >
          <Typography  sx={{color:'#281900',fontWeight:450,fontSize:'20px'}}>Can A.O. make adjustments without intimation to assessee u/s 143(1)?</Typography>
        </AccordionSummary>
        <AccordionDetails>
         <Content paragraph ="No adjustments shall be made unless intimation is given to the
          assessee of such adjustments either in writing or in electronic mode.
          Further, the response received from the assessee shall be considered
          before making any adjustment, and in a case where no response is
          received within thirty days of the issue of such intimation, such
          adjustments shall be made."/>
            
         
        </AccordionDetails>
      </Accordion>
      <Divider sx={{border: '1px solid #FF990080'}}/>
      <Accordion expanded={expanded === 'panel2'} onChange={handleChange('panel2')}  elevation={0} sx={{ boxShadow: 'unset !important' }} disableGutters>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel2bh-content"
          id="panel2bh-header"
        >
          <Typography  sx={{color:'#281900',fontWeight:450,fontSize:'20px'}}>What is the time limit for making the assessment under section 143(1)?</Typography>
        </AccordionSummary>
        <AccordionDetails>
         <Content paragraph =" ​​​​​​​​​Assessment under section 143(1)​ can be made within a period
          of 9 months from the end of the financial year in which the return of
          income is filed."/>
            
         
        </AccordionDetails>
      </Accordion>
      <Divider sx={{border: '1px solid #FF990080'}}/>
      <Accordion expanded={expanded === 'panel3'} onChange={handleChange('panel3')}  elevation={0} sx={{ boxShadow: 'unset !important' }} disableGutters>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel3bh-content"
          id="panel3bh-header"
        >
          <Typography  sx={{color:'#281900',fontWeight:450,fontSize:'20px'}}>What is the procedure adopted for making the assessment under section 143(3) i.e. scrutiny assessment?</Typography>
        </AccordionSummary>
        <AccordionDetails>
         <Content paragraph =" 12 months from the end of the assessment year in which the income was
          first"/>
            
         
        </AccordionDetails>
      </Accordion>
      <Divider sx={{border: '1px solid #FF990080'}}/>
      
      <Divider sx={{border: '1px solid #FF990080'}}/>

    </div>
  )
}

export default Faq