import React from "react";
import { Box } from "@mui/material";
import Mainhead from "../../../Components/Services/MainContent/Mainhead";
import Content from "../../../Components/Services/MainContent/Content";
import CustomList from "../../../Components/Income-Tax/CustomList";
import Step from "../../../Components/Services/MainContent/Step";
import ReusableTable from "../../../Components/Income-Tax/ReusableTable";
import { Image } from "../../../Components/Income-Tax/ImageStyle";
import Image8 from "../../../Assets/IncomeTax/hp3.jpg";
import Image4 from "../../../Assets/IncomeTax/hp4.jpg";

const HousingLoanDeduction = () => {
  const deductionTableData = [
    {
      Particulars: "Gross annual Value (Rent paid- 10000*12)",
      SOP: "Nil",
      "Let out": "1,20,000",
    },
    {
      Particulars: "Less: Municipal Taxes or Taxes paid to local authorities",
      SOP: "NA",
      "Let out": "(4,500)",
    },
    { Particulars: "Net Annual Value(NAV)", SOP: "Nil", "Let out": "1,15,500" },
    {
      Particulars: "Less: Standard Deduction",
      SOP: "NA",
      "Let out": "(34,650)",
    },
    {
      Particulars: "Section 24(b) : Interest on Housing Loan",
      SOP: "(2,00,000)",
      "Let out": "(2,50,000)",
    },
    {
      Particulars: "Pre-construction interest (1/5th of 3 Lakhs)",
      SOP: "(60,000)",
      "Let out": "(60,000)",
    },
    {
      Particulars: "Income from House Property",
      SOP: "(2,60,000)",
      "Let out": "(2,29,150)",
    },
    {
      Particulars: "Overall loss restricted to",
      SOP: "(2,00,000)",
      "Let out": "(2,00,000)",
    },
  ];

  return (
    <Box
      style={{
        display: "flex",
        flexDirection: "column",
        gap: 4,
        marginBottom: "2rem",
      }}
    >
      <Mainhead heading="Section 24b - Deduction of Interest on Housing Loan In India" />
      <Step
        number={`
          Introduction
        `}
      />
      <Content paragraph="Section 24b of the Income Tax Act allows for the deduction of house loan interest from taxable income. Such a loan should be obtained for the purchase, construction, repair, or reconstruction of real estate." />

      <Step
        number={`
          What are various Inclusions/Exclusions in Interest as per Income Tax Act, 1961 In India?
        `}
      />
      <Content paragraph="Interest comprises service fees, brokerage, commission, and prepayment penalties, among other things. Interest or penalties for overdue interest are not deductible." />

      <Step
        number={`
          What Is the Maximum Deduction Limit Under Section 24B of Income Tax Act,1961 In India?
        `}
      />
      <Content paragraph="The maximum deduction limit on the interest of a loan is ₹ 2,00,000. It is applicable for both rental and self-occupied housing property. Individuals can avail benefit for two self-occupied housing properties with effect from AY 2020-2021." />
      <Content paragraph="In the following instances, the aforementioned SOP maximum of Rs 2,000,000 is reduced to Rs 30,000." />
      <CustomList
        points={[
          "Loan borrowed before 01-04-1999 for any purpose related to house property.",
          "Loan borrowed after 01-04-1999 for any purpose other than construction or acquisition.",
          "If construction/acquisition is not completed within 3 years from the end of the financial year in which capital was borrowed.",
        ]}
      />
      <Content
        paragraph={`For example, if a loan is acquired for construction/acquisition on November 25, 2023, the deduction limit should be reduced to Rs 30,000 if the construction/acquisition is completed after March 31, 2029.`}
      />

      <Step
        number={`
          What to do for the Interest Paid on Loan in the Pre Acquisition Period In India?
        `}
      />
      <Content paragraph="Interest for the pre-construction/acquisition period is allowable in five equal installments beginning from the year of completion of the house property. If the loan is used for repairs, renewal, or reconstruction, this deduction is not eligible." />
      <Content paragraph="The pre-construction/acquisition period begins on the date of borrowing and concludes on the last day of the previous fiscal year in which the construction is completed." />
      <Content
        paragraph={`For example, if the house property is completed on 1st January 2020, then the deduction is allowed from Financial Year 2020-2021 to 2024-25.`}
      />

      <Step
        number={`
          Assume a person repays a housing loan of Rs 6 lakh per year, of which Rs 2.5 lakh is interest...
        `}
      />
      <Content paragraph="Assume a person repays a housing loan of Rs 6 lakh per year, of which Rs 2.5 lakh is interest. He has also paid Rs 3 lakh in pre-construction interest. He earns Rs 10,000 per month from a rented property and pays Rs 4,500 in municipal taxes on the residence. Let's compute his income from real estate in both scenarios: (1) He owns a home for himself, or (2) the home is rented out." />
      <ReusableTable data={deductionTableData} />

      <Step
        number={`
          What are the deductions available for co-borrowers in the Income Tax Act,1961 In India?
        `}
      />
      <Content paragraph="Each co-borrower is entitled to a deduction based on his or her part of the loan if the home loan is carried out in joint names. In order to be eligible for this deduction, the co-borrower must also share ownership of the property. The assessee may deduct all interest paid if he is a co-owner and is repaying the entire loan on his own." />
      <Content paragraph="The deduction cap for self-occupied property is applicable to each co-borrower on an individual basis. Stated differently, a co-borrower may deduct up to Rs. 2 lakh/Rs. 30,000. There are no restrictions on renting out property." />

      <Step
        number={`
          WHAT IS THE DIFFERENCE BETWEEN SECTION 24b AND SECTION 80C OF THE INCOME TAX ACT,1961 IN INDIA?
        `}
      />
      <Content paragraph="Section 24b permits interest on home loans, while Section 80C permits principal on home loans. The following is a comparison between section 24 and 80C:" />

      <ReusableTable
        data={[
          {
            Basis: "Tax Deduction allowed",
            "Section 24b": "Only for Interest",
            "Section 80C": "Principal",
          },
          {
            Basis: "Basis of Tax Deduction",
            "Section 24b": "Accrual Basis",
            "Section 80C": "Cash Basis",
          },
          {
            Basis: "Amount of Deduction",
            "Section 24b":
              "Self-occupied property: Rs. 2,00,000 Other than Self-occupied property: No limit",
            "Section 80C": "Rs. 1,50,000",
          },
          {
            Basis: "Purpose of loan",
            "Section 24b":
              "Purchase/ Construction/ Repair/ Renewal/ Reconstruction of a Residential House Property.",
            "Section 80C": "Purchase / Construction of a new House Property",
          },
          {
            Basis: "Restriction on Sale of Property",
            "Section 24b": "NIL",
            "Section 80C":
              "Tax Deduction claimed would be reversed if Property sold within 5 years from the end of financial year in which such property is acquired by him.",
          },
          {
            Basis: "Deduction during construction period",
            "Section 24b":
              "Interest paid during the construction/acquisition period shall be allowed in 5 equal installments from the last day of preceding Financial Year in which the construction is completed",
            "Section 80C":
              "No deduction is available for the principal repayment during the construction/acquisition period.",
          },
        ]}
      />
      <Step
        number={`
          HRA and Interest On House Loan In India
        `}
      />
      <Content paragraph="Let's start with the fundamentals." />
      <Content paragraph="HRA is typically paid by the company to the employee to cover the cost of a rented/shared space. If and only if the employee lives in a rented place, the Income Tax Act provides a special exemption in the form of HRA. An employee must pay the rent for the place he or she occupies in order to claim a tax exemption." />
      <Content paragraph="In terms of interest, the Income Tax Act allows taxpayers to deduct interest on home loans. As a result, one can claim tax deductions for any house loan acquisition without difficulty. Furthermore, if the loans are used for other reasons such as reconstruction, renovation, or purchase, the interest deduction still applies." />

      <Step
        number={`
          Can you get both HRA and home loan benefits at the same time under Income Tax Act, 1961 In India?
        `}
      />
      <Content paragraph="There are no restrictions for collecting HRA and home loan interest at the same time, even if both residences are in the same city. However, there should be enough grounds for you to leave the house you purchased. Such allegations are frequently scrutinized by Income Tax inspectors. If they are not satisfied, they can deny a portion of the claim, especially if the sum is somewhat large." />
      <Content paragraph="Furthermore, in order to claim both, you must have all of the appropriate documents, such as the sales deed, completion certificate, and so on, to back up your claim in the event of a dispute." />
      <Content paragraph="To answer your question, yes, you can claim both tax breaks in the same year, lowering your taxable income greatly. However, you must keep good records and submit the necessary documentation." />

      <Step
        number={`
          Different Scenarios For Claiming HRA and Interest Deduction at the same time
        `}
      />
      <Content paragraph="An employee shall claim both HRA and the interest on a home loan as both these provisions are independent of each other. Possible scenarios that may arise and whether the Act permits the taxpayer to claim the benefit of both:" />

      <ReusableTable
        data={[
          {
            Scenarios:
              "In situations where a taxpayer owns real estate but lives in a rental home in a different place for employment or other reasons",
            "Is it possible for a taxpayer to claim both the HRA exemption and the interest deduction on a house loan?":
              "Yes",
            Example:
              "Mr. Abhi has a residence in Delhi that he purchased with the help of a home loan. Interest is being paid by him on the debt. He lives in a rented Bangalore apartment because of his job, and his employer pays HRA. As a result, Mr. Abhi is entitled to a deduction of this interest under section 24(b) and an exemption from HRA under Act 10(13A) of the Income Tax Act, 1961.",
          },
          {
            Scenarios:
              "When a taxpayer owns a house property on which he has paid a home loan but lives in a leased house in the same city due to employment, children's schooling demands, or any other fair and reasonable cause",
            "Is it possible for a taxpayer to claim both the HRA exemption and the interest deduction on a house loan?":
              "Yes",
            Example:
              "Mr. Suresh has a residence in South Mumbai that he purchased with the help of a home loan. He lives in a leased home in Mumbai's northern region and is compensated by his company for housing allowance (HRA). As a result, Mr. Suresh is entitled to a deduction of this interest under section 24(b) and an exemption from HRA under Act 10(13A).",
          },
          {
            Scenarios:
              "When the taxpayer's house is under construction and he or she is living elsewhere on rent",
            "Is it possible for a taxpayer to claim both the HRA exemption and the interest deduction on a house loan?":
              "Yes. The taxpayer can, however, deduct the interest on such under-construction property over the next five years in equal increments beginning with the year construction is completed",
          },
          {
            Scenarios: "When you rent your own home and live somewhere else.",
            "Is it possible for a taxpayer to claim both the HRA exemption and the interest deduction on a house loan?":
              "Yes. In this instance, you must present rental receipts from your own home when computing your tax liability",
            Example:
              "Mr. Ramesh owns property in Jaipur, which he rents out to a tenant. He lives on rent in different accommodations. He is eligible to claim HRA exemption for rent paid and deduction of interest on the home loan.",
          },
        ]}
      />

      <Step
        number={`
          HOME LOAN FOR JOINT OWNERS
        `}
      />
      <Content paragraph="One of the most common ways to finance a new home is through a joint home loan. This reduces the financial burden on a single person by distributing it among two or more co-borrowers. It also provides various tax benefits given that certain conditions are fulfilled." />

      <Step
        number={`
          WHAT DOES JOINT HOME LOAN MEAN?
        `}
      />
      <Content paragraph="Joint home loan is when two or more members of a family jointly apply for a home loan, sharing the responsibility of repayment of such loan." />

      <Step
        number={`
          WHAT ARE THE DIFFERENT CONDITIONS TO AVAIL TAX BENEFIT IN CASE OF JOINT OWNERSHIP?
        `}
      />
      <ReusableTable
        data={[
          {
            Conditions: "Ownership in property",
            Details:
              "All the applicants of the loan must have ownership in the property for which the loan has been taken.",
          },
          {
            Conditions: "Family members",
            Details:
              "The persons jointly applying for a home loan must be closely related to each other. For example, parents, spouse, siblings, etc.",
          },
          {
            Conditions: "Co-borrowers",
            Details:
              "In order to claim tax benefits, you must be an applicant to the loan as per the loan documents and must be contributing a certain share in the EMI.",
          },
          {
            Conditions: "Fully constructed property",
            Details:
              "Tax benefits can be claimed from the financial year in which the construction has been completed.",
          },
        ]}
      />

      <Step
        number={`
          WHAT ARE THE TAX BENEFITS ON JOINT HOME LOAN UNDER INCOME TAX ACT, 1961 IN INDIA?
        `}
      />
      <Content paragraph="If you fulfill the conditions specified above, you can enjoy the following tax benefits:" />

      <ReusableTable
        data={[
          {
            Benefits: "Tax benefit on interest payment",
            Details:
              "As per section 24 of Act, interest payments made towards a home loan can be claimed as deduction. The maximum deduction that can be claimed for a self-occupied property is Rs 2 lakhs for each co-borrower. There is no maximum deduction limit in case of let out property.",
          },
          {
            Benefits: "Tax benefit on principal repayment",
            Details:
              "As per section 80C of the Act, each co-borrower can claim a maximum deduction of Rs. 1.5 Lakhs for the principal amount repaid on the home loan during the year. Thus, the deduction that can be claimed will increase as the number of co-borrowers will increase.",
          },
          {
            Benefits: "Higher loan amount",
            Details:
              "Since the combined income of all the co-borrowers is used to determine the eligible amount, you can apply for a higher loan amount.",
          },
          {
            Benefits: "Deduction for first time home buyers",
            Details:
              "If the co-borrowers are first-time home buyers they can jointly avail an additional deduction of Rs. 1.5 Lakhs on the interest paid.",
          },
          {
            Benefits: "Maximizing tax benefits",
            Details:
              "Thus, by taking a joint home loan the tax benefits can be maximized as each co-borrower can claim deductions independently based on their share of the loan.",
          },
        ]}
      />

      <Step
        number={`
          VARIOUS TYPES OF HOUSE PROPERTY 
        `}
      />
      <Content paragraph="SELF OCCUPIED PROPERTY (SOP) - Where property consists of a house or part of a house which:-" />
      <CustomList
        points={[
          "Is occupation of the owner for the purpose of his own residence or",
          "Cannot actually be occupied by the owner",
          "By reason of the fact that",
          "Owing to his employment or business or profession",
          "Carried out any other place",
          "He has to reside at the other place in a building",
          "Not belonging to him",
        ]}
      />

      <Content paragraph="The following are the main details about self-occupied property in India for income tax purposes:" />
      <CustomList
        points={[
          "Tax Implication: A person may be eligible for certain tax benefits under the Income Tax Act if they own a single residential house that they occupy themselves. This applies to individuals who own only one residential house property.",
          "Annual Value of Self-Occupied Property: For income tax purposes, the annual value of a self-occupied property is zero. For tax purposes, the annual value of a self-occupied property is assumed to be zero, in contrast to rented properties where the actual rent received or expected rent is taken into account.",
          "Deductions for Interest Paid: Those who have borrowed money to build or buy a primary residence are eligible to deduct interest from their home loan payments. A maximum deduction of ₹2 lakh is permitted on interest paid for a self-occupied property per financial year under Section 24(b) of the Income Tax Act.",
          "Multiple Self-Occupied Properties: The advantage of treating the dwellings as self-occupied has been extended to two houses. As per income tax, an owner can declare his two homes as self-occupied, and the remaining house as rented.",
        ]}
      />

      <Step
        number={`
          LET OUT PROPERTY - A Let Out House Property is a property that is rented out, either entirely or partially.
        `}
      />
      <CustomList
        points={[
          "Tax Implication - Determining Annual Value: The income tax considers the let-out property's annual worth for determining the taxable income from residential property. Usually, the annual value is calculated using the possible rent that the property could attract on the market, also known as the reasonably expected rent.",
          "Actual Rent Received vs. Expected Rent: The actual rent that the owner receives or the expected rent, which is assumed to be the potential rent (maximum amount of rental income), is the larger value used to determine the annual value subject to taxes. The actual rent received is what is regarded as the annual value if it is more than the expected rent. For taxation purposes, the expected rent is taken into account if the actual rent is less than that amount.",
          "Deductions on Municipal Taxes and Standard Deduction: Owners of let-out properties are entitled to a deduction for municipal taxes paid throughout the Financial year. A standard deduction of up to 30% of the annual value is also included. This standard deduction is irrespective of the actual expenses incurred.",
          "Interest on Loan Deduction: Under Section 24(b) of the Income Tax Act, those who have taken out a loan for the rental property may deduct the interest they have paid on their home loan.",
        ]}
      />

      <Step
        number={`
          INHERITED PROPERTY -
        `}
      />
      <Content paragraph="Property obtained by the use of the laws of distribution and descent. Upon a person's death, inheritance is the custom of transferring assets, debts, titles, rights, and duties to that person's legal heir." />
      <Content paragraph="Real estate or any other tangible asset that passes to a person through the legal process of inheritance upon the death of the original owner is referred to as inherited property." />
      <CustomList
        points={[
          "Tax Implications at the Time of Inheritance: In India, there is no income tax applied to the act of inheriting property. An inheritance-related property transfer is not regarded as a taxable event.",
          "Tax Implications on Rental Income: If the inherited residence is rented out, the inheritor's rental income is taxable under the 'Income from House Property' heading. Deductions for municipal taxes and standard deductions on the annual value of the property can be claimed.",
        ]}
      />

      <Step
        number={`
          PARTLY LET OUT - A property is said to be "partly let out" if its owner uses a section of it for their own purposes and rents out the remaining space to tenants.
        `}
      />
      <CustomList
        points={[
          "Annual Value: The annual value for tax purposes of a partially leased property is computed differently for the rented and self-occupied property.",
          "Rental Income from Let-Out Portion: The actual rent received or the expected rent that the rented portion could reasonably obtain in the market is used to calculate the rented portion's annual worth. Income tax is owed on this yearly rental income.",
          "Self-Occupied Portion: For income tax purposes, the annual value of the self-occupied portion of the property is nil.",
          "Deductions and Tax Treatment: The entire amount of municipal taxes paid by the property can be written off by the owner. However, only the rental portion of the property is eligible for the standard deduction of 30% of the annual value.",
        ]}
      />

      <Mainhead
        heading={`
          SECTION 80EE AND 80EEA OF INCOME TAX ACT 1961
        `}
      />
      <Step
        number={`
          SECTION 80 EE - INTEREST ON LOAN TAKEN FOR RESIDENTIAL HOUSE IN INDIA -
        `}
      />
      <Content paragraph="Section 80EE provides income tax benefits on the interest component of a residential home loan obtained from any financial institution. This part allows you to claim a deduction of up to Rs 50,000 per financial year. You can continue to claim this deduction until you have fully repaid the loan." />
      <Box sx={{ textAlign: "center" }}>
        <Image
          component="img"
          sx={{
            content: {
              xs: `url(${Image8})`,
            },
          }}
        />
      </Box>
      <Step
        number={`
          SECTION 80 EEA - INTEREST ON LOAN TAKEN FOR RESIDENTIAL HOUSE IN INDIA
        `}
      />
      <Content
        paragraph={`"Housing for all," the government has extended the interest deduction allowed for low-cost house loans taken between April 1, 2019, and March 31, 2022. Taxpayers may deduct under Section 80 EEA from their income until their home debt is settled.`}
      />
      <Box sx={{ textAlign: "center" }}>
        <Image
          component="img"
          sx={{
            content: {
              xs: `url(${Image4})`,
            },
          }}
        />
      </Box>
    </Box>
  );
};

export default HousingLoanDeduction;
