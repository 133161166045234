import React from 'react';
import Mainhead from '../../../../Components/Services/MainContent/Mainhead';
import List from '../../../../Components/Services/MainContent/List';
import Step from '../../../../Components/Services/MainContent/Step';
import { Box , styled} from '@mui/material';
import Submitted from '../../../../Assets/Services/Partnership/LegalFactory_Partnership2.png'
import Officeregistration from '../../../../Assets/Services/Partnership/LegalFactory_Partnership1.png'



const Submitimg = styled(Box)(({ theme }) => ({
  width:'28,5rem',
  height:'32rem',
  marginTop:'0.8rem',
  [theme.breakpoints.down("sm")]: {
    width:'18rem',
  height:'21.5rem',
  },
  [theme.breakpoints.down("md")]: {
    width:'18rem',
  height:'21.5rem',
  },
  [theme.breakpoints.between("md", "lg")]: {
    width:'25rem'
  }
}));

const Office = styled(Box)(({ theme }) => ({
  width:'28,5rem',
  height:'32rem',
  marginTop:'0.8rem',
  [theme.breakpoints.down("sm")]: {
    width:'18rem',
  height:'21.5rem',
  },
  [theme.breakpoints.down("md")]: {
    width:'18rem',
  height:'21.5rem',
  },
  [theme.breakpoints.between("md", "lg")]: {
    width:'25rem'
  }
}));

function Register() {
  return (
    <div>
      <Mainhead heading="Legal Factory - Partnership Firm online registration process :"/>

      <Step number="Step - 1: Arrange basic documents of partners."/>
      

      <Box sx={{textAlign:'center'}}>
      <Submitimg component="img"
                sx={{
                  content: {
                    xs: `url(${Submitted})`
                  }
                }}/>
                </Box>
              <br/>
                <Box sx={{textAlign:'center'}}>
      <Office component="img"
                sx={{
                  content: {
                    xs: `url(${Officeregistration})`
                  }
                }}/>
                </Box>


      <Step number="Step -2 :File an Application"/> 
      <Step number="Step -3 : Preparation of Partnership deed"/>
     <ul className='list'>
      <li className='bullet'>
        <span>
      <List points="Details of the partners and firm such as their name, address, qualification"/>
      </span>
      </li>
      <li className='bullet'>
        <span>
      <List points="Nature of business activities involved"/>
      </span>
      </li>
      <li className='bullet'>
        <span>
      <List points="Capital contribution made by all partners"/>
      </span>
      </li>
      <li className='bullet'>
        <span>
      <List points="Shares/Interest of all the partners"/>
      </span>
      </li>
      <li className='bullet'>
        <span>
      <List points="Profit/loss sharing ratio among all the partners"/>
      </span>
      </li>
      <li className='bullet'>
        <span>
      <List points="Rights, duties, salaries, commissions or payable amount of the partners"/>
      </span>
      </li>
      <li className='bullet'>
        <span>
      <List points="Details of the loans provided by the partners"/>
      </span>
      </li>
      <li className='bullet'>
        <span>
      <List points="Circumstances or process that would be followed in case of death or retirement of any designated partner"/>
      </span>
      </li>
      <li className='bullet'>
        <span>
      <List points="Other clauses made with mutual consent of all the partners."/>
      </span>
      </li>
      </ul>
      <Step number="Step -4: Submission of the Documents"/>
      <Step number="Step -5: Verification of Documents And Issuance of Registration Certificate."/>
      
    </div>
  )
}

export default Register