import React from 'react'
import Mainhead from '../../../../Components/Services/MainContent/Mainhead';
import Content from '../../../../Components/Services/MainContent/Content';
import List from '../../../../Components/Services/MainContent/List';
import { Box , styled} from '@mui/material';
import Step from '../../../../Components/Services/MainContent/Step';
import StartupBanner1 from '../../../../Assets/Services/StartupReg/LegalFactory_StartUp2.png';
import StartupBanner2 from '../../../../Assets/Services/StartupReg/LegalFactory_StartUp3.png';
import StartupBanner3 from '../../../../Assets/Services/StartupReg/LegalFactory_StartUp4.png';

const Tabletimg = styled(Box)(({ theme }) => ({
    width:'15rem',
    height:'10rem',
    marginTop:'0.8rem',
    [theme.breakpoints.down("sm")]: {
     width:'4rem',
   height:'8rem',
   },
   [theme.breakpoints.down("md")]: {
     width:'9rem',
   height:'8rem',
   },
   [theme.breakpoints.between("md", "lg")]: {
    width:'10rem'
   },
   [theme.breakpoints.up("xl")]: {
     width:'16rem'
    }
 }));

function Overview(){
    return(
        <div>
            <Mainhead heading="What is Startup India Registration?" />
            <Content paragraph= "The Startup India initiative is a blue belt initiative by the Govt of India to foster the entrepreneurships and assist startup innovations in flourishing by aiding bank financing, resolving the incorporation process & granting exemptions to startups, provided you adequately meet/satisfy the conditions for an ‘Eligible Startup’ to gain all the listed/mentioned benefits." />

            <Step number="STARTUP India SCHEME"/>
            <Content paragraph="Under this scheme, Govt of India is promoting the innovative startups by easing the heavy regulatory burdens and registration process for an inception to a startup entrepreneurship so that they can focus more on setting up their businesses and achieve their objectives along with granting numerous benefits in addition to the huge/giant networking opportunities thrown-at by the Indian Govt raised global-level and domestic-tier startup fairs bi-annually." />

            <Step number="VISION & MISSION OF OUR TEAM"/>
            <Content paragraph="We boldly resolve to register your Startup and provide the benefits of Government policies aiding Startups. We embolden your ambitions, we encourage and anchor your dream aspirations; register your Startup here." />

            <Box sx={{flexDirection: 'row' }}>
                <Tabletimg component="img"
                sx={{
                  content: {
                    xs: `url(${StartupBanner1})`
                  }
                }}/>
                <Tabletimg component="img"
                sx={{
                  content: {
                    xs: `url(${StartupBanner2})`
                  }
                }}/>
                <Tabletimg component="img"
                sx={{
                  content: {
                    xs: `url(${StartupBanner3})`
                  }
                }}/>

            </Box>

        </div>
    )
}

export default Overview