import React from 'react'
import Mainhead from '../../../../Components/Services/MainContent/Mainhead';
import Content from '../../../../Components/Services/MainContent/Content'
import Step from '../../../../Components/Services/MainContent/Step';
function Overview() {
  return (
    <div>
      <Mainhead heading="What is Shop Act Registration ?"/>
      <Content paragraph="The Labour Department of every state regulates the Shop & Establishment Act in their own state. And although the general provisions of the Act are the same in all states they differ slightly when states issue the registration under the Act. Shops coming under the Act are the ones where selling of the goods takes place either by retail or wholesale or where services are rendered to customers like every cafeteria, cinemas, restaurants, offices, etc places of entertainment or who have a connection with trade or business. The Shop and Establishment Act regulates the functioning of businesses wherein any trade is carried out. Not only does this apply to commercial establishments but also to charitable trusts, profit-run educational institutions."/>

      <Step number="STATUTORY MEANING TO COMMERCIAL ESTABLISHMENTS"/>
      <Content paragraph="Commercial establishments are generally defined as a commercial, banking, trading or insurance establishment or administrative service in which persons are employed for office work. It includes a hotel, boarding or eating house, restaurant, cafe, theatre, or other public entertainment or amusement places.
      However, factories and industries are not governed by the Act."/>

    </div>
  )
}

export default Overview