import React from 'react'
import Mainhead from '../../../../Components/Services/MainContent/Mainhead';
import Content from '../../../../Components/Services/MainContent/Content';
import List from '../../../../Components/Services/MainContent/List';
import { Box, styled } from '@mui/material';
import Step from '../../../../Components/Services/MainContent/Step';

//import ChecklistBanner from "../../../../Assets/Services/StartupReg/ChecklistBanner.png"


function Checklist() {
    return (
        <div>
            <Mainhead heading="Checklist for a Nidhi Company " />
            <ul className="list">
                <li className="bullet">
                    <span>
                        <List points="At Least 7 members are mandatory to form a Nidhi Company. Out of these, three should be designated as the directors. However, it should acquire a minimum of 200 members within one year of commencement." />
                    </span>
                </li>
                <li className='bullet'>
                    <span>
                        <List points="Moreover, the company should have a minimum equity share of Rs 5 lakhs, for registering as a Nidhi Company. This entire amount has to be paid up. However, the Net Owned Funds (NOF) must be increased to Rs 10 lakhs within a year of registration." />
                    </span>
                </li>
                <li className='bullet'>
                    <span>
                        <List points="At least 10% of its outstanding deposits should comprise unencumbered term deposits." />
                    </span>
                </li>
                <li className='bullet'>
                    <span>

                        <List points="The prescribed NOF to deposits ratio should be 1:20 where 10% of the total deposits are in a fixed deposit account of a nationalised bank." />
                    </span>
                </li>
            </ul>




        </div>


    )
}

export default Checklist