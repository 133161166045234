import React from 'react'
import Mainhead from '../../../../Components/Services/MainContent/Mainhead';
import Content from '../../../../Components/Services/MainContent/Content';
import List from '../../../../Components/Services/MainContent/List';
import { Box, styled } from '@mui/material';
import Step from '../../../../Components/Services/MainContent/Step';

function Advantage() {
    return (
        <div>
            <Mainhead heading="Advantages of Startup Scheme" />
            <ul className='list'>
                <li className='bullet'>
                    <span>
                        <List points="Self-Certification: Self-certify and comply under 3 Environmental & 6 Labour Laws." />
                    </span>
                </li>
                <li className='bullet'>
                    <span>
                        <List points="Tax Exemption: Income Tax exemption for a period of 3 consecutive years and exemption on capital and investments above Fair Market Value" />
                    </span>
                </li>
                <li className='bullet'>
                    <span>
                        <List points="Easy Winding of Company: In 90 days under Insolvency & Bankruptcy Code, 2016." />
                    </span>
                </li>
                <li className='bullet'>
                    <span>
                        <List points="Startup Patent Application & IPR Protection: Fast track patent application with up to 80% rebate in filling patents." />
                    </span>
                </li>
                <li className='bullet'>
                    <span>
                        <List points="Easier Public Procurement Norms: Exemption from requirement of earnest money deposit, prior turnover and experience requirements in government tenders." />
                    </span>
                </li>
                <li className='bullet'>
                    <span>
                        <List points="SIDBI Fund of Funds: Funds for investment into startups through Alternate Investment Funds" />
                    </span>
                </li>
            </ul>
        </div>
    )
}

export default Advantage