import React from "react";
import { Link } from "react-router-dom";
import { Box } from "@mui/material";

function Sidecomponent({ menuItems }) {
  return (
    <div>
      <Box
        width="90%"
        height="auto"
        style={{
          display: "flex",
          flexDirection: "column",
          background: "#FFFFFF",
          boxShadow: "rgba(0, 0, 0, 0.35) 0px 5px 15px",
        }}
      >
        <ul className="sidenav">
          {menuItems.map((item, index) => (
            <li className="sidelist" key={index}>
              <Link to={item.path} className="nav-link">
                {item.label}
              </Link>
            </li>
          ))}
        </ul>
      </Box>
    </div>
  );
}

export default Sidecomponent;
