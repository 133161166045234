import React from "react";
import { Box, Typography, styled } from "@mui/material";
import Mainhead from "../../../Components/Services/MainContent/Mainhead";
import Content from "../../../Components/Services/MainContent/Content";
import Step from "../../../Components/Services/MainContent/Step";

const ServiceContent = styled(Typography)(({ theme }) => ({
  color: "#000000",
  fontSize: "1rem",
  fontWeight: 450,
  lineHeight: "1.8rem",
  marginTop: "0.5rem",

  [theme.breakpoints.down("sm")]: {
    marginLeft: "0.8rem",
    lineHeight: "1.6rem",
    fontSize: "0.85rem",
  },
  [theme.breakpoints.between("md", "lg")]: {
    fontSize: "0.75rem",
    lineHeight: "1.8rem",
  },
  [theme.breakpoints.up("xl")]: {
    fontSize: "1.1rem",
    lineHeight: "1.8rem",
  },
}));

const IncomeChargeableUnderBusinessAndProfession = () => {
  return (
    <Box
      style={{
        display: "flex",
        flexDirection: "column",
        gap: 4,
        marginBottom: "2rem",
      }}
    >
      <Mainhead heading="Income Chargeable Under Business and Profession" />

      <Content paragraph="Introduction: Provisions of section 28 of the Income Tax Act, 1961 specify the income chargeable to income tax under the taxable head ‘Profits and gains of business or profession’." />

      <Content paragraph="The terms 'Business' and 'Profession' are defined in the Income Tax Act and are described further below -" />

      <Step
        number={`
            Business:
          `}
      />
      <Content paragraph="According to Section 2(13) of the Income Tax Act, a 'business' is any trade, commerce, or manufacture, as well as any adventure or concern in the nature of trade, commerce, or manufacture." />

      <Step
        number={`
            Profession:
          `}
      />
      <Content paragraph="The term 'profession' is defined in Section 2(36) of the Income Tax Act. As a result, the profession is simply stated to include vocation." />

      <Step
        number={`
            Vocation:
          `}
      />
      <Content paragraph="Vocation refers to a person's innate aptitude to perform a specific task, such as singing or dancing etc. There is no training or qualification required here, only natural aptitude." />

      <Step
        number={`
            What is Section 28: Basis of Charge as per Income Tax Act,1961?
          `}
      />
      <Content paragraph="The incomes listed below are subject to income tax under the 'PGBP' head:" />
      <ul className="list">
        <li>
          <strong>(1) Revenue Profits from Business or Profession:</strong>
          <ul>
            <ServiceContent component="li">
              The sum of all of the assessee's business or professional gains
              made at any point in the prior year;
            </ServiceContent>
            <ServiceContent component="li">
              Losses in the course of Business - Revenue, Capital, Transfer, No
              transfer Deduction U/s 28 Chargeable under 'Capital Gain', No
              deduction
            </ServiceContent>
          </ul>
        </li>

        <li>
          <strong>
            (2) Any remuneration or additional payment owed to or obtained from:
          </strong>
          <ul>
            <ServiceContent component="li">
              Compensation received by Circumstances thereof
              <ul>
                <ServiceContent component="li">
                  Any person in charge of an Indian Company's affairs completely
                  or substantially. Upon termination of his management or
                  alteration of the terms and conditions governing it.
                </ServiceContent>
                <ServiceContent component="li">
                  Any individual In connection with the transfer of management
                  of any business or property to the Government/Corporation
                  (owned or managed by the Government). & If the terms and
                  conditions of any contract relating to his business are
                  terminated or modified
                </ServiceContent>
                <ServiceContent component="li">
                  Any person holding an agency in India In relation to the
                  cancellation or alteration of the agency's terms and
                  conditions
                </ServiceContent>
              </ul>
            </ServiceContent>
          </ul>
        </li>

        <li>
          <strong>
            (3) Money received by a trade, professional, or comparable
            association in exchange for particular services rendered to its
            members.
          </strong>
        </li>

        <li style={{ marginTop: ".5rem" }}>
          <strong>(4) Receipts related to overseas trade:</strong>
          <ul>
            <ServiceContent component="li">
              Revenue from the import license sale.
            </ServiceContent>
            <ServiceContent component="li">
              Certificates for duty-free replenishment, duty remission
              (reduction), and duty drawback.
            </ServiceContent>
            <ServiceContent component="li">Monetary Support.</ServiceContent>
            <ServiceContent component="li">
              The Duty Entitlement Passbook's sale proceeds.
            </ServiceContent>
            <ServiceContent component="li">
              Paying back anyone who received customs or excise taxes in
              relation to exports.
            </ServiceContent>
          </ul>
        </li>

        <li>
          <strong>
            (5) Worth of any advantages or benefits from a career or business:
          </strong>
          <ServiceContent component="p">
            The worth of any advantage or privilege, whether or not it can be
            exchanged for cash, that results from conducting business or
            practicing a vocation.
          </ServiceContent>
        </li>

        <li>
          <strong>(6) Remuneration to partner from the firm:</strong>
          <ServiceContent component="p">
            Any interest, pay, commission, bonus, or other compensation that a
            partner in a firm owes or receives from the firm, provided that it
            has been approved as a deduction when determining the taxable
            profits of that firm.
          </ServiceContent>
        </li>

        <li>
          <strong>
            (7) Amount received or due in accordance with a specific agreement:
          </strong>
          <ul>
            <ServiceContent component="li">
              Not engaging in any business-related activities; or
            </ServiceContent>
            <ServiceContent component="li">
              Not disclosing any trade secrets, trade names, patents,
              copyrights, licenses, franchises, or other similar business or
              commercial rights; or
            </ServiceContent>
            <ServiceContent component="li">
              Not exchanging any information or techniques.
            </ServiceContent>
          </ul>
        </li>

        <li>
          <strong>(8) Keyman Insurance Policy:</strong>
          <ServiceContent component="p">
            Any amount obtained under a policy for keyman insurance, including
            any bonus money granted under such a policy.
          </ServiceContent>
        </li>

        <li>
          <strong>(9) Interest on securities:</strong>
          <ServiceContent component="p">
            Interest on securities is subject to income tax under the heading
            "Income from other sources" unless the assessee's primary business
            is investing in securities.
          </ServiceContent>
        </li>

        <li>
          <strong>
            (10) Recovery against specific capital assets covered by u/s 35AD:
          </strong>
          <ServiceContent component="p">
            Any amount received as a result of the demolition, destruction,
            disposal, or transfer of any capital asset (apart from real estate,
            goodwill, or financial instruments), provided that the entire cost
            of the capital asset has been allowed as a deduction under u/s 35AD.
          </ServiceContent>
        </li>
      </ul>
    </Box>
  );
};

export default IncomeChargeableUnderBusinessAndProfession;
