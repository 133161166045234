import React from 'react';
import {
  Container, Grid,
  styled,
  Typography,
  Box,

} from '@mui/material';
import DivGap from "../../../Components/CommonCss/Home/DivGap"
import BOB from "../../../Assets/Home/Brand/1.png";
import Kotak from "../../../Assets/Home/Brand/2.png";
import AU from "../../../Assets/Home/Brand/4.png";
import IndianBank from "../../../Assets/Home/Brand/3.png"
import Union from "../../../Assets/Home/Brand/5.png"
import HDFC from "../../../Assets/Home/Brand/6.jpg"
import Bajaj from "../../../Assets/Home/Brand/7.png"
import Axix from "../../../Assets/Home/Brand/8.png"
import SBI from "../../../Assets/Home/Brand/9.png";
import ICICI from "../../../Assets/Home/Brand/10.png";
import HDFCmutual from "../../../Assets/Home/Brand/11.png";
import Nippon from "../../../Assets/Home/Brand/12.png"
import Aditya from "../../../Assets/Home/Brand/13.png"
import PPFS from "../../../Assets/Home/Brand/14.png"
import Kotakmutual from "../../../Assets/Home/Brand/15.png"
import Mirae from "../../../Assets/Home/Brand/16.png"
import Heading from '../../../Components/CommonCss/Home/Heading';




const Brand = () => {

  const Background = styled(Box)(({ theme }) => ({
    backgroundColor: 'rgb(250, 250, 250)',
    width: '100%',
    height: '100%',

}));

  
  const Image = styled(Typography)(({ theme }) => ({

    [theme.breakpoints.up("lg")]: {
      width: '95%',
      height: 'auto'
    },
    
    [theme.breakpoints.between("md", "lg")]: {
      width: '5rem',
      height: 'auto'
    },
    [theme.breakpoints.down("md")]: {
      width: '6rem',
      height: 'auto'
    },
    [theme.breakpoints.down("sm")]: {
      width: '4.5rem',
      height: 'auto'
    },

  }));

  const Secondhead = styled(Typography)(({ theme }) => ({
    color: '#FF6600',
    fontWeight: 700,
    textAlign: 'center',
    marginTop: '1rem',
    marginBottom:'1rem',
    fontStyle: 'normal',
    //lineHeight: '2.6rem',
    [theme.breakpoints.between("md", "lg")]: {
        fontSize: '1.2rem',
        marginTop: '10px',
        lineHeight: '2rem',
        },
        [theme.breakpoints.up("lg")]: {
            fontSize: '1.785rem',
            marginTop:'2rem'
            
        },
        [theme.breakpoints.up("xl")]: {
            fontSize: '1.8rem',
            lineHeight: '2.5rem',
            marginTop: '2.5rem'
        },
    }));
    


  return (
    <>
    <Background>
    <div className='Continer'>
      <Container>
      <Heading mainhead = "We Are Trusted By"/>
      <Secondhead>Our Banking Partners:</Secondhead>
        <Box sx={{ border:'0.5px solid lightgray',py:2.5,px:1.5,textAlign:'center',
        
      }}>
          
          <Grid container spacing={2} columns={16}>

            <Grid item xs={4} sm={4} md={2} lg={2}>
              <Image component="img"
                sx={{
                  content: {
                    xs: `url(${BOB})`
                  }
                }}>

              </Image>
            </Grid>
            <Grid item xs={4} sm={4} md={2} lg={2}>

              <Image component="img"
                sx={{
                  content: {
                    xs: `url(${Kotak})`
                  }
                }}>

              </Image>
            </Grid>
            <Grid item xs={4} sm={4} md={2} lg={2}>
              <Image component="img"
                sx={{
                  content: {
                    xs: `url(${AU})`
                  }
                }}>

              </Image>
            </Grid>
            <Grid item xs={4} sm={4} md={2} lg={2}>
              <Image component="img"
                sx={{
                  content: {
                    xs: `url(${IndianBank})`
                  }
                }}>

              </Image>
            </Grid>
            <Grid item xs={4} sm={4} md={2} lg={2}>
              <Image component="img"
                sx={{
                  content: {
                    xs: `url(${Union})`
                  }
                }}>

              </Image>
            </Grid>
            <Grid item xs={4} sm={4} md={2} lg={2}>
              <Image component="img"
                sx={{
                  content: {
                    xs: `url(${HDFC})`
                  }
                }}>
                  </Image>
                  </Grid>
                

<Grid item xs={4} sm={4} md={2} lg={2}>
              <Image component="img"
                sx={{
                  content: {
                    xs: `url(${Bajaj})`
                  }
                }}>
                  </Image>
                  </Grid>
                 
            <Grid item xs={4} sm={4} md={2} lg={2}>
              <Image component="img"
                sx={{
                  content: {
                    xs: `url(${Axix})`
                  }
                }}>
                  </Image>
                  </Grid>
          </Grid>
        </Box>
<br/>
        <Secondhead>Our Mutual Fund Partners:</Secondhead>
        <Box sx={{ border:'0.5px solid lightgray',py:2.5,px:1.5,textAlign:'center',
      }}>
          
          <Grid container spacing={2} columns={16}>

            <Grid item xs={4} sm={4} md={2} lg={2}>
              <Image component="img"
                sx={{
                  content: {
                    xs: `url(${SBI})`
                  }
                }}>

              </Image>
            </Grid>
            <Grid item xs={4} sm={4} md={2} lg={2}>

              <Image component="img"
                sx={{
                  content: {
                    xs: `url(${ICICI})`
                  }
                }}>

              </Image>
            </Grid>
            <Grid item xs={4} sm={4} md={2} lg={2}>
              <Image component="img"
                sx={{
                  content: {
                    xs: `url(${HDFCmutual})`
                  }
                }}>

              </Image>
            </Grid>
            <Grid item xs={4} sm={4} md={2} lg={2}>
              <Image component="img"
                sx={{
                  content: {
                    xs: `url(${Nippon})`
                  }
                }}>

              </Image>
            </Grid>
            <Grid item xs={4} sm={4} md={2} lg={2}>
              <Image component="img"
                sx={{
                  content: {
                    xs: `url(${Aditya})`
                  }
                }}>

              </Image>
            </Grid>
            <Grid item xs={4} sm={4} md={2} lg={2}>
              <Image component="img"
                sx={{
                  content: {
                    xs: `url(${PPFS})`
                  }
                }}>
                  </Image>
                  </Grid>
                

<Grid item xs={4} sm={4} md={2} lg={2}>
              <Image component="img"
                sx={{
                  content: {
                    xs: `url(${Kotakmutual})`
                  }
                }}>
                  </Image>
                  </Grid>
                 
            <Grid item xs={4} sm={4} md={2} lg={2}>
              <Image component="img"
                sx={{
                  content: {
                    xs: `url(${Mirae})`
                  }
                }}>
                  </Image>
                  </Grid>
          </Grid>
        </Box>

      
      </Container>
    </div>
    <DivGap />
    </Background>
    </>
  )
}

export default Brand;

