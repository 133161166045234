import React from 'react';
import {
    Box,
    Container,
    Divider,
    Grid,
    IconButton,
    styled,
    Typography
} from '@mui/material';
import Logo from "../Assets/Logo/LegalFactory_Logo2.png";
import { FaFacebookF } from "react-icons/fa";
import CopyrightIcon from "@mui/icons-material/Copyright";
import TwitterIcon from '@mui/icons-material/Twitter';
import {
    AiOutlineInstagram,
    AiOutlineWhatsApp,
   
} from "react-icons/ai";
import "./Footer.css"

const Footer = () => {

    //Footer background color
    const Background = styled(Box)(({ theme }) => ({
        backgroundColor: theme.palette.secondary.main,
        width: '100%',
        height: '100%',

    }));

    //Footer Year color
    const Bottom = styled(Box)(({ theme }) => ({
        backgroundColor: theme.palette.primary.main,
        width: '100%',
        height: '100%',

    }));

    //Footer Menu Heading

    const Heading = styled(Typography)(({ theme }) => ({
        color:theme.palette.otherColor.main,
        fontWeight:600,
        marginTop:'0.8rem',
         [theme.breakpoints.up("lg")]: {
            fontSize:'1.1rem',
            marginTop: '1.45rem',
         },
         [theme.breakpoints.up("xl")]: {
            fontSize:'1.3',
            marginTop: '1.45rem',
         },
         [theme.breakpoints.down("md")]: {
            fontSize:'0.875rem'
         },
        
    }));

    //Footer menu list
  
    const Link = styled(Typography)(({ theme }) => ({
        color: theme.palette.otherColor.main,
        fontWeight:400,
        ":hover": { color: theme.palette.primary.main, },
        marginBottom: '0.3rem',
        [theme.breakpoints.up("xl")]: {
            fontSize:'1.3rem'
         },
         [theme.breakpoints.up("lg")]: {
          fontSize:'0.97rem'
       },
         [theme.breakpoints.down("md")]: {
            fontSize:'0.78rem'
         },
        
    }));
    
    const Icon = styled(IconButton)(({ theme }) => ({
        color: theme.palette.primary.main,
        border: "solid",
        marginTop: '0.6rem',
        marginRight: '0.6rem',
        width:'40px',
        height:'40px',
         [theme.breakpoints.up("lg")]: {
            height:'45px',
            width:'45px',
            fontSize:'25px'
         },
         [theme.breakpoints.down("sm")]: {
            height:'35px',
            width:'35px',
            fontSize:'20px'
         },
    }));

    //Footer heading line
    const Line = styled(Divider)(({ theme }) => ({
        backgroundColor: theme.palette.primary.main,
        borderBottomWidth: 3.5,
        marginTop: '0.8rem',
        marginBottom: '1.3rem',
        width: '60px'
    }));
  

return (
        <div>
            <Background>
                <br />
                <Container >
                
                    <Grid container
                        direction={{ xs: 'column', sm: 'row' }}
                        justifyContent={{ xs: 'left', sm: 'flex-start' }}
                        alignContent={{ xs: 'left', sm: 'flex-start' }}
                        spacing={3}
                    >
                        <Grid item xs={12} sm={6} md={6} lg={3} xl={3}>
                        <Box sx={{display:'flex'}}>
                                <img src={Logo} alt='Logo'
                                    style={{
                                        height: 'auto',
                                        width: '200px',
                                        marginTop:'1.5rem',
                                        marginBottom:'0.5rem'
                                    }} />

                            </Box>
                           
                           <Heading>Connect With Us On :</Heading>
                            <Box sx={{ display: 'flex'}}>
                                <Icon>
                                    <a
                                        style={{
                                            color: "white",
                                            paddingTop: "7px",
                                            
                                        }}
                                        href="https://www.facebook.com/legalfatory"
                                    >
                                        <FaFacebookF />
                                    </a>
                                </Icon>
                                <Icon>
                                    <a
                                        style={{
                                            color: "white",
                                            paddingTop: "7px",
                                           
                                        }}
                                        href="https://twitter.com/Legalfactoryin"
                                    >
                                        <TwitterIcon />
                                    </a>
                                </Icon>
                                <Icon>
                                    <a
                                        style={{
                                            color: "white",
                                            paddingTop: "7px",
                                            
                                        }}
                                        href="https://www.instagram.com/legalfactory.in"
                                    >
                                        <AiOutlineInstagram />
                                    </a>
                                </Icon>
                                <Icon>
                                    <a
                                        style={{
                                            color: "white",
                                            paddingTop: "7px",
                                            
                                        }}
                                        href="https://api.whatsapp.com/send?phone=9587754754"
                                    >
                                        <AiOutlineWhatsApp />
                                    </a>
                                </Icon>

                                      </Box>
                                      <Heading>Company</Heading>
                                      <Line/>
                                      <ul>
                                      <a href='/about'><li>
                            <Link >About Us</Link>
                            </li></a>
                            <li>
                              <a href='/contact'>
                            <Link>Contact Us</Link></a>
                            </li>
                                
                            </ul>
                                     
                        </Grid>

                        <Grid item xs={12} sm={6} md={6} lg={3} xl={3}>
                            
                            {/*<Link>Singapore Incorporation</Link>
                            <Link>UK Incorporation</Link>
                            <Link>HongKong Incorporation</Link>
                            <Link>Netherlands Incorporation</Link>
                                    <Link>Dubai Incorporation</Link>*/}
                                     <Heading>Business SetUp</Heading>
                            <Line />
                            <ul>
                              <li>
                            <a href="/private"><Link>Private Limited</Link></a>
                            </li>
                            <li>
                            <a href="/startupregistration"><Link>Start Up India</Link></a></li>
                            <li>
                            <a href="/producercompany"><Link>Producer Company</Link></a>
                            </li>
                            <li>
                            <a href="/partnershipfirm"><Link>Partnership Firm</Link></a>
                            </li>
                            <li>
                            <a href="/soleproprietorship"><Link>Sole Proprietorship</Link></a>
                            </li>
                            <li>
                            <a href="/shopact"><Link>Shop Act</Link></a>
                            </li>
                            <li>
                            <a href="/nidhicompany"><Link>Nidhi Company</Link></a>
                            </li>
                            <li>
                            <a href="/limitedliability"><Link>Limited Liability Partnership</Link></a>
                           </li>
                           </ul>
                           
                            <Heading style={{display:'none'}}>Other Special Entities</Heading>
                            <Line style={{display:'none'}}/>
                            <ul style={{display:'none'}}>
                             <li> <Link>US Incorporation</Link></li>
                            </ul>
                           
                                    
                                    
                        </Grid>
                        <Grid item xs={12} sm={6} md={6} lg={3} xl={3}>
                            {/*<Heading>Fundraising</Heading>
                            <Smallhead>Fundraising</Smallhead>
                            <Line />
                            <Link>Fundraising</Link>
                            <Link>Pitch Deck</Link>
                            <Heading>NGO</Heading>
                            <Smallhead>Registration</Smallhead>
                            <Line />
                            <Link>NGO</Link>
                            <Link>Section 8</Link>
                            <Link>Truth Registration</Link>
                            <Link>Society Registration</Link>
                            <Smallhead>NGO Compliance</Smallhead>
                            <Line />
                            <Link>NGO Compliance</Link>
                            <Link>Section 8 Compliance</Link>
                            <Link>CSR-1 Filing</Link>
                            <Link>Darpan Registration</Link>
                                <Link>Sec.80G & Sec.12A</Link>*/}
                                 <Heading style={{display:'none'}}>Trademark</Heading>
                            <Line style={{display:'none'}}/>
                            <ul style={{display:'none'}}>
                              <li>
                            <Link>Trademark Renewal</Link>
                                </li>
                                </ul>
                               
                                <Heading>Tax Planning</Heading>
                                <Line/>
                                <ul>
                                  <li>
                                <a href="/tax/incometax"><Link> Basics Of Income Tax</Link></a>
                                </li>
                               <li>
                                <a href="/tax/Itrfiling"><Link> ITR Filing</Link></a>
                                </li>
                                <li>
                                <a href="/tax/procedure"><Link> Procedure Of ITR Filing</Link></a>
                                </li>
                                <li>
                                <a href="/tax/residential"><Link>Residential Status</Link></a>
                                </li>
                                <li>
                                <a href="/tax/salaries"><Link>Income From Salaries</Link></a>
                                </li>
                                <li>
                                <a href="/tax/houseproperty"><Link>Income From House Property</Link></a>
                                </li>
                                <li>
                                <a href="/tax/business"><Link>Profits Of Gains From Business And Profession</Link></a>
                                </li>
                                <li>
                                <a href="/tax/capitalgain"> <Link> Capital Gain</Link></a>
                                </li>
                                </ul>
                                
                               
                                </Grid>

                        <Grid item xs={12} sm={6} md={6} lg={3} xl={3}>
                          <br/>
                        <ul>
                              
                                <li>
                                <a href="/tax/othersources"><Link>Income From Other Sources</Link></a>
                                </li>
                                <li>
                                <a href="/tax/income"><Link>Excemption From Income U/S 10</Link></a>
                                </li>
                                <li>
                                <a href="/tax/taxableincome"><Link>Deductions From Taxable Income</Link></a>
                                </li>
                                <li>
                                <a href="/tax/tds"><Link>Deduction Of Tax From Income (TDS)</Link></a>
                                </li>
                                <li>
                                <a href="/tax/advancetax"><Link> Advance TAX Liability</Link></a>
                                </li>
                                <li>
                                <a href="/tax/incomeassessed"><Link>Incomes Assessed in IT Act</Link></a>
                                </li>
                                <li>
                                <a href="/tax/appeal"><Link>How To File An Appeal</Link></a>
                            </li>
                            </ul>
                            <Heading>Trademark</Heading>
                            <Line/>
                            <ul>
                              <li>
                                <a href='/trademark'><Link>Trademark Renewal</Link></a>
                              </li>
                            </ul>
                            
                            {/*<Heading>Tax & GST</Heading>
                            <Smallhead>GST</Smallhead>
                            <Line />
                            <Link>GST Registration</Link>
                            <Link>GST Filing</Link>
                            <Link>GST Advisory</Link>
                            <Smallhead>Tax</Smallhead>
                            <Line />
                            <Link>Advance Tax</Link>
                            <Link>Appeals</Link>
                            <Link>Assessment</Link>
                            <Link>Capital Gain</Link>
                            <Link>Deductions</Link>
                            <Link>Income From House Property</Link>
                            <Link>Profit Gains in Business</Link>
                            <Link>Procedure / Rules for return filing</Link>
                            <Link>Tax Deduction At Source (TDS)</Link>
                            <Link>Income Tax From Salary</Link>
                            <Link>Salary Heads</Link>
                            <Link>Other Income Sources</Link>*/}
                           
                            
                        </Grid>
                        


                    </Grid>


                </Container>
                <br />
            </Background>

            <Bottom>
                <Typography
                    sx={{
                        display: "flex",
                        justifyContent: "center",
                        fontWeight: "bold",
                        py: 2,
                        color: "#4A2902",

                    }}
                >
                    <CopyrightIcon />
                    2023 LegalFactory
                </Typography>
            </Bottom>
        </div>
    )
}

export default Footer