import React from "react";
import { Box } from "@mui/material";
import Mainhead from "../../../Components/Services/MainContent/Mainhead";
import Content from "../../../Components/Services/MainContent/Content";
import CustomList from "../../../Components/Income-Tax/CustomList";
import Step from "../../../Components/Services/MainContent/Step";
import { Image } from "../../../Components/Income-Tax/ImageStyle";
import Image8 from "../../../Assets/IncomeTax/salary/image8.png";
import Image5 from "../../../Assets/IncomeTax/salary/image5.png";
import NormalList from "../../../Components/Income-Tax/NormalList";

const DirectAndIndirectTax = () => {
  return (
    <Box
      style={{
        display: "flex",
        flexDirection: "column",
        gap: 4,
        marginBottom: "2rem",
      }}
    >
      <Mainhead heading="Direct and Indirect Tax in India" />
      <Box sx={{ textAlign: "center" }}>
        <Image
          component="img"
          sx={{
            content: {
              xs: `url(${Image8})`,
            },
          }}
        />
      </Box>
      <Step number={`Direct Taxes in India`} />
      <Content paragraph="Direct taxes are taxes that are levied on individuals or organisations and are paid directly to the Government of India. These taxes are imposed on the income, profits, assets, or wealth of individuals or entities. Unlike indirect taxes, which are typically passed on to the consumer, direct taxes are borne by the person or entity on which they are imposed." />
      <CustomList
        points={[
          "Income tax in India: Tax levied on the income earned by individuals, corporations, partnerships, and other entities.",
          "Corporate tax in India: Imposed on the profits earned by companies or corporations.",
          "Capital gains tax in India: Applied to the profits made from the sale of capital assets.",
          "Property tax in India: Levied on the value of real estate or other tangible properties.",
          "Inheritance tax or estate tax in India: Imposed on the transfer of assets or wealth from deceased persons to their heirs or beneficiaries.",
        ]}
      />
      <Content paragraph="Importance of Direct Taxes in India:" />
      <CustomList
        points={[
          "Direct taxes contribute significantly to government revenue and are essential for funding public services, infrastructure, social welfare programs, and other government initiatives.",
          "These taxes can be used to promote equity by redistributing wealth and income, especially through progressive tax structures that impose higher rates on higher incomes.",
        ]}
      />
      <Step number={`Indirect Taxes in India`} />
      <Content paragraph="Indirect taxes are levies imposed on goods and services rather than on individuals or entities directly. These taxes are not directly paid by the consumer or taxpayer to the Government of India but are instead collected by an intermediary, such as a manufacturer, supplier, or retailer, who then passes the tax burden along to the final consumer as part of the product's price." />
      <Content paragraph="Key characteristics of Indirect Taxes in India include:" />
      <CustomList
        points={[
          "Passing on of taxes: The burden of indirect taxes is shifted from the entity collecting the tax to the end consumer, as the tax is embedded in the price of the goods or services.",
          "Applied on consumption: Indirect taxes are typically applied on the purchase of goods and services. The more a person consumes or spends, the more indirect tax they will pay.",
        ]}
      />
      <Content paragraph="Examples of Indirect Taxes in India include:" />
      <CustomList
        points={[
          "Value Added Tax (VAT) or Goods and Services Tax (GST) in India: A consumption tax imposed on the value added to goods and services at each stage of production or distribution.",
          "Excise duties in India: Levied now only on specific goods such as alcohol, tobacco, petrol and diesel, and certain luxury items.",
          "Customs duties in India: Taxes imposed on goods imported into or exported out of a country.",
          "Sales tax in India: A tax imposed on the sale of goods and services at the point of retail purchase.",
        ]}
      />
      <Step number={`Goods and Services Tax (GST) in India`} />
      <Content paragraph="Goods and Services Tax (GST) - It is a unified indirect tax system implemented in India since July 1, 2017. It replaced multiple central and state-level taxes, streamlining the taxation structure. GST operates on the principle of taxing the consumption of goods and services. It encompasses a multi-tier tax structure with four primary tax rates: 5%, 12%, 18%, and 28%, along with certain goods falling under exempted categories or attracting lower rates. It follows a dual model, including Central GST (CGST) levied by the central government and State GST (SGST) imposed by individual state governments. For inter-state transactions, Integrated GST (IGST) is applicable. GST allows for the seamless flow of input tax credit, ensuring that taxes paid at each stage of the supply chain can be offset against subsequent tax liabilities, thus preventing double taxation. Compliance procedures such as registration, return filing, and payment are conducted online through the GST portal, promoting transparency and efficiency. Overall, GST aims to simplify taxation, foster a single national market, and enhance economic growth." />
      <Content paragraph="Key Features of GST in India:" />
      <CustomList
        points={[
          "One Nation, One Tax: GST aims to create a single, unified taxation system, replacing various indirect taxes like excise duty, service tax, VAT, etc., with a single tax.",
          "Destination-Based Tax: GST is a destination-based tax, levied where goods or services are consumed, leading to a fair distribution of tax revenue among states.",
          "Tax Slabs: GST in India has multiple tax slabs: 5%, 12%, 18%, and 28%, with certain essential items taxed at 0% (nil rate) and some goods under special rates.",
          "Input Tax Credit (ITC): Businesses can claim input tax credit, allowing them to set off the tax they have already paid on inputs against the tax they need to pay on outward supplies.",
        ]}
      />
      <Box sx={{ textAlign: "center" }}>
        <Image
          component="img"
          sx={{
            content: {
              xs: `url(${Image5})`,
            },
          }}
        />
      </Box>
      <Step number={`Legislative Powers and Income Tax`} />
      <Content paragraph="Seventh schedule to the Constitution empowers the Central Government to make Legislations. These are Three Lists" />
      <CustomList
        points={[
          "Central List (List I): Subject on which Laws can be made by the Central Government.",
          "State List (List II): Subject on which Laws can be made by the State Government.",
          "Concurrent List (List III): Subject on which Laws can be made by Central and State Government.",
        ]}
      />
      <Content paragraph="Income-tax is made part of the Central List in Entry no. 82, which empowers the Central Government to make laws on:- 'Tax on income except on agricultural income.' By virtue of this entry, the Income-tax Act, 1961 has been formulated." />
      <Content paragraph="Further, where the Act delegates the powers to other authorities to make law on a particular subject, the same is called Delegated/ subordinate legislation. The Income-tax Act prescribes that the CBDT may make rules (Income-tax Rules, 1962 have been framed). Similarly, CBDT issues Circulars and Notifications on various subjects." />
      <Step number={`Income Tax in India`} />
      <Content paragraph="Income tax is a tax levied by the Government of India on the income earned by individuals, businesses, and other entities within its jurisdiction. It's one of the primary sources of revenue for governments worldwide. The tax is calculated based on the income earned by individuals or entities, and the rates typically vary based on the amount of income earned." />
      <CustomList
        points={[
          "Salary and wages: Income earned from employment. In India, this is taxed as income from salary.",
          "Investments: Profits from investments such as interest, dividends, and capital gains.",
          "Self-employment income: Income earned by self-employed individuals or freelancers.",
          "Rental income: Earnings from renting out property.",
          "Business income: Profits earned by businesses, partnerships, or corporations.",
          "Pensions and annuities: Regular income received post-retirement.",
        ]}
      />
      <Content paragraph="Governments use progressive tax systems where tax rates increase as income rises. This means that higher-income earners are generally taxed at higher rates compared to those with lower incomes. However, tax laws and rates can vary significantly between countries and regions." />
      <Content paragraph="To determine the amount of income tax owed, taxpayers typically calculate their total income, deduct any allowable deductions or exemptions, and then apply the relevant tax rates to arrive at the final tax liability. Many jurisdictions also have tax credits or deductions designed to reduce the overall tax burden." />
      <Step number={`Income Exempt Under Income Tax Act 1961 In India`} />
      <CustomList
        points={[
          "Agricultural Income (Section 10(1)): Income derived from agricultural activities as defined by the Income Tax Act, 1961, is considered exempt from income tax.",
          "Gratuity (Section 10(10)): Gratuity received by employees, either government or non-government, is exempt from tax subject to certain conditions.",
          "Leave Travel Allowance (LTA) (Section 10(5)): LTA received by an employee for travel expenses incurred for self and family within India is exempt from tax, subject to certain conditions and limits.",
          "House Rent Allowance (HRA) (Section 10(13A)): HRA received by an employee for payment of rent for accommodation is partially exempt from tax.",
          "Scholarships and Awards (Section 10(16)): Certain scholarships granted to meet the cost of education and awards received in recognition of merit or talent are exempt from tax.",
          "Interest on PPF (Public Provident Fund) and EPF (Employee Provident Fund) (Section 10(11), 10(12)): Interest earned on contributions made to PPF and EPF accounts is exempt from tax.",
          "Income from Long-Term Capital Gains (LTCG) (Section 10(38)): Long-term capital gains from the sale of equity shares or equity-oriented mutual funds held for more than one year are exempt from tax up to a specified limit, provided the Securities Transaction Tax (STT) is paid at the time of sale.",
          "Income of specified entities or institutions (Section 10(23C), 10(46)): The income of certain entities, such as notified religious or charitable trusts or institutions, educational institutions, etc., is exempt under specified conditions and provisions laid down in the Act.",
        ]}
      />
      <Step number={`Taxable Income`} />
      <Content paragraph="Explanation Of Various Heads Under Income Tax in India" />
      <NormalList
        points={[
          "1. Income from Salary:",
          "‣ Scope: This head covers income received by an individual as a result of employment. It includes basic salary, allowances, bonuses, commissions, perquisites, retirement benefits, and any other monetary benefits received by an employee from an employer.",
          "‣ Tax Treatment: The employer deducts taxes at source (TDS) based on the individual's salary income and other benefits. Certain exemptions and deductions are available under this head, such as house rent allowance (HRA), standard deduction, etc.",
          "2.Income from House Property:",
          "‣ Scope: This head includes income generated from owning a house property, whether it is let-out, self-occupied, or deemed to be let-out. It covers rental income from property after deducting municipal taxes and a standard deduction of 30% on the net annual value.",
          "‣ Tax Treatment: Interest paid on a home loan is eligible for deduction under this head, subject to certain conditions. Deductions for repairs and maintenance expenses are also available.",
          "3. Profits and Gains from Business or Profession:",
          "‣ Scope: This head includes income earned from a business, profession, trade, or vocation. It covers income from manufacturing, trading, consultancy, freelancing, and other commercial activities.",
          "‣ Tax Treatment: Various business expenses, depreciation on assets, and specific deductions related to business activities can be claimed to arrive at the net taxable income from this head.",
          "4. Capital Gains:",
          "‣ Scope: Capital gains arise when a capital asset, such as property, stocks, or bonds, is transferred for a profit. It is categorized into short-term capital gains (assets held for less than a certain period) and long-term capital gains (assets held for a longer duration).",
          "‣ Tax Treatment: Different tax rates are applicable for short-term and long-term capital gains. Exemptions and deductions, like investing in specified bonds or utilizing the capital gains for purchasing a residential property, can help reduce tax liabilities.",
          "5. Income from Other Sources:",
          "‣ Scope: This head includes income that does not fall under any other specific heads, such as interest income from savings accounts, fixed deposits, dividends, gifts received (if not exempt), income from winning lotteries, etc.",
          "‣ Tax Treatment: Income under this head is added to the total income and taxed at the applicable slab rates. Certain exemptions and deductions are available, like deductions for interest income up to a specified limit.",
        ]}
      />

      <Content paragraph="Now we will understand the First Head Of Income Tax i.e 'Income From Salary'" />
    </Box>
  );
};

export default DirectAndIndirectTax;
