import React from "react";
import { Box } from "@mui/material";
import Mainhead from "../../../Components/Services/MainContent/Mainhead";
import Content from "../../../Components/Services/MainContent/Content";
import Step from "../../../Components/Services/MainContent/Step";
import NormalList from "../../../Components/Income-Tax/NormalList";

const SectionDeductionsAndTDS = () => {
  const deductionsDetails = [
    "According to the Income Tax Act, income from other sources includes a wide range of income sources that do not fit under any of the other particular income categories, including capital gains, home property, company or profession, or pay.",
    "The following deductions apply to income received from other sources:",
  ];

  const deductionTypes = [
    {
      type: "Saving bank account - Interest Income",
      details:
        "Any interest that builds up in your savings account needs to be reported as income from other sources on your tax return. Savings bank interest is not subtracted by the bank. While savings bank interest and post office deposits are partially tax exempt, interest from both recurring and fixed deposits is taxable.",
    },
    {
      type: "Interest income earned",
      details:
        "Interest income earned by an individual (under 60), Hindu undivided family for the amount of RS. 10,000 a specific financial year can be exempt from tax.",
    },
    {
      type: "Post office saving account",
      details:
        "Details about deductions related to post office saving account.",
    },
    {
      type: "Saving bank account",
      details: "Details about deductions related to saving bank account.",
    },
    {
      type: "Standard Deduction (for certain types of income)",
      details:
        "In some cases, the Income Tax Act allows for a standard deduction from certain incomes falling under 'Income from Other Sources.' For instance, for income earned from family pension, a standard deduction of a certain percentage or a fixed amount might be applicable.",
    },
    {
      type: "Expenses Incurred for Earning Income",
      details:
        "If there are any expenses directly related to earning income from other sources, they might be deductible. However, the conditions for claiming these deductions can be stringent, and they need to be thoroughly documented and justified.",
    },
    {
      type: "Gifts and Donations",
      details:
        "Certain donations to approved charitable institutions qualify for deduction under Section 80G. However, the deduction amount and the organizations eligible for deduction vary, so it's essential to check the specifics.",
    },
    {
      type: "Family Pension Deduction",
      details:
        "In the case of family pension, a standard deduction of a certain percentage or a fixed amount might be allowed while calculating the taxable amount.",
    },
    {
      type: "Tax on Employment Income",
      details:
        "If you have income from other sources in addition to your employment income, you may be eligible for certain deductions or exemptions to reduce your overall tax liability.",
    },
  ];

  const tdsDetails = [
    "TDS (Tax Deducted at Source) is a system introduced by the government where tax is deducted at the source of income itself. This is applicable to various sources of income, including income from other sources.",
    "For income from other sources, the payer is responsible for deducting tax at the prescribed rates before making payment to the payee.",
    "The deducted TDS needs to be deposited with the government on behalf of the payee, and the payee receives credit for the TDS against their overall tax liability.",
    "It is crucial for both the payer and payee to comply with TDS regulations to avoid penalties and ensure smooth tax transactions.",
  ];

  const tdsIncomeFromOtherSources = [
    "Tax Deducted at Source (TDS) is a mechanism through which the government collects tax by requiring the payer (employer, bank, or any other deductor) to deduct tax at the source from the income payable to the payee (employee, depositor, or any other deductee).",
    "TDS is applicable to various types of income, including income from other sources, and is governed by the Income Tax Act in many jurisdictions.",
    "When it comes to income from other sources, the provisions for TDS can vary depending on the nature of the income.",
    "Here are some common scenarios where TDS might be applicable to income from other sources:",
    {
      type: "◘ Interest Income",
      details:
        "If you earn interest income from sources such as fixed deposits, savings accounts, or other interest-bearing instruments, the bank or financial institution may deduct TDS on the interest paid to you if it exceeds a certain threshold.",
    },
    {
      type: "◘ Rental Income",
      details:
        "In some jurisdictions, TDS may be applicable to rental income. If you are receiving rent, the tenant might be required to deduct TDS on the rent paid to you.",
    },
    {
      type: "◘ Commission and Brokerage Income",
      details:
        "TDS may be applicable on commission or brokerage income earned from various sources.",
    },
    {
      type: "◘ Pension Income",
      details:
        "If you receive a pension as income from other sources, the payer (such as the pension-disbursing authority) may deduct TDS before making the payment.",
    },
    {
      type: "◘ TDS on Fixed Deposit",
      details:
        "The interest on your fixed deposit is added to any other income you may have, such as a salary or other sources of income from your profession, and you will be required to pay taxes on that total income at the applicable tax rate. TDS is withheld from interest income as soon as it is earned.",
      example:
        "For five years, the bank will take TDS on the interest that is accumulated annually on a fixed deposit account. Consequently, rather than waiting until the FD matures to pay your taxes, it is best to do it annually.",
    },
  ];

  return (
    <Box
      style={{
        display: "flex",
        flexDirection: "column",
        gap: 4,
        marginBottom: "2rem",
      }}
    >
      <Mainhead heading="Deductions And TDS" />
      <Step number="Overview" />
      {deductionsDetails.map((detail, index) => (
        <Content key={index} paragraph={detail} />
      ))}
      <Step number="Deduction Types" />
      {deductionTypes.map((deduction, index) => (
        <Content
          key={index}
          title={deduction.type}
          paragraph={deduction.details}
        />
      ))}
      <Step number="Tax Deducted at Source (TDS)" />
      {tdsDetails.map((detail, index) => (
        <Content key={index} paragraph={detail} />
      ))}
      <Step number="TDS for Income from Other Sources" />
      {tdsIncomeFromOtherSources.map((tdsInfo, index) => (
        <div key={index}>
          {typeof tdsInfo === "string" ? (
            <Content paragraph={tdsInfo} />
          ) : (
            <NormalList
              points={[tdsInfo.type, tdsInfo.details, tdsInfo.example]}
            />
          )}
        </div>
      ))}
    </Box>
  );
};

export default SectionDeductionsAndTDS;
