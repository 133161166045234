import React from 'react';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Divider } from '@mui/material';
import Content from '../../../Components/Services/MainContent/Content';
import Mainhead from '../../../Components/Services/MainContent/Mainhead';


function Faq() {
const [expanded, setExpanded] = React.useState(false);

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };
  return (
    <div>
        <Mainhead heading="FAQ'S"/>
      <br/>
      <Divider sx={{border: '1px solid #FF990080'}}/>
      <Accordion expanded={expanded === 'panel1'} onChange={handleChange('panel1')}  elevation={0} sx={{ boxShadow: 'unset !important' }} disableGutters>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1bh-content"
          id="panel1bh-header"
        >
          <Typography  sx={{color:'#281900',fontWeight:450,fontSize:'20px'}}>My employer reimburses me all my expenses on grocery and children’s education. Would these be considered as my income?</Typography>
        </AccordionSummary>
        <AccordionDetails>
         <Content paragraph ="  Yes, these are in the nature of perquisites ."/>
            
         
        </AccordionDetails>
      </Accordion>
      <Divider sx={{border: '1px solid #FF990080'}}/>
      <Accordion expanded={expanded === 'panel2'} onChange={handleChange('panel2')}  elevation={0} sx={{ boxShadow: 'unset !important' }} disableGutters>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel2bh-content"
          id="panel2bh-header"
        >
          <Typography  sx={{color:'#281900',fontWeight:450,fontSize:'20px'}}> During the year I had worked with three different employers and none of them deducted any tax from salary paid to me. If all these amounts are clubbed together, my income will exceed the basic exemption limit. Do I have to pay taxes on my own?
</Typography>
        </AccordionSummary>
        <AccordionDetails>
         <Content paragraph ="Yes, you will have to pay self-assessment tax and file the return of income.​
"/>
            
         
        </AccordionDetails>
      </Accordion>
      <Divider sx={{border: '1px solid #FF990080'}}/>
      <Accordion expanded={expanded === 'panel3'} onChange={handleChange('panel3')}  elevation={0} sx={{ boxShadow: 'unset !important' }} disableGutters>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel3bh-content"
          id="panel3bh-header"
        >
          <Typography  sx={{color:'#281900',fontWeight:450,fontSize:'20px'}}>Even if no taxes have been deducted from salary, is there any need for my employer to issue Form-16 to me?
</Typography>
        </AccordionSummary>
        <AccordionDetails>
         <Content paragraph ="Form-16 is a certificate of TDS. In your case it will not apply. However, your employer can issue a salary statement.​
"/>
            
         
        </AccordionDetails>
      </Accordion>
      <Divider sx={{border: '1px solid #FF990080'}}/>
      <Accordion expanded={expanded === 'panel4'} onChange={handleChange('panel4')}  elevation={0} sx={{ boxShadow: 'unset !important' }} disableGutters>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel4bh-content"
          id="panel4bh-header"
        >
          <Typography  sx={{color:'#281900',fontWeight:450,fontSize:'20px'}}>Is pension income taxed as salary income?
</Typography>
        </AccordionSummary>
        <AccordionDetails>
         <Content paragraph ="  ​​Yes, except exempted income or income which do not form part of total income?​​"/>
            
         
        </AccordionDetails>
      </Accordion>
      <Divider sx={{border: '1px solid #FF990080'}}/>
      <Accordion expanded={expanded === 'panel5'} onChange={handleChange('panel5')}  elevation={0} sx={{ boxShadow: 'unset !important' }} disableGutters>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel5bh-content"
          id="panel5bh-header"
        >
          <Typography  sx={{color:'#281900',fontWeight:450,fontSize:'20px'}}>  Is Family pension taxed as salary income?
</Typography>
        </AccordionSummary>
        <AccordionDetails>
         <Content paragraph =" ​​No, it is taxable as income from other sources.
"/>
            
         
        </AccordionDetails>
      </Accordion>
 
      <Divider sx={{border: '1px solid #FF990080'}}/>
      <Accordion expanded={expanded === 'panel6'} onChange={handleChange('panel6')}  elevation={0} sx={{ boxShadow: 'unset !important' }} disableGutters>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel6bh-content"
          id="panel6bh-header"
        >
          <Typography  sx={{color:'#281900',fontWeight:450,fontSize:'20px'}}>Salary received by the partner from the firm is taxable under which head?
</Typography>
        </AccordionSummary>
        <AccordionDetails>
         <Content paragraph ="It is taxable under the head “Profits and gains of business and profession"/>
            
         
        </AccordionDetails>
      </Accordion>

      <Divider sx={{border: '1px solid #FF990080'}}/>
      <Accordion expanded={expanded === 'panel7'} onChange={handleChange('panel7')}  elevation={0} sx={{ boxShadow: 'unset !important' }} disableGutters>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel7bh-content"
          id="panel7bh-header"
        >
          <Typography  sx={{color:'#281900',fontWeight:450,fontSize:'20px'}}> If I receive my pension through a bank, who will issue Form-16 or pension statement to me- the bank or my former employer?
</Typography>
        </AccordionSummary>
        <AccordionDetails>
         <Content paragraph ="   The bank issues form 16 in case of retired government employees receiving pension income.
"/>

        </AccordionDetails>
      </Accordion>
      <Divider sx={{border: '1px solid #FF990080'}}/>
      <Accordion expanded={expanded === 'panel8'} onChange={handleChange('panel8')}  elevation={0} sx={{ boxShadow: 'unset !important' }} disableGutters>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel8bh-content"
          id="panel8bh-header"
        >
          <Typography  sx={{color:'#281900',fontWeight:450,fontSize:'20px'}}>  I have received arrears of salary for many years. Shall it be taxable?

</Typography>
        </AccordionSummary>
        <AccordionDetails>
         <Content paragraph =" Yes. However, the benefit of spread over income to the years to which it relates to can be    availed for lower   incidence of tax. This is called as relief ​ u/s 89 ​of the Income-tax Act.​​

"/>

        </AccordionDetails>
      </Accordion>

      <Divider sx={{border: '1px solid #FF990080'}}/>
      <Accordion expanded={expanded === 'panel9'} onChange={handleChange('panel9')}  elevation={0} sx={{ boxShadow: 'unset !important' }} disableGutters>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel9bh-content"
          id="panel9bh-header"
        >
          <Typography  sx={{color:'#281900',fontWeight:450,fontSize:'20px'}}>  
          Under which head salary received by a Member of parliament shall be taxable?

</Typography>
        </AccordionSummary>
        <AccordionDetails>
         <Content paragraph =" Salary received by MP, MLA shall be taxable under the head “Income from Other Sources” 

"/>

        </AccordionDetails>
      </Accordion>

      <Divider sx={{border: '1px solid #FF990080'}}/>
      <Accordion expanded={expanded === 'panel10'} onChange={handleChange('panel10')}  elevation={0} sx={{ boxShadow: 'unset !important' }} disableGutters>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel10bh-content"
          id="panel10bh-header"
        >
          <Typography  sx={{color:'#281900',fontWeight:450,fontSize:'20px'}}>  
   
          Can my employer consider relief u/s 89 for the purposes of calculating the TDS from salary?

</Typography>
        </AccordionSummary>
        <AccordionDetails>
         <Content paragraph ="  ​​​​​Yes, if you are a Government employee or an employee of a PSU or company or co-operative society or local authority or university or institution or association or body. In such a case you need to furnish Form No. 10E to your employer. ​​ 


"/>
        </AccordionDetails>
      </Accordion>

      <Divider sx={{border: '1px solid #FF990080'}}/>
      <Accordion expanded={expanded === 'panel11'} onChange={handleChange('panel11')}  elevation={0} sx={{ boxShadow: 'unset !important' }} disableGutters>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel11bh-content"
          id="panel11bh-header"
        >
          <Typography  sx={{color:'#281900',fontWeight:450,fontSize:'20px'}}>  
   
          My employer has paid professional tax on my behalf, can I claim deduction for the same?

</Typography>
        </AccordionSummary>
        <AccordionDetails>
         <Content paragraph ="Yes, If professional tax is paid by employer on behalf of employee, the amount shall be included in gross salary as a perquisites and then deduction can be claimed.
"/>
        </AccordionDetails>
      </Accordion>

      <Divider sx={{border: '1px solid #FF990080'}}/>
      <Accordion expanded={expanded === 'panel12'} onChange={handleChange('panel12')}  elevation={0} sx={{ boxShadow: 'unset !important' }} disableGutters>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel12bh-content"
          id="panel12bh-header"
        >
          <Typography  sx={{color:'#281900',fontWeight:450,fontSize:'20px'}}>  
          My income from let out house property is negative. Can I ask my employer to consider this loss against my salary income while computing the TDS on my salary?

</Typography>
        </AccordionSummary>
        <AccordionDetails>
         <Content paragraph ="Yes but only to the extent of Rs. 2 Lakh, however, losses other than losses under the head ‘Income from house property’ cannot be set-off while determining the TDS from salary.​​

"/>
        </AccordionDetails>
      </Accordion>
     
      <Divider sx={{border: '1px solid #FF990080'}}/>
      <Accordion expanded={expanded === 'panel13'} onChange={handleChange('panel13')}  elevation={0} sx={{ boxShadow: 'unset !important' }} disableGutters>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel13bh-content"
          id="panel13bh-header"
        >
          <Typography  sx={{color:'#281900',fontWeight:450,fontSize:'20px'}}>  
          Is leave encashment taxable as salary?

</Typography>
        </AccordionSummary>
        <AccordionDetails>
         <Content paragraph ="​​​It is taxable if received while i n service. Leave encashment received at the time of retirement is exempt in the hands of the Government employee. In case of non-Government employee leave encashment will be exempt subject to the limit prescribed in this behalf under the Income-tax Law.​

"/>
        </AccordionDetails>
      </Accordion>

      <Divider sx={{border: '1px solid #FF990080'}}/>
      <Accordion expanded={expanded === 'panel14'} onChange={handleChange('panel14')}  elevation={0} sx={{ boxShadow: 'unset !important' }} disableGutters>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel14bh-content"
          id="panel14bh-header"
        >
          <Typography  sx={{color:'#281900',fontWeight:450,fontSize:'20px'}}>  
          What is the taxability of ex-gratia received from an employer?

</Typography>
        </AccordionSummary>
        <AccordionDetails>
         <Content paragraph ="​​If a person or his heir receives ex-gratia from Central govt/state govt/ local authority/Public Sector Undertaking due to injury to the person/death while on duty such ex-gratia payment will not be taxable.

"/>
        </AccordionDetails>
      </Accordion>

      <Divider sx={{border: '1px solid #FF990080'}}/>
      <Accordion expanded={expanded === 'panel15'} onChange={handleChange('panel15')}  elevation={0} sx={{ boxShadow: 'unset !important' }} disableGutters>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel15bh-content"
          id="panel15bh-header"
        >
          <Typography  sx={{color:'#281900',fontWeight:450,fontSize:'20px'}}>  
          Where is House Rent allowance (HRA) to be reflected while filing income-tax return (ITR)?
</Typography>
        </AccordionSummary>
        <AccordionDetails>
         <Content paragraph ="​​​The amount of HRA is required to be disclosed in the ITR under the column allowances to the extent exempt under section 10. Section 10(3A) is the relevant section under which the amount of exempt HRA to be shown.
"/>
        </AccordionDetails>
      </Accordion>

      <Divider sx={{border: '1px solid #FF990080'}}/>
      <Accordion expanded={expanded === 'panel16'} onChange={handleChange('panel16')}  elevation={0} sx={{ boxShadow: 'unset !important' }} disableGutters>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel16bh-content"
          id="panel16bh-header"
        >
          <Typography  sx={{color:'#281900',fontWeight:450,fontSize:'20px'}}>  
          Is standard deduction applicable to all the salaried person whether he is an employee   of Central or State Government?

</Typography>
        </AccordionSummary>
        <AccordionDetails>
         <Content paragraph ="​​​Standard deduction is available to all classes of employees irrespective of the nature of employer. Standard Deduction is also available to pensioners( only for Pension Chargeable under the head 'Income under the head Salaries' and not for Pension chargeable under 'Income from Other Sources'.) Amount of Standard Deduction is Rs. 50,000 or amount of salary/pension, whichever is lower."/>
        </AccordionDetails>
      </Accordion>

      <Divider sx={{border: '1px solid #FF990080'}}/>
      <Accordion expanded={expanded === 'panel17'} onChange={handleChange('panel17')}  elevation={0} sx={{ boxShadow: 'unset !important' }} disableGutters>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel17bh-content"
          id="panel17bh-header"
        >
          <Typography  sx={{color:'#281900',fontWeight:450,fontSize:'20px'}}>  
          Whether transport allowance can  be claimed as an exemption by an employee?

</Typography>
        </AccordionSummary>
        <AccordionDetails>
         <Content paragraph ="​Exemption of transport allowance of Rs. 3200 p.m. granted to an employee who is blind or deaf and dumb or orthopedically handicapped is available.
​"/>
        </AccordionDetails>
      </Accordion>

      
      <Divider sx={{border: '1px solid #FF990080'}}/>
      <Accordion expanded={expanded === 'panel18'} onChange={handleChange('panel18')}  elevation={0} sx={{ boxShadow: 'unset !important' }} disableGutters>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel18bh-content"
          id="panel18bh-header"
        >
          <Typography  sx={{color:'#281900',fontWeight:450,fontSize:'20px'}}>  
          What is the taxability of entertainment allowance?

</Typography>
        </AccordionSummary>
        <AccordionDetails>
         <Content paragraph ="​It is fully taxable for all employees; however, deduction shall be allowed in case of government employees to the extent of least of following"/>
        <Content paragraph="a) 	20% of Basic Salary"/>
        <Content paragraph="b) 	Actual amount received"/>
        <Content paragraph="c) 	Rs. 5000"/>

         <Content paragraph="Deduction is allowed only if the employee is a Central Government employee or State Government employee i.e. in case of employees of Local Authority, Statutory Corporation, Public Sector Units (PSU) etc. deduction is not allowed."/>

        </AccordionDetails>
      </Accordion>

      <Divider sx={{border: '1px solid #FF990080'}}/>
      <Accordion expanded={expanded === 'panel19'} onChange={handleChange('panel19')}  elevation={0} sx={{ boxShadow: 'unset !important' }} disableGutters>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel19bh-content"
          id="panel19bh-header"
        >
          <Typography  sx={{color:'#281900',fontWeight:450,fontSize:'20px'}}>  
          Mr. X having Gross Salary of Rs. 7,00,000 during the previous year 2020-21. Compute the standard deduction allowable to him?
</Typography>
        </AccordionSummary>
        <AccordionDetails>
         <Content paragraph ="Standard deduction is allowable to the extent of/\"/>
         <Content paragraph=" a) Rs. 50000 " />
         <Content paragraph="b) Amount of salary, whichever is lower "/>
         <Content paragraph="In this case standard deduction of Rs. 50,000 is allowable to Mr.X."/>

        </AccordionDetails>
      </Accordion>

      <Divider sx={{border: '1px solid #FF990080'}}/>
      <Accordion expanded={expanded === 'panel20'} onChange={handleChange('panel20')}  elevation={0} sx={{ boxShadow: 'unset !important' }} disableGutters>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel20bh-content"
          id="panel20bh-header"
        >
          <Typography  sx={{color:'#281900',fontWeight:450,fontSize:'20px'}}>  
          Ravi is a retired central government employee and has received leave salary of Rs. 200000 what will be tax liability of Ravi?

</Typography>
        </AccordionSummary>
        <AccordionDetails>
         <Content paragraph ="Any amount received as leave salary at the time of retirement is exempt from tax in case of       employees of central government / State Government. Leave salary received by Ravi will be exempt.
​"/>
        </AccordionDetails>
      </Accordion>
      <Divider sx={{border: '1px solid #FF990080'}}/>
      
    </div>
  )
}

export default Faq;