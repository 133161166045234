import React, { useRef, useState } from "react";
import {
  Box,
  Divider,
  MenuItem,
  MenuList,
  Popover,
  Accordion,
  AccordionSummary,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { ItemHead, SubLinks, MenuLinks } from "./HeaderStyles";
import { Link } from "react-router-dom";

const CustomAccordion = ({ title, menuItems }) => {
  const anchorRef = useRef(null);
  const [expanded, setExpanded] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);

  const handleChange = (event, isExpanded) => {
    setExpanded(isExpanded);
  };

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);

  return (
    <>
      <Accordion
        expanded={expanded}
        onChange={handleChange}
        elevation={0}
        disableGutters={true}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel-content"
          id="panel-header"
          ref={anchorRef}
          onClick={handleClick}
        >
          <MenuLinks
            aria-controls={anchorEl ? "custom-menu" : undefined}
            aria-haspopup="true"
            aria-expanded={Boolean(anchorEl) ? "true" : undefined}
            onClick={handleClick}
            className="MenuLinks"
          >
            {title}
          </MenuLinks>
        </AccordionSummary>
      </Accordion>
      <Popover
        open={open}
        anchorEl={anchorRef.current}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
      >
        <MenuList>
          {menuItems.map((menuItem, index) => (
            <Box key={index}>
              <ItemHead variant="h6">{menuItem.category}</ItemHead>
              <Divider
                variant="middle"
                sx={{
                  background: "#F19E1E",
                  borderBottomWidth: 2,
                }}
              />
              {menuItem.items.map((item, subIndex) => (
                <MenuItem key={subIndex} onClick={handleClose}>
                  <Link to={item.link}>
                    <SubLinks primary={item.label} />
                  </Link>
                </MenuItem>
              ))}
            </Box>
          ))}
        </MenuList>
      </Popover>
    </>
  );
};

export default CustomAccordion;
