import React from 'react';
import Sidecomponent from '../../../Components/Tax-Planning/Sidecomponent';
import Topdiv from "../../../Components/Tax-Planning/Topdiv"

import Mainhead from '../../../Components/Services/MainContent/Mainhead';
import Content from '../../../Components/Services/MainContent/Content';
import List from '../../../Components/Services/MainContent/List';
import Step from '../../../Components/Services/MainContent/Step';

import TableCell, { tableCellClasses } from '@mui/material/TableCell';

import Return1 from "../../../Assets/TaxPlanning/Procedure/Return1.jpg";
import Return2 from "../../../Assets/TaxPlanning/Procedure/Return2.jpg";
import Return3 from "../../../Assets/TaxPlanning/Procedure/Return3.jpg";
import Return4 from "../../../Assets/TaxPlanning/Procedure/Return4.png";
import Return5 from "../../../Assets/TaxPlanning/Procedure/Return5.png";
import Return6 from "../../../Assets/TaxPlanning/Procedure/Return6.png";
import Return7 from "../../../Assets/TaxPlanning/Procedure/Return7.png";
import Return8 from "../../../Assets/TaxPlanning/Procedure/Return8.png";
import Return9 from "../../../Assets/TaxPlanning/Procedure/Return9.png";
import Return10 from "../../../Assets/TaxPlanning/Procedure/Return10.png";
import Return11 from "../../../Assets/TaxPlanning/Procedure/Return11.png";
import Return12 from "../../../Assets/TaxPlanning/Procedure/Return12.png";
import TaxPortal1 from "../../../Assets/TaxPlanning/Procedure/TaxPortal1.jpg";
import TaxPortal2 from "../../../Assets/TaxPlanning/Procedure/TaxPortal2.jpg";
import TaxPortal3 from "../../../Assets/TaxPlanning/Procedure/TaxPortal3.jpg";
import TaxPortal4 from "../../../Assets/TaxPlanning/Procedure/TaxPortal4.jpg";
import TaxPortal5 from "../../../Assets/TaxPlanning/Procedure/TaxPortal5.jpg";
import TaxPortal6 from "../../../Assets/TaxPlanning/Procedure/TaxPortal6.jpg";
import Faq from "./Faq.jsx";
import GetInTouch from '../../../Components/Services/GetInTouch';

import {
  Grid, Box, Container, styled, Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Link
} from '@mui/material';

function createData(
  Col1,
  Col2,
  
) {
  return {
    Col1,
    Col2,
    
  };
}

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: '#773106',
    color: theme.palette.common.white,
    textAlign: 'left',
    fontFamily: 'Sora',
    fontWeight: 'bold',



  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 16,
    textAlign: 'left',
    textAlign: 'left',
    fontFamily: 'Sora',
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: theme.palette.action.hover,
    fontFamily: 'Sora'
  },
  // hide last border
  '&:last-child td, &:last-child th': {
    border: 0,
  },
}));

const Bottomspace = styled(Box)(({ theme }) => ({
  marginBottom:'2.1rem'
}));

const rows1 = [
  createData('Company/Firm', 'Yes'),
  createData('Individual/HUF/AOP/BOI or Artificial Juridical Person', 'If total income without giving effect to the exemption contained in section 54/54B/54D/54EC/54G/54GA/54GB in respect of capital gain or deduction under chapter VI-A exceeds the basic exemption limit.'),
  createData('Any person (other than a company or firm)', 'If during the previous year: a) Deposited an amount (or aggregate of the amounts) exceeding Rs. 1 crore in one (or more) current account(s) in a bank; or b) Incurred expenditure of an amount (or aggregate of the amounts) exceeding Rs. 2 lakh for himself (or any other person) for travel to a foreign country; or c) Incurred expenditure of an amount (or aggregate of the amounts) exceeding Rs. 1 lakh towards consumption of electricity; or d) Fulfils such other conditions as may be prescribed'),
  createData('Resident not being ordinarily resident in India', 'If at any time during the previous year: a) Holds, as a beneficial owner* or otherwise, any asset (including any financial interest in any entity) located outside India or has a signing in any account located outside India; or b) Is a beneficiary* of any asset (including any financial interest in any entity) located outside India.'),
];

const rows2 = [
  createData('Company', 'October 31st of the Assessment Year'),
  createData('Any person (other than company) who is required to furnish tax audit report', 'October 31st of the Assessment Year'),
  createData('Partner of a firm whose accounts are required to be audited', 'October 31st of the Assessment Year'),
  createData('Any other assessee', 'July 31st of the Assessment Year'),
];


function Procedure() {

  const Image  = styled(Box)(({ theme }) => ({
    marginTop:'30px',
    width:'95%',
    marginBottom:'3rem',
    justifyContent:'center',
    [theme.breakpoints.down("md")]: {
      marginTop:'20px',
      width:'100%',
    }
  }));
  const Hyperlink  = styled(Link)(({ theme }) => ({ 
    [theme.breakpoints.down("sm")]: {
      fontSize:'0.775rem',
      color:'blue',

    }
  }));
  

  return (
    <div>
    <Topdiv heading="Are you looking to file your Income Tax Returns ?"/>
    <div className='Container'>
      <Container>
        <Grid container>
          <Grid item  lg={4} xl={3}>
            <Box sx={{ display: { xs: 'none', lg: 'block' }, marginTop: '4rem', position: 'sticky', top: 90, marginBottom: '3rem' }}>
              <Sidecomponent />
            </Box>
          </Grid>
          <Grid item xs={11} lg={8} sx={{ marginTop: '4rem' }} xl={9}>
            
            <Mainhead heading="Indian Income tax Return filing process in compliance with Procedure and Rules" />
            <Content paragraph ="1. Income tax return must be filed mandatorily by a specified due date, which is generally 31st July of the assessment year for individuals and 31st October for companies." />
            <Content paragraph ="2. To file a return, the following documents are required: PAN card, bank account details, TDS certificates (Form 16/16A), proof of investments and expenses, and proof of business income and expenses." />

            <Content paragraph ="3. Before filing the return, it is important to check the authenticity of the documents and ensure they are complete and accurate." />

            <Content paragraph ="4. Selecting the appropriate form is also crucial, as different forms are applicable for different types of income and taxpayers." />

            <Content paragraph ="5. A checklist should be prepared at the time of filing the return, which should include all the required documents and information." />

            <Content paragraph ="6. Compliance post filing the return includes following up on the status of the return, rectifying any errors or omissions, and paying any outstanding taxes." />

            <Content paragraph ="7. Compliance post processing the return includes responding to any notices or queries from the tax department, and taking corrective action as required." />

            <Content paragraph ="8. In case of delayed return or return filed after the due date, the taxpayer can file a belated return within a specified period. In such cases, a fee for late filing may be applicable." />

            <Content paragraph ="9. Condonation of delay in filing return can also be applied for in certain cases with valid reasons." />
            <Bottomspace/>
            <Mainhead heading="FILING OF INCOME TAX RETURN section 139(1)" />
            <Content paragraph="Who is required to file an Income Tax Return?" />
            <br/>
            <TableContainer component={Paper}>
                <Table  aria-label="customized table"  size='small'>
        <TableHead>
          <TableRow>
            <StyledTableCell>Type of Assessee </StyledTableCell>
            <StyledTableCell>Return Filing Compulsory </StyledTableCell>
           
          </TableRow>
        </TableHead>
        <TableBody>
          {rows1.map((row) => (
            <StyledTableRow
              key={row.name}
            
            >
              <StyledTableCell component="th" scope="row"
              >
                {row.Col1}
              </StyledTableCell>
              <StyledTableCell>{row.Col2}</StyledTableCell>
      
              </StyledTableRow>
          ))}
        </TableBody>
      </Table>
      </TableContainer>

            <br />
            <Mainhead heading="ITR filing for special entities" />
            <ul className="list">
              <li className="bullet">
                <span>
            <List points="Charitable and religious organizations must file an ITR if their income surpasses the tax-free threshold after exemptions from sections 11 and 12 are applied" />
            </span>
            </li>
            <li className="bullet">
                <span>
            <List points="Political parties must file an ITR if their income surpasses the tax-free threshold after exemptions from section 13A are applied." />
            </span>
            </li>
            <li className="bullet">
                <span>
            <List points="Hospitals, schools, colleges and other institutions specified under section 10, Investor Protection Funds, Core Settlement and Guarantee Funds, and other entities specified under section 139(4C) must file an ITR if their income surpasses the tax-free threshold after exemptions from section 10 are applied." />
             </span>
             </li>
             </ul>
              
            <Mainhead heading="Due Dates for filing the return u/s 139(1)" />
            <br/>
            <TableContainer component={Paper}>
                <Table  aria-label="customized table"  size='small'>
        <TableHead>
          <TableRow>
            <StyledTableCell>Assessee </StyledTableCell>
            <StyledTableCell>Due Date for Filing Return of Income </StyledTableCell>
           
          </TableRow>
        </TableHead>
        <TableBody>
          {rows2.map((row) => (
            <StyledTableRow
              key={row.name}
            
            >
              <StyledTableCell component="th" scope="row"
              >
                {row.Col1}
              </StyledTableCell>
              <StyledTableCell>{row.Col2}</StyledTableCell>
      
              </StyledTableRow>
          ))}
        </TableBody>
      </Table>
      </TableContainer>
            <Bottomspace/>
            <Mainhead heading="Documents required to fill ITR" />
            <Content paragraph="It is important to have the following documents ready before starting the e-filing process for ITR:" />
            <ul className='list'>
              <li className='bullet'>
                <span>
            <List points="Bank and post office savings account passbook, PPF account passbook" />
            </span>
            </li>
            <li className='bullet'>
                <span>
            <List points="Salary slips" />
            </span>
            </li>
            <li className='bullet'>
                <span>
            <List points="Aadhar Card and PAN card" />
            </span>
            </li>
            <li className='bullet'>
                <span>
            <List points="Form-16 (TDS certificate issued by employer that provides information on salary and TDS deductions)" />
            </span>
            </li>
            <li className='bullet'>
                <span>
            <List points="Interest certificates from banks and post office" />
            </span>
            </li>
            <li className='bullet'>
                <span>
            <List points="Form-16A (if TDS is deducted on payments other than salaries, such as interest from fixed deposits)" />
            </span>
            </li>
            <li className='bullet'>
                <span>
            <List points="Form-16B (if property is sold, showing TDS deductions on the amount paid)" />
            </span>
            </li>
            <li className='bullet'>
                <span>
            <List points="Form-16C (if rent is received, showing TDS deductions on rent" />
            </span>
            </li>
            <li className='bullet'>
                <span>
            <List points="Form 26AS (consolidated annual tax statement that includes information on taxes deposited against PAN)" />
            </span>
            </li>
            <li className='bullet'>
                <span>
            <List points="Tax saving investment proofs" />
            </span>
            </li>
            <li className='bullet'>
                <span>
            <List points="Proofs for claiming deductions under sections 80D to 80U (health insurance, education loan)" />
            </span>
            </li>
            <li className='bullet'>
                <span>
            <List points="Home loan statement from bank" />
            </span>
            </li>
            <li className='bullet'>
                <span>
            <List points="Capital Gains Report." />
            </span>
            </li>
            </ul>
            <Bottomspace/>
            <Mainhead heading="Check the authenticity of document"/>
            <Content paragraph="Follow the steps given below to e-file your Income Tax return using the e-filing portal"/>
            <Step number="Step 1 :Visit the official website for e-filing of returns"/>
            <Content paragraph="Click on the 'Login' tab."/>
            <a href="https://www.incometax.gov.in/iec/foportal" style={{color:"blue"}}>https://www.incometax.gov.in/iec/foportal</a>
            <Image component="img"
                      sx={{
                        content: {
                          xs: `url(${Return1})`
                        }
                      }}/>

            
            <Step number="Step 2: Enter your username i.e. PAN and after clicking continue enter your password."/>
            <Image component="img"
                      sx={{
                        content: {
                          xs: `url(${Return2})`
                        }
                      }}/>
            <Step number="Step 3: After being logged in click on the ‘ E- file return’ and click on the ‘ File income tax return’ option"/>
            <Image component="img"
                      sx={{
                        content: {
                          xs: `url(${Return3})`
                        }
                      }}/>
            <Step number="Step 4: Select the Assessment year for which you wish to file your income tax returns and click on ‘Continue’."/>
            <Image component="img"
                      sx={{
                        content: {
                          xs: `url(${Return4})`
                        }
                      }}/>
            <Step number="Step 5: Now enter the mode of filing of return i.e whether you wish to file your returns online or offline.You need to choose the online mode which is also the recommended mode."/>
            <Image component="img"
                      sx={{
                        content: {
                          xs: `url(${Return5})`
                        }
                      }}/>    
            <Step number="Step 6: Start new filing by clicking on the ‘Start New Filing button.’ If you already have a saved draft, you could use the ‘Resume Filing button’."/>
            <Image component="img"
                      sx={{
                        content: {
                          xs: `url(${Return6})`
                        }
                      }}/>    
            <Step number="Step 7: Choose status of the person i.e whether the person whose return is being filed is an individual orHindu Undivided Family (HUF), or others. Choose the option ‘individual’."/>
            <Image component="img"
                      sx={{
                        content: {
                          xs: `url(${Return7})`
                        }
                      }}/>
            <Step number="Step 8: Select the applicable ITR form for e.g.ITR 1 then click Proceed with ITR 1 button. To know more about ITR forms click here."/>
            <Image component="img"
                      sx={{
                        content: {
                          xs: `url(${Return8})`
                        }
                      }}/> 
            <Step number="Step 9: Start the validation process by clicking the ‘Let's Get Started’ button"/>
            <Image component="img"
                      sx={{
                        content: {
                          xs: `url(${Return9})`
                        }
                      }}/> 
            <Step number="Step 10: Select the reason for filing income tax return"/>
            <Image component="img"
                      sx={{
                        content: {
                          xs: `url(${Return10})`
                        }
                      }}/> 
            <Step number="Step 11: Confirm Return Summary There is 5 sections under Return Summary, you need to confirm one by one individually. The 5 sections are,"/> 
            <ul className='list'>
              <li className='bullet'>
                <span>
            <List points="Verify personal information such as Aadhaar, PAN, contact, and bank details (for return purpose)"/>
          </span>
          </li>
          <li className='bullet'>
                <span>
            <List points="Check gross total income, including income from business, profession, salary, house property, and income from other sources such as bank interest.
            "/>
            </span>
            </li>
            <li className='bullet'>
                <span>
            <List points="Review total deductions, including tax-saving deductions or payments towards life insurance, medical premium, pension funds, provident fund, and ELSS.
            "/>
            </span>
            </li>
            <li className='bullet'>
                <span>
            <List points="Check tax paid, including details of taxes deducted and paid by your employer.
            "/> 
            </span>
            </li>
            <li className='bullet'>
                <span>
            <List points="Verify total tax liability, the amount you owe to the income tax department based on your income, deductions, and tax paid already.
            "/> 
            </span>
            </li>
            </ul>
            <Image component="img"
                      sx={{
                        content: {
                          xs: `url(${Return11})`
                        }
                      }}/> 
            <Content paragraph="After checking and confirming each section click on the ‘proceed’ button."/>   

            <Step number="Step 12: Confirm on Return Summary"/> 
            <ul className='list'>
              <li className='bullet'>
                <span>
            <List points ="Proceed to the refund verification page"/>
            </span>
            </li>
            <li className='bullet'>
                <span>
            <List points ="Check the refund amount which is calculated as: Tax Paid – Total Tax Liability"/> 
            </span>
            </li>
            <li className='bullet'>
                <span>
            <List points ="If Tax Paid is more than the Total Tax Liability, the extra amount will be refunded to you"/>
            </span>
            </li>
            <li className='bullet'>
                <span>
            <List points ="If Tax Paid is less than the Total Tax Liability, you will need to pay the balance tax amount."/>
            </span>
            </li>
            </ul>   
            <Image component="img"
                      sx={{
                        content: {
                          xs: `url(${Return12})`
                        }
                      }}/> 
            <Content paragraph="The steps provided in the document are:"/>
            <ul className="list">
            <li className='bullet'>
              <span>
            <List points ="After reviewing and confirming the return summary"/>
            </span>
            </li>
            <li className='bullet'>
                <span>
            <List points ="Provide your declaration to confirm the summary"/> 
            </span>
            </li>
            <li className='bullet'>
                <span>
            <List points ="Verify and submit the Income Tax Return"/> 
                </span>
                </li>
                </ul>
                <Bottomspace/>
            <Mainhead heading="STEPS to Check Income Tax e-Filing status?" />  
            <Content paragraph="The document provided steps on how to check the Income Tax e-filing status through the e-filing portal."/>
            <Content paragraph="One way is by using the acknowledgement number:"/> 
            <ul className='list'>
              <li className='bullet'>
                <span>
            <List points ="Go to the official Income Tax e-filing portal at"/> <a href="https://www.incometax.gov.in/iec/foporta" style={{color:"blue"}}>https://www.incometax.gov.in/iec/foporta</a>
            </span>
            </li>
            <li className='bullet'>
                <span>
            <List points ='Click on the "Income Tax Return Status" link'/>
            </span>
            </li>
            <li className='bullet'>
                <span>
            <List points ="Enter your PAN, acknowledgement number, and captcha code and click on ‘Submit’."/>
            </span>
            </li>
            <li className='bullet'>
                <span>
            <List points ="The status will be displayed on the screen."/>
            </span>
            </li>
           </ul>
            <Content paragraph="The other way is by using login credentials:"/>
            <ul className='list'>
            <li className='bullet'>
                <span>
            <List points ="Log in to the e-filing website"/>
            </span>
            </li>
            <li className='bullet'>
                <span>
            <List points ='On the dashboard, you will see the option "View Returns/Forms"'/>
            </span>
            </li>
            <li className='bullet'>
                <span>
            <List points ='Select "Income Tax Returns" and the assessment year from the dropdown menu and click on ‘Submit’'/>
            </span>
            </li>
            <li className='bullet'>
                <span>
            <List points ="The status will be displayed on the screen."/>
             </span>
             </li>
             </ul>
           <Bottomspace/>
            <Mainhead heading="What is Form 16?" />
            <Content paragraph="Form 16 is a certificate of tax deduction at source (TDS) issued by an employer to an employee. It is a detailed summary of the salary paid to the employee and the taxes deducted by the employer on behalf of the employee. It acts as proof that the employer has deposited TDS to the income tax department accurately on behalf of the employee. It is mandatory for the employer to generate and distribute Form 16, for a financial year, on or before 15th June of the succeeding financial year. The employee can use Form 16 as a document while filing their Income Tax Return (ITR). Form 16 is divided into two parts: Part A and Part B. Part A contains the general information of the employee and the employer, and Part B contains the details of the salary paid and the taxes deducted by the employer." />
           <Bottomspace/>
            <Mainhead heading="What are the components of Form 16?" />
            <Content paragraph="There are two components of Form 16 — Part A and Part B." />
            <Content paragraph="Part A of Form 16 includes the following details:" />
            <ul className='list'>
              <li className='bullet'>
                <span>
            <List points="The name and address of the employer and their PAN (Permanent Account Number) and TAN (Tax Deduction and Collection Account Number)" />
            </span>
            </li>
            <li className='bullet'>
                <span>
            <List points="The name, address and PAN of the employee" />
            </span>
            </li>
            <li className='bullet'>
                <span>
            <List points="The Assessment Year (AY) for which the form is being issued" />
            </span>
            </li>
            <li className='bullet'>
                <span>
            <List points="The quarter-wise salary paid and TDS (Tax Deducted at Source) that has been deposited by the employer to the income tax department on behalf of the employee" />
            </span></li>
            </ul>
            
            <Content paragraph="Part B of Form 16 includes the following details:" />
            <ul className='list'>
            <li className='bullet'>
                <span>
            <List points="The employee's taxable salary which is the total salary earned by the employee during the financial year, minus any exemptions or deductions that are allowed as per the income tax laws" />
            </span>
            </li>
            <li className='bullet'>
                <span>
            <List points="Details of any allowances that are taxable and not included in the salary, such as conveyance or travel allowances" />
            </span>
            </li>
            <li className='bullet'>
                <span>
            <List points="Details of any pensions or annuities received by the employee" />
            </span>
            </li>
            <li className='bullet'>
                <span>
            <List points="The amount of tax that has been paid or is due to be paid by the employee, and any outstanding tax liabilities" />
            </span>
            </li>
            </ul>
            <Mainhead heading="Follow the steps below to check the authenticity of form 16." />
            <Step number="Step:1 Visit the official website of the income tax portal" />
            <Image component="img"
                      sx={{
                        content: {
                          xs: `url(${TaxPortal1})`
                        }
                      }}/> 
            <Step number="Step: 2 Log in using username (your PAN) & password" />
            <Image component="img"
                      sx={{
                        content: {
                          xs: `url(${TaxPortal2})`
                        }
                      }}/>           
            <Step number="Step: 3 Choose the ‘e-file’ tab and click on ‘View 26AS’ under the ‘Income Tax Returns’ option" />
            <Step number="Step: 4 This will navigate you to the TRACES website" />
            <Image component="img"
                      sx={{
                        content: {
                          xs: `url(${TaxPortal3})`
                        }
                      }}/>   

            <Step number="Step: 5 After being redirected to the TRACES website, click on the ‘View Tax Credit’ tab" />
            <Image component="img"
                      sx={{
                        content: {
                          xs: `url(${TaxPortal4})`
                        }
                      }}/> 
            <Step number="Step 6: Then, click on the ‘Verify TDS Certificate’ option." />
            <Image component="img"
                      sx={{
                        content: {
                          xs: `url(${TaxPortal5})`
                        }
                      }}/>  
            <Step number="Step 7: Enter TAN of employer and enter TDS certificate number" />
            <Step number="Step 8: Select Assessment Year and input TDS amount as per certificate" />  
            <Step number='Step 9: Choose source of income (salary or non-salary) from the drop-down menu and Click on "Validate" button to verify Form 16 authenticity.' />  
            <Image component="img"
                      sx={{
                        content: {
                          xs: `url(${TaxPortal6})`
                        }
                      }}/>    
            <Step number='Step 10: Check Form 16 or TDS certificate authenticity by clicking on "Validate". If the details match, the certificate is considered valid. If the website shows the certificate is fake or invalid, reach out to the employer promptly to have it corrected.' />
            <br/>
            <Mainhead heading="Check the authenticity of the digital signature certificate (DSC) on Form 16" />
            <ol>
              <li><Content paragraph="Open Form 16 in Adobe Reader." /></li>
              <li><Content paragraph="Right-click on the digital signature." /></li>
              <li><Content paragraph='Select "Validate Signature".' /></li>
              <li><Content paragraph="Check the validity of the signature displayed." /></li>
              <li><Content paragraph="Ensure Form 16 authenticity is verified before filing ITR." /></li>
            </ol>
            
            <br />
            <Mainhead heading="Penalty for Late filing" />
            <ol>
              <li><Content paragraph="Penalty for late ITR filing imposed by CBDT." /></li>
              <li><Content paragraph="Penalty amount is Rs. 5000 under section 234F" /></li>
              <li><Content paragraph='Penalty capped at Rs. 1000 for total income less than Rs. 5 lakh.' /></li>
              
            </ol>           
            <br />

            <Faq/>
            <br/>
          </Grid>


        </Grid>
      </Container>

    </div>
    </div>
  )
}

export default Procedure