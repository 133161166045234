import React from 'react';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Divider, Box, styled } from '@mui/material';
import Content from '../../../Components/Services/MainContent/Content';
import Mainhead from '../../../Components/Services/MainContent/Mainhead';

import F1 from "../../../Assets/TaxPlanning/Procedure/F1.png";
import F2 from "../../../Assets/TaxPlanning/Procedure/F2.jpg";
import F3 from "../../../Assets/TaxPlanning/Procedure/F3.jpg";
import F4 from "../../../Assets/TaxPlanning/Procedure/F4.jpg";
import F5 from "../../../Assets/TaxPlanning/Procedure/F5.jpg";




function Faq() {
  const Image  = styled(Box)(({ theme }) => ({
    marginTop:'30px',
    width:'95%',
    marginBottom:'3rem',
    justifyContent:'center',
    [theme.breakpoints.down("md")]: {
      marginTop:'20px',
      width:'100%',
    }
  }));
const [expanded, setExpanded] = React.useState(false);

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };
  return (
    <div>
        <Mainhead heading="FAQ'S"/>
      <br/>
      <Divider sx={{border: '1px solid #FF990080'}}/>
      <Accordion expanded={expanded === 'panel1'} onChange={handleChange('panel1')}  elevation={0} sx={{ boxShadow: 'unset !important' }} disableGutters>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1bh-content"
          id="panel1bh-header"
        >
          <Typography  sx={{color:'#281900',fontWeight:450,fontSize:'20px'}}>What are the consequences of Filing ITR with Only Aadhaar</Typography>
        </AccordionSummary>
        <AccordionDetails>
         <Content paragraph ="In case you do not have a Permanent Account Number, you can file your income tax return using Aadhaar. Under the new income tax rules, taxpayers who file their returns using their Aadhaar card will be allotted PAN after their demographic data has been obtained from the Unique Identification Authority of India (UIDAI)."/>
            
         
        </AccordionDetails>
      </Accordion>
      <Divider sx={{border: '1px solid #FF990080'}}/>
      <Accordion expanded={expanded === 'panel2'} onChange={handleChange('panel2')}  elevation={0} sx={{ boxShadow: 'unset !important' }} disableGutters>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel2bh-content"
          id="panel2bh-header"
        >
          <Typography  sx={{color:'#281900',fontWeight:450,fontSize:'20px'}}>What happens if your Aadhaar and PAN are not linked?</Typography>
        </AccordionSummary>
        <AccordionDetails>
         <Content paragraph ="Failure to link your PAN with Aadhaar will prevent you from filing your income tax returns after the specified deadline.
        The Government of India has introduced the new rule in order to enhance tax compliance while easing the tax payment process for taxpayers. As of now, it is compulsory for taxpayers to quote their PAN when filing their returns. Going forward, however, it will be compulsory to link your Aadhaar and PAN to file your returns."/>
            
         
        </AccordionDetails>
      </Accordion>
      <Divider sx={{border: '1px solid #FF990080'}}/>
      <Accordion expanded={expanded === 'panel3'} onChange={handleChange('panel3')}  elevation={0} sx={{ boxShadow: 'unset !important' }} disableGutters>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel3bh-content"
          id="panel3bh-header"
        >
          <Typography  sx={{color:'#281900',fontWeight:450,fontSize:'20px'}}>How can I file my ITR electronically? </Typography>
        </AccordionSummary>
        <AccordionDetails>
         <Content paragraph ="You can log on to"/>
         <a href=" https://eportal.incometax.gov.in/iec/foservices/#/login " style={{color:"blue"}}> https://eportal.incometax.gov.in/iec/foservices/#/login </a>
         <Content paragraph ="if you want to file ITR electronically. It is an independent portal launched by the income tax department"/>
            
         
        </AccordionDetails>
      </Accordion>
      <Divider sx={{border: '1px solid #FF990080'}}/>
      <Accordion expanded={expanded === 'panel4'} onChange={handleChange('panel4')}  elevation={0} sx={{ boxShadow: 'unset !important' }} disableGutters>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel4bh-content"
          id="panel4bh-header"
        >
          <Typography  sx={{color:'#281900',fontWeight:450,fontSize:'20px'}}>How will the excess tax paid by me be refunded?</Typography>
        </AccordionSummary>
        <AccordionDetails>
         <Content paragraph ="It will be refunded to the bank account or a cheque will be sent once the refund gets processed. You can check the refund status through the portal."/>
            
         
        </AccordionDetails>
      </Accordion>
      <Divider sx={{border: '1px solid #FF990080'}}/>
      <Accordion expanded={expanded === 'panel5'} onChange={handleChange('panel5')}  elevation={0} sx={{ boxShadow: 'unset !important' }} disableGutters>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel5bh-content"
          id="panel5bh-header"
        >
          <Typography  sx={{color:'#281900',fontWeight:450,fontSize:'20px'}}>Whom can I contact for rectification and other income tax processing relaterd queries ?</Typography>
        </AccordionSummary>
        <AccordionDetails>
         <Content paragraph ="In such a case, you can contact the centralised processing centre between 8 am and 8 pm on a weekday.,"/>
            
         
        </AccordionDetails>
      </Accordion>
      <Divider sx={{border: '1px solid #FF990080'}}/>
      <Accordion expanded={expanded === 'panel6'} onChange={handleChange('panel6')}  elevation={0} sx={{ boxShadow: 'unset !important' }} disableGutters>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel6bh-content"
          id="panel6bh-header"
        >
          <Typography  sx={{color:'#281900',fontWeight:450,fontSize:'20px'}}>In case I have a query about Form16 or something related o TDS statement, whicj helpdesk should I contact? </Typography>
        </AccordionSummary>
        <AccordionDetails>
         <Content paragraph ="The TDS reconciliation Analysis and Correction Enabling System also known as TRACES can be contacted in such a case."/>
            
         
        </AccordionDetails>
      </Accordion>
      <Divider sx={{border: '1px solid #FF990080'}}/>

      <Accordion expanded={expanded === 'panel7'} onChange={handleChange('panel7')}  elevation={0} sx={{ boxShadow: 'unset !important' }} disableGutters>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel7bh-content"
          id="panel7bh-header"
        >
          <Typography  sx={{color:'#281900',fontWeight:450,fontSize:'20px'}}>What is centralised processing centre? </Typography>
        </AccordionSummary>
        <AccordionDetails>
         <Content paragraph ="To process E-returns at one place electronically, the Central Government issued a scheme called ‘Centralised Processing of Returns Scheme"/>
         <Content paragraph ="The CPC is headed by the Commissioner of Income tax and is responsible for following activities."/>
         <ol>
          <li>
          <Content paragraph ="Receipt of electronic returns"/>
          </li>
          <li>
          <Content paragraph ="Scanning of documents received like ITR-V acknowledgments"/>
          </li>
          <li>
          <Content paragraph ="Data entry related activities related to receipt of ITRs and processing of them"/>
          </li>
          <li>
          <Content paragraph ="Processing of ITRs"/>
          </li>
          <li>
          <Content paragraph ="Prioritising the kind of returns which need to be processed on priority"/>
          </li>
          <li>
          <Content paragraph ="Issue of refunds through refund banker scheme"/>
          </li>
          <li>
          <Content paragraph ="Validation of return filing softwares"/>
          </li>
          <li>
          <Content paragraph ="Storage and retrieval of records"/>
          </li>
          <li>
          <Content paragraph ="Receipt of rectification applications and carrying out rectifications"/>
          </li>
          <li>
          <Content paragraph ="Issue of defective return notices and carrying out relevant corrections"/>
          </li>
          <li>
          <Content paragraph ="Adjustments of refunds in cases of demands"/>
          </li>
          <li>
          <Content paragraph ="Making rules and scheme for all the above tasks"/>
          </li>
         </ol>
            
         
        </AccordionDetails>
      </Accordion>
      <Divider sx={{border: '1px solid #FF990080'}}/>
      <Accordion expanded={expanded === 'panel8'} onChange={handleChange('panel8')}  elevation={0} sx={{ boxShadow: 'unset !important' }} disableGutters>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel8bh-content"
          id="panel8bh-header"
        >
          <Typography  sx={{color:'#281900',fontWeight:450,fontSize:'20px'}}>Is it compulsory to find return of income </Typography>
        </AccordionSummary>
        <AccordionDetails>
         <Content paragraph ="Yes, for company and Partnership Firm (including LLP) , return filing is compulsory. B. for other assessee: - if Gross total Income > basic exemption(RS. 250000) , then also return filing is compulsory."/>
            
         
        </AccordionDetails>
      </Accordion>
      <Divider sx={{border: '1px solid #FF990080'}}/>
      <Accordion expanded={expanded === 'panel9'} onChange={handleChange('panel9')}  elevation={0} sx={{ boxShadow: 'unset !important' }} disableGutters>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel9bh-content"
          id="panel9bh-header"
        >
          <Typography  sx={{color:'#281900',fontWeight:450,fontSize:'20px'}}>I am beneficial owner of assets located outside India. Am I compulsorily required to file a return of Income </Typography>
        </AccordionSummary>
        <AccordionDetails>
         <Content paragraph ="Yes, both the beneficial owner and beneficiary of the assets are compulsorily required to file return of income."/>
            
         
        </AccordionDetails>
      </Accordion>
      <Divider sx={{border: '1px solid #FF990080'}}/>
      <Accordion expanded={expanded === 'panel10'} onChange={handleChange('panel10')}  elevation={0} sx={{ boxShadow: 'unset !important' }} disableGutters>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel10bh-content"
          id="panel10bh-header"
        >
          <Typography  sx={{color:'#281900',fontWeight:450,fontSize:'20px'}}>I have deposited Rs. 15000000 in current a/c. Am I required to file return of income if my income is less than the basic exemption limit ?</Typography>
        </AccordionSummary>
        <AccordionDetails>
         <Content paragraph ="Yes, if any person has deposited more than Rs. 1 crore in aggregate in one or more current A/C maintain with bank or cooperative bank , has to compulsorily file the return of income, even if his income is less than basic exemption."/>
         <Image component="img"
                      sx={{
                        content: {
                          xs: `url(${F1})`
                        }
                      }}/>
            <Image component="img"
            sx={{
              content: {
                xs: `url(${F2})`
              }
            }}/>
            <Image component="img"
            sx={{
              content: {
                xs: `url(${F3})`
              }
            }}/>
            <Image component="img"
            sx={{
              content: {
                xs: `url(${F4})`
              }
            }}/>
            <Image component="img"
            sx={{
              content: {
                xs: `url(${F5})`
              }
            }}/>
            
         
        </AccordionDetails>
      </Accordion>
      <Divider sx={{border: '1px solid #FF990080'}}/>

      
    </div>
  )
}

export default Faq;