import React,{ useRef, useEffect, useState } from 'react';
import {
    Box,
    Typography,
    Container,
    styled,
    Divider,
    Grid,
    Button,
    useTheme,
  useMediaQuery
} from '@mui/material';
import Topdiv from "../../../Components/Tax-Planning/Topdiv";
import "./Common.css"
import Overview from './Overview/Overview';
import Checklist from './Checklist/Checklist';
import Step from './Steps/Step';
import Cost from './Cost/Cost';
import Forget from './ForgetToFile/Forget';
import Faq from './Faq/Faq';


  const getDimensions = ele => {
  const  {height}  = ele.getBoundingClientRect();
  const offsetTop = ele.offsetTop;
  const offsetBottom = offsetTop + height;

  return {
    height,
    offsetTop,
    offsetBottom,
  };
};

const scrollTo = ele => {
  ele.scrollIntoView({
    behavior: "smooth",
    block: "start",
  });
};

const Trademark = () => {

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const isTablet = useMediaQuery(theme.breakpoints.down("md"));
  const [visibleSection, setVisibleSection] = useState();
  const headerRef = useRef(null);
    const overviewRef = useRef(null);
    const checklistRef = useRef(null);
    const stepsRef = useRef(null);
    const costRef = useRef(null);
    const forgetRef = useRef(null);
    const faqRef = useRef(null);
  
    const sectionRefs = [
      { section: "Overview", ref: overviewRef },
      { section: "Checklist", ref: checklistRef },
      { section: "Steps", ref: stepsRef },
      { section: "Cost", ref: costRef },
      { section: "Forget", ref: forgetRef },
      { section: "Faq", ref: faqRef },
    ];
  
    useEffect(() => {
      const handleScroll = () => {
        const { height: headerHeight } = getDimensions(headerRef.current);
        const scrollPosition = window.scrollY + headerHeight;
  
        const selected = sectionRefs.find(({ section, ref }) => {
          const ele = ref.current;
          if (ele) {
            const { offsetBottom, offsetTop } = getDimensions(ele);
            return scrollPosition > offsetTop && scrollPosition < offsetBottom;
          }
        });
  
        if (selected && selected.section !== visibleSection) {
          setVisibleSection(selected.section);
        }
         
         else if (!selected && visibleSection) {
          setVisibleSection(undefined);
        }
      };
  
      handleScroll();
      window.addEventListener("scroll", handleScroll);
      return () => {
        window.removeEventListener("scroll", handleScroll);
      };
    });
    
    
    const Topspacer  = styled(Box)(({ theme }) => ({
      height:'6rem',
      [theme.breakpoints.down("sm")]: {
        height:'1.5rem',
      }
          
  }));

  const Content  = styled(Box)(({ theme }) => ({
    
        
}));
  
  
  const Sticky  = styled(Box)(({ theme }) => ({
      position:'sticky',
      top:90,
      left:0,
     }));
  
  const PLheader  = styled(Box)(({ theme }) => ({
      display:'flex',
      flexDirection:'column',
      justifyContent:'flex-start',
  
  }));
  
  const Bottom_spacer  = styled(Box)(({ theme }) => ({
      height:'8rem',
  }));
  

  const Section  = styled(Box)(({ theme }) => ({
      height:'auto'
    }));

  return (
    <div>
         <Topdiv heading={["Get A Trademark",<br/>, "Done For Your",<br/>, "Brand"]}/>
         <div className='Container'>
        <Container>
          <div className="App">
      <Topspacer />
      
     <Content>
        <Grid container spacing={3}>
            <Grid item lg={3} xl={3} md={4}
            sx={
              isMobile || isTablet ? { display: 'none' } : { }
            }>
              
        <Sticky>
          <PLheader ref={headerRef}>
            <Box
              className={`header_link ${visibleSection === "Overview" ? "selected" : ""}`}
              onClick={() => {
                scrollTo(overviewRef.current);
              }}
            >
              Overview
            </Box>
            <Box
              type="button"
              className={`header_link ${visibleSection === "Checklist" ? "selected" : ""}`}
              onClick={() => {
                scrollTo(checklistRef.current);
              }}
            >
              Checklist
            </Box>
            <Box
              type="button"
              className={`header_link ${visibleSection === "Steps" ? "selected" : ""}`}
              onClick={() => {
                scrollTo(stepsRef.current);
              }}
            >
             Steps 
            </Box>

            <Box
              type="button"
              className={`header_link ${visibleSection === "Document_private" ? "selected" : ""}`}
              onClick={() => {
                scrollTo(costRef.current);
              }}
            >
              Cost
            </Box>
            <Box
              type="button"
              className={`header_link ${visibleSection === "Forget" ? "selected" : ""}`}
              onClick={() => {
                scrollTo(forgetRef.current);
              }}
            >
              Forget To File
            </Box>
            <Box
              type="button"
              className={`header_link ${visibleSection === "Faq" ? "selected" : ""}`}
              onClick={() => {
                scrollTo(faqRef.current);
              }}
            >
              FAQ'S
            </Box>
            </PLheader>
        </Sticky>
        
        </Grid>
        <Grid item md={8} lg={8} xl={8}
         sx={
          isMobile || isTablet ? { display: 'none' } : { }
        }>
        <Section id="Overview" ref={overviewRef}>
        <Overview/>
        </Section>
        <Topspacer/>
        <Section id="Checklist" ref={checklistRef}>
          <Checklist/>
        </Section>
        <Topspacer/>
        <Section id="Steps" ref={stepsRef}>
       <Step/>
        </Section>
        <Topspacer/>
        <Section id="Cost" ref={costRef}>
         <Cost/>
        </Section>
        <Topspacer/>
        <Section id="Forget" ref={forgetRef}>
       <Forget/>
        </Section>
       <Topspacer/>
        <Section id="Faq" ref={faqRef}>
        <Faq/>
        </Section>
        </Grid>

       
        
        
        </Grid>
       </Content>

       <Box sx={
          isMobile || isTablet ? {  } : {display:'none' }
        }>
          <Grid container>
          <Grid item xs={11}>
         <br/><br/>
          <Overview/>
          <br/>
          <Checklist/>
          <br/>
          <Step/>
          <br/>
          <Cost/>
          <br/>
          <Forget/>
          <br/>
          <Faq/>
          </Grid>
          </Grid>
        </Box>
      
      


      <Bottom_spacer/>
      
      
    </div>
           
           </Container>

    </div>
    </div>
  )
}

export default Trademark