import React from "react";
import { Box } from "@mui/material";
import Mainhead from "../../../Components/Services/MainContent/Mainhead";
import Content from "../../../Components/Services/MainContent/Content";
import CustomList from "../../../Components/Income-Tax/CustomList";
import Step from "../../../Components/Services/MainContent/Step";
import ReusableTable from "../../../Components/Income-Tax/ReusableTable";

const ExpensesNotAllowed = () => {
  // Data for the disallowed expense tables
  const section40a1Data = [
    {
      Circumstance: "TDS is deductible but not deducted",
      "Disallowance in Current Year": "100%",
    },
    {
      Circumstance: "TDS is deductible and deducted but not deposited on time",
      "Disallowance in Current Year": "100%",
    },
    {
      Circumstance: "TDS is deductible, deducted, and deposited on time",
      "Disallowance in Current Year": "No Disallowance",
    },
  ];

  const section40aiaData = [
    {
      Circumstance: "TDS is deductible but not deducted",
      "Disallowance in Current Year": "30%",
    },
    {
      Circumstance: "TDS is deductible and deducted but not deposited on time",
      "Disallowance in Current Year": "30%",
    },
    {
      Circumstance: "TDS is deductible, deducted, and deposited on time",
      "Disallowance in Current Year": "No Disallowance",
    },
  ];
  const section40a1ibData = [
    {
      Circumstance:
        "Equalization Levy not deducted or deducted but not remitted on time",
      "Disallowance in Current Year": "100%",
    },
  ];

  const section40a2Data = [
    {
      Circumstance: "Payment of Income Tax, Wealth Tax, and Other Taxes",
      "Disallowance in Current Year": "100%",
    },
  ];

  const section40a2NoteData = [
    {
      Note: "Income tax includes interest paid on late payment of Income Tax, interest paid on late filing of Income Tax and TDS Returns, and tax paid in any other country for which relief is available under Sections 90, 90A, and 91.",
    },
  ];

  const section40a1iibData = [
    {
      Payer: "State Government Undertaking",
      Payee: "State Government",
      "Nature of payment":
        "Royalty, license fee, service fee, privilege fee, service charge, or any other cost or charge imposed by the state government",
      "Percent of disallowance": "100%",
    },
  ];

  const section40a3Data = [
    {
      Period: "For the months of April to February",
      "Timelines for payment of TDS":
        "On or before 7th of the subsequent month.",
    },
    {
      Period: "March",
      "Timelines for payment of TDS": "On or before 30th April",
    },
  ];

  const section40a4Data = [
    {
      Note: "Any contribution made to a provident or other fund created for the benefit of employees is prohibited unless the employer has made effective arrangements to ensure that tax is deducted at source from any payments made from the fund that are liable to tax under the heading 'Salaries.'",
    },
  ];

  const section40a5Data = [
    {
      Note: "Any tax on non-cash benefits paid by the employer on the employee's behalf and exempt under Section 10(10CC) in the employee's possession would be denied.",
    },
  ];

  const section40bData = [
    {
      Note: "Maximum compensation and interest payable to a partner under the Income Tax Act of 1961 for capital paid or owed. Any sum over the designated cap will not be accepted.",
    },
  ];

  const section40aExcessivePaymentData = [
    {
      Circumstance: "Excessive payment to relatives",
      "Disallowance by Assessing Officer": "If it is excessive or unreasonable",
    },
  ];

  const section40aPaymentInCashData = [
    {
      "Disallowance of Cash Payments":
        "Payments to individuals in excess of Rs. 10,000 per day, other than account payee cheques, bank drafts, or the use of an electronic clearing system, will be prohibited. The entire amount will be denied. ",

      Exception:
        "The maximum of disallowance shall not exceed Rs. 35,000 where payment is made for plying, hiring, or leasing goods carriages.",
    },
  ];

  const section40a7Data = [
    {
      Note: "Disallowance of making any provision for unapproved gratuity funds (to cover future liabilities).",
    },
  ];
  return (
    <Box
      style={{
        display: "flex",
        flexDirection: "column",
        gap: 4,
        marginBottom: "2rem",
      }}
    >
      <Mainhead heading="EXPENSES NOT ALLOWABLE UNDER BUSINESS AND PROFESSION" />
      <Step number="What do you mean by Expenditure?" />
      <Content paragraph="Expenditure refers to a cost related to an accounting period's operations or income received within the period, the benefits of which do not extend beyond the period." />

      <Step number="Various categories of expenditure under Income Tax Act, 1961" />
      <CustomList
        points={[
          "Allowable Expenditure: Includes expenses permitted or deducted for calculating taxable income under the heading 'Profits and Gains from Business or Profession.'",
          "Disallowed Expense: Expenses falling under 'Profits and Gains from Business or Profession' but not allowed or subtracted while calculating taxable income.",
        ]}
      />

      <Step number="Sec 40 of The Income Tax Act, 1961- Amounts not deductible" />
      <Content paragraph="Section 40(a)(i) - Payments/credits provided to a non-resident who is neither a company nor a foreign company:" />
      <ReusableTable data={section40a1Data} />

      <Content paragraph="Section 40(a)(ia) - Payments/Credits made to Resident:" />
      <ReusableTable data={section40aiaData} />
      <Content paragraph="Section 40(a)(ib): Levy on Equalization" />
      <ReusableTable data={section40a1ibData} />

      <Content paragraph="Section 40(a)(ii)/(iia)- Disallowance of Income Tax, Wealth Tax, and Other Taxes:" />
      <ReusableTable data={section40a2Data} />
      <ReusableTable data={section40a2NoteData} />

      <Content paragraph="Section 40(a)(iib) - Payment to the State Government:" />
      <ReusableTable data={section40a1iibData} />

      <Content paragraph="Sec 40(a)(iii)- Payment of Salary:" />
      <ReusableTable data={section40a3Data} />

      <Content paragraph="Sec 40(a)(iv)- Payment to Provident Fund and other Funds:" />
      <ReusableTable data={section40a4Data} />

      <Content paragraph="Sec 40(a)(v)-Tax paid by Employer on Non-Monetary perquisites:" />
      <ReusableTable data={section40a5Data} />

      <Content paragraph="Sec 40(b)- Disallowance in case of partnership firms/LLP:" />
      <ReusableTable data={section40bData} />

      <Content paragraph="Requirements for claiming a deduction for remuneration paid/payable to a partner:" />
      <CustomList
        points={[
          "It must be paid to the working partner.",
          "It must be permitted in the partnership deed.",
          "It must relate to the period following the date of the partnership deed, i.e., it cannot be retroactive.",
          "It must be within the limits:",
          "On the first Rs.3,00,000 of Book Profits or in case of loss - Rs.1,50,000 OR 90% of the Book Profits, whichever is higher",
          "On the balance of Book Profit - 60% of the Book Profits",
        ]}
      />
      <Content paragraph="Sec. 40A of The Income Tax Act, 1961: Expenses not deductible in certain circumstances:" />
      <ReusableTable data={section40aExcessivePaymentData} />

      <Content paragraph="Payment in Cash:" />
      <ReusableTable data={section40aPaymentInCashData} />

      <Content paragraph="Sec 40A(7)- Disallowance in the case of an unapproved Gratuity Fund provision:" />
      <ReusableTable data={section40a7Data} />
    </Box>
  );
};

export default ExpensesNotAllowed;
