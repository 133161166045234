import React from "react";
import { Box } from "@mui/material";
import Mainhead from "../../../Components/Services/MainContent/Mainhead";
import Content from "../../../Components/Services/MainContent/Content";
import CustomList from "../../../Components/Income-Tax/CustomList";
import Step from "../../../Components/Services/MainContent/Step";
import ReusableTable from "../../../Components/Income-Tax/ReusableTable";
import { Image } from "../../../Components/Income-Tax/ImageStyle";
import Image8 from "../../../Assets/IncomeTax/salary/image6.png";

const C2CvsTakeHomeSalary = () => {
  return (
    <Box
      style={{
        display: "flex",
        flexDirection: "column",
        gap: 4,
        marginBottom: "2rem",
      }}
    >
      <Mainhead heading="C2C vs. Take Home Salary in India" />

      <Step number={`What is CTC Meaning in Salary in India?`} />
      <Content paragraph="CTC or Cost to Company refers to the total cost incurred by the company in the form of salary paid to the employee. It is to be noted that the total cost here includes the total salary package including various allowances, perquisites, perks (gratuity, pension, etc.)." />
      <CustomList
        points={[
          "Basic Salary: It’s the base amount exclusive of bonus, additional payments, etc. When it comes to a hike in salary, employees get a % raise in the base salary alone.",
          "Allowances: This includes various allowances that the organization provides to employees in various forms for various purposes such as Medical, fuel, conveyance, etc.",
          "House Rent Allowance: Paid to the employee if the employee stays in a rented accommodation.",
          "Dearness Allowance: Paid to the employee in lieu of the impact of inflation in the economy by the employer.",
          "Bonus and Other Incentives: CTC also includes bonus payments and other incentive payments based on work performance and efficiency.",
          "EPF: The amount paid by the employer to the employee for retirement benefits.",
          "Gratuity: The amount deducted from the salary of an employee and awarded to him as a token of gratitude.",
        ]}
      />

      <Content paragraph="Let’s take an example for better understanding:" />
      <ReusableTable
        data={[
          {
            Particulars: "Basic Salary",
            "Amount in Rs.": "",
            "Amount in Rs": "1,000,000",
          },
          {
            Particulars: "Add: Allowances",
            "Amount in Rs.": "",
            "Amount in Rs": "",
          },
          {
            Particulars: "HRA (House Rent Allowance)",
            "Amount in Rs.": "200,000",
            "Amount in Rs": "",
          },
          {
            Particulars: "Dearness Allowance",
            "Amount in Rs.": "100,000",
            "Amount in Rs": "",
          },
          {
            Particulars: "Conveyance Allowance",
            "Amount in Rs.": "55,000",
            "Amount in Rs": "",
          },
          {
            Particulars: "Overtime Allowance",
            "Amount in Rs.": "12,000",
            "Amount in Rs": "367,000",
          },
          {
            Particulars: "Gross Salary",
            "Amount in Rs.": "",
            "Amount in Rs": "1,367,000",
          },
          {
            Particulars: "Less: Benefits",
            "Amount in Rs.": "",
            "Amount in Rs": "",
          },
          { Particulars: "PF", "Amount in Rs.": "48,000", "Amount in Rs": "" },
          {
            Particulars: "Laptop",
            "Amount in Rs.": "65,000",
            "Amount in Rs": "",
          },
          {
            Particulars: "Medical Insurance",
            "Amount in Rs.": "2,500",
            "Amount in Rs": "115,500",
          },
          {
            Particulars: "Net Salary before Tax",
            "Amount in Rs.": "",
            "Amount in Rs": "1,251,500",
          },
        ]}
      />
      <Content
        paragraph="Hence the CTC over here would be the Basic Salary + The amount of benefits received which would be :-
Rs. 13,67,000 + Rs. 1,15,500 = Rs. 14,82,500.
"
      />

      <Step
        number={`What is the Gross salary in Income Tax Act 1961 in India?`}
      />
      <Content paragraph="The Gross Salary is the amount paid to the employee before any voluntary deductions are made. As a result, it is the amount an employee receives before taxes and other deductions such as employee welfare deductions, insurance premium deductions, and so on." />
      <Content paragraph="The gross salary comprises all benefits, allowances, and service fees that an employee is entitled to. After the deductions are made, the employee is paid in cash, also known as net income." />
      <Step number={`C2C vs. Take-Home Salary In India`} />
      <Content paragraph="Although gross compensation and CTC may sound comparable, they differ dramatically. The CTC is the overall expense borne by a firm to hire and retain its employees, whereas the gross reimbursement is the total income before deduction that an individual receives from his or her job." />
      <Content paragraph="If we subtract PPF, group insurance premiums, gratuity, and other deductions from the cost to the employer (CTC), we get the gross wage. After deducting taxes and other statutory deductions, this is the net salary that an individual receives." />
      <Content paragraph="The CTC comprises all direct, indirect, and savings benefits, whereas the gross compensation is an employee's entire income before taxes and deductions." />

      <Step number={`What is Take Home Salary (Net Salary) In India?`} />
      <Content paragraph="Amidst all of these calculations, the question arises what is Take home salary then? The answer to it is very simple, it is basically the salary which you actually receive in your account in layman’s terms or the Net Salary which is received in the bank account." />
      <Content paragraph="So as per the above example, The Net Salary received is Rs. 12,51,500 which then would be classified as the Take Home Salary." />

      <Step
        number={`What is The Difference between ‘Take home salary‘ and ‘Gross Salary’ in India ?`}
      />
      <Content paragraph="Gross pay is the amount obtained after aggregating all allowances and benefits but before deducting taxes, whereas net salary is the amount received by an employee. An individual's gross wage includes benefits such as HRA, conveyance allowance, medical allowance, and so on. Net Salary = Gross Salary minus all deductions, such as income tax, pension, and professional tax. Net wage is another phrase for take-home pay." />

      <Step number={`How does a pay-slip look like? (Sample Pay-slip)`} />
      <Box sx={{ textAlign: "center" }}>
        <Image
          component="img"
          sx={{
            content: {
              xs: `url(${Image8})`,
            },
          }}
        />
      </Box>
      <Content paragraph="The above pay-slip clearly demonstrates how various allowances are added and deductions and benefits are subtracted leaving us with the Take Home Salary." />
    </Box>
  );
};

export default C2CvsTakeHomeSalary;
