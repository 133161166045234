


export const capitalGainsTableData = [
    {
        Particulars: "Full value of consideration (i.e., Sales consideration)",
        Rs: "14,20,000",
    },
    {
        Particulars: "Less: Expenditure incurred in connection with transfer",
        Rs: "(20,000)",
    },
    {
        Particulars: "Net sale consideration",
        Rs: "14,00,000",
    },
    {
        Particulars: "Less: Cost of acquisition (i.e., purchase price)",
        Rs: "(12,00,000)",
    },
    {
        Particulars: "Short-Term Capital Gains",
        Rs: "2,00,000",
    },
];

// Data for Mr. Ramesh's capital gains
export const capitalGainsRamesh = [
    {
        Particulars: "",
        Option1_AvailIndexation: "",
        Option2_DoNotAvailIndexation: "",
    },
    { Particulars: "Full value of consideration", Option1_AvailIndexation: "6,00,000", Option2_DoNotAvailIndexation: "6,00,000" },
    { Particulars: "Less: Indexed cost of acquisition (Rs. 32,100 × 331/100)", Option1_AvailIndexation: "1,06,251", Option2_DoNotAvailIndexation: "NA" },
    { Particulars: "Less: Cost of acquisition", Option1_AvailIndexation: "", Option2_DoNotAvailIndexation: "32,100" },
    { Particulars: "Taxable Gain", Option1_AvailIndexation: "4,93,749", Option2_DoNotAvailIndexation: "5,67,900" },
    { Particulars: "Tax @ 20% on 4,93,749", Option1_AvailIndexation: "98,750", Option2_DoNotAvailIndexation: "NA" },
    { Particulars: "Tax @ 10% on 5,67,900", Option1_AvailIndexation: "NA", Option2_DoNotAvailIndexation: "56,790" },
];

// data for Section 54F
export const section54FTableData = [
    { Condition: "Allowability", Section54F: "Exemption is granted if the assessee has long-term capital gains on the sale of a capital asset other than a residential property." },
    { Condition: "Allowed To", Section54F: "Individual/HUF" },
    { Condition: "Conditions to be satisfied", Section54F: "a.) Purchase the residential house property either one year before or two years after the sale/transfer date.\nb.) Construct the residential house property within 3 years from the date of sale/transfer.\nc.) Purchase or construct such residential house within India" },
    { Condition: "Amount of Exemption", Section54F: "a.) If the entire sale proceeds are invested - The entire amount of long term capital gain shall be exempted.\nb.) If the entire sale proceeds are not invested - Exemption will be allowed proportionately i.e. LTCG * Amount reinvested / Sales consideration" },
    { Condition: "Reversal of Exemption", Section54F: "a.) If the property is sold within 3 years of purchase/construction.\nb.) If you purchase another residential house within 2 years of sale of the original asset.\nc.) If you construct a residential house within 3 years of sale of the original asset." },
    { Condition: "Consequences", Section54F: "If the assessee violates the conditions stated above, the exemption previously granted will be withdrawn and capital gain will become taxable from the year of transfer." },
];

// data for Section 54D
export const section54DTableData = [
    { Condition: "Allowability", Section54D: "Exemption is granted if there is compulsory acquisition of a property being used for industrial purposes." },
    { Condition: "Allowed To", Section54D: "Any category of person." },
    { Condition: "Conditions to be satisfied", Section54D: "a.) The property must have been used for industrial purpose for a period of at least the preceding 2 years.\nb.) The Assessee should purchase the new property within a period of 3 years from the date of receipt of compensation." },
    { Condition: "Amount of Exemption", Section54D: "Amount of exemption shall be lower of-\na.) Capital gain on the compulsory acquisition of property.\nb.) Amount invested in new property purchased for re-establishment." },
    { Condition: "Reversal of Exemption", Section54D: "The exemption will be reversed if the assessee sells the new property before the expiry of 3 years." },
    { Condition: "Consequences", Section54D: "If the assessee violates the conditions stated above, the cost of acquisition used while calculating capital gain on the new property will be reduced by the amount that was previously exempted." },
];

// data for Section 54EC
export const section54ECTableData = [
    { Condition: "Allowability", Section54EC: "Exemption is granted if the assessee invests the capital gain amount in the specified bonds within the given time limit." },
    { Condition: "Allowed To", Section54EC: "Any category of person." },
    { Condition: "Conditions to be satisfied", Section54EC: "a.) The assessee must invest in the capital gain bonds within a period of 6 months from the date of transfer of original assets.\nb.) The total amount of investment cannot exceed Rs 50 lakhs.\nc.) The original asset should be a long term asset i.e. it should be held by assessee for a period of more than 24 months." },
    { Condition: "Bonds eligible for 54EC exemption", Section54EC: "⦁ National Highway Authority of India or NHAI Bonds\n⦁ Indian Railway Finance Corporation Limited Bonds\n⦁ Power Finance Corporation Limited Bonds\n⦁ Rural Electrification Corporation Limited Bonds" },
    { Condition: "Amount of Exemption", Section54EC: "Amount of exemption shall be lower of-\na.) Amount invested in bonds\nb.) Rs. 50 Lakhs" },
    { Condition: "Reversal of Exemption", Section54EC: "If the assessee sells such bonds before the expiry of a period of 5 years, the exemption under section 54EC will be reversed." },
    { Condition: "Consequences", Section54EC: "If the assessee violates the conditions stated above, the exemption previously granted will be withdrawn and capital gain will become taxable." },
];



