import React from 'react';
import Mainhead from '../../../../Components/Services/MainContent/Mainhead';
import Content from '../../../../Components/Services/MainContent/Content';

function Cost() {
  return (
    <div>
     <Mainhead hheading="Cost of Trademark Renewal
"/>
     <Content paragraph="The government fee for Trademark Renewal is Rs 10,000 (for offline filing) and Rs.9,000 for (for online filing) if the application for the renewal is filed before the expiry of the trademark. If the application is filed within 6 months after the expiry, the applicable surcharge over and above the renewal fees would be Rs.4,500 per class (for e-filing) and Rs.5,000 per class (for offline filing).
"/>
    </div>
  )
}

export default Cost