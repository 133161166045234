import React from "react";
import { Box } from "@mui/material";
import Mainhead from "../../../Components/Services/MainContent/Mainhead";
import Step from "../../../Components/Services/MainContent/Step";
import Content from "../../../Components/Services/MainContent/Content";
import CustomList from "../../../Components/Income-Tax/CustomList";

const Section80CCC = () => {
  return (
    <Box
      style={{
        display: "flex",
        flexDirection: "column",
        gap: 4,
        marginBottom: "2rem",
      }}
    >
      <Mainhead heading="Section 80CCC - Deduction in respect of contribution to certain pension funds" />

      <Step
        number={`
          What is the eligibility to claim 80CCC deduction?
        `}
      />
      <CustomList
        points={[
          "1) Be an individual: You must be an individual to claim the income tax advantage under Section 80CCC. This section is also available to non-resident individuals (NRIs).",
          "2) Have a taxable income: You can claim the tax break only if you have taxable income that can be offset by this deduction. There is no need to claim the deduction if your income is less than the basic exemption amount.",
          "3) Contribute to specified pension funds: The tax benefit is only available if the money is invested in certain pension funds during the relevant fiscal year.",
          "4) Contribute out of taxable income: The investment must be done out of your taxable income and not from any other source. Note: If any sum paid or deposited by the assessee has been taken into account for the purposes of this section, a deduction under section 80C with relation to such amount is not allowed.",
        ]}
      />

      <Step
        number={`
          What is the tax break for investments made under Section 80CCC?
        `}
      />
      <Content
        paragraph={`
          The tax implications and benefits under Section 80CCC are as follows:
          1. Investment Amount: You are eligible for a full deduction of up to Rs 1.5 lakh.
          2. Received pension or withdrawal amount: This money is fully taxable in the receiver's hands.
          3. Received Interest or Bonus: The money received is completely taxable in the receiver's hands.
        `}
      />

      <Step
        number={`
          What is the Maximum Allowable Deduction under section 80CCC?
        `}
      />
      <Content
        paragraph={`
          The maximum allowable deduction is Rs. 1,50,000 [Furthermore, the total limit of'1,50,000 imposed in section 80CCE will remain in effect, i.e. the maximum permitted deduction under sections 80C, 80CCC, and 80CCD(1) combined is Rs.1,50,000].
        `}
      />

      <Step
        number={`
          Deemed Income:
        `}
      />
      <Content
        paragraph={`
          If the assessee or his nominee receives any amount standing to the credit of the assessee in the fund in respect of which a deduction has been allowed, together with interest or bonus accrued or credited to the assessee's account on account of the surrender of the annuity plan in any previous year or as pension received from the annuity plan, such amount will be deemed to be the assessee's or the nominee's income in that previous year. It will be taxed as income from the prior year.
          It is not available under the default tax regime under section 115 BAC.`}
      />
    </Box>
  );
};

export default Section80CCC;
