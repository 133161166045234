import React from 'react';
import Mainhead from '../../../../Components/Services/MainContent/Mainhead';
import List from '../../../../Components/Services/MainContent/List';


function Checklist() {
  return (
    <div>

      <Mainhead heading="Checklist for sole proprietorship registration in India"/>
      <ul className='list'>
        <li className='bullet'>
          <span>
      <List points="A certificate/license issued by Municipal authorities under the Shop And Establishment Act."/>
      </span>
      </li>
      <li className='bullet'>
          <span>
      <List points="The license issued by Registering authorities like the Certificate of Practice is issued by the Institute of Chartered Accountants of India."/>
      </span>
      </li>
      <li className='bullet'>
          <span>
      <List points="The registration/ licensing document is issued in the name of the proprietary concern by the Central Govt/ State Govt Authority/ Department."/>
      </span>
      </li>
      <li className='bullet'>
          <span>
      <List points="The Banks may also accept the IEC(Importer Exporter Code) issued to the proprietary concern by the office of the DGFT as an identity document for opening of the bank account etc."/>
      </span>
      </li>
      <li className='bullet'>
          <span>
      <List points="Complete Income Tax return(not just the acknowledgement) in the name of the sole proprietor where the firm’s income is reflected, duly authenticated and acknowledged by the Income Tax Authorities."/>
      </span>
      </li>
      </ul>

    </div>
  )
}

export default Checklist;