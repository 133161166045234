import React from 'react';
import Mainhead from '../../../../Components/Services/MainContent/Mainhead';
import Content from '../../../../Components/Services/MainContent/Content';
import List from '../../../../Components/Services/MainContent/List';
import Step from '../../../../Components/Services/MainContent/Step';
import { Box , styled, Typography, Link} from '@mui/material';



function Register() {
  return (
    <div>
      <Mainhead heading="LLP Registration Process"/>

       <Step number="Step -1: Getting Digital Signature Certificate (DSC):"/>
       <Content paragraph="Before initiation, you must apply for the digital signature of the designated partners of the proposed LLP. All the documents for LLP are filed online and are required to be digitally signed. So, the designated partner must obtain their digital signature certificates from government recognized certifying agencies"/>

       <Step number="Step -2 : Apply for Director Identification Number (DIN):"/>
       <Content paragraph="Apply DIN of all the designated partners or those intending to be designated partners of the proposed LLP. The application for allotment of DIN has to be made in Form DIR-3. Simply attach the scanned copy of documents (usually Aadhaar/PAN) to the form.The form has to be signed by a Company Secretary in full-time employment or by the. Managing Director/Director/CEO/CFO of the existing company in which the applicant shall be appointed as a director."/>

       <Step number="Step -3 : Approval of the Name:"/>
       <Content paragraph="We need to check if the name you want to register under is available and reserve it for your LLP. You can check for name availability in the MCA portal. The approval of the name will be made by the registrar only if the central government does not deem it undesirable. The name should not hold any resemblance to any of the existing partnership firms, LLPs, trademarks, or body corporates You can check for name availability in the"/> <Link href="https://www.mca.gov.in/mcafoportal/showCheckLLPName.do">MCA portal</Link>

      <Step number="Step -4 : Incorporating LLP:"/>
      <ul className='list'>
        <li className='bullet'>
          <span>
      <List points="FILLIP (Form for incorporation of Limited Liability Partnership) which is filled with the Registrar who has jurisdiction over the state in which the registered office of the LLP is situated. The form will be an integrated form."/>
      </span>
      </li>
      <li className='bullet'>
          <span>
      <List points ="Fees as per Annexure ‘A’ shall be paid."/>
         </span>
         </li>
         <li className='bullet'>
          <span>
      <List points ="This form also provides for applying for allotment of DPIN, if an individual who is to be appointed as a designated partner does not have a DPIN or DIN."/>
      </span>
      </li>
      <li className='bullet'>
          <span>
      <List points ="The application for allotment shall be allowed to be made by two individuals only."/>
       </span>
       </li>
       <li className='bullet'>
          <span>
      <List points ="The application for reservation may be made through FiLLiP too."/>
      </span>
      </li>
      <li className='bullet'>
          <span>
      <List points ="If the name that is applied for is approved, then this approved and reserved name shall be filled as the proposed name of the LLP"/>
        </span>
        </li>
        </ul>
      
      <Step number="Step -5 : File LLP Agreement:"/>

      <Content paragraph ="LLP agreement governs the mutual rights and duties amongst the partners and also between the LLP and its partners."/>
      <ul className='list'>
       <li className='bullet'>
        <span>
      <List points ="LLP agreement must be filed in form 3 online on MCA Portal."/>
      </span>
      </li>
      <li className='bullet'>
        <span>
      <List points ="Form 3 for the LLP agreement has to be filed within 30 days of the date of incorporation."/>
      </span>
      </li>
      <li className='bullet'>
        <span>
      <List points ="The LLP Agreement has to be printed on Stamp Paper. The value of Stamp Paper is different for every state."/>
       </span>
       </li>
       </ul>
  
    </div>
  )
}

export default Register