import React from "react";
import { Box } from "@mui/material";
import Mainhead from "../../../Components/Services/MainContent/Mainhead";
import Content from "../../../Components/Services/MainContent/Content";
import ReusableTable from "../../../Components/Income-Tax/ReusableTable";

const Section50CAnd43CA = () => {
  const data = [
    {
      slNo: 1,
      BASIS: "Applicability",
      Section_50C:
        "Capital asset refers to land and buildings that are considered immovable property.",
      Section_43CA:
        "Immovable property includes buildings and land that are classified as stock in trade.",
    },
    {
      slNo: 2,
      BASIS: "Owner's Occupations",
      Section_50C:
        "In accordance with this, the assessee typically does not engage in real estate activities with regard to such movable property, which includes buildings and land.",
      Section_43CA:
        "As a trader or real estate developer, the assessee is required by this to be involved in real estate activities. The assessee's argument needs to be supported by business activity.",
    },
    {
      slNo: 3,
      BASIS: "Allowance for deduction in calculating profit or gain",
      Section_50C:
        "i. Cost of acquisition ii. Cost of Improvement iii. Expenses on transfer *Indexation benefit will be provided in case of Long term Capital Gain.",
      Section_43CA:
        "Purchase Cost + Brokerage paid + Plotting expenses + other Incidental business expenses incurred.",
    },
    {
      slNo: 4,
      BASIS: "Accounting procedures under Books",
      Section_50C: "Booked under the heading Fixed assets",
      Section_43CA:
        "Stock in Trade is recorded in books under the heading Current assets as Stock in Trade.",
    },
    {
      slNo: 5,
      BASIS: "Income derived from the sale of immovable property",
      Section_50C:
        "Any profit or gain derived from the sale of immovable property (land and buildings) as a capital asset is taxable under the heading Capital Gain.",
      Section_43CA:
        "Any gain or profit resulting from the transfer of immovable property, such as buildings and land, as stock in trade is subject to taxation under the Business income heading.",
    },
    {
      slNo: 6,
      BASIS: "Exclusion",
      Section_50C:
        "In the following situations, Section 50C does not apply:⦁ Transfer of leasehold rights within a building, since a plot of land is not considered land or a building under Section 50C. Transfer of development rights over the owner's property.",
      Section_43CA:
        "The provisions relating to capital gains tax on the disposal of immovable property held as stock in trade do not apply in the following scenarios: 100% of the company's shares are transferred when the only asset is immovable property. Section 43CA does not apply to rights that are connected, incidental, or attached to land, buildings, or both.",
    },
    {
      slNo: 7,
      BASIS: "Consideration for Sale",
      Section_50C:
        "The sale consideration cannot be less than 110% of the stamp duty value or the government's determined stamp duty value.",
      Section_43CA:
        "The sale consideration must equal 110% of the stamp duty value or the government's determined stamp duty value.",
    },
  ];

  return (
    <Box
      style={{
        display: "flex",
        flexDirection: "column",
        gap: 4,
        marginBottom: "2rem",
      }}
    >
      {" "}
      <Mainhead heading="SECTION-50C AND SECTION-43CA" />
      <Content paragraph="WHAT DO YOU MEAN BY SECTION 50C UNDER INCOME TAX ACT,1961?" />
      <Content paragraph="The computation of capital gains on the sale of real estate, including buildings and land, is covered by Section 50C. These assets are held as capital assets. According to this section, the Stamp Valuation Authority's assessment of the stamp duty value shall not be less than the value of the sale consideration. However, the income tax department permits a variation in marginal relief of 10%. If property is held as stock, either as a building or as land, then Section 50C does not apply." />
      <Content paragraph="WHAT DO YOU MEAN BY SECTION 43CA UNDER INCOME TAX ACT,1961?" />
      <Content paragraph="Section 43CA was enacted to cover the transfer of immovable property held as stock in trade. Where the consideration received or accruing as a result of an assessee's transfer of an asset (other than a capital asset), being land or building or both, is less than the value adopted, assessed, or assessable by any authority of a State Government for the purpose of payment of stamp duty in respect of such transfer, the value so adopted, assessed, or assessable shall be deemed to be the value adopted, assessed, or assessable for the purposes of computing profits and gains from such transfer. Section 43CA applies to the transfer of immovable property held as stock in trade, and the undervalued portion of the stamp duty valuation of immovable property held as stock in trade is taxable as business income in the seller's hands." />
      <Content paragraph="IS SECTION 50C AND 43 CA SAME?" />
      <ReusableTable
        columns={[
          { label: "Sl. No.", key: "slNo" },
          { label: "BASIS", key: "BASIS" },
          { label: "Section 50C", key: "Section_50C" },
          { label: "Section 43CA", key: "Section_43CA" },
        ]}
        data={data}
      />
    </Box>
  );
};

export default Section50CAnd43CA;
