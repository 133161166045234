import React from 'react';
import Sidecomponent from '../../../Components/Tax-Planning/Sidecomponent';
import Topdiv from "../../../Components/Tax-Planning/Topdiv"

import Mainhead from '../../../Components/Services/MainContent/Mainhead';
import Content from '../../../Components/Services/MainContent/Content';
import List from '../../../Components/Services/MainContent/List';
import Step from '../../../Components/Services/MainContent/Step';
import Faq from "./Faq.jsx";

import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import GetInTouch from '../../../Components/Services/GetInTouch';

import {
  Grid, Box, Container, styled, Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Link
} from '@mui/material';

function createData(
  Col1,
  Col2,
  
) {
  return {
    Col1,
    Col2,
    
  };
}
function createData1(
  Col1,
  Col2,
  Col3,
  Col4,
  Col5
) {
  return {
    Col1,
    Col2,
    Col3,
    Col4,
    Col5
    
  };
}

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: '#773106',
    color: theme.palette.common.white,
    textAlign: 'left',
    fontFamily: 'Sora',
    fontWeight: 'bold',



  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 16,
    textAlign: 'left',
    textAlign: 'left',
    fontFamily: 'Sora',
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: theme.palette.action.hover,
    fontFamily: 'Sora'
  },
  // hide last border
  '&:last-child td, &:last-child th': {
    border: 0,
  },
}));

const Bottomspace = styled(Box)(({ theme }) => ({
  marginBottom:'2.1rem'
}));

const rows1 = [
  createData('Profit as per P&L a/c', 'XXX'),
  createData('Less: Income not chargeable to tax under PGBP but shown in P&L a/c ', 'XXX'),
  createData('Less: Expense allowable as per income tax act but not shown in P&L a/c', 'XXX'),
  createData('Add: Income chargeable to tax under PGBP but not shown in P&L a/c', 'XXX'),
  createData('Add: Expense not allowable as per income tax act but shown in P&L a/c', 'XXX'),
  createData('Income taxable under head PGBP', 'XXX'),
]

const rows2 = [
  createData('Buildings (Residential)', '5%'),
  createData('Factory Buildings and Commercial Buildings ', '10%'),
  createData('Furniture and Fittings ', '10%'),
  createData('Plant and Machinery', '15%'),
  createData('Motor Cars (Used in the business of running on hire) ', '30%'),
  createData('Motor Cars (Other than those used in the business of running on hire)', '15%'),
  createData('Computers and Laptops', '40%'),
]
const rows3 = [
  createData('Actual cost of asset', 'XXX'),
  createData('Less: Salvage value', 'XXX'),
  createData('Depreciable amount ', 'XXX'),
  createData('Less: Depreciation (as per written down value method)', 'XXX'),
  createData('Written down value (WDV) of asset ', 'XXX'),
]
const rows4 = [
  createData('Residency', 'Resident Individual, Resident Hindu Undivided Family, and Resident Partnership Firm (excluding Limited Liability Partnership firms)'),
  createData('Claims for deductions', 'Individuals or entities that have not made claims for deductions under sections 10A/10AA/10B/10BA or under sections 80HH to 80RRB in the relevant year'),
  createData('Business','Small taxpayers engaged in any business, except for: Business of plying, hiring or leasing goods carriages referred to in sections 44AE, Agency Business, Commission Business, Any business whose total turnover or gross receipts exceeds two crore rupees, Person carrying on profession as referred to in section 44AA(1)'),
]
const rows5 = [
  createData('Residency', 'Resident assessees'),
  createData('Gross receipts of profession', 'Total gross receipts of profession do not exceed fifty Lakh rupees'),
]
const rows6 = [
  createData('Total turnover or gross receipts', 'Total turnover or gross receipts from the business do not exceed the limit prescribed under section 44AB(i.e., Rs. 2,00,00,000)'),
]
const rows7 = [
  createData('Profit as per profit and loss account or as per books', 'XXX'),
  createData('Less: Income not chargeable to tax under PGBP but shown in P&L account', 'XXX'),
  createData('Less: Expense allowable as per income tax act but not shown in P&L account', 'XXX'),
  createData('Add: Income chargeable to tax under PGBP but not shown in P&L account', 'XXX'),
  createData('Add: Expense not allowable as per income tax act but shown in P&L account', 'XXX'),
  createData('Income taxable under head PGBP  ', 'XXX'),
]
const rows8 = [
  createData('Eligibility', 'Businesses except those in plying, hiring or leasing of goods carriages, agency or commission business, or those whose total turnover or gross receipts exceed Rs. 2 crore.'),
  createData('Computation of Taxable Income', '@ 8%/6% of turnover or gross receipts with no further deductions or allowances.'),
  createData('Maintenance of Books of Account', 'Not required'),
  createData('Advance Tax Liability', 'Required, with interest for non-payment by 15th March of previous year'),
  createData('Consequences of Opting Out', 'Not eligible for presumptive taxation scheme for the next 5 AYs, required to maintain books of account and subject to tax audit as per section 44AB from the AY in which he opts out.'),
 
]
const rows9= [
  createData1('Resident assessee (individual or HUF) carrying on specified profession with gross receipts not exceeding INR 50 Lakhs per financial year', 'Income computed at 50% of gross receipts', 'No, deemed to have claimed all deductions of expenses', 'Liable to pay whole amount of advance tax on or before 15th March of previous year', 'Not required to maintain books of account as per section 44AA'),
  createData1('Lower income declaration', 'Not allowed to claim deductions of expenses if income exceeds maximum amount not chargeable to tax', 'Required to maintain books of account as per section 44A')
  
]
const rows10 = [
  createData('Ownership of goods vehicles', 'Not more than 10 goods vehicles at any time during the year'),
  createData('Advance tax', 'Liable to pay advance tax'),
  createData('Books of account', 'Not required to maintain books of account under Section 44AA'),
]


function Business() {
  return (
    <div>
      <Topdiv heading="Are you looking to file your Income Tax Returns ?"/>
      <div className='Container'>
      <Container>
        <Grid container>
          <Grid item lg={4} xl={3}>
          <Box sx={{ display: { xs: 'none', lg: 'block' }, marginTop: '4rem', position: 'sticky', top: 90, marginBottom: '3rem' }}>
              <Sidecomponent />
            </Box>
          </Grid>
          <Grid item xs={11} lg={8} sx={{ marginTop: '4rem' }} xl={9}>
            
            <Mainhead heading='Basic Concept and Charging Section'/>
            <Step number="In all, there are three concepts,namely, (i) Business, (ii) Profession and (iii) Vocation."/>
            <Content paragraph="There is no need to explain the term ‘Business’ , as it is very much self-explanatory."/>
            <Step number="Business :"/>
            <Content paragraph="According to Section 2(13), the term ‘Business’ is defined to include any Trade, commerce or manufacture or any Adventure or Concern in the nature of Trade, Commerce or Manufacture."/>
            <Step number="Profession :"/>
            <Content paragraph="The term ‘profession’ has been defined in a very narrow manner. According to section 2(36) the term ‘profession’ has been defined to include any ‘vocation’. "/>
            <Content paragraph="The terms ‘Profession’ and ‘Vocation’ are very similar to each other; the only difference is that ‘vocation’ requires ‘natural abilities’ , whereas ‘Profession’ requires some kind of ‘educational qualification’ . "/>
            <Content paragraph="Chartered Accountant, Doctor, Lawyer are examples of profession, whereas examples of vocation would include, singer, Actor, Musician, Carpenter, Cobbler, Plumber,Barber, etc. Income Tax Act, does not recognized any difference between Profession and Vocation, according to it both Vocation and profession are one and the same, but it does recognize the difference between ‘Business’ and ‘Profession’ . "/>
            <Content paragraph="Through, Act recognizes the difference between the two, income from both is taxed at the same rates and in a similar way.Capital Receipt v/s Revenue Receipts "/>
            <Content paragraph="Generally all capital receipts are exempt and all revenue receipts are taxable. However, in many cases, capital receipts are made taxable under the Act. Section 28 covers various situations when capital receipts are made taxable. If any capital receipt is NOT covered by other clauses of section 28, it may be exempted. "/>
           <Bottomspace/>

            <Mainhead heading="Which incomes are considered as business income?"/>
            <Content paragraph="Business income includes income arising from the business or profession carried out by the assessee during the previous year, such as revenue from sales, fees or commissions earned, and any other income generated by the business. This can include remuneration, interest on capital, profit from partnership firms (although this may be exempt income), bonuses, salaries, and any other income received as a result of the business or profession. Additionally, any gifts, benefits, or perquisites arising due to the business or profession, fair market value of inventory converted into capital assets, and any compensation received on the termination or modification of any contracts relating to the business are also considered as business income."/>
            <Bottomspace/>
            <Mainhead heading="How to calculate the income from PGBP?"/>
            <Step number="COMPUTATION OF INCOME FROM PGBP" />
            <Content paragraph="To calculate the income from 'Profit and Gains of Business or Profession' (PGBP), you would start by determining the profit as per the Profit and Loss account (P&L) or as per the books. This would be the gross income generated by the business or profession."/>
            <Content paragraph="Then, deduct any income that is not chargeable to tax under PGBP but is shown in the P&L account, such as any exemptions or deductions."/>
            <Content paragraph="Next, deduct any expenses that are allowable as per the income tax act but are not shown in the P&L account, such as depreciation or any other expenses that are allowed as per the tax laws."/>
            <Content paragraph="Add any income that is chargeable to tax under PGBP but is not shown in the P&L account, such as any income from other sources."/>
            <Content paragraph="Finally, add any expenses that are not allowable as per the income tax act but are shown in the P&L account, such as any fines or penalties."/>
            <Bottomspace/>

            <TableContainer component={Paper}>
                <Table  aria-label="customized table"  size='small'>
        <TableHead>
          <TableRow>
            <StyledTableCell>Particulars </StyledTableCell>
            <StyledTableCell>Amount </StyledTableCell>
           
          </TableRow>
        </TableHead>
        <TableBody>
          {rows1.map((row) => (
            <StyledTableRow
              key={row.name}
            
            >
              <StyledTableCell component="th" scope="row"
              >
                {row.Col1}
              </StyledTableCell>
              <StyledTableCell>{row.Col2}</StyledTableCell>
      
              </StyledTableRow>
          ))}
        </TableBody>
      </Table>
      </TableContainer>

      <br />
            <Mainhead heading="What is the treatment of rent when the assessee is the owner of the property?" />
            <Content paragraph="When the assessee is the owner of the property and occupies it for business purposes, no notional rent is allowed as a deduction. This means that the assessee cannot claim any expenses related to the property as a deduction, even if they are using it for their business."/>
            <Content paragraph="However, in case of a firm, where the firm runs its business in the premises owned by one of its partners, the rent payable to the partner is allowed as a deduction under the head of 'Profit and Gains of Business or Profession' (PGBP). This means that the firm can claim the rent paid to the partner as an expense and deduct it from their taxable income."/>
            
          <Bottomspace/>
            <Mainhead heading="Whether the amount spent for the repair of a building shall be allowed as a deduction?" />
            <Content paragraph="The amount spent for the repair of a building can be allowed as a deduction under the head of 'Profit and Gains of Business or Profession' (PGBP) in the following cases:"/>
            <ul className="list">
              <li className="bullet">
                <span>
                <Content paragraph="If the assessee is the owner of the property and the repairs are incurred for the purpose of earning income from the property."/>
                </span>
              </li>
              <li className="bullet">
                <span>
                <Content paragraph="If the assessee is a tenant who has undertaken to bear the cost of repair of the building"/>
                </span>
              </li>
              <li className="bullet">
                <span>
                <Content paragraph="In cases of lessee, licensee, or mortgagee with possession, they are entitled to claim the deduction of repair of the building under this head."/>
                </span>
              </li>
            </ul>
            <Content paragraph="However, it's important to note that repair of capital nature shall not be allowed as a deduction under this head. Capital repairs refer to substantial renovations or improvements to the property that increase its value or extend its useful life."/>
<Bottomspace/>
            <Mainhead heading="Whether the repair and insurance of machinery, plant and furniture shall be allowed as a deduction?" />
            <Content paragraph="The repair and insurance of machinery, plant, and furniture can be allowed as a deduction under the head of 'Profit and Gains of Business or Profession' (PGBP) if the following conditions are met:"/>
            <ul className='list'>
              <li className="bullet">
                <span>
                <Content paragraph="The assets are used for the purpose of business"/>
                </span>
              </li>
              <li className="bullet">
                <span>
                <Content paragraph="The repair expenses incurred are for the maintenance or repair of the assets, and does not include reconstruction or replacement of the assets."/>
                </span>
              </li>
              <li className="bullet">
                <span>
                <Content paragraph="The insurance premium should have been actually paid."/>
                </span>
              </li>
            </ul>
            <Content paragraph="However, it's important to note that repair of capital nature shall not be allowed as a deduction under this head. Capital repairs refer to substantial renovations or improvements to the assets that increase its value or extend its useful life."/>
            <Bottomspace/>
            <Mainhead heading="Whether the charge of depreciation is mandatory?" />
            <Content paragraph="Yes, the charge of depreciation is mandatory. Under the Income Tax Act, the deduction on account of depreciation shall be made compulsorily, whether or not the assessee has claimed the deduction in computing their total income."/>
            <Content paragraph="Depreciation is the method of accounting for the gradual wear and tear of assets used in a business over time. It is a non-cash expense that is used to spread the cost of an asset over its useful life. Depreciation is calculated on the cost of the asset and is allowed as a deduction in computing the taxable income."/>
            <Content paragraph="The Income Tax Act has laid down the rates at which depreciation is to be charged, which is different for different assets. The assessee is eligible to claim the depreciation on the assets which are used for the purpose of business or profession."/>
            <Bottomspace/>
            <Mainhead heading="On which assets the depreciation should be allowable?" />
            <Content paragraph="Depreciation should be allowable only on tangible assets used in a business or profession. Examples of such assets include:"/>
            <Content paragraph="Building: such as factories, offices, warehouses, etc."/>
            <Content paragraph="Machinery: such as production equipment, vehicles, etc."/>
            <Content paragraph="Plant: such as generators, compressors, boilers, etc."/>
            <Content paragraph="Furniture: such as desks, chairs, tables, etc."/>
            <Content paragraph="These are the assets for which the Income Tax Act has laid down the rates at which depreciation is to be charged. Depreciation is calculated on the cost of the asset, including any expenditure incurred on its installation and commissioning, and is allowed as a deduction in computing the taxable income."/>
           <Bottomspace/>
            <Mainhead heading="Whether the depreciation is allowed on the intangible assets?"/>
            <Content paragraph="No, depreciation is not allowed on any kind of intangible assets such as patents, trademarks, copyrights, and goodwill. Intangible assets are non-physical assets that have monetary value but cannot be seen or touched. They represent an organization's intellectual property and provide a competitive advantage. Examples of intangible assets include patents, trademarks, and copyrights, as well as business processes, customer lists, and other proprietary information."/>
            <Content paragraph="Depreciation is the method of accounting for the gradual wear and tear of assets used in a business over time. It is a non-cash expense that is used to spread the cost of an asset over its useful life. Depreciation is only allowed for tangible assets, such as buildings, machinery, plant, and furniture, as they are physical assets that have a limited useful life."/>
            <Content paragraph="Intangible assets do not have physical existence, so the concept of wear and tear does not apply to them. Therefore, they cannot be depreciated. However, there are other methods like Amortization to account for the use of intangible assets over time."/>
           <Bottomspace/>
            <Mainhead heading="Are land depreciable assets?"/>
            <Content paragraph="No, land is not considered a depreciable asset. Land is a long-term asset that is not subject to wear and tear or obsolescence and it's value may appreciate over time. Depreciation is the method of accounting for the gradual wear and tear of assets used in a business over time."/>
            <Content paragraph="Land is not depreciable because it is considered a fixed asset and it is not subject to wear and tear, obsolescence or it's value does not decline over time. Therefore, the cost of land is not depreciated but it is recorded as an asset on the balance sheet and its value is not reduced over time."/>
            <Bottomspace/>
            <Mainhead heading="What are the conditions for claiming the deduction of depreciation under income tax?"/>
            <Content paragraph="There are several conditions that must be met in order to claim the deduction of depreciation under income tax:"/>
            <ul className='list'>
              <li className="bullet">
                <span>
              <Content paragraph="The asset must be used for the purpose of the business or profession: The asset must be used for the purpose of earning income, and the depreciation can only be claimed for the period for which the asset was used for the business or profession."/>
              </span>
              </li>
              <li className="bullet">
                <span>
              <Content paragraph="The asset must be owned by the assessee: The asset must be owned by the assessee, either fully or partially, in order to claim the depreciation."/>
              </span>
              </li>
              <li className="bullet">
                <span>
              <Content paragraph="The asset must be in the possession of the assessee: The asset must be in the possession of the assessee, either fully or partially, in order to claim the depreciation."/>
              </span>
              </li>
              <li className="bullet">
                <span>
              <Content paragraph="The asset must be in a depreciable condition: The asset must be in a depreciable condition, meaning that it is subject to wear and tear, and has a limited useful life."/>
              </span>
              </li>
              <li className="bullet">
                <span>
              <Content paragraph="The asset must be put to use for the first time during the previous year: The asset must be put to use for the first time during the previous year for which the depreciation is claimed."/>
              </span>
              </li>
              <li className="bullet">
                <span>
              <Content paragraph="The asset must be fully depreciated in the year of disposal or discontinuation of use: If the asset is disposed of or discontinues to be used during the year, the entire amount of depreciation should be claimed in that year."/>
              </span>
              </li>
            </ul>
            <br/>
            <Mainhead heading="What is the provision for the assets used for less than 180 days?" /> 
            <Content paragraph="Where any assets are acquired by the assessee during the previous year and is put to use for the purpose of business and profession for a period of less than 180 days, the provision for depreciation is that the depreciation shall be allowed at 50 percent of the normal rate provided as per the Income Tax Act. This means that if the normal rate for depreciation on that asset is say, 15%, the depreciation allowed for an asset used for less than 180 days will be 7.5%."/>
            <Content paragraph="This provision is in place to account for the fact that the asset has not been used for a full year, and therefore should not be depreciated at the full rate. The 180 days threshold is used to determine whether the asset has been used for a significant amount of time, and whether the full depreciation rate should be applied."/>
            <Bottomspace/>
            <Mainhead heading="What is the threshold limit of getting an audit of books of accounts in case of a profession?" /> 
            <Content paragraph="As per the Income Tax Act, for a profession, if the total gross receipt exceeds Rs 50 lakh, then the threshold limit for getting an audit of books of accounts is triggered. This means that if the total gross receipt from the profession exceeds Rs 50 lakh, the assessee is required to get their books of accounts audited by a Chartered Accountant (CA) and file an audit report along with the income tax return."/>
            <Content paragraph="This audit requirement applies to all professions, including professionals such as doctors, engineers, architects, chartered accountants, and lawyers, among others. The audit report must be in the prescribed form, and the CA must certify that the books of accounts have been audited in accordance with the applicable laws and regulations."/>

            <Bottomspace/>
            <Mainhead heading="Whether personal expenditure is allowed as a deduction from profit and gain from business and profession?" /> 
            <Content paragraph="No, personal expenditure is not allowed as a deduction from profit and gain from business and profession. Personal expenditure refers to any expenses incurred for personal or non-business related purposes and is not considered an allowable expense for the purpose of calculating taxable income from a business or profession."/>
            <Content paragraph="Expenses that are incurred solely for the purpose of earning income from a business or profession are considered as business expenses and are allowed as deductions. Examples include rent for business premises, salaries for employees, cost of goods sold, repairs and maintenance, and insurance."/>
            <Content paragraph="Personal expenses such as food, clothing, travel, and housing that are not incurred solely for the purpose of earning income, are not allowed as deductions."/>

            <Bottomspace/>
            <Mainhead heading="What are the conditions for claiming additional depreciation?" />
            <Content paragraph="To claim the additional depreciation, the following conditions must be met:"/>
            <ul className='list'>
              <li className='bullet'>
                <span>
                <Content paragraph="The new plant or machinery must be acquired or installed after 31st March 2005 by the assessee."/>
                </span>
              </li>
              <li className="bullet">
                <span>
                <Content paragraph="The assessee must be engaged in the business of manufacturing, producing any article or thing or in the business of generation, transmission or distribution of power."/>
                </span>
              </li>
              <li className="bullet">
                <span>
                <Content paragraph="The standard rate of additional depreciation is 20% of the cost of the new asset."/>
                </span>
              </li>
              <li className="bullet">
                <span>
                <Content paragraph="If the new asset is put to use for less than 180 days in the same financial year in which it was acquired, the rate of additional depreciation would be 50% of the standard rate, which is 10% of the cost of the asset."/>
                </span>
              </li>
            </ul>

            <br/>
            <Mainhead heading="What kind of plant and machinery cannot be claimed for additional depreciation?" />
            <Content paragraph="Additional depreciation cannot be claimed for:"/>
            <ul className='list'>
              <li className='bullet'>
                <span>
                <Content paragraph="Plant and machinery that have been previously used before its installation in India or outside India by any other person, commonly known as second-hand or used plant and machinery."/>
                </span>
              </li>
              <li className="bullet">
                <span>
                <Content paragraph="Machinery or plants that have been installed in office premises, residential accommodation, or any guest house."/>
                </span>
              </li>
              <li className="bullet">
                <span>
                <Content paragraph="Office appliances such as computers, printers, and fax machines."/>
                </span>
              </li>
              <li className="bullet">
                <span>
                <Content paragraph="Road transportation vehicles, such as cars and buses, unless they are used specifically for the business or profession."/>
                </span>
              </li>
           </ul>

            <br/>
            <Mainhead heading="What are the four main categories of the assets as per income tax act?" />
            <Content paragraph="The four main categories of assets as per the Income Tax Act in India are:"/>
            <ul className='list'>
              <li className='bullet'>
                <span>
                <Content paragraph="Buildings"/>
                </span>
              </li>
              <li className="bullet">
                <span>
                <Content paragraph="Plant and machinery"/>
                </span>
              </li>
              <li className="bullet">
                <span>
                <Content paragraph="Furniture and fittings"/>
                </span>
              </li>
              <li className="bullet">
                <span>
                <Content paragraph="Ships"/>
                </span>
              </li>
            </ul>
            <Content paragraph="These are considered as depreciable assets and are eligible for claiming depreciation under the Income Tax Act. The rate of depreciation and method of calculation may vary for each category of assets."/>
            <Bottomspace/>
            <Mainhead heading="What is the rate of depreciation on buildings mainly used for residential purposes except hotels and boarding houses?" />
            <Content paragraph="The rate of depreciation on buildings mainly used for residential purposes except hotels and boarding houses is 5% per annum as per the Income Tax Act in India. This rate applies to the actual cost of the building, which includes the cost of acquisition, construction, repairs and any other capital expenditure incurred on the building. The depreciation is calculated on a straight-line basis, which means that the same amount of depreciation is claimed each year over the useful life of the building."/>
            <Bottomspace/>
            <Mainhead heading="Depreciation rates for various types of assets:" />
            <Content paragraph="Here's a table summarizing the depreciation rates for various types of assets as per the Income Tax Act in India:"/>
            <br/>
            <TableContainer component={Paper}>
                <Table  aria-label="customized table"  size='small'>
        <TableHead>
          <TableRow>
            <StyledTableCell>Asset </StyledTableCell>
            <StyledTableCell>Depreciation Rate </StyledTableCell>
           
          </TableRow>
        </TableHead>
        <TableBody>
          {rows2.map((row) => (
            <StyledTableRow
              key={row.name}
            
            >
              <StyledTableCell component="th" scope="row"
              >
                {row.Col1}
              </StyledTableCell>
              <StyledTableCell>{row.Col2}</StyledTableCell>
      
              </StyledTableRow>
          ))}
        </TableBody>
      </Table>
      </TableContainer>
      <br/>
      <Mainhead heading="What is the actual cost of assets acquired by way of gift or inherent?" />
      <Content paragraph="The actual cost of assets acquired by way of gift or inheritance is determined as the actual cost to the previous owner minus the depreciation allowable to the assessee, as per the Income Tax Act in India."/>
      <Content paragraph="In the case of assets acquired by way of gift, the actual cost to the previous owner would be the cost at which the previous owner acquired the asset. In the case of assets acquired by way of inheritance, the actual cost to the previous owner would be the fair market value of the asset at the time of the previous owner's death."/>
      <Bottomspace/>
      <Mainhead heading="What are the methods of calculation of depreciation as per income tax act 1961?" />
      <Content paragraph="As per the Income Tax Act 1961 in India, there are two main methods of calculation of depreciation:"/>
      <ul className='list'>
        <li className='bullet'>
          <span>
          <Content paragraph="Written Down Value Method (for block of assets)"/>
          </span>
        </li>
        <li className="bullet">
          <span>
          <Content paragraph="Straight Line Method (for power generation units)"/>
          </span>
        </li>
      </ul>
      <Content paragraph="The Written Down Value Method is used to calculate depreciation on a block of assets, such as buildings, plant and machinery, furniture and fittings, and ships. Under this method, the depreciable amount is calculated as the actual cost of the asset minus the salvage value, divided by the useful life of the asset. The depreciation rate is then applied to the depreciable amount in each year, and the depreciation amount is reduced each year as the value of the asset decreases"/>
      <Content paragraph="The Straight Line Method is used to calculate depreciation on power generation units. Under this method, the depreciable amount is calculated as the actual cost of the asset minus the salvage value, divided by the useful life of the asset. The same amount of depreciation is claimed each year over the useful life of the asset."/>
      <Bottomspace/>
      <Mainhead heading="How to calculate the written down value and depreciation in case of block of assets?" />
      <Content paragraph="To calculate the depreciation for each year, you can use the following formula: Depreciation = (Actual cost of asset - Salvage value) x Depreciation rate The written down value (WDV) of the asset is calculated as follows: WDV = Actual cost of asset - Depreciation (as per written down value method)"/>
      <Content paragraph="Here is a table summarizing the calculation of written down value and depreciation for block of assets:"/>
      <br/>

      <TableContainer component={Paper}>
                <Table  aria-label="customized table"  size='small'>
        <TableHead>
          <TableRow>
            <StyledTableCell>Particulars </StyledTableCell>
            <StyledTableCell>Amount </StyledTableCell>
           
          </TableRow>
        </TableHead>
        <TableBody>
          {rows3.map((row) => (
            <StyledTableRow
              key={row.name}
            
            >
              <StyledTableCell component="th" scope="row"
              >
                {row.Col1}
              </StyledTableCell>
              <StyledTableCell>{row.Col2}</StyledTableCell>
      
              </StyledTableRow>
          ))}
        </TableBody>
      </Table>
      </TableContainer>
      <br/>
      <Mainhead heading="Who is eligible to take advantage of the presumptive taxation scheme of section 44AD?" />
      <Content paragraph="Eligible assessees for the presumptive taxation scheme of section 44AD are resident individuals, resident Hindu Undivided Families, and resident partnership firms (excluding Limited Liability Partnership firms). Non-residents and other types of entities are not eligible to use this scheme. Additionally, individuals or entities that have made claims for deductions under sections 10A/10AA/10B/10BA or under sections 80HH to 80RRB in the relevant year are also not eligible to use this scheme."/>
      <Bottomspace/>
      <Mainhead heading="Eligibility for Presumptive Taxation Scheme under Section 44AD:" /> 
      <br/>
      <TableContainer component={Paper}>
                <Table  aria-label="customized table"  size='small'>
        <TableHead>
          <TableRow>
            <StyledTableCell>Criteria </StyledTableCell>
            <StyledTableCell>Eligibility</StyledTableCell>
           
          </TableRow>
        </TableHead>
        <TableBody>
          {rows4.map((row) => (
            <StyledTableRow
              key={row.name}
            
            >
              <StyledTableCell component="th" scope="row"
              >
                {row.Col1}
              </StyledTableCell>
              <StyledTableCell>{row.Col2}</StyledTableCell>
      
              </StyledTableRow>
          ))}
        </TableBody>
      </Table>
      </TableContainer>
      <br/>
      <br/>
      <Mainhead heading="Eligibility for Presumptive Taxation Scheme under Section 44ADA:"/>
      <TableContainer component={Paper}>
                <Table  aria-label="customized table"  size='small'>
        <TableHead>
          <TableRow>
            <StyledTableCell>Criteria </StyledTableCell>
            <StyledTableCell>Eligibility </StyledTableCell>
           
          </TableRow>
        </TableHead>
        <TableBody>
          {rows5.map((row) => (
            <StyledTableRow
              key={row.name}
            
            >
              <StyledTableCell component="th" scope="row"
              >
                {row.Col1}
              </StyledTableCell>
              <StyledTableCell>{row.Col2}</StyledTableCell>
      
              </StyledTableRow>
          ))}
        </TableBody>
      </Table>
      </TableContainer>

      <br/>
      <br/>
      <Mainhead heading="Eligibility for Presumptive Taxation Scheme under Section 44AD:"/>
      <TableContainer component={Paper}>
                <Table  aria-label="customized table"  size='small'>
        <TableHead>
          <TableRow>
            <StyledTableCell>Criteria </StyledTableCell>
            <StyledTableCell>Eligibility </StyledTableCell>
           
          </TableRow>
        </TableHead>
        <TableBody>
          {rows6.map((row) => (
            <StyledTableRow
              key={row.name}
            
            >
              <StyledTableCell component="th" scope="row"
              >
                {row.Col1}
              </StyledTableCell>
              <StyledTableCell>{row.Col2}</StyledTableCell>
      
              </StyledTableRow>
          ))}
        </TableBody>
      </Table>
      </TableContainer>
      <br/>
      <br/>
      <Mainhead heading="What is the manner of computation of taxable business income under the normal provisions of the Income-tax Law, i.e., in case of a person not adopting the presumptive taxation scheme of section 44AD?"/>
      <Content paragraph="Computation of Taxable Business Income (Normal Provisions)"/>
      <br/>
      
      <TableContainer component={Paper}>
                <Table  aria-label="customized table"  size='small'>
        <TableHead>
          <TableRow>
            <StyledTableCell>Particulars </StyledTableCell>
            <StyledTableCell>Amount </StyledTableCell>
           
          </TableRow>
        </TableHead>
        <TableBody>
          {rows7.map((row) => (
            <StyledTableRow
              key={row.name}
            
            >
              <StyledTableCell component="th" scope="row"
              >
                {row.Col1}
              </StyledTableCell>
              <StyledTableCell>{row.Col2}</StyledTableCell>
      
              </StyledTableRow>
          ))}
        </TableBody>
      </Table>
      </TableContainer>
      <br/>
      <Content paragraph="This method of computation of taxable business income is done by maintaining the books of account and the income will be computed based on the information revealed in the books of account."/>
      <br/>

      <Mainhead heading="What is the manner of computation of taxable business income in case of a person adopting the presumptive taxation scheme of section 44AD?"/>
      <Content paragraph="The manner of computation of taxable business income in case of a person adopting the presumptive taxation scheme of section 44AD is as follows:"/>
      <ul className='list'>
        <li className='bullet'>
        <span>
        <Content paragraph="Income will be computed on a presumptive basis, i.e., @ 8% of the turnover or gross receipts of the eligible business for the year."/>
        </span>
        </li>
        <li className="bullet">
          <span>
        <Content paragraph="In case of payment made through account payee cheque, demand draft, electronic clearing system or any other electronic mode as may be prescribed, income shall be calculated at a rate of 6% of the total turnover or gross receipts."/>
        </span>
        </li>
        <li className="bullet">
          <span>
        <Content paragraph="The actual income can be declared at a higher rate if it is higher than 8% of the turnover or gross receipts."/>
        </span>
        </li>
      </ul>
      <br/>
      <Mainhead heading="As per the presumptive taxation scheme of section 44AD, income of a taxpayer will be computed @ 8%/6% of the turnover or gross receipt and from such income can the taxpayer claim any further deductions?"/>
      <Content paragraph="As per the presumptive taxation scheme of section 44AD, income of a taxpayer will be computed @ 8%/6% of the turnover or gross receipt and from such income, the taxpayer cannot claim any further deductions. The income computed at the presumptive rate of 8%/6% is the final taxable income of the business covered under the presumptive taxation scheme and no further expenses will be allowed or disallowed. However, the taxpayer can claim deductions under chapter VI-A of the Income-tax Act, such as deductions for investments in specified savings, pension funds, etc."/>
      <Content paragraph="Separate deductions for depreciation are not available while computing income as per the provisions of section 44AD. However, the written down value of any asset used in such business shall be calculated as if depreciation as per section 32 is claimed and has been actually allowed"/>
      <Bottomspace/>
      <Mainhead heading="Adoption of Presumptive Taxation Scheme 44AD:"/>
      <br/>
      <TableContainer component={Paper}>
                <Table  aria-label="customized table"  size='small'>
        <TableHead>
          <TableRow>
            <StyledTableCell>Adoption of Presumptive Taxation Scheme </StyledTableCell>
            <StyledTableCell>44AD </StyledTableCell>
           
          </TableRow>
        </TableHead>
        <TableBody>
          {rows8.map((row) => (
            <StyledTableRow
              key={row.name}
            
            >
              <StyledTableCell component="th" scope="row"
              >
                {row.Col1}
              </StyledTableCell>
              <StyledTableCell>{row.Col2}</StyledTableCell>
      
              </StyledTableRow>
          ))}
        </TableBody>
      </Table>
      </TableContainer>
      <br/>
      <br/>
      <Mainhead heading="Who is eligible to take advantage of the presumptive taxation scheme of section 44ADA?"/>
      <Content paragraph="The presumptive taxation scheme of section 44ADA is available to resident individuals or HUFs who are engaged in specified professions, such as legal, medical, engineering or architectural, accountancy, technical consultancy, and interior decoration, and whose gross receipts do not exceed INR 50 Lakhs per financial year. Additionally, other professions may also be notified by the Central Board of Direct Taxes (CBDT) as eligible for this scheme."/>
      <Content paragraph="Table: Presumptive Taxation Scheme of Section 44ADA:"/>
      <Bottomspace/>
      <TableContainer component={Paper}>
                <Table  aria-label="customized table"  size='small'>
        <TableHead>
          <TableRow>
            <StyledTableCell>Eligibility </StyledTableCell>
            <StyledTableCell>Manner of computation of taxable income </StyledTableCell>
            <StyledTableCell>Allowance of further deductions </StyledTableCell>
            <StyledTableCell>Advance Tax </StyledTableCell>
            <StyledTableCell>Books of Account </StyledTableCell>
           
          </TableRow>
        </TableHead>
        <TableBody>
          {rows9.map((row) => (
            <StyledTableRow
              key={row.name}
            
            >
              <StyledTableCell component="th" scope="row"
              >
                {row.Col1}
              </StyledTableCell>
              <StyledTableCell>{row.Col2}</StyledTableCell>
              <StyledTableCell>{row.Col3}</StyledTableCell>
              <StyledTableCell>{row.Col4}</StyledTableCell>
              <StyledTableCell>{row.Col5}</StyledTableCell>
      
              </StyledTableRow>
          ))}
        </TableBody>
      </Table>
      </TableContainer>
      <br/>
      <Mainhead heading='For whom the presumptive taxation scheme of section 44AE is designed?'/>
      <Content paragraph="This scheme is designed for individuals or HUFs who own not more than 10 goods carriages and are engaged in the business of plying, hiring or leasing them. It allows them to compute their taxable income on a presumptive basis, instead of maintaining books of account and getting their accounts audited as per the normal provisions of the Income-tax Law."/>
      
      <Content paragraph="Table: Eligibility of the presumptive taxation scheme of Section 44AE"/>
      <br/>
      <TableContainer component={Paper}>
                <Table  aria-label="customized table"  size='small'>
        <TableHead>
          <TableRow>
            <StyledTableCell>Criteria </StyledTableCell>
            <StyledTableCell>Eligibility </StyledTableCell>
           
          </TableRow>
        </TableHead>
        <TableBody>
          {rows10.map((row) => (
            <StyledTableRow
              key={row.name}
            
            >
              <StyledTableCell component="th" scope="row"
              >
                {row.Col1}
              </StyledTableCell>
              <StyledTableCell>{row.Col2}</StyledTableCell>
      
              </StyledTableRow>
          ))}
        </TableBody>
      </Table>
      </TableContainer>
      <Bottomspace/>
      <Faq/>

      <Bottomspace/>
      <Bottomspace/>
      <Bottomspace/>



          </Grid>

        </Grid>

      </Container>
    </div>
    </div>
  )
}

export default Business