import React from 'react';
import { Typography,styled, useTheme,Box } from '@mui/material';
import StarIcon from '@mui/icons-material/Star';

const Staricon = styled(Typography)(({ theme }) => ({
    fontSize:'1rem',
    fontWeight:450,
     marginTop:'0.5rem',
    [theme.breakpoints.down("sm")]: {
     
      
      fontSize:'0.85rem'
         },
    [theme.breakpoints.between("md", "lg")]: {
          fontSize:'0.8rem',
         
        },
        [theme.breakpoints.up("xl")]: {
          fontSize:'1.1rem'
        }
}));

function  points(props) {
  return (
    <div>
        
       <Staricon>
           {props.points}
        </Staricon>
        
    </div>
  )
}

export default points;