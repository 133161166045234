import React from "react";
import { Box } from "@mui/material";
import Mainhead from "../../../Components/Services/MainContent/Mainhead";
import Content from "../../../Components/Services/MainContent/Content";
import CustomList from "../../../Components/Income-Tax/CustomList";
import Step from "../../../Components/Services/MainContent/Step";
import ReusableTable from "../../../Components/Income-Tax/ReusableTable";
import { capitalGainsTableData, capitalGainsRamesh } from "./TablesData";
import { Image } from "../../../Components/Income-Tax/ImageStyle";
import Image8 from "../../../Assets/IncomeTax/image8.png";

const CapitalGainsTax = () => {
  // calculation details for Mr. Suresh's tax burden
  const taxComputationTableData = [
    { Particulars: "Salary income", Rs: "8,40,000" },
    { Particulars: "Short-Term Capital Gains(*)", Rs: "2,00,000" },
    { Particulars: "Gross Total Income", Rs: "10,40,000" },
    { Particulars: "Less: Deduction", Rs: "Nil" },
    { Particulars: "Taxable Income", Rs: "10,40,000" },
    { Particulars: "Computation of Tax", Rs: "" },
    { Particulars: "Up to 2,50,000 @ nil", Rs: "Nil" },
    { Particulars: "2,50,000-5,00,000 @ 5%", Rs: "12,500" },
    { Particulars: "5,00,000-10,00,000 @ 20%", Rs: "1,00,000" },
    { Particulars: "Above 10,00,000 @ 30%", Rs: "12,000" },
    { Particulars: "Tax on total income", Rs: "1,24,500" },
    { Particulars: "Add: Health and Education cess @ 4%", Rs: "4,980" },
    { Particulars: "Total tax liability for the year", Rs: "1,29,480" },
  ];
  return (
    <Box
      style={{
        display: "flex",
        flexDirection: "column",
        gap: 4,
        marginBottom: "2rem",
      }}
    >
      {" "}
      <Mainhead heading="Taxation on Capital Gains" />
      <Step
        number={`
            Do I need to pay taxes on capital gain?
          `}
      />
      <Content paragraph="Income from capital gains refers to any profit or gain realized from the sale of a capital asset. These capital gains are subject to taxation in the year that the capital asset is transferred, known as capital gains tax." />
      <Step
        number={`
            Two types of Capital Gains
          `}
      />
      <CustomList
        points={[
          "Short-term capital gains (STCG)",
          "Long-term capital gains (LTCG)",
        ]}
      />
      <Step
        number={`
            What are tax rates under capital gain?
          `}
      />
      <Box sx={{ textAlign: "center" }}>
        <Image
          component="img"
          sx={{
            content: {
              xs: `url(${Image8})`,
            },
          }}
        />
      </Box>
      <Step
        number={`
            What are the different tax rates under STCG of Income Tax Act,1961?
          `}
      />
      <CustomList
        points={[
          "Normal STCG, i.e., STCG other than covered under section 111A: Normal slab rates",
          "STCG covered under section 111A: @ 15%",
        ]}
      />
      <Step
        number={`
            What is STCG tax rate under section 111A of Income Tax Act, 1961?
          `}
      />
      <Content paragraph="Short-Term Capital Gains (STCG) on the sale of equity shares listed on a recognized stock exchange, units of an equity-oriented mutual fund, and units of a business trust, i.e., STCG covered by section 111A." />
      <Content paragraph="Let's look at an example for better understanding of the provisions:" />
      <Content paragraph="Mr. Suresh (resident, 38 years old) is a salaried employee of AG Ltd., earning Rs. 8,40,000 per year. He bought a block of land in October 2022 for Rs. 12,00,000 and sold it in May 2023 for Rs. 14,20,000 (brokerage Rs. 20,000)." />
      <Step
        number={`We must first calculate Mr. Suresh's taxable income before calculating his tax liability. `}
      />
      <ReusableTable data={taxComputationTableData} />
      <Step number={`(*) Computation of STCG : `} />
      <ReusableTable data={capitalGainsTableData} />
      <Step
        number={`
            What are the different tax rates under LTCG of Income Tax Act,1961?
          `}
      />
      <CustomList
        points={[
          "Normal LTCG, i.e., LTCG other than covered under section 112A: @ 20%",
          "LTCG covered under section 112A: @ 10%",
        ]}
      />
      <Step
        number={`
            At what scenarios my capital gain would be taxed at 10% rate?
          `}
      />
      <Content paragraph="The benefit of charging long-term capital gains at 10% applies only in the following circumstances:" />
      <CustomList
        points={[
          "Long-term capital gains from the sale of listed securities in excess of Rs. 1,000,000 (Section 112A)",
          "Do not take advantage of indexation.",
        ]}
      />
      <Step
        number={`
            Long Term Capital Gain Resulting from a Specific Asset
          `}
      />
      <Content paragraph="A taxpayer who has long-term capital gains from the transfer of any listed asset or any unit of UTI or mutual fund (listed or not) and Zero coupon bonds has two options:" />
      <CustomList
        points={[
          "Avail of the benefit of indexation; the capital gains so computed will be charged to tax at the normal rate of 20% (plus surcharge and cess as applicable).",
          "Do not avail of the benefit of indexation, the capital gain so computed is charged to tax @ 10% (plus surcharge and cess as applicable).",
        ]}
      />
      <Content paragraph="The choice should be chosen by calculating the tax liability under both alternatives and selecting the option with the lower tax liability." />
      <Content paragraph="Consider the following example:" />
      <Content paragraph="Mr. Ramesh (a non-resident) paid Rs. 32,100 for equity shares (listed) in Reliance Ltd. in June 1998. These shares are sold for Rs. 6,00,000 (outside of a recognized stock exchange) in July 2022. In India, he has no other taxable income. What will his tax liability be?" />
      <Content
        paragraph={`The tax liability for Mr. Ramesh would be calculated as follows:`}
      />
      <ReusableTable data={capitalGainsRamesh} />
      <Content
        paragraph={`According to the foregoing computation, Mr. Ramesh should exercise option 2, because the tax liability (excluding cess as applicable) in this circumstance is Rs. 56,790, which is less than the tax liability (excluding cess as applicable) under option 1, which is Rs. 98,750.`}
      />
    </Box>
  );
};

export default CapitalGainsTax;
