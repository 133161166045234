import React from "react";
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { Divider } from "@mui/material";
import Content from "../Services/MainContent/Content";
import Mainhead from "../Services/MainContent/Mainhead";

function Faq({ data }) {
  const [expanded, setExpanded] = React.useState(false);

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  return (
    <div>
      <Mainhead heading="FAQ'S" />
      <br />
      <Divider sx={{ border: "1px solid #FF990080" }} />

      {data.map((faqItem) => (
        <div key={faqItem.id}>
          <Accordion
            expanded={expanded === faqItem.id}
            onChange={handleChange(faqItem.id)}
            elevation={0}
            sx={{ boxShadow: "unset !important" }}
            disableGutters
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls={`${faqItem.id}bh-content`}
              id={`${faqItem.id}bh-header`}
            >
              <Typography
                sx={{ color: "#281900", fontWeight: 600, fontSize: "20px" }}
              >
                {faqItem.question}
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Content paragraph={faqItem.answer} />
            </AccordionDetails>
          </Accordion>
          <Divider sx={{ border: "1px solid #FF990080" }} />
        </div>
      ))}
    </div>
  );
}

export default Faq;
