import * as React from 'react';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Divider } from '@mui/material';
import Content from '../../../../Components/Services/MainContent/Content';
import Mainhead from '../../../../Components/Services/MainContent/Mainhead';

export default function FAQ() {
  const [expanded, setExpanded] = React.useState(false);

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  return (
    <div>
      <Mainhead heading="FAQ'S"/>
      <br/>
      <Divider sx={{border: '1px solid #FF990080'}}/>
      <Accordion expanded={expanded === 'panel1'} onChange={handleChange('panel1')}  elevation={0} sx={{ boxShadow: 'unset !important' }} disableGutters>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1bh-content"
          id="panel1bh-header"
        >
          <Typography  sx={{color:'#281900',fontWeight:450,fontSize:'20px'}}>What Is StartUp India Hub </Typography>
        </AccordionSummary>
        <AccordionDetails>
         <Content paragraph ="Startup India is a scheme available for every stakeholder in the Startup network to interact amongst one another, exchange knowledge as well as create successful partnerships in a highly dynamic environment"/>
            
         
        </AccordionDetails>
      </Accordion>
      <Divider sx={{border: '1px solid #FF990080'}}/>
      <Accordion expanded={expanded === 'panel2'} onChange={handleChange('panel2')}
       elevation={0} sx={{ boxShadow: 'unset !important' }} disableGutters>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel2bh-content"
          id="panel2bh-header"
        >
          <Typography sx={{color:'#281900',fontWeight:450,fontSize:'20px'}}>
          Can a One Person Comapny (OPC) enjoy the incentives under the Startup India Initiative?
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
         <Content paragraph="Yes, One Person Company can register and enjoy the incentives under the initiative) 3. What is the timeline of receiving a Startup recognition certificate?(When an application is made along with all the pre-requisite documents then approval will be received within a period of two working days"/>
        </AccordionDetails>
      </Accordion>
      <Divider sx={{border: '1px solid #FF990080'}}/>
      <Accordion expanded={expanded === 'panel3'} onChange={handleChange('panel3')}
       elevation={0} sx={{ boxShadow: 'unset !important' }} disableGutters>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel3bh-content"
          id="panel3bh-header"
        >
          <Typography sx={{color:'#281900',fontWeight:450,fontSize:'20px'}}>
          How much does it cost to register a StartUp in india
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
         <Content paragraph="The cost of registration of a sole proprietor company is nearly Rs. 2,500 while that of a partnership firm is nearly Rs. 5,000. If you incorporate a private(LLP or LLC) company with a minimum authorised capital of Rs. 1,00,000, the registration will cost you approx Rs. 7,000"/>
        </AccordionDetails>
      </Accordion>
      <Divider sx={{border: '1px solid #FF990080'}}/>
      <Accordion expanded={expanded === 'panel4'} onChange={handleChange('panel4')}
       elevation={0} sx={{ boxShadow: 'unset !important' }} disableGutters>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel4bh-content"
          id="panel4bh-header"
        >
          <Typography sx={{color:'#281900',fontWeight:450,fontSize:'20px'}}> What is the validity of Startup Recognition Certificate</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Content paragraph="Up to 10 years from the date of incorporation/registration or valid until its turnover in any previous year does not exceed Rs. 100 crores, whichever is earlier"/>
        </AccordionDetails>
      </Accordion>
      <Divider sx={{border: '1px solid #FF990080'}}/>
      <Accordion expanded={expanded === 'panel5'} onChange={handleChange('panel5')}
       elevation={0} sx={{ boxShadow: 'unset !important' }} disableGutters>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel5bh-content"
          id="panel5bh-header"
        >
          <Typography sx={{color:'#281900',fontWeight:450,fontSize:'20px'}}> Whether the Tax Exemption is attached by default with all StartUp Recognition</Typography>
        </AccordionSummary>
        <AccordionDetails>
         <Content paragraph="No. According to the current notification dated 11.04.2018, in order to claim Tax benefit or exemption under the Income Tax Act, one is required to apply separately after getting the startup recognized"/>
          </AccordionDetails>
          </Accordion>


          <Divider sx={{border: '1px solid #FF990080'}}/>
      <Accordion expanded={expanded === 'panel6'} onChange={handleChange('panel6')}
       elevation={0} sx={{ boxShadow: 'unset !important' }} disableGutters>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel6bh-content"
          id="panel6bh-header"
        >
          <Typography sx={{color:'#281900',fontWeight:450,fontSize:'20px'}}>Who is the authority of issuing StartUp Recognition Certificate</Typography>
        </AccordionSummary>
        <AccordionDetails>
         <Content paragraph="Startup Recognition Certificate is issued by the Ministry of Commerce and Industry(Department for Promotion of Industry and Internal Trade"/>
          </AccordionDetails>
          </Accordion>

          <Divider sx={{border: '1px solid #FF990080'}}/>
      <Accordion expanded={expanded === 'panel7'} onChange={handleChange('panel7')}
       elevation={0} sx={{ boxShadow: 'unset !important' }} disableGutters>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel7bh-content"
          id="panel7bh-header"
        >
          <Typography sx={{color:'#281900',fontWeight:450,fontSize:'20px'}}> Which is Industry is best in India for StartUp?</Typography>
        </AccordionSummary>
        <AccordionDetails>
         <Content paragraph="According to the Economic Times, as of 2020, the top-earning businesses in India are from the financial services, transport and support services, aerospace, defence and security services, technology services, health and education services "/>
          </AccordionDetails>
          </Accordion>

          <Divider sx={{border: '1px solid #FF990080'}}/>
      <Accordion expanded={expanded === 'panel8'} onChange={handleChange('panel8')}
       elevation={0} sx={{ boxShadow: 'unset !important' }} disableGutters>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel8bh-content"
          id="panel8bh-header"
        >
          <Typography sx={{color:'#281900',fontWeight:450,fontSize:'20px'}}> What is the timeline of receiving a recognition certificate</Typography>
        </AccordionSummary>
        <AccordionDetails>
         <Content paragraph="When an application is made along with all the pre-requisite documents then approval will be received within a period of two working days"/>
          </AccordionDetails>
          </Accordion>

          <Divider sx={{border: '1px solid #FF990080'}}/>
      
      

         




      


      


      



    </div>
  );
}