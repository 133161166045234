import React from 'react';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Divider } from '@mui/material';
import Content from '../../../Components/Services/MainContent/Content';
import Mainhead from '../../../Components/Services/MainContent/Mainhead';


function Faq() {
const [expanded, setExpanded] = React.useState(false);

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };
  return (
    <div>
        <Mainhead heading="FAQ'S"/>
      <br/>
      <Divider sx={{border: '1px solid #FF990080'}}/>
      <Accordion expanded={expanded === 'panel1'} onChange={handleChange('panel1')}  elevation={0} sx={{ boxShadow: 'unset !important' }} disableGutters>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1bh-content"
          id="panel1bh-header"
        >
          <Typography  sx={{color:'#281900',fontWeight:450,fontSize:'20px'}}>Meaning Of Residential Status?</Typography>
        </AccordionSummary>
        <AccordionDetails>
         <Content paragraph ="The taxability of an individual in India depends upon his residential status in India for any particular financial year. An individual may be a citizen of India but may end up being a non-resident for a particular year.Similarly, a foreign citizen may end up being a resident of India for income tax purposes for a particular year."/>
            
         
        </AccordionDetails>
      </Accordion>
      <Divider sx={{border: '1px solid #FF990080'}}/>
      <Accordion expanded={expanded === 'panel2'} onChange={handleChange('panel2')}  elevation={0} sx={{ boxShadow: 'unset !important' }} disableGutters>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel2bh-content"
          id="panel2bh-header"
        >
          <Typography  sx={{color:'#281900',fontWeight:450,fontSize:'20px'}}>Who Is Deemed Resident?</Typography>
        </AccordionSummary>
        <AccordionDetails>
         <Content paragraph ="An Individual, being a citizen of India, whose total income, other than income from foreign sources, exceeds Rs.15 lakhs during the previous year and if he is not liable to pay tax in any other country, he shall be deemed to be resident in India for that previous year. (w.e.f. 1-4-2021)."/>
            
         
        </AccordionDetails>
      </Accordion>
      <Divider sx={{border: '1px solid #FF990080'}}/>
      <Accordion expanded={expanded === 'panel3'} onChange={handleChange('panel3')}  elevation={0} sx={{ boxShadow: 'unset !important' }} disableGutters>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel3bh-content"
          id="panel3bh-header"
        >
          <Typography  sx={{color:'#281900',fontWeight:450,fontSize:'20px'}}>Who Is Non-Residet in India?</Typography>
        </AccordionSummary>
        <AccordionDetails>
         <Content paragraph ="If you do not satisfy the condition laid out above– you will be considered a NON RESIDENT INDIAN."/>
            
         
        </AccordionDetails>
      </Accordion>
      <Divider sx={{border: '1px solid #FF990080'}}/>
      </div>
  )
}

export default Faq;