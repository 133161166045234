import React from "react";
import { Box } from "@mui/material";
import Mainhead from "../../../Components/Services/MainContent/Mainhead";
import Content from "../../../Components/Services/MainContent/Content";
import Step from "../../../Components/Services/MainContent/Step";
import ReusableTable from "../../../Components/Income-Tax/ReusableTable";

const CapitalAssetToStockInTrade = () => {
  return (
    <Box
      style={{
        display: "flex",
        flexDirection: "column",
        gap: 4,
        marginBottom: "2rem",
      }}
    >
      {" "}
      <Mainhead heading="Conversion of Capital Asset into Stock-in-Trade" />
      <Step
        number={`
            Conversion of Capital Asset into Stock-in-Trade
          `}
      />
      <Content paragraph="The conversion of a capital asset into stock-in-trade is treated as a transfer for income tax purposes, triggering capital gains taxation." />
      <Step
        number={`
            What Happens if Capital Asset is Converted to Stock-in-Trade?
          `}
      />
      <Content
        paragraph="When an assessee converts a capital asset into stock in trade by introducing such asset into business, it will be treated as transfer under income tax and will be subject to capital gain taxation in the following manner-
"
      />
      ⦁ Capital gain will arise where a capital asset is converted into stock in
      trade
      <br />
      ⦁ Such capital gain will become taxable in the year such stock in trade is
      sold.
      <br />
      ⦁ If only part of an asset is sold, then capital gain shall arise on a
      proportionate basis in that year.
      <ReusableTable
        columns={["Particulars", "Amount"]}
        data={[
          { Particulars: "Fair Value on Conversion (FVOC)", Amount: "XXXX" },
          { Particulars: "Cost of Acquisition", Amount: "(XXX)" },
          { Particulars: "Short-Term/Long-Term Capital Gain", Amount: "XXXX" },
        ]}
      />
      <Step
        number={`
            What Happens if Stock-in-Trade is Converted to Capital Asset?
          `}
      />
      <Content
        paragraph="When an assessee converts a stock in trade into capital asset shall be treated as a transfer and it will be subject to income tax in the following manner-

        "
      />
      ⦁ The fair market value of the stock in trade as on the date it is
      converted into capital asset will be taxable under ‘ Profit from business
      and profession’.
      <br /> ⦁ When the capital asset will be subsequently sold, the fair market
      value of stock in trade as on the date of conversion shall be taken as
      Cost of acquisition for the purpose of calculating capital gain.
      <ReusableTable
        columns={["Particulars", "Amount"]}
        data={[
          { Particulars: "Fair Value on Conversion (FMV)", Amount: "XXXX" },
          { Particulars: "Purchase Price", Amount: "(XXX)" },
          {
            Particulars: "Profit from Business and Profession (PGBP)",
            Amount: "XXXX",
          },
        ]}
      />
      <Step
        number={`
            Difference Between Capital Asset and Stock-in-Trade
          `}
      />
      <Content paragraph="The key factor that distinguishes between a capital asset and stock-in-trade is the intention of the taxpayer at the time of acquisition. If the intention is to hold the asset for investment or personal use, it is a capital asset. If the intention is to sell it in the normal course of business, it is treated as stock-in-trade." />
      <ReusableTable
        columns={["Basis", "Capital Asset", "Stock-in-Trade"]}
        data={[
          { Basis: "", "Capital Asset": "", "Stock-in-Trade": "" },
          {
            Basis: "Nature",
            "Capital Asset":
              "Held for investment, personal use, or earning rental income.",
            "Stock-in-Trade":
              "Goods held for resale in the ordinary course of business.",
          },
          {
            Basis: "Holding Period",
            "Capital Asset":
              "Holding period determines short-term or long-term classification.",
            "Stock-in-Trade": "Holding period is less relevant.",
          },
          {
            Basis: "Taxation on Transfer",
            "Capital Asset":
              "May give rise to capital gains taxed at specific rates.",
            "Stock-in-Trade":
              "Profits treated as business income, taxed at applicable slab rates.",
          },
        ]}
      />
    </Box>
  );
};

export default CapitalAssetToStockInTrade;
