import React from 'react';
import {
    Container,
    Grid,
    useTheme,
    useMediaQuery,
    styled,
    Typography,
    Box
} from '@mui/material'
import Man from "../../../Assets/Home/Common/Image2.png";
import Heading from "../../../Components/CommonCss/Home/Heading"
import DivGap from "../../../Components/CommonCss/Home/DivGap"
import Background1 from "../../../Assets/Home/Common/Background.png";
import PersonAddAltIcon from '@mui/icons-material/PersonAddAlt';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import TextSnippetOutlinedIcon from '@mui/icons-material/TextSnippetOutlined';
import CurrencyRupeeOutlinedIcon from '@mui/icons-material/CurrencyRupeeOutlined';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import GroupsOutlinedIcon from '@mui/icons-material/GroupsOutlined';

const Standus = () => {
    const Iconhead = styled(Typography)(({ theme }) => ({
        color: '#281900',
        fontWeight: 600,
        textAlign: 'center',
        fontStyle: 'normal',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        [theme.breakpoints.up("lg")]: {
            fontSize: '1.1rem',
            lineheight: '1.75rem',
            textAlign: 'center',
            marginBottom: '1rem',
            margintop: '1rem'

        },
        [theme.breakpoints.up("xl")]: {
            fontSize: '1.45rem',
            lineheight: '1.75rem',
            textAlign: 'center',
            marginBottom: '5.5rem',
            margintop: '2rem'

        },
        [theme.breakpoints.between("md", "lg")]: {
            fontSize: '0.785rem',
            lineheight: '1.8rem',
            textAlign: 'center',
            marginBottom: '1.5rem',
            margintop: '3rem'
        },

        [theme.breakpoints.down("md")]: {
            fontSize: '0.58rem',
            lineheight: '1rem',
            textAlign: 'center',
            marginBottom: '2rem',
            margintop: '1.8rem'
        },

        [theme.breakpoints.down("sm")]: {
            fontSize: '0.4rem',
            lineheight: '0.75rem',
            textAlign: 'center',
            marginBottom: '1.5rem',
            margintop: '2rem'
        },

    }));



    const Image = styled(Box)(({ theme }) => ({

        [theme.breakpoints.up("lg")]: {
            width: '26.8rem',
            height: '32.5rem'
        },
        [theme.breakpoints.up("xl")]: {
            width: '36rem',
            height: '48rem'
        },
        [theme.breakpoints.between("md", "lg")]: {
            width: '20.8rem',
            height: '24.5rem'
        },
        [theme.breakpoints.down("md")]: {
            width: '15rem',
            height: '20.5rem'
        },
        [theme.breakpoints.down("sm")]: {
            width: '10rem',
            height: '16.5rem'
        },

    }));

    const Background = styled(Box)(({ theme }) => ({

        width: '100%',
        height: '100%',
        backgroundColor: 'rgb(250, 250, 250)',

    }));


    const theme = useTheme();
    const isTablet = useMediaQuery(theme.breakpoints.down("md"));
    const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
    const isTab = useMediaQuery(theme.breakpoints.down("md"));
    const isRange = useMediaQuery(theme.breakpoints.between("md", "lg"));
    const isRange2 = useMediaQuery(theme.breakpoints.between("lg", "xl"));
    return (
        <>
        <Background>
            <br/>
        <div className='Container'>
            <Container>
                <Heading mainhead = {["What Make Us Stand",<br /> ,"Out From The Rest?"]}/>
                {isMobile || isTab || isRange ? (
                    <Grid container spacing={5}
                    >
                        <Grid item xs={7} md={7}>
                            <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                                <div style=
                                    {{

                                        backgroundImage: `url(${Background1})`,
                                        backgroundRepeat: 'no-repeat',
                                        backgroundSize: '100% 99%'
                                    }
                                    }
                                >
                                    <Image component="img"
                                        sx={{
                                            content: {
                                                xs: `url(${Man})`
                                            },

                                        }}>

                                    </Image>
                                </div>

                            </Box>


                        </Grid>
                        <Grid item xs={2} sm={2} md={2}>
                            <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                                <PersonAddAltIcon fontSize='large'
                                    sx={
                                        !(isMobile || isTablet) && !isRange
                                            ? {
                                                
                                            }
                                            : isRange
                                                ? {
                                                    background: '#F19E1E',
                                                    borderRadius: '50%',
                                                    padding: '12px',
                                                    color: 'white',
                                                    fontSize: "45px"
                                                }
                                                : {
                                                    background: '#F19E1E',
                                                    borderRadius: '50%',
                                                    padding: '10px',
                                                    color: 'white',
                                                    fontSize: "20px",
                                                    marginTop:'0.4rem'
                                                }
                                    }
                                />
                                <Iconhead>Dedicated<br/> Admin<br/> Manager</Iconhead>
                                <TextSnippetOutlinedIcon fontSize='large'
                                    sx={
                                        !(isMobile || isTablet) && !isRange
                                            ? {}
                                            : isRange
                                                ? {
                                                    background: '#FFC700',
                                                    borderRadius: '50%',
                                                    padding: '12px',
                                                    color: 'white',
                                                    fontSize: "45px"
                                                }
                                                : {
                                                    background: '#FFC700',
                                                    borderRadius: '50%',
                                                    padding: '10px',
                                                    color: 'white',
                                                    fontSize: "20px",
                                                    marginTop:'0.7rem'
                                                }
                                    } />
                                <Iconhead>Organized<br/> Documentation</Iconhead>
                                <LockOutlinedIcon fontSize='large'
                                    sx={
                                        !(isMobile || isTablet) && !isRange
                                            ? {}
                                            : isRange
                                                ? {
                                                    background: '#AECA00',
                                                    borderRadius: '50%',
                                                    padding: '12px',
                                                    color: 'white',
                                                    fontSize: "45px"
                                                }
                                                : {
                                                    background: '#AECA00',
                                                    borderRadius: '50%',
                                                    padding: '10px',
                                                    color: 'white',
                                                    fontSize: "20px",
                                                    //marginTop:'0.5rem'
                                                }
                                    }
                                />
                                <Iconhead>100% <br/>Privacy<br/> Guaranteed</Iconhead>
                            </Box>
                        </Grid>

                        <Grid item xs={3} sm={3} md={3}>
                            <Box sx={{
                                display: 'flex', flexDirection: 'column', alignItems: 'center',
                            }}>
                                <AccessTimeIcon fontSize='large'

                                    sx={
                                        !(isMobile || isTablet) && !isRange
                                            ? {}
                                            : isRange
                                                ? {
                                                    background: '#E32227',
                                                    borderRadius: '50%',
                                                    padding: '12px',
                                                    color: 'white',
                                                    fontSize: "45px"
                                                }
                                                : {
                                                    background: '#E32227',
                                                    borderRadius: '50%',
                                                    padding: '10px',
                                                    color: 'white',
                                                    fontSize: "20px",
                                                   marginTop:'0.4rem'
                                                }
                                    } />
                                <Iconhead>Dedicated<br/> Support<br/> & Customer<br/> Care</Iconhead>
                                <CurrencyRupeeOutlinedIcon fontSize='large'
                                    sx={
                                        !(isMobile || isTablet) && !isRange
                                            ? {}
                                            : isRange
                                                ? {
                                                    background: '#0085FF',
                                                    borderRadius: '50%',
                                                    padding: '12px',
                                                    color: 'white',
                                                    fontSize: "45px"
                                                }
                                                : {
                                                    background: '#0085FF',
                                                    borderRadius: '50%',
                                                    padding: '10px',
                                                    color: 'white',
                                                    fontSize: "20px",
                                                    marginTop:'0.2rem'
                                                }
                                    } />
                                <Iconhead>Affordable<br/>Prices</Iconhead>
                                <GroupsOutlinedIcon fontSize='large'
                                    sx={
                                        !(isMobile || isTablet) && !isRange
                                            ? {}
                                            : isRange
                                                ? {
                                                    background: '#2F1EF1',
                                                    borderRadius: '50%',
                                                    padding: '12px',
                                                    color: 'white',
                                                    fontSize: "45px"
                                                }
                                                : {
                                                    background: '#2F1EF1',
                                                    borderRadius: '50%',
                                                    padding: '10px',
                                                    color: 'white',
                                                    fontSize: "20px",
                                                    //marginTop:'0.5rem'
                                                
                                                }
                                    } />
                                <Iconhead>Team Of<br/> Experts On<br/> Boards</Iconhead>

                            </Box>

                        </Grid>

                    </Grid>
                ) : (
                    <>
                        <Grid container>
                            <Grid item xs={3}>
                                <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', marginTop: '60px' }}>
                                    <PersonAddAltIcon fontSize='large'
                                        sx={{
                                            background: '#F19E1E',
                                            borderRadius: '50%',
                                            padding: '15px',
                                            color: 'white',
                                            fontSize: "50px",
                                            marginBottom: '20px'
                                        }} />
                                    <Iconhead>Dedicated <br/>Admin<br/> Manager</Iconhead>
                                    <TextSnippetOutlinedIcon fontSize='large'
                                        sx={{
                                            background: '#FFC700',
                                            borderRadius: '50%',
                                            padding: '15px',
                                            color: 'white',
                                            fontSize: "50px",
                                            marginBottom: '20px'
                                        }} />
                                    <Iconhead>Organized <br/>Documentation</Iconhead>

                                    <LockOutlinedIcon fontSize='large' sx={{
                                        background: '#AECA00', borderRadius: '50%',
                                        padding: '15px',
                                        color: 'white',
                                        fontSize: "50px",
                                        marginBottom: '20px'
                                    }} />
                                    <Iconhead>100% Privacy<br/> Guaranteed</Iconhead>
                                </Box>

                            </Grid>
                            <Grid item xs={6}>
                                <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                                    <div style=
                                        {{

                                            backgroundImage: `url(${Background1})`,
                                            backgroundRepeat: 'no-repeat',
                                            backgroundSize: '100% 100%',
                                            marginTop: '50px',

                                        }
                                        }
                                    >
                                        <Image component="img"
                                            sx={{
                                                content: {
                                                    xs: `url(${Man})`
                                                },
                                            }}>

                                        </Image>
                                    </div>

                                </Box>
                            </Grid>

                            <Grid item xs={3}>
                                <Box sx={{
                                    display: 'flex', flexDirection: 'column', alignItems: 'center',
                                    marginTop: '60px'
                                }}>
                                    <AccessTimeIcon fontSize='large'
                                        sx={{
                                            background: '#E32227',
                                            borderRadius: '50%',
                                            padding: '15px',
                                            color: 'white',
                                            fontSize: "50px",
                                            marginBottom: '20px'
                                        }} />
                                    <Iconhead>Dedicated Support<br/> & Customer<br/> Care</Iconhead>
                                    <CurrencyRupeeOutlinedIcon fontSize='large'
                                        sx={{
                                            background: '#0085FF',
                                            borderRadius: '50%',
                                            padding: '15px',
                                            color: 'white',
                                            fontSize: "50px",
                                            marginBottom: '20px'
                                        }} />
                                    <Iconhead>Affordable<br /> Prices</Iconhead>
                                    <GroupsOutlinedIcon fontSize='large'
                                        sx={{
                                            background: '#2F1EF1',
                                            borderRadius: '50%',
                                            padding: '15px',
                                            color: 'white',
                                            fontSize: "50px",
                                            marginBottom: '20px'
                                        }} />
                                    <Iconhead>Team Of Experts <br /> On Boards</Iconhead>

                                </Box>

                            </Grid>

                        </Grid>

                    </>

                )}

            </Container>
        </div>
        <DivGap spacing=""/>
        </Background>
        </>
    )
}

export default Standus