import React from 'react';
import Mainhead from '../../../../Components/Services/MainContent/Mainhead';
import Content from '../../../../Components/Services/MainContent/Content';
import List from '../../../../Components/Services/MainContent/List';
import Step from '../../../../Components/Services/MainContent/Step';
import { Box , styled, Typography} from '@mui/material';

function WhyLegalFactory(){
return(
    <div>
        <Mainhead heading="Why Legal Factory ?"/>
        <Content paragraph="LEGAL FACTORY is cognizant of the new process. Our team is experienced like old wine and we have helped many start ups, traders, manufacturers, entrepreneurs, financial advisories, ad agencies, professionals register their businesses in India. Entire India , our services are comprehensive and reach every corner of the country . With Legal factory and Xpert finance setting up business is an easy , 100% reliable, secure, fast process . When you choose us to be your partner to register your business , you get to avail a few undeniable benefits and in case of amendments or changes from the government/ regulator our experienced Dedicated compliance team takes care of them for you. We also provide assistance with:"/>
         <ul className='list'>
            <li className='bullet'>
                <span>
        <List points="A first free consultation, followed by subsequent meetings to clear every concern you may face."/>
        </span>
        </li>
        <li className='bullet'>
                <span>
        <List points="Complete support on opening a Current Bank Account with partner banks."/>
        </span>
        </li>
        <li className='bullet'>
                <span>
        <List points="Comprehensive and on-time updates on ROC compliances."/>
        </span>
        </li>
        <li className='bullet'>
                <span>
        <List points="Purchase of Online Accounting Software or exclusive account manager for handling accounts ."/>
        </span>
        </li>
        <li className='bullet'>
                <span>
        <List points="A Master file that contains all the documents needed to file the incorporation."/>
        </span>
        </li>
        <li className='bullet'>
                <span>
        <List points="A Dedicated Admin manager is present at all times to ensure quality of service and maintenance of timeliness and schedules."/>
        </span>
        </li>
        </ul>
    </div>
)
}

export default WhyLegalFactory