import React from 'react';
import { Typography,styled, useTheme } from '@mui/material';

const Serviceheading = styled(Typography)(({ theme }) => ({
    color:'#281900',
    fontSize:'1.6rem',
    fontWeight:700,
    lineHeight:'2.205rem',
    [theme.breakpoints.down("sm")]: {
      fontSize:'1.1rem',
      marginLeft:'0.8rem'
         },
         [theme.breakpoints.between("md", "lg")]: {
          fontSize:'0.9rem'
        },

        [theme.breakpoints.up("xl")]: {
          fontSize:'1.5rem'
        }
}));

function heading(props) {
  return (
    <div>
        <Serviceheading>
          {props.heading}
        </Serviceheading>
    </div>
  )
}

export default heading;