import React from 'react'
import Mainhead from '../../../../Components/Services/MainContent/Mainhead';
import Content from '../../../../Components/Services/MainContent/Content';
import List from '../../../../Components/Services/MainContent/List';
import { Box , styled} from '@mui/material';
import Step from '../../../../Components/Services/MainContent/Step';


function Register(){
    return(
        <div>
            <Mainhead heading="Procedure to file for a Nidhi Company"/>

            <Step number="Step - 1: Name Reservation"/>
            <Content paragraph="We help you reserve your name with MCA."/>

            <Step number="Step -2 : DSC and DINs"/>
            <Content paragraph="We help you get 1 DSC and 3 DINs."/>

            <Step number="Step -3 : Documents and approval"/>
            <Content paragraph="We help you at every stage of the documentation process and in getting in-principle approval from the RBI."/>
            
        </div>
    )

}
export default Register