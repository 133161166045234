import React from 'react'
import Mainhead from '../../../../Components/Services/MainContent/Mainhead';
import Content from '../../../../Components/Services/MainContent/Content';
import List from '../../../../Components/Services/MainContent/List';
import { Box , styled} from '@mui/material';
import Step from '../../../../Components/Services/MainContent/Step';

function Documents(){
    return(
        <div>
            <Mainhead heading="Basic Documents Required for LLP"/>
            <Step number="A. Documents of Partners"/>
            <ul className='list'>
            <li className='bullet'>
                <span>
            <List points="PAN Card/ ID Proof of Partners"/>
            </span>
            </li>
            <li className='bullet'>
                <span>
            <List points="Address Proof of Partners – Partner can submit any one document out of Voter’s ID, Passport, Driver’s license or Aadhar Card"/>
            </span>
            </li>
            <li className='bullet'>
                <span>
            <List points="Residence Proof of Partners – Latest bank statement, mobile bill, electricity bill or gas bill, etc can be submitted as proof"/>
            </span>
            </li>
            <li className='bullet'>
                <span>
            <List points="Photograph"/>
            </span>
            </li>
            <li className='bullet'>
                <span>
            <List points="Passport (in case of Foreign Nationals/ NRIs) –
            Foreign nationals or NRIs have to submit proof of address also which will be a driving license, bank statement, residence card or any government-issued identity proof containing the address. If the documents are in other than the English language, a notarized or apostilled translation copy will also be attached

            "/>
            </span>
            </li>
            </ul>
            <Step number="B. Documents of LLP"/>
            <ul className='list'>
                <li className='bullet'>
                    <span>
            <List points="Proof of Registered Office Address: Proof of registered office has to be submitted during registration or within 30 days of its incorporation."/>
            </span>
            </li>
            <li className='bullet'>
                    <span>
                <List points="If the registered office is taken on rent, a rent agreement and a no-objection certificate from the landlord has to be submitted."/>
                </span>
                </li>
                <li className='bullet'>
                    <span>
                <List points="Besides, any one document out of utility bills like gas, electricity, or telephone bills must be submitted"/>
                </span>
                </li>
                <li className='bullet'>
                    <span>
            <List points="Digital Signature Certificate: One of the designated partners needs to opt for a digital signature certificate also since all documents and applications will be digitally signed by the authorized signatory"/>
            </span>
            </li>
            </ul>
        </div>
    )
}
export default Documents