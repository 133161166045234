import React from "react";
import { Box } from "@mui/material";
import Mainhead from "../../../Components/Services/MainContent/Mainhead";
import Content from "../../../Components/Services/MainContent/Content";
import ReusableTable from "../../../Components/Income-Tax/ReusableTable";
import { Image } from "../../../Components/Income-Tax/ImageStyle";
import Image1 from "../../../Assets/IncomeTax/salary/image1.png";

const ArrearsOfSalary = () => {
  const arrearsCalculationData = [
    {
      Step: "1",
      Description:
        "Tax on Rs. 16,40,000 (Rs. 14,00,000 + Rs. 2,20,000) = Rs. 2,77,000 (After deducting the standard deduction of Rs. 50,000) (AY 2023-24)",
    },
    {
      Step: "2",
      Description:
        "Tax on Rs. 14,00,000 = Rs. 2,05,000 (After deducting the standard deduction of Rs. 50,000) (AY 2023-24)",
    },
    {
      Step: "3",
      Description:
        "Difference between Step:1 and Step:2 ; Rs. 2,77,000 – Rs. 2,05,000 = Rs. 72,000",
    },
    {
      Step: "4",
      Description:
        "Tax on Rs. 12,00,000 (Rs. 9,80,000 + Rs. 2,20,000)) = Rs. 1,57,500 (After deducting standard deduction of Rs. 50,000) (AY 2022-23)",
    },
    {
      Step: "5",
      Description:
        "Tax on Rs. 9,80,000 = Rs. 98,500 (After deducting standard deduction of Rs. 50,000) (AY 2022-23)",
    },
    {
      Step: "6",
      Description:
        "Difference between Step:4 and Step:5 ; Rs. 1,57,500 – Rs. 98,500 = Rs. 59,000",
    },
    {
      Step: "7",
      Description:
        "Difference between Step:5 and Step:6 ; Rs. 72,000- Rs. 59,000 = Rs. 13,000",
    },
  ];

  return (
    <Box
      style={{
        display: "flex",
        flexDirection: "column",
        gap: 4,
        marginBottom: "2rem",
      }}
    >
      <Mainhead heading="Arrears of Salary in India" />

      <Content paragraph="The word 'arrears of salary' refers to the unpaid salary from the previous year that is received during the current year. In simple terms, it is the salary that was not received or was not yet received for previous years but is now received. This may result from non-payment or retrospective salary adjustments." />
      <Content paragraph="The employer mentions the arrears separately in salary slips and also in form 16." />

      <Content paragraph="Now the question arises: How does receiving salary in arrears affect taxability? Such an amount would be taxable upon receipt, meaning when you get your outstanding salary, you will have to pay tax for it. However, if this results in higher taxes in a higher tax bracket, relief can be claimed under section 89(1) of the Income Tax Act." />

      <Content paragraph="Section 89(1) of the Income Tax Act 1961 in India states that an employee can file Form 10E to avoid increased tax liability arising from non-payment of dues. If you receive salary arrears and have to pay higher taxes in a higher slab rate because you received that salary that you should have received earlier, the act provides relief under the aforementioned section by filing Form 10 E, which can be filed online on the income tax portal." />

      <Content paragraph="Formula to Calculate Relief under Section 89(1) of Income Tax Act 1961" />
      <Box sx={{ textAlign: "center" }}>
        <Image
          component="img"
          sx={{
            content: {
              xs: `url(${Image1})`,
            },
          }}
        />
      </Box>
      <ReusableTable data={arrearsCalculationData} />

      <Content paragraph="This is a detailed explanation of the calculation of relief under section 89(1). Let's explain it with an inclusive example:" />
      <ReusableTable data={arrearsCalculationData} />
      <Content paragraph="Hence Rs. 13,000 can be claimed as relief under Section 89 (1)." />
    </Box>
  );
};

export default ArrearsOfSalary;
