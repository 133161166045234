import React from 'react';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Divider } from '@mui/material';
import Content from '../../../Components/Services/MainContent/Content';
import Mainhead from '../../../Components/Services/MainContent/Mainhead';


function Faq() {
const [expanded, setExpanded] = React.useState(false);

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };
  return (
    <div>
        <Mainhead heading="FAQ'S"/>
      <br/>
      <Divider sx={{border: '1px solid #FF990080'}}/>
      <Accordion expanded={expanded === 'panel1'} onChange={handleChange('panel1')}  elevation={0} sx={{ boxShadow: 'unset !important' }} disableGutters>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1bh-content"
          id="panel1bh-header"
        >
          <Typography  sx={{color:'#281900',fontWeight:450,fontSize:'20px'}}>I  have availed a loan from my RELATIVE for pursuing higher education. Can I claim the interest paid on such a loan as a deduction under Section 80E?</Typography>
        </AccordionSummary>
        <AccordionDetails>
         <Content paragraph ="A deduction of interest paid on an education loan under Section 80E can be made only if the loan has been availed from a financial institution for pursuing higher education. Therefore, availing a loan from your RELATIVE will not entitle you to claim the interest under Section 80E."/>
            
         
        </AccordionDetails>
      </Accordion>
      <Divider sx={{border: '1px solid #FF990080'}}/>
      <Accordion expanded={expanded === 'panel2'} onChange={handleChange('panel2')}  elevation={0} sx={{ boxShadow: 'unset !important' }} disableGutters>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel2bh-content"
          id="panel2bh-header"
        >
          <Typography  sx={{color:'#281900',fontWeight:450,fontSize:'20px'}}>Can a company or a firm take benefit of Section 80C?</Typography>
        </AccordionSummary>
        <AccordionDetails>
         <Content paragraph ="The provisions of Section 80C apply only to individuals or a Hindu Undivided Family (HUF). Hence, a company or a firm cannot take benefit of Section 80C."/>
            
         
        </AccordionDetails>
      </Accordion>
      <Divider sx={{border: '1px solid #FF990080'}}/>
      <Accordion expanded={expanded === 'panel3'} onChange={handleChange('panel3')}  elevation={0} sx={{ boxShadow: 'unset !important' }} disableGutters>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel3bh-content"
          id="panel3bh-header"
        >
          <Typography  sx={{color:'#281900',fontWeight:450,fontSize:'20px'}}>Can payments made towards premium to a private life insurer company be claimed under section 80C ?
</Typography>
        </AccordionSummary>
        <AccordionDetails>
         <Content paragraph ="Deduction under Section 80C is available in respect of life insurance premiums paid to any insurer approved by the Insurance Regulatory and Development Authority of India, whether public or private. Hence, the insurance premium you are paying will also help you claim an 80C deduction."/>
        </AccordionDetails>
      </Accordion>
      <Divider sx={{border: '1px solid #FF990080'}}/>
    </div>
  )
}

export default Faq;