import React from 'react'
import Mainhead from '../../../../Components/Services/MainContent/Mainhead';
import Content from '../../../../Components/Services/MainContent/Content';
import List from '../../../../Components/Services/MainContent/List';
import { Box , styled} from '@mui/material';
import Step from '../../../../Components/Services/MainContent/Step';

function Documents(){
    return(
        <div>
            <Mainhead heading="A Few things to Remember "/>
            <br/>
            <Content paragraph="The name should be simple, unique, pin-pointed i.e. descriptive to the brand, ‘Nidhi Limited’ should come as the suffix, and it must not offend anyone, any group, creed, or people. There are a few forms to be filled-"/>
            <ul className="list">
                <li className="bullet">
                    <span>
            <List points="INC9-To be filed by all the subscribers to MoA"/>
            </span>
            </li>
            <li className="bullet">
                    <span>
            <List points="DIR 2-To be filed by all the directors of the company also to be signed by all the subscribers, deceleration as per rule 5 & 6 of Nidhi rules 2014-

"/>
</span>
</li>
<li className="bullet">
                    <span>
            <List points="The Need For Digital Signature"/>
            </span>
            </li>
            <li className="bullet">
                    <span>
            <List points="Consent On Name"/>
            </span>
            </li>
            <li className="bullet">
                    <span>
            <List points="Articles Of Association And Memorandum Of Association(Final Incorporation)"/>
            </span>
            </li>
            <li className="bullet">
                    <span>
            <List points="PAN and TAN & Opening Of Your Bank Account"/>
            </span>
            </li>
            </ul>
        </div>
    )
}
export default Documents