import React from 'react';
import Mainhead from '../../../../Components/Services/MainContent/Mainhead';
import Content from '../../../../Components/Services/MainContent/Content';
import Step from '../../../../Components/Services/MainContent/Step';
import { Box , styled} from '@mui/material';
import PCtypes from '../../../../Assets/Services/ProducerCompany/LegalFactory_Producer1.png';

const Types = styled(Box)(({ theme }) => ({
  width:'28,5rem',
  height:'32rem',
  marginTop:'0.8rem',
  [theme.breakpoints.down("sm")]: {
    width:'18rem',
  height:'21.5rem',
  },
  [theme.breakpoints.down("md")]: {
    width:'18rem',
  height:'21.5rem',
  },
  [theme.breakpoints.between("md", "lg")]: {
    width:'25rem'
  }
}));

function Categories() {
  return (
    <div>
      <Mainhead heading="Categories of Producer Company"/>

      <Step number="PRODUCTION BUSINESSES:"/>
      <Content paragraph="The main functions of producer companies comprises production, procurement or manufacture of any primary produce for its members(for further sale) and to others."/>

      <Step number="MARKETING BUSINESSES:"/>
      <Content paragraph="Even a business involved in the marketing or promotion of primary produce or provision of educational services to members and others can constitute itself as a producer company."/>

      <Step number="TECHNICAL SERVICE BUSINESSES:"/>
      <Content paragraph="Any business offering technical assistance to producers, providing training & educational services or conducting research and development can register as a producer company."/>

      <Step number="FINANCING BUSINESSES:"/>
      <Content paragraph="Businesses that finance activities within the producer section, whether it’s the assembly , marketing or development domain, is eligible to register itself as a producer company."/>

      <Step number="INFRASTRUCTURE BUSINESSES:"/>
      <Content paragraph="Businesses that give infrastructure to the producers like electricity, water resources or land utilization along with irrigation techniques or consultations can register themselves as a producer company
      "/>
      <br/>

    <Box sx={{textAlign:'center'}}>
      <Types component="img"
                sx={{
                  content: {
                    xs: `url(${PCtypes})`
                  }
                }}/>
                </Box>

      
    </div>
  )
}

export default Categories