import { Typography, ListItemText, styled, Button } from "@mui/material";

export const ItemHead = styled(Typography)(({ theme }) => ({
  justifyContent: "left",
  marginTop: "10px",
  marginLeft: "14px",
  fontWeight: 600,
  color: theme.palette.secondary.main,
}));

//Menu SubLinks Item list
export const SubLinks = styled(ListItemText)(({ theme }) => ({
  fontWeight: 450,
  ":hover": { color: theme.palette.primary.main },
  marginBottom: "0.3rem",
  fontFamily: "Sora",
  [theme.breakpoints.up("xl")]: {
    fontSize: "1.3rem",
  },
  [theme.breakpoints.up("lg")]: {
    fontSize: "0.97rem",
  },
  [theme.breakpoints.down("md")]: {
    fontSize: "0.78rem",
  },
}));

export const MenuLinks = styled(Button)(({ theme }) => ({
  color: "#323C40",
  backgroundColor: "transparent",
  border: "none",
  padding: "10px",
  fontSize: "1rem",
  fontWeight: "bold",
  textTransform: "capitalize",
  outline: "none",
  boxShadow: "none",
  "&:hover": {
    backgroundColor: "transparent",
  },
}));
