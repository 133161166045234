import React from 'react';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Divider, Box, styled } from '@mui/material';
import Content from '../../../Components/Services/MainContent/Content';
import Mainhead from '../../../Components/Services/MainContent/Mainhead';

import F1 from "../../../Assets/TaxPlanning/Procedure/F1.png";
import F2 from "../../../Assets/TaxPlanning/Procedure/F2.jpg";
import F3 from "../../../Assets/TaxPlanning/Procedure/F3.jpg";
import F4 from "../../../Assets/TaxPlanning/Procedure/F4.jpg";
import F5 from "../../../Assets/TaxPlanning/Procedure/F5.jpg";




function Faq() {
  const Image  = styled(Box)(({ theme }) => ({
    marginTop:'30px',
    width:'95%',
    marginBottom:'3rem',
    justifyContent:'center',
    [theme.breakpoints.down("md")]: {
      marginTop:'20px',
      width:'100%',
    }
  }));
const [expanded, setExpanded] = React.useState(false);

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };
  return (
    <div>
        <Mainhead heading="FAQ'S"/>
      <br/>
      <Divider sx={{border: '1px solid #FF990080'}}/>
      <Accordion expanded={expanded === 'panel1'} onChange={handleChange('panel1')}  elevation={0} sx={{ boxShadow: 'unset !important' }} disableGutters>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1bh-content"
          id="panel1bh-header"
        >
          <Typography  sx={{color:'#281900',fontWeight:450,fontSize:'20px'}}>Whether the premium paid by the employer for employee health insurance is allowed as a deduction from PGBP?</Typography>
        </AccordionSummary>
        <AccordionDetails>
         <Content paragraph ="The premium paid by an employer for employee health insurance is allowed as a deduction from PGBP (Profit and Gains of Business or Profession), only if it is paid by any mode other than cash and the health insurance of the employee is in accordance with a scheme framed by The General Insurance Corporation of India and approved by the Central Government or any other insurer approved by IRDA (Insurance Regulatory and Development Authority)."/>
            
         
        </AccordionDetails>
      </Accordion>
      <Divider sx={{border: '1px solid #FF990080'}}/>
      <Accordion expanded={expanded === 'panel2'} onChange={handleChange('panel2')}  elevation={0} sx={{ boxShadow: 'unset !important' }} disableGutters>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel2bh-content"
          id="panel2bh-header"
        >
          <Typography  sx={{color:'#281900',fontWeight:450,fontSize:'20px'}}>Bonus and commission And borrowed capital</Typography>
        </AccordionSummary>
        <AccordionDetails>
         <Content paragraph ="Bonus and commission, as well as interest on borrowed capital, are allowed as deductions from PGBP (Profit and Gains of Business or Profession), provided that the interest on borrowed capital is only allowed as a deduction if the capital is borrowed for the purpose of business activities."/>
            
         
        </AccordionDetails>
      </Accordion>
      <Divider sx={{border: '1px solid #FF990080'}}/>
      <Accordion expanded={expanded === 'panel3'} onChange={handleChange('panel3')}  elevation={0} sx={{ boxShadow: 'unset !important' }} disableGutters>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel3bh-content"
          id="panel3bh-header"
        >
          <Typography  sx={{color:'#281900',fontWeight:450,fontSize:'20px'}}>Whether contribution to provident fund and other funds allowed? </Typography>
        </AccordionSummary>
        <AccordionDetails>
         <Content paragraph ="Contributions made by an employer towards the recognized provident fund, superannuation fund and gratuity funds of its employees are eligible for deductions from PGBP (Profit and Gains of Business or Profession) as long as the gratuity fund is established under a trust, the contributions are made on a periodic basis and not as a one-time payment, and the fund is exclusively meant for the benefit of the employees"/>
            
         
        </AccordionDetails>
      </Accordion>
      <Divider sx={{border: '1px solid #FF990080'}}/>
      <Accordion expanded={expanded === 'panel4'} onChange={handleChange('panel4')}  elevation={0} sx={{ boxShadow: 'unset !important' }} disableGutters>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel4bh-content"
          id="panel4bh-header"
        >
          <Typography  sx={{color:'#281900',fontWeight:450,fontSize:'20px'}}>Are Bad Debts allowable?.</Typography>
        </AccordionSummary>
        <AccordionDetails>
         <Content paragraph ="Bad debts incurred by a business can be considered as an allowable deduction from PGBP (Profit and Gains of Business or Profession) as long as the debt is related to the business carried out by the assessee during the previous year and it was taken into account when computing the income of the assessee in the year it was written off."/>
            
         
        </AccordionDetails>
      </Accordion>
      <Divider sx={{border: '1px solid #FF990080'}}/>
      <Accordion expanded={expanded === 'panel5'} onChange={handleChange('panel5')}  elevation={0} sx={{ boxShadow: 'unset !important' }} disableGutters>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel5bh-content"
          id="panel5bh-header"
        >
          <Typography  sx={{color:'#281900',fontWeight:450,fontSize:'20px'}}>What is the limit of remuneration to partners as per income tax?</Typography>
        </AccordionSummary>
        <AccordionDetails>
         <Content paragraph ="The amount of remuneration paid to partners as per income tax is limited by the conditions set in the partnership deed. The first RS. 300000/- of the book profit or in case of loss: RS. 150000/- or 90% of the book profit whichever is higher can be paid as remuneration. For the remaining book profit, only 60% can be paid as remuneration."/>
            
         
        </AccordionDetails>
      </Accordion>
      <Divider sx={{border: '1px solid #FF990080'}}/>
      <Accordion expanded={expanded === 'panel6'} onChange={handleChange('panel6')}  elevation={0} sx={{ boxShadow: 'unset !important' }} disableGutters>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel6bh-content"
          id="panel6bh-header"
        >
          <Typography  sx={{color:'#281900',fontWeight:450,fontSize:'20px'}}>Which deductions are only allowable on the actual payment?</Typography>
        </AccordionSummary>
        <AccordionDetails>
         <Content paragraph ="Certain deductions, such as taxes, duties, cess, fees, contributions to employee welfare funds, bonus and commission payments, and interest on loans and borrowings from certain institutions, are only allowed as deductions when they are actually paid. These deductions cannot be claimed in advance or on an accrual basis."/>
            
         
        </AccordionDetails>
      </Accordion>
      <Divider sx={{border: '1px solid #FF990080'}}/>

      <Accordion expanded={expanded === 'panel7'} onChange={handleChange('panel7')}  elevation={0} sx={{ boxShadow: 'unset !important' }} disableGutters>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel7bh-content"
          id="panel7bh-header"
        >
          <Typography  sx={{color:'#281900',fontWeight:450,fontSize:'20px'}}>What is the threshold limit of getting mandatory tax audit of the accounts of a business not opting for presumptive income?</Typography>
        </AccordionSummary>
        <AccordionDetails>
         <Content paragraph ="The mandatory tax audit of the accounts of a business is required if the total sales, turnover or gross receipts exceed RS 1 crore during the previous year. However, if cash transactions are below 5% of total gross receipts and payments, the threshold limit for turnover for tax audit is increased to Rs.10 crore (effective from Financial Year 2020-21)."/>
         
         
        </AccordionDetails>
      </Accordion>
      <Divider sx={{border: '1px solid #FF990080'}}/>
      <Accordion expanded={expanded === 'panel8'} onChange={handleChange('panel8')}  elevation={0} sx={{ boxShadow: 'unset !important' }} disableGutters>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel8bh-content"
          id="panel8bh-header"
        >
          <Typography  sx={{color:'#281900',fontWeight:450,fontSize:'20px'}}>Whether personal expenditure is allowed as a deduction from profit and gain from business and profession? </Typography>
        </AccordionSummary>
        <AccordionDetails>
         <Content paragraph ="Personal expenses, which are incurred for non-business related activities, are not considered as a deductible expense for the purpose of calculating profit and gain from business and profession."/>
            
         
        </AccordionDetails>
      </Accordion>
      <Divider sx={{border: '1px solid #FF990080'}}/>
      <br/>
      
    </div>
  )
}

export default Faq;