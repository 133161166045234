import React from 'react';
import Mainhead from '../../../../Components/Services/MainContent/Mainhead';
import List from '../../../../Components/Services/MainContent/List';
import Step from '../../../../Components/Services/MainContent/Step';

function Document() {
  return (
    <div>
      <Mainhead heading="Basic Documents Required"/>
      <ul className='list'>
        <li className='bullet'>
          <span>
      <List points="Application for registration of partnership(Form 1)"/>
      </span>
      </li>
      <li className='bullet'>
          <span>
      <List points="Certified original copy of partnership deed."/>
      </span>
      </li>
      <li className='bullet'>
          <span>
      <List points="Specimen of an affidavit certifying all the details mentioned in the partnership deed and documents are correct."/>
      </span>
      </li>
      <li className='bullet'>
          <span>
      <List points="PAN card and address proof of the partners."/>
      </span>
      </li>
      <li className='bullet'>
          <span>
      <List points="Proof of principal place of business of the firm."/>
      </span>
      </li>
      </ul>
      <Step number="NOTE: If the documents satisfy the registrar, the firm will be entered into the register of firms and a certificate of registration will be issued."/>
      
    </div>
  )
}

export default Document