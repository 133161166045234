import React from 'react';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Divider } from '@mui/material';
import Content from '../../../Components/Services/MainContent/Content';
import Mainhead from '../../../Components/Services/MainContent/Mainhead';


function Faq() {
const [expanded, setExpanded] = React.useState(false);

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };
  return (
    <div>
        <Mainhead heading="FAQ'S"/>
      <br/>
      <Divider sx={{border: '1px solid #FF990080'}}/>
      <Accordion expanded={expanded === 'panel1'} onChange={handleChange('panel1')}  elevation={0} sx={{ boxShadow: 'unset !important' }} disableGutters>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1bh-content"
          id="panel1bh-header"
        >
          <Typography  sx={{color:'#281900',fontWeight:450,fontSize:'20px'}}>Who is required to pay income tax?</Typography>
        </AccordionSummary>
        <AccordionDetails>
         <Content paragraph ="Any individual or artificial body or group of individuals that earn more than the basic exemption limit are expected to pay income tax. This limit is different for senior citizens (Rs 3 lakhs) and super senior citizens (Rs 5 lakh)."/>
            
         
        </AccordionDetails>
      </Accordion>
      <Divider sx={{border: '1px solid #FF990080'}}/>
      <Accordion expanded={expanded === 'panel2'} onChange={handleChange('panel2')}  elevation={0} sx={{ boxShadow: 'unset !important' }} disableGutters>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel2bh-content"
          id="panel2bh-header"
        >
          <Typography  sx={{color:'#281900',fontWeight:450,fontSize:'20px'}}> What documents are to be enclosed along the return of income?</Typography>
        </AccordionSummary>
        <AccordionDetails>
         <Content paragraph =" There is no need to enclose any documents with the return of income.However, one should retain the documents to produce before any competent authority as and when required in future."/>
            
         
        </AccordionDetails>
      </Accordion>
      <Divider sx={{border: '1px solid #FF990080'}}/>
      <Accordion expanded={expanded === 'panel3'} onChange={handleChange('panel3')}  elevation={0} sx={{ boxShadow: 'unset !important' }} disableGutters>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel3bh-content"
          id="panel3bh-header"
        >
          <Typography  sx={{color:'#281900',fontWeight:450,fontSize:'20px'}}>Can I file a return after completion of the assessment year?</Typography>
        </AccordionSummary>
        <AccordionDetails>
         <Content paragraph ="The Budget 2022 has proposed to introduce an ‘Updated’ return that can be filed within 24 months of the end of the relevant AY, on the payment of additional tax. Even if you have not filed the original return before the due date specified in the Income Tax Act, you can file the ‘updated’ return."/>
            
         
        </AccordionDetails>
      </Accordion>
      <Divider sx={{border: '1px solid #FF990080'}}/>
      <Accordion expanded={expanded === 'panel4'} onChange={handleChange('panel4')}  elevation={0} sx={{ boxShadow: 'unset !important' }} disableGutters>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel4bh-content"
          id="panel4bh-header"
        >
          <Typography  sx={{color:'#281900',fontWeight:450,fontSize:'20px'}}>Best Investments to save income tax?</Typography>
        </AccordionSummary>
        <AccordionDetails>
         <Content paragraph ="There are various instruments in which you can invest to save tax. Some of the most common options available to you include PPF, National Savings Certificate, National Pension System, ELSS schemes, etc."/>
            
         
        </AccordionDetails>
      </Accordion>
      <Divider sx={{border: '1px solid #FF990080'}}/>
      

      <Accordion expanded={expanded === 'panel5'} onChange={handleChange('panel5')}  elevation={0} sx={{ boxShadow: 'unset !important' }} disableGutters>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel5bh-content"
          id="panel5bh-header"
        >
          <Typography  sx={{color:'#281900',fontWeight:450,fontSize:'20px'}}>How much is tax free income in India?</Typography>
        </AccordionSummary>
        <AccordionDetails>
         <Content paragraph =" There are two different tax regimes which are currently used in India to file income tax returns. However, the tax-free income is the same on the basis of both the old regime and the new regime. In both cases, annual income of up to Rs.2.5 lakh is tax free."/>
            
         
        </AccordionDetails>
      </Accordion>
      <Divider sx={{border: '1px solid #FF990080'}}/>
      <Accordion expanded={expanded === 'panel6'} onChange={handleChange('panel6')}  elevation={0} sx={{ boxShadow: 'unset !important' }} disableGutters>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel6bh-content"
          id="panel6bh-header"
        >
          <Typography  sx={{color:'#281900',fontWeight:450,fontSize:'20px'}}>Is the due date for filing income tax returns the same for all taxpayers?</Typography>
        </AccordionSummary>
        <AccordionDetails>
         <Content paragraph ="All individuals and assessees who do not require their accounts to be audited will have to file their income tax returns by July 31. However, companies, individuals and working partners of firms whose accounts must be audited are required to file their income tax returns by September 30. Assessees who are required to submit a report under Section 92E of the Income Tax Act must file their returns by November 30. "/>
            
         
        </AccordionDetails>
      </Accordion>
      <Divider sx={{border: '1px solid #FF990080'}}/>

      
    </div>
  )
}

export default Faq;