import React from 'react'
import Mainhead from '../../../../Components/Services/MainContent/Mainhead';
import Content from '../../../../Components/Services/MainContent/Content'
import Step from '../../../../Components/Services/MainContent/Step';

function Register() {
  return (
    <div>
      <Mainhead heading="Legal Factory - Shop Act Registration process :"/>
    
      <Step number="Step - 1: Labour Department"/>
      <Content paragraph="Every state's labour department has a website with an online registration form and instructions.After landing on the LDMS portal, click on “Establishment Registration” menu icon. Click on “Rajasthan Shops and Commercial Establishments Act, 1958”."/>

      <Step number="Step -2 : Filling/Submitting the Application"/>
      <Content paragraph="Form will appear in the right pane, fill the form and attach appropriate documents. After completing the above instructions, submit the form and LDMS application number will be generated. Before the submission you might be required to pay the general fee depending on the type of company and the type of licence. Many states have an online payment option, but DD/cash payments may also be made offline.
      Keep it safe; it might be required later for retrieving information."/>

      <Step number="Step -3 : Verification"/>
      <Content paragraph="Your application is examined by the Labour Department and, if the application and attached documents are ok then you’ll get the acknowledgement message from the department on your registered mobile number and email. Otherwise they will appoint an inspection to verify the particulars you filled up and documents attached."/>
      
      <Step number="Step -4: License"/>
      <Content paragraph="After the verification, a valid license (certificate) is issued to you. This license is normally valid for one year with a renewal each year. You can download the certificate online.
      In order to obtain the certificate offline, the registration application has to be filled and submitted directly to the Chief Inspector of the area along with the required fees. The Chief Inspector will then issue the registration certificate to the owner or proprietor after being satisfied with the correctness of the application."/>

      
    

    </div>
  )
}

export default Register