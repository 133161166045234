import React from 'react';
import Mainhead from '../../../../Components/Services/MainContent/Mainhead';
import Content from '../../../../Components/Services/MainContent/Content';
import List from '../../../../Components/Services/MainContent/List';
import Step from '../../../../Components/Services/MainContent/Step';


function Document() {
  return (
    <div>
      <Mainhead heading="Basic Documents Required"/>
     <ul className='list'>
      <li className='bullet'>
        <span>
      <List points="Address and identity proof"/>
      </span>
      </li>
      <li className='bullet'>
        <span>
      <List points="PAN card, KYC documents and"/>
      </span>
      </li>
      <li className='bullet'>
        <span>
      <List points="Rental agreement or sale deed(in case of Shops and Establishment Act Registration)"/>
      </span>
      </li>
      </ul>
      <Step number="What are the documents required for opening a current account?"/>
      <ul className='list'>
      <li className='bullet'>
        <span>
      <List points="Proof of the existence of your business"/>
      </span>
      </li>
      <li className='bullet'>
        <span>
      <List points="Shops and Establishments Act Registration"/>
      </span>
      </li>
      <li className='bullet'>
        <span>
      <List points="PAN card"/>
      </span>
      </li>
      <li className='bullet'>
        <span>
      <List points="Address and identity proof"/>
      </span>
      </li>
      </ul>


    </div>
  )
}

export default Document