import React from "react";
import { Box } from "@mui/material";
import Mainhead from "../../../Components/Services/MainContent/Mainhead";
import Content from "../../../Components/Services/MainContent/Content";
import Step from "../../../Components/Services/MainContent/Step";
import ReusableTable from "../../../Components/Income-Tax/ReusableTable";
import CustomList from "../../../Components/Income-Tax/CustomList";

const IndexationAcquisitionImprovement = () => {
  const ciiData = [
    { slNo: 1, financialYear: "2001-02", costInflationIndex: 100 },
    { slNo: 2, financialYear: "2002-03", costInflationIndex: 105 },
    { slNo: 3, financialYear: "2003-04", costInflationIndex: 109 },
    { slNo: 4, financialYear: "2004-05", costInflationIndex: 113 },
    { slNo: 5, financialYear: "2005-06", costInflationIndex: 117 },
    { slNo: 6, financialYear: "2006-07", costInflationIndex: 122 },
    { slNo: 7, financialYear: "2007-08", costInflationIndex: 129 },
    { slNo: 8, financialYear: "2008-09", costInflationIndex: 137 },
    { slNo: 9, financialYear: "2009-10", costInflationIndex: 148 },
    { slNo: 10, financialYear: "2010-11", costInflationIndex: 167 },
    { slNo: 11, financialYear: "2011-12", costInflationIndex: 184 },
    { slNo: 12, financialYear: "2012-13", costInflationIndex: 200 },
    { slNo: 13, financialYear: "2013-14", costInflationIndex: 220 },
    { slNo: 14, financialYear: "2014-15", costInflationIndex: 240 },
    { slNo: 15, financialYear: "2015-16", costInflationIndex: 254 },
    { slNo: 16, financialYear: "2016-17", costInflationIndex: 264 },
    { slNo: 17, financialYear: "2017-18", costInflationIndex: 272 },
    { slNo: 18, financialYear: "2018-19", costInflationIndex: 280 },
    { slNo: 19, financialYear: "2019-20", costInflationIndex: 289 },
    { slNo: 20, financialYear: "2020-21", costInflationIndex: 301 },
    { slNo: 21, financialYear: "2021-22", costInflationIndex: 317 },
    { slNo: 22, financialYear: "2022-23", costInflationIndex: 331 },
    { slNo: 23, financialYear: "2023-24", costInflationIndex: 348 },
  ];

  return (
    <Box
      style={{
        display: "flex",
        flexDirection: "column",
        gap: 4,
        marginBottom: "2rem",
      }}
    >
      {" "}
      <Mainhead heading="Indexation as per Income Tax Act, 1961" />
      <Step
        number={`
          WHAT DOES INDEXATION MEAN AS PER INCOME TAX ACT,1961?
        `}
      />
      <Content paragraph="Indexation, under the Income Tax Act, refers to the adjustment of the purchase price of an asset to account for inflation when calculating the capital gains tax on the sale of certain assets held for the long term." />
      <Content paragraph="This adjustment is allowed to reflect the impact of inflation on the cost of the asset, reducing the tax liability by adjusting the cost price upwards based on the inflation index." />
      <Step
        number={`
          Key points regarding indexation:
        `}
      />
      <CustomList
        points={[
          "Long-term Capital Assets: Indexation is applicable to certain long-term capital assets such as real estate, bonds, debentures, and listed securities. These are assets held for more than three years in the case of movable property like stocks and for more than two years in the case of immovable property like land or building.",
          "Cost Inflation Index (CII): The Central Government notifies the Cost Inflation Index (CII) every financial year. This index reflects the inflation rates for the financial year. The CII is used to adjust the cost of acquisition of an asset to its current value by applying the indexation factor.",
          "Calculation of Indexed Cost: To calculate the indexed cost of acquisition, the following formula is used: Indexed Cost of Acquisition = Cost of Acquisition × (CII of the year of transfer / CII of the year of acquisition). Here, the Cost of Acquisition refers to the original cost of the asset and the CII values correspond to the cost inflation index of the year of transfer and the year in which the asset was acquired.",
          "Benefit of Indexation: Indexation allows taxpayers to adjust the purchase price of the asset in accordance with inflation, thereby reducing the taxable capital gains. It recognizes that the nominal value of the asset might increase over time due to inflation, thus preventing the taxpayer from paying taxes on the 'inflated' portion of the gain.",
          "Impact on Tax Liability: By indexing the cost of acquisition, the capital gains reduce, and consequently, the tax liability on the sale of the asset decreases. This benefits taxpayers by taxing only the real gains earned from the asset's appreciation above the inflation-adjusted cost.",
          "Indexation is an essential mechanism provided under the Income Tax Act to ensure a fair calculation of capital gains tax on the sale of certain long-term assets, considering the impact of inflation on the asset's cost over the holding period.",
        ]}
      />
      <Step
        number={`
          Indexation is an essential mechanism provided under the Income Tax Act to ensure a fair calculation of capital gains tax on the sale of certain long-term assets, considering the impact of inflation on the asset's cost over the holding period.
        `}
      />
      <ReusableTable
        data={ciiData}
        columns={[
          { label: "Sl. No.", key: "slNo" },
          { label: "Financial Year", key: "financialYear" },
          { label: "Cost Inflation Index", key: "costInflationIndex" },
        ]}
      />
      <Mainhead heading="Cost of Acquisition and Cost of Improvement as per Income Tax Act, 1961" />
      <Step
        number={`
    WHAT DOES COST OF ACQUISITION MEAN AS PER INCOME TAX ACT,1961?
  `}
      />
      <Content paragraph="Simply defined, Cost of Acquisition is the expenditure incurred on the acquisition of property. It includes the stamp duty, registration expenses, brokerage, interest cost, etc., that is incurred at the time of purchase." />
      <Content paragraph="The definition of the Cost of Acquisition, as per the Income Tax Act, includes:" />
      <CustomList
        points={[
          "Actual Purchase Price: The primary component of the cost of acquisition is the actual amount paid by the taxpayer to purchase the asset. This purchase price forms the base value for computing gains or losses upon the sale or transfer of the asset.",
          "Incidental Expenses: Additionally, any expenses directly related to the purchase of the asset are included in the cost of acquisition. These expenses might include brokerage charges, stamp duty, registration fees, legal fees, transfer fees, etc. These incidental expenses that are directly attributable to the acquisition of the asset become part of the total cost of acquisition.",
          "Adjustments and Indexation: In certain cases, adjustments might be allowed for improvements or additions made to the asset that increases its value. Similarly, for assets held for the long term, indexation benefits might be available, allowing the adjustment of the purchase price based on inflation rates.",
          "Inherited Assets or Gifts: In cases where the asset is received as an inheritance or as a gift, the cost of acquisition might differ based on the provisions of the Income Tax Act. For inherited assets, the cost to the previous owner or the fair market value as of a particular date might be considered.",
        ]}
      />
      <Step
        number={`
    HOW TO CALCULATE INDEXED COST OF ACQUISITION?
  `}
      />
      <Content paragraph="Indexed Cost of Acquisition is applicable only in the case of long-term capital assets and that too at the option of the assessee. It is calculated using the following formula:" />
      <code>
        Cost of Acquisition = CII of the year of transfer / CII of the year of
        acquisition
      </code>
      <Step
        number={`
    WHAT DOES COST OF IMPROVEMENT MEAN AS PER INCOME TAX ACT,1961?
  `}
      />
      <Content paragraph="All expenditure of a capital nature incurred in making any additions or alterations to the capital asset by the assessee or the previous owner covered u/s 49(1). In this routine expenses or repair and maintenance expenses are not Cost of Improvement." />
      <Content paragraph="Key points regarding the cost of improvement under the Income Tax Act:" />
      <CustomList
        points={[
          "Definition: Section 55 of the Income Tax Act defines the 'cost of improvement' as any expenditure incurred by the taxpayer to make improvements or additions to the capital asset. These expenses should be directly related to increasing the value of the asset.",
          "Inclusion in Cost of Acquisition: When calculating capital gains, the cost of improvement is added to the actual cost of acquisition (purchase price) of the asset. The resultant figure represents the adjusted cost of acquisition for the purpose of computing capital gains.",
          "Indexation Benefit: Similar to the original cost of acquisition, the cost of improvement may also be adjusted for inflation using the Cost Inflation Index (CII) if the improvement was made after the base year for indexation. This adjusted cost of improvement can be considered while computing the indexed cost of acquisition for assets held for the long term.",
          "Calculation of Capital Gains: The capital gains on the transfer of a capital asset are computed by deducting the indexed cost of acquisition (including the cost of improvement) from the full value of consideration received or accruing as a result of the transfer.",
          "Documentation and Proof: It's crucial for taxpayers to maintain proper documentation and evidence of the expenses incurred for improvements or additions to the capital asset. This documentation is essential for tax assessment purposes to substantiate the cost of improvement.",
        ]}
      />
      <Step
        number={`
    WHAT IS INDEXED COST OF IMPROVEMENT?
  `}
      />
      <Content paragraph="The 'Indexed Cost of Improvement' is a concept used in the computation of long-term capital gains tax under the Income Tax Act, 1961, in India. It refers to the adjusted cost incurred for making improvements or additions to a capital asset, considering the effect of inflation by indexing the cost to the current year using the Cost Inflation Index (CII)." />
      <Content paragraph="It is calculated using the following formula:" />
      <code>
        Cost of Improvement = CII of the year of transfer / CII of the year of
        improvement
      </code>
    </Box>
  );
};

export default IndexationAcquisitionImprovement;
