import React from 'react';
import Mainhead from '../../../../Components/Services/MainContent/Mainhead';
import Content from '../../../../Components/Services/MainContent/Content';


function Forget() {
  return (
    <div>
        <Mainhead heading="What will happen if I forget to file for Trademark Renewal?
"/>
        <Content paragraph="If the trademark is not renewed within its due date then it can still be renewed within six months of grace period from the expiry of the registration. However, in that case, you have to pay an additional fee as discussed above. In case you miss renewing the trademark even within the grace period then the mark gets canceled. A canceled mark can be restored within one year from the date of its expiry with a restoration fee of Rs.9,000 (for e-filing) and Rs.10,000 (for offline filing) per class charged over and above the normal renewal fees.
"/>
    </div>
  )
}

export default Forget