import React from 'react'
import Mainhead from '../../../../Components/Services/MainContent/Mainhead';
import Content from '../../../../Components/Services/MainContent/Content';
import Threesole from '../../../../Assets/Services/Soleproprietorship/LegalFactory_Sole3.png';
import { Box , styled} from '@mui/material';


const Tabletimg = styled(Box)(({ theme }) => ({
  width:'45rem',
  height:'10rem',
  marginTop:'0.8rem',
  [theme.breakpoints.down("sm")]: {
   width:'18rem',
 height:'8rem',
 },
 [theme.breakpoints.down("md")]: {
   width:'18rem',
 height:'8rem',
 },
 [theme.breakpoints.between("md", "lg")]: {
  width:'30rem'
 },
 [theme.breakpoints.up("xl")]: {
   width:'48rem'
  }
}));

function Overview() {
  return (
    <div>
        <Mainhead heading="What is a Sole Proprietorship?"/>
        <Content paragraph="A Sole Proprietorship is a blessing in disguise for merchants and small traders involved in unsystematic mode of businesses with little investments. It is owned by a single person wherein there is no legal partition between the owner and the business. Which directly implies that the loss or profit of the proprietorship company is considered that of the individual and the income of the company as the income of the owner as per the Income Tax Act. Moreover, the owner enjoys the rights provided to a registered legal company although it is not a legal entity. There is unlimited liability and no perpetual existence."/>
         <br/>
        <Tabletimg component="img"
                sx={{
                  content: {
                    xs: `url(${Threesole})`
                  }
                }}/>
    </div>
  )
}

export default Overview
