

export const menuItems = [
    { path: "/incometax/incometax", label: "Basics Of Income Tax" },
    { path: "/incometax/itrfiling", label: "ITR Filing" },
    { path: "/incometax/procedure", label: "Procedure Of ITR Filing" },
    { path: "/incometax/residential", label: "Residential Status" },
    { path: "/incometax/salaries", label: "Income From Salaries" },
    { path: "/incometax/houseproperty", label: "Income From HouseProperty" },
    { path: "/incometax/business", label: "Profits Of Gain From Business And Profession" },
    { path: "/incometax/capitalgain", label: "Capital Gain" },
    { path: "/incometax/othersources", label: "Income From Other Sources" },
    { path: "/incometax/income", label: "Exemptions From Income U/S 10" },
    { path: "/incometax/taxableincome", label: "Deduction From Taxable Inocme" },
    { path: "/incometax/tds", label: "Deduction Of Tax From Income (TDS)" },
    { path: "/incometax/advancetax", label: "Advance Tax Liability" },
    { path: "/incometax/incomeassessed", label: "Income Assessed In IT Act" },
    { path: "/incometax/appeal", label: "How To File An Appeal" },
];
