import React from "react";
import { Box } from "@mui/material";
import Mainhead from "../../../Components/Services/MainContent/Mainhead";
import Content from "../../../Components/Services/MainContent/Content";
import Step from "../../../Components/Services/MainContent/Step";
import CustomList from "../../../Components/Income-Tax/CustomList";

const BusinessStructuresInIndia = () => {
  return (
    <Box
      style={{
        display: "flex",
        flexDirection: "column",
        gap: 4,
        marginBottom: "2rem",
      }}
    >
      <Mainhead heading="Various Types of Business Structure in India" />
      <Step
        number={`
            In India, different types of business structures have varying tax implications under the Income Tax Act. The most common business structures include:
          `}
      />
      <Step
        number={`
            1. Sole Proprietorship:
          `}
      />
      <Content
        paragraph={`
            A sole proprietorship is the simplest form of business structure owned and operated by a single individual. The business and the owner are considered the same entity.
          `}
      />
      <CustomList
        points={[
          <span key={1}>
            Advantages: -<br />
            Easy and inexpensive to establish and dissolve.
            <br />
            Direct control over business operations.
            <br />
            Simplicity in decision-making and compliance.
          </span>,
          <span key={2}>
            Disadvantages: -<br />
            Unlimited personal liability exposes the owner's personal assets to
            business risks.
            <br />
            Limited opportunities for raising capital compared to larger
            business structures.
          </span>,
        ]}
      />
      <Step
        number={`
            2. Partnership Firm:
          `}
      />
      <Content
        paragraph={`
            A partnership involves two or more individuals (partners) joining together to carry on a business with a view to earning profits. Partnerships can be registered or unregistered.
          `}
      />
      <CustomList
        points={[
          <span key={1}>
            Advantages: -<br />
            Shared responsibility and decision-making among partners.
            <br />
            Easier access to capital compared to sole proprietorships.
            <br />
            Flexibility in profit-sharing and management.
          </span>,
          <span key={2}>
            Disadvantages: -<br />
            Unlimited liability exposes partners' personal assets to business
            risks.
            <br />
            Disputes among partners can affect business operations.
          </span>,
        ]}
      />
      <Step
        number={`
            3. Limited Liability Partnership (LLP):
          `}
      />
      <Content
        paragraph={`
            An LLP is a hybrid business structure that combines elements of partnerships and corporations. It provides limited liability to its partners.
          `}
      />
      <CustomList
        points={[
          <span key={1}>
            Advantages: -<br />
            Limited liability for partners.
            <br />
            Flexibility in management and fewer regulatory formalities compared
            to companies.
            <br />
            Perpetual succession, ensuring continuity even with changes in
            ownership.
          </span>,
          <span key={2}>
            Disadvantages: -<br />
            Complex formation and compliance processes compared to partnerships.
            <br />
            Not suitable for certain regulated sectors or industries.
          </span>,
        ]}
      />
      <Step
        number={`
            4. Private Limited Company:
          `}
      />
      <Content
        paragraph={`
            A private limited company is a separate legal entity owned by shareholders. It's regulated by the Companies Act and provides limited liability to its shareholders.
          `}
      />
      <CustomList
        points={[
          <span key={1}>
            Advantages: -<br />
            Limited liability for shareholders.
            <br />
            Better access to funding, as it can issue shares to raise capital.
            <br />
            Separate legal existence ensures continuity and stability.
          </span>,
          <span key={2}>
            Disadvantages: -<br />
            Stricter compliance and regulatory requirements compared to other
            structures.
            <br />
            More complex setup and operational procedures.
          </span>,
        ]}
      />
      <Step
        number={`
      5. One Person Company (OPC):
    `}
      />
      <Content
        paragraph={`
      An OPC is a type of private company that can be formed by a single person. It provides limited liability to its sole shareholder.
    `}
      />
      <CustomList
        points={[
          <span key={1}>
            Advantages: -<br />
            Limited liability for the sole shareholder.
            <br />
            Perpetual succession and separate legal existence.
            <br />
            Easier to manage compared to other structures for single
            entrepreneurs.
          </span>,
          <span key={2}>
            Disadvantages: -<br />
            Restrictions on the number of OPCs a person can incorporate.
            <br />
            Complex conversion procedures if the business grows and needs to
            change its structure.
          </span>,
        ]}
      />
      <Step
        number={`
      6. Cooperative Society:
    `}
      />
      <Content
        paragraph={`
      A cooperative society is an autonomous association of individuals who voluntarily cooperate for their mutual economic, social, and cultural benefits.
    `}
      />
      <CustomList
        points={[
          <span key={1}>
            Advantages: -<br />
            Collective ownership and democratic management.
            <br />
            Economic benefits through collective bargaining and shared
            resources.
            <br />
            Some tax benefits and exemptions under the Income Tax Act.
          </span>,
          <span key={2}>
            Disadvantages: -<br />
            Challenges in decision-making with a larger member base.
            <br />
            Limited access to external funding compared to other business
            structures.
          </span>,
        ]}
      />
      <Step
        number={`
      WHAT ARE THE TAX IMPLICATIONS OF DIFFERENT STRUCTURES IN INDIA?
    `}
      />
      <Step
        number={`
      Sole Proprietorship:
    `}
      />
      <Content
        paragraph={`
      In a sole proprietorship, the business and the individual are considered one entity for tax purposes. The income earned by the business is treated as the owner's personal income and is taxed at individual income tax rates.
    `}
      />
      <CustomList
        points={[
          <span key={1}>
            Advantages: -<br />
            Simple to set up, fewer regulatory formalities, and the ability to
            take advantage of individual tax slabs and deductions.
          </span>,
        ]}
      />
      <Step
        number={`
      Partnership Firm:
    `}
      />
      <Content
        paragraph={`
      Partnership firms are not taxed as separate entities. The income earned by the firm is distributed among partners, and each partner is taxed individually on their share of the profits at the applicable tax rates.
    `}
      />
      <CustomList
        points={[
          <span key={1}>
            Advantages: -<br />
            Flexibility in the distribution of profits among partners and fewer
            compliance requirements compared to companies.
          </span>,
        ]}
      />
      <Step
        number={`
      Limited Liability Partnership (LLP):
    `}
      />
      <Content
        paragraph={`
      LLPs are separate legal entities. The income of an LLP is taxed at a flat rate under the presumptive taxation scheme, or LLPs can opt for regular taxation and be taxed similar to a partnership firm.
    `}
      />
      <CustomList
        points={[
          <span key={1}>
            Advantages: -<br />
            Combines the benefits of a partnership and limited liability for its
            partners, while providing some tax advantages.
          </span>,
        ]}
      />
      <Step
        number={`
      Private Limited Company:
    `}
      />
      <Content
        paragraph={`
      Private Limited Companies are separate legal entities from their shareholders. They are taxed at a flat rate on their income. Dividends distributed to shareholders are taxed in their hands as per the applicable rates.
    `}
      />
      <CustomList
        points={[
          <span key={1}>
            Advantages: -<br />
            Limited liability for shareholders.
            <br />
            Better access to funding, as it can issue shares to raise capital.
            <br />
            Separate legal existence ensures continuity and stability.
          </span>,
        ]}
      />
      <Step
        number={`
      One Person Company (OPC):
    `}
      />
      <Content
        paragraph={`
      Similar to a private limited company, an OPC is a separate legal entity. The income of an OPC is taxed at the corporate tax rate, and dividends distributed to the sole shareholder are taxed in their hands.
    `}
      />
      <CustomList
        points={[
          <span key={1}>
            Advantages: -<br />
            Provides the benefits of limited liability while allowing a single
            individual to operate a company.
          </span>,
        ]}
      />
      <Step
        number={`
      Cooperative Society:
    `}
      />
      <Content
        paragraph={`
      Cooperative societies are taxed separately on their income at specified rates. Members of the cooperative are taxed on the dividends or income received from the society.
    `}
      />
      <CustomList
        points={[
          <span key={1}>
            Advantages: -<br />
            Cooperative societies promote collective ownership and democratic
            management, and they have specific tax benefits under the Income Tax
            Act.
          </span>,
        ]}
      />
      Copy code
      <Step
        number={`
      7. What Do You Mean by Digital Signature?
    `}
      />
      <Content
        paragraph={`
      A digital signature is a cryptographic technique used to authenticate the identity of the sender of a message or the signer of a document in the digital realm. It serves a similar purpose to a handwritten signature on a paper document but in an electronic format.
    `}
      />
      <Step
        number={`
      8. What are the Key Components of Digital Signature?
    `}
      />
      <CustomList
        points={[
          "Authentication: Digital signatures help verify the authenticity of the sender or signer, ensuring that the message or document has not been tampered with and was indeed created by the claimed sender.",
          "Integrity: They ensure the integrity of the document or message by confirming that it hasn't been altered or modified since the time it was signed.",
          "Non-repudiation: Digital signatures provide non-repudiation, meaning the signer cannot deny their involvement or claim that they didn't sign the document. This is due to the cryptographic evidence that links the signature to the signer.",
        ]}
      />
      <Step
        number={`
      9. How is DSC Created?
    `}
      />
      <Content
        paragraph={`
      The process of creating a digital signature involves using an algorithm to generate a unique digital fingerprint of the document or message, known as a hash. This hash is encrypted using the sender's private key, forming the digital signature. The recipient can then use the sender's public key to decrypt the signature and verify the authenticity of the document or message.
    `}
      />
      <Content
        paragraph={`
      Digital signatures are widely used in various industries, including:
    `}
      />
      <CustomList
        points={[
          "Business and Legal Transactions: Signing contracts, agreements, and legal documents electronically.",
          "Email Security: Authenticating emails to prevent tampering or impersonation.",
          "Government and Regulatory Compliance: Ensuring the integrity and security of sensitive government documents and transactions.",
          "E-commerce: Verifying the authenticity of online transactions and ensuring the security of online payments.",
        ]}
      />
      <Content
        paragraph={`
      Digital signatures play a crucial role in ensuring the security, integrity, and authenticity of digital communications and transactions, providing a secure method for verifying identities and validating the integrity of electronic documents or messages.
    `}
      />
      <Step
        number={`
      10. What are the Various Uses of DSC in Income Tax 1961?
    `}
      />
      <Content
        paragraph={`
      In the context of the Income Tax Act, 1961 in India, a Digital Signature Certificate (DSC) holds significant importance and is widely used for various purposes to facilitate secure and paperless transactions and communications. Here are some of the primary uses of Digital Signature Certificates in Income Tax:
    `}
      />
      <CustomList
        points={[
          "Filing Income Tax Returns (ITR): Taxpayers, including individuals, businesses, and organizations, can electronically file their Income Tax Returns using a Digital Signature Certificate. A DSC ensures the authenticity of the taxpayer's identity while filing the returns online.",
          "Authentication of Documents: Digital signatures are used to authenticate various documents submitted to the Income Tax Department, such as audit reports, financial statements, forms, certificates, and other filings. A DSC ensures the integrity and validity of these documents.",
          "Online Verification and Transactions: Taxpayers and authorized entities can use DSCs for online verification and authentication of various transactions and communications with the Income Tax Department's e-Filing portal or other relevant platforms.",
          "Authorizing Representatives: Taxpayers can authorize representatives, such as Chartered Accountants or Tax Consultants, to file tax returns or represent them before the Income Tax Department using a DSC. This ensures secure and authorized representation.",
          "Digital Signing of Notices and Communications: Income Tax authorities use digital signatures to issue notices, orders, or communications to taxpayers electronically. This provides an authenticated and secure way of receiving official communications.",
          "E-assessments and E-appeals: Digital Signatures play a crucial role in the e-assessment process initiated by the Income Tax Department, where assessments are conducted online. Similarly, for filing appeals electronically, a DSC ensures the authenticity of the appellant's identity.",
          "Compliance and Regulatory Requirements: Various compliance-related filings, such as Tax Deducted at Source (TDS) returns, Tax Collected at Source (TCS) returns, and other filings mandated under the Income Tax Act, can be submitted using Digital Signatures to comply with regulatory requirements.",
        ]}
      />
      <Step
        number={`
      11. What Are the Various Return and ITR Forms for PGBP in Income Tax Act?
    `}
      />
      <Content
        paragraph={`
      Under the Income Tax Act in India, the term 'PGBP' refers to 'Profits and Gains of Business or Profession.' For taxpayers earning income from business or profession, specific Income Tax Return (ITR) forms are prescribed by the Income Tax Department to report their PGBP.
    `}
      />
      <Content
        paragraph={`
      The appropriate ITR forms for reporting Profits and Gains of Business or Profession depend on various factors such as the nature of business, income sources, and the taxpayer's status. Here are the commonly used ITR forms for individuals, Hindu Undivided Families (HUFs), and businesses reporting income under the head 'Profits and Gains of Business or Profession':
    `}
      />
      <CustomList
        points={[
          "ITR-3: This form is used by individuals and HUFs having income from a proprietary business or profession. It is applicable to individuals and HUFs who are partners in a firm but do not carry out business under the firm's name.",
          "ITR-4: Also known as Sugam, this form is specifically designed for individuals, HUFs, and firms (other than Limited Liability Partnerships - LLPs) having income from a presumptive business or profession under Sections 44AD, 44ADA, or 44AE of the Income Tax Act. It is suitable for small businesses or professionals opting for presumptive taxation.",
          "ITR-5: Partnerships, LLPs, and Association of Persons (AOPs) are required to file their income tax returns using Form ITR-5. This form is applicable to entities other than companies, individuals, and HUFs.",
          "ITR-6: This form is applicable to companies other than companies claiming exemption under Section 11 (Income from property held for charitable or religious purposes) of the Income Tax Act. Companies, including those not claiming exemption under Section 11, should file their returns using Form ITR-6.",
        ]}
      />
    </Box>
  );
};

export default BusinessStructuresInIndia;
