import React from 'react';
import Sidecomponent from '../../../Components/Tax-Planning/Sidecomponent';
import Topdiv from "../../../Components/Tax-Planning/Topdiv"
import GetInTouch from '../../../Components/Services/GetInTouch';
import {
  Grid, Box, Container, styled, Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Link
} from '@mui/material';

import Mainhead from '../../../Components/Services/MainContent/Mainhead';
import Content from '../../../Components/Services/MainContent/Content';
import List from '../../../Components/Services/MainContent/List';
import Step from '../../../Components/Services/MainContent/Step';

import TableCell, { tableCellClasses } from '@mui/material/TableCell';


function createData(
  Quarter,
  QuarterPeriod,
  QuarterEnding,
  DueDate

   ) {
     return {  
        Quarter,
        QuarterPeriod,
        QuarterEnding,
       DueDate

     };
   }
   const StyledTableCell = styled(TableCell)(({ theme }) => ({
     [`&.${tableCellClasses.head}`]: {
       backgroundColor:'#773106',
       color: theme.palette.common.white,
       textAlign:'left',
       fontFamily: 'Sora',
       fontWeight:'bold',
       
       
     
     },
     [`&.${tableCellClasses.body}`]: {
       fontSize: 16,
       textAlign:'left',
       textAlign:'left',
       fontFamily: 'Sora',
      },
   }));
   
   const StyledTableRow = styled(TableRow)(({ theme }) => ({
     '&:nth-of-type(odd)': {
       backgroundColor: theme.palette.action.hover,
       fontFamily:'Sora'
     },
     // hide last border
     '&:last-child td, &:last-child th': {
         border: 0 ,
     },
   }));
   const rows = [
     createData('1st Quarter','April - June','30th June','31st July 2022'),
     createData('2nd Quarter','July - September',' 30th September','31st Oct 2022'),
     createData('3rd Quarter','October - December',' 31st December',' 31st Jan 2023'),
     createData('4th Quarter','January - March','31st March','31st May 2023')
   ];


function Tds() {
  return (
    <div>
 <Topdiv heading="Are you looking to file your Income Tax Returns ?"/>
      
 <div className='Container'>
        
      
      
      <Container>
      
        <Grid container>

          <Grid item  lg={4} xl={3}>
            <Box sx={{ display: { xs: 'none', lg: 'block' }, marginTop: '4rem', position: 'sticky', top: 90, marginBottom: '3rem' }}>
              <Sidecomponent />
            </Box>
          </Grid>
          <Grid item xs={11} lg={8} sx={{ marginTop: '4rem' }} xl={9}>
            <Mainhead heading="What is the tax deducted at source ?" />

            <Content paragraph="TDS is a way for the government to collect tax from the source of income. It is designed to ensure that taxpayers pay their tax liabilities on a timely basis. Under the TDS system, a deductor is required to deduct tax at a prescribed rate from the payments made to a deductee, such as salary, rent, commission, or professional fees. The deductor then needs to deposit the deducted tax with the government, along with a TDS return, on a regular basis."/>
            <Content paragraph="The deductee is entitled to receive credit for the tax deducted at source, which can be claimed against their tax liability for the relevant assessment year. The credit can be claimed by the deductee using Form 26AS, which is a statement of tax credit available to the taxpayers,or by submitting a TDS certificate issued by the deductor."/>
            <Content paragraph="TDS is an important aspect of the income tax system in India and is administered by the Central Board of Direct Taxes (CBDT). It helps to ensure that tax is collected from taxpayers in a timely and efficient manner and helps to reduce the burden of tax compliance for both taxpayers and the government."/>
            
            <Mainhead heading="What is the provision and rate of TDS on dividend?"/>
            <Content paragraph="As per the provisions of the Income Tax Act, 1961 in India , any company or mutual fund that pays dividend to a resident individual shareholders in a financial year (FY) must deduct TDS at the rate of 10% if the dividend payable exceeds INR 5,000 (Indian Rupees five thousand ) in a FY . This TDS is required to be deducted before making any payment of the dividend, regardless of the mode of payment."/>
            <Content paragraph="It’s important to note that this TDS rate is applicable only to dividends paid by companies or mutual funds to resident individual shareholders. The TDS may be different for other types of income or for non-resident taxpayers"/>
            <br/>
            <Mainhead heading="What is the provision regarding TDS on interest other than interest on securities?"/>
            <Content paragraph="If you receive interest from a banking company, co-operative society engaged in banking business, or a post office on a notified deposit under a notified scheme, and the amount of interest exceeds Rs. 40000 in a financial year, then the payer is required to deduct tax at the rate of 10% on the excess amount. This limit is Rs. 50000 for senior citizens."/>
            <Content paragraph="In other cases, if the payer is not a banking company, co-operative society, or post office and the amount of interest exceeds Rs. 5000 in a financial year, then the payer is required to deduct tax at the rate of 10% on the excess amount."/>
            <Content paragraph="This provision applies to interest paid or credited by any person, including a company, firm, association of persons, or an individual. The payer is required to deduct tax at source and deposit it with the government. The receipt of the interest is required to report the interest income in their income tax return and pay on it as per their applicable tax slab."/>
            <br/>
            <Mainhead heading="What is the provision regarding TDS on the insurance commission?"/>
            <Content paragraph="If you are responsible for paying any income by way of remuneration or reward for soliciting or procuring insurance business, and the amount exceeds Rs. 15000 in a financial year, then you are required to deduct tax at source at the rate of 5% on the excess amount. This provision applies to any person, including a company, firm, association of persons, or an individual."/>
            <Content paragraph="This requirement is specified under section194D of the Income Tax Act, 1961, which deals with the deduction of tax at source on income by way of commission or brokerage. The payer is required to deduct tax at the time of credit of such income to the account of the payee or at the time of payment, whichever is earlier."/>
            <br/>
            <Mainhead heading="What is the provision regarding TDS on any sum under a life insurance policy?"/>
            <Content paragraph="If you are responsible for paying any sum under a life insurance policy, including the sum allocated by way of bonus, and the amount exceeds Rs. 100000 in a financial year, then you are required to deduct tax at source at the rate of 5% on the excess amount. This provision applies to any person, including a company, firm, association of persons, or an individual."/>
            <Content paragraph="This requirement is specified under section194DA of the Income Tax Act, 1961, which deals with the deduction of tax at source on income by way of any sum payable under a life insurance policy."/>
            <br/>
            <Mainhead heading="What is the provision regarding TDS on income from units other than in the nature of capital gain?"/>
            <Content paragraph="If you are responsible for paying any income in respect of units of mutual funds other than in the nature of capital gain, and the amount exceeds Rs. 5000 in a financial year, then you are required to deduct tax at source at the rate of 10% on the excess amount. This provision applies to any person, including a company, firm, association of persons, or individual."/>
            <Content paragraph="This requirement is specified under section194K of the Income Tax Act, 1961, which deals with the deduction of tax at source on income by way of units of mutual funds."/>
            <br/>
            <Mainhead heading="What are the provisions regarding TDS on cash withdrawal from any banking institution?"/>
            <Content paragraph="If you are a banking company, bank, or any other banking institution, co-operative bank, or post office, and you are responsible for paying any sum, being the amount or the aggregate amount, to any person from one or more accounts maintained by the recipient, and the amount exceeds Rs. 1 crore during the previous year, then you are required to deduct tax at source at the rate of 2% on the excess amount. This provision applies to any person, including a company, firm, association of persons, or an individual."/>
            <Content paragraph="This requirement is specified under section194N of the Income Tax Act, 1961, which deals with the deduction of tax at source on cash withdrawal from any banking company or bank or any other banking institution, co-operative bank, or post office."/>
            <br/>
            <Mainhead heading="What is the provision regarding TDS on purchase of goods?"/>
            <Content paragraph="If you are a buyer who is responsible for paying any sum to any resident for the purchase of goods, and the amount exceeds Rs. 50 lakhs in a previous year, then you are required to deduct tax at source at the rate of 0.1% on the excess amount. This provision applies to any person, including a company, firm, association of persons, or an individual."/>
            <Content paragraph="This requirement is specified under section194Q of the Income Tax Act, 1961, which deals with the deduction of tax at source on the purchase of goods."/>
            <br/>
            <Mainhead heading="What is the difference between PAN and TAN?"/>
            <Step number="PAN (Permanent Account Number)"/>
            <Content paragraph="PAN (Permanent Account Number) is a unique 10-digit alphanumeric identification number issued by the Income Tax Department to individuals, companies, and other entities in India. PAN is required to be quoted in all documents related to financial transactions, including income tax returns, tax deducted at source (TDS) and tax collection at source (TCS) returns, and in any other communication with the Income Tax Department."/>
            <Step number="TAN (Tax Deduction and Collection Account Number)"/>
            <Content paragraph="TAN (Tax Deduction and Collection Account Number) is a unique 10-digit alphanumeric identification number issued by the Income Tax Department to persons who are required to deduct tax at source or collect tax at source. TAN is required to be quoted in all TDS and TCS returns, and in any other communication with the Income Tax Department."/>
            <Content paragraph="In summary, PAN is a unique identification number assigned to individuals and entities for the purpose of tracking their financial transactions, while TAN is a unique identification number assigned to persons who are responsible for deducting tax at source or collecting tax at source. Both PAN and TAN are important for compliance with the income tax laws in India."/>
            <br/>
            <Mainhead heading="Documents required to file TDS return online"/>
            <Content paragraph="To file a TDS (Tax Deducted at Source) return online, you will need the following documents:"/>
            <List points ="Form 16 or Form 16A (TDS certificate): This is a certificate issued by your employer or deductor showing the details of the TDS deducted from your salary or income."/>
            <List points ="PAN (Permanent Account Number): This is a unique identification number issued by the Income Tax Department to individuals and businesses. You will need to enter your PAN while filing your TDS return online."/>
            <List points ="TAN (Tax Deduction and Collection Account Number): This is a unique identification number issued by the Income Tax Department to persons or entities who are responsible for deducting or collecting tax. You will need to enter your TAN while filing your TDS return online."/>
            <List points ="Any other documents that may be required, depending on the nature of your income and the TDS deducted."/>
            <br/>
            <Mainhead heading="TDS Payment Due Dates and Penalties"/>
            <Content paragraph="The due dates for paying TDS (Tax Deducted at Source) depend on the type of TDS and the nature of the payment. The general due dates for TDS payments are as follows:"/>
            <ul>
              <li style={{listStyle:'number',color:'#815102'}}><Step number="Salary TDS: TDS on salary should be deposited on or before the 7th of the following month in which the salary is paid."/></li>
              <li style={{listStyle:'number',color:'#815102'}}><Step number=" TDS on other income (such as rent, commission, professional fees, etc.): TDS on other income should be deposited on or before the 7th of the following month in which the payment is made."/></li>
              <li style={{listStyle:'number',color:'#815102'}}><Step number="TDS on sale of property: TDS on the sale of a property should be deposited within 30 days from the date of transfer."/></li>
              <li style={{listStyle:'number',color:'#815102'}}><Step number="TDS on import of goods:TDS on the import of goods should be deposited within 15 days from the date of import"/></li>
              <li style={{listStyle:'number',color:'#815102'}}><Step number="If you fail to pay TDS by the due date, you may be liable to pay a penalty. The penalty for late payment of TDS is 1% per month or part of the month, up to a maximum of 12% of the TDS amount. However, if you can show that there were reasonable causes for the delay, the penalty may be waived. It is important to pay TDS on time to avoid any penalty and ensure that your TDS has been credited to your account."/></li>
              
            </ul>
            <Mainhead heading="How do we help in Filing TDS Return?"/>
            <ul>
              <li style={{listStyle:'number'}}><Content paragraph=" Gather all the required documents, such asForm 16 or Form 16A (TDS certificate) and any other documents that may be needed."/></li>
              <li style={{listStyle:'number'}}><Content paragraph="Go to the website and login using your user ID and password."/></li>
              <li style={{listStyle:'number'}}><Content paragraph="Click on the E-File tab and select E-File TDS/TCS Returns from the drop-down menu."/></li>
              <li style={{listStyle:'number'}}><Content paragraph="Choose the appropriate TDS return form"/></li>
              <li style={{listStyle:'number'}}><Content paragraph="Enter the required information, including your TAN (Tax Deduction and Collection Account Number), PAN (Permanent Account Number), and the financial year for which you are filing the return."/></li>
              <li style={{listStyle:'number'}}><Content paragraph="Upload the necessary documents, such as Form 16 or Form 16A."/></li>
              <li style={{listStyle:'number'}}><Content paragraph=" Review the information you have entered to ensure that it is correct."/></li>
              <li style={{listStyle:'number'}}><Content paragraph="Submit the return and print a copy for your records."/></li>
              <li style={{listStyle:'number'}}><Content paragraph="It is important to file your TDS return on time to avoid any penalty or late fees. You should also ensure that the TDS deducted has been credited to your account by checking yourForm 26AS"/></li>
              
            </ul>
            <Mainhead heading="Due date for filing TDS return"/>
            <br/>

            <TableContainer component={Paper}>
                <Table  aria-label="customized table"  size='small'>
        <TableHead>
          <TableRow>
            <StyledTableCell>Quarter </StyledTableCell>
            <StyledTableCell>Quarter Period </StyledTableCell>
            <StyledTableCell>Quarter Ending</StyledTableCell>
            <StyledTableCell>Due Date</StyledTableCell>

          </TableRow>
        </TableHead>
        <TableBody>
          {rows.map((row) => (
            <StyledTableRow
              key={row.name}
            
            >
              <TableCell component="th" scope="row"
              >
                {row.Quarter}
              </TableCell>
              <StyledTableCell>{row.QuarterPeriod}</StyledTableCell>
              <StyledTableCell>{row.QuarterEnding}</StyledTableCell>
              <StyledTableCell>{row.DueDate}</StyledTableCell>
              
            </StyledTableRow>
          ))}
        </TableBody>
      </Table>
    
    </TableContainer>
<br/><br/>
            </Grid>
            </Grid>
            
      </Container>


    </div>
    </div>
  )
}

export default Tds
