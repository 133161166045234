import React,{ useRef, useEffect, useState } from 'react';
import {
    Box,
    Typography,
    Container,
    styled,
    Divider,
    Grid,
    Button,
    useTheme,
  useMediaQuery
} from '@mui/material';
import "../Common.css"
import Topbox from '../../../Components/Services/Topbox';
import GetInTouch from '../../../Components/Services/GetInTouch';
import Overview from './Overview/Overview';
import Checklist from './Checklist/Checklist';
import Register from './Register/Register';
import Document from './DocumentRequired/Document';
import Legalfactory from './WhyLegalFactory/Legalfactory';
import Faq from './FAQ/Faq';


const getDimensions = ele => {
  const { height } = ele.getBoundingClientRect();
  const offsetTop = ele.offsetTop;
  const offsetBottom = offsetTop + height;

  return {
    height,
    offsetTop,
    offsetBottom,
  };
};

const scrollTo = ele => {
  ele.scrollIntoView({
    behavior: "smooth",
    block: "start",
  });
};

const PartnershipFirm = () => {

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const isTablet = useMediaQuery(theme.breakpoints.down("md"));
  const [visibleSection, setVisibleSection] = useState();
  const headerRef = useRef(null);
    const overviewRef = useRef(null);
    const checklistRef = useRef(null);
    const registerRef = useRef(null);
    const documentRef = useRef(null);
    const legalRef = useRef(null);
    const faqRef = useRef(null);
  
    const sectionRefs = [
      { section: "Overview_part", ref: overviewRef },
      { section: "Checklist_part", ref: checklistRef },
      { section: "Register_part", ref: registerRef },
      { section: "Document_part", ref: documentRef },
      { section: "Legal_part", ref: legalRef },
      { section: "Faq_part", ref: faqRef },
    ];
  
    useEffect(() => {
      const handleScroll = () => {
        const { height: headerHeight } = getDimensions(headerRef.current);
        const scrollPosition = window.scrollY + headerHeight;
  
        const selected = sectionRefs.find(({ section, ref }) => {
          const ele = ref.current;
          if (ele) {
            const { offsetBottom, offsetTop } = getDimensions(ele);
            return scrollPosition > offsetTop && scrollPosition < offsetBottom;
          }
        });
  
        if (selected && selected.section !== visibleSection) {
          setVisibleSection(selected.section);
        }
         
         else if (!selected && visibleSection) {
          setVisibleSection(undefined);
        }
      };
  
      handleScroll();
      window.addEventListener("scroll", handleScroll);
      return () => {
        window.removeEventListener("scroll", handleScroll);
      };
    }, [visibleSection]);
    
    
    const Topspacer  = styled(Box)(({ theme }) => ({
      height:'6rem',
      [theme.breakpoints.down("sm")]: {
        height:'1.5rem',
      }
          
  }));

  const Content  = styled(Box)(({ theme }) => ({
    
        
}));
  
  
  const Sticky  = styled(Box)(({ theme }) => ({
      position:'sticky',
      top:90,
      left:0,
     }));
  
  const PLheader  = styled(Box)(({ theme }) => ({
      display:'flex',
      flexDirection:'column',
      justifyContent:'flex-start',
  
  }));
  
  const Bottom_spacer  = styled(Box)(({ theme }) => ({
      height:'8rem',
  }));
  

  const Section  = styled(Box)(({ theme }) => ({
      height:'auto'
    }));

return (
        <div>
        <Topbox heading="Partnership Firm" service="Partnership Firm"
        document={[<a href='#Document_part' style={{color:'#F19E1E'}}  onClick={() => {
          scrollTo(documentRef.current);
        }}>LearnMore</a>]}

        description="Unlock the potential of your partnership with ease. Legal Factiry offers efficient Partnership Firm Registration services, ensuring a strong legal foundation for your collaborative venture."

        rating="4.4"

        clients="From 90 customer"

        priceinfo="Start your Partnership Firm Company Registration Journey"/>
        <div className='Container'> 
          <Container>
          
          <div className="App">
      <Topspacer />
      
     <Content>
        <Grid container spacing={3}>
            <Grid item lg={3} xl={3} md={3}
            sx={
              isMobile || isTablet ? { display: 'none' } : { }
            }>
              
        <Sticky>
          <PLheader ref={headerRef}>
            <Box
              className={`header_link ${visibleSection === "Overview_part" ? "selected" : ""}`}
              onClick={() => {
                scrollTo(overviewRef.current);
              }}
            >
              Overview
            </Box>
            <Box
              type="button"
              className={`header_link ${visibleSection === "Checklist_part" ? "selected" : ""}`}
              onClick={() => {
                scrollTo(checklistRef.current);
              }}
            >
              Checklist
            </Box>
            <Box
              type="button"
              className={`header_link ${visibleSection === "Register_part" ? "selected" : ""}`}
              onClick={() => {
                scrollTo(registerRef.current);
              }}
            >
              How To Register
            </Box>

            <Box
              type="button"
              className={`header_link ${visibleSection === "Document_part" ? "selected" : ""}`}
              onClick={() => {
                scrollTo(documentRef.current);
              }}
            >
              Documents Required
            </Box>
            <Box
              type="button"
              className={`header_link ${visibleSection === "Legal_part" ? "selected" : ""}`}
              onClick={() => {
                scrollTo(legalRef.current);
              }}
            >
              Why Legal Factory
            </Box>
            <Box
              type="button"
              className={`header_link ${visibleSection === "Faq_part" ? "selected" : ""}`}
              onClick={() => {
                scrollTo(faqRef.current);
              }}
            >
              FAQ'S
            </Box>
            </PLheader>
        </Sticky>
        
        </Grid>
        <Grid item md={9} lg={9} xl={9}
         sx={
          isMobile || isTablet ? { display: 'none' } : { }
        }>
        <Section id="Overview_part" ref={overviewRef}>
          <Overview/>
        </Section>
        <Topspacer/>
        <Section id="Checklist_part" ref={checklistRef}>
          <Checklist/>
        </Section>
        <Topspacer/>
        <Section id="Register_part" ref={registerRef}>
         <Register/>
        </Section>
        <Topspacer/>
        <Section id="Document_part" ref={documentRef}>
          <Document/>
        </Section>

        
        <Topspacer/>
        <Section id="Legal_part" ref={legalRef}>
         <Legalfactory/>
        </Section>
       <Topspacer/>
        <Section id="Faq_part" ref={faqRef}>
        <Faq/>
        </Section>
        </Grid>

       
          <GetInTouch Floatingicon="Partnership Firm"/>
        
        </Grid>
       </Content>
<Box sx={
          isMobile || isTablet ? {  } : {display:'none' }
        }>
          <Grid container>
          <Grid item xs={12}>
          <Grid item xs={12}>
          <Overview/>
          <br/>
          <Checklist/>
          <br/>
          <Register/>
          <br/>
          <Document/>
          <br/>
          <Legalfactory/>
          <br/>
          <Faq/>
          </Grid>
          
          </Grid>
          </Grid>
        </Box>
      
      

      <Bottom_spacer/>
      
      
    </div>
           
           </Container>
           </div>
        </div>
    )
}

export default PartnershipFirm