import React from 'react'
import Mainhead from "../../../../Components/Services/MainContent/Mainhead";
import Step from "../../../../Components/Services/MainContent/Step";
import Content from "../../../../Components/Services/MainContent/Content";
import { Box , styled} from '@mui/material';
import Threeproducer from "../../../../Assets/Services/ProducerCompany/LegalFactory_Producer2.png"

const Producer = styled(Box)(({ theme }) => ({
  width:'48rem',
  height:'10rem',
  marginTop:'0.8rem',
  [theme.breakpoints.down("sm")]: {
   width:'18rem',
 height:'8rem',
 },
 [theme.breakpoints.down("md")]: {
   width:'18rem',
 height:'8rem',
 },
 [theme.breakpoints.between("md", "lg")]: {
  width:'30rem'
 },
 [theme.breakpoints.up("xl")]: {
   width:'48rem'
  }
}));

function Advantages() {
  return (
    <div>
         <Mainhead heading="Advantages of a Producer Company" />
         <Step number="SEPARATE LEGAL ENTITY:"/>
         <Content paragraph="In any Business there exists a risk of not being able to repay their liabilities. It is necessary evil. In the event that this occurs, an individual producer is personally liable for all the debts of the business. The members though, have unlimited liability as the company itself is an entity. Therefore, the amount lost would only be the one invested in the business; however, the personal property of the directors would be safe."/>

         <Step number="ECONOMIC ADVANTAGES:"/>
         <Content paragraph="Out of all the Indian farmers , only 15% own over two acres of land. The majority of them are, therefore, unable to unlock the advantages that come with economies of scale. However, with a producer company, multiple farmers can work as a collective and lower costs, reduce risk and even get access to better credit facilities. Hence, enabling them to do better planning and bargaining with buyers."/>

         <Step number="FLEXIBLE MANAGEMENT:"/>
         <Content paragraph="Instead of a single farmer that manages the entire business, work within the producer company can be divided between its directors. It is mainly managed by the Board of Management, who have a tenure of five years. The Producer company, also has a separate legal existence, which means that it isn't affected by the death of any of its members"/>

         <Step number="TAX EXCLUSION/EXEMPTION"/>
         <Content paragraph="Any registered Producer Company can enjoy tax benefits like exemption from agricultural income under the section 10(1) of the income tax act, 1961. The exemption varies on the activities that the farmers carry out such as the agriculture income is 100% exempted from the income tax while the income that is earned from the production of green tea is 60% exempted as per the law."/>

         <Producer component="img"
                sx={{
                  content: {
                    xs: `url(${Threeproducer})`
                  }
                }}/>
         
      
    </div>
  )
}

export default Advantages
