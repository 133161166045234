import { List, ListItem, Typography, styled } from "@mui/material";

import StarIcon from "@mui/icons-material/Star";

const Staricon = styled(Typography)(({ theme }) => ({
  fontSize: "1rem",
  fontWeight: 450,
  marginTop: "0.5rem",
  [theme.breakpoints.down("sm")]: {
    fontSize: "0.85rem",
  },
  [theme.breakpoints.between("md", "lg")]: {
    fontSize: "0.8rem",
  },
  [theme.breakpoints.up("xl")]: {
    fontSize: "1.1rem",
  },
}));

const Points = ({ point }) => {
  return (
    <ListItem>
      <Staricon>
        <StarIcon style={{ marginRight: "0.5rem" }} />
        {point}
      </Staricon>
    </ListItem>
  );
};
const CustomList = ({ points }) => {
  const listItems = points.map((point, index) => (
    <Points key={index} point={point} />
  ));

  return <List>{listItems}</List>;
};

export default CustomList;
