import React from 'react'
import Mainhead from '../../../../Components/Services/MainContent/Mainhead';
import Content from '../../../../Components/Services/MainContent/Content';
import List from '../../../../Components/Services/MainContent/List';
import { Box, styled } from '@mui/material';
import Tablet from "../../../../Assets/Services/Private/LegalFactory_Private2.png"

const Tabletimg = styled(Box)(({ theme }) => ({
  width: '42rem',
  height: '10rem',
  marginTop: '0.8rem',
  [theme.breakpoints.down("sm")]: {
    width: '16rem',
    height: '8rem',
    
  },
  [theme.breakpoints.down("md")]: {
    width: '18rem',
    height: '8rem',
  },
  [theme.breakpoints.between("md", "lg")]: {
    width: '30rem'
  },
  [theme.breakpoints.up("xl")]: {
    width: '48rem'
  }
}));



function Overview() {
  return (
    <div>
      <Mainhead heading="Online Company Registration in India - An Overview" />
      <Content paragraph="The Xpert Finance empowered by LEGAL FACTORY having decades of experience of setting up and arranging compliances for private limited company in India . The provisions of setting up a making compliances for a private limited company are being provided and governed by the provisions of The Companies Act 2013 and rules there under . Setting up a business in corporate form is highly recommended forms of setting and running up business in India since a private limited company gives you the following advantages" />
      <ul className='list'>
        <li className='bullet'><span>
      <List points="No personal liability for losses and other risks." />
      </span>
      </li>
      <li className='bullet'>
      <span>
      <List points="Scalability of operations." />
      </span>
      </li>
      <li className='bullet'>
      <span>
      <List points="Making and managing operations at scale." />
      </span>
      </li>
      <li className='bullet'>
      <span>
      <List points="Better brand image." />
      </span>
      </li>
      <li className='bullet'>
      <span>
      <List points="Easy access to funds in form of debt and equity." />
      </span>
      </li>
      </ul>
      <Content paragraph="In a private limited company the persons who invest in company are known as Shareholders and the one who run and manage the day to day show and operations of the company are known as Directors" />
      <Content paragraph="The Xpert Finance powered with LEGAL FACTORY arranges the setting up and registration of company and also arranges you to make necessary compliances once you receive the certificate of incorporation from the centralized registrar of companies." />

      <Tabletimg component="img"
        sx={{
          content: {
            xs: `url(${Tablet})`
          }
        }} />






    </div>
  )
}

export default Overview