import React from 'react';
import { Typography,styled, useTheme } from '@mui/material';

const Servicecontent = styled(Typography)(({ theme }) => ({
    color:'#000000',
    fontSize:'1rem',
    fontWeight:450,
    lineHeight:'1.8rem',
    marginTop:'0.5rem',
    [theme.breakpoints.down("sm")]: {
      marginLeft:'0.8rem',
      lineHeight:'1.6rem',
      fontSize:'0.85rem'
         },
        
   [theme.breakpoints.between("md", "lg")]: {
          fontSize:'0.75rem',
          lineHeight:'1.8rem'
        },

        [theme.breakpoints.up("xl")]: {
          fontSize:'1.1rem',
          lineHeight:'1.8rem'
        }
}));

function paragraph(props) {
  return (
    <div>
        <Servicecontent>
          {props. paragraph}
        </Servicecontent>
    </div>
  )
}

export default paragraph;