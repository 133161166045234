const faqData = [
    {
        id: 'panel1',
        question: 'Are there specific deductions available for business and profession income?',
        answer: 'Yes, both businesses and professions can claim deductions for expenses incurred wholly and exclusively for the purpose of earning income. These deductions include expenses such as rent, salaries, office expenses, depreciation, travel, and other legitimate business or profession-related expenses.',
    },
    {
        id: 'panel2',
        question: 'Is there a difference in the taxation of income from a business and income from a profession?',
        answer: "In terms of taxation, both business income and profession income fall under the category of 'income from business and profession.' However, certain professions might have specific provisions or deductions tailored to their nature of work or expenses.",
    },
    {
        id: 'panel3',
        question: 'Can a person have both a business and a profession?',
        answer: 'Yes, an individual or entity can engage in both business and professional activities simultaneously. For instance, a doctor (profession) might also own and operate a pharmacy (business) alongside providing medical services.',
    },
    {
        id: 'panel4',
        question: 'How are business and profession activities differentiated for tax purposes?',
        answer: 'Business activities often involve trade, commerce, manufacturing, or retailing of goods, while profession activities are generally based on specialized knowledge or skills offering services in fields like healthcare, law, engineering, etc.',
    },

    {
        id: 'panel5',
        question: 'What are allowable business expenses?',
        answer: 'Allowable business expenses are costs that are incurred wholly and exclusively for the purpose of the business. These can include rent, salaries, utilities, office supplies, advertising, insurance, travel expenses, depreciation, etc.',
    },

    {
        id: 'panel6',
        question: 'Are all expenses incurred in the course of business deductible for tax purposes?',
        answer: 'No, only expenses that are directly related to the business or profession and incurred for its operation or to earn income are deductible. Personal expenses or those not related to the business are not deductible.',
    },
    {
        id: 'panel7',
        question: 'Can I claim expenses without proper documentation?',
        answer: 'The tax authority often requires proper documentation (such as receipts, invoices, bills) as evidence to support expense claims. Lack of proper documentation might lead to the disallowance of the claimed expenses.',
    },
    {
        id: 'panel8',
        question: 'Are there specific guidelines for claiming vehicle expenses?',
        answer: 'Yes, vehicle expenses can be claimed based on actual expenses incurred or by using standard mileage rates set by tax authorities. However, there are rules and restrictions on claiming vehicle expenses, and maintaining a logbook is often required.',
    },
    {
        id: 'panel9',
        question: 'Can I deduct home office expenses?',
        answer: 'Yes, if you use a portion of your home regularly and exclusively for business purposes, you can deduct a portion of expenses like rent, utilities, insurance, etc., based on the proportion of space used for business.',
    },
    {
        id: 'panel10',
        question: 'Are entertainment expenses deductible?',
        answer: 'Entertainment expenses are subject to specific rules and limitations. Generally, only 50% of allowable entertainment expenses are deductible for tax purposes.',
    },
    {
        id: 'panel11',
        question: 'Can I claim expenses that were incurred before starting the business?',
        answer: 'Usually, expenses incurred before the start of the business are not deductible. However, some expenses might be eligible for deduction as startup costs under certain conditions.',
    },
    {
        id: 'panel12',
        question: 'Are professional fees and subscriptions deductible?',
        answer: 'Yes, fees paid to professionals, such as legal or accounting fees, and subscriptions related to the business or profession are usually deductible.',
    },
    {
        id: 'panel13',
        question: 'Are travel expenses deductible?',
        answer: 'Travel expenses that are incurred for business purposes are generally deductible. This includes airfare, accommodation, meals, and other related expenses. However, personal portions of such expenses are not deductible.',
    },
    {
        id: 'panel14',
        question: 'What should I do if I\'m unsure about the deductibility of an expense?',
        answer: 'It\'s advisable to seek advice from a tax professional or accountant who can provide guidance based on the specific circumstances and tax regulations.',
    },
    {
        id: 'panel15',
        question: 'In terms of Section 28 of the Income Tax Act, what does "profits and gains of business or profession" mean?',
        answer: 'A taxpayer\'s profits and gains of business or profession are defined as their business or profession\'s income less any expenses paid solely and exclusively for the purpose of operating their business or profession.',
    },
    {
        id: 'panel16',
        question: 'Are there any special rules under Section 28 pertaining to the taxation of limited liability partnerships and partnerships?',
        answer: 'Yes, there are particular rules in Section 28 that deal with how limited liability partnerships and partnerships in general are taxed. For instance, the clause permits taxation on a partner\'s portion of the partnership\'s gains and profits as well as any interest, pay, commission, bonus, or other compensation the partner receives from the business.',
    },
    {
        id: 'panel17',
        question: 'Does Section 28 apply to income from profits and gains of a business or profession that includes revenue from agricultural activities?',
        answer: 'No, income from agricultural operations is taxed under a different head of income and is not included in the income from profits and gains of business or profession under Section 28.',
    }, {
        id: 'panel18',
        question: 'Can the business losses of a deceased assessee be carried forward and set off by his/her heir?',
        answer: 'As per section 78(2), in case of inheritance the heir is entitled to carry forward and set off losses of the previous owner who originally suffered such losses.',
    },
    {
        id: 'panel19',
        question: 'Is it required to file ITR for carrying forward business losses?',
        answer: 'Yes, as per the Income Tax Department, you can only carry forward business losses if you have filed the ITR return for the relevant year in which you incurred losses.',
    },
    {
        id: 'panel20',
        question: 'If I fail to file the return within the due date, will I be allowed to carry forward business loss on filing a belated return?',
        answer: 'No, you will not be able to carry forward business losses in such a case.',
    },
    {
        id: 'panel21',
        question: 'What is Freelancing?',
        answer: 'Freelancing refers to working independently and offering services, skills, or expertise to clients or businesses on a contractual basis rather than being employed by a single employer.',
    },
    {
        id: 'panel22',
        question: 'What Services Can I Offer as a Freelancer?',
        answer: 'Freelancers can provide a wide range of services such as writing, graphic design, web development, programming, marketing, consulting, video editing, translation, and more, depending on their expertise.',
    },
    {
        id: 'panel23',
        question: 'How Do I Find Clients as a Freelancer?',
        answer: 'Finding clients involves networking, leveraging online platforms and marketplaces, showcasing a portfolio, using social media for promotion, attending industry events, and referrals from previous clients.',
    },
    {
        id: 'panel24',
        question: 'How Do I Determine My Freelance Rates?',
        answer: 'Freelance rates can be determined based on factors like your skill level, expertise, industry standards, project complexity, market demand, and the value you provide to clients.',
    },
    {
        id: 'panel25',
        question: 'What Tax Obligations Do I Have as a Freelancer?',
        answer: 'Freelancers are usually considered self-employed and are responsible for paying income tax on their earnings. They may also need to pay self-employment tax or national insurance contributions based on local tax laws.',
    },
    {
        id: 'panel26',
        question: 'What Expenses Can I Deduct as a Freelancer?',
        answer: 'Deductible expenses for freelancers typically include home office expenses, equipment, software, office supplies, travel, marketing, professional fees, and other costs directly related to their freelance work.',
    },
    {
        id: 'panel27',
        question: 'How Should I Invoice Clients as a Freelancer?',
        answer: 'Invoices should include your contact information, client details, a description of services rendered, payment terms, due date, and any other relevant information specific to the project or agreement.',
    },
    {
        id: 'panel28',
        question: 'What Should I Do for Retirement Planning as a Freelancer?',
        answer: 'Freelancers can set up retirement accounts like Individual Retirement Accounts (IRAs), Simplified Employee Pension (SEP) IRAs, or other pension plans to save for retirement.',
    },    // FAQs on Tax Audit
    {
        id: 'taxAudit1',
        question: 'What documents are required for a tax audit?',
        answer: 'Taxpayers need to maintain and provide relevant books of accounts, financial statements, ledgers, vouchers, and other documents related to income, expenses, investments, loans, etc., for scrutiny during the tax audit.',
    },
    {
        id: 'taxAudit2',
        question: 'Can a tax audit be conducted electronically?',
        answer: 'The tax audit report (Form 3CD) needs to be filed electronically through the Income Tax Department\'s official e-filing portal in XML format.',
    },
    {
        id: 'taxAudit3',
        question: 'Are there any exemptions from tax audit under Section 44AB?',
        answer: 'Certain businesses or professions might be exempt from tax audit under specific conditions or categories as provided for in the Income Tax Act. However, these exemptions usually come with specific criteria and conditions.',
    },
    {
        id: 'taxAudit4',
        question: 'Can the tax audit report be revised after filing?',
        answer: 'Yes, if any errors or discrepancies are identified after filing the tax audit report, taxpayers can revise the report before the due date for filing the income tax return. It\'s important to rectify any mistakes at the earliest.',
    },
    {
        id: 'taxAudit5',
        question: 'What are the consequences of not complying with tax audit provisions?',
        answer: 'Non-compliance with tax audit provisions may lead to penalties, scrutiny assessments, and legal implications. It could also result in disallowance of expenses or deductions claimed by the taxpayer.',
    },
    {
        id: 'taxAudit6',
        question: 'Is professional assistance necessary for tax audit compliance?',
        answer: 'Given the complexities involved, seeking professional advice from a Chartered Accountant or tax consultant is advisable to ensure proper compliance with tax audit requirements and avoid penalties or issues with tax authorities.',
    },

    // FAQs on Deemed Business Income
    {
        id: 'deemedBusiness1',
        question: 'What are the common instances where income is treated as deemed business income?',
        answer: 'Instances include the remission or cessation of a trading liability, recovery of bad debts for which a deduction was earlier claimed, and certain provisions of Section 28 to 43 relating to specific incomes deemed to be business income.',
    },
    {
        id: 'deemedBusiness2',
        question: 'How does the remission of trading liability lead to deemed business income?',
        answer: 'When a trading liability for which a deduction was claimed in an earlier year ceases to exist or is remitted, the amount of such remission is treated as deemed income chargeable to tax in the year of remission under Section 41 of the Income Tax Act.',
    },
    {
        id: 'deemedBusiness3',
        question: 'Are there specific provisions in the Income Tax Act that deem certain incomes as business income?',
        answer: 'Yes, various sections from 28 to 43 of the Income Tax Act provide for specific incomes that are deemed to be business income, such as income from profession, speculative business income, income from owning and maintaining racehorses, etc.',
    },
    {
        id: 'deemedBusiness4',
        question: 'How is deemed business income taxed?',
        answer: 'Deemed business income is taxed in the year in which the event leading to such income occurs (e.g., remission of liability, recovery of bad debts) as per the applicable tax rates and provisions under the Income Tax Act.',
    },
    {
        id: 'deemedBusiness5',
        question: 'Is there any specific documentation required for reporting deemed business income?',
        answer: 'Taxpayers should maintain proper records and documentation related to the remission of liabilities, recovery of bad debts, or any other instances leading to deemed business income to ensure accurate reporting and compliance with the Income Tax Act.',
    },
    {
        id: 'deemedBusiness6',
        question: 'What happens if a taxpayer fails to report deemed business income?',
        answer: 'Non-reporting of deemed business income or non-compliance with the provisions of the Income Tax Act regarding such income may lead to penalties, scrutiny assessments, and legal implications.',
    },

    // FAQs on Depreciation
    {
        id: 'depreciation1',
        question: 'What is the rate of depreciation of the solar power generation system?',
        answer: 'A solar power system depreciates at a rate of 40%.',
    },
    {
        id: 'depreciation2',
        question: 'Is it possible to claim depreciation on personal assets?',
        answer: 'No, only assets utilized for commercial or professional reasons can be depreciated.',
    },
    {
        id: 'depreciation3',
        question: 'Is it possible to claim depreciation in the year that an asset is purchased?',
        answer: 'Depreciation can be claimed only in the year when an asset is used for commercial purposes.',
    },
    {
        id: 'depreciation4',
        question: 'Can depreciation be claimed on fully depreciated assets?',
        answer: 'No, depreciation cannot be claimed on fully depreciated assets.',
    },

    // FAQs on Section 44AD
    {
        id: 'section44AD1',
        question: 'What does section 44AD mean by eligible business?',
        answer: 'Any business with a preceding year\'s annual turnover or gross receipts of less than Rs. 3 crores. Except for playing, recruiting, and agency business, the business can be anything.',
    },
    {
        id: 'section44AD2',
        question: 'Who is eligible for the Section 44AD benefit?',
        answer: 'Individuals/HUFs/Partnership firms other than LLP are eligible to get benefits.',
    },
    {
        id: 'section44AD3',
        question: 'How is presumptive income determined?',
        answer: 'The presumptive income is estimated at 8% of the previous year\'s total sales or gross receipts. If the company\'s transactions are done digitally, the rate drops to 6% of total turnover or gross receipts.',
    },
    {
        id: 'section44AD4',
        question: 'Can section 44AD and 44ADA be utilized concurrently?',
        answer: 'Yes, both sections can be used at the same time.',
    },
    {
        id: 'section44AD5',
        question: 'Do I have to pay advance tax if I use Section 44AD\'s Presumptive Taxation Scheme?',
        answer: 'Yes. You must pay advance tax if your total tax due for the fiscal year exceeds INR 10,000. If you have chosen the presumptive taxation system under sections 44AD or 44ADA, you must pay advance tax on or by March 15th instead of four installments in other situations. However, if you fail to pay advance tax by the 15th March of the fiscal year, you must pay interest under Sections 234B and 234C.',
    },
    {
        id: 'section35_1',
        question: 'Which expenses fall under Section 35(1)(ii)\'s deductible category?',
        answer: 'This clause allows for the deduction of both revenue and capital expenditures used for scientific research.',
    },
    {
        id: 'section35_2',
        question: 'According to Section 35(1)(ii), who is the designated authority for approving scientific research?',
        answer: 'The Department of Scientific and Industrial Research (DSIR), a division of the Ministry of Science and Technology, is the designated authority.',
    },
    {
        id: 'section35_3',
        question: 'Can new businesses seek a deduction under Section 35(1)(ii)?',
        answer: 'Yes, scientific research start-ups can claim a deduction under this section, subject to clearance from the designated body.',
    },
    {
        id: 'section35_4',
        question: 'What documents and records are necessary to claim a deduction under Section 35(1)(ii)?',
        answer: 'Businesses must keep records and paperwork detailing the nature and purpose of the research, the amount of money spent, and the approvals acquired from the appropriate authorities.',
    },

    // FAQs on expenditure related to Business
    {
        id: 'expenditureBusiness1',
        question: 'Is it necessary to withhold TDS on non-resident salaries in accordance with Section 195 or Section 192?',
        answer: 'Salary is expressly excluded by Sec. 195. Therefore, TDS should be withheld in accordance with Section 192 when paying a non-resident\'s salary.',
    },
    {
        id: 'expenditureBusiness2',
        question: 'Non-monetary prerequisites: what are they?',
        answer: 'Employers provide a free facility or benefit to the employee. For instance - Rent-free accommodation, Medical facilities, Recreational facilities, Education to children of Employee.',
    },
    {
        id: 'expenditureBusiness3',
        question: 'According to Section 44AD, can XYZ and company deduct interest and compensation given to its partners from its net profit?',
        answer: 'No, if the assessee has chosen presumptive taxation, it is not permitted to deduct compensation and interest paid to its partners from net earnings.',
    },
    {
        id: 'expenditureBusiness4',
        question: 'What happens if an expense is approved on time and is paid for with cash the next year?',
        answer: 'According to Section 40A(3A), in the event that an expense is approved on time and the assessee makes a payment in the following year that exceeds Rs. 10,000.00 using a method other than those specified, it will be considered business income and subject to taxation.',
    },

    // FAQs On Maintenance Of Books Of Accounts 
    {
        id: 'booksMaintenance1',
        question: 'For what period is a business entity required to maintain books of accounts?',
        answer: 'Books of account and documents should be kept and maintained for a period of 6 years from the end of the relevant assessment year.',
    },
    {
        id: 'booksMaintenance2',
        question: 'What is the penalty if the assessee fails to maintain books of accounts?',
        answer: 'If an assessee fails to maintain or retain books of account and other documents for the specified period in accordance with this provision, a penalty may be imposed under Section 271A of Rs. 25,000.',
    },
    {
        id: 'booksMaintenance3',
        question: 'If a person engaged in the medical profession, which type of books maintain?',
        answer: 'Individuals working in the medical field must also keep a daily case log in the required Performa (Form No. 3C) and an inventory of the supplies of drugs, medications, and other consumable accessories used for the profession at the start and end of the year.',
    },
    {
        id: 'booksMaintenance4',
        question: 'When a person fails to keep accounting records?',
        answer: 'No books of accounts shall be kept in accordance with Section 44AA (2) if the income, gross receipts, or gross turnover of an individual engaged in business or a profession other than those specified under Section 44AA (1) do not exceed in any one of the three years prior to the previous year.',
    },

    // FAQs Related to Section 28
    {
        id: 'section28_1',
        question: 'What are Income Tax Sections 28 through 44?',
        answer: 'The provisions pertaining to the calculation of income under the taxable head "Profits and gains of business or profession" are covered by Sections 28 through 44.',
    },
    {
        id: 'section28_2',
        question: 'Does section 28 apply to income from agricultural activities?',
        answer: 'No, Section 28 does not apply to income derived from agricultural activities.',
    },
    {
        id: 'section28_3',
        question: 'What are amendments to Section 28?',
        answer: 'The Finance Bill 2023 suggests a change to section 28. The amendment impacts section 28\'s clause (vi), which clarifies that the clause encompasses circumstances in which the benefit or perquisite is provided in cash, in kind, or in both. The change will start on 1st April 2024.',
    },

    // FAQs related to Section 44AE
    {
        id: 'section44AE1',
        question: 'How does section 44AE\'s presumptive taxation structure apply?',
        answer: 'One may use the presumptive taxation plan under section 44AE if they are involved in the following types of businesses: Carriage of goods: plying, hiring, leasing. Assuming that at no point in the preceding year did he or she own more than 10 goods cars.',
    },
    {
        id: 'section44AE2',
        question: 'What if the taxpayer owns more than 10 goods carriages at any time during the PY?',
        answer: 'Under section 44AE, the taxpayer will be treated as a regular business and is not permitted to choose the presumptive taxation plan.',
    },
    {
        id: 'section44AE3',
        question: 'What is meant by a heavy goods vehicle for the purpose of section 44AE?',
        answer: 'A "heavy goods vehicle" is any goods carriage whose gross vehicle weight is more than 12 tonnes, or 12,000 kilograms.',
    },
    {
        id: 'section44AE4',
        question: 'Should I maintain books of accounts if I opt to pay tax under section 44AE?',
        answer: 'No, there will be no need to maintain the books of accounts as required by section 44AA.',
    },

    // FAQs on Profit and Gains from Business & Profession
    {
        id: 'profitGains1',
        question: 'How many accounting procedures are there under profit and gains from business & profession to determine income?',
        answer: 'Income under Business & Profession is calculated under Sec. 145 using the standard accounting procedure that the assesses use. The Mercantile system of accounting and the Cash system are the two accepted approaches.',
    },
    {
        id: 'profitGains2',
        question: 'In business and profession, what do the terms "cash system" and "mercantile system" signify in accounting?',
        answer: 'Cash System: Under this system, all income and expenses are recorded as soon as they are received. Accounting for Mercantile Systems: All income and expenses are recorded on an accrual basis.',
    },
    {
        id: 'profitGains3',
        question: 'What is Section 29 of the Income Tax Act?',
        answer: 'The guidelines in sections 30 to 43D will be followed in calculating the income mentioned in section 28.',
    },
    {
        id: 'section40A(3)1',
        question: 'What does the Income Tax Act\'s Section 40A(3) mean?',
        answer: 'A business\'s ability to deduct certain expenses from its income tax return is restricted by Section 40A(3) of the Income Tax Act if the payment is made in cash and exceeds a predetermined threshold.',
    },
    {
        id: 'section40A(3)2',
        question: 'What is the maximum amount that can be paid with cash under Section 40A(3)?',
        answer: 'As per Section 40A(3), the maximum amount that can be paid in cash is Rs. 10,000. Overpayment in cash will prevent the expense from being deducted from income on the income tax return.',
    },
    {
        id: 'section40A(3)3',
        question: 'What happens if a payment in cash exceeding Rs. 10,000 is made?',
        answer: 'Any cash payment made in excess of Rs. 10,000 cannot be claimed as a deduction on the income tax return. The expense that is not permitted is added back to the business\'s revenue and taxed appropriately.',
    },
    {
        id: 'section40A(3)4',
        question: 'Is there any penalty for making cash payments exceeding Rs. 10,000?',
        answer: 'The payment of more than Rs. 10,000 in cash is subject to a penalty. The fine is the same as the cash outlay and cannot be deducted from income on your income tax return.',
    },

    // FAQs relating to Remuneration and Interest allowable under Income Tax Act, 1961
    {
        id: 'remunerationInterest1',
        question: 'What is Section 40(b) of the Income Tax Act?',
        answer: 'Section 40(b) pertains to the provisions of deduction for expenses that are specifically not allowed or are subject to certain conditions in case of partnerships, specifically for remuneration and interest paid to partners.',
    },
    {
        id: 'remunerationInterest2',
        question: 'What kind of expenses are covered under Section 40(b)?',
        answer: 'This section primarily deals with the deduction of remuneration and interest paid to partners in a partnership firm while calculating the firm\'s taxable income.',
    },
    {
        id: 'remunerationInterest3',
        question: 'Can all partnership firms claim a deduction under Section 40(b)?',
        answer: 'Yes, partnership firms are allowed to claim deductions for the remuneration and interest paid to partners as per the provisions and conditions specified in Section 40(b) of the Income Tax Act.',
    },
    {
        id: 'remunerationInterest4',
        question: 'Are there any specific conditions to claim deductions under Section 40(b)?',
        answer: 'Yes, there are certain conditions specified under Section 40(b) that need to be fulfilled for claiming deductions. For instance, the remuneration should be authorized by the partnership deed and should not exceed the prescribed limit. Similarly, interest paid to partners should also comply with the conditions outlined in the section.',
    },
    {
        id: 'remunerationInterest5',
        question: 'What are the limits for claiming deductions under Section 40(b)?',
        answer: 'The allowable deduction for remuneration and interest to partners is subject to certain limits as specified in the Income Tax Act. The maximum limit for remuneration to working partners and interest to partners is determined based on the prescribed percentages or amounts specified in the Act.',
    },
    {
        id: 'remunerationInterest6',
        question: 'What happens if the remuneration or interest exceeds the prescribed limits?',
        answer: 'If the remuneration or interest paid to partners exceeds the limits specified in Section 40(b), the excess amount will not be allowed as a deduction while computing the taxable income of the partnership firm.',
    },
    {
        id: 'remunerationInterest7',
        question: 'How is the computation of taxable income affected by Section 40(b)?',
        answer: 'Section 40(b) affects the computation of taxable income of a partnership firm by allowing deductions for remuneration and interest to partners within the specified limits, thereby reducing the taxable income of the firm.',
    },

    // FAQs RELATING TO ADDITIONAL DEPRECIATION IN INCOME TAX ACT,1961
    {
        id: 'additionalDepreciation1',
        question: 'What is the benefit of additional depreciation?',
        answer: 'Additional depreciation helps the assessee to save taxes by reducing the total taxable income. This maintains a better cash flow in the business. It gives an opportunity to manufacturing houses to use the best technology for their production.',
    },
    {
        id: 'additionalDepreciation2',
        question: 'How to claim additional depreciation?',
        answer: 'The following steps are followed: Calculate the cost of the asset and the rate of additional depreciation applicable. Claim the normal depreciation. Then, claim the additional depreciation. The total amount of depreciation will be reduced from the taxable income.',
    },


    // FAQs ON SECTION 44ADA
    {
        id: 'section44ADA1',
        question: 'Difference between 44 AD and 44ADA?',
        answer: '44 AD prescribes presumptive taxation for small businesses while 44 ADA prescribes presumptive taxation for small professionals.',
    },
    {
        id: 'section44ADA2',
        question: 'Which ITR is to be filed for presumptive taxation?',
        answer: 'Taxpayers opting for presumptive taxation shall file ITR 4 for income under business and profession. If there is any capital gain income as well, they are required to file ITR 3.',
    },
    {
        id: 'section44ADA3',
        question: 'Do I have to get my books audited if I choose presumptive taxation?',
        answer: 'You will only be required to get your books audited if you declare a profit less than 50% of your income.',
    },
    {
        id: 'section44ADA4',
        question: 'Should I choose presumptive taxation if my net profit ratio is low?',
        answer: 'No, you should avoid choosing presumptive taxation in case of low net profit ratios as you will compulsorily have to declare at least 50% of your gross receipts as your profit which may be more than your actual profits.',
    },

    // FAQs RELATED TO SECTION 43
    {
        id: 'section43_1',
        question: 'What is depreciation?',
        answer: 'Depreciation is the reduction in the value of assets over time due to wear and tear, technological obsolescence, or other factors.',
    },
    {
        id: 'section43_2',
        question: 'How is section 43 important for businesses?',
        answer: 'This section is crucial for businesses as it outlines how to calculate depreciation on various assets used for business purposes, allowing them to claim tax deductions.',
    },

    // FAQs RELATED TO SECTION 40(a) AND 40(ia)
    {
        id: 'section40a40ia_1',
        question: 'WHAT EXPENSES ARE COVERED UNDER THESE SECTIONS?',
        answer: 'Expenses such as interest, commission, brokerage, rent, royalty, fees for professional services, fees for technical services etc are covered under these sections.',
    },
    {
        id: 'section40a40ia_2',
        question: 'Which section will be applicable if TDS has been deducted but not paid timely?',
        answer: 'In such a case, Section 40 (a) (ia) will be applicable instead of Section 40 (a) (i).',
    },
    {
        id: 'section40a40ia_3',
        question: 'On whom are these sections applicable?',
        answer: 'These sections are applicable to all taxpayers, including individuals, Hindu Undivided Families (HUFs), partnership firms, companies, and any other entity.',
    },
    {
        id: 'section40a40ia_4',
        question: 'What is the disallowance under these sections?',
        answer: 'Amount paid or credited to Non resident or Foreign company if TDS has not been deducted in P.Y. Tds deducted but not paid to the government up to the due date of return filing. Then such sum (100%) shall not be allowed as deduction in the current P.Y. Amount paid or credited to resident and if TDS has not been deducted in P.Y. Tds deducted but not paid to the government up to the due date of return filing. Then 30 % of such sum shall not be allowed as deduction in the current P.Y.',
    },
];

export default faqData;

