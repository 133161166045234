import React from 'react';
import { Typography } from '@mui/material';
import { Box } from '@mui/material';
import { createTheme } from '@mui/material/styles';
import { useMediaQuery } from '@mui/material';

import AccessTimeIcon from '@mui/icons-material/AccessTime';
import PersonAddAltIcon from '@mui/icons-material/PersonAddAlt';
import TextSnippetOutlinedIcon from '@mui/icons-material/TextSnippetOutlined';
import CurrencyRupeeIcon from '@mui/icons-material/CurrencyRupee';
import CurrencyRupeeOutlinedIcon from '@mui/icons-material/CurrencyRupeeOutlined';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import GroupsOutlinedIcon from '@mui/icons-material/GroupsOutlined';
import { styled } from '@mui/material/styles';

const MarketingComponent = () => {

     
    
    const theme = createTheme({
        palette: {
            primary: {
            light: '#E99101',
            main: '#E99101',
            dark: '#E99101',
            contrastText: '#ffffff',
            },
            secondary: {
            light: '#E99101',
            main: '#1976D2',
            dark: '#E99101',
            contrastText: '#ffffff',
            },
            
        },
        typography:{
        fontFamily:'Sora'
    }
        });

        const smDown = useMediaQuery(theme.breakpoints.down("sm"));
        const mdDown = useMediaQuery(theme.breakpoints.down("md"));
        const mdlg = useMediaQuery(theme.breakpoints.between("md", "lg"));

        const Iconhead_marketing = styled(Typography)(({ theme }) => ({
          color: '#281900',
          fontWeight: '700 ',
          textAlign: 'left',
          fontStyle: 'normal',
          display:'',
          flexDirection:'column',
          alignItems:'center',
          textWrap:'wrap',
         
          [theme.breakpoints.up("lg")]: {
              fontSize: '1.1rem ',
              lineheight:'1.75rem',
              fontWeight: '700',
              
              textAlign:'left',
              marginBottom:'0rem',
              margintop:'1rem',
              
              
          },
          [theme.breakpoints.up("xl")]: {
              fontSize: '1.5rem ',
              lineheight:'1.75rem',
              textAlign:'left',
              
              marginBottom:'0rem',
              margintop:'2rem'
    
          },
          [theme.breakpoints.between("md", "lg")]: {
              fontSize: '1.2rem',
              lineheight:'2rem',
             textAlign:'left',
             
              
              margintop:'1.8rem' ,
              width:'100%',
          },
         
          [theme.breakpoints.down("md")]: {
              fontSize: '0.78rem ',
              lineheight:'1rem',
             textAlign:'left',
              
              // marginBottom:'0rem',
              margintop:'1.8rem',
              width:'120%',
          },
          
          [theme.breakpoints.down("sm")]: {
              fontSize: '0.49rem ',
              lineheight:'0.75rem',
              textAlign:'left',
              marginBottom:'0rem',
              
              // width:'45%',
              // marginTop:'1rem' 
          },
    
      }));
   
      if(smDown){
        return(
            <>
                    <Box sx={{justifyContent:'space-around'}}>

                    <div style={{marginTop:'10px', marginRight:'10px'}}>
                    <PersonAddAltIcon fontSize='large'
                                sx={{background:'#F19E1E', 
                                borderRadius:'50%',
                                padding:'10px',
                                color:'white',
                                fontSize:"20px"}}/>
                              <Iconhead_marketing>Dedicated
                                <br/>
                                 Admin 
                                 <br/>
                                 Manager
                                 </Iconhead_marketing>  
                    </div>
                              <div style={{marginTop:'10px', marginRight:'10px'}}>
                              <TextSnippetOutlinedIcon fontSize='large'
                                sx={{background:'#FFC700', 
                                borderRadius:'50%',
                                padding:'10px',
                                color:'white',
                                fontSize:"20px"}}/>
                                <Iconhead_marketing>Organized
                                <br/>
                                 Documentation</Iconhead_marketing>
                              </div>
                                <div style={{marginTop:'10px', marginRight:'10px'}}>
                                <LockOutlinedIcon fontSize='large'sx={{background:'#AECA00', borderRadius:'50%',
                                padding:'10px',
                                color:'white',
                                fontSize:"20px"}}/>
                            <Iconhead_marketing>100%
                              <br/>
                              Privacy 
                              <br/>
                              Guaranted
                              </Iconhead_marketing>
                                </div>
                    </Box>
                    <Box>
                          <div style={{marginTop:'10px'}}>
                          <AccessTimeIcon fontSize='large'
                                sx={{background:'#E32227', 
                                borderRadius:'50%',
                                padding:'10px',
                                
                                color:'white',
                                fontSize:"20px"}}/>
                              <Iconhead_marketing>24/7
                                <br/>
                               Customer
                               <br/>
                                Care
                                </Iconhead_marketing> 
                          </div>
                          <div style={{marginTop:'10px'}}> 
                          <CurrencyRupeeOutlinedIcon  fontSize='large'
                                sx={{background:'#0085FF', 
                                borderRadius:'50%',
                                padding:'10px',
                                color:'white',
                                fontSize:"20px"}}/>
                              <Iconhead_marketing>Affordable
                              <br/>
                               Prices
                               </Iconhead_marketing> 
                          </div>
                        <div style={{marginTop:'10px'}}>
                        <GroupsOutlinedIcon fontSize='large'sx={{background:'#2F1EF1', borderRadius:'50%',
                                padding:'10px',
                                color:'white',
                                fontSize:"20px"}}/>
                              <Iconhead_marketing>Team Of 
                              <br/>
                              Experts
                              <br/>
                              On Boards</Iconhead_marketing>
                        </div>
                              
                    </Box>
                     
           </> 
        )
      }
      else if(mdDown){
        return(
            <>
                    <Box sx={{justifyContent:'space-around'}}>

                    <div style={{marginTop:'10px', marginRight:'10px'}}>
                    <PersonAddAltIcon fontSize='large'
                                sx={{background:'#F19E1E', 
                                borderRadius:'50%',
                                padding:'10px',
                                height:'40px',
                                width:'40px',
                                color:'white',
                                fontSize:"20px"}}/>
                              <Iconhead_marketing>Dedicated
                                <br/>
                                 Admin 
                                 <br/>
                                 Manager
                                 </Iconhead_marketing>  
                    </div>
                              <div style={{marginTop:'10px', marginRight:'10px'}}>
                              <TextSnippetOutlinedIcon fontSize='large'
                                sx={{background:'#FFC700', 
                                borderRadius:'50%',
                                padding:'10px',
                                height:'40px',
                                width:'40px',
                                color:'white',
                                fontSize:"20px"}}/>
                                <Iconhead_marketing>Organized
                                <br/>
                                 Documentation</Iconhead_marketing>
                              </div>
                                <div style={{marginTop:'10px', marginRight:'10px'}}>
                                <LockOutlinedIcon fontSize='large'sx={{background:'#AECA00', borderRadius:'50%',
                                padding:'10px',
                                height:'40px',
                                width:'40px',
                                color:'white',
                                fontSize:"20px"}}/>
                            <Iconhead_marketing>100%
                              <br/>
                              Privacy 
                              <br/>
                              Guaranted
                              </Iconhead_marketing>
                                </div>
                    </Box>
                    <Box>
                          <div style={{marginTop:'10px'}}>
                          <AccessTimeIcon fontSize='large'
                                sx={{background:'#E32227', 
                                borderRadius:'50%',
                                padding:'10px',
                                height:'40px',
                                width:'40px',
                                color:'white',
                                fontSize:"20px"}}/>
                              <Iconhead_marketing>24/7
                                <br/>
                                Customer Care
                                <br/>
                                </Iconhead_marketing> 
                          </div>
                          <div style={{marginTop:'10px'}}> 
                          <CurrencyRupeeOutlinedIcon  fontSize='large'
                                sx={{background:'#0085FF', 
                                borderRadius:'50%',
                                padding:'10px',
                                height:'40px',
                                width:'40px',
                                color:'white',
                                fontSize:"20px"}}/>
                              <Iconhead_marketing>Affordable 
                                <br/>
                                Prices</Iconhead_marketing> 
                          </div>
                        <div style={{marginTop:'10px'}}>
                        <GroupsOutlinedIcon fontSize='large'
                                sx={{background:'#2F1EF1', 
                                borderRadius:'50%',
                                padding:'10px',
                                height:'40px',
                                width:'40px',
                                color:'white',
                                fontSize:"20px"}}/>
                              <Iconhead_marketing>Team Of
                              <br/> 
                              Experts
                              <br/>
                               On Boards</Iconhead_marketing>
                        </div>
                              
                    </Box>
                     
           </> 
        )
      }
        else{
            return(
                <>
                    <Box sx={{justifyContent:'space-around'}}>

                    <div style={{marginTop:'00px', marginRight:'10px'}}>
                    <PersonAddAltIcon fontSize='large'
                                sx={{background:'#F19E1E', 
                                borderRadius:'50%',
                                padding:'10px',
                                height:'50px',
                                width:'50px',
                                color:'white',
                                fontSize:"20px"}}/>
                              <Iconhead_marketing>Dedicated
                                <br/>
                                 Admin 
                                 <br/>
                                 Manager
                                 </Iconhead_marketing>  
                    </div>
                              <div style={{marginTop:'20px', marginRight:'10px'}}>
                              <TextSnippetOutlinedIcon fontSize='large'
                                sx={{background:'#FFC700', 
                                borderRadius:'50%',
                                padding:'10px',
                                height:'50px',
                                width:'50px',
                                color:'white',
                                fontSize:"20px"}}/>
                                <Iconhead_marketing>Organized
                                <br/>
                                 Documentation</Iconhead_marketing>
                              </div>
                                <div style={{marginTop:'20px', marginRight:'10px'}}>
                                <LockOutlinedIcon fontSize='large'sx={{background:'#AECA00', borderRadius:'50%',
                                padding:'10px',
                                height:'50px',
                                width:'50px',
                                color:'white',
                                fontSize:"20px"}}/>
                            <Iconhead_marketing>100%
                              <br/>
                              Privacy 
                              <br/>
                              Guaranted
                              </Iconhead_marketing>
                                </div>
                    </Box>
                    <Box  >
                          <div style={{marginTop:'00px'}}>
                          <AccessTimeIcon fontSize='large'
                                sx={{background:'#E32227', 
                                borderRadius:'50%',
                                padding:'10px',
                                height:'50px',
                                width:'50px',
                                color:'white',
                                fontSize:"20px"}}/>
                              <Iconhead_marketing>24/7
                              <br/>
                               Customer
                               <br/>
                               Care</Iconhead_marketing> 
                          </div>
                          <div style={{marginTop:'20px'}}> 
                          <CurrencyRupeeOutlinedIcon  fontSize='large'
                                sx={{background:'#0085FF', 
                                borderRadius:'50%',
                                padding:'10px',
                                height:'50px',
                                width:'50px',
                                color:'white',
                                fontSize:"20px"}}/>
                              <Iconhead_marketing>Affordable 
                              <br/>
                              Prices
                              </Iconhead_marketing> 
                          </div>
                        <div style={{marginTop:'20px'}}>
                        <GroupsOutlinedIcon fontSize='large'
                        sx={{background:'#2F1EF1', 
                                borderRadius:'50%',
                                padding:'10px',
                                height:'50px',
                                width:'50px',
                                color:'white',
                                fontSize:"20px"}}/>
                              <Iconhead_marketing>Team Of
                              <br/> 
                              Experts
                              <br/> 
                              On Boards
                              </Iconhead_marketing>
                        </div>
                              
                    </Box>
                     
           </> 
            )
}
}
export default MarketingComponent; 