import React from 'react';
import Sidecomponent from '../../../Components/Tax-Planning/Sidecomponent';
import Topdiv from "../../../Components/Tax-Planning/Topdiv"
import GetInTouch from '../../../Components/Services/GetInTouch';
import {
  Grid, Box, Container, styled, Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Link
} from '@mui/material';

import Mainhead from '../../../Components/Services/MainContent/Mainhead';
import Content from '../../../Components/Services/MainContent/Content';
import List from '../../../Components/Services/MainContent/List';
import Step from '../../../Components/Services/MainContent/Step';

import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import Faq from './Faq';


function createData(
  Sno,
  Individual,
  origin,
  purpose,
  work,
  
 ) {
   return {
  Sno,
  Individual,
  origin,
  purpose,
  work,
  };
 }
 function createData1(
   Nature,
   ROR,
   RNOR,
   NR
 ){
   return{
   Nature,
   ROR,
   RNOR,
   NR
   }
 }
 
 const StyledTableCell = styled(TableCell)(({ theme }) => ({
   [`&.${tableCellClasses.head}`]: {
     backgroundColor: '#773106',
     color: theme.palette.common.white,
     textAlign: 'left',
     fontFamily: 'Sora',
     fontWeight: 'bold',
 
 
 
   },
   [`&.${tableCellClasses.body}`]: {
     fontSize: 16,
     textAlign: 'left',
     textAlign: 'left',
     fontFamily: 'Sora',
   },
 }));
 
 const StyledTableRow = styled(TableRow)(({ theme }) => ({
   '&:nth-of-type(even)': {
     backgroundColor: theme.palette.action.hover,
     fontFamily: 'Sora'
   },
 
   '&:last-child td, &:last-child th': {
     border: 0,
   },
 }));
 
 const rows = [
   createData('1','Individual','Indian Citizen','Leaving India','For Employment Purpose'),
   createData('2','Individual','Indian Citizen','Leaving India','As a crew member of Indian Ship'),
   createData('3','Individual','Indian Citizen or Indian Origin','Comming India','For visiting purpose'),
 ];
 
 const rows1 = [
   createData1('Accured in India & received in India','Taxable','Taxable','Taxable'),
   createData1('Accured in India & received in outside India','Taxable','Taxable','Taxable'),
   createData1('Accured outside India & received in India','Taxable','Taxable','Taxable'),
   createData1('Accured & Received ouutside India but control in India','Taxable','Taxable','Not Taxable'),
   createData1('Accured & received outside India but control in outside India','Taxable','Not Taxable','Taxable'),
   createData1('Past untaxed/taxed profit remitted to India this year','Not Taxable','Not Taxable','Not Taxable')
 ]

 const Bottomspace = styled(Box)(({ theme }) => ({
  marginBottom:'2.1rem'
}));
 
function Residential() {
  return (
    <div>
     <Topdiv heading="Are you looking to file your Income Tax Returns ?"/>
     <div className='Container'>
      <Container>
        <Grid container>
          <Grid item  lg={4} xl={3}>
            <Box sx={{ display: { xs: 'none', lg: 'block' }, marginTop: '4rem', position: 'sticky', top: 90, marginBottom: '3rem' }}>
              <Sidecomponent />
            </Box>
          </Grid>
          <Grid item xs={11} lg={8} sx={{ marginTop: '4rem' }} xl={9}>
            <Mainhead heading="Resident as per income tax act" />
            <Content paragraph="Total income of a person can’t be computed unless we know his residential status, place of Accrual & place of receipt in India during PY."/>
            <Bottomspace/>
            <Mainhead heading ="Residential Status Of Assesse  In India"/>
            <Content paragraph="Basic Rule for Determining RS : The following rule must be kept in mind:"/>
            <ul className="list">
              <li className="bullet">
                <span>
            <List points="It is determining for each category of person separately because rule for determining RS are different for individual & for company"/>
            </span>
            </li>
            <li className="bullet">
                <span>
            <List points="Total income of a person can’t be determined w/o knowing his Rs."/>
            </span>
            </li>
            <li className="bullet">
                <span>
            <List points="Residential status of a person is to be determined for every PY because it may change year to year."/>
            </span>
            </li>
            <li className="bullet">
                <span>
            <List points="A person can be resident in more than one country for the same PY."/>
            </span>
            </li>
            <li className="bullet">
                <span>
            <List points="Foreign citizens may be resident in india."/>
            </span>
            </li>
            <li className="bullet">
                <span>
            <List points="Indian citizens may be non - resident in india."/>
            </span>
            </li>
            </ul>
            <Bottomspace/>
            <Mainhead heading="How  To Determine The Residential Status ( SEC 6)"/>
            <Content paragraph="BASIC CONDITION : Shall be resident in india if any one of the following condition is satisfied:"/>
            <Step number="1.He stayed in India for a period of 182 days or more in the relevant previous year."/>
            <Step number="2.If he stayed in India for a period of 60 days or more during the RPY & 365 days or more during four PY immediately preceding the RPY."/>
            <Bottomspace/>
            <Mainhead heading="Analysis"/>
            <ul className='list'>
              <li className='bullet'>
                <span>
            <List points="If both of the above conditions are not satisfied - he is Non resident in india."/>
            </span>
            </li>
            <li className="bullet">
                <span>
            <List points="The day on which he enters in india as well as leaves india shall be taken into a/c as in India"/>
            </span>
            </li>
            </ul>
            <Bottomspace/>
            <Mainhead heading="Exceptional Situation"/>
            <Content paragraph="For the following persons, only sec 6(1) (a) (i.e. 182 days) shall only apply (in other words (60+365) days condition will not apply)"/>
            <br/>
            <TableContainer component={Paper}>
                <Table aria-label="customized table" size='small'>
                  <TableHead>
                    <TableRow>
                      <StyledTableCell></StyledTableCell>
                      <StyledTableCell></StyledTableCell>
                      <StyledTableCell></StyledTableCell>
                      <StyledTableCell></StyledTableCell>
                      <StyledTableCell></StyledTableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {rows.map((row) => (
                      <StyledTableRow
                        key={row.name}

                      >
                        <StyledTableCell component="th" scope="row"
                        >
                          {row.Sno}
                        </StyledTableCell>
                        <StyledTableCell>{row.Individual}</StyledTableCell>
                        <StyledTableCell>{row.origin}</StyledTableCell>
                        <StyledTableCell>{row.purpose}</StyledTableCell>
                        <StyledTableCell>{row.work}</StyledTableCell>
                      </StyledTableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
              <br/><br/>
              <Step number="Indian origin : A person is deemed to be of Indian origin, if he or either his parents or any of his grandparents was born in undivided india."/>
              <Bottomspace/>
              <Mainhead heading="Additional Conditions ( sec 6(6))"/>
              <Content paragraph="Individual shall be resident and ordinary resident in india if he is satisfied both the additional conditions:"/>
              <ul className='list'>
                <li className='bullet'>
                  <span>
              <List points="He has been resident in India for at least 2 years out of 10 P/Y immediately RPY."/>
             </span>
             </li>
             <li className='bullet'>
                  <span>
              <List points="He has been resident in India for at least 730 days out 7 P/y immediate PY"/>
              </span>
              </li>
              </ul>
              <Bottomspace/>
              <Mainhead heading="Residential Status Of Company (SEC 6(3))"/>
              <Step number="INDIAN COMPANY "/>
              <Content paragraph="It will be always resident whether control or management wholly situated outside india or india."/>
              <Step number=""/>
              <Content paragraph="Foreign Company "/>
              <Content paragraph="will be resident if the effective place of management in that year is in india."/>
              <br/>
              <Mainhead heading="Impact Of Residential Status On Tax Incidence ( SEC 5)"/>
              <br/>
              <TableContainer component={Paper}>
                <Table aria-label="customized table" size='small'>
                  <TableHead>
                    <TableRow>
                      <StyledTableCell>Nature Of Income</StyledTableCell>
                      <StyledTableCell>ROR</StyledTableCell>
                      <StyledTableCell>RNOR</StyledTableCell>
                      <StyledTableCell>NR</StyledTableCell>
                     
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {rows1.map((row) => (
                      <StyledTableRow
                        key={row.name}

                      >
                        <StyledTableCell component="th" scope="row"
                        >
                          {row.Nature}
                        </StyledTableCell>
                        <StyledTableCell>{row.ROR}</StyledTableCell>
                        <StyledTableCell>{row.RNOR}</StyledTableCell>
                        <StyledTableCell>{row.NR}</StyledTableCell>
                    </StyledTableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
              <br/><br/>
              <Mainhead heading="SECTION 6(A) -"/>
              <Content paragraph="The condition to substitute '60 days with 182 days' is changed from '60 days to 120 days'"/>
              <Bottomspace/>
              <Step number="RESIDENT-"/>
              <Content paragraph="Any citizen of India or a person of Indian origin having taxable income in India other than foreign sourced income exceeding Rs. 15 lakh in the relevant financial year and not liable to tax in any other country due to reason of domicile will be deemed to be resident of India. The same will be classified as Resident and Not ordinary Resident for the concerned previous year."/>
              <Bottomspace/>
              <Step number ="RESIDENT NOT ORDINARILY RESIDENT"/>
               <Content paragraph="A citizen of India or a person of Indian origin who leaves India for employment outside India during the year will be a resident and ordinarily resident if he stays in India for an aggregate period of 182 days or more. However, this condition will apply only if his total income (other than foreign sources) exceeds Rs 15 lakh.who is deemed to be a resident in India (FY 2020-21) will be a resident and ordinarily resident in India."/>
               <Content paragraph="Note - Income from foreign sources means income which accrues or arises outside India (except income derived from a business controlled in India or profession set up in India)."/>
               <Bottomspace/>
               <Step number="NON - RESIDENT"/>
               <Content paragraph="An individual satisfying neither of the conditions SEC 6(1) OR SEC 6(6) stated in above would be an NR for the year."/>
               <br/>
               <br/>
               <Mainhead heading="Taxability -"/>
               <Step number="Resident"/>
               <Content paragraph=" A resident will be charged to tax in India on his global income i.e. income earned in India as well as income earned outside India."/>
               <Bottomspace/>
               <Step number="NR and RNOR:."/>
               <Content paragraph="Their tax liability in India is restricted to the income they earn in India. They need not pay any tax in India on their foreign income. Also not a double taxation of income."/>
               <Bottomspace/>
               <Mainhead heading="How to determine residential status"/>
                <Content paragraph="For the purpose of income tax in India, the income tax laws in India classifies taxable persons as:"/>
                <ul className='list'>
                  <li className='bullet'>
                    <span>
                <List points="A resident"/>
                </span>
                </li>
                <li className='bullet'>
                  <span>
                <List points="A resident not ordinarily resident (RNOR)"/>
                </span>
                </li>
                <li className='bullet'>
                  <span>
                <List points="A non-resident (NR)"/>
                </span>
                </li>
                </ul>
                <Bottomspace/>
                <Content paragraph="The taxability differs for each of the above categories of taxpayers. Individual are considered an Indian resident for a financial year if individual satisfy any of the conditions below:"/>
                <Content paragraph="When a indian citizen who leaves in India for at least 182 days or more during the financial year"/>
                <Content paragraph="Individual have been in India for 60 days in the previous year and have lived for one whole year (365 days) in the last four years.
Note: If you are an Indian citizen working abroad or a crew member on an Indian ship, only the first condition is available to you – which means you are a resident when you spend at least 182 days in India. Second condition does not apply. RNOR Amendment"/>
                <Content paragraph="Total income other than foreign income is Rs 15 lakh or more,"/>
                <Content paragraph="The individual has stayed in India for more than 120 days but less than 182 days in the previous year, or"/>
                <Content paragraph="The individual has stayed in India for 365 days or more in four years preceding the previous year"/>
                <Bottomspace/>
                <Mainhead heading="Who is RESIDENT in India?"/>
                <Step number="If He is in India for 182 days or more during the financial year."/>
                <Content paragraph="OR"/>
                <Content paragraph="If he is in India for at least 365 days during the 4 years preceding that year AND at least 60 days in that year."/>
                <br/>
                <Faq/>
                <Bottomspace/>
                <Bottomspace/>
                <br/>
            </Grid>
            </Grid>
           
            </Container>
            </div>
    </div>
  )
}

export default Residential