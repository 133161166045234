import React from "react";
import { Box } from "@mui/material";
import Mainhead from "../../../Components/Services/MainContent/Mainhead";
import Content from "../../../Components/Services/MainContent/Content";
import Step from "../../../Components/Services/MainContent/Step";
import ReusableTable from "../../../Components/Income-Tax/ReusableTable";
import CustomList from "../../../Components/Income-Tax/CustomList";
const Section80C = () => {
  return (
    <Box
      style={{
        display: "flex",
        flexDirection: "column",
        gap: 4,
        marginBottom: "2rem",
      }}
    >
      <Mainhead heading="Section 80C - Income Tax Exemptions" />

      <Step
        number={`
          What does Section 80C mean as per Income Tax Act, 1961?
        `}
      />
      <Content paragraph="Certain investments and expenses are exempt from income tax under Section 80C of the Income Tax Act. By distributing your investments among various financial assets like PPF, NSC, ELSS, and so on, you can minimize your tax obligation by claiming deductions under Section 80C of up to Rs. 1.5 lakh." />

      <Step
        number={`
          Update in Budget Regarding Section 80C
        `}
      />
      <Content paragraph="Section 80C regulations remain unchanged from the recently finished Union Budget 2023, according to Finance Minister Nirmala Sitharaman. Therefore, you are eligible to deduct up to Rs. 1.5 lakh if you are paying taxes under the previous regime. If you have selected the option for the new tax regime, the deduction rules do not apply to you." />

      <Step
        number={`
          What is the Various List of Investments Allowable Under Section 80C?
        `}
      />
      <ReusableTable
        data={[
          {
            srNo: 1,
            particulars: "Contribution in Unit-linked Insurance Plan 1971",
          },
          {
            srNo: 2,
            particulars:
              "Contribution in Unit-linked Insurance Plan of LIC Mutual Fund",
          },
          {
            srNo: 3,
            particulars: "Premium paid in respect of life insurance policy",
          },
          {
            srNo: 4,
            particulars:
              "Premium paid in respect of a contract for deferred annuity",
          },
          {
            srNo: 5,
            particulars:
              "Any sum deducted from the salary payable of a Government employee for securing a deferred annuity",
          },
          { srNo: 6, particulars: "Contribution to SPF/PPF/RPF" },
          {
            srNo: 7,
            particulars: "Contribution to approved superannuation Fund",
          },
          {
            srNo: 8,
            particulars:
              "Any sum paid or deposited in Sukanya Samriddhi Account",
          },
          {
            srNo: 9,
            particulars: "Subscription to National Savings Certificates VIII",
          },
          {
            srNo: 10,
            particulars: "Contribution to approved annuity plan of LIC",
          },
          {
            srNo: 11,
            particulars:
              "Subscription towards notified units of mutual fund or UTI",
          },
          {
            srNo: 12,
            particulars:
              "Contribution to notified pension fund set up by mutual fund or UTI",
          },
          {
            srNo: 13,
            particulars:
              "Contribution to National Housing Bank (Tax Saving) Term Deposit Scheme, 2008",
          },
          { srNo: 14, particulars: "Subscription to notified deposit scheme" },
          {
            srNo: 15,
            particulars:
              "Payment of tuition fees to any university, college, school or other educational institutions within India for full-time education for maximum 2 children",
          },
          {
            srNo: 16,
            particulars:
              "Repayment of housing loan including stamp duty, registration fee and other expenses",
          },
          {
            srNo: 17,
            particulars: "Subscription to certain equity shares or debentures",
          },
          {
            srNo: 18,
            particulars: "Subscription to certain units of mutual fund",
          },
          { srNo: 19, particulars: "Investment in five year Term Deposit" },
          {
            srNo: 20,
            particulars: "Subscription to notified bonds issued by NABARD",
          },
          {
            srNo: 21,
            particulars:
              "Deposit in Senior Citizens Savings Scheme Rules, 2004",
          },
          {
            srNo: 22,
            particulars: "Investment in five year Post Office time deposit",
          },
          {
            srNo: 23,
            particulars: "Contribution to additional account under NPS",
          },
        ]}
      />

      <Step
        number={`
    Detailed Explanation of Investments Allowable Under Section 80C of Income Tax Act, 1961 in India
  `}
      />
      <CustomList
        points={["Contribution in Unit-linked Insurance Plan 1971"]}
      />
      <Content paragraph="Contributions to the Unit-linked Insurance Plan 1971 in the name of the individual, his or her spouse, or any child of the individual. Contributions to a HUF can be made in the name of any member." />
      <CustomList
        points={[
          "Contribution in Unit-linked Insurance Plan of LIC Mutual Fund",
        ]}
      />
      <Content paragraph="Contributions made in the name of the individual, their spouse, or any of their children in order to participate in any LIC Mutual Fund unit-linked insurance plan. Any member may make a contribution in the event of a HUF." />
      <CustomList
        points={["Premium paid in respect of life insurance policy"]}
      />
      <Content paragraph="The premium for life insurance covers the individual, spouse, minor or major children, and, in the case of a HUF, any member of the HUF. Both an endowment insurance and a life policy will be part of this." />

      <Step
        number={`
    Tabular Description of Deduction Under Section 80C
  `}
      />
      <Content paragraph="A tabular description of the deduction permitted under Section 80C with respect to the policy's issue date is provided below:" />

      <ReusableTable
        data={[
          {
            key: "policy-before-2012",
            deduction: "In respect of policies issued before 31.3.2012",
            condition: "",
            premium:
              "Premium paid to the extent of 20% of actual capital sum assured",
          },
          {
            key: "policy-2012-to-2013",
            deduction:
              "In respect of policies issued on or after 1.4.2012 but before 1.4.2013",
            condition: "",
            premium:
              "Premium paid to the extent of 10% of actual capital sum assured",
          },
          {
            key: "policy-after-2013-disability",
            deduction: "In respect of policies issued on or after 1.4.2013",
            condition:
              "Where the insurance is on the life of a person with disability or severe disability...",
            premium:
              "Premium paid to the extent of 15% of actual capital sum assured",
          },
          {
            key: "policy-after-2013-no-disability",
            deduction: "In respect of policies issued on or after 1.4.2013",
            condition:
              "Where the insurance is on the life of any person, other than a person with a disability...",
            premium:
              "Premium paid to the extent of 10% of actual capital sum assured",
          },
        ]}
      />

      <CustomList
        points={["Premium paid in respect of a contract for deferred annuity"]}
      />
      <Content paragraph="A premium is paid to establish and maintain a deferred annuity contract on the life of the policyholder, their spouse, and any children, provided that the contract does not include a clause allowing the policyholder to exercise an option to receive cash payments in lieu of annuity payments." />

      <CustomList
        points={[
          "Any sum deducted from the salary payable of a Government employee for securing a deferred annuity",
        ]}
      />
      <Content paragraph="Amount withheld from a government employee's pay by the government or on its behalf in accordance with the terms of his employment to secure a deferred annuity or provide for his spouse or children. Any surplus beyond one-fifth of the salary, if any, should be disregarded." />

      <CustomList points={["Contribution to SPF/PPF/RPF"]} />
      <Content paragraph="Section 80C allows for the deduction of contributions made to any provident fund established by the Central Government and notified on his behalf, such as the Public Provident Fund created under the Public Provident Fund Scheme, 1968. A gift of this kind may be given in the names of the individual, his spouse, any children, and, in the event of a HUF, any family member. The maximum limit for deposit in PPF is Rs.1,50,000 in a year." />

      <CustomList points={["Contribution to approved superannuation Fund"]} />
      <Content paragraph="A section 80C deduction is available for employee contributions made to a superannuation fund that has been approved." />

      <CustomList
        points={["Any sum paid or deposited in Sukanya Samriddhi Account"]}
      />
      <Content paragraph="Membership in any Central Government security or deposit plan that the Central Government may announce in the Official Gazette. As a result, the Sukanya Samriddhi Scheme has been informed to stipulate that any amount paid or deposited in the Scheme's preceding year by an individual in the name of – (1) Any girl child of the individual; or (2) Individual is the legal guardian, would be eligible for deduction under section 80C." />

      <CustomList
        points={["Subscription to National Savings Certificates VIII"]}
      />
      <Content paragraph="Subscription to any Savings Certificates declared by the Central Government in the Official Gazette under the Government Savings Certificates Act, 1959 (i.e. National Savings Certificate (VIII Issue) issued under the Government Savings Certificates Act, 1959)." />

      <CustomList points={["Contribution to approved annuity plan of LIC"]} />
      <Content paragraph="Contributions to approved annuity plans of the Life Insurance Corporation of India (LIC) (New Jeevan Dhara and New Jeevan Akshay, New Jeevan Dhara I and New Jeevan Akshay I, II, and III) or any other insurer (Tata AIG Easy Retire Annuity Plan of Tata AIG Life Insurance Company Ltd.) as specified by the Central Government by notification in the Official Gazette." />

      <CustomList
        points={["Subscription towards notified units of mutual fund or UTI"]}
      />
      <Content paragraph="Subscription to any units of any mutual fund referred to in section 10(23D) or from the Administrator or the specified company under any plan formulated in accordance with such scheme notified by the Central Government." />

      <CustomList
        points={[
          "Contribution to notified pension fund set up by mutual fund or UTI",
        ]}
      />
      <Content paragraph="Contribution by an individual to a pension fund set up by any Mutual Fund referred to in section 10(23D) or by the Administrator or the specified company as the Central Government may specify (i.e., UTI-Retirement Benefit Pension Fund set up by the specified company referred to in section 2(h) of the Unit Trust of India (Transfer of Undertaking and Repeal) Act, 2002 as a pension fund)." />

      <CustomList
        points={[
          "Contribution to National Housing Bank (Tax Saving) Term Deposit Scheme, 2008",
        ]}
      />
      <Content paragraph="Subscription to any deposit scheme or contribution to any pension fund set up by the National Housing Bank i.e., National Housing Bank (Tax Saving) Term Deposit Scheme, 2008." />

      <CustomList points={["Subscription to notified deposit scheme"]} />
      <Content paragraph="Subscription to any such deposit scheme of a public sector company engaged in providing long-term finance for the construction or purchase of houses in India for residential purposes or any such deposit scheme of any authority established in India by or under any law enacted either to deal with and satisfy the need for housing accommodation, or to plan, develop, or improve cities, towns, and villages, or both. The Central Government should notify the deposit program, such as HUDCO's public deposit system." />

      <CustomList
        points={[
          "Payment of tuition fees to any university, college, school or other educational institutions within India for full-time education for maximum 2 children",
        ]}
      />
      <Content paragraph="Tuition fee must be paid in full for any two of the individual assessee's children to attend any university, college, school, or other educational institution in India, either at the time of admission or subsequently. This benefit is limited to the cost of tuition for a full-time education; it does not cover payments for development fees, donations, or other similar sums, nor does it cover payments for education to any institution outside of India." />

      <CustomList
        points={[
          "Repayment of housing loan including stamp duty, registration fee and other expenses",
        ]}
      />
      <Content
        paragraph="The approved types of payments are as follows:
        
⦁ Any installment or part payment of the amount due under any self-financing or other schemes of any development authority, Housing Board or other authority engaged in the construction and sale of house property on ownership basis; or
⦁ Any installment or part payment of the amount due to any company or a cooperative society of which the assessee is a shareholder or member towards the cost of house allotted to him; or
⦁ Repayment of amount borrowed by the assessee from:
  (a) The Central Government or any State Government;
  (b) Any bank including a co-operative bank;
  (c) The Life Insurance Corporation;
  (d) The National Housing Bank;
⦁ Any public company formed and registered in India with the main object of carrying on the business of providing long-term finance for construction or purchase of houses in India for residential purposes which is eligible for deduction under section 36(1)(viii);
⦁ Any company in which the public are substantially interested or any cooperative society engaged in the business of financing the construction of houses;
⦁ The assessee‘s employer, where such employer is an authority or a board or a corporation or any other body established or constituted under a Central or State Act;
⦁ The assessee‘s employer where such employer is a public company or public sector company or a university established by law or a college affiliated to such university or a local authority or a co-operative society.
⦁ Stamp duty, registration fee and other expenses for the purposes of transfer of such house property to the assessee.
Inadmissible payments: However, the following amounts do not qualify for rebate:
⦁ Admission fee, cost of share and initial deposit which a shareholder of a company or a member of a co-operative society has to pay for becoming a shareholder or member; or
⦁ The cost of any addition or alteration or renovation or repair of the house property after the issue of completion certificate in respect of the house property or after the house has been occupied by the assessee or any person on his behalf or after it has been let out; or
⦁ Any expenditure in respect of which deduction is allowable under section 24."
      />

      <CustomList
        points={["Subscription to certain equity shares or debentures"]}
      />
      <Content paragraph="Subscription to equity shares or debentures forming part of any eligible issue of capital approved by the Board on an application made by a public company or as a subscription to any eligible issue of capital by any public financial institution in the prescribed form. A lock-in period of three years is provided in respect of such equity shares or debentures. In case of any sale or transfer of shares or debentures within three years of the date of acquisition, the aggregate amount of deductions allowed in respect of such equity shares or debentures in the previous year or years preceding the previous year in which such sale or transfer has taken place shall be deemed to be the income of the assessee of such previous year and shall be liable to tax in the assessment year relevant to such previous year." />

      <CustomList points={["Subscription to notified deposit scheme"]} />
      <Content paragraph="Subscription to any such deposit scheme of a public sector company engaged in providing long-term finance for the construction or purchase of houses in India for residential purposes or any such deposit scheme of any authority established in India by or under any law enacted either to deal with and satisfy the need for housing accommodation, or to plan, develop, or improve cities, towns, and villages, or both. The Central Government should notify the deposit program, such as HUDCO's public deposit system." />

      <CustomList
        points={[
          "Payment of tuition fees to any university, college, school or other educational institutions within India for full-time education for maximum 2 children",
        ]}
      />
      <Content paragraph="Tuition fee must be paid in full for any two of the individual assessee's children to attend any university, college, school, or other educational institution in India, either at the time of admission or subsequently. This benefit is limited to the cost of tuition for full-time education; it does not cover payments for development fees, donations, or other similar sums, nor does it cover payments for education to any institution outside of India." />

      <CustomList
        points={[
          "Repayment of housing loan including stamp duty, registration fee and other expenses",
        ]}
      />
      <Content
        paragraph="The approved types of payments are as follows:
- Any installment or part payment of the amount due under any self-financing or other schemes of any development authority, Housing Board or other authority engaged in the construction and sale of house property on ownership basis; or
- Any installment or part payment of the amount due to any company or a cooperative society of which the assessee is a shareholder or member towards the cost of house allotted to him; or
- Repayment of amount borrowed by the assessee from:
  (a) The Central Government or any State Government;
  (b) Any bank including a co-operative bank;
  (c ) The Life Insurance Corporation;
  (d) The National Housing Bank;
  - Any public company formed and registered in India with the main object of carrying on the business of providing long-term finance for construction or purchase of houses in India for residential purposes which is eligible for deduction under section 36(1)(viii);
  - Any company in which the public are substantially interested or any cooperative society engaged in the business of financing the construction of houses;
  - The assessee‘s employer, where such employer is an authority or a board or a corporation or any other body established or constituted under a Central or State Act;
  - The assessee‘s employer where such employer is a public company or public sector company or a university established by law or a college affiliated to such university or a local authority or a co-operative society."
      />

      <CustomList
        points={[
          "Stamp duty, registration fee and other expenses for the purposes of transfer of such house property to the assessee.",
        ]}
      />
      <Content
        paragraph="Inadmissible payments: However, the following amounts do not qualify for rebate:
- Admission fee, cost of share and initial deposit which a shareholder of a company or a member of a co-operative society has to pay for becoming a shareholder or member; or
- The cost of any addition or alteration or renovation or repair of the house property after the issue of completion certificate in respect of the house property or after the house has been occupied by the assessee or any person on his behalf or after it has been let out; or
- Any expenditure in respect of which deduction is allowable under section 24."
      />

      <CustomList
        points={["Subscription to certain equity shares or debentures"]}
      />
      <Content paragraph="Subscription to equity shares or debentures forming part of any eligible issue of capital approved by the Board on an application made by a public company or as a subscription to any eligible issue of capital by any public financial institution in the prescribed form. A lock-in period of three years is provided in respect of such equity shares or debentures. In the case of any sale or transfer of shares or debentures within three years of the date of acquisition, the aggregate amount of deductions allowed in respect of such equity shares or debentures in the previous year or years preceding the previous year in which such sale or transfer has taken place shall be deemed to be the income of the assessee of such previous year and shall be liable to tax in the assessment year relevant to such previous year." />

      <CustomList points={["Subscription to certain units of mutual fund"]} />
      <Content paragraph="Subscription to any units of any mutual fund referred to in section 10(23D) and approved by the Board on an application made by such mutual fund in the prescribed form." />

      <CustomList points={["Investment in five year Term Deposit"]} />
      <Content paragraph="Investment in term deposit for a period of not less than five years with a scheduled bank, which is in accordance with a scheme framed and notified by the Central Government in the Official Gazette qualifies as an eligible investment for availing deduction under section 80C. The maximum limit for investment in term deposit is Rs.1,50,000." />

      <CustomList
        points={["Subscription to notified bonds issued by NABARD"]}
      />
      <Content paragraph="Subscription to such bonds issued by NABARD (as the Central Government may notify in the Official Gazette) qualifies for deduction under section 80C." />

      <CustomList
        points={["Deposit in Senior Citizens Savings Scheme Rules, 2004"]}
      />
      <Content paragraph="Deposit in an account under the Senior Citizens Savings Scheme Rules, 2004 qualifies for deduction under section 80C." />

      <CustomList
        points={["Investment in five year Post Office time deposit"]}
      />
      <Content paragraph="Investment in five year time deposit in an account under Post Office Time Deposit Rules, 1981 qualifies for deduction under section 80C." />

      <CustomList points={["Contribution to additional account under NPS"]} />
      <Content paragraph="Contribution by a Central Government employee to an additional account under NPS (specified account) referred to in section 80CCD for a fixed period of not less than 3 years and which is in accordance with the scheme notified by the Central Government for this purpose qualifies for deduction under section 80C. It may be noted that only the contribution to the additional account under NPS will qualify for deduction under section 80C." />
    </Box>
  );
};

export default Section80C;
