import React from "react";
import { Box } from "@mui/material";
import Mainhead from "../../../Components/Services/MainContent/Mainhead";
import Content from "../../../Components/Services/MainContent/Content";
import Step from "../../../Components/Services/MainContent/Step";

const Section80RRB = () => {
  return (
    <Box
      style={{
        display: "flex",
        flexDirection: "column",
        gap: 4,
        marginBottom: "2rem",
      }}
    >
      <Mainhead heading="Section 80RRB" />
      <Content paragraph="Section 80RRB of the Income Tax Act, 1961, provides a deduction to individuals earning income through patents and copyrights." />
      <Step
        number={`
          What is the Eligibility Criteria for section 80RRB of Income Tax Act, 1961?
        `}
      />
      <Content paragraph="The deduction is available to resident individuals, including non-citizens of India. Hindu Undivided Families (HUFs) or other types of entities are not eligible. The income source should be from patents or copyrights registered on or after April 1, 2003, and the claimant must be the holder or owner of the patent or copyright." />
      <Step
        number={`
          What is the Deduction Amount under section 80RRB of Income Tax  Act, 1961 in India?
        `}
      />
      <Content paragraph="The deduction allowed under Section 80RRB is the lesser of the total income from patents/copyrights during the year or a cap of Rs. 3,00,000 if the income exceeds this limit." />
      <Step
        number={`
          Duration of Deduction and compliance requirements under Section 80RRB
        `}
      />
      <Content paragraph="The deduction is available for a consecutive period of 10 assessment years. Compliance requirements include the submission of Form 10CCB and maintaining proper documentation related to patents or copyrights and their income." />
      <Content
        paragraph={`For Example:- Let's consider an individual, Mr. A, who holds a patent for a unique technology and earns income from licensing that patent to various companies. In a particular financial year, Mr. A earned Rs. 5,00,000 as royalty income from his patented technology.`}
      />
      <Content
        paragraph={`Under Section 80RRB, Mr. A can claim a deduction for the income earned from his patent, subject to certain conditions:`}
      />
      <Step
        number={`
          Eligibility:
        `}
      />
      <Content
        paragraph={`Mr. A is a resident individual and the holder of the patent registered after April 1, 2003.`}
      />
      <Step
        number={`
          Deduction Calculation:
        `}
      />
      <Content paragraph={`The deduction allowed will be the lesser of:`} />
      <ul className="list">
        <li className="bullet">
          <span>The total income received from the patents: Rs. 5,00,000</span>
        </li>
        <li className="bullet">
          <span>Cap of Rs. 3,00,000 mentioned under Section 80RRB.</span>
        </li>
      </ul>
      <Step
        number={`
          Deduction Amount:
        `}
      />
      <Content
        paragraph={`As per the provision, Mr. A can claim a deduction of Rs. 3,00,000 because it is lower than the total income of Rs. 5,00,000 earned from the patented technology.`}
      />
      <Content
        paragraph={`Therefore, for this financial year, Mr. A can claim a deduction of Rs. 3,00,000 under Section 80RRB while filing his income tax return. This deduction will be subtracted from his total taxable income, reducing his overall tax liability.`}
      />
      <Content
        paragraph={`It's important to note that this illustration simplifies the application of Section 80RRB and does not include other factors that might affect Mr. A's tax situation, such as other sources of income, deductions, exemptions, or any changes in tax laws or regulations.`}
      />
    </Box>
  );
};

export default Section80RRB;
