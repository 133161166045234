import React from "react";
import { Box } from "@mui/material";
import Mainhead from "../../../Components/Services/MainContent/Mainhead";
import Content from "../../../Components/Services/MainContent/Content";
import CustomList from "../../../Components/Income-Tax/CustomList";
import Step from "../../../Components/Services/MainContent/Step";
import ReusableTable from "../../../Components/Income-Tax/ReusableTable";
import { Image } from "../../../Components/Income-Tax/ImageStyle";
import Image8 from "../../../Assets/IncomeTax/hp2.png";
import Image2 from "../../../Assets/IncomeTax/hp1.png";

const HousePropertyIncome = () => {
  const letOutPropertyTableData = [
    { Particulars: "Gross Annual Value", Amount: "xxxx" },
    { Particulars: "Less: Municipal Taxes", Amount: "(xxx)" },
    { Particulars: "Net Annual Value", Amount: "xxxx" },
    { Particulars: "Less: Standard Deduction (30% of NAV)", Amount: "(xxx)" },
    { Particulars: "Less: Interest on House Loan", Amount: "(xxx)" },
    { Particulars: "Net Income from House Property", Amount: "xxxx" },
  ];

  const selfOccupiedPropertyTableData = [
    { Particulars: "Gross Annual Value", Amount: "-" },
    { Particulars: "Less: Municipal Taxes", Amount: "NA" },
    { Particulars: "Net Annual Value", Amount: "-" },
    { Particulars: "Less: Standard Deduction (30% of NAV)", Amount: "(xxx)" },
    {
      Particulars: "Less: Interest on House Loan (Up to Rs 2 lakhs)",
      Amount: "NA",
    },
    { Particulars: "Net Income from House Property", Amount: "(xxx)" },
  ];

  return (
    <Box
      style={{
        display: "flex",
        flexDirection: "column",
        gap: 4,
        marginBottom: "2rem",
      }}
    >
      {" "}
      <Mainhead heading="Income from House Property in India" />
      <Step
        number={`
            What do you mean by House Property in India?
          `}
      />
      <Content paragraph="House Property refers to any building (including the land attached to it) used for residential or commercial purposes. It includes various types of properties such as residential houses, apartments, commercial buildings, shops, offices, vacant land with buildings, and rental properties." />
      <Step
        number={`
            What is Income from House Property as per Income Tax Act, 1961 in India?
          `}
      />
      <Content paragraph="As per the Income Tax Act, 1961, income is taxable under the head ‘House Property’ if such income arises from a property consisting of any building or land appurtenant thereto." />
      <Step
        number={`
            Is there any condition for my House Property income in India?
          `}
      />
      <CustomList
        points={[
          "There should be a building and land appurtenant thereto.",
          "The assessee should be the owner of such property.",
          "The assessee should not be using such property for his/her business.",
        ]}
      />
      <Box sx={{ textAlign: "center" }}>
        <Image
          component="img"
          sx={{
            content: {
              xs: `url(${Image8})`,
            },
          }}
        />
      </Box>
      <Step
        number={`
            Computation of Income from House Property in India
          `}
      />
      <CustomList
        points={[
          "Determine the gross annual value (GAV) - total rental income from the property.",
          "Reduce municipal taxes paid from GAV.",
          "Calculate Net Annual Value (NAV) by subtracting municipal taxes from GAV.",
          "Apply Standard Deduction (30% of NAV) as a rebate for repair and reconstruction.",
          "Deduct home loan interest under Section 24 of the Act.",
          "The resulting value is the taxable income from house property, taxed as per applicable slabs.",
        ]}
      />
      <Step
        number={`
            What is the formula to calculate income from House Property in India? (For Let Out Property)
          `}
      />
      <ReusableTable data={letOutPropertyTableData} />
      <Step
        number={`
            What is the formula to calculate income from House Property in India? (For Self Occupied Property)
          `}
      />
      <ReusableTable data={selfOccupiedPropertyTableData} />
      <Content paragraph="Note: Loss from house property in the case of self-occupied property can be adjusted with income from other heads." />
      <Step
        number={`
            What are the steps to calculate Gross Annual Value (GAV) in India?
          `}
      />
      <CustomList
        points={[
          "Calculate the fair rent for your property.",
          "Calculate the municipal value prescribed by the municipal authorities.",
          "Take out the higher value of the above two values.",
          "Calculate standard rent if given under the Rent control act.",
          "Compare the value taken out in step-3 with standard rent and whichever is higher of those two will be known as the expected rent.",
          "Now, calculate what you have actually received during the year i.e. the actual rent.",
          "The higher of actual rent and expected rent is the required GAV.",
        ]}
      />
      <Step
        number={`
            Computation of Rent under Various Situations in India
          `}
      />
      <Content paragraph="In order to understand the various situations under which income as Rent from House Property can be calculated, we need to understand these terms first:" />
      <CustomList
        points={[
          "Self-Occupied Property: A house property in which the owner resides for personal use. If a person owns more than one property for self-occupation, only one property can be considered as self-occupied, and the others are deemed to be let out.",
          "Let-Out Property: A property that is rented out to tenants for residential or commercial purposes. The rental income earned from such property is taxable under the head 'Income from House Property.'",
          "Deemed to be Let-Out Property: If a property is not rented out or self-occupied by the owner (except for specific conditions like residing in another city for employment), it is treated as deemed to be let-out. The Income Tax Act assumes that the property is rented out, and notional rental income is calculated for taxation.",
        ]}
      />
      <Step
        number={`
            Do I need to report any Rental Income under my Self Occupied House In India?
          `}
      />
      <Content paragraph="Since SOP means self-occupied, it basically means there is no Rental Income and hence the Gross Annual Value of the House Property would be nil. However, if the assessee has more than 1 house property, up till 2 it will be considered under SOP and over 2 it would be considered as Deemed Let out Property." />
      <Content paragraph="Hence the Rent under SOP would be calculated as follows:" />
      <ReusableTable
        data={[
          { Particulars: "Gross Annual Value", Amount: "Nil" },
          { Particulars: "Less: Municipal Taxes", Amount: "Nil" },
          { Particulars: "Net Annual Value", Amount: "Nil" },
          { Particulars: "Less: Deductions u/s 24", Amount: "" },
          { Particulars: "b. Interest on house loan", Amount: "(xxx)" },
          { Particulars: "Net Income from House Property", Amount: "(xxx)" },
        ]}
      />
      <Content paragraph="It is to be noted that the Income Tax Act has limited the amount of deduction under section 24 (b) of interest on home loan to Rs. 2,00,000 in case of Self Occupied Properties." />
      <Step
        number={`
            Illustration: Computation of Income from House Property
          `}
      />
      <Content paragraph="Let's take an illustration to understand this:" />
      <Content paragraph="Mr. Rajesh owns a property at Juhu and currently has a home loan on the said property. He has paid Rs. 3,67,900 as interest on the home loan. What would be the total income under the head of house property?" />
      <ReusableTable
        data={[
          { Particulars: "Gross Annual Value", Amount: "Nil" },
          { Particulars: "Less: Municipal Taxes", Amount: "Nil" },
          { Particulars: "Net Annual Value", Amount: "Nil" },
          { Particulars: "Less: Deductions u/s 24", Amount: "" },
          {
            Particulars:
              "b. Interest on house loan (Rs. 3,67,900 limited to Rs. 2,00,000)",
            Amount: "(2,00,000)",
          },
          {
            Particulars: "Net Income from House Property",
            Amount: "(2,00,000)",
          },
        ]}
      />
      <Step
        number={`
            Calculation of Rent under Let out Property In India
          `}
      />
      <Content paragraph="To determine the income under a let-out property, these steps need to be followed:" />
      <CustomList
        points={[
          "Determining the GAV (Gross Annual Value)",
          "Determine the reasonable expected rent of the property: The reasonable expected rate of return, in the context of house property refers to the anticipated annual rental income that the property is expected to generate. It's shown as below - Municipal Valuation Or Fair Value Whichever is higher Up to the Standard Rent determined by the Rent Control Act.",
          "Determining the actual rent received: Once you have determined the Reasonable Expected Rate of Return, you need to find out the amount of actual rent received. Your GAV would be higher than the Reasonable Expected Rate of Return or the Actual Rent Received.",
        ]}
      />
      <Content paragraph="Suppose you own a property that you've let out for rent during the financial year 2023-24. You get a rent of Rs. 40,000 per month (which is Rs. 4,80,000 for the year and the GAV is also equal to that), the municipal taxes paid on such a property are Rs. 12,000, and suppose you paid Rs. 1,20,000 for a house loan. Your income from this let out property will be calculated as follows:" />
      <ReusableTable
        data={[
          { Particulars: "Gross Annual Value", Amount: "480000" },
          { Particulars: "Less: Municipal Taxes", Amount: "12000" },
          { Particulars: "Net Annual Value", Amount: "468000" },
          { Particulars: "Less: Deductions u/s 24", Amount: "" },
          { Particulars: "a. Standard Deduction of 30%", Amount: "140400" },
          { Particulars: "b. Interest on House Loan paid", Amount: "120000" },
          { Particulars: "Net Income from House Property", Amount: "207600" },
        ]}
      />
      <Content paragraph="So, the taxable income from this let-out property for the financial year 2023-24 would be ₹2,07,600, subject to any other applicable provisions of the Income Tax Act." />
      <Step
        number={`
            Calculation of Rental Income from Deemed to be Let Out Property In India
          `}
      />
      <Content paragraph="Imagine you own more than one house or property besides the one you live in. The tax rules in India have a provision that assumes if you have these extra properties and you're not living in all of them, then you must be earning rent from them, even if you're not actually renting them out to tenants." />
      <Content paragraph="For tax purposes, the government considers these additional properties as if they are rented out. This means they assume you're receiving a certain amount of rent annually from these properties, even if they're sitting empty or being used for personal purposes." />
      <Content paragraph="However, you get to choose one property to be considered as your self-occupied residence, and it won't be treated as rented out. But any other properties you own, irrespective of whether you're renting them or they're vacant, will be assumed to be generating rental income." />
      <Content paragraph="This assumption helps the tax authorities calculate your taxable income by including this notional or presumed rental income from the properties you own but don’t actually rent out." />
      <Content paragraph="It's important to note that certain deductions are available to reduce the impact of this deemed rental income. For instance, you can claim deductions for municipal taxes paid on these properties, and there's also a standard deduction of 30% on the deemed rental income to cover expenses like maintenance and repairs, reducing the taxable amount." />
      <Content paragraph="In essence, even if you're not earning any actual rent from these additional properties, the tax law assumes you are and calculates your taxes accordingly, with deductions to ease the tax burden a bit." />
      <Content paragraph="The rent under Deemed to be Let Out Property would be calculated in the same manner as Self Occupied Property." />
      <Step
        number={`
            Understanding Tax Benefits on Home Loans in India
          `}
      />
      <Content paragraph="Buying a home is a significant milestone for many individuals and families. Apart from the emotional fulfillment of owning a property, there are also financial perks in the form of tax benefits that come with home loans in India. These benefits play a crucial role in making homeownership more accessible and affordable. Here's a comprehensive guide to understanding the tax benefits associated with home loans in India." />
      <Box sx={{ textAlign: "center" }}>
        <Image
          component="img"
          sx={{
            content: {
              xs: `url(${Image2})`,
            },
          }}
        />
      </Box>
      <Step
        number={`
            Can I claim Deduction of Interest Paid on Housing Loan in my Income Tax Return?
          `}
      />
      <Content paragraph="One of the most significant tax benefits for homebuyers in India is the deduction on the interest paid on the home loan. For a self-occupied property, individuals can claim a deduction of up to ₹2 lakh per financial year under Section 24(b) of the Income Tax Act. However, for properties that are not self-occupied, the entire interest paid on the loan can be claimed as a deduction without any upper limit." />
      <Step
        number={`
            Does Principal Repayment of Loan Also Entitle Tax Deduction under Income Tax Act, 1961 in India?
          `}
      />
      <Content paragraph="In addition to the interest component, the repayment of the principal amount of the home loan is eligible for tax benefits. Under Section 80C of the Income Tax Act, individuals can claim a deduction of up to ₹1.5 lakh per financial year on the principal repayment amount, along with other eligible investments." />
      <Step
        number={`
            What is the Benefit for First Time Home Buyers under Income Tax Act, 1961 in India?
          `}
      />
      <Content paragraph="First-time homebuyers are entitled to an additional deduction on the interest paid for the loan under Section 80EEA. This deduction of up to ₹1.5 lakh per financial year is available provided specific criteria are met, encouraging and supporting individuals in their first property purchase." />
      <Step
        number={`
            What are the Benefits to Those Who Have a Joint Home Loan under Income Tax Act, 1961 in India?
          `}
      />
      <Content paragraph="For those who have jointly taken a home loan with another individual, such as a family member or spouse, both co-applicants can individually claim tax benefits based on their respective shares in the loan amount. This allows each borrower to avail tax benefits on their share of the loan repayment." />
      <Step
        number={`
            What to Do for the Charges Paid on Registration?
          `}
      />
      <Content paragraph="Apart from the loan repayment and interest, homebuyers can claim deductions on expenses related to the property purchase. Stamp duty and registration charges paid for the property's official registration can be claimed as a deduction under Section 80C, within the overall limit of ₹1.5 lakh." />
      <Content paragraph="Understanding and leveraging the tax benefits associated with home loans can significantly reduce the financial burden of owning a property. These benefits not only encourage homeownership but also provide relief by lowering the taxable income. However, it's essential for homebuyers to familiarize themselves with the eligibility criteria and consult tax professionals to maximize these benefits in compliance with tax laws and regulations." />
      <Content paragraph="This guide serves as a comprehensive resource for prospective homebuyers in India, empowering them to make informed decisions regarding their home purchase while taking advantage of available tax benefits." />
    </Box>
  );
};

export default HousePropertyIncome;
