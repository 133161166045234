import React from "react";
import { Box } from "@mui/material";
import Mainhead from "../../../Components/Services/MainContent/Mainhead";
import Content from "../../../Components/Services/MainContent/Content";
import CustomList from "../../../Components/Income-Tax/CustomList";
import Step from "../../../Components/Services/MainContent/Step";
import NormalList from "../../../Components/Income-Tax/NormalList";

const VRSIndiaTDSSalaryIndia = () => {
  return (
    <Box
      style={{
        display: "flex",
        flexDirection: "column",
        gap: 4,
        marginBottom: "2rem",
      }}
    >
      <Mainhead heading="Voluntary Retirement Scheme (VRS) in India" />

      <Step
        number={`
          What does Voluntary Retirement Scheme mean as per Income Tax Act 1961 in India?
        `}
      />
      <Content paragraph="Voluntary Retirement Scheme, as the name suggests, is a type of scheme which the organization provides to its employees wherein the employee gets the option to voluntarily retire from their employment in early retirement. This is usually done by the organizations for cost-cutting measures or to tackle the issue of overstaffing." />

      <Step
        number={`
          Is there any criteria to claim VRS in India?
        `}
      />
      <Content paragraph="There are a few criteria for employees to claim the benefits of VRS which are as follows:" />
      <CustomList
        points={[
          "Minimum age for VRS is 40 years old.",
          "The Employee should have at least worked for 10 years.",
          "This scheme is applicable to all employees of a company, except directors of a company or a cooperative society.",
          "However, the organization needs approval from the government in order to offer VRS to its employees. Apart from this, another thing that needs to be kept in mind is that the employee should not be working at any other organization to avail VRS.",
        ]}
      />

      <Step
        number={`
          Is there any benefit to the employee in availing VRS in India?
        `}
      />
      <CustomList
        points={[
          "The employee gets to enjoy an early retirement and also receives the dues owed by the organization like provident fund, gratuity, etc.",
          "The employee gets counseling and tax consultation along with salary up to 45 days of each year of completed service.",
        ]}
      />

      <Step
        number={`
          Is there any benefit to the organization if any employee opts for VRS in India?
        `}
      />
      <CustomList
        points={[
          "The company gets to let go of employees in a healthy and professional manner along with achieving measures like cost-cutting, reduction of overcrowded offices, etc.",
          "The money saved can be utilized for improving the productivity and efficiency of the organization.",
        ]}
      />

      <Step
        number={`
          What are the tax implications of VRS in India?
        `}
      />
      <Content paragraph="As per the Income Tax Act, 1961, the amount received by an employee under this scheme would be deemed to be exempt under Section 10(10C), which means the amount received from VRS will not attract any taxes. However, the following conditions need to be followed for the same:" />
      <NormalList
        points={[
          "⦁ It applies only to those employees who have completed at least 10 years of service in the organization and are a minimum of 40 years old.",
          "⦁ It applies to all employees including workers and executives, except the directors of a company and cooperative employees.",
          "⦁ The scheme should have been introduced for the reduction of the workforce of employees.",
          "⦁ The vacancy caused by VRS should not have been filled up.",
          "⦁ The retiring employee of the company should not be employed in any other company belonging to the same management concern.",
          "⦁ If relief under Section 89 of the Income Tax Act has been taken by the employee, then no exemption can be claimed under this section for the same assessment year.",
          "⦁ The amount received under VRS would be exempt up to:",
          "  3 months Salary * Completed Years of Service",
          "  Or Last Drawn Salary * No. of months of remaining service",
          "  Or Rs. 5,00,000",
          "  Or The Actual Amount of VRS received",
          "Whichever is lower.",
          "For Example:",
          "Mr. Rajesh has a monthly salary of Rs. 88,000 (incl. Dearness Allowance). He has worked at Great Co. Ltd. for about 15 years, and his age is 43 years. The company introduced the VRS scheme due to the problem of overstaffing and offered Rs. 21,65,000. He has about 4 months of service remaining. What amount will be exempt for Mr. Rajesh?",
          "To find the amount exempt under Section 10(10C) following limits need to be checked:",
          " 3 months Salary * Completed Years of Service",
          "= 3 * 88,000 * 15 = Rs. 39,60,000",
          "Or Last Drawn Salary * No. of months of remaining service",
          "= 88,000 * 4 = Rs, 3,52,000",
          "Or Rs. 5,00,000",
          "Or The Actual Amount of VRS received",
          "= Rs. 21,65,000",
          "Hence the amount allowed for exemption would be Rs. 3,52,000.",
        ]}
      />
      <Mainhead heading="TDS on Salary under Section 192 of Income Tax Act, 1961 in India" />

      <Step
        number={`
    What does TDS on Salary mean in the Income Tax Act 1961 in India?
  `}
      />
      <Content paragraph="Your employer basically deducts a portion of your salary every month and pays it to the Income Tax Department on your behalf. Such a portion is based on the total salary for the whole year and the investments in tax-saving products. For a salaried employee, TDS forms a major portion of an employee’s income tax payment. The employer provides a TDS certificate called Form 16, showing how much tax has been deducted each month." />

      <Step
        number={`
    Who can deduct TDS on Salary In India?
  `}
      />
      <Content paragraph="The employers allowed to deduct TDS on salary can be Companies, Individuals, HUF, Trusts, Partnership Firms, and Co-operative Societies. These employers must deduct TDS every month at the time of payment of salary. However, if the total income of an employee is not over the basic exemption limit (Rs. 2,50,000), then there is no need to deduct TDS since the tax payable by that employee is zero." />

      <Step
        number={`
    What is the rate at which TDS is to be deducted u/s 192 In India?
  `}
      />
      <Content paragraph="The rate used under this section is called the average rate of tax. It is calculated by first estimating the tax liability of an employee and then dividing it by the number of months of employment under that employer. It is to be noted, however, that if no PAN of the employee is available, then the rate of TDS would be 20% plus 4% of Health and Education Surcharge." />

      <Step
        number={`
    Let's take an example for better understanding:
  `}
      />
      <Content paragraph="Mr. Rajesh has an income of Rs. 8,00,000 and he claims a deduction under section 80 C of Rs. 1,50,000, so his taxable income after standard deduction is Rs. 6,00,000, and if he opts for the old tax regime, then he has to pay a tax of Rs. 33,800 and the same can be paid by his employer by deducting Rs. 2820 every month in the form of TDS." />

      <Step
        number={`
    Updates on TDS Deducted on Salary In India
  `}
      />
      <Content paragraph="⦁ Where the employee is in simultaneous employment with more than one employer, he may furnish the details of salary income, TDS out of such income, and other necessary particulars to any one employer selected by him for the purpose of TDS. In such a case, the selected employer will have to consider such details and deduct TDS." />
      <Content paragraph="⦁ If the employer does not make an exact amount of deduction every month, interest u/s 201(1A) cannot be levied, and any shortfall or excess TDS may be adjusted in subsequent months." />

      <Mainhead heading="Form 16 in Income Tax Act, 1961 in India" />

      <Step
        number={`
    What is Form 16 in the Income Tax Act, 1961 in India?
  `}
      />
      <Content paragraph="Form 16 is a certificate issued by employers to their employees as proof of the TDS (Tax Deducted at Source) from salary income and the details of salary paid during a financial year." />

      <Step
        number={`
    What is the purpose to issue Form 16 in India?
  `}
      />
      <Content paragraph="Employers issue Form 16 to employees showing the details of salary paid, deductions allowed under the Income Tax Act, and the TDS on salary deducted and deposited with the government for the purpose of return filing." />

      <Step
        number={`
    What are the various components of Form 16 in India?
  `}
      />
      <Content paragraph="Form 16 can be divided into two components:" />
      <Content
        paragraph={`
  - Part A: Contains details of the employer and employee, such as their names, addresses, PAN (Permanent Account Number), TAN (Tax Deduction and Collection Account Number) of the employer, and period of employment.
  `}
      />
      <Content
        paragraph={`
  - Part B: Provides a detailed breakup of the salary paid, different components of salary, allowances, deductions under various sections of the Income Tax Act (like 80C, 80D, etc.), and the tax deducted at source.
  `}
      />

      <Step
        number={`
    Is there any advantage of filing Form 16 in India?
  `}
      />
      <Content
        paragraph={`
  - Helps in Filing Income Tax Returns
  - Proof Of Income
  - Document showing deductions and TDS
  - Loan Assessment and Approval
  - Visa Issuance
  - On Switching jobs it can be helpful for the new employer to calculate the tax as per the previously issued statements
  - It also helps in financial planning
  `}
      />

      <Step
        number={`
    I am a non-salaried person - Do I also need Form 16 in India?
  `}
      />
      <Content paragraph="No, for individuals who have income sources other than salary or are self-employed, Form 16 might not be applicable. They need to compute their taxes and file returns based on other relevant documents like Form 16A, Form 16B, etc." />

      <Step
        number={`
    What is Form 16A, 16B, and 16C in India?
  `}
      />
      <Content
        paragraph={`
  - Form 16A: This form is issued for TDS on income other than salary, like interest income, etc.
  `}
      />
      <Content
        paragraph={`
  - Form 16B and Form 16C: These forms are related to TDS on the sale of property and are issued by the buyer of property to the seller.
  `}
      />

      <Step
        number={`
    TDS vs. Tax Liability
  `}
      />
      <Content paragraph="Form 16 helps individuals assess whether the TDS deducted is sufficient to cover their tax liability or an additional tax payment might be required if the TDS is lower than the actual tax liability." />

      <Step
        number={`
    What is my responsibility as an employer in issuing Form 16?
  `}
      />
      <Content
        paragraph={`
  - Mandatory Issuance: Employers are mandated by the Income Tax Department to issue Form 16 to their employees who are subject to TDS on salary.
  `}
      />
      <Content
        paragraph={`
  - Submission to Authorities: Employers need to submit Form 16 to the Income Tax Department and provide a copy to their employees.
  `}
      />
    </Box>
  );
};

export default VRSIndiaTDSSalaryIndia;
