import React from "react";
import Sidecomponent from "../../../Components/Income-Tax/Sidecomponent";
import Topdiv from "../../../Components/Tax-Planning/Topdiv";
import CapitalGainComponent from "./CapitalGainComponent";

import { Grid, Box, Container } from "@mui/material";

import Faq from "../../../Components/Income-Tax/Faq";
import faqData from "./faqData";
import { menuItems } from "./menuItems";
import TypesOfCapitalAssetsComponent from "./TypesOfCapitalAssetsComponent";
import DeductibleExpenses from "./DeductibleExpenses";
import CapitalGainsTax from "./CapitalGainsTax";
import CapitalGainsSections from "./CapitalGainsSections";
import CapitalGainPeriod from "./CapitalGainPeriod";
import SalesConsideration from "./SalesConsideration";
import IndexationAcquisitionImprovement from "./IndexationAcquisitionImprovement";
import CapitalGainCalculation from "./CapitalGainCalculation";
import SlumpSaleExplanation from "./SlumpSaleExplanation";
import InsuranceAndCapitalGainComponent from "./InsuranceAndCapitalGainComponent";
import Section50CAnd43CA from "./Section50CAnd43CA";
import ExemptionSections5454B54G from "./ExemptionSections5454B54G";
import CapitalGainExemption from "./CapitalGainExemption";
import TDSCapitalGain from "./TDSCapitalGain";
import MutualFundTaxation from "./MutualFundTaxation";
import CryptoDigitalMoneyTax from "./CryptoDigitalMoneyTax";
import CapitalAssetToStockInTrade from "./CapitalAssetToStockInTrade";

function Capitalgain() {
  return (
    <div>
      <Topdiv heading="Are you looking to file your Income Tax Returns ?" />
      <div className="Container">
        <Container>
          <Grid container>
            <Grid item lg={4} xl={3}>
              <Box
                style={{
                  display: { xs: "none", lg: "block" },
                  marginTop: "4rem",
                  position: "sticky",
                  top: 90,
                  marginBottom: "3rem",
                }}
              >
                <Sidecomponent menuItems={menuItems} />
              </Box>
            </Grid>

            <Grid item xs={11} lg={8} sx={{ marginTop: "4rem" }} xl={9}>
              <CapitalGainComponent />
              <TypesOfCapitalAssetsComponent />
              <DeductibleExpenses />
              <CapitalGainsTax />
              <CapitalGainsSections />
              <CapitalGainPeriod />
              <SalesConsideration />
              <IndexationAcquisitionImprovement />
              <CapitalGainCalculation />
              <SlumpSaleExplanation />
              <InsuranceAndCapitalGainComponent />
              <Section50CAnd43CA />
              <ExemptionSections5454B54G />
              <CapitalGainExemption />
              <TDSCapitalGain />
              <MutualFundTaxation />
              <CryptoDigitalMoneyTax />
              <CapitalAssetToStockInTrade />
              <Faq data={faqData} />
            </Grid>
          </Grid>
        </Container>
      </div>
    </div>
  );
}

export default Capitalgain;
