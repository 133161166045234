import React from 'react';
import {
    Typography,
    Container,
    Box,
    styled,
    useTheme,
    useMediaQuery,
    Grid,
    Button
} from '@mui/material';
import Hand from "../../../Assets/Home/Common/LegalFactory_Home1.png";
import Girl from "../../../Assets/Home/Common/Image1.png";
import Heading from "../../../Components/CommonCss/Home/Heading"
import DivGap from "../../../Components/CommonCss/Home/DivGap"
import EastIcon from '@mui/icons-material/East';

const Solutions = () => {

    {/* Second head [Your Success is our commitment] part */ }
    const Secondhead = styled(Typography)(({ theme }) => ({
        color: '#FF6600',
        fontWeight: 700,
        textAlign: 'left',
        marginTop: '1.25rem',
        fontStyle: 'normal',
        lineHeight: '2.6rem',
        [theme.breakpoints.between("md", "lg")]: {
            fontSize: '1.2rem',
            marginTop: '10px',
            lineHeight: '2rem',
        },
        [theme.breakpoints.up("lg")]: {
            fontSize: '1.4rem',
            lineHeight: '2rem'
        },

    }));


    {/*Clients and cities heading part */ }
    const Thirdhead = styled(Typography)(({ theme }) => ({
        color: '#FF6600',
        fontWeight: 700,
        textAlign: 'left',
        fontSize: '2rem',
        marginTop: '1.25rem',
        fontStyle: 'normal',
        lineHeight: '2rem',
        [theme.breakpoints.between("md", "lg")]: {
            fontSize: '1.2rem',
            lineHeight: '1rem',
        },
        [theme.breakpoints.up("lg")]: {
            fontSize: '1.3rem',
            lineHeight: '1rem',
        },

    }));


    {/*Content Part */ }
    const Body = styled(Typography)(({ theme }) => ({
        color: '#302F2F',
        fontWeight: 450,
        textAlign: 'left',
        fontSize: '1.2rem',
        lineHeight: '2rem',

        [theme.breakpoints.between("md", "lg")]: {
            fontSize: '1rem',
            lineHeight: '1.8rem',
        },
        [theme.breakpoints.down("md")]: {
            fontSize: '1.2rem',
            top: '75px',
            position: 'absolute',
        },
        [theme.breakpoints.down("sm")]: {
            fontSize: '0.875rem',
            top: '60px',
            lineHeight: '1.2rem',
            position: 'absolute',
        },
    }));



    {/*Clients and cities part content */ }
    const Smallbody = styled(Typography)(({ theme }) => ({
        color: '#302F2F',
        fontSize: '0.9rem',
        fontWeight: 450,
        textAlign: 'left',
        marginTop: '0.623rem',
        fontStyle: 'normal',
        lineHeight: '1.6rem',
        [theme.breakpoints.between("md", "lg")]: {
            fontSize: '0.8rem',
            lineHeight: '1.2rem',


        },



    }));


    {/* Hand Image */ }
    const Image = styled(Box)(({ theme }) => ({
        maxWidth: "90%",
        height: 'auto',
        objectFit: 'cover',
        [theme.breakpoints.between("md", "lg")]: {
            width: '100%',
            height: '98%',
        },
        [theme.breakpoints.down("md")]: {
             width: '100%',
             height:'60%',
             objectFit:'contain'
        

        }

    }));


    {/*Girl Image */ }
    const Secondimg = styled(Box)(({ theme }) => ({
        maxWidth: '65%',
        height: 'auto',

    }));


    {/*Mobile view button client and cities*/ }

    const Whitebutton = styled(Button)(({ theme }) => ({
        background: 'white',
        color: '#F19E1E',
        border: '1px solid #F19E1E',
        borderRadius: 5,
        fontWeight: 'bolder',
        "&:hover": {
            background: 'white',
            color: '#F19E1E',
        },
        [theme.breakpoints.down("md")]: {
            fontSize: '1.4rem',
            bottom: '170px',
            left: '50px',
            position: 'absolute',
        },
        [theme.breakpoints.down("sm")]: {
            fontSize: '1rem',
            bottom: '170px',
            left: '30px',
            position: 'absolute',

        },
    }));


    const Secondbutton = styled(Button)(({ theme }) => ({
        background: 'white',
        color: '#F19E1E',
        border: '1px solid #F19E1E',
        borderRadius: 5,
        fontWeight: 'bolder',
        "&:hover": {
            background: 'white',
            color: '#F19E1E',
        },
        [theme.breakpoints.down("md")]: {
            fontSize: '1.4rem',
            bottom: '170px',
            right: '50px',
            position: 'absolute',
        },
        [theme.breakpoints.down("sm")]: {
            fontSize: '1rem',
            bottom: '170px',
            right: '30px',
            position: 'absolute',


        },

    }));

    const Background = styled(Box)(({ theme }) => ({

        width: '100%',
        height: '100%',
        backgroundColor: 'rgb(250, 250, 250)',

    }));

    

    return (
        <>
            <Background component='div'>
                <Box  sx={{ display: { xs: 'none', md: 'block' } }}>
                <br /><br />
                </Box>
                <div className='Container'>
                    <Box sx={{ display: { xs: 'block', md: 'none' } }}>
                        <Container >
                            {/*Mobile part */}



                            <Box sx={{
                                position: 'relative',
                                textAlign: 'center',
                            }}>
                                <Heading mainhead={[<Box sx={{textAlign:'left'}}>Your Solutions Start Here</Box>]} />
                                <Body>Expertise in Company Registration and Income Tax Solutions</Body>
                                <br />
                                <Image component="img"
                                    sx={{
                                        content: {
                                            xs: `url(${Hand})`
                                        }
                                    }}>

                                </Image>
                                <Whitebutton size='small'>12+ Cities</Whitebutton>
                                <Secondbutton size='small'>390 Clients</Secondbutton>
                                {/*<Redbutton variant='medium' endIcon={<EastIcon fontSize="large" />}>Get Quotes</Redbutton>*/}


                            </Box>
                        </Container>
                    </Box>

                    <Box sx={{ display: { xs: 'none', md: 'block' } }}>
                        <Container>
                            {/*Desktop part */}
                            <Grid container >
                                <Grid item md={3.5} lg={3.5}>
                                    <Heading mainhead={[<Box sx={{ textAlign: 'left' }}>Your<br />Solutions<br /> Start Here.</Box>]}
                                    />
                                    <Body>Expertise in Company<br /> Registration and Income <br />Tax Solutions</Body>

                                    <Secondhead>Your Success Is<br /> Our Commitment.</Secondhead>
                                    {/*<Redbutton variant='medium' endIcon={<EastIcon />}>Get Quotes</Redbutton>*/}
                                </Grid>
                                <Grid item md={5} lg={5} >
                                    <Image component="img"
                                        sx={{
                                            content: {
                                                xs: `url(${Hand})`
                                            }
                                        }}>

                                    </Image>

                                </Grid>
                                <Grid item md={3.5} lg={3.5}>
                                    <Secondimg component="img"
                                        sx={{
                                            content: {
                                                xs: `url(${Girl})`
                                            }
                                        }}>

                                    </Secondimg>
                                    <Grid container>
                                        <Grid item lg={12} xl={12}>
                                            <Smallbody>Welcome to Legal Factory, your trusted partner in company registration and income tax filing. With years of experience and a team of dedicated experts, we simplify the process of establishing your business and ensure seamless compliance with income tax regulations.</Smallbody>
                                        </Grid>
                                        <Grid item lg={12} xl={12}>
                                            <Thirdhead>200+ Clients</Thirdhead>
                                            <Smallbody>Your Vision, Our Mission: A Multiservice Company with a Portfolio of Satisfied Clients</Smallbody>
                                        </Grid>
                                        <Grid item lg={12} xl={12}>
                                            <Thirdhead>12+ Cities</Thirdhead>
                                            <Smallbody>Empowering Progress, Together: Your Multicity Destination for Diverse Services</Smallbody>
                                        </Grid>
                                    </Grid>

                                </Grid>

                            </Grid>
                        </Container>
                    </Box>
                    
                   





                </div>
                
            </Background>
            <DivGap/>
        </>
    )
}

export default Solutions