import Sidecomponent from "../../../Components/Income-Tax/Sidecomponent";
import Topdiv from "../../../Components/Tax-Planning/Topdiv";

import { Grid, Box, Container } from "@mui/material";

import Faq from "../../../Components/Income-Tax/Faq";
import faqData from "./faqData";

import { menuItems } from "../CapitalGain/menuItems";
import BusinessAndProfessionTax from "./BusinessAndProfessionTax";
import IncomeChargeableUnderBusinessAndProfession from "./IncomeChargeableUnderBusinessAndProfession";
import BusinessLosses from "./BusinessLosses";
import FreelancerInfo from "./FreelancerInfo";
import BusinessStructuresInIndia from "./BusinessStructuresInIndia";
import TaxAuditSection44AB from "./TaxAuditSection44AB";
import DeemedBusinessIncomeAndDepreciation from "./DeemedBusinessIncomeAndDepreciation";
import Section44ADPlan from "./Section44ADPlan";
import ScientificResearchSection from "./ScientificResearchSection";
import ExpensesNotAllowed from "./ExpensesNotAllowed";
import BooksOfAccounts from "./BooksOfAccounts";
import ChargingSection28 from "./ChargingSection28";
import ComputationSection29 from "./ComputationSection29";
import Section44AE from "./Section44AE";
import Section40A3and44ADA from "./Section40A3and44ADA";

const Taxableincome = () => {
  return (
    <div>
      <Topdiv heading="Are you looking to file your Income Tax Returns ?" />
      <div className="Container">
        <Container>
          <Grid container>
            <Grid item lg={4} xl={3}>
              <Box
                style={{
                  display: { xs: "none", lg: "block" },
                  marginTop: "4rem",
                  position: "sticky",
                  top: 90,
                  marginBottom: "3rem",
                }}
              >
                <Sidecomponent menuItems={menuItems} />
              </Box>
            </Grid>

            <Grid item xs={11} lg={8} sx={{ marginTop: "4rem" }} xl={9}>
              <BusinessAndProfessionTax />
              <IncomeChargeableUnderBusinessAndProfession />
              <BusinessLosses />
              <FreelancerInfo />
              <BusinessStructuresInIndia />
              <TaxAuditSection44AB />
              <DeemedBusinessIncomeAndDepreciation />
              <Section44ADPlan />
              <ScientificResearchSection />
              <ExpensesNotAllowed />
              <BooksOfAccounts />
              <ChargingSection28 />
              <ComputationSection29 />
              <Section44AE />
              <Section40A3and44ADA />
              <Faq data={faqData} />
            </Grid>
          </Grid>
        </Container>
      </div>
    </div>
  );
};

export default Taxableincome;
