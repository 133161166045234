import React from 'react';
import Mainhead from '../../../../Components/Services/MainContent/Mainhead';
import Content from '../../../../Components/Services/MainContent/Content';
import List from '../../../../Components/Services/MainContent/List';
import Step from '../../../../Components/Services/MainContent/Step';


function Document() {
  return (
    <div>
      <Mainhead heading="Basic Documents Required"/>
      <Step number="Identity and Address Proof"/>
      <ul className='list'>
      <li className='bullet'>
        <span>
      <List points="Scanned copy of PAN card or passport (foreign nationals & NRIs)"/>
      </span>
      </li>
      <li className='bullet'>
        <span>
      <List points="Scanned copy of voter ID/passport/driving licence"/>
      </span>
      </li>
      <li className='bullet'>
        <span>
      <List points="Scanned copy of the latest bank statement/telephone or mobile bill/electricity or gas bill"/>
      </span>
      </li>
      <li className='bullet'>
        <span>
      <List points="Scanned copy of the latest bank statement/telephone or mobile bill/electricity or gas bill"/>
      </span>
      </li>
      </ul>
      <Step number="Registered Office Proof"/>
      <ul className='list'>
        <li className='bullet'>
        <span>
      <List points="Scanned copy of the latest bank statement/telephone or mobile bill/electricity or gas bill"/>
      </span>
      </li>
      <li className='bullet'>
        <span>
      <List points="Scanned copy of notarized rental agreement in English"/>
      </span>
      </li>
      <li className='bullet'>
        <span>
      <List points="Scanned copy of no-objection certificate from the property owner"/>
      </span>
      </li>
      <li className='bullet'>
        <span>
      <List points="Scanned copy of sale deed/property deed in English (in case of owned property)"/>
      </span>
      </li>
</ul>
    </div>
  )
}

export default Document