import * as React from 'react';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Divider } from '@mui/material';
import Content from '../../../../Components/Services/MainContent/Content';
import Mainhead from '../../../../Components/Services/MainContent/Mainhead';

export default function Faq() {
  const [expanded, setExpanded] = React.useState(false);

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  return (
    <div>
      <Mainhead heading="FAQ'S"/>
      <br/>
      <Divider sx={{border: '1px solid #FF990080'}}/>
      <Accordion expanded={expanded === 'panel1'} onChange={handleChange('panel1')}  elevation={0} sx={{ boxShadow: 'unset !important' }} disableGutters>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1bh-content"
          id="panel1bh-header"
        >
          <Typography  sx={{color:'#281900',fontWeight:450,fontSize:'20px'}}>Is there a need to be physically present during this process?</Typography>
        </AccordionSummary>
        <AccordionDetails>
         <Content paragraph ="No, the entire process is online and thus no physical presence is
              required. The Government of India in its efforts to streamline and
              promote ease of doing business has made the entire process through
              its portal."/>
            
         
        </AccordionDetails>
      </Accordion>
      <Divider sx={{border: '1px solid #FF990080'}}/>
      <Accordion expanded={expanded === 'panel2'} onChange={handleChange('panel2')}
       elevation={0} sx={{ boxShadow: 'unset !important' }} disableGutters>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel2bh-content"
          id="panel2bh-header"
        >
          <Typography sx={{color:'#281900',fontWeight:450,fontSize:'20px'}}>
          What are the timeline for setting up a private limited company in
              India?
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
         <Content paragraph="The legal factory team shall assist in filing of all the documents
              with the registrar of company once all the documentation is been
              completed it shall take a maximum timeline of 10-12 days."/>
        </AccordionDetails>
      </Accordion>
      <Divider sx={{border: '1px solid #FF990080'}}/>
      <Accordion expanded={expanded === 'panel3'} onChange={handleChange('panel3')}
       elevation={0} sx={{ boxShadow: 'unset !important' }} disableGutters>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel3bh-content"
          id="panel3bh-header"
        >
          <Typography sx={{color:'#281900',fontWeight:450,fontSize:'20px'}}>
          Are there any guidelines for picking a name for a private limited
              company?
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
         <Content paragraph="The Registrar of Companies (RoC) across India expects the
              applicant to follow a few naming guidelines. Some of them are
              subjective, which means that approval can depend on the opinion of
              the officer handling your application. Moreover, The Trademark
              Team at Legal Factory can assist you in getting a particular name
              Trademarked and Logo and which shall be helpful for picking a
              particular company name, you can contact our support team
              to understand the rules and have a better chance of approval."/>
        </AccordionDetails>
      </Accordion>
      <Divider sx={{border: '1px solid #FF990080'}}/>
      <Accordion expanded={expanded === 'panel4'} onChange={handleChange('panel4')}
       elevation={0} sx={{ boxShadow: 'unset !important' }} disableGutters>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel4bh-content"
          id="panel4bh-header"
        >
          <Typography sx={{color:'#281900',fontWeight:450,fontSize:'20px'}}> What is a DSC?</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Content paragraph="Digital signature (DSC) is the digital code used for signing all
              documents related to the company."/>
        </AccordionDetails>
      </Accordion>
      <Divider sx={{border: '1px solid #FF990080'}}/>
      <Accordion expanded={expanded === 'panel5'} onChange={handleChange('panel5')}
       elevation={0} sx={{ boxShadow: 'unset !important' }} disableGutters>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel5bh-content"
          id="panel5bh-header"
        >
          <Typography sx={{color:'#281900',fontWeight:450,fontSize:'20px'}}> Can a private limited company have perpetual succession ?</Typography>
        </AccordionSummary>
        <AccordionDetails>
         <Content paragraph="A company has perpetual succession and a company has a separate
              legal existence as compared to its shareholders and directors."/>
          </AccordionDetails>
          </Accordion>


          <Divider sx={{border: '1px solid #FF990080'}}/>
      <Accordion expanded={expanded === 'panel6'} onChange={handleChange('panel6')}
       elevation={0} sx={{ boxShadow: 'unset !important' }} disableGutters>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel6bh-content"
          id="panel6bh-header"
        >
          <Typography sx={{color:'#281900',fontWeight:450,fontSize:'20px'}}>Is it necessary to audit the company accounts ?</Typography>
        </AccordionSummary>
        <AccordionDetails>
         <Content paragraph="Yes, a private limited company must hire an auditor, no matter
              what its revenues. In fact, an auditor must be appointed within 30
              days of incorporation."/>
          </AccordionDetails>
          </Accordion>

          <Divider sx={{border: '1px solid #FF990080'}}/>
      <Accordion expanded={expanded === 'panel7'} onChange={handleChange('panel7')}
       elevation={0} sx={{ boxShadow: 'unset !important' }} disableGutters>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel7bh-content"
          id="panel7bh-header"
        >
          <Typography sx={{color:'#281900',fontWeight:450,fontSize:'20px'}}> What are the important documents are required to complete the
              process?</Typography>
        </AccordionSummary>
        <AccordionDetails>
         <Content paragraph="The basic documents that are required are - 
              (i)Copy of PAN Card (must for all resident copy of the latest bank
              statement/telephone or mobile bill/electricity or gas bill
              shareholders / directors ) or Passport (Foreign Nationals & NRIs)
             (ii)
              - Copy of Voter’s ID/Passport/Driver’s License/aadhar card Any of
              one 
              (iii)
              - Copy of the latest bank statement/telephone or mobile
              bill/electricity or gas bill(i.e not older than 2 months and
              payment proof of same.) "/>
          </AccordionDetails>
          </Accordion>

          <Divider sx={{border: '1px solid #FF990080'}}/>
      <Accordion expanded={expanded === 'panel8'} onChange={handleChange('panel8')}
       elevation={0} sx={{ boxShadow: 'unset !important' }} disableGutters>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel8bh-content"
          id="panel8bh-header"
        >
          <Typography sx={{color:'#281900',fontWeight:450,fontSize:'20px'}}> Can you explain EMoa and eAoA of a private limited company.</Typography>
        </AccordionSummary>
        <AccordionDetails>
         <Content paragraph="The legal factory team shall assist in compiling and drafting the
              Memorandum Of Association (EMoa) and Articles Of Association
              (eAoA) of the company and also ensure that they are complied with
              the Company Act Rules and Laws.The EMoa and eAoA are very
              important in administering and managing a company business , also
              it is a very important formation document which shall be required
              for day to day operations."/>
          </AccordionDetails>
          </Accordion>

          <Divider sx={{border: '1px solid #FF990080'}}/>
      <Accordion expanded={expanded === 'panel9'} onChange={handleChange('panel9')}
       elevation={0} sx={{ boxShadow: 'unset !important' }} disableGutters>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel9bh-content"
          id="panel9bh-header"
        >
          <Typography sx={{color:'#281900',fontWeight:450,fontSize:'20px'}}> How much does it cost to setup your company or do company
              incorporation ?</Typography>
        </AccordionSummary>
        <AccordionDetails>
         <Content paragraph="You can request a callback and get a free 15 minutues consultation
              so that we can understand your business requirements and create a
              customised package to suit your needs. After that you can start
              the company registration process by paying Rs 499."/>
          </AccordionDetails>
          </Accordion>
          <Divider sx={{border: '1px solid #FF990080'}}/>
      <Accordion expanded={expanded === 'panel10'} onChange={handleChange('panel10')}
       elevation={0} sx={{ boxShadow: 'unset !important' }} disableGutters>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel10bh-content"
          id="panel10bh-header"
        >
          <Typography sx={{color:'#281900',fontWeight:450,fontSize:'20px'}}>Is there a limit on the minimum capital needed to form a Private
              Limited Company?</Typography>
        </AccordionSummary>
        <AccordionDetails>
         <Content paragraph=" Normally the minimum paid capital with which the people start a
              private limited company is 1 lakh rupees, as such the act does not
              specify the minimum paid up capital requirement"/>
          </AccordionDetails>
          </Accordion>
      

          <Divider sx={{border: '1px solid #FF990080'}}/>
      <Accordion expanded={expanded === 'panel11'} onChange={handleChange('panel11')}
       elevation={0} sx={{ boxShadow: 'unset !important' }} disableGutters>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel11bh-content"
          id="panel11bh-header"
        >
          <Typography sx={{color:'#281900',fontWeight:450,fontSize:'20px'}}>Is it possible that the director of a private limited company is
              also a salaried person?</Typography>
        </AccordionSummary>
        <AccordionDetails>
         <Content paragraph="Yes, a salaried person can become the director in Private limited,
              LLP or OPC. You will need to check the employment agreement if
              that allows you for such provisions. In most of the cases, the
              employers are quite comfortable with the fact that their employee
              is a director in another company."/>
          </AccordionDetails>
          </Accordion>
      

          <Divider sx={{border: '1px solid #FF990080'}}/>
      <Accordion expanded={expanded === 'panel12'} onChange={handleChange('panel12')}
       elevation={0} sx={{ boxShadow: 'unset !important' }} disableGutters>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel12bh-content"
          id="panel12bh-header"
        >
          <Typography sx={{color:'#281900',fontWeight:450,fontSize:'20px'}}> Can we legally convert limited liability partnership (LLP) to a
              Private Limited Company?</Typography>
        </AccordionSummary>
        <AccordionDetails>
         <Content paragraph="Yes, an LLP can be converted into a company and vice versa since
              Corporatization is the need of the hour. The entire world is
              gradually drifting towards one global market without any trade
              barriers between the countries."/>
          </AccordionDetails>
          </Accordion>

          <Divider sx={{border: '1px solid #FF990080'}}/>
      

         




      


      


      



    </div>
  );
}