const faqData = [
  {
    id: "panel1",
    question: "What is deemed income?",
    answer:
      "Any individual receives a benefit (cash, moveable or immovable) in excess of Rs. 50,000 from another person.",
  },
  {
    id: "panel2",
    question:
      "Can closely held businesses issue shares in excess of their fair market value?",
    answer:
      "Yes, closely owned corporations can issue more than their fair market value and have the extra money taxed in their hands.",
  },
  {
    id: "panel3",
    question:
      "Is the tax liability applicable to any ULIP purchased before February 1, 2021?",
    answer:
      "No, you will not be taxed on ULIP investments made before February 1, 2022.",
  },
  {
    id: "panel4",
    question: "Who deducts TDS from lottery or game show winnings?",
    answer:
      "Before the awards are paid out, the entity offering them will deduct TDS.",
  },
  {
    id: "panel5",
    question: "Can I claim a deduction if I invest the prize money in ELSS?",
    answer:
      "No, if you win cash or property as a prize from a game show or lottery, you won't be qualified for any tax exemptions or deductions.",
  },
  {
    id: "panel6",
    question: "How do I file tax on online poker winnings in India?",
    answer:
      "Winnings from poker will be subject to taxes at the maximum tax rate of 30%.",
  },
  {
    id: "panel7",
    question:
      "How much tax would Suman have to pay if he were to win an Alto car whose market value is Rs. 4,00,000?",
    answer:
      "Suman has won an Alto car in a contest whose market value is Rs. 4 lakhs, then tax @ 31.2%, which is Rs. 1,24,800, must be paid before giving the car to the winner. (30% + 4 % health and education cess)",
  },
  {
    id: "panel8",
    question: "Is any deduction/expenditure allowed from lottery income?",
    answer:
      "No deduction/expenditure is allowed from such income. No deduction under section 80C or 80D or any other deduction/allowance is allowed from such income.",
  },
  {
    id: "panel9",
    question: "Are employee contributions to provident funds taxable?",
    answer:
      "In many jurisdictions, employee contributions to provident funds, such as the Employees' Provident Fund (EPF), are eligible for tax benefits under specified conditions. The employer's contribution may also be subject to tax rules. It's important to check the specific provisions of the Income Tax Act in your country for details.",
  },
  {
    id: "panel10",
    question:
      "Can I claim a tax deduction for voluntary retirement contributions?",
    answer:
      "The tax treatment of voluntary retirement contributions can vary. In some cases, contributions to retirement savings plans may be eligible for tax benefits. Check the tax laws in your jurisdiction and consult with a tax professional for guidance on the specific treatment of voluntary retirement contributions.",
  },
  {
    id: "panel11",
    question: "Are superannuation contributions taxable for employees?",
    answer:
      "Superannuation contributions made by employees may have varying tax implications depending on the jurisdiction. In some cases, contributions to superannuation funds may be eligible for tax benefits. Consult the local tax laws or seek advice from a tax professional to understand the specific rules that apply to superannuation contributions.",
  },
  {
    id: "panel12",
    question: "Are employee contributions to group health insurance taxable?",
    answer:
      "Employee contributions to group health insurance premiums may be eligible for tax benefits in certain jurisdictions. The tax treatment can depend on the local regulations. It's advisable to check the specific provisions of the Income Tax Act in your country or consult with a tax professional for guidance on health insurance contributions.",
  },
  {
    id: "panel13",
    question: "How are employee contributions to pension schemes taxed?",
    answer:
      "The tax treatment of employee contributions to pension schemes can vary. In some cases, contributions may be eligible for tax deductions or exemptions. Check the tax laws in your jurisdiction and consult with a tax professional to understand how pension contributions are taxed.",
  },

  {
    id: "panel14",
    question: "Is rental income from leasing plant and machinery taxable?",
    answer:
      "Yes, rental income from leasing plant and machinery is generally considered taxable income. The income is typically subject to taxation under the head of 'Income from Other Sources' in many jurisdictions. The specific tax treatment, rates, and deductions can vary, so it's important to refer to the local tax laws.",
  },
  {
    id: "panel15",
    question:
      "Can I claim any deductions against rental income from plant and machinery?",
    answer:
      "In some jurisdictions, certain deductions may be available against rental income from plant and machinery. Common deductions include maintenance expenses, repair costs, and depreciation on the assets. Check the local tax laws and consult with a tax professional to determine the specific deductions applicable to your situation.",
  },
  {
    id: "panel16",
    question: "How is depreciation calculated on rented plant and machinery?",
    answer:
      "The calculation of depreciation on rented plant and machinery depends on the local tax regulations. Generally, depreciation is calculated based on the cost of the asset, its useful life, and the method of depreciation allowed by tax authorities. Refer to the specific rules outlined in the Income Tax Act of your jurisdiction for details on calculating depreciation.",
  },
  {
    id: "panel17",
    question:
      "Are there any exemptions for rental income from specific types of plant and machinery?",
    answer:
      "Some jurisdictions may provide exemptions or reduced tax rates for specific types of plant and machinery. For example, there might be special provisions for certain environmentally friendly or energy-efficient equipment. Consult the local tax laws or seek advice from a tax professional to understand any exemptions applicable to your assets.",
  },
  {
    id: "panel18",
    question: "Are there any tax incentives for leasing plant and machinery?",
    answer:
      "Some jurisdictions may offer tax incentives or credits for leasing specific types of plant and machinery, especially if they contribute to certain economic or environmental goals. Check the local tax laws to identify any incentives or consult with a tax professional for information on available benefits.",
  },

  {
    id: "panel19",
    question: "Is family pension taxable under the Income Tax Act?",
    answer:
      "Yes, family pension is generally taxable under the head 'Income from Other Sources' as per Section 56 of the Income Tax Act.",
  },
  {
    id: "panel20",
    question: "Are there any exemptions for family pension income?",
    answer:
      "Family pension received by the widow, children, or nominated heirs of a deceased member of the armed forces is exempt from tax under Section 10(18).",
  },
  {
    id: "panel21",
    question:
      "How is family pension treated for tax purposes if the recipient is a minor?",
    answer:
      "If the recipient of the family pension is a minor, it is clubbed with the income of the parent whose income is higher.",
  },
  {
    id: "panel22",
    question:
      "What is the tax treatment of other income earned by family members in addition to the family pension?",
    answer:
      "Any other income earned by family members should be reported under the respective heads, such as salary, business income, or capital gains.",
  },
  {
    id: "panel23",
    question:
      "Are there any provisions for senior citizens or individuals with family pension income?",
    answer:
      "While there are specific provisions for senior citizens, there is no separate treatment for family pension income. Senior citizens may be eligible for higher basic exemption limits.",
  },

  {
    id: "panel24",
    question:
      "Is the premium paid for a key man insurance policy tax-deductible?",
    answer:
      "Premiums paid for key man insurance policies are not typically tax-deductible as business expenses. However, the proceeds received from the policy may be tax-exempt under certain conditions.",
  },
  {
    id: "panel25",
    question: "Are the proceeds from a key man insurance policy taxable?",
    answer:
      "The tax treatment of proceeds from a key man insurance policy depends on the purpose for which the insurance was taken out and the relationship of the business with the insured individual.",
  },
  {
    id: "panel26",
    question:
      "If the key man insurance proceeds are received as a lump sum, is it taxable as income?",
    answer:
      "Generally, if the purpose of the key man insurance is to indemnify the business against the loss of a key employee, the proceeds are not treated as income and are tax-exempt.",
  },
  {
    id: "panel27",
    question:
      "How is the tax treatment if the key man insurance proceeds are invested and earn interest or capital gains?",
    answer:
      "Any interest or capital gains earned on the investment of key man insurance proceeds may be taxable. The taxation would depend on the nature of the income generated.",
  },
  {
    id: "panel28",
    question:
      "Is there a specific form to report key man insurance proceeds while filing income tax returns?",
    answer:
      "Key man insurance proceeds are not typically reported as income while filing income tax returns if they are received for the purpose of business protection.",
  },

  {
    id: "panel29",
    question: "Can I claim deductions for family pension income?",
    answer:
      "Deductions under the Income Tax Act are generally applicable to specific heads of income, and there are limited deductions directly related to family pension income. However, you may be eligible for standard deductions or deductions under Section 57.",
  },
  {
    id: "panel30",
    question:
      "Are there any deductions available for income from other sources for senior citizens?",
    answer:
      "Senior citizens may be eligible for additional deductions, such as those under Section 80D for health insurance premiums and Section 80DDB for medical expenses. However, these deductions are not specific to income from other sources.",
  },
  {
    id: "panel31",
    question:
      "Can I claim deductions for expenses incurred in earning income from other sources?",
    answer:
      "Yes, certain expenses directly related to earning income from other sources may be deductible. For example, if you earn interest income and incur expenses for earning that income, those expenses may be eligible for deduction.",
  },
  {
    id: "panel32",
    question:
      "Can I claim a deduction for expenses related to earning rental income from other sources?",
    answer:
      "Yes, expenses related to earning rental income from other sources, such as property taxes, repairs, and maintenance, may be eligible for deduction under the head 'Income from House Property.'",
  },
  {
    id: "panel33",
    question:
      "Do I need to submit any additional documents to claim deductions against income from other sources?",
    answer:
      "Depending on the nature of the deduction, you may need to submit supporting documents such as bills, receipts, or certificates. It's essential to maintain proper documentation to substantiate your claims during tax assessments.",
  },

  {
    id: "panel34",
    question: "Is TDS applicable to income from other sources?",
    answer:
      "Yes, TDS provisions are applicable to various types of income, including income from other sources such as interest income, family pension, lottery winnings, etc.",
  },
  {
    id: "panel35",
    question: "At what rate is TDS deducted on income from other sources?",
    answer:
      "The TDS rate varies depending on the type of income. For example, TDS on interest income is generally deducted at 10%, subject to certain exemptions and thresholds.",
  },
  {
    id: "panel36",
    question: "Is TDS applicable to family pension income?",
    answer:
      "Yes, TDS is applicable to family pension income. The person responsible for making the payment is required to deduct TDS at the prescribed rates.",
  },
  {
    id: "panel37",
    question: "What is the TDS rate on family pension income?",
    answer:
      "TDS rates on family pension income can vary. The payer needs to deduct TDS as per the applicable rates and deposit it with the government.",
  },

  {
    id: "panel38",
    question: "What is DDT?",
    answer:
      "DDT is a tax required to be paid by domestic companies on the dividend paid by them to the shareholders. It is not applicable w.e.f 1.04.2020.",
  },
  {
    id: "panel39",
    question: "Is advance tax liability to be paid on dividend income?",
    answer:
      "If the shortfall in the advance tax installment or the failure to pay the same on time is on account of dividend income, no interest under section 234C shall be charged provided the assessee has paid full tax in subsequent advance tax installments. However, this benefit shall not be available in respect of the deemed dividend as referred to in Section 2(22)(e).",
  },
  {
    id: "panel40",
    question:
      "Which section provides for deduction of tax at source on distribution or payment of dividend by an Indian Company?",
    answer:
      "Section 194 provides for deduction of tax at source on distribution or payment of dividend by an Indian Company.",
  },

  {
    id: "panel41",
    question: "What types of incomes are covered under section 57?",
    answer:
      "Section 57 covers income from other sources, income from house property, and income from business or profession.",
  },
  {
    id: "panel42",
    question: "How is family pension different from pension?",
    answer:
      "Pension is taxable under the head income from salary whereas family pension is taxable under the head income from other sources.",
  },
  {
    id: "panel43",
    question: "Is depreciation allowed as a deduction?",
    answer:
      "Yes, depreciation on plant, furniture, machinery, and building can be claimed as a deduction against income earned from letting out such an asset.",
  },

  {
    id: "panel44",
    question: "What are securities in the context of interest income?",
    answer:
      "Securities refer to various financial instruments like government bonds, corporate bonds, debentures, fixed-income securities, etc., that generate interest income for investors.",
  },
  {
    id: "panel45",
    question: "Is the interest earned on securities taxable?",
    answer:
      "Yes, interest earned on securities is generally taxable as per the Income Tax Act and needs to be included in the taxpayer's total income for the respective assessment year.",
  },
  {
    id: "panel46",
    question: "How is the interest income from securities taxed?",
    answer:
      "Interest income from securities is typically added to the taxpayer's total income and taxed at the applicable income tax rates based on their income slab as per the Income Tax Act.",
  },
  {
    id: "panel47",
    question:
      "What is Tax Deduction at Source (TDS) on interest income from securities?",
    answer:
      "TDS is the tax deducted by the payer (like banks or financial institutions) on the interest paid to the recipient. It's deducted at a specified rate set by tax regulations.",
  },
  {
    id: "panel48",
    question:
      "Can expenses related to earning interest on securities be claimed as deductions?",
    answer:
      "Yes, expenses directly related to earning interest income on securities can be claimed as deductions under Section 57(1)(iii) of the Income Tax Act, provided they are wholly and exclusively incurred for earning such income.",
  },
  {
    id: "panel49",
    question:
      "Are there any exemptions or deductions available for certain types of securities?",
    answer:
      "Yes, certain government bonds or specified securities might enjoy exemptions or deductions as per the provisions specified in the Income Tax Act.",
  },
  {
    id: "panel50",
    question:
      "How is the accrued interest on securities treated for tax purposes?",
    answer:
      "Accrued interest on securities is generally considered taxable income in the year it accrues, even if it's not yet received in cash.",
  },
  {
    id: "panel51",
    question:
      "What documentation is necessary for claiming deductions against interest income from securities?",
    answer:
      "Taxpayers should maintain proper records and documentation to substantiate the expenses incurred against interest income, ensuring compliance and validity of deductions.",
  },
  {
    id: "panel52",
    question: "Is grossing up applicable to interest income from securities?",
    answer:
      "Grossing up is used to determine the original or gross amount of interest income before the deduction of taxes, especially in cases where Tax Deduction at Source (TDS) is applied.",
  },
  {
    id: "panel53",
    question:
      "How can one seek advice on managing tax implications related to interest on securities?",
    answer:
      "Seeking guidance from tax professionals, financial advisors, or referring to official tax resources can help individuals understand and manage tax implications related to interest income from securities.",
  },

  {
    id: "panel54",
    question: "What constitutes a gift under the Income Tax Act?",
    answer:
      "A gift is considered any sum of money, movable property, or immovable property received without consideration, i.e., without anything in return.",
  },
  {
    id: "panel55",
    question:
      "Is there any specific exemption for gifts received under the Income Tax Act?",
    answer:
      "Yes, certain gifts are exempt from tax. Gifts received from specified relatives (as defined in the Income Tax Act) or received on occasions like marriage or inheritance are generally exempt.",
  },
  {
    id: "panel56",
    question: "Is the entire gift amount taxable?",
    answer:
      "No, only the amount exceeding the specified limits is taxable. As of my last update, any gift received above Rs. 50,000 in a financial year is taxable, unless it falls under specific exemptions.",
  },
  {
    id: "panel57",
    question: "What is the tax treatment of gifts received from an employer?",
    answer:
      "Gifts received from an employer are considered a perquisite and are taxed under the head 'Income from Salaries.' However, certain small gifts may be exempt.",
  },
  {
    id: "panel58",
    question: "Are gifts received by HUF (Hindu Undivided Family) taxable?",
    answer:
      "Yes, gifts received by an HUF are taxable under the head 'Income from Other Sources' if they do not fall under any exemptions.",
  },
  {
    id: "panel59",
    question:
      "Is there any reporting requirement for gifts in the income tax return?",
    answer:
      "Yes, gifts received exceeding Rs. 50,000 in a financial year need to be reported in the income tax return under the head 'Income from Other Sources.'",
  },
  {
    id: "panel60",
    question: "Can gifts be received in cash without attracting tax?",
    answer:
      "Gifts received in cash exceeding Rs. 50,000 are taxable. It is advisable to receive gifts through banking channels to maintain transparency.",
  },

  {
    id: "panel61",
    question: "Under which head is commission income taxed?",
    answer:
      "Commissions are generally taxed under the head 'Income from Other Sources' unless they are directly related to a business or profession, in which case they may be taxed under the head 'Profits and Gains of Business or Profession.'",
  },
  {
    id: "panel62",
    question: "What are the applicable TDS rates on commission income?",
    answer:
      "TDS rates on commission income can vary depending on the nature of the commission and the provisions of the Income Tax Act. It's important to check the specific section relevant to the type of commission received.",
  },
  {
    id: "panel63",
    question:
      "Can I claim expenses against commission income to reduce taxable income?",
    answer:
      "The Income Tax Act allows individuals to deduct certain genuine and reasonable business expenses related to earning commission income. Proper documentation should be maintained to support such deductions.",
  },
  {
    id: "panel64",
    question:
      "How is commission income reported while filing income tax returns?",
    answer:
      "Commission income should be reported under the head 'Income from Other Sources' in the income tax return. The appropriate ITR (Income Tax Return) form should be used based on the overall income and nature of the commission.",
  },
  {
    id: "panel65",
    question: "Is there a threshold for taxability of commission income?",
    answer:
      "In general, there is no specific threshold for the taxability of commission income. All commission income, regardless of the amount, is subject to income tax.",
  },

  {
    id: "panel66",
    question:
      "Is it possible to deduct personal expenses from other sources of income?",
    answer:
      "Section 58 of the Income Tax Act of 1961 states that personal expenses cannot be subtracted from income received from other sources.",
  },
  {
    id: "panel67",
    question: "Can I deduct income tax from sources other than my own?",
    answer: "No, you cannot deduct income tax from other sources of income.",
  },
];

export default faqData;
