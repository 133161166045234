import React from "react";
import { Box } from "@mui/material";
import Mainhead from "../../../Components/Services/MainContent/Mainhead";
import Content from "../../../Components/Services/MainContent/Content";
import Step from "../../../Components/Services/MainContent/Step";
import ReusableTable from "../../../Components/Income-Tax/ReusableTable";

const TDSCapitalGain = () => {
  const longTermCapitalGainTableData = [
    {
      Particulars: "LESS THAN 50 LAKH",
      TaxRate: "20%",
      Surcharge: "0%",
      TotalTaxIncludingSurcharge: "20%",
      Cess: "4%",
      ApplicableTDSRate: "20.80%",
    },
    {
      Particulars: "50 LAKH TO 1 CRORE",
      TaxRate: "20%",
      Surcharge: "10% of above",
      TotalTaxIncludingSurcharge: "22%",
      Cess: "4%",
      ApplicableTDSRate: "22.88%",
    },
    {
      Particulars: "MORE THAN 1 CRORE",
      TaxRate: "20%",
      Surcharge: "15% of above",
      TotalTaxIncludingSurcharge: "23%",
      Cess: "4%",
      ApplicableTDSRate: "23.92%",
    },
  ];

  const mutualFundTaxationTableData = [
    {
      Particulars:
        "CAPITAL GAINS ON EQUITY-ORIENTED MUTUAL FUNDS (SUBJECT TO STT*) of NRI except Foreign Institutional Investor (FII)",
      ShortTerm: "15%",
      LongTerm: "10%",
    },
    {
      Particulars:
        "CAPITAL GAINS ON NON-EQUITY ORIENTED MUTUAL FUNDS of NRI except Foreign Institutional Investor (FII)",
      ShortTerm: "30%",
      LongTerm:
        "Listed: 20% (with indexation benefit) Unlisted: 10% (without indexation and foreign exchange fluctuation benefit)",
    },
    {
      Particulars: "TAX ON INCOME DISTRIBUTION (IDCW OPTION)",
      ShortTerm: "20%",
      LongTerm: "-",
    },
  ];

  return (
    <Box
      style={{
        display: "flex",
        flexDirection: "column",
        gap: 4,
        marginBottom: "2rem",
      }}
    >
      {" "}
      <Mainhead heading="TDS on Capital Gain" />
      <Step number="TDS on Property Sale Under Section 194IA"></Step>
      <Content paragraph="TDS @ 1% is applicable to transactions involving immovable property where the consideration exceeds Rs. 50 lakhs and the property is bought from an Indian resident." />
      <Step number="More than one seller or buyer"></Step>
      <Content paragraph="If there are multiple buyers or sellers and the transaction's total value exceeds Rs. 50 lakhs, Section 194IA will apply, and TDS on property must be deducted and deposited with the government before the deadline." />
      <Step number="TDS on Purchase of Property from Non-Resident (Section 195)"></Step>
      <Content paragraph="According to Section 195, any person responsible for paying any interest or any other sum chargeable under the provisions of this Act to a non-resident shall deduct TDS at the prescribed rates." />
      <Step number="Rates of TDS"></Step>
      <Content paragraph="The type of capital gain determines the rate of TDS. Rates for long-term and short-term capital gains for non-residents are as follows:" />
      <Step number="TDS RATE ON LONG TERM CAPITAL GAIN " />
      Gains arising to non-residents that are held for two years or longer are
      considered long-term and are subject to a 20% tax rate (plus surcharge and
      cess). Let's visualize this using the following table:
      <ReusableTable data={longTermCapitalGainTableData} />
      <Step number="TDS RATE ON SHORT TERM CAPITAL GAIN "></Step>
      The gain arising to the non-resident is of a short-term nature and is
      subject to income tax if the property is held for less than two years. In
      accordance with the income tax slab, the applicable tax rate will also
      include the surcharge and cess.
      <Step number="Mutual Fund Taxation for NRIs in India"></Step>
      <Content paragraph="For NRIs, mutual fund taxation in India involves different tax rates for short-term and long-term capital gains on equity-oriented and non-equity-oriented mutual funds." />
      <ReusableTable data={mutualFundTaxationTableData} />
      *STT @ 0.001% is applicable on redemption of units of Equity-oriented
      Mutual Funds. After adding the surcharge and the health and education
      cess, all of the above TDS was deducted.
    </Box>
  );
};

export default TDSCapitalGain;
