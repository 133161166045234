import React from 'react'
import Sidecomponent from '../../../Components/Tax-Planning/Sidecomponent'
import Topdiv from "../../../Components/Tax-Planning/Topdiv"
import GetInTouch from '../../../Components/Services/GetInTouch';

import {
  Grid, Box, Container, styled, Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Link
} from '@mui/material';

import Mainhead from '../../../Components/Services/MainContent/Mainhead';
import Content from '../../../Components/Services/MainContent/Content';
import List from '../../../Components/Services/MainContent/List';
import Step from '../../../Components/Services/MainContent/Step';

import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import Faq from './Faq';

function createData(
   sections,
   eligible,
) {
  return {
   sections,
   eligible,
  };
}

function createDataopt(
  InvestmentOptions,
  AverageInterest,
  LockPeriod,
  RiskFactor,
) {
 return {
  InvestmentOptions,
  AverageInterest,
  LockPeriod,
  RiskFactor,
 };
}

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: '#773106',
    color: theme.palette.common.white,
    textAlign: 'left',
    fontFamily: 'Sora',
    fontWeight: 'bold',



  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 16,
    textAlign: 'left',
    textAlign: 'left',
    fontFamily: 'Sora',
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: theme.palette.action.hover,
    fontFamily: 'Sora'
  },
  // hide last border
  '&:last-child td, &:last-child th': {
    border: 0,
  },
}));


const rows = [
  createData('80C', 'Payments made towards life insurance premiums, Equity Linked Saving Schemes, payments made towards the principal sum of a home loan, SSY, NSC, SCSS, and so on.',),
  createData('80CCC', 'Payment made towards pension plans, and mutual funds.',),
  createData('80CCD (1)', 'Payments paid to government-sponsored plans such as the National Pension System, the Atal Pension Yojana, and others.',),
  createData('80CCD (1B) ', 'Investments of up to Rs.50,000 in NPS.'),
  createData('80CCD (2)', 'Employer’s contribution towards NPS (up to 10%, comprising basic salary and dearness allowance, if any)',),
 
];

const rowsopt = [
  createDataopt('ELSS funds','12% - 15%','3 years','High',),
  createDataopt('NPS Scheme','8% – 10%','Till 60 years of age','High',),
  createDataopt('ULIP','8% – 10%','5 years','Medium',),
  createDataopt('Tax saving FD','Up to 8.40%','5 years','Low',),
  createDataopt('PPF','7.90%','15 years','Low',),
  createDataopt('Senior citizen savings scheme','8.60%','5 years (can be extended for other 3 years)','Low',),
  createDataopt('National Savings Certificate','7.9%','5 years','Low',),
  createDataopt('Sukanya Samriddhi Yojana','8.50%','Till girl child reaches 21 years of age','Low',),

];

const Bottomspace = styled(Box)(({ theme }) => ({
  marginBottom:'2.1rem'
}));

function Taxableincome() {
  return (
    <div>
     <Topdiv heading="Are you looking to file your Income Tax Returns ?"/>
     <div className='Container'>
       <Container>
        <Grid container>
          <Grid item  lg={4} xl={3}>
            <Box sx={{ display: { xs: 'none', lg: 'block' }, marginTop: '4rem', position: 'sticky', top: 90, marginBottom: '3rem' }}>
              <Sidecomponent />
            </Box>
          </Grid>
          <Grid item xs={11} lg={8} sx={{ marginTop: '4rem' }} xl={9}>
            <Mainhead heading="Deductions"/>
            
            <Content paragraph="A deduction is a reduction in the amount of income that is subject to tax. Deductions can be taken for a variety of expenses and investments, such as mortgage interest, charitable donations, and state and local taxes. The purpose of deductions is to lower a taxpayer's overall tax liability by reducing their taxable income. Deductions can be taken on both federal and state taxes, and the rules and guidelines for taking deductions can vary depending on the jurisdiction."/> 
            <Bottomspace/>
            
            <Mainhead heading="Various sections for deductions:"/>
            <br/>
            <Step number="Section 80C:"/>
            <Content paragraph="Section 80C of the Income Tax Act in India allows for certain payments made or deposited in the previous year to be eligible for deductions from taxable income. These payments include life insurance premiums, contributions to specified savings plans and funds, repayment of housing loans from certain sources, tuition fees for full-time education of children, fixed term deposits with scheduled banks, certain bonds, and certain savings schemes. Additionally, certain contributions made by central government employees to a specified pension plan are also eligible for deductions under this section. The maximum amount that can be claimed as a deduction under this section is Rs. 1,50,000."/>
            <Bottomspace/>
            <Step number="Section 80CCC:"/>
            <Content paragraph="80CCC refers to a section of the Income Tax Act in India that allows individuals to claim a tax deduction for contributions made to certain pension funds, such as an annuity plan of LIC of India or any other insurer, for the purpose of receiving a pension from the fund. The amount of the deduction is the amount paid or deposited, subject to a maximum of INR 1,50,000. This means that an individual can claim a tax deduction of up to INR 1,50,000 for contributions made to such a fund in a financial year."/> 
             <Bottomspace/>
            <Step number="Section 80CCD:"/>
            <Content paragraph="80CCD refers to a section of the Income Tax Act in India that allows individuals to claim a tax deduction for contributions made to certain pension schemes, such as the Pension Scheme of the Central Government, the New Pension Scheme, or the Atal Pension Yojana."/><br/>

            <b>80CCD(1):</b><br/>
            <Content paragraph="Under Sec 80CCD(1), an individual can claim a tax deduction for their own contribution to the pension scheme. For salaried individuals, the deduction is restricted to 10% of their salary. For others, the deduction is restricted to 20% of their gross total income. Additionally, an additional deduction of up to INR 50,000 is available under Sec 80CCD(1B)."/><br/>

            <b>80CCD(2):</b><br/>
            <Content paragraph="Under Sec 80CCD(2), an individual can claim a tax deduction for the employer's contribution to the pension scheme. For employees of the Central Government, the deduction is restricted to 14% of their salary, and for any other employer, it is restricted to 10%. The entire employer's contribution is included in the salary of the employee. These deductions can be claimed by the individual in addition to the deduction available under 80CCC."/>
            <Bottomspace/>
            <TableContainer component={Paper}>
                <Table  aria-label="customized table"  size='small'>
        <TableHead>
          <TableRow>
            <StyledTableCell>Sections</StyledTableCell>
            <StyledTableCell>Eligible investments for Tax Deductions</StyledTableCell>
         
           
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.map((row) => (
            <StyledTableRow
              key={row.name}
            
            >
              <StyledTableCell component="th" scope="row"
              >
                {row.sections}
              </StyledTableCell>
              <StyledTableCell>{row.eligible}</StyledTableCell>
             
              </StyledTableRow>
          ))}
        </TableBody>
      </Table>
      </TableContainer>
       <br/><br/> <br/>

       <Step number="SECTION 8OC INVESTMENT OPTIONS"/> <br/> <br/>
       <TableContainer component={Paper}>
                <Table  aria-label="customized table"  size='small'>
        <TableHead>
          <TableRow>
            <StyledTableCell>Investment options</StyledTableCell>
            <StyledTableCell>Average Interest</StyledTableCell>
            <StyledTableCell>Lock-in period for</StyledTableCell>
            <StyledTableCell>Risk factor</StyledTableCell>

          </TableRow>
        </TableHead>
        <TableBody>
          {rowsopt.map((row) => (
            <StyledTableRow
              key={row.name}
            >
              <StyledTableCell component="th" scope="row"
              >
                {row.InvestmentOptions}
              </StyledTableCell>
              <StyledTableCell>{row.AverageInterest}</StyledTableCell>
              <StyledTableCell>{row.LockPeriod}</StyledTableCell>
              <StyledTableCell>{row.RiskFactor}</StyledTableCell>
             
              </StyledTableRow>
          ))}
        </TableBody>
      </Table>
      </TableContainer>
       <br/><br/> <br/>
       
       <Step number="Section 80D:"/> 
       <Content paragraph="80D is a section of the Income Tax Act in India that allows individuals and Hindu Undivided Families (HUFs) to claim a tax deduction for the premium paid for medical insurance. The deduction can be claimed for the premium paid for the insurance of self, spouse, dependent children, and parents (in case of an individual) or members (in case of an HUF). The premium must be paid otherwise than by way of cash. Payment for preventive health checkup can be made in cash."/><br/>

       <Content paragraph="The maximum amount that can be claimed as a deduction under this section is INR 25,000 (INR 50,000 in case the individual or his or her spouse is a senior citizen). This means that an individual or HUF can claim a tax deduction of up to INR 25,000 or INR 50,000 (for senior citizens) for the premium paid for medical insurance in a financial year."/>
       <Bottomspace/>

       <Step number="Section 80DD:"/>
       <Content paragraph="Section 80DD of the Income Tax Act in India allows for a deduction of certain expenses incurred towards the maintenance and medical treatment of a dependent disabled person. The dependent can be a spouse, children, parents, brothers, or sisters of the individual taxpayer or any member of a Hindu Undivided Family (HUF). However, the dependent must not have claimed a deduction under Section 80U for the same year. The deduction is a flat amount of 75,000 rupees for a person with a disability of less than 80%, and 1,25,000 rupees for a person with a disability of 80% or more."/> <br/> <br/>

       <Step number="Section 80DDB:"/>
       <Content paragraph="Section 80DDB of the Income Tax Act in India allows for a deduction of certain expenses incurred towards the medical treatment of specified diseases or ailments. The deduction is available to both individual taxpayers and Hindu Undivided Families (HUFs). For an individual, the expenses can be claimed for themselves or for their dependant who is wholly or mainly dependent on them for support and maintenance. The dependent can be a spouse, children, parents, brothers, or sisters. For a HUF, the expenses can be claimed for any member. The deduction is limited to the actual expenses incurred or 40,000 rupees, whichever is less, minus any amount received from an insurance company or reimbursed by an employer."/>
       <Bottomspace/>

       <Step number="Section 80E:"/>
       <Content paragraph="Section 80E of the Income Tax Act in India allows for a deduction of the interest paid on a loan taken for higher education. The loan must be taken for the purpose of pursuing higher education in India or abroad by the individual taxpayer themselves, their spouse, or their children, or by a student for whom the individual is the legal guardian. The deduction is available for a period of 8 consecutive financial years starting from the year in which the individual starts paying interest on the loan. No other deductions are allowed for the same interest amount."/>
       <Bottomspace/>
       <Step number="Section 80EE:"/>
       <Content paragraph="Section 80EE of the Income Tax Act in India allows for an additional deduction of up to 50,000 rupees for the interest paid on a loan taken for the acquisition of a residential house property. To be eligible for the deduction, the loan must have been sanctioned during the financial year 2016-2017 and the loan amount must not exceed 35 lakhs rupees. The value of the house must not exceed 50 lakhs rupees, and the individual taxpayer should not own any other residential house on the date of the loan sanction. The individual should first claim the deduction under the head 'Income from House Property' and the remaining amount of interest can be claimed under this section."/> 
       <Bottomspace/>

       <Step number="Section 80EEA:"/>
       <Content paragraph="The 80EEA deduction is a tax deduction available to individuals who have taken a loan from a financial institution (FI) to acquire a house property. The deduction is for the interest paid on the loan and is limited to Rs. 1,50,000. This deduction is in addition to the general deduction of Rs. 2 lakhs available under Section 24 for self-occupied properties. To qualify for this deduction, the loan must have been sanctioned by an FI between April 1, 2019 and March 31, 2022, the stamp duty value of the house must be less than or equal to Rs. 45 lakhs, the individual cannot own any other residential house on the date of loan sanction, and the individual cannot claim the deduction under Section 80EE."/> 
       <Bottomspace/>
       <Step number="Section 80EEB:"/>
       <Content paragraph="The 80EEB deduction is a tax deduction available to individuals who have taken a loan from a financial institution (FI) to purchase an electric vehicle. The deduction is for the interest paid on the loan and is limited to Rs. 1,50,000. To qualify for this deduction, the loan must have been sanctioned by an FI between April 1, 2019 and March 31, 2023 for the purchase of electric vehicles."/> 
       <Bottomspace/>
       <Step number="Section 80U:"/>
       <Content paragraph="The 80U deduction is a tax deduction available to resident individuals who are certified by a medical authority as having a disability. The deduction is a flat amount and is available to those with a disability, a flat deduction of Rs. 75,000 is allowed, and for those with severe disability (80% or more) the flat deduction of Rs. 1,25,000 is allowed. This deduction is available to the person with disability, it can be claimed while filing the income tax return."/> 
       <Bottomspace/>
       <Step number="Section 80TTA:"/>
       <Content paragraph="The 80TTA deduction is a tax deduction available to individuals or Hindu Undivided Families (HUFs) for interest earned on deposits in savings accounts with a bank, a co-operative society, or a post office. The deduction is limited to the actual interest earned, subject to a maximum of Rs. 10,000. This deduction is not available to resident senior citizens, who are eligible for a higher limit of Rs. 50,000 under Section 80TTB. This deduction can be claimed while filing an income tax return."/>
        <Bottomspace/>
       <Step number="Section 80TTB:"/>
       <Content paragraph="The 80TTB deduction is a tax deduction available to resident senior citizens (individuals who are 60 years of age or older at any time during the previous year) for interest earned on deposits (both fixed deposits and savings accounts) with a banking company, a co-operative society engaged in the business of banking, or a post office. The deduction is limited to the actual interest earned, or Rs. 50,000, whichever is less. This deduction is in addition to the general deduction of Rs. 10,000 available to other individuals and HUFs under Section 80TTA. This deduction can be claimed while filing an income tax return."/>
       <Bottomspace/>
       <Step number="Section 80G:"/>
       <Content paragraph="Section 80G of the Income Tax Act in India allows for a tax deduction for donations made to certain funds, charitable institutions, and charitable trusts. There are four categories of deductions available under this section:"/>

       <List points="100% deduction of the amount donated, without any qualifying limit:"/>
       <Content paragraph=" This applies to donations made to certain specified funds such as the Prime Minister’s National Relief Fund, National Children’s Fund, etc."/> 
       
       <List points="50% deduction of the amount donated, without any qualifying limit:"/>
       <Content paragraph=" This applies to donations made to certain specified funds such as the Prime Minister’s Drought Relief Fund, Jawaharlal Nehru Memorial Fund, Indira Gandhi Memorial Trust, etc."/>

       <List points="100% deduction of the amount donated, subject to a qualifying limit:"/>
       <Content paragraph="This applies to donations made to a government or local authority, or an institution for the promotion of family planning, etc."/> 

       <List points="50% deduction of the amount donated, subject to a qualifying limit:"/>
       <Content paragraph=" This applies to donations made to a government or any local authority to be used for charitable purposes, other than promotion of family planning, etc."/> 
        <Bottomspace/>
       <Step number="Calculation of Qualifying limit for Category III & IV donations"/>
       <Content paragraph="The qualifying limit for category III and IV donations under Section 80G of the Income Tax Act in India is calculated as follows:"/>
      <ul className='list'>
        <li className='bullet'>
          <span>
       <List points="Compute the adjusted total income, which is the gross total income as reduced by the following:"/>
       </span>
       </li>
       <li className='bullet'>
          <span>
       <List points="Deductions under Chapter VI-A, except under Section 80G"/>
       </span>
       </li>
       <li className='bullet'>
          <span>
       <List points="Short-term capital gains"/>
       </span>
       </li>
       <li className='bullet'>
          <span>
       <List points="Long-term capital gains"/>
       </span>
       </li>
       <li className='bullet'>
          <span>
       <List points="Calculate 10% of the adjusted total income."/>
       </span>
       </li>
       <li className='bullet'>
          <span>
       <List points="Calculate the actual donation, which is subject to the qualifying limit."/>
       </span>
       </li>
       <li className='bullet'>
          <span>
       <List points="The lower of step 2 or step 3 is the maximum permissible deduction."/>
       </span>
       </li>
       <li className='bullet'>
          <span>
       <List points="The said deduction is adjusted first against donations qualifying for 100% deduction . Thereafter, 50% of the balance qualifies for a deduction under Section 80G."/>
       </span>
       </li>
       </ul>
       
       
      <Step number="Section 80GG – Income Tax Deduction on House Rent Paid"/>
      <ul className='list'>
        <li className='bullet'>
          <span>
      <List points="This deduction is available for rent paid when HRA is not received and the taxpayer, spouse or minor child should not own residential house at the place of employment."/>
      </span>
      </li>
      <li className='bullet'>
          <span>
      <List points=" The taxpayer should not have self-occupied residential property in any other place."/>
      </span>
      </li>
      <li className='bullet'>
          <span>
      <List points=" The taxpayer must be living on rent and paying rent."/>
      </span>
      </li>
      <li className='bullet'>
          <span>
      <List points="It is applicable/available only for individual asessee’s."/>
      </span>
      </li>
      </ul>

      <Step number="Deduction available is the least of the following:"/>
      <ul className='list'>
        <li className='bullet'>
          <span>
      <List points="Rent paid minus 10% of adjusted total income"/>
      </span>
      </li>
      <li className='bullet'>
          <span>
      <List points="Rs 5,000/- per month"/>
      </span>
      </li>
      <li className='bullet'>
          <span>
      <List points="25% of adjusted total income*"/>
      </span>
      </li>
      </ul>
      <br/>
      <Content paragraph="*Adjusted Gross Total Income is calculated by adjusting  the Gross Total Income for certain deductions, exempt income, long-term capital gains."/>
      <br/> <br/>

      <Step number="Section 80GGB – Company Donation to Political Parties"/>
      <Content paragraph="This is allowed to an Indian company for the amount contributed by it to any political party or an electoral trust. A deduction is allowed for contributions done in any way other than cash."/>
      <Bottomspace/>
      <Step number="Section 80GGC – Deduction on Donations By a Person to Political Parties"/>
      <Content paragraph="This is allowed to an individual taxpayer for any amount contributed to a political party or an electoral trust. It is not available for companies, local authorities and an artificial juridical person. This deduction can be availed only if paid in any way other than cash. "/>
      <Bottomspace/>
      <Step number="Section 80RRB – Deduction on Income via Royalty of a Patent"/>
      <Content paragraph="This is for  any income by way of royalty for a patent, registered on or after 1 April 2003 under the Patents Act 1970, shall be available for up to Rs.3 lakh or the income received, whichever is less. "/> 
      <Content paragraph="The taxpayer must be an individual and an Indian resident. "/> 
       <Bottomspace/>
       <Faq/>
       <Bottomspace/>
       <Bottomspace/>
       <Bottomspace/>
       
          </Grid>
        </Grid>
      </Container>
    </div>
    </div>
  )
}

export default Taxableincome