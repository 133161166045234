import React from 'react';
import { Typography,styled, useTheme,Box } from '@mui/material';

const Steps = styled(Typography)(({ theme }) => ({
    color:'#815102',
    fontSize:'1rem',
    fontWeight:600,
    lineHeight:'2.5rem',
    marginTop:'0.5rem',
    [theme.breakpoints.down("sm")]: {
      marginLeft:'0.8rem',
      lineHeight:'1.8rem',
      fontSize:'0.9rem'
         },

         [theme.breakpoints.between("md", "lg")]: {
          fontSize:'0.9rem',
          lineHeight:'1.8rem'
        },

        [theme.breakpoints.up("xl")]: {
          fontSize:'1.1rem'
        }
}));

function number(props) {
  return (
    <div>
        <Steps>
            {props.number}
        </Steps>
    </div>
  )
}

export default number