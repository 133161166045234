import React from 'react';
import Mainhead from '../../../../Components/Services/MainContent/Mainhead';
import Content from '../../../../Components/Services/MainContent/Content';
import List from '../../../../Components/Services/MainContent/List';
import Step from '../../../../Components/Services/MainContent/Step';

function Register() {
  return (
    <div>
      <Mainhead heading="Sole Proprietorship registration process :"/>

      <Step number="Step - 1: Acquire Aadhar Card"/>
      <Content paragraph="Aadhar card is the number one Identity proof that every Indian Citizen must have. It is a basic document required for legalization of any business in India. Any IT return can only be filed if the taxpayer has his/her PAN card synched. To get an Aadhar card, applicant can contact the nearest Aadhar, Seva Kendra or E-Mitra . It takes utpo 90 days for Aadhar card generation after enrollment."/>

      <Step number="Step -2 : Get PAN Card"/>
      <Content paragraph="Since you cannot file an IT return without the presence of a PAN, it is important that you apply for one. This can be done through a dedicated government portal that requires a nominal fee to register a person for PAN"/>
      <ul className="list">
        <li className="Bullet">
          <span>
      <List points="Applicant Photograph"/>
      </span>
      </li>
      <li className="Bullet">
          <span>
      <List points="ID proof"/>
      </span>
      </li>
      <li className="Bullet">
          <span>
      <List points="Address Proof"/>
      </span>
      </li>
      </ul>
      
      <Step number="Step -3 : Apply for a Bank Account in the name of Business"/>
      <Content paragraph="After acquiring the PAN and Aadhar Number, contact any bank to open a current account. Basic documents required for that are:"/>
      <ul className="list">
        <li className="bullet">
          <span>
      <List points="PAN"/>
      </span>
      </li>
      <li className="bullet">
          <span>
      <List points="Aadhar Number"/>
      </span>
      </li>
      <li className="bullet">
          <span>
      <List points="GST registration"/>
      </span>
      </li>
      <li className="bullet">
          <span>
      <List points="Address and identification proof"/>
      </span>
      </li>
      </ul>

      <Step number="Step -4: Secure Shop and Establishment Act License."/>
      <Content paragraph="A shop and Establishment license for any commercial establishment can be stated as a legal compulsion. A commercial establishments provides goods or services to the customer. It is a legally required for the owner of any small establishment or a shop to secure a license."/>

      <Step number="Step -5: Register under GST"/>
      <Content paragraph="Registering GST is a requirement for all form of businesses whose annual turnover is above the prescribed threshold limit. Currently, the businesses whose yearly turnover is greater than Rs 40 lakh are mandated to secure this registration. This is also important for online business owners."/>
  
    </div>
  )
}

export default Register