import Header from "../../Components/Header";
import Footer from '../../Components/Footer';
import Homepage from "../Home/Homepage";
import {Outlet , useNavigate} from 'react-router-dom';


const Navigation = () => {
    const navigate = useNavigate();

    return (
        <>
            <Header />
            <Outlet />
            <Footer />
            
        </>
    )
}

export default Navigation;