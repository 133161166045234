import React from 'react';
import Mainhead from '../../../../Components/Services/MainContent/Mainhead';
import Step from '../../../../Components/Services/MainContent/Step';
import List from '../../../../Components/Services/MainContent/List';
import Content from '../../../../Components/Services/MainContent/Content'

function Register() {
  return (
    <div>

      <Mainhead heading="How to Register a Producer Company"/>

      <Step number="Step - 1: Obtain a Digital Signature Certificate (DSC) from all the directors. The documents required for that are :"/>
      <ul className='list'>
       <li className='bullet'>
        <span>
      <List points="Copy of PAN Card (must for all resident copy of the latest bank statement/telephone or mobile bill/electricity or gas bill shareholders / directors ) or Passport (Foreign Nationals & NRIs)"/>
      </span>
      </li>
      <li className='bullet'>
        <span>
      <List points="Copy of Voter’s ID/Passport/Driver’s License/Aadhar card"/>
      </span>
      </li>
      <li className='bullet'>
        <span>
      <List points="Copy of the latest bank statement/telephone or mobile bill/electricity or gas bill(i.e not older than 2 months and payment proof of same.)"/>
      </span>
      </li>
      <li className='bullet'>
        <span>
      <List points="Email ID"/>
      </span>
      </li>
      <li className='bullet'>
        <span>
      <List points="Contact Number"/>
      </span>
      </li>
      </ul>
      <Step number="Step -2 : Obtaining DIN."/>
      <Content paragraph="Next obtain the Director Identification Number (DIN) by filing the form DIR – 3 along with a self-attested Identity proof, address proof, and a photo."/>

      <Step number="Step -3 : Apply for name availability of the proposed company."/>
      <Content paragraph="When all that is done then the name of the production company can be finalized. For that, the form to be filled is the Form INC – 1 to the Registrar of Companies (ROC), by giving 6 names ordered by preference along with their significance. However, it should have the words PRODUCER COMPANY at the end."/>

      <Step number="Step -4: After the approval of the name by the ROC, the following documents are to be prepared :"/>
      <ul className='list'>
      <li className='bullet'>
        <span>
      <List points="The Memorandum of Association incorporating the objective that the company intends to follow."/>
      </span>
      </li>
      <li className='bullet'>
        <span>
      <List points="The Articles of Association containing all the by-laws of the company"/>
      </span>
      </li>
      <li className='bullet'>
        <span>
      <List points="A declaration by a professional has to be drafted in the format of form INC – 8."/>
      </span>
      </li>
      <li className='bullet'>
        <span>
      <List points="An affidavit has to be signed by all the subscribers of the proposed company declaring their legal competency to act as the subscribers."/>
      </span>
      </li>
      <li className='bullet'>
        <span>
      <List points="A utility bill and a NOC have to be taken from the owner whose address is to be used as the registered office of the company. If it is not owned, a lease agreement will be attached to the form."/>
      </span>
      </li>
      <li className='bullet'>
        <span>
      <List points="The directors will give their consent to act in Form DIR – 2 and details in DIR – 8."/>
      </span>
      </li>
      </ul>
      <Content paragraph="All the drafted documents will be attached to Form INC – 7, INC – 22 and DIR – 12 and uploaded to the ROC website. After proper verification, the ROC will issue a Certificate of Incorporation and the company can commence its business operations"/>

    </div>
  )
}

export default Register