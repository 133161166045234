import React from 'react';
import {
    Grid,
    Container,
    Typography,
    styled,
    Card,
    CardMedia,
    CardContent,
    CardActions,
    Button,
    Box
} from '@mui/material';
import Slider from "react-slick";
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'
import EastIcon from '@mui/icons-material/East';
import Heading from "../../../Components/CommonCss/Home/Heading"
import DivGap from "../../../Components/CommonCss/Home/DivGap"
import Content from "../../../Components/Services/MainContent/Content";
import Backimage from "../../../Assets/Home/Services/LegalFactory_Home4.png";
import Private from "../../../Assets/Home/Services/1.jpg";
import Limited from "../../../Assets/Home/Services/2.jpg";
import GST from "../../../Assets/Home/Services/GST.jpg";
import Shopact from "../../../Assets/Home/Services/3.jpg";
import Soleproprietorship from "../../../Assets/Home/Services/4.jpg"
import Nidhi from "../../../Assets/Home/Services/5.jpg";
import Producer from "../../../Assets/Home/Services/6.jpg";
import Partnership from "../../../Assets/Home/Services/7.jpg";
import Startup from "../../../Assets/Home/Services/8.jpg";


import { useNavigate } from 'react-router-dom';




const Services = () => {
    {/*Head part what we offer*/ }

    const navigate = useNavigate();
    const clickSole = () => {
        navigate('/soleproprietorship');
    }
    const clickPrivate = () => {
        navigate('/private');
    }
    const clickPartnership = () => {
        navigate('/partnershipfirm');
    }

    const clickStartup = () => {
        navigate('/startupregistration');
    }
    const clickLimited = () => {
        navigate('/limitedliability');
    }
    const clickNidhi = () => {
        navigate('/nidhicompany');
    }
    const clickShop = () => {
        navigate('/shopact');
    }
    const clickProducer = () => {
        navigate('/producercompany');
    }

    


    {/*Content Part */ }
    const Body = styled(Typography)(({ theme }) => ({
        color: '#000000',
        fontSize: '1rem',
        fontWeight: 450,
        lineHeight: '1.8rem',
        marginTop: '0.5rem',
        [theme.breakpoints.down("sm")]: {
            marginLeft: '0.8rem',
            lineHeight: '1.6rem',
            fontSize: '0.85rem'
        },

        [theme.breakpoints.between("md", "lg")]: {
            fontSize: '0.75rem',
            lineHeight: '1.8rem'
        },

        
    }));


    {/*Box Size */ }
    const Services = styled(Card)(({ theme }) => ({
        background: 'FAFCFF',
        //border: '1.5px solid rgba(3, 58, 109, 0.29)',
        boxShadow: 'rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px',
        borderRadius: '12px',
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        "&:hover": {
  transform: 'scale(1.05)', 
        },
       
        [theme.breakpoints.up("lg")]: {
            maxWidth: "85%"
        },
        
        [theme.breakpoints.down("sm")]: {
            maxWidth: "50%",
           // marginLeft:'40px',
            
        },
        [theme.breakpoints.down("md")]: {
            maxWidth: "75%",
            
            
        },
    }));

    const Image = styled(CardMedia)(({ theme }) => ({
        height: "60px",
        width: "60px",
        marginTop: "10px",
        marginLeft: "5px",
        objectFit: 'contain',
        [theme.breakpoints.up("md")]: {
            marginLeft: "10px"
        }
    }));


    {/*Service Heading */ }
    const Servicehead = styled(Typography)(({ theme }) => ({
        fontWeight: 700,
        fontSize: '0.985rem',
        paddingTop: '0.625rem',
        textAlign: 'left',
        marginLeft: '1.5rem',
        fontStyle: 'normal',
        [theme.breakpoints.up("lg")]: {
            fontSize: '1.25rem',
            lineHeight: '1.875rem'
        },
       
        [theme.breakpoints.between("md", "lg")]: {
            fontSize: '0.985rem',
            lineHeight: '1.5rem',

        },
        [theme.breakpoints.down("md")]: {
            fontSize: '1rem',
            lineHeight: '1.6rem'
        },

    }));


    const Display = styled(Box)(({ theme }) => ({
        display: "flex",
        flexFlow: "row ",


    }));
    {/*Service content */ }
    const Content = styled(CardContent)(({ theme }) => ({
        color: '#281900',
        fontFamily:'Sora',
        fontWeight: 500,
        fontStyle: 'normal',
        textAlign: 'left',
        [theme.breakpoints.up("md")]: {
            fontSize: '1.2rem',
            lineHeight: '1.5rem',
        },
        [theme.breakpoints.up("lg")]: {
            fontSize: '1rem',
            lineHeight: '1.5rem',
        },
        
        [theme.breakpoints.between("md", "lg")]: {
            fontSize: '0.85rem',
            lineHeight: '1.5rem',

        },
        [theme.breakpoints.down("sm")]: {
            fontSize: '0.8rem',
            lineHeight: '1.5rem',

        },
        [theme.breakpoints.down("md")]: {
            fontSize: '0.85rem',
            lineHeight: '1.4rem',

        },

    }));

    const Action = styled(Button)(({ theme }) => ({
        fontWeight: '500',
        fontSize: '0.9rem',
        textTransform: 'capitalize',
        [theme.breakpoints.up("md")]: {
            fontSize: '1.3rem',
        },
        [theme.breakpoints.down("sm")]: {
            fontSize: '0.985rem',
        },
        [theme.breakpoints.between("md", "lg")]: {
            fontSize: '0.985rem',
        },
        

    }));


    const Margin = styled(Box)(({ theme }) => ({
        paddingLeft: '80px',
        paddingRight:'80px',
        marginBottom:'50px',
        marginLeft:'20px',

        [theme.breakpoints.down("md")]: {
            marginLeft: '5px'
        },
    }));

    const Background = styled(Box)(({ theme }) => ({
        //backgroundImage: `url(${Backimage})`,
        width: '100%',
        height:'100%',
        backgroundColor:'rgb(242, 242, 242)',
        //backgroundRepeat: 'no-repeat',
       // backgroundSize: '100% 160%',
        //backgroundPosition: 'left',
       
    }));

    const settings = {
        dots: true,
        infinite: true,
        className: "center",
        initialSlide: 0,
        autoplay: true,
        slidesToShow: 2,
        slidesToScroll: 2,
        speed: 4000,
        autoplaySpeed: 4000,
        arrows: false,
        adaptiveHeight:false,
        
        responsive: [
    
          {
            breakpoint: 600,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1,
            }
          }
        ]
      };



    return (
        <>
        <Background component='div'>
          <br/> 
        <div className='Container'> 
        <Box  sx={{ display: { xs: 'none', md: 'block' } }}>
           
            <Container>
                <Heading mainhead = "What We Offer ?"/>
                <Grid container>
                    <Grid item xs={12}>
                        <Body>Partner with Legal Factory for all your company registration and income tax filing needs. Experience the peace of mind that comes with having dedicated experts by your side, committed to ensuring your financial success and compliance with the ever-evolving tax landscape.</Body>
                    </Grid>
                </Grid>
                <br /><br />
                
                  <Margin>
                        <Grid container spacing={1}
                           
                            display='flex'
                            direction={{ xs: 'column', sm: 'row' }}
                            justifyContent={{ xs: 'center', sm: 'center', lg: 'center' }}
                            alignContent={{ xs: 'center', sm: 'center' }}

                        >

                            <Grid item xs={12} sm={6} md={4} lg={4} >
                                <Services>
                                    <Display>
                                        <Image
                                            image={Private} />
                                        <Servicehead color="#0085FF">Private Limited Company</Servicehead>
                                    </Display>
                                    <Content>Take your business to another level by registering your company as private.</Content>
                                    <CardActions>
                                        <Action sx={{ color: "#0085FF" }} onClick={clickPrivate}
                                        endIcon={<EastIcon style={{ color: '#0085FF', fontSize: '30px' }} />}>Learn More</Action>
                                    </CardActions>
                                </Services>
                            </Grid>
                            <Grid item xs={12} sm={6} md={4} lg={4} >
                                <Services>
                                    <Display>
                                        <Image
                                            image={Limited} />
                                        <Servicehead color="#2F1EF1">Limited Liability Partnership</Servicehead>
                                    </Display>
                                    <Content>Make your business run smoothly, limited liabilities to all the partners.</Content>
                                    <CardActions>
                                        <Action sx={{ color: "#2F1EF1" }} onClick={clickLimited}
                                        endIcon={<EastIcon style={{ color: '#2F1EF1', fontSize: '30px' }} />}
                                        >Learn More</Action>
                                    </CardActions>
                                </Services>
                            </Grid>
                            {/* <Grid item xs={12} sm={6} md={4} lg={4}  >
                            <Services>
                                <Display>
                                    <Image
                                        image={GST} />
                                    <Servicehead color="#AECA00">GST Registration</Servicehead>
                                </Display>
                                <Content>GST registration,filings and returns made easy.</Content>
                                <CardActions>
                                    <Action sx={{ color: "#AECA00" }}>Learn More</Action>
                                </CardActions>
                            </Services>
                        </Grid> */}
                            <Grid item xs={12} sm={6} md={4} lg={4}>
                                <Services>
                                    <Display>
                                        <Image
                                            image={Shopact} />
                                        <Servicehead color="#0085FF">Shop Act Establishment</Servicehead>
                                    </Display>
                                    <Content>Get your Shop & Establishment Act now under our Legal guidance.</Content>
                                    <CardActions>
                                        <Action sx={{ color: "#0085FF" }} onClick={clickShop}
                                        endIcon={<EastIcon style={{ color: '#0085FF', fontSize: '30px' }} />}>Learn More</Action>
                                    </CardActions>
                                </Services>
                            </Grid>
                            </Grid>
                            </Margin>
                            <Margin>
                        <Grid container spacing={1}
                           
                            display='flex'
                            direction={{ xs: 'column', sm: 'row' }}
                            justifyContent={{ xs: 'center', sm: 'center', lg: 'center' }}
                            alignContent={{ xs: 'center', sm: 'center' }}

                        >

                            <Grid item xs={12} sm={6} md={4} lg={4} >
                                <Services>
                                    <Display>
                                        <Image
                                            image={Producer} />
                                        <Servicehead color="#0085FF">Producer Company</Servicehead>
                                    </Display>
                                    <Content>Produce the best quality products and register your company as a Producer with a few steps.</Content>
                                    <CardActions>
                                        <Action sx={{ color: "#0085FF" }} onClick={clickProducer}
                                        endIcon={<EastIcon style={{ color: '#0085FF', fontSize: '30px' }} />}>Learn More</Action>
                                    </CardActions>
                                </Services>
                            </Grid>
                            <Grid item xs={12} sm={6} md={4} lg={4} >
                                <Services>
                                    <Display>
                                        <Image
                                            image={Partnership} />
                                        <Servicehead color="#2F1EF1">Partnership Firm</Servicehead>
                                    </Display>
                                    <Content>Forget your worries when you have partners to rely on, register as a Partnership Firm and rest easy.</Content>
                                    <CardActions>
                                        <Action sx={{ color: "#2F1EF1" }} onClick={clickPartnership}
                                        endIcon={<EastIcon style={{ color: '#2F1EF1', fontSize: '30px' }} />}>Learn More</Action>
                                    </CardActions>
                                </Services>
                            </Grid>
                            <Grid item xs={12} sm={6} md={4} lg={4} >
                                <Services>
                                    <Display>
                                        <Image
                                            image={Startup} />
                                        <Servicehead color="#0085FF">Startup India Registration</Servicehead>
                                    </Display>
                                    <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}>
                                        <Content>Register your startup easily and effortlessly.</Content>
                                        <CardActions>
                                            <Action sx={{ color: "#0085FF" }} onClick={clickStartup}
                                            endIcon={<EastIcon style={{ color: '#0085FF', fontSize: '30px' }} />}>Learn More</Action>
                                        </CardActions>
                                    </Box>
                                </Services>
                            </Grid>
                            </Grid>
                            </Margin>
                            <Margin>
                        <Grid container spacing={1}
                           
                            display='flex'
                            direction={{ xs: 'column', sm: 'row' }}
                            justifyContent={{ xs: 'center', sm: 'center', lg: 'center' }}
                            alignContent={{ xs: 'center', sm: 'center' }}

                        >
                            <Grid item xs={12} sm={6} md={4} lg={4} >
                                <Services>
                                    <Display>
                                        <Image
                                            image={Nidhi} />
                                        <Servicehead color="#0085FF">Nidhi Company</Servicehead>
                                    </Display>
                                    <Content>Save and Secure your business through savings and reserve funds.</Content>
                                    <CardActions>
                                        <Action sx={{ color: "#0085FF" }} onClick={clickNidhi}
                                        endIcon={<EastIcon style={{ color: '#0085FF', fontSize: '30px' }} />}>Learn More</Action>
                                    </CardActions>
                                </Services>
                            </Grid>
                            <Grid item xs={12} sm={6} md={4} lg={4} >
                                <Services>
                                    <Display>
                                        <Image
                                            image={Soleproprietorship} />
                                        <Servicehead color="#2F1EF1">Sole Proprietorship</Servicehead>
                                    </Display>
                                    <Content>Become your own Boss, take control of your company and expand your business.</Content>
                                    <CardActions>
                                        <Action sx={{ color: "#2F1EF1" }} onClick={clickSole}
                                        endIcon={<EastIcon style={{ color: '#2F1EF1"', fontSize: '30px' }} />}>Learn More</Action>
                                    </CardActions>
                                </Services>
                            </Grid>

                    </Grid>
                    </Margin>
                   
            

            </Container>
            </Box>
            <Box  sx={{ display: { xs: 'block', md: 'none' } }}>
            
            <Container>
                <Heading mainhead = "What We Offer ?"/>
                <Grid container>
                    <Grid item xs={12}>
                        <Body>Partner with Legal Factory for all your company registration and income tax filing needs. Experience the peace of mind that comes with having dedicated experts by your side, committed to ensuring your financial success and compliance with the ever-evolving tax landscape.</Body>
                    </Grid>
                </Grid>
                <br /><br />
                <Slider {...settings}>
                    
                <div className='slide'>
                                <Services>
                                    <Display>
                                        <Image
                                            image={Private} />
                                        <Servicehead color="#0085FF">Private Limited Company</Servicehead>
                                    </Display>
                                    <Content>Take your business to another level by registering your company as private.</Content>
                                    <CardActions>
                                        <Action sx={{ color: "#0085FF" }} onClick={clickPrivate}
                                        endIcon={<EastIcon style={{ color: '#0085FF', fontSize: '30px' }} />}>Learn More</Action>
                                    </CardActions>
                                </Services>
                                
                            </div>
                            <div className='slide'>
                                <Services>
                                    <Display>
                                        <Image
                                            image={Limited} />
                                        <Servicehead color="#2F1EF1">Limited Liability Partnership</Servicehead>
                                    </Display>
                                    <Content>Make your business run smoothly, limited liabilities to all the partners.</Content>
                                    <CardActions>
                                        <Action sx={{ color: "#2F1EF1" }} onClick={clickLimited}
                                        endIcon={<EastIcon style={{ color: '#2F1EF1', fontSize: '30px' }} />}
                                        >Learn More</Action>
                                    </CardActions>
                                </Services>
                           
                            </div>
                            {/* <Grid item xs={12} sm={6} md={4} lg={4}  >
                            <Services>
                                <Display>
                                    <Image
                                        image={GST} />
                                    <Servicehead color="#AECA00">GST Registration</Servicehead>
                                </Display>
                                <Content>GST registration,filings and returns made easy.</Content>
                                <CardActions>
                                    <Action sx={{ color: "#AECA00" }}>Learn More</Action>
                                </CardActions>
                            </Services>
                        </Grid> */}
                        <div className='slide'>
                            
                                <Services>
                                    <Display>
                                        <Image
                                            image={Shopact} />
                                        <Servicehead color="#0085FF">Shop Act Establishment</Servicehead>
                                    </Display>
                                    <Content>Get your Shop & Establishment Act now under our Legal guidance.</Content>
                                    <CardActions>
                                        <Action sx={{ color: "#0085FF" }} onClick={clickShop}
                                        endIcon={<EastIcon style={{ color: '#0085FF', fontSize: '30px' }} />}>Learn More</Action>
                                    </CardActions>
                                </Services>
                            
                            </div>
                              <div>
                            
                                <Services>
                                    <Display>
                                        <Image
                                            image={Producer} />
                                        <Servicehead color="#0085FF">Producer Company</Servicehead>
                                    </Display>
                                    <Content>Produce the best quality products and register your company as a Producer with a few steps.</Content>
                                    <CardActions>
                                        <Action sx={{ color: "#0085FF" }} onClick={clickProducer}
                                        endIcon={<EastIcon style={{ color: '#0085FF', fontSize: '30px' }} />}>Learn More</Action>
                                    </CardActions>
                                </Services>
                            
                            </div>
                            <div className='slide'>
                            
                                <Services>
                                    <Display>
                                        <Image
                                            image={Partnership} />
                                        <Servicehead color="#2F1EF1">Partnership Firm</Servicehead>
                                    </Display>
                                    <Content>Forget your worries when you have partners to rely on, register as a Partnership Firm and rest easy.</Content>
                                    <CardActions>
                                        <Action sx={{ color: "#2F1EF1" }} onClick={clickPartnership}
                                        endIcon={<EastIcon style={{ color: '#2F1EF1', fontSize: '30px' }} />}>Learn More</Action>
                                    </CardActions>
                                </Services>
                           
                            </div>
                            <div className='slide'>
                            
                                <Services>
                                    <Display>
                                        <Image
                                            image={Startup} />
                                        <Servicehead color="#0085FF">Startup India Registration</Servicehead>
                                    </Display>
                                    <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}>
                                        <Content>Register your startup easily and effortlessly.</Content>
                                        <CardActions>
                                            <Action sx={{ color: "#0085FF" }} onClick={clickStartup}
                                            endIcon={<EastIcon style={{ color: '#0085FF', fontSize: '30px' }} />}>Learn More</Action>
                                        </CardActions>
                                    </Box>
                                </Services>
                            
                           </div>
                           <div className='slide'>
                            
                                <Services>
                                    <Display>
                                        <Image
                                            image={Nidhi} />
                                        <Servicehead color="#0085FF">Nidhi Company</Servicehead>
                                    </Display>
                                    <Content>Save and Secure your business through savings and reserve funds.</Content>
                                    <CardActions>
                                        <Action sx={{ color: "#0085FF" }} onClick={clickNidhi}
                                        endIcon={<EastIcon style={{ color: '#0085FF', fontSize: '30px' }} />}>Learn More</Action>
                                    </CardActions>
                                </Services>
                            
                            </div>
                            <div className='slide'>
                            
                                <Services>
                                    <Display>
                                        <Image
                                            image={Soleproprietorship} />
                                        <Servicehead color="#2F1EF1">Sole Proprietorship</Servicehead>
                                    </Display>
                                    <Content>Become your own Boss, take control of your company and expand your business.</Content>
                                    <CardActions>
                                        <Action sx={{ color: "#2F1EF1" }} onClick={clickSole}
                                        endIcon={<EastIcon style={{ color: '#2F1EF1"', fontSize: '30px' }} />}>Learn More</Action>
                                    </CardActions>
                                </Services>
                            
                            </div>
                           
                            

        </Slider>           
            

            </Container>
        


            </Box>
            




        </div>
        
       <DivGap spacing=""/>
       <br/>
        </Background>
        
        </>
    )
}

export default Services
