import React,{ useRef, useEffect, useState } from 'react';
import {
    Box,
    Typography,
    Container,
    styled,
    Divider,
    Grid,
    Button,
    useTheme,
  useMediaQuery
} from '@mui/material';
import "../Common.css"
import Topbox from '../../../Components/Services/Topbox';
import GetInTouch from '../../../Components/Services/GetInTouch';
import Overview from './Overview/Overview';
import Advantages from './Advantages/Advantages';
import Checklist from './Checklist/Checklist';
import Register from './Register/Register';
import Document from './DocumentRequired/Document';
import Legalfactory from './WhyLegalFactory/Legalfactory';
import Faq from './FAQ/Faq';


const getDimensions = ele => {
  const { height } = ele.getBoundingClientRect();
  const offsetTop = ele.offsetTop;
  const offsetBottom = offsetTop + height;

  return {
    height,
    offsetTop,
    offsetBottom,
  };
};

const scrollTo = ele => {
  ele.scrollIntoView({
    behavior: "smooth",
    block: "start",
  });
};

const SoleProprietorship = () => {

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const isTablet = useMediaQuery(theme.breakpoints.down("md"));
  const [visibleSection, setVisibleSection] = useState();
  const headerRef = useRef(null);
    const overviewRef = useRef(null);
    const advantagesRef = useRef(null);
    const checklistRef = useRef(null);
    const registerRef = useRef(null);
    const documentRef = useRef(null);
    const legalRef = useRef(null);
    const faqRef = useRef(null);
  
    const sectionRefs = [
      { section: "Overview", ref: overviewRef },
      { section: "Advantages", ref: advantagesRef },
      { section: "Checklist", ref: checklistRef },
      { section: "Register", ref: registerRef },
      { section: "Document", ref: documentRef },
      { section: "Legal", ref: legalRef },
      { section: "Faq", ref: faqRef },
    ];
  
    useEffect(() => {
      const handleScroll = () => {
        const { height: headerHeight } = getDimensions(headerRef.current);
        const scrollPosition = window.scrollY + headerHeight;
  
        const selected = sectionRefs.find(({ section, ref }) => {
          const ele = ref.current;
          if (ele) {
            const { offsetBottom, offsetTop } = getDimensions(ele);
            return scrollPosition > offsetTop && scrollPosition < offsetBottom;
          }
        });
  
        if (selected && selected.section !== visibleSection) {
          setVisibleSection(selected.section);
        }
         
         else if (!selected && visibleSection) {
          setVisibleSection(undefined);
        }
      };
  
      handleScroll();
      window.addEventListener("scroll", handleScroll);
      return () => {
        window.removeEventListener("scroll", handleScroll);
      };
    }, [visibleSection]);
    
    
    const Topspacer  = styled(Box)(({ theme }) => ({
      height:'6rem',
      [theme.breakpoints.down("sm")]: {
        height:'1.5rem',
      }
          
  }));

  const Content  = styled(Box)(({ theme }) => ({
    
        
}));
  
  
  const Sticky  = styled(Box)(({ theme }) => ({
      position:'sticky',
      top:90,
      left:0,
     }));
  
  const PLheader  = styled(Box)(({ theme }) => ({
      display:'flex',
      flexDirection:'column',
      justifyContent:'flex-start',
  
  }));
  
  const Bottom_spacer  = styled(Box)(({ theme }) => ({
      height:'8rem',
  }));
  

  const Section  = styled(Box)(({ theme }) => ({
      height:'auto'
    }));

return (
        <div>
         <Topbox heading="Sole Proprietorship" service="Sole Proprietorship"
         document={[<a href='#Document' style={{color:'#F19E1E'}} onClick={() => {
          scrollTo(documentRef.current);
        }}>LearnMore</a>]}
        description="Embark on your entrepreneurial journey with confidence. Legal Factory provides smooth and reliable Sole Proprietorship Registration services, laying the groundwork for your business success."

        rating="4.3"

        clients="From 70 customers"

        priceinfo="Start your Sole Proprietorship Company Registration Journey At"/>
        <div className='Container'>
          <Container>
          
          <div className="App">
      <Topspacer />
      
     <Content>
        <Grid container spacing={3}>
            <Grid item lg={3} xl={3} md={3}
            sx={
              isMobile || isTablet ? { display: 'none' } : { }
            }>
              
        <Sticky>
          <PLheader ref={headerRef}>
            <Box
              className={`header_link ${visibleSection === "Overview" ? "selected" : ""}`}
              onClick={() => {
                scrollTo(overviewRef.current);
              }}
            >
              Overview
            </Box>
            <Box
              className={`header_link ${visibleSection === "Advantages" ? "selected" : ""}`}
              onClick={() => {
                scrollTo(advantagesRef.current);
              }}
            >
              Advantages
            </Box>
            <Box
              type="button"
              className={`header_link ${visibleSection === "Checklist" ? "selected" : ""}`}
              onClick={() => {
                scrollTo(checklistRef.current);
              }}
            >
              Checklist
            </Box>
            <Box
              type="button"
              className={`header_link ${visibleSection === "Register" ? "selected" : ""}`}
              onClick={() => {
                scrollTo(registerRef.current);
              }}
            >
              How To Register?
            </Box>

            <Box
              type="button"
              className={`header_link ${visibleSection === "Document" ? "selected" : ""}`}
              onClick={() => {
                scrollTo(documentRef.current);
              }}
            >
              Documents Required
            </Box>
            <Box
              type="button"
              className={`header_link ${visibleSection === "Legal" ? "selected" : ""}`}
              onClick={() => {
                scrollTo(legalRef.current);
              }}
            >
              Why Legal Factory?
            </Box>
            <Box
              type="button"
              className={`header_link ${visibleSection === "Faq" ? "selected" : ""}`}
              onClick={() => {
                scrollTo(faqRef.current);
              }}
            >
              FAQ'S
            </Box>
            </PLheader>
        </Sticky>
        
        </Grid>
        <Grid item md={9} lg={9} xl={9}
         sx={
          isMobile || isTablet ? { display: 'none' } : { }
        }>
        <Section id="Overview" ref={overviewRef}>
          <Overview/>
        </Section>
        <Topspacer/>
        <Section id="Advantages" ref={advantagesRef}>
         <Advantages/>
        </Section>
        <Topspacer/>

        <Section id="Checklist" ref={checklistRef}>
          <Checklist/>
        </Section>
        <Topspacer/>
        <Section id="Register" ref={registerRef}>
        <Register/>
        </Section>
        <Topspacer/>
        <Section id="Document" ref={documentRef}>
         <Document/>
        </Section>

        
        <Topspacer/>
        <Section id="Legal" ref={legalRef}>
          <Legalfactory/>
        </Section>
       <Topspacer/>
        <Section id="Faq" ref={faqRef}>
         <Faq/>
        </Section>
        </Grid>

       
          <GetInTouch Floatingicon="Sole Proprietorship"/>
        
        </Grid>
       </Content>
<Box sx={
          isMobile || isTablet ? {  } : {display:'none' }
        }>
          <Grid container>
          <Grid item xs={12}>
          <Overview/>
          <br/>
          <Advantages/>
          <br/>
          <Checklist/>
          <br/>
          <Register/>
          <br/>
          <Document/>
          <br/>
          <Legalfactory/>
          <br/>
          <Faq/>
       
          </Grid>
          </Grid>
        </Box>
      
      

      <Bottom_spacer/>
      
      
    </div>
           
           </Container>
        </div>
        </div>
    )
}

export default SoleProprietorship
