import React from "react";
import { Box } from "@mui/material";

import Mainhead from "../../../Components/Services/MainContent/Mainhead";
import Content from "../../../Components/Services/MainContent/Content";
import Step from "../../../Components/Services/MainContent/Step";

const TypesOfCapitalAssetsComponent = () => {
  return (
    <Box>
      <Mainhead heading="TYPES OF CAPITAL ASSETS" />
      <Content paragraph="Capital assets encompass a wide range of properties and possessions, each categorized based on specific criteria. Understanding the types of capital assets is crucial for accurate tax assessment and compliance." />

      <Mainhead heading="WHAT DO YOU MEAN BY SHORT TERM CAPITAL ASSET AS PER INCOME TAX ACT, 1961 IN INDIA?" />
      <Content paragraph="Short-term capital assets  in the context of the Income Tax Act in India, refer to assets that are held for a relatively brief period before being sold or transferred. These assets are characterized by a shorter duration of ownership, falling within a specified timeframe as determined by tax regulations." />
      <Step number="WHAT IS THE CRITERIA FOR DEFINING AN ASSET AS A SHORT TERM CAPITAL ASSET IN INDIA?" />
      <Content paragraph="The key criterion defining a short-term capital asset is its holding period, which typically spans up to 36 months for most assets. However, in the case of certain assets like immovable property such as land or building, the duration for short-term classification is limited to 24 months." />

      <Step number="WHAT ARE THE EXAMPLES OF SHORT TERM CAPITAL ASSETS IN INDIA?" />
      <Content paragraph="Examples of assets that fall under the classification of short-term capital assets include shares and securities (such as equity or preference shares) listed on recognized stock exchanges, equity-oriented mutual funds, certain debt-oriented mutual funds, immovable property like land or building held for up to 24 months, among others. These assets, when held for the specified shorter duration, qualify as short-term capital assets, and the gains realized upon their sale or transfer are deemed as STCG." />

      <Step number="WHAT IS TAXATION OF SHORT TERM CAPITAL ASSETS?" />
      <Content paragraph="The taxation of short-term capital gains is based on the applicable individual income tax slab rates for the particular financial year in which the gains are realized. This contrasts with long-term capital gains, which are subject to separate tax rates and treatment." />
      <Content paragraph="It's crucial to note that the period of holding, i.e., the time between the date of acquisition and the date of sale or transfer, is a determining factor in classifying assets as short-term or long-term. Proper documentation and accurate record-keeping of transactions involving capital assets are essential for precise calculation of gains and adherence to tax regulations." />

      <Mainhead heading="WHAT DO YOU MEAN BY LONG TERM CAPITAL ASSET AS PER INCOME TAX ACT,1961 IN INDIA?" />
      <Content paragraph="Long-term capital assets, under the purview of the Income Tax Act in India, signify assets that are held for an extended duration before being sold or transferred. These assets encompass a diverse array of possessions and properties that are retained for a significant period, meeting the stipulated criteria set by tax regulations." />

      <Step number="WHAT IS TAXATION OF LONG TERM CAPITAL ASSETS?" />
      <Content paragraph="The tax implications on long-term capital gains have undergone changes over time. Previously, LTCG from listed securities and equity-oriented mutual funds enjoyed a concessional tax rate, but as of April 1, 2018, a flat tax of 10% is applicable on LTCG exceeding Rs. 1 lakh without the benefit of indexation. However, for other long-term assets, the tax rates and available benefits may vary." />
      <Content paragraph="One significant advantage associated with certain long-term assets, particularly real estate, is the provision of indexation benefits. Indexation involves adjusting the purchase price of an asset to account for inflation, effectively reducing the taxable portion of the capital gains." />
      <Content paragraph="Furthermore, the Income Tax Act offers various exemptions and deductions aimed at reducing the tax liability on long-term capital gains. These exemptions, such as investing in specified assets like residential property under sections like 54, 54F, etc., provide avenues for taxpayers to mitigate their tax obligations within the framework of the law." />
    </Box>
  );
};

export default TypesOfCapitalAssetsComponent;
