import React from "react";
import { Box } from "@mui/material";
import Mainhead from "../../../Components/Services/MainContent/Mainhead";
import Step from "../../../Components/Services/MainContent/Step";
import Content from "../../../Components/Services/MainContent/Content";
import ReusableTable from "../../../Components/Income-Tax/ReusableTable";

const Section80TTA = () => {
  const data = [
    {
      Specifications: "Eligibility Criteria",
      Section_80TTA: "Hindu Undivided Family (HUF) and individuals",
      Section80TTB: "Senior citizens aged over 60 years",
    },
    {
      Specifications: "Applicable Instruments",
      Section_80TTA: "Savings accounts and NRO accounts",
      Section80TTB: "Savings bank accounts and fixed deposits",
    },
    {
      Specifications: "Limit of Exemption",
      Section_80TTA: "Up to ₹10,000 / year",
      Section80TTB: "Up to ₹50,000 / year",
    },
  ];
  return (
    <Box
      style={{
        display: "flex",
        flexDirection: "column",
        gap: 4,
        marginBottom: "2rem",
      }}
    >
      <Mainhead heading="Section 80TTA - Deduction for savings account interest" />

      <Step
        number={`
          INTRODUCTION
        `}
      />
      <Content
        paragraph={`
          You can claim a deduction for savings account deposits under Section 80TTA of the Income Tax Act of 1961. It allows for a Rs.10,000 deduction for savings account interest income. These accounts must be held in a cooperative society, bank, or post office to be eligible.

          Since 2013, this clause has been a part of the Finance Bill and has provided tax relief to taxpayers. To encourage citizens to save money, the Indian government has implemented tax breaks on basic savings accounts.
        `}
      />

      <Step
        number={`
          Characteristics of Section 80TTA
        `}
      />
      <Content
        paragraph={`
          The following are the main characteristics of Income Tax Act Section 80TTA:
        `}
      />
      <ul className="list">
        <li className="bullet">
          <span>
            There is a ₹10,000 annual cap on the tax exemption from interest
            income in savings accounts.
          </span>
        </li>
        <li className="bullet">
          <span>
            This deduction only applies to individual and Hindu Undivided Family
            (HUF) savings accounts.
          </span>
        </li>
        <li className="bullet">
          <span>
            A person may hold numerous savings accounts at various banks.
            However, in order to receive full exemption, the total interest
            income from all those accounts combined must be less than ₹10,000.
          </span>
        </li>
        <li className="bullet">
          <span>
            If the total interest earned from savings accounts surpasses 10,000,
            then a ₹10,000 tax exemption can be claimed. There will be income
            tax applied to this additional income.
          </span>
        </li>
        <li className="bullet">
          <span>
            In addition to the ₹1.5 lakh deduction under Section 80C No Tax
            Deduction at Source (TDS) for savings accounts held by individuals
            and HUFs, there is an additional tax deduction under Section 80TTA.
          </span>
        </li>
        <li className="bullet">
          <span>
            Even if a person's interest income from savings bank accounts
            surpasses Rs.10,000, 80TTA will not apply if their gross total
            income is less than the minimum taxable income threshold.
          </span>
        </li>
      </ul>

      <Step
        number={`
          Financial institutions' savings accounts covered under Section 80TTA
        `}
      />
      <Content
        paragraph={`
          Banks, Post Offices, and Cooperative Societies are the financial institutions covered under Section 80TTA.
        `}
      />

      <Step
        number={`
          Persons eligible to claim 80TTA
        `}
      />
      <Content
        paragraph={`
          Hindu Undivided Family (HUF), individuals, non-residents of India with Non-resident Ordinary (NRO) savings accounts, and entities with savings accounts at eligible financial institutions are eligible to claim deductions under section 80TTA.
        `}
      />

      <Step
        number={`
          Circumstances 80TTA will not be applied
        `}
      />
      <Content
        paragraph={`
          Section 80TTA does not apply if the gross total income is less than the taxable limit. Deductions are not available to senior citizens, and certain types of deposits such as Term Deposits, Fixed Deposits, Recurring Deposits, and deposits from Non-banking Financial Companies (NBFCs) are not eligible for the tax deduction under this section.
        `}
      />

      <Step
        number={`
          How to Apply for a Section 80TTA Deduction
        `}
      />
      <Content
        paragraph={`
          Here's how to apply for Section 80TTA deductions:
        `}
      />
      <ul className="list">
        <li className="bullet">
          <span>
            Determine the Eligible Deduction:- Calculate the total amount of
            interest income earned on your savings accounts during the fiscal
            year. If your interest income is less than or equal to Rs.10,000,
            you can deduct the entire amount.
          </span>
        </li>
        <li className="bullet">
          <span>
            Gather Required Documents:- Keep all documents pertaining to your
            savings account and the interest earned. Bank statements, passbooks,
            and interest certificates provided by the bank are examples of such
            documents.
          </span>
        </li>
        <li className="bullet">
          <span>
            Submit an Income Tax Return:- Report your total interest income on
            your income tax return under the heading 'Income from Other Sources'
            on the appropriate ITR form.
          </span>
        </li>
        <li className="bullet">
          <span>
            Provide Evidence if Needed:- The proof of your interest income and
            the claimed deduction may be requested by the income tax department.
            It is imperative that you have the supporting documentation on hand
            in case verification calls for it.
          </span>
        </li>
        <li className="bullet">
          <span>
            Check and Send in:- Check the completeness and accuracy of your
            income tax return. Send the return to the income tax department
            after you're satisfied. You might need to e-verify the return if you
            are filing it online through net banking, Aadhaar OTP, or other
            available ways.
          </span>
        </li>
      </ul>

      <Content
        paragraph={`
          Make sure you keep up with any modifications to the tax code, and seek advice from a tax expert if necessary.
        `}
      />
      <Content
        title="Are Section 80TTA and 80TTB Same?"
        paragraph="Given the significance of understanding the distinctions between the provisions of sections 80TTA and 80TTB, the following table provides an overview of the same:"
      />
      <ReusableTable data={data} />
    </Box>
  );
};

export default Section80TTA;
