import React from "react";
import { Box } from "@mui/material";
import Mainhead from "../../../Components/Services/MainContent/Mainhead";
import Content from "../../../Components/Services/MainContent/Content";
import Step from "../../../Components/Services/MainContent/Step";
import CustomList from "../../../Components/Income-Tax/CustomList";
import { Image } from "../../../Components/Income-Tax/ImageStyle";
import Image7 from "../../../Assets/IncomeTax/image7.png";
import Image1 from "../../../Assets/IncomeTax/image1.png";
import Image3 from "../../../Assets/IncomeTax/image3.png";
import Image4 from "../../../Assets/IncomeTax/image4.png";

const CapitalGainCalculation = () => {
  return (
    <Box
      style={{
        display: "flex",
        flexDirection: "column",
        gap: 4,
        marginBottom: "2rem",
      }}
    >
      <Mainhead heading="How to Calculate Capital Gain under Income Tax Act, 1961" />
      <Step
        number={`
          What is Capital Gain under Income Tax Act, 1961?
        `}
      />
      <Content paragraph="Capital gain, under section 45 of the Income Tax Act, refers to the profit gained from selling or transferring a capital asset. It's the positive difference between the selling price of the asset and its original purchase price. This gain is subject to taxation under specific rules outlined in tax laws." />
      <Content paragraph="When an individual or entity sells a capital asset, any profit gained from this transaction is considered a capital gain. The Income Tax Act governs the taxation of these gains and defines how they are calculated and taxed based on the duration the asset was held before its sale. Whether it's from real estate, stocks, bonds, or other investments, any increase in value resulting from the sale or transfer of these assets falls under the purview of capital gains tax regulations. The Act outlines different tax rates and exemptions applicable to short-term and long-term capital gains, impacting the final tax liabilities of individuals or entities engaging in such transactions." />
      <Step
        number={`
      What is Short Term Capital Gain (STCG) under Income Tax Act,1961?
    `}
      />
      <Content paragraph="Short-term capital gain refers to the profit earned from the sale or transfer of a capital asset that has been held for a short duration, typically up to a specific period as determined by tax regulations. In many jurisdictions, including India's Income Tax Act, assets held for up to 36 months (24 months for certain assets like listed equity shares, equity-oriented mutual funds, etc.) before being sold or transferred are considered short-term capital assets. Gains realized from the disposal of these assets are categorized as short-term capital gains and are subject to taxation at applicable slab rates corresponding to an individual's income tax bracket." />
      <Content paragraph="Short-term capital gains are usually taxed at higher rates compared to long-term capital gains in many tax jurisdictions. The taxation on these gains is calculated based on the individual's regular income tax slab, which means that the rate of tax applied to short-term capital gains aligns with the tax bracket the taxpayer falls into. This type of capital gain is prevalent in transactions involving assets held for a relatively shorter duration, such as stocks, certain mutual funds, and other securities. Taxpayers need to consider the short-term capital gains tax implications while planning their investments and financial decisions, as these gains can significantly impact their overall tax liability." />
      <Step
        number={`
      How do you calculate Short Term Capital Gain?
    `}
      />
      <Box sx={{ textAlign: "center" }}>
        <Image
          component="img"
          sx={{
            maxWidth: "50%",

            content: {
              xs: `url(${Image7})`,
            },
          }}
        />
      </Box>
      <CustomList
        points={[
          "Ascertain the Full Value of Consideration (FVOC)",
          "Ascertain the Total Cost",
          "Calculation of Short Term Capital Gain (STCG) as FVOC - Total Cost",
        ]}
      />
      <Content paragraph="Let's understand this calculation with an illustration:" />
      <Content
        paragraph={`Suppose you purchased 100 shares of a company on January 1st for Rs. 50 each, totaling an investment of Rs. 5,000 (100 shares x Rs. 50 per share).
  Now, let's say you sold all these shares on June 1st of the same year for Rs. 70 each, receiving Rs. 7,000 (100 shares x Rs. 70 per share) in total from the sale.
  Also, the expenses related to the sale of these 100 shares amounted to ₹500.`}
      />
      <CustomList
        points={[
          "Ascertaining Full Value of Consideration (FVOC): Rs. 7,000",
          "Ascertain the Total Cost:",
          "Cost of Acquisition (COA): Rs. 5,000",
          "Expenses related to transfer: Rs. 500",
          "Total Cost: Rs. 5,500",
          "Calculation of Short Term Capital Gain (STCG): Rs. 7,000 - Rs. 5,500 = Rs. 1,500",
        ]}
      />
      <Content paragraph="The Short Term Capital Gain (STCG) is Rs. 1,500." />
      <Step
        number={`
      What is the rate of tax on Short Term Capital Gain?
    `}
      />
      <Content paragraph="Short-term capital gain is usually considered in two categories:" />
      <Box sx={{ textAlign: "center" }}>
        <Image
          component="img"
          sx={{
            maxWidth: "50%",

            content: {
              xs: `url(${Image1})`,
            },
          }}
        />
      </Box>
      <Step
        number={`
        Short Term Capital Gain on Equity Shares:
    `}
      />
      Section 111A of the Income Tax Act in India deals specifically with the
      taxation of short-term capital gains arising from the transfer of listed
      equity shares or equity-oriented mutual funds. It provides a concessional
      tax rate for such gains to encourage investment in the stock market.
      <br />
      Key points regarding Section 111A:
      <CustomList
        points={[
          "Applicability: This section applies to gains arising from the transfer of listed equity shares or equity-oriented mutual fund units held for less than or equal to 12 months. If these assets are held for more than 12 months, the gains are considered long-term capital gains.Tax Rate: Under Section 111A, short-term capital gains from the sale",
          "Tax Rate: Under Section 111A, short-term capital gains from the sale of these listed equity shares or equity-oriented mutual funds are subject to a special tax rate. As of the latest information available (up to January 2022), the tax rate is 15% (+ cess and surcharge). This rate is generally lower than the regular tax rates applicable to short-term capital gains on other assets.",
          "Eligibility: To benefit from the lower tax rate under Section 111A, the asset being sold must be listed on recognized stock exchanges in India. Additionally, the gains must be short-term, i.e., arising from the sale of these assets within 12 months of their acquisition.",
        ]}
      />
      <Content paragraph="Let's suppose you had purchased 100 shares (equity in nature) at the rate of Rs. 50 and then sold them after 6 months of purchasing at the rate of Rs. 70, Rs. 500 being the expenses on transfer." />
      <CustomList
        points={[
          "Ascertaining Full Value of Consideration (FVOC): Rs. 7,000",
          "Ascertain the Total Cost:",
          "Cost of Acquisition (COA): Rs. 5,000",
          "Expenses related to transfer: Rs. 500",
          "Total Cost: Rs. 5,500",
          "Calculation of Short Term Capital Gain (STCG): Rs. 7,000 - Rs. 5,500 = Rs. 1,500",
          "Tax on STCG (assuming a 20% slab rate): Rs. 1,500 * 20% = Rs. 300",
        ]}
      />
      <Content paragraph="The tax on this Short Term Capital Gain would be Rs. 300." />
      <CustomList
        points={[
          "Short Term Capital Gain under Section 111A:",
          "Short Term Capital Gain other than 111A:",
        ]}
      />
      <Content paragraph="Examples of the same include the sale of assets other than equity shares, sale of those equity shares that are not listed on any stock exchange, sale of debt-oriented securities, etc." />
      <Content
        paragraph={`Let's understand this with an illustration:
  Mr. Rajesh has an annual income of Rs. 9,95,000. He had purchased 20 units of Equity linked mutual funds at the rate of Rs. 150 (Invested amount being Rs. 3000) and in the same year he sold them when the NAV reached Rs. 230 (Rs. 4600 being the amount of sale).
  He earned Rs. 1,600 as Short Term Capital Gain.
  Based on Mr. Rajesh’s Tax Slab his tax liability would lie under the 20% slab rate.`}
      />
      <CustomList
        points={[
          "Ascertaining Full Value of Consideration (FVOC): Rs. 4,600",
          "Ascertain the Total Cost:",
          "Cost of Acquisition (COA): Rs. 3,000",
          "Total Cost: Rs. 3,000",
          "Calculation of Short Term Capital Gain (STCG): Rs. 4,600 - Rs. 3,000 = Rs. 1,600",
          "Tax on STCG (20% slab rate): Rs. 1,600 * 20% = Rs. 320",
        ]}
      />
      <Content paragraph="The tax on this Short Term Capital Gain would be Rs. 320." />
      <Step
        number={`
      What is Long Term Capital Gain (LTCG)?
    `}
      />
      <Content paragraph="As per the Income Tax Act, 1961; Long-Term Capital Gain (LTCG) denotes the profit acquired from the sale or transfer of specific capital assets that have been held for a minimum defined period. The qualifying duration for an asset to be categorized as a long-term asset typically ranges from 24 months to 36 months, contingent upon the nature of the asset. These assets may encompass real estate, equities, mutual funds, bonds, and certain other investments. LTCG is recognized when the selling price of the asset surpasses its original purchase price after it has been held for this stipulated period." />
      <Step
        number={`
      How do you calculate Long Term Capital Gain?
    `}
      />
      <Box sx={{ textAlign: "center" }}>
        <Image
          component="img"
          sx={{
            maxWidth: "50%",

            content: {
              xs: `url(${Image3})`,
            },
          }}
        />
      </Box>
      <CustomList
        points={[
          "Indexed Cost of Acquisition and Indexed Cost of Improvement are explained in detail here. (Note to Editor: put hyperlink to the article relating to cost of acquisition and cost of improvement)",
        ]}
      />
      <Content
        paragraph={`Let's understand this better with an example:
  Assume you purchased a property on January 1, 2010, for ₹50 lakhs. You also spent ₹5 lakhs on significant improvements to the property on April 1, 2015. Furthermore, you incurred ₹2 lakhs as expenses related to the transfer of the property when you sold it on March 31, 2022, for ₹1 crore.`}
      />
      <CustomList
        points={[
          "Ascertaining Full Value of Consideration (FVOC): Rs. 1,00,00,000",
          "Ascertaining Cost Incurred:",
          "Expenses Incurred: Rs. 2,00,000",
          "Indexed Cost of Acquisition: Rs. 1,07,43,243 (approx.)",
          "Indexed Cost of Improvement: Rs. 7,22,727 (approx.)",
          "Total Cost Incurred: Rs. 1,16,65,970",
          "Long Term Capital Gain (LTCG): Rs. 1,00,00,000 - Rs. 1,16,65,970 = (Rs. 16,65,970)",
        ]}
      />
      <Content paragraph="In this case, it is a Long Term Capital Loss obtained after deducting all the expenses in proportion to the cost inflation rates." />
      <Step
        number={`
      What is the rate of tax on Long Term Capital Gain?
    `}
      />
      <Box sx={{ textAlign: "center" }}>
        <Image
          component="img"
          sx={{
            maxWidth: "50%",

            content: {
              xs: `url(${Image4})`,
            },
          }}
        />
      </Box>
      <Content paragraph="Tax on long term capital gain can be bifurcated into two sections - 112 and 112A" />
      <CustomList
        points={[
          "Long Term Capital Gain under Section 112:",
          "Long Term Capital Gain under Section 112A:",
        ]}
      />
      <Content
        paragraph={`Suppose you had purchased 100 shares of XYZ ltd an unlisted company on 20th April 2015 at the rate of Rs. 76. Now you decided to sell the same on 23rd December 2022 at the rate of Rs. 250.
  Your Capital Gain calculated would be as follows:`}
      />
      <CustomList
        points={[
          "Full Value of Consideration: Rs. 25,000",
          "Indexed Cost of Acquisition:",
          "Cost of Acquisition: Rs. 7,600",
          "Indexation: Rs. 10,481.66 (approx.)",
          "Long Term Capital Gain (LTCG): Rs. 25,000 - Rs. 10,481.66 = Rs. 14,518.34",
          "Tax on LTCG (20% rate with indexation): Rs. 14,518.34 * 20% = Rs. 2,903.67",
          "If no Indexation is claimed:",
          "Long Term Capital Gain: Rs. 25,000 - Rs. 7,600 = Rs. 17,400",
          "Tax on LTCG (10% rate without indexation): Rs. 17,400 * 10% = Rs. 1,740",
        ]}
      />
      <Content paragraph="It would be more advisable not to claim the benefit of indexation as the resulting tax would be higher." />
      <Content paragraph="Long Term Capital Gain under Section 112A applies to listed equity shares, units of equity-oriented mutual funds, and units of business trusts where STT is paid on acquisition and transfer." />
      <Content
        paragraph={`Suppose you, a resident of India, had purchased 1000 shares of LF Ltd. on 25th January 2016, at the rate of Rs. 34.67. As on 31st January 2018, the Fair Market Value per share was Rs. 106.59 after STT. You decided to sell these shares on 1st October 2022 for Rs. 257.89. STT paid is 0.025%.
  Your Capital Gain would be calculated as follows:`}
      />
      <CustomList
        points={[
          "Full Value of Consideration: Rs. 2,57,890",
          "Ascertaining the Cost of Acquisition:",
          "Cost originally incurred: Rs. 34,670",
          "Or FMV as on 31st January 2018: Rs. 1,06,590",
          "Cost of Acquisition: Rs. 1,06,590",
          "Capital Gain: Rs. 2,57,890 - Rs. 1,06,590 = Rs. 1,51,300",
          "Tax on LTCG: (Rs. 1,51,300 - Rs. 1,00,000) * 10% = Rs. 5,130",
        ]}
      />
    </Box>
  );
};

export default CapitalGainCalculation;
