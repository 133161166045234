import React from "react";
import { Box } from "@mui/material";
import Mainhead from "../../../Components/Services/MainContent/Mainhead";
import Content from "../../../Components/Services/MainContent/Content";
import Step from "../../../Components/Services/MainContent/Step";
import ReusableTable from "../../../Components/Income-Tax/ReusableTable";
import {
  section54FTableData,
  section54DTableData,
  section54ECTableData,
} from "./TablesData";

const CapitalGainExemption = () => {
  return (
    <Box
      style={{
        display: "flex",
        flexDirection: "column",
        gap: 4,
        marginBottom: "2rem",
      }}
    >
      {" "}
      <Mainhead heading="Capital Gain Exemption under Sections 54F, 54D, and 54EC" />
      <Step number="What is Section 54F of Income Tax Act, 1961?"></Step>
      <Content paragraph="Capital gain on the transfer of certain capital assets will not be charged in case of investment in a residential house under Section 54F." />
      <ReusableTable data={section54FTableData} />
      <Step number="What is Section 54D of Income Tax Act, 1961?"></Step>
      <Content paragraph="Capital gain on compulsory acquisition of lands and buildings will not be charged in certain cases under Section 54D." />
      <ReusableTable data={section54DTableData} />
      <Step number="What is Section 54EC of the Income Tax Act, 1961?" />
      <Content paragraph="Capital gain will not be charged on investment in certain specified investments under Section 54EC." />
      <ReusableTable data={section54ECTableData} />
    </Box>
  );
};

export default CapitalGainExemption;
