import { collection, addDoc } from "firebase/firestore";
import { db } from "../firebaseConfig";
import { toast } from "react-toastify";
import { sendWhatsAppSMSAPI } from "../API/Api";

export const submitDetailsHome = (details) => async (dispatch) => {
  try {
    await addDoc(collection(db, "Users"), details);
  } catch (error) {
    console.log("Error", error);
  }
};

export const sendWhatsAppSMS = (number) => async (dispatch) => {
  try {
    sendWhatsAppSMSAPI(number);
    toast(`A message was sent to your WhatsApp ${number}`)
  } catch (error) {
    toast("Message sent not successful");
    console.log(error);
  }
};
