import React from 'react';
import Mainhead from '../../../../Components/Services/MainContent/Mainhead';
import Content from '../../../../Components/Services/MainContent/Content';
import List from '../../../../Components/Services/MainContent/List';
import Step from '../../../../Components/Services/MainContent/Step';
import { Box , styled, Typography} from '@mui/material';
import Submit from "../../../../Assets/Services/Private/LegalFactory_Private3.png";
import Office from "../../../../Assets/Services/Private/LegalFactory_Private4.png"



const Submitimg = styled(Box)(({ theme }) => ({
  width:'28,5rem',
  height:'32rem',
  marginTop:'0.8rem',
  [theme.breakpoints.down("sm")]: {
    width:"90%",
   height:'auto',
   objectFit:'contain'
},
  [theme.breakpoints.down("md")]: {
    width:'18rem',
  height:'21.5rem',
  },
  [theme.breakpoints.between("md", "lg")]: {
    width:'25rem'
  }
}));

function Register() {
  return (
    <div>
      <Mainhead heading="Private limited company online registration process :"/>
      
        
      <Step number="Step - 1: Arrange basic documents of partners."/>
     <ul className='list'>
      <li className='bullet'>
        <span>
      <List points="Copy of PAN Card (must for all resident copy of the latest bank statement/telephone or mobile bill/electricity or gas bill shareholders / directors ) or Passport (Foreign Nationals & NRIs)"/>
      </span>
      </li>
      <li className='bullet'>
        <span>
      <List points="Copy of Voter’s ID/Passport/Driver’s License/Aadhar card"/>
      </span>
      </li>
      <li className='bullet'>
        <span>
      <List points="Copy of the latest bank statement/telephone or mobile bill/electricity or gas bill(i.e not older than 2 months and payment proof of same.)"/>
      </span>
      </li>
      </ul>
      <Box sx={{textAlign:'center'}}>
      <Submitimg component="img"
                sx={{
                  content: {
                    xs: `url(${Submit})`
                  }
                }}/>
                </Box>


      <Step number="Step -2 :Obtaining DSC and DIN."/>
      <Content paragraph="The first step is to obtain DSC of the designated partners of the Limited Liability Partnership since all forms need to be submitted online on the ministry of corporate affairs portal and require the director’s digital signatures. The LEGAL FACTORY team shall also assist and prepare other documents required before filing for name approval."/>
      <Step number="Step -3 : Apply for name availability of the proposed company."/>
      <Content paragraph="The Registrar of Companies (RoC) across India expect applicant to follow a few naming guidelines. Some of them are subjective, which means that approval can depend on the opinion of the officer handling your application. However, the more closely you follow the rules listed below, the better your chances of approval."/>
      <Step number="Step -4: Provide registered office proof of the company."/>
      <Content paragraph="As per the company incorporation rules a company must have a registered office in India . the registered office is the place where all books, records ,etc are to be kept and maintained by the company and also for correspondence unless otherwise specified or kept at corporate office.The following documents are required to provide for the registered office ."/>
      <ul className='list'>
      <li className='bullet'>
        <span>
      <List points ="Copy of notarized rental agreement if rented property , notarized No Objection Certificate (NOC) if property self owned or by shareholders/director. The same in any vernacular language has to be translated to English."/>
      </span>
      </li>
      <li className='bullet'>
        <span>
      <List points ="Copy of the latest bank statement/paid telephone or paid mobile bill/paid electricity or paid gas bill."/>
      </span>
      </li>
      </ul>
      <Box sx={{textAlign:'center'}}>
      <Submitimg component="img"
                sx={{
                  content: {
                    xs: `url(${Office})`
                  }
                }}/>
                </Box>

      <Content paragraph="The registered office of a company does not have to be a commercial space. Even a rented home can be the registered office, so long as an NOC is obtained from the landlord."/>

      <Step number="Step -5: Filing of the EMoa and eAoA to register a private limited company."/>

      <Content paragraph ="The legal factory team shall assist in compiling and drafting the memorandum and articles of the company and also ensure that they are complied with the company act rules and laws.The EMoa and eAoA are very important in administering and managing a company business , also is a very important formation document which shall be required for day to day operations."/>

      <Step number="Step -6: Verification of all documents and forms filed for incorporation by the registrar of companies central registration center India ."/>

      <Content paragraph="The Legal Factory team shall assist in filing of all the documents with the registrar of company once all the documentation is been completed"/>

      <Step number="Step -7: Get Company Incorporation Certificate."/>
      <Step number="Step -8: Obtain PAN, TAN etc bank account opening assistance."/>
      <Step number="Step -9: Once we open the bank account we need to subscribe to the company shares and pay the subscription amount to the company."/>
      <Step number="Step -10: After getting certificate of commencement of business start doing business operations."/>

  
    </div>
  )
}

export default Register