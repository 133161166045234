import React from "react";
import { Box } from "@mui/material";
import Mainhead from "../../../Components/Services/MainContent/Mainhead";
import Content from "../../../Components/Services/MainContent/Content";
import CustomList from "../../../Components/Income-Tax/CustomList";
import Step from "../../../Components/Services/MainContent/Step";
import ReusableTable from "../../../Components/Income-Tax/ReusableTable";

const Section58IncomeTax = () => {
  const ulipDetails = [
    "The Unit-Linked Insurance Policy (ULIP) combines insurance and investment, with a portion of the premium invested in direct or indirect stock market investments.",
    "ULIP returns were tax-free as long as the yearly investment stayed below 10% of the plan's life insurance.",
    "Returns from ULIPs are now subject to taxation if the premium paid exceeds Rs 2.5 lakh.",
    "The Rs 2.5 lakh cap is applicable to total premiums for plans purchased on or after February 1, 2021.",
    "ULIPs will be subject to long-term capital gains (LTCG) tax, similar to other equity-oriented investments.",
    "10% tax must be paid on long-term capital gains (LTCG), and no taxes are due in the event of an individual's death.",
  ];

  const ulipTaxationTableData = [
    {
      capitalGains: "Long-term capital gains",
      periodOfHolding: "More than 12 months",
      tax: "10% on gains above Rs 1 lakh",
    },
    {
      capitalGains: "Short-term capital gains",
      periodOfHolding: "12 months or less",
      tax: "15% on overall gains",
    },
  ];

  const disallowedExpenses = [
    "Personal expenses",
    "Interest chargeable to tax which is payable outside India on which tax has not been paid or deducted at source",
    "‘Salaries’ payable outside India on which no tax is paid or deducted at source",
    "Wealth-tax",
    "Expenditure of the nature specified in section 40A",
    "Expenditure in respect of Royalty and Technical Fees received by a Foreign Company",
    "Expenditure in connection with winnings from lotteries, crossword puzzles, races, games, gambling or betting",
  ];

  const disallowanceDetails = [
    {
      section: "58(1)(a)(i)",
      nature: "Personal expenses",
      explanation: "The assessee's personal expenses are not deductible.",
    },
    {
      section: "58(1)(a)(ii)",
      nature: "Interest",
      explanation:
        "Any interest (chargeable under the Act in the hands of the recipient) payable outside India and not taxed or deducted at source is not deductible.",
    },
    {
      section: "58(1)(a)(iii)",
      nature: "Salaries",
      explanation:
        "Any payment (chargeable under the heading 'Salaries' in the hands of the receiver and payable outside India) is not deductible if no tax is paid or deducted.",
    },
    {
      section: "58(1A)",
      nature: "Wealth-tax",
      explanation:
        "Disallowance of spending if tax is not deducted or remitted with the Central Government on or before the due date for filing of return.",
    },
    {
      section: "58(2)",
      nature: "Expenditure of the nature specified in section 40A",
      explanation:
        "Disallowance under Section 40A for excessive payment made to relatives, payment made in cash, provision made for gratuity, contributions made to non-statutory funds, marked to market loss.",
    },
    {
      section: "58(3)",
      nature:
        "Expenditure in respect of Royalty and Technical Fees received by a Foreign Company",
      explanation: "Details for this specific expenditure.",
    },
    {
      section: "58(4)",
      nature:
        "Expenditure in connection with winnings from lotteries, crossword puzzles, races, games, gambling or betting",
      explanation: "Details for this specific expenditure.",
    },
  ];
  const deemedIncomeCloselyHeldDetails = [
    "The recipient is a business or entity that is closely held and in which the general public has little or no interest.",
    "The asset obtained from any person on or after June 10, 2010, but before April 1, 2017, in the form of shares in a closely held firm, is received without consideration or with insufficient consideration.",
    "If such property is acquired through a transaction that isn't considered a transfer, the clause won't be applicable.",
    "Nothing will be taxable in the recipient's hands if the total market value of the shares they got without payment during the prior year does not exceed Rs. 50,000.",
    "The total fair market value of the shares received without consideration in the prior year will be taxable in the recipient's hands if it surpasses Rs. 50,000.",
    "Nothing will be taxable in the recipient's hands if shares are received for less than fair market value and the total difference is less than Rs. 50,000; however, if the total difference exceeds Rs. 50,000, the recipient will be responsible for paying taxes on the total fair market value less the total consideration.",
  ];
  const deemedIncomeTransactions = [
    "A sum of money received without consideration",
    "An immovable property received without consideration",
    "An immovable property received for inadequate consideration",
    "A movable property received without consideration",
    "A movable property received for inadequate consideration",
  ];

  const deemedIncomeDetails = [
    {
      transactionType: "A sum of money received without consideration",
      aggregateTransactions:
        "Yes, the aggregate of all transactions in this category shall be considered",
      thresholdValue: "50,000",
    },
    {
      transactionType: "An immovable property received without consideration",
      aggregateTransactions:
        "No, each transaction in this category should be considered separately",
      thresholdValue: "50,000",
    },
    {
      transactionType:
        "An immovable property received for inadequate consideration",
      aggregateTransactions:
        "No, each transaction in this category should be considered separately",
      thresholdValue: "50,000",
    },
    {
      transactionType: "A movable property received without consideration",
      aggregateTransactions:
        "Yes, the aggregate of all the transactions in this category shall be considered",
      thresholdValue: "50,000",
    },
    {
      transactionType:
        "A movable property received for inadequate consideration",
      aggregateTransactions:
        "Yes, the aggregate of all transactions in this category shall be considered",
      thresholdValue: "50,000",
    },
  ];

  const deemedIncomeSharePremiumDetails = [
    "The recipient company must not be a publicly traded corporation.",
    "It obtains compensation from a resident person for the issuance of equity or preference shares.",
    "Shares are issued at a discount (received consideration less face value).",
    "The taxability provision does not apply if the consideration for the issue of shares is obtained by a Venture Capital enterprise from a Venture Capital Company or a Venture Capital Fund.",
    "The taxability provision does not apply if the consideration for the issue of shares is obtained by a company formed from a class or classes of people as determined by the Central Government.",
    "The 'Fair Market Value' of shares is the greater of the value determined using a specified procedure or the value proved to the satisfaction of the assessing officer based on the value of the company's assets.",
  ];

  const exampleTableData = [
    {
      particulars: "Face value of the share",
      situation1: "Rs. 100",
      situation2: "Rs. 100",
      situation3: "Rs. 100",
    },
    {
      particulars: "Fair market value of the share",
      situation1: "Rs. 120",
      situation2: "Rs. 140",
      situation3: "Rs. 150",
    },
    {
      particulars: "Issue price of the share",
      situation1: "Rs. 100",
      situation2: "Rs. 130",
      situation3: "Rs. 160",
    },
    {
      particulars:
        "Whether the issue price exceeds face value and fair market value?",
      situation1: "NO",
      situation2: "NO",
      situation3: "NO",
    },
    {
      particulars: "No. of shares issued",
      situation1: "20,000",
      situation2: "20,000",
      situation3: "20,000",
    },
    {
      particulars: "Deemed income under section 56(2)(viib)",
      situation1: "NIL",
      situation2: "NIL",
      situation3: "200,000",
    },
    {
      particulars: "[E = D * (C - B)]",
      situation1: "",
      situation2: "",
      situation3: "[20,000 * (160 - 150)]",
    },
  ];

  return (
    <Box
      style={{
        display: "flex",
        flexDirection: "column",
        gap: 4,
        marginBottom: "2rem",
      }}
    >
      <Mainhead heading="Section 58 of the Income Tax Act 1961" />
      <Step number="Amounts Expressly Disallowed from 'Income from Other Sources' in India" />
      <Content paragraph="Section 58 specifies certain amounts that are expressly disallowed from being considered as 'Income from Other Sources' in India." />
      <CustomList points={disallowedExpenses} />
      <Step number="Details of Disallowed Expenses" />
      {disallowanceDetails.map((item) => (
        <Box key={item.section} style={{ marginBottom: "1rem" }}>
          <Step number={item.section} />
          <Content paragraph={item.nature} />
          <Content paragraph={`Explanation: ${item.explanation}`} />
        </Box>
      ))}
      <Content
        paragraph="EXPENDITURE FOR ROYALTY AND TECHNICAL FEES BY A FOREIGN COMPANY [Section 58(3)] -
 In the case of foreign corporations, royalties and technical service fees as defined in section 44D are not deductible.
"
      />
      <Content
        paragraph="EXPENDITURE IN RESPECT OF LOTTERY WINNINGS [Section 58(4)] 
No deduction shall be allowed under any provision of the Act in calculating revenue from lotteries, crossword puzzles, or racing.
However, the assessee's expenditure for the activity of owning and keeping race horses is allowable as a deduction when calculating the revenue from this activity.
"
      />

      <Mainhead heading="Deemed Income under Section 56(2)(x)" />
      <Step number="What is Deemed income under Section 56(2)(x) of Income Tax Act,1961?" />
      <Content paragraph="Section 56(2)(x) applies if any person obtains a benefit (cash, moveable or immovable) of more than Rs. 50,000 from another person." />
      <Content paragraph="The following activities generate considered income under this provision:" />
      <CustomList points={deemedIncomeTransactions} />
      <Step number="Details of Deemed Income Transactions" />
      {deemedIncomeDetails.map((item) => (
        <Box key={item.transactionType} style={{ marginBottom: "1rem" }}>
          <Step number={item.transactionType} />
          <Content
            paragraph={`Is it necessary to aggregate transactions in each category? ${item.aggregateTransactions}`}
          />
          <Content paragraph={`Threshold value: ${item.thresholdValue}`} />
        </Box>
      ))}
      <Step number="Deemed Income of Closely Held Company - Shares Received by a Firm or a Company" />
      <Step number="Section 56(2)(vii a)" />
      {deemedIncomeCloselyHeldDetails.map((detail, index) => (
        <Content key={index} paragraph={detail} />
      ))}
      <Step number="Share Premium in Excess of Fair Market Value - Section 56(2)(vii b)" />
      <Step number="Conditions for Taxability" />
      {deemedIncomeSharePremiumDetails.map((detail, index) => (
        <Content key={index} paragraph={detail} />
      ))}
      <Step number="Example Table" />
      <ReusableTable data={exampleTableData} />
      <Mainhead heading="Income Received from Unit-Linked Insurance Policy (ULIP)" />
      <Step number="Overview of ULIP" />
      {ulipDetails.map((detail, index) => (
        <Content key={index} paragraph={detail} />
      ))}
      <Step number="Taxation on ULIPs" />
      <ReusableTable data={ulipTaxationTableData} />
    </Box>
  );
};

export default Section58IncomeTax;
