import React from 'react'
import Mainhead from '../../../../Components/Services/MainContent/Mainhead';
import Content from '../../../../Components/Services/MainContent/Content'
import Step from '../../../../Components/Services/MainContent/Step';
import List from '../../../../Components/Services/MainContent/List';
import { Box , styled} from '@mui/material';
import ThreeShop from '../../../../Assets/Services/ShopAct/LegalFactory_Shop1.png';

const ShopAct = styled(Box)(({ theme }) => ({
  width:'48rem',
  height:'10rem',
  marginTop:'0.8rem',
  [theme.breakpoints.down("sm")]: {
   width:'18rem',
 height:'8rem',
 },
 [theme.breakpoints.down("md")]: {
   width:'18rem',
 height:'8rem',
 },
 [theme.breakpoints.between("md", "lg")]: {
  width:'30rem'
 },
 [theme.breakpoints.up("xl")]: {
   width:'48rem'
  }
}));
function Advantages() {
  return (
    <div>
      <Mainhead heading="Advantages Enjoyed by the businesses under Shop & Establishment Act"/>
      <Content paragraph="The main purpose of the Shop and Establishment Act was to make sure that all employees get equal rights and benefits regardless of their workplace. And since it comes under the labour laws, it requires the owners to adhere to the rules and maintain a certain record of consistency whether it's the working hours of the business or the work environment, they have to keep in mind the national and religious leaves along with keeping a track record of the holiday wages."/>

      <Step number="Basic benefits include:"/>
      <ul className='list'>
        <li className='bullet'>
          <span>
      <List points="A legal entity proof for carrying on a trade, business or profession."/>
      </span>
      </li>
      <li className='bullet'>
          <span>
      <List points="It ensures a peace of mind for employer & employee about their safety in terms of health and        financial status."/>
      </span>
      </li>
      <li className='bullet'>
          <span>
      <List points="Availing Government benefits that are provided from time to time only to those registered under     this Act."/>
      </span>
      </li>
      <li className='bullet'>
          <span>
      <List points="Evidence for opening a current bank account for business purposes."/>
      </span>
      </li>
      <li className='bullet'>
          <span>
      <List points="It transforms into a legal entity that inherently solidifies the position of the business as a reliable contender in the market. Consequently, the establishment would start building trust with target customers which in turn helps it to cement its position in the market."/>
      </span>
      </li>
      <li className='bullet'>
          <span>
      <List points="It helps to resolve disputes emerging in a shop or establishment, in a legal manner."/>
         </span>
         </li>
         </ul>
      <ShopAct component="img"
                sx={{
                  content: {
                    xs: `url(${ThreeShop})`
                  }
                }}/>

      
    

    </div>
  )
}

export default Advantages