const faqData = [
  {
    id: "panel1",
    question: "What is Municipal value?",
    answer:
      "This is the valuation done by the municipal authorities for the purpose of levying taxes on such property.",
  },
  {
    id: "panel2",
    question: "What is Fair rent?",
    answer:
      "It is the rent that a similar property situated in the same or similar locality can fetch.",
  },
  {
    id: "panel3",
    question: "What is Standard rent?",
    answer:
      "If rent is fixed for the property under the Rent Control Act, then such property cannot fetch an amount that is greater than the standard rent.",
  },
  {
    id: "panel4",
    question: "What is Actual rent?",
    answer:
      "This is the actual rent received during the year by letting out the property.",
  },
  {
    id: "panel5",
    question: "What is Expected rent?",
    answer:
      "Higher of the municipal value and fair rent subject to a maximum of standard rent is known as expected rent.",
  },
  {
    id: "panel6",
    question: "What qualifies as a Let Out property?",
    answer:
      "A property that is rented out or leased to tenants for residential or commercial purposes is considered a Let Out property.",
  },
  {
    id: "panel7",
    question: "What are the tax implications for Let Out property?",
    answer:
      "Rental income earned from the property is taxable under the head 'Income from House Property' after deducting municipal taxes and standard deductions. Expenses related to maintenance and repairs can also be claimed.",
  },
  {
    id: "panel8",
    question: "How is the rental income calculated for a Let Out property?",
    answer:
      "The actual rent received or receivable is considered as the rental income for tax purposes. In case the actual rent is lower than the fair market rent, the higher of the two is taken into account.",
  },
  {
    id: "panel9",
    question: "What defines a Self-Occupied property?",
    answer:
      "A property owned by an individual and used for personal residential purposes, not rented out, is classified as a Self-Occupied property.",
  },
  {
    id: "panel10",
    question: "Are there any tax implications for Self-Occupied property?",
    answer:
      "No, there is no rental income derived from a Self-Occupied property, and hence, there's no income tax liability on such a property. However, deductions on home loan interest are capped at a specific limit under Section 24 of the Income Tax Act.",
  },
  {
    id: "panel11",
    question: "What does Deemed to be Let Out property mean?",
    answer:
      "It refers to a property that is not rented out or self-occupied but is deemed to be rented out for taxation purposes. The notional rent, based on fair market value, is considered as income.",
  },
  {
    id: "panel12",
    question:
      "How is the notional rent calculated for a Deemed to be Let Out property?",
    answer:
      "The property's fair market value is assessed, and a hypothetical rental income is determined based on market rates. This deemed rental income is taxable under 'Income from House Property.'",
  },
  {
    id: "panel13",
    question:
      "Can an owner switch the status of a property from Deemed to be Let Out to Self-Occupied?",
    answer:
      "Yes, an owner can occupy the property for personal use to change its status from Deemed to be Let Out to Self-Occupied, thereby avoiding tax on deemed rental income.",
  },
  {
    id: "panel14",
    question:
      "Are there any deductions available for a Deemed to be Let Out property?",
    answer:
      "Similar to a Let Out property, deductions for municipal taxes and standard deductions are allowed against the notional rent to arrive at the taxable income.",
  },
  // FAQs ON TAX BENEFITS AVAILABLE UNDER HOUSE PROPERTY
  {
    id: "panel15",
    question:
      "1. What tax benefits are available on the principal amount of a home loan?",
    answer:
      "Under Section 80C of the Income Tax Act, the principal repayment of a home loan is eligible for a deduction of up to Rs. 1.5 lakhs per annum. This includes the principal amount paid towards the home loan EMI.",
  },
  {
    id: "panel16",
    question:
      "2. Are there tax benefits available on the interest paid on a home loan?",
    answer:
      "Yes, Section 24(b) allows for a deduction on the interest paid on a home loan. For a self-occupied property, the maximum deduction allowed is Rs. 2 lakhs per annum. In the case of a property that is not self-occupied or is rented out, there is no upper limit on the interest deduction.",
  },
  {
    id: "panel17",
    question:
      "3. Can both spouses claim tax benefits for a jointly owned property and home loan?",
    answer:
      "Yes, both spouses can claim tax benefits individually if they are co-owners and co-borrowers of the home loan. The deduction for interest and principal repayment can be claimed in proportion to their ownership share in the property.",
  },
  {
    id: "panel18",
    question:
      "4. What if the construction of the property is not completed? Can tax benefits be claimed for an under-construction property?",
    answer:
      "Yes, tax benefits for interest payment can be claimed in five equal installments starting from the year in which the construction is completed. However, no deduction is allowed on the principal repayment until the construction is completed.",
  },
  {
    id: "panel19",
    question:
      "5. Are there any specific conditions to claim tax benefits on a home loan?",
    answer:
      "Yes, to claim tax benefits, the loan must be taken from a recognized financial institution like banks, housing finance companies, or certain approved charitable institutions. Additionally, the property's construction should be completed, and the taxpayer must be able to provide proof of the actual payment of interest and principal amount.",
  },
  {
    id: "panel20",
    question: "6. Can I claim tax benefits on a second home loan?",
    answer:
      "Yes, tax benefits on a second home loan are available. The interest paid on the home loan for the second property is eligible for a deduction under Section 24(b). However, there are no benefits available on the principal repayment for the second property.",
  },
  {
    id: "panel21",
    question:
      "7. What happens if the property is sold before completing five years from the end of the financial year in which the possession was taken?",
    answer:
      "If the property is sold before completing five years, any tax benefits claimed earlier under Section 80C for principal repayment will be reversed, and they will be added to the income of the year in which the property is sold.",
  },

  // FAQs On Section 24B of the Income Tax Act,1961 In India
  {
    id: "panel22",
    question:
      "Is Section 24B allowing a tax credit for interest paid prior to the construction or acquisition of a residential property?",
    answer:
      "Yes, taxpayers can deduct interest paid in the year preceding the construction or acquisition of a new housing property. The deduction is available in five equal installments at the start of the year in which the residence was built or purchased.",
  },
  {
    id: "panel23",
    question:
      "Are charges on overdue interest deductible under Section 24B of the Income Tax Act?",
    answer:
      "Individuals are not permitted to claim a tax deduction for the penalty on unpaid interest under Section 24B.",
  },
  {
    id: "panel24",
    question: "Can deduction be claimed for two or more house loans?",
    answer: "The discount is also available for two or more homes.",
  },
  {
    id: "panel25",
    question:
      "If property is partly SOP and partly Let out Can the limit be restricted to 2,00,000/30,000?",
    answer:
      "If a property is partially SOP and half let out, the limit of Rs 2,00,000/30,000 is applicable for the SOP portion, but there is no deduction limit for the let out portion, even if the construction is completed after three years.",
  },
  // FAQs ON HRA AND INTEREST ON HOUSE LOAN
  {
    id: "panel26",
    question:
      "Is it possible to receive advantages from a home loan and HRA simultaneously?",
    answer:
      "Yes, you receive benefits from your home loan and HRA at the same time.",
  },
  {
    id: "panel27",
    question:
      "When you reside somewhere else and rent your own house. Do you receive HRA and interest on your home loan under section 24(b)?",
    answer:
      "Yes, you receive HRA and can also claim the interest on your home loan under section 24(b).",
  },
  {
    id: "panel28",
    question:
      "When a taxpayer owns a home on which he has made mortgage payments but resides in a city where they rent because of their job, the needs of their children's education, or any other justifiable reason. Is he eligible for section 24b interest deduction in addition to HRA?",
    answer:
      "Yes, he can utilize section 24b's interest deduction and HRA, but there must be a minimum of 35 kilometers between his home and place of business.",
  },

  // FAQs On Joint Home Loan
  {
    id: "panel29",
    question: "How is the tax deduction fraction determined?",
    answer:
      "The tax deduction fraction is determined by the proportion of ownership of the loan. For example, if there are two co-borrowers who have an equal share in the property (50:50) but their loan ownership proportion is 80:20, then they will be able to avail tax benefits in the ratio 80:20.",
  },
  {
    id: "panel30",
    question: "Can the ratio of tax benefits be changed?",
    answer:
      "Yes, the tax benefit ratio can be changed with respect to any change made in the loan ownership proportion.",
  },
  {
    id: "panel31",
    question: "Who has the repayment responsibility of the loan?",
    answer:
      "The responsibility of loan repayment is the collective and individual responsibility of all the co-applicants. The EMIs of such a loan can be made out of a single joint account.",
  },
  {
    id: "panel32",
    question: "Is there any benefit of a joint home loan regarding stamp duty?",
    answer:
      "If you take a joint home loan with a female as one of the co-borrowers, such as your wife, mother, sister, daughter, etc., you can benefit from lower stamp duty charges. Many states like Delhi, UP, Haryana, Rajasthan, Punjab, etc., have relaxed rates for women as compared to men.",
  },
  {
    id: "panel33",
    question: "Documents required for applying for a joint home loan?",
    answer:
      "The following documents are required to apply for a joint home loan -\nBank statements\nAddress proof\nProperty documents\nProof of income\nPermanent account number (PAN)\n\nThe following documents are required to avail tax benefits on the home loan -\nCo-applicant declaration form specifying that the applicant wishes to avail tax benefits on the same.\nNo objection certificate (NOC) from other members specifying they will not claim any benefits from that specific amount.",
  },

  // FAQs On Section 80EE And 80EEA Of the Income Tax Act, 1961 In India
  {
    id: "panel34",
    question:
      "an Mr. Amit simultaneously claim the interest payment deduction under Sections 80EE and 80EEA on his home loan?",
    answer:
      "No, Mr. Amit must not be claiming a deduction under Section 80EEA in order to qualify for a deduction under Section 80EE.",
  },
  {
    id: "panel35",
    question:
      "What will be the total tax benefit under section 80 EE or under section 24?",
    answer:
      "Under section 24: Rs. 2,00,000\nUnder section 80EE: Rs. 50,000\nTotal deduction: Rs. 2,50,000",
  },
  {
    id: "panel36",
    question:
      "Can Mr. Amit simultaneously claim the interest payment deduction under Sections 24 or under section 80EEA?",
    answer:
      "Yes, Mr. Amit can claim deductions simultaneously under Sections 24 and 80EEA.",
  },
  {
    id: "panel37",
    question: "Eligible criteria for claiming 80EE or 80EEA deduction?",
    answer:
      "Only individuals can claim the deduction. This deduction is not available for individuals, HUF, partnership firms, etc.",
  },
  {
    id: "panel38",
    question: "DIFFERENCE UNDER SECTION 80C OR 80EEA",
    answer:
      "80C:\n- If you took out a loan to build a house, the building should be finished within five years of the loan being taken out.\n- Available for individual or HUF as an 80C deduction.\n- Deduction available under section 80C is up to Rs. 1,50,000.\n- Not applicable for an 80EEA deduction claim.\n- 80C can claim this deduction if more than one house property is owned.\n\n80EEA:\n- When a buyer buys a residential property, they must obtain a home loan, which must be approved by the financial institution between April 1, 2019, and March 31, 2021.\n- Individuals only are eligible for the 80EEA deduction.\n- Maximum deduction under 80EEA is up to Rs. 1,50,000.\n- Assessee does not make any 80EE deduction claims.\n- For the assessee to be eligible for section 80EEA, he must not own a residential property.",
  },
];

export default faqData;
