import React from 'react'
import Mainhead from '../../../../Components/Services/MainContent/Mainhead';
import Content from '../../../../Components/Services/MainContent/Content';
import Step from '../../../../Components/Services/MainContent/Step';
import List from '../../../../Components/Services/MainContent/List';
import { Box , styled} from '@mui/material';
import Threepartner from '../../../../Assets/Services/Partnership/LegalFactory_Partnership4.png'

const Partnership = styled(Box)(({ theme }) => ({
   width:'48rem',
   height:'10rem',
   marginTop:'0.8rem',
   [theme.breakpoints.down("sm")]: {
    width:'18rem',
  height:'8rem',
  },
  [theme.breakpoints.down("md")]: {
    width:'18rem',
  height:'8rem',
  },
  [theme.breakpoints.between("md", "lg")]: {
   width:'30rem'
  },
  [theme.breakpoints.up("xl")]: {
    width:'48rem'
   }
}));


function Overview() {
  return (
    <div>
      <Mainhead heading="How to set up a Partnership Firm in India ?"/>
      <Content paragraph="General Partnership is a well recognised corporate structure wherein two or more partners work in mutual alliance with unlimited liability for the debts of the business, registration of which is done under the Partnership Act, 1932."/>
      <Content paragraph="However this option suits home businesses wherein this unlimited liability dies/loses its effect as there are no debts to be put on, moreover there are many benefits introduced by the govt for registering a Partnership Firm. Count on Legal Factory experts to register and to know the latest deed format.
      "/>
      <Content paragraph="Partnership Firm a profit-oriented organisation wherein all the partners share the profits and losses in proportion to their ownership. The Capital amount is often huge and it is mandatory for all partners to be on the same path before taking any decision."/>
      <Step number="BENEFITS UNDER PARTNERSHIP FIRM REGISTRATION"/>
      <ul className='list'>
        <li className='bullet'>
          <span>
      <List points="Easiest Business Structure"/>
      </span>
      </li>
      <li className='bullet'>
          <span>
      <List points="Ease in Decision Making"/>
      </span>
      </li>
      <li className='bullet'>
          <span>
      <List points="Raising Funds"/>
      </span>
      </li>
      <li className='bullet'>
          <span>
      <List points="Easy Management without any Disputes"/>
      </span>
      </li>
      <li className='bullet'>
          <span>
      <List points="Ability to file case against Third Parties"/>
      </span>
      </li>
      <li className='bullet'>
          <span>
      <List points="Can file suit against co-partners"/>
      </span>
      </li>
      <li className='bullet'>
          <span>
      <List points="Easy Conversion of the Firm"/>
      </span>
      </li> 
      
      <li className='bullet'>
          <span>
      <List points="Enjoys Higher Credibility"/>
      </span>
      </li>
      <li className='bullet'>
          <span>
      <List points="Ability to Claim Set-off"/>
      </span>
      </li>
  </ul>
      
      <Partnership component="img"
                sx={{
                  content: {
                    xs: `url(${Threepartner})`
                  }
                }}/>

      


    

    </div>
  )
}

export default Overview